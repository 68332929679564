import moment from 'moment'
import { MM_DD_YYYY } from 'components/utils/time-formats'

export const getAnswers = identifiersJson => {
  try {
    const jsonParsed = identifiersJson
      ? JSON.parse(identifiersJson).map(answer => ({
          ...answer,
          answer: answer.response?.length > 0 ? answer.response.join(', ') : ''
        }))
      : []
    return jsonParsed.filter(identifier => identifier.answer && identifier.prompt)
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getIdentifiers = identifiersJson => {
  try {
    const identifiers = []
    if (identifiersJson) {
      JSON.parse(identifiersJson)?.forEach(answer => {
        if (answer?.response?.length > 0) {
          identifiers.push(...answer?.response)
        }
      })
    }
    return identifiers
  } catch (error) {
    console.error(error)
    return []
  }
}

const formatAnswerIdentifiers = answerIdentifiers =>
  answerIdentifiers
    .filter(e => e.length > 0)
    .map(e => (moment(e, moment.ISO_8601).isValid() ? moment(e).format(MM_DD_YYYY) : e))

export default formatAnswerIdentifiers
