/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
import WebpackerReact from 'webpacker-react'
import initializeFirebase from '../lib/firebase-messaging'

import HomepageRoot from '../components/homepage'

import '../translations/i18n'

// This must be done before initializing the app, since some setup expects this to be initialized
initializeFirebase()

WebpackerReact.setup({ HomepageRoot })

require('trix')
require('@rails/actiontext')
