import React, { useEffect, useState } from 'react'
import { useStyletron } from 'baseui'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { toast } from 'components/utils/toast'
import { MetabaseReport, MetabaseReportForm } from 'components/models/MetabaseReport'
import { reportsService } from 'components/services/reports.service'
import { URL_REG_EXP } from 'components/constants/reg-exps'
import { useTranslation } from 'react-i18next'

import Button from 'components/ui/specific/PrimaryButton'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'

const DEFAULT_REPORT = {
  name: '',
  uri: ''
}

type Props = {
  open: boolean
  onClose: () => void
  report?: MetabaseReport | null
  onFinish?: () => void
}

export const MetabaseAnalyticsSettingsModal = ({
  open,
  onClose,
  report: _report,
  onFinish
}: Props) => {
  const [css] = useStyletron()
  const [report, setReport] = useState<MetabaseReportForm>(DEFAULT_REPORT)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (_report)
      setReport({
        id: _report.id,
        name: _report.name,
        uri: _report.uri
      })
  }, [_report])

  const handleClose = () => {
    onClose()
    setReport(DEFAULT_REPORT)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const [data, status] = report.id
      ? await reportsService.updateReport(report)
      : await reportsService.createReport(report)
    if ([200, 201].includes(status)) {
      toast(
        {
          info: report.id
            ? t('Common.Info.Interpolated.Text', {
                model: t('Common.ModelType.Report.Text'),
                action: t('Common.Actions.Updated.Text')
              })
            : t('Common.Info.Interpolated.Text', {
                model: t('Common.ModelType.Report.Text'),
                action: t('Common.Actions.Created.Text')
              })
        },
        200
      )
      if (onFinish) {
        onFinish()
      }
      handleClose()
    } else {
      toast(data, status)
    }
    setLoading(false)
  }

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalHeader>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          })}>
          <span>{t('Settings.Analytics.CreateModal.Header.Text')}</span>
        </div>
      </ModalHeader>
      <ModalBody>
        <FormControl label={t('Settings.Analytics.CreateModal.Fields.Name.Label.Text')}>
          <Input
            autoComplete="off"
            name="report[name]"
            value={report.name}
            onChange={evt => {
              setReport({
                ...report,
                name: evt.currentTarget.value
              })
            }}
          />
        </FormControl>
        <FormControl label={t('Settings.Analytics.CreateModal.Fields.ReportUrl.Label.Text')}>
          <Input
            autoComplete="off"
            name="report[uri]"
            value={report.uri}
            error={report.uri !== '' && !URL_REG_EXP.test(report.uri)}
            onChange={evt => {
              setReport({
                ...report,
                uri: evt.currentTarget.value
              })
            }}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!report.uri || !report.name || !URL_REG_EXP.test(report.uri)}
          isLoading={loading}
          onClick={handleSubmit}>
          {report.id
            ? t('Settings.Analytics.CreateModal.ConfirmButton.Update.Text')
            : t('Settings.Analytics.CreateModal.ConfirmButton.Create.Text')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
