import { useStyletron } from 'baseui'
import { useMediaQuery } from 'react-responsive'
import { breakpoints } from '../../constants/breakpoints'

const useSizing = () => {
  const [_css, theme] = useStyletron()
  const isMediumAndUp = useMediaQuery({
    minWidth: theme.breakpoints.medium
  })

  const customBreakPoints = {
    sm: useMediaQuery({
      query: `(max-width: ${breakpoints.sm.max}px)`
    }),
    mdMax: useMediaQuery({
      query: `(max-width: ${breakpoints.md.max}px)`
    }),
    md: useMediaQuery({
      query: `(min-width: ${breakpoints.md.max}px)`
    }),
    lg: useMediaQuery({
      query: `(min-width: ${breakpoints.lg.max}px)`
    }),
    xl: useMediaQuery({
      query: `(min-width: ${breakpoints.xl.max}px)`
    })
  }

  return { isMediumAndUp, customBreakPoints }
}

export default useSizing
