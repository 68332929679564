import { toaster } from 'baseui/toast'
import i18n from 'translations/i18n'

const formatMessages = (messages: unknown) =>
  Object.keys(messages).map((key: string) => {
    if (['info', 'base'].includes(key)) return messages[key]
    const messageKey = key.replace(/_|\./g, ' ')
    return `${messageKey.replace(/^\w/, c => c.toUpperCase())} ${messages[key]}`
  })

export const toast = (res: unknown, status?: number) => {
  const messages = formatMessages(res)
  const messageString = messages.join(', ')
  if (messages.length === 0) {
    toaster.negative(
      `${i18n.t('Constants.SomethingWentWrong.Text')} ${i18n.t(
        'Constants.AssistanceMessage.Text'
      )}`,
      {}
    )
  } else if (status === 201) {
    toaster.positive(messageString, {})
  } else if ([null, 200, 304].includes(status)) {
    toaster.info(messageString, {})
  } else if (status >= 500) {
    toaster.negative(messageString, {})
  } else {
    toaster.warning(messageString, {})
  }
}
