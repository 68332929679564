import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import React, { useContext, useEffect, useState } from 'react'

import ErrorMessageButton from 'components/shared/error-message-button'
import { Block } from 'baseui/block'

interface ConfirmationModalProps {
  isOpen: boolean
  loading: boolean
  title: string
  cancelAction: () => void
  confirmAction: () => void
}

export const ConfirmationModal = ({
  isOpen,
  loading: saveStarts,
  title,
  cancelAction,
  confirmAction
}: ConfirmationModalProps) => {
  const [enabled, setEnabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Modal unstable_ModalBackdropScroll onClose={cancelAction} isOpen={isOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody></ModalBody>
      <ModalFooter>
        <Block display="flex" alignItems="center" justifyContent="flex-end" gridGap="scale600">
          <ErrorMessageButton
            label={'Cancel'}
            errors={[]}
            buttonProps={{
              isLoading: saveStarts,
              onClick: cancelAction,
              kind: 'secondary'
            }}
          />
          <ErrorMessageButton
            label={'Confirm'}
            errors={[]}
            buttonProps={{
              isLoading: saveStarts,
              onClick: confirmAction
            }}
          />
        </Block>
      </ModalFooter>
    </Modal>
  )
}
