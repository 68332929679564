import React, { useState, useEffect, useContext } from 'react'
import { Button } from 'baseui/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Datepicker } from 'baseui/datepicker'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { TimePicker } from 'baseui/timepicker'
import { Block } from 'baseui/block'
import { Checkbox } from 'baseui/checkbox'
import authenticatedFetch from '../../../utils/authenticated-fetch'
import { fancyToast } from '../../../utils'
import QuestionsList from '../../../facilities/book/additional-information/QuestionsList'
import AppointmentType from '../../appointment-type'
import { FTL, LTL, SLOT } from '../../adjustable-calendar/toggles/event-type'
import { CalendarToggleContext } from '../../../contexts/calendar-toggle-context'
import ErrorMessageButton from '../../error-message-button'
import moment from 'moment-timezone'
import CyberInput from 'cyber/input'
import DockType from './dock-type'
import CommodityType from 'components/shared/fields/commodity-type'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import { trim } from 'lodash'
import { useCQStateValue } from 'components/contexts/custom-questions.context'

interface CreateAppointmentProps {
  event: any
  isOpen: boolean
  close: () => void
}

const CreateAppointment = (props: CreateAppointmentProps) => {
  const { event, isOpen, close } = props

  const { selectedDate, setSelectedDate } = useContext(CalendarToggleContext)
  const [appointment, setAppointment] = useState<any>(event)
  const [loading, setLoading] = useState<boolean>(false)
  const [facility, setFacility] = useState<any>(null)
  const {
    questions,
    answers,
    actions: { setQuestions }
  } = useCQStateValue()

  useEffect(() => {
    if (appointment && appointment.facilityId && !facility) {
      authenticatedFetch({
        path: `/facilities/${appointment.facilityId}.json`
      })
        .then(([json, _status]) => {
          const equipmentTypeIds = [...Array.from(new Set<String>(json?.docks?.equipmentTypes))]
          setFacility({ ...json, equipmentTypeIds })

          if (equipmentTypeIds.length <= 1) {
            setAppointment({ ...appointment, dockType: equipmentTypeIds[0] })
          }
          if (json.appointmentTypes.length === 1) {
            const parsedQuestions = json.appointmentTypes[0]?.question.filter(
              ({ equipmentTypeIds, disabled }) =>
                !disabled && equipmentTypeIds.includes(appointment?.equipmentTypeId)
            )
            setQuestions(parsedQuestions)
          } else {
            setQuestions([])
          }
        })
        .catch(console.log)
    }
  }, [appointment])

  if (!facility || !appointment) {
    return <></>
  }

  const { purchaseOrderIdentifier, arrivalTime, customerName } = appointment

  const createAppointment = async () => {
    setLoading(true)
    const { answersAttributes } = appointment

    let { appointmentTypeId } = appointment

    if (facility.appointmentTypes && facility.appointmentTypes.length === 1) {
      appointmentTypeId = facility.appointmentTypes[0].id
    }

    const purchaseOrdersAttributes = purchaseOrderIdentifier
      ? [{ identifier: purchaseOrderIdentifier }]
      : null

    const [json, status] = await authenticatedFetch({
      path: '/appointments.json',
      method: 'POST',
      body: {
        appointment: {
          ...appointment,
          lessThanTruckload: appointment.type == LTL,
          purchaseOrdersAttributes: appointment.purchaseOrdersAttributes.map(
            (order: { identifier: string; id: string }) => ({
              ...order,
              identifier: trim(order.identifier)
            })
          ),
          appointmentTypeId,
          timeZone: moment.tz.guess(),
          ...(answers && { answersAttributes: answers }),
          ...(questions && { questions }),
          originalId: event.originalId
        }
      }
    })
    setLoading(false)

    if (status === 201) {
      setSelectedDate(new Date(selectedDate))
      close()
      fancyToast({ info: 'Appointment was successfully created' }, 201)
    } else {
      fancyToast(json, status)
    }
  }

  return (
    <Modal unstable_ModalBackdropScroll onClose={close} isOpen={isOpen}>
      <ModalHeader>Create Appointment</ModalHeader>
      <ModalBody>
        <FlexGrid
          flexGridColumnCount={[1, 1, 2]}
          flexGridRowGap="scale400"
          flexGridColumnGap="scale200">
          <FlexGridItem>
            <FormControl label="Facility">
              <Input value={appointment && facility?.name} disabled />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <FormControl label="Unique Load Identifier">
              <Input
                autoFocus
                id="appointment[purchase_order_identifier]"
                autoComplete="off"
                name="appointment[purchase_order_identifier]"
                value={appointment && purchaseOrderIdentifier}
                onChange={event =>
                  setAppointment({
                    ...appointment,
                    purchaseOrderIdentifier: event.currentTarget.value
                  })
                }
              />
            </FormControl>
          </FlexGridItem>
          {appointment && arrivalTime && (
            <>
              <FlexGridItem>
                <FormControl label="Arrival Date">
                  <Datepicker
                    value={arrivalTime}
                    formatString="MM/dd/yyyy"
                    onChange={({ date }) =>
                      setAppointment({
                        ...appointment,
                        arrivalTime: date as Date
                      })
                    }
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl label="Arrival Time">
                  <TimePicker
                    step={30 * 60}
                    value={arrivalTime}
                    onChange={date =>
                      setAppointment({
                        ...appointment,
                        arrivalTime: date as Date
                      })
                    }
                  />
                </FormControl>
              </FlexGridItem>
            </>
          )}
          <FlexGridItem>
            <FormControl label="Scheduler">
              <CyberAutocomplete
                record={appointment}
                setRecord={setAppointment}
                indexName="scheduler"
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <FormControl label="Quantity">
              <CyberInput
                autoComplete="off"
                name="appointment[quantity]"
                value={appointment.quantity}
                onChange={e => {
                  setAppointment({
                    ...appointment,
                    quantity: e.currentTarget.value.replace(/\D/, '')
                  })
                }}
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <CommodityType {...{ appointment, setAppointment }} />
          </FlexGridItem>
          <FlexGridItem>
            <AppointmentType
              appointmentTypes={facility.appointmentTypes}
              onClick={(_, index) => {
                const { id, questions } = facility.appointmentTypes[index]
                setAppointment({ ...appointment, appointmentTypeId: id })
                setQuestions(questions)
              }}
            />
          </FlexGridItem>
        </FlexGrid>
        <FlexGrid>
          {facility?.equipmentTypeIds?.length > 1 && (
            <FlexGridItem>
              <DockType
                facility={facility}
                appointment={appointment}
                setAppointment={setAppointment}
              />
            </FlexGridItem>
          )}
          {facility && facility.id && questions && questions.length > 0 && (
            <QuestionsList appointment={appointment} setAppointment={setAppointment} />
          )}
        </FlexGrid>
      </ModalBody>
      <ModalFooter>
        <ErrorMessageButton
          errors={[
            {
              label: 'Unique Load Identifier or Customer Name must be present.',
              status: !!purchaseOrderIdentifier || !!customerName
            },
            { label: 'Arrival time must be present.', status: !!arrivalTime },
            { label: 'Facility must be present.', status: !!facility?.id },

            {
              label: 'A valid scheduler must be linked to this appointment.',
              status: !!appointment?.schedulerId
            },
            {
              label: 'Quantity must be present.',
              status: appointment.quantity
            },
            {
              label: 'An appointment type must be selected.',
              status: appointment.appointmentTypeId || facility.appointmentTypes.length < 2
            },
            {
              label: 'A dock type must be selected.',
              status: appointment.equipmentTypeId
            },
            {
              label: 'A commodity type must be selected.',
              status: appointment.commodityType
            }
          ]}
          buttonProps={{ isLoading: loading, onClick: createAppointment }}
          label="Create"
        />
      </ModalFooter>
    </Modal>
  )
}

export default CreateAppointment
