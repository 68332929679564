import React, { useState } from 'react'
import Button from 'components/ui/generic/Button'
import { fancyToast } from 'components/utils'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { useInterval } from 'react-use'
import { useCurrentFacilityContext } from 'components/contexts/current-facility.context'
import { useTranslation } from 'react-i18next'
import { SUCCESS_CODE, CREATED_CODE } from '../../utils/status-codes'

const AppointmentDownloadButton = () => {
  const [download, setDownload] = useState(null)
  const { currentFacility } = useCurrentFacilityContext()
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  useInterval(
    async () => {
      const [_, status] = await authenticatedFetch({
        path: `/downloads/${download?.id}.json`,
        method: 'GET'
      })
      if (status === SUCCESS_CODE) {
        setDownload({ ...download, ready: true })
        setIsLoading(false)
      }
    },
    isLoading ? 500 : null
  )

  if (!currentFacility) {
    return <></>
  } else if (download?.ready) {
    return (
      <Button
        overrides={{ BaseButton: { style: { width: '100%' } } }}
        onClick={() => window.open(`/downloads/${download?.id}.csv`)}>
        {`${t('Appointments.AppointmentDownloadButton.Download')}`}
      </Button>
    )
  } else {
    return (
      <Button
        isLoading={download && !download.ready}
        onClick={async () => {
          const [json, status] = await authenticatedFetch({
            path: `/downloads.json`,
            method: 'POST'
          })

          if (status === SUCCESS_CODE || status === CREATED_CODE) {
            setDownload(json)
            setIsLoading(true)
            fancyToast(
              {
                info: `${t('Appointments.AppointmentDownloadButton.PrepareToast')}`
              },
              status
            )
          }
        }}
        overrides={{ BaseButton: { style: { width: '100%' } } }}>
        {t('Appointments.AppointmentDownloadButton.Prepare')}
      </Button>
    )
  }
}

export default AppointmentDownloadButton
