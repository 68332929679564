import React from "react";
import ReactPlayer from "react-player";
import StyledSpinner from "./styled-spinner";
import { fancyToast } from "../utils";

const RingVideoWrapper = ({ cameraIdentifier }) => {
  if (!cameraIdentifier) {
    return <StyledSpinner />;
  }
  return (
    <ReactPlayer
      width="360px"
      height="240px"
      controls
      config={{ file: { attributes: { muted: true, autoPlay: true } } }}
      key="file"
      url={`https://ring-gateway.terusama.com/output/${cameraIdentifier}/stream.m3u8`}
      onError={() => {
        fancyToast({ info: "Unable to find video stream." });
      }}
    />
  );
};

export default RingVideoWrapper;
