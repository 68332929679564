import React, { useContext } from "react";
import { Calendar } from "baseui/datepicker";
import { CalendarToggleContext } from "../../../contexts/calendar-toggle-context";
import { CalendarReferenceContext } from "../../../contexts/calendar-reference-context";

const MiniCalendar = () => {
  const { calendarReference } = useContext(CalendarReferenceContext);
  const { selectedDate, setSelectedDate } = useContext(CalendarToggleContext);

  return (
    <Calendar
      overrides={{
        Root: {
          style: ({ $theme }) => {
            return {
              transform: "matrix( 0.8, 0, 0, 0.8, -31.25, 13)",
            };
          },
        },
        CalendarContainer: {
          style: ({ $theme }) => {
            return {
              ...$theme.borders.border400,
            };
          },
        },
        MonthYearSelectButton: {
          style: ({ $theme }) => {
            return { fontSize: $theme.sizing.scale700 };
          },
        },
      }}
      highlightedDate={new Date()}
      value={[selectedDate]}
      onChange={({ date }) => {
        setSelectedDate(date);
        const calendarApi =
          calendarReference && calendarReference.current.getApi();
        calendarApi.gotoDate(date);
      }}
    />
  );
};

export default MiniCalendar;
