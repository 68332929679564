import React from 'react'
import { FormControl } from 'baseui/form-control'
import Select from 'components/ui/generic/Select'
import { useTranslation } from 'react-i18next'
import {
  CARRIER_UNAVAILABLE_CANCEL_REASON,
  PRODUCTION_ISSUE_CANCEL_REASON,
  TRAFFIC_CANCEL_REASON,
  USER_ERROR_CANCEL_REASON,
  WEATHER_ISSUE_CANCEL_REASON,
  DAILY_LIMIT_EXCEEDED_REASON
} from './../../constants/cancel_reasons'

const RescheduleReason = ({ value, onChange }) => {
  const { t } = useTranslation()

  const handleChange = event => {
    if (event.value) {
      onChange(event.value)
    }
  }
  return (
    <FormControl>
      <Select
        options={[
          {
            label: t('Appointments.CancelAppointmentModal.CancelOptions.UserError.Text'),
            id: USER_ERROR_CANCEL_REASON
          },
          {
            label: t('Appointments.CancelAppointmentModal.CancelOptions.CarrierUnavailable.Text'),
            id: CARRIER_UNAVAILABLE_CANCEL_REASON
          },
          {
            label: t('Appointments.CancelAppointmentModal.CancelOptions.WeatherIssue.Text'),
            id: WEATHER_ISSUE_CANCEL_REASON
          },
          {
            label: t('Appointments.CancelAppointmentModal.CancelOptions.Traffic.Text'),
            id: TRAFFIC_CANCEL_REASON
          },
          {
            label: t('Appointments.CancelAppointmentModal.CancelOptions.ProductionIssue.Text'),
            id: PRODUCTION_ISSUE_CANCEL_REASON
          },
          {
            label: t('Appointments.CancelAppointmentModal.CancelOptions.ExceededDailyLimit.Text'),
            id: DAILY_LIMIT_EXCEEDED_REASON
          }
        ]}
        value={value}
        searchable={false}
        clearable={false}
        onChange={handleChange}
        label={t('Appointments.EditDropAppointmentModal.Fields.RescheduleReason.Label.Text')}
      />
    </FormControl>
  )
}

export default RescheduleReason
