export const SET_FACILITY_OPTIONS = 'SET_FACILITY_OPTIONS'
export const SET_FACILITY = 'SET_FACILITY'
export const SET_FACILITY_RESPONSE_DONE = 'SET_FACILITY_RESPONSE_DONE'

const facilityReducer = (state, action) => {
  switch (action.type) {
    case SET_FACILITY_OPTIONS:
      return {
        ...state,
        facilityOptions: action.payload
      }
    case SET_FACILITY:
      return {
        ...state,
        facility: action.payload
      }
    case SET_FACILITY_RESPONSE_DONE:
      return {
        ...state,
        facilityRequestDone: action.payload
      }
    default:
      return state
  }
}

export default facilityReducer
