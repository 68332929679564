import React, { createContext, useContext, useEffect, useReducer } from 'react'
import {
  SET_ACTION_BUTTON_LOADING,
  SET_ACTIVE_TAB,
  SET_COMPLETE_MODAL_OPEN,
  SET_DRAWER_OPEN,
  SET_FLAG_FILTER_ON,
  SET_NOTES_MODAL_OPEN,
  SET_SHIFT_ACTIVE,
  SET_SHIFT_BUTTON_LOADING,
  SET_TABLE_LOADING,
  SET_TASKS_AND_COUNTERS,
  SET_TASKS_AND_COUNTERS_PAGINATED,
  SET_TRAILER_NUMBER,
  YardJockeyReducer
} from 'components/reducers/yard-jockey.reducer'
import { useTranslation } from 'react-i18next'
import { taskService } from '../services/task.service'
import { PAGE_SIZE } from '../constants/page-size'
import { CurrentUserContext } from '../homepage/current-user-context'
import { Task } from '../models/Task'
import { userService } from '../services/user.service'
import { fancyToast } from '../utils'
import { useSafeDispatch } from 'components/hooks/use-safe-dispatch'

export const YARD_JOCKEY_PAGE = 'YARD_JOCKEY_PAGE'
export const COMPLETE_TAB = 'COMPLETE'
export const NEW_TAB = 'NEW'
export const PENDING_TAB = 'PENDING'

export const COMPLETE_MODAL_BUTTON = 'CompleteSuccess'
export const STOP_MODAL_BUTTON = 'StopSuccess'
export const REJECT_MODAL_BUTTON = 'RejectSuccess'
export const FLAG_MODAL_BUTTON = 'FlagSuccess'
export const ACCEPT_BUTTON = 'AcceptedSuccess'
export const START_BUTTON = 'StartedSuccess'

interface YardJockeyContextProps {
  actionButtonLoading: boolean
  actionModalButtonLoading: boolean
  shiftActive: boolean
  completeModalOpen: boolean
  taskInProgressId?: string
  drawerOpen: boolean
  shiftButtonLoading: boolean
  tableLoading: boolean
  trailerNumber: string
  flagFilterOn: boolean
  activeTab: string
  tasks: Task[]
  counters: Record<string, number>
  notesModalAction?: string
  notesModalTask?: Task
  notesModalOpen: boolean
  drawerTask?: Task
  page: number
}

export const COUNTERS_INITIAL_STATE = {
  [COMPLETE_TAB]: 0,
  [NEW_TAB]: 0,
  [PENDING_TAB]: 0
}

export const YardJockeyContext = createContext({} as any)

const initialState: YardJockeyContextProps = {
  actionButtonLoading: false,
  actionModalButtonLoading: false,
  completeModalOpen: false,
  drawerOpen: false,
  shiftButtonLoading: false,
  tableLoading: false,
  shiftActive: false,
  flagFilterOn: false,
  trailerNumber: null,
  activeTab: NEW_TAB,
  taskInProgressId: null,
  tasks: [],
  counters: COUNTERS_INITIAL_STATE,
  notesModalAction: null,
  notesModalTask: null,
  drawerTask: null,
  notesModalOpen: false,
  page: 1
}

export const YardJockeyProvider = ({
  children,
  defaultInitialState
}: {
  children: any
  defaultInitialState?: YardJockeyContextProps
}) => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(YardJockeyReducer, defaultInitialState || initialState)
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const safeDispatch = useSafeDispatch(dispatch)

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      refreshTasks()
    }

    return () => {
      isMounted = false
    }
  }, [currentUser.id])

  const refreshTasks = () => {
    taskService
      .getTasksByUserPaginated({
        activeTab: state.activeTab,
        page: 1,
        pageSize: PAGE_SIZE,
        trailerNumber: state.trailerNumber,
        userId: currentUser.id,
        flag: state.flagFilterOn
      })
      .then(({ tasks, counters, taskInProgressId }) => {
        setTasksAndCounters(tasks, counters, taskInProgressId, 1)
      })
  }

  const setTasksAndCounters = (
    tasks: Task[],
    counters: Record<string, string>,
    taskInProgressId: string,
    page
  ) => {
    safeDispatch({
      type: SET_TASKS_AND_COUNTERS,
      payload: { tasks, counters, taskInProgressId, page }
    })
  }

  const setTasksAndCountersPaginated = (
    tasks: Task[],
    counters: Record<string, string>,
    taskInProgressId: string,
    page
  ) => {
    dispatch({
      type: SET_TASKS_AND_COUNTERS_PAGINATED,
      payload: { tasks, counters, taskInProgressId, page }
    })
  }

  const setActionButtonLoading = (actionButtonLoading: boolean) => {
    dispatch({ type: SET_ACTION_BUTTON_LOADING, payload: { actionButtonLoading } })
  }

  const setActiveTab = (newActiveTab: string) => {
    dispatch({ type: SET_ACTIVE_TAB, payload: { activeTab: newActiveTab } })

    taskService
      .getTasksByUserPaginated({
        activeTab: newActiveTab,
        page: 1,
        pageSize: PAGE_SIZE,
        trailerNumber: state.trailerNumber,
        userId: currentUser.id,
        flag: state.flagFilterOn
      })
      .then(({ tasks, counters, taskInProgressId }) => {
        setTasksAndCounters(tasks, counters, taskInProgressId, 1)
      })
  }

  const setDrawerOpen = (drawerOpen: boolean, task?: Task) => {
    dispatch({ type: SET_DRAWER_OPEN, payload: { drawerOpen, task } })
  }

  const setNotesModalOpen = (
    notesModalOpen: boolean,
    notesModalTask: Task,
    notesModalAction: string
  ) => {
    dispatch({
      type: SET_NOTES_MODAL_OPEN,
      payload: { notesModalOpen, notesModalTask, notesModalAction }
    })
  }

  const setShiftActive = (shiftActive: boolean) => {
    dispatch({ type: SET_SHIFT_ACTIVE, payload: { shiftActive } })
  }

  const startOrEndShift = (activeShift: boolean) => {
    setShiftButtonLoading(true)
    if (activeShift) {
      userService
        .update({
          ...currentUser,
          lastShiftDateLog: activeShift ? new Date() : null
        })
        .then(([_, status]) => {
          if (status === 200) {
            fancyToast({ info: t('YardJockey.Header.ShiftStartedSuccessfully.Text') }, status)
            setActiveTab(NEW_TAB)
          }
        })
        .finally(() => setShiftActive(activeShift))
    } else {
      userService
        .endShift(currentUser?.id)
        .then(([{ message }, status]) => {
          fancyToast({ info: message }, status)
        })
        .finally(() => setShiftActive(activeShift))
    }

    setCurrentUser({
      ...currentUser,
      lastShiftDateLog: activeShift ? new Date() : null
    })

    setShiftButtonLoading(false)
  }

  const setShiftButtonLoading = (shiftButtonLoading: boolean) => {
    dispatch({ type: SET_SHIFT_BUTTON_LOADING, payload: { shiftButtonLoading } })
  }

  const setTableLoading = (tableLoading: boolean) => {
    dispatch({ type: SET_TABLE_LOADING, payload: { tableLoading } })
  }

  const setFlagFilterOn = (flagFilterOn: boolean) => {
    dispatch({ type: SET_FLAG_FILTER_ON, payload: { flagFilterOn } })
    setTableLoading(true)

    taskService
      .getTasksByUserPaginated({
        activeTab: state.activeTab,
        page: 1,
        pageSize: PAGE_SIZE,
        trailerNumber: state.trailerNumber,
        flag: flagFilterOn,
        userId: currentUser.id
      })
      .then(({ tasks, counters, taskInProgressId }) => {
        setTasksAndCounters(tasks, counters, taskInProgressId, 1)
        setTableLoading(false)
      })
      .catch(error => {
        setTableLoading(false)
        console.error(error)
      })
  }

  const setCompleteModalOpen = (completeModalOpen: boolean) => {
    dispatch({ type: SET_COMPLETE_MODAL_OPEN, payload: { completeModalOpen } })
  }

  const setActionModalButtonLoading = (actionModalButtonLoading: boolean) => {
    dispatch({ type: SET_COMPLETE_MODAL_OPEN, payload: { actionModalButtonLoading } })
  }

  const setTrailerNumber = (trailerNumber: string) => {
    dispatch({ type: SET_TRAILER_NUMBER, payload: { trailerNumber } })
    setTableLoading(true)

    taskService
      .getTasksByUserPaginated({
        activeTab: state.activeTab,
        page: 1,
        pageSize: PAGE_SIZE,
        trailerNumber: trailerNumber,
        flag: state.flagFilterOn,
        userId: currentUser.id
      })
      .then(({ tasks, counters, taskInProgressId }) => {
        setTasksAndCounters(tasks, counters, taskInProgressId, 1)
        setTableLoading(false)
      })
      .catch(error => {
        setTableLoading(false)
        console.error(error)
      })
  }

  const handleButtonClick = (task: Task, buttonType: string, callback?: () => void) => {
    setLoadingForButton(true, buttonType)
    taskService
      .updateAssignedTask(task)
      .then(() => {
        fancyToast({ info: t(`YardJockey.Table.Toast.${buttonType}.Text`) }, 200)
        if (callback) {
          callback()
        }
        refreshTasks()
      })
      .catch(console.error)
      .finally(() => setLoadingForButton(false, buttonType))
  }

  const setLoadingForButton = (loading: boolean, buttonType: string) => {
    if (
      [COMPLETE_MODAL_BUTTON, STOP_MODAL_BUTTON, REJECT_MODAL_BUTTON, FLAG_MODAL_BUTTON].includes(
        buttonType
      )
    ) {
      setActionModalButtonLoading(loading)
    } else {
      setActionButtonLoading(loading)
    }
  }

  const loadMoreTasks = () => {
    taskService
      .getTasksByUserPaginated({
        activeTab: state.activeTab,
        page: state.page + 1,
        pageSize: PAGE_SIZE,
        trailerNumber: state.trailerNumber,
        userId: currentUser.id,
        flag: state.flagFilterOn
      })
      .then(({ tasks, counters, taskInProgressId }) => {
        setTasksAndCountersPaginated(tasks, counters, taskInProgressId, state.page + 1)
      })
  }

  const actions = {
    setActiveTab,
    setTasksAndCounters,
    setShiftButtonLoading,
    startOrEndShift,
    setFlagFilterOn,
    setTrailerNumber,
    setActionButtonLoading,
    setCompleteModalOpen,
    handleButtonClick,
    setShiftActive,
    setDrawerOpen,
    setNotesModalOpen,
    loadMoreTasks
  }

  return (
    <YardJockeyContext.Provider value={{ ...state, actions }}>
      {children}
    </YardJockeyContext.Provider>
  )
}

export const useYardJockeyContext = () => useContext(YardJockeyContext)
