import { useCallback, useEffect, useState } from 'react'
import { useHttp } from './use-http'

type UseHttp<T> = [(body?: unknown) => Promise<T>, { data: T; loading: boolean }]

export const useHttpDidMount = <T>(request: (body: unknown) => Promise<T>): UseHttp<T> => {
  const [callback, state] = useHttp<T>(request)

  useEffect(() => {
    callback()
  }, [callback])

  return [callback, state]
}
