import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import React from 'react'
import {
  AppointmentInfoContainer,
  AppointmentInfoLabel,
  AppointmentInfoLabelText,
  CustomerNameTitle,
  DateText,
  ModalContentCustomerName,
  ModalContentPO,
  ModalContentTopContainer,
  ModalContentTopContainerWrapper,
  PurchaseOrderText
} from './AppointmentCard.styled'
import { ArrowLineDown, ArrowLineUp } from '@phosphor-icons/react'
import { INBOUND_TYPE, OUTBOUND_TYPE } from '../../constants/appointment_types'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'
import EventIcon from 'components/ui/specific/EventIcon'
import Tag from '../../ui/specific/Tag'
import TimeFormatter from '../../utils/time-formatter'

const ICONS_SIZE = 10

export const AppointmentCardSmall = ({ appointment, showAnswers }) => {
  const { t } = useTranslation()
  return (
    <ModalContentTopContainer>
      <ModalContentTopContainerWrapper>
        <ModalContentCustomerName>
          <EventIcon
            event={{
              extendedProps: {
                equipmentTypeId: appointment?.equipmentTypeId
              }
            }}
          />
          <CustomerNameTitle> {appointment?.schedulerName}</CustomerNameTitle>
        </ModalContentCustomerName>
        <ModalContentPO>
          <PurchaseOrderText marginLeft="5px">
            {(appointment as any)?.purchaseOrderIdentifiers}
          </PurchaseOrderText>
          <span className="event-type">
            {appointment?.appointmentType === INBOUND_TYPE ? (
              <ArrowLineDown size={ICONS_SIZE} />
            ) : appointment?.appointmentType === OUTBOUND_TYPE ? (
              <ArrowLineUp size={ICONS_SIZE} />
            ) : (
              appointment?.appointmentType
            )}
          </span>
          <Tag type="secondary" closeable={false}>
            {capitalize(appointment?.trailer?.status)}
          </Tag>
        </ModalContentPO>
      </ModalContentTopContainerWrapper>
      <div>
        <DateText>
          {new TimeFormatter('humanDateAndTime').format(appointment?.arrivalTime)}
        </DateText>
      </div>
      <FlexGrid
        alignItems="center"
        justifyContent="center"
        flexGridColumnGap="scale600"
        flexGridRowGap="scale200"
        flexGridColumnCount={2}
        marginTop="12px">
        <FlexGridItem>
          <AppointmentInfoContainer>
            <AppointmentInfoLabel>
              {t('YardSchedule.DropModal.IdAndType.Text')}
            </AppointmentInfoLabel>
            <div style={{ lineHeight: 0 }}>
              <AppointmentInfoLabelText>
                {appointment?.purchaseOrders?.map(po => po.identifier)}
              </AppointmentInfoLabelText>
              <span className="event-type">
                {appointment?.appointmentType === INBOUND_TYPE ? (
                  <ArrowLineDown size={ICONS_SIZE} />
                ) : appointment?.appointmentType === OUTBOUND_TYPE ? (
                  <ArrowLineUp size={ICONS_SIZE} />
                ) : (
                  appointment?.appointmentType
                )}
              </span>
            </div>
          </AppointmentInfoContainer>
        </FlexGridItem>
        <FlexGridItem>
          <AppointmentInfoContainer>
            <AppointmentInfoLabel>
              {t('YardSchedule.DropModal.AppointmentId.Text')}
            </AppointmentInfoLabel>
            <AppointmentInfoLabelText>{appointment?.confirmationId}</AppointmentInfoLabelText>
          </AppointmentInfoContainer>
        </FlexGridItem>
        <FlexGridItem>
          <AppointmentInfoContainer>
            <AppointmentInfoLabel>
              {t('YardSchedule.DropModal.TypeOfAppointment.Text')}
            </AppointmentInfoLabel>
            <AppointmentInfoLabelText>
              {appointment?.appointmentType} {`(${capitalize(appointment?.handlingMethod)})`}
            </AppointmentInfoLabelText>
          </AppointmentInfoContainer>
        </FlexGridItem>
        <FlexGridItem>
          <AppointmentInfoContainer>
            <AppointmentInfoLabel>
              {t('YardSchedule.DropModal.AppointmentTypeName.Text')}
            </AppointmentInfoLabel>
            <AppointmentInfoLabelText>{appointment?.appointmentTypeName}</AppointmentInfoLabelText>
          </AppointmentInfoContainer>
        </FlexGridItem>
        <FlexGridItem>
          <AppointmentInfoContainer>
            <AppointmentInfoLabel>{t('YardSchedule.DropModal.Carrier.Text')}</AppointmentInfoLabel>
            <AppointmentInfoLabelText>{appointment?.carrierName}</AppointmentInfoLabelText>
          </AppointmentInfoContainer>
        </FlexGridItem>
        <FlexGridItem>
          <AppointmentInfoContainer>
            <AppointmentInfoLabel>
              {t('YardSchedule.DropModal.TrailerId.Text')}
            </AppointmentInfoLabel>
            <AppointmentInfoLabelText>{appointment?.trailer?.number}</AppointmentInfoLabelText>
          </AppointmentInfoContainer>
        </FlexGridItem>
        <FlexGridItem>
          <AppointmentInfoContainer>
            <AppointmentInfoLabel>
              {t('YardSchedule.DropModal.TrailerState.Text')}
            </AppointmentInfoLabel>
            <AppointmentInfoLabelText>
              {capitalize(appointment?.trailer?.state)}
            </AppointmentInfoLabelText>
          </AppointmentInfoContainer>
        </FlexGridItem>
        <FlexGridItem>
          <AppointmentInfoContainer>
            <AppointmentInfoLabel>
              {t('YardSchedule.DropModal.DriverName.Text')}
            </AppointmentInfoLabel>
            <AppointmentInfoLabelText>{''}</AppointmentInfoLabelText>
          </AppointmentInfoContainer>
        </FlexGridItem>

        {showAnswers &&
          appointment?.answersAttributes.map(
            (a: { question: { prompt: string }; response: string }) => (
              <FlexGridItem key={a?.question?.prompt}>
                <AppointmentInfoContainer>
                  <AppointmentInfoLabel>{a?.question?.prompt}</AppointmentInfoLabel>
                  <AppointmentInfoLabelText>{a?.response}</AppointmentInfoLabelText>
                </AppointmentInfoContainer>
              </FlexGridItem>
            )
          )}
      </FlexGrid>
    </ModalContentTopContainer>
  )
}

export default AppointmentCardSmall
