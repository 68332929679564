import { styled } from 'baseui'

export const RecurringPanel = styled('div', ({ isUpdate, toggle, recurring }) => ({
  height: isUpdate ? (toggle ? '90px' : '0px') : recurring ? '90px' : '0px',
  opacity: isUpdate ? (toggle ? '1' : '0') : recurring ? '1' : '0',
  transition: 'all .25s ease-out',
  visibility: isUpdate ? (toggle ? 'visible' : 'hidden') : recurring ? 'visible' : 'hidden'
}))

export const RecurringDays = styled('div', () => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  columnGap: '8px',
  width: '100%',
  flexWrap: 'wrap'
}))

export const RecurringCheckboxWrapper = styled('div', ({ $theme }) => ({
  marginBottom: $theme.sizing.scale600,
  display: 'flex'
}))

export const RecurringToggleWrapper = styled('div', ({ $theme, toggle }) => ({
  marginLeft: $theme.sizing.scale600,
  transform: toggle ? 'rotate( -180deg )' : 'rotate( -360deg )',
  transition: toggle ? 'transform 150ms ease' : 'transform 150ms ease',
  cursor: 'pointer'
}))
