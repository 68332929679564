import { Combobox } from "baseui/combobox";
import authenticatedFetch from "components/utils/authenticated-fetch";
import React, { useEffect, useState } from "react";
import { find } from "lodash";

const DockSelect = ({ appointment, setAppointment }) => {
  const [docks, setDocks] = useState([]);

  useEffect(() => {
    appointment?.dockId &&
      !appointment?.dockName &&
      authenticatedFetch({
        path: `/docks/${appointment.dockId}.json`,
      }).then(([json, status]) => {
        if ([200, 304].includes(status)) {
          setAppointment({ ...appointment, dockName: json.name });
        }
      });
  }, [appointment]);

  useEffect(() => {
    authenticatedFetch({
      path: `/facilities/${appointment.facilityId}/docks.json`,
    }).then(([json, status]) => {
      if (!find(json, { id: appointment.dockId })) {
        setAppointment({ ...appointment, dockId: "", dockName: "" });
      }
      setDocks(json.filter(({ enabled }) => enabled));
    });
  }, [appointment.facilityId]);

  return (
    <Combobox
      overrides={{
        Input: { props: { name: "appointment[dock]" } },
      }}
      value={appointment?.dockName}
      options={docks}
      mapOptionToString={({ name }) => name}
      onChange={(value, option: { id: string }) => {
        if (!option) {
          setAppointment({ ...appointment, dockName: value });
          return;
        }

        const { id } = option;

        setAppointment({ ...appointment, dockId: id, dockName: value });
      }}
    />
  );
};

export default DockSelect;
