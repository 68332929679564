import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'components/ui/generic/Select'
import {
  DEFAULT_UNASSIGNED_DOCK_OPTION,
  useDockAssigmentContext
} from '../../contexts/dock-assigment.context'
import { useDebounce } from 'react-use'
import { connectSearchBox } from 'react-instantsearch-dom'

const DEBOUNCE_TIME_IN_MS = 200

const NonConnectedDockAssignmentDockSelect = () => {
  const {
    dockOptions,
    selectedDocks,
    selectedFacility,
    actions: { setSelectedDocks }
  } = useDockAssigmentContext()

  const [search, setSearch] = useState<string>('')
  const [options, setOptions] = useState(dockOptions)
  const { t } = useTranslation()

  useDebounce(
    () => {
      if (search !== '') {
        setOptions(
          dockOptions.filter((item: { id: string; label: string }) =>
            item.label.toLowerCase().includes(search.toLowerCase())
          )
        )
      } else {
        setOptions(dockOptions)
      }
    },
    DEBOUNCE_TIME_IN_MS,
    [search]
  )

  useEffect(() => setOptions(dockOptions), [dockOptions])

  const onChange = ({ value, option }) => {
    const AllDocksOptionIncluded = value.filter(
      value => value.id === DEFAULT_UNASSIGNED_DOCK_OPTION.id
    )[0]

    if (AllDocksOptionIncluded) {
      setSelectedDocks([option])
    } else {
      setSelectedDocks(value)
    }
  }

  return (
    <Select
      clearable={false}
      backspaceClearsInputValue
      maxDropdownHeight="250px"
      deleteRemoves
      placeholder={t('Common.Select.DockAssignment.PlaceHolder.Text')}
      value={selectedFacility ? selectedDocks : []}
      onChange={e => onChange(e)}
      options={options}
      multi
      onInputChange={e => {
        setSearch(e.currentTarget.value)
      }}
      minWidth="135px"
      maxWidth="550px"
    />
  )
}

// If we don't have a searchbox, then typesense gets confused
const ConnectedDockAssignmentDockSelect = connectSearchBox(NonConnectedDockAssignmentDockSelect)

export default ConnectedDockAssignmentDockSelect
