import http from 'components/utils/authenticated-fetch'

class OrderPreferencesService {
  getUrl = (id?: string) => `/order_preferences${id ? `/${id}.json` : '.json'}`

  getOrderPreferences = (): Promise<[any, number]> => {
    return http({
      path: this.getUrl()
    })
  }

  saveOrderPreferences = (data: any): Promise<[any, number]> => {
    return http({
      path: this.getUrl(),
      method: 'POST',
      body: data
    })
  }
  updateOrderPreferences = (data: any): Promise<[any, number]> => {
    return http({
      path: this.getUrl(data?.id),
      method: 'PUT',
      body: data
    })
  }
}

export const orderPreferencesService = new OrderPreferencesService()
