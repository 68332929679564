import React, { createContext, useContext, useEffect, useReducer, useCallback } from 'react'
import { Carrier } from 'components/models/Carrier'
import carriersReducer, {
  CREATED_CARRIER,
  CREATE_CARRIER,
  DELETE_CARRIER,
  SEARCH,
  SEARCH_PAGINATE,
  SELECT_CARRIER,
  SET_CARRIER,
  SET_CARRIERS,
  SET_CARRIER_TO_DELETE,
  SET_CARRIER_TO_UPDATE,
  SET_CLOSE_MODAL_STATE,
  SET_CURRENT_PAGE,
  SET_FORM_PARAMS,
  SET_LOADING,
  SET_MODAL_STATE,
  SET_MODAL_TYPE,
  SET_SCAC_CODE,
  SET_SEARCH,
  SET_SEARCH_CARRIER,
  UPDATE_CARRIER,
  RESET_CARRIER_STATE,
  IS_VENDOR_SELECT_DISABLED
} from 'components/reducers/carriers.reducer'
import { carrierService } from 'components/services/carrier.service'
import { MODAL_TYPE_CREATE, MODAL_TYPE_EDIT } from 'components/constants/modal-types'
import { StatusCodes } from 'components/constants/http-status-codes'
import useIsMounted from 'components/hooks/use-is-mounted'
import { useSafeDispatch } from 'components/hooks/use-safe-dispatch'

interface CarriersContextProps {
  carriers: {
    searchScac?: Carrier
    items?: Array<Carrier>
    loading: boolean
    saving: boolean
    selectedCarrier: Carrier
    createdCarrier: Carrier
    CarrierToUpdate?: Carrier
    carrierToDelete?: Carrier
    modalType: string
    isModalActive: boolean
    carrier: Carrier
    scacCode: string
    disableFields: boolean
    externalUser?: boolean
    schedulerId?: string
    createdAsOther?: boolean
    isModalOpen: boolean
    isDeleteModalOpen: boolean
    total?: number
    currentPage?: number
    perPage?: number
    search?: string
    selectItems?: Array<Carrier>
    isVendorSelectDisabled?: boolean
  }
}

const initialState: CarriersContextProps = {
  carriers: {
    loading: false,
    saving: false,
    selectedCarrier: {},
    createdCarrier: {},
    CarrierToUpdate: undefined,
    carrierToDelete: undefined,
    modalType: 'Create',
    isModalActive: false,
    items: [],
    carrier: null,
    scacCode: '',
    disableFields: false,
    externalUser: false,
    createdAsOther: false,
    isModalOpen: false,
    isDeleteModalOpen: false,
    total: 0,
    currentPage: 1,
    perPage: 10,
    selectItems: [],
    isVendorSelectDisabled: false
  }
}

export const CarriersContext = createContext({} as any)

export const CarriersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(carriersReducer, initialState)
  const isMounted = useIsMounted()
  const safeDispatch = useSafeDispatch(dispatch)

  const setSearchCarrier = (value: { carrier?: Carrier; disableFields: boolean }) => {
    safeDispatch({ type: SET_SEARCH_CARRIER, payload: value })
  }

  const setModalType = value => {
    safeDispatch({ type: SET_MODAL_TYPE, payload: value })
  }

  const setLoading = value => {
    safeDispatch({ type: SET_LOADING, payload: value })
  }

  const setCarriers = carriers => {
    safeDispatch({ type: SET_CARRIERS, payload: carriers })
  }

  const setCarrierToUpdate = (carrier, isModalActive) => {
    safeDispatch({ type: SET_CARRIER_TO_UPDATE, payload: { carrier, isModalActive } })
  }

  const setCarrierToDelete = (carrier, isDeleteModalActive) => {
    safeDispatch({ type: SET_CARRIER_TO_DELETE, payload: { carrier, isDeleteModalActive } })
  }

  const setCreatedCarrier = (carrier: Carrier) => {
    safeDispatch({ type: CREATED_CARRIER, payload: carrier })
  }

  const closeModal = () => {
    setCarrier(null)
    safeDispatch({ type: SET_CLOSE_MODAL_STATE, payload: {} })
  }

  const searchCarrier = useCallback(async () => {
    setLoading(true)
    try {
      const [data, status] = await carrierService.getCarriersPaginated(
        state.carriers.search,
        state.carriers.currentPage,
        state.carriers.perPage
      )
      if (isMounted.current) {
        if (status === StatusCodes.OK) {
          safeDispatch({ type: SEARCH_PAGINATE, payload: data })
        } else {
          safeDispatch({ type: SEARCH_PAGINATE, payload: { results: [], total: 0 } })
        }
        closeModal()
      }
    } finally {
      if (isMounted.current) setLoading(false)
    }
  }, [
    state.carriers.search,
    state.carriers.currentPage,
    state.carriers.perPage,
    setLoading,
    isMounted
  ])

  const selectedCarrier = (carrier: Carrier) => {
    safeDispatch({ type: SELECT_CARRIER, payload: carrier })
  }

  const setModalState = value => {
    safeDispatch({ type: SET_MODAL_STATE, payload: value })
  }

  const setSCACcode = value => {
    safeDispatch({ type: SET_SCAC_CODE, payload: value })
  }

  const setCarrier = value => {
    safeDispatch({ type: SET_CARRIER, payload: value })
  }

  const search = search => {
    safeDispatch({ type: SET_SEARCH, payload: search })
  }

  const getSchedulers = () => {
    if (state.carriers.externalUser && state.carriers.schedulerId) {
      return [state.carriers.schedulerId]
    } else {
      return state.carriers.carrier?.schedulers
    }
  }

  const setCarrierToEdit = (carrier: Carrier) => {
    setModalType(MODAL_TYPE_EDIT)
    setCarrier(carrier)
  }

  const setDeleteCarrier = (carrier: Carrier) => {
    setCarrierToDelete(carrier, true)
  }

  const setCurrentPage = page => {
    safeDispatch({ type: SET_CURRENT_PAGE, payload: page })
  }

  const resetCarrierState = () => {
    safeDispatch({ type: RESET_CARRIER_STATE, payload: initialState })
  }

  const setIsVendorSelectDisabled = value => {
    dispatch({ type: IS_VENDOR_SELECT_DISABLED, payload: value })
  }

  const handleCreateCarrier = () => {
    if (state.carriers.modalType === MODAL_TYPE_CREATE) {
      createCarrier({
        name: state.carriers.carrier?.name,
        scacCode: state.carriers.carrier?.scacCode,
        email: state.carriers.carrier?.email,
        address: state.carriers.carrier?.address,
        schedulers: getSchedulers(),
        createdAsOther: !!state.carriers.createdAsOther
      })
    }

    if (state.carriers.modalType === MODAL_TYPE_EDIT) {
      updateCarrier({
        id: state.carriers.carrier?.id,
        name: state.carriers.carrier?.name,
        scacCode: state.carriers.carrier?.scacCode,
        email: state.carriers.carrier?.email,
        address: state.carriers.carrier?.address,
        schedulers: state.carriers.carrier?.schedulers
      })
    }
  }

  const handleDeleteCarrier = () => {
    deleteCarrier(state.carriers.carrierToDelete)
  }

  const setFormParams = (value: {
    externalUser?: boolean
    schedulerId?: string
    createdAsOther?: boolean
  }) => {
    safeDispatch({ type: SET_FORM_PARAMS, payload: value })
  }

  const createCarrier = useCallback(
    async carrier => {
      setLoading(true)
      try {
        const data = await carrierService.createCarrier(carrier)
        if (isMounted.current && data) {
          safeDispatch({
            type: CREATE_CARRIER,
            payload: data
          })
          setCreatedCarrier(data)
        }
        searchCarrier()
      } finally {
        if (isMounted.current) setLoading(false)
      }
    },
    [setLoading, setCreatedCarrier, searchCarrier, isMounted]
  )

  const updateCarrier = useCallback(
    async carrier => {
      setLoading(true)
      try {
        const data = await carrierService.updateCarrier(carrier)
        if (isMounted.current && data) {
          safeDispatch({ type: UPDATE_CARRIER, payload: data })
        }
        setCarrierToUpdate(undefined, false)
        setCarrier(null)
      } finally {
        if (isMounted.current) setLoading(false)
      }
    },
    [setLoading, setCarrierToUpdate, setCarrier, isMounted]
  )

  const deleteCarrier = useCallback(
    async carrier => {
      setLoading(true)
      try {
        const data = await carrierService.deleteCarrier(carrier)
        if (isMounted.current && data) {
          safeDispatch({ type: DELETE_CARRIER, payload: carrier })
        }
        setCarrierToDelete(undefined, false)
        searchCarrier()
      } finally {
        if (isMounted.current) setLoading(false)
      }
    },
    [setLoading, setCarrierToDelete, searchCarrier, isMounted]
  )

  const getAllRecords = useCallback(
    async search => {
      setLoading(true)
      try {
        const [data, status] = await carrierService.getCarriers(search)
        if (isMounted.current) {
          if (status === StatusCodes.OK) {
            safeDispatch({ type: SEARCH, payload: data })
          } else {
            safeDispatch({ type: SEARCH, payload: [] })
          }
        }
      } finally {
        if (isMounted.current) setLoading(false)
      }
    },
    [setLoading, isMounted]
  )

  useEffect(() => {
    searchCarrier()
  }, [state.carriers.search, state.carriers.currentPage])

  const searchBy = async searchParam => {
    const [data, status] = await carrierService.getCarriers(
      null,
      searchParam === 'scac' && state.carriers.carrier?.scacCode,
      searchParam === 'name' && state.carriers.carrier?.name
    )
    if ((state.carriers.carrier?.scacCode || state.carriers.carrier?.name) && data?.length > 0) {
      setSearchCarrier({
        carrier: {
          ...state.carriers.carrier,
          name: data[0]?.name,
          address: data[0]?.address,
          email: data[0]?.email,
          scacCode: data[0]?.scacCode
        },
        disableFields: state.carriers.modalType !== MODAL_TYPE_EDIT
      })
    } else {
      setSearchCarrier({
        carrier: {
          ...state.carriers.carrier
        },
        disableFields: false
      })
    }
  }

  const actions = {
    handleDeleteCarrier,
    setModalState,
    setModalType,
    setCarriers,
    search,
    searchBy,
    createCarrier,
    updateCarrier,
    selectedCarrier,
    setCarrierToUpdate,
    setCarrierToDelete,
    setSCACcode,
    setCarrier,
    setSearchCarrier,
    handleCreateCarrier,
    setFormParams,
    setCarrierToEdit,
    setDeleteCarrier,
    closeModal,
    setCurrentPage,
    getAllRecords,
    resetCarrierState,
    setIsVendorSelectDisabled
  }

  return (
    <CarriersContext.Provider value={{ ...state, actions }}>{children}</CarriersContext.Provider>
  )
}

export const useCarriersContext = () => useContext(CarriersContext)
