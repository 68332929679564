import { styled } from 'baseui'
import scStyled from 'styled-components'

const getExactWidth = ({ sm, md, lg, xl }): any => {
  let size = '0px'
  if (sm) {
    size = '0px'
  }
  if (md) {
    size = '420px'
  }
  if (md && lg) {
    size = '826px'
  }
  if (xl) {
    size = '1280px'
  }
  return size
}

export const SignUpBtn = styled('button', ({ $theme, width }) => ({
  color: 'white',
  background: $theme.colors.primary,
  fontSize: '14px',
  fontFamily: "'Rubik', Helvetica, serif",
  width: '100%',
  height: '40px',
  borderRadius: '24px',
  cursor: 'pointer',
  lineHeight: '16px',
  letterSpacing: '1.25px'
}))

// Main panel
export const MainContainer = styled('div', () => ({
  height: '100vh',
  display: 'flex',
  backgroundColor: 'white'
}))

export const BackgroundContainer = styled(
  'div',
  ({ backgroundimage, custombreakpoints, heightsize }) => ({
    width: getExactWidth(custombreakpoints),
    height: '100vh',
    backgroundImage: `url(${backgroundimage})`,
    backgroundRepeat: 'no-repeat center',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  })
)

export const SignInTitle = scStyled.div`
  top: 50%;
  left: 50%;
  transform: translate(0%, 200%);
`

export const SignIntroSection = styled('div', () => ({
  textAlign: 'center',
  alignItems: 'center',
  marginBottom: '10rem',
  marginTop: '3rem'
}))

export const ResponsiveSignInCard = styled('div', ({ custombreakpoints }) => ({
  width: `calc(100vw - ${getExactWidth(custombreakpoints)})`,
  maxWidth: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  display: 'flex'
}))

export const ResponsiveSignInContent = styled('div', ({ heightsize }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  marginTop: heightsize < 700 ? '20rem' : '0rem',
  height: '100vh',
  padding: '50px 32px',
  boxSizing: 'border-box'
}))

export const DynamicLogo = scStyled.img`
    max-width: 100%;
    max-height: 200px;
    margin: 48px auto;
    display: block;
`

export const LogoContainer = styled('div', () => ({
  margin: '1rem 0',
  position: 'relative',
  width: '100%',
  minHeight: '150px',
  maxWidth: '360px'
}))

export const SignInContainer = styled('div', () => ({
  margin: 'auto',
  width: '100%',
  maxWidth: '360px'
}))

export const SignInCustomParagraph = styled('p', ({ fontWeight, opacity }) => ({
  fontWeight,
  fontSize: '14px',
  lineHeight: '24px',
  alignItems: 'center',
  textAlign: 'center'
}))
