import React from 'react'
import { connectPagination } from 'react-instantsearch-dom'

import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'

import Pagination from 'components/ui/generic/Pagination'

const NonConnectedPagination = ({ currentRefinement, refine, nbPages }) => {
  const [css] = useStyletron()
  const { t } = useTranslation()

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      })}>
      <Pagination
        labels={{
          prevButton: t('Common.Table.Pagination.Prev.Text'),
          nextButton: t('Common.Table.Pagination.Next.Text'),
          preposition: t('Common.Table.Pagination.Preposition.Text')
        }}
        numPages={nbPages}
        currentPage={currentRefinement}
        onPageChange={({ nextPage }) => {
          refine(nextPage)
        }}
      />
    </div>
  )
}

const ConnectedPagination = connectPagination(NonConnectedPagination)

export default ConnectedPagination
