import styled from 'styled-components'

export const TooltipText = styled.div`
  border-radius: 3px;
  cursor: pointer;
`
export const TooltipBox = styled.div`
  position: absolute;
  bottom: calc(100% + 3px);
  visibility: hidden;
  background-color: #fff;
  width: 200px;
  padding: 8px;
  border-radius: 4px;
  transition: visibility 0.3s, color 0.3s, background-color 0.3s, width 0.3s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px;
  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 130px;
    bottom: -4px;
    position: absolute;
    border: 4px solid #fff;
    transform: rotate(315deg);
    transition: border 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px;
  }
`
export const TooltipCard = styled.div`
  position: relative;
  flex: 1;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #04112b;
    background-color: #fff;
    &:before {
      border: 4px solid #fff;
    }
  }
`
