import styledComponents from 'styled-components'
import { styled } from 'baseui'
import { FlexGrid } from 'baseui/flex-grid'
import { CHECKED_OUT_AT } from '../../../../models/Milestones'
import Button from 'components/ui/specific/SecondaryButton'

export const TabsWrapper = styled('div', ({ $theme }) => ({
  [$theme.mediaQuery.medium]: {
    marginLeft: $theme.sizing.scale800,
    padding: `0 ${$theme.sizing.scale800}`,
    borderLeft: `1px solid ${$theme.colors.inputBorder}`,
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '60vh',
    maxHeight: '80vh',
    height: '100%'
  }
}))

export const Icon = styledComponents.div`
  cursor: pointer;
`

export const MilestonesWrapper = styled(FlexGrid, ({ milestonesKey, editableMode }) => {
  return {
    padding: '8px 0',
    height: editableMode ? '96px' : '48px',
    borderBottom: CHECKED_OUT_AT !== milestonesKey ? '0.5px solid rgba(33, 33, 33, 0.08)' : ''
  }
})

export const ButtonStyled = styled(Button, () => {
  return {
    width: '100%'
  }
})
