import { EquipmentTypeState } from 'components/contexts/equipment-types.context'
import { EquipmentType } from 'components/models/DockCapacity'

export const SET_LOADING = 'SET_LOADING'
export const SET_COLUMNS = 'SET_COLUMNS'
export const SET_ALL_AVAILABLE = 'SET_ALL_AVAILABLE'
export const RECALCULATE_COLUMNS = 'RECALCULATE_COLUMNS'
export const SET_SAVING = 'SET_SAVING'
export const SET_SELECTED_EQUIPMENT_TYPE = 'SET_SELECTED_EQUIPMENT_TYPE'
export const SET_FORM_OPEN = 'SET_FORM_OPEN'
export const SET_CONFIRMATION_NEEDED = 'SET_CONFIRMATION_NEEDED'

const extractAvailable = (
  allEqTypes: EquipmentType[],
  assignedEqTypes: EquipmentType[]
): EquipmentType[] => {
  const assignedIds = assignedEqTypes?.map(eqType => eqType.id) || []
  return allEqTypes?.filter(eqType => !assignedIds.includes(eqType.id))
}

const recalculateColumns = (
  { columns },
  shipperEquipmentTypes: EquipmentType[],
  assignedEqTypes: EquipmentType[]
) => {
  return {
    ...columns,
    selected: {
      ...columns.selected,
      list: assignedEqTypes || []
    },
    available: {
      ...columns.available,
      list: extractAvailable(shipperEquipmentTypes, assignedEqTypes) || []
    }
  }
}

export const EquipmentTypeReducer = (state: EquipmentTypeState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SET_COLUMNS:
      return {
        ...state,
        columns: action.payload
      }
    case SET_ALL_AVAILABLE:
      return {
        ...state,
        allAvailableEqTypes: action.payload
      }
    case RECALCULATE_COLUMNS:
      return {
        ...state,
        columns: recalculateColumns(state, action.payload.all, action.payload.assigned)
      }
    case SET_SAVING:
      return {
        ...state,
        saving: action.payload
      }
    case SET_SELECTED_EQUIPMENT_TYPE:
      return {
        ...state,
        selectedEquipmentType: action.payload
      }
    case SET_FORM_OPEN:
      return {
        ...state,
        isFormOpen: action.payload
      }
    case SET_CONFIRMATION_NEEDED:
      return {
        ...state,
        confirmationNeeded: action.payload
      }
    default:
      return state
  }
}
