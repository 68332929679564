import React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { KIND } from 'baseui/tag'
import Tag from 'components/ui/generic/Tag'
import { ArrowLineDown, ArrowLineUp } from '@phosphor-icons/react'
import { Block, BlockProps } from 'baseui/block'
import { Card, StyledBody } from 'baseui/card'
import LabelField from './LabelField'
import { LabelSmall } from 'baseui/typography'
import { StatefulTooltip } from 'baseui/tooltip'

// Icons
import * as Icons from 'react-icons/fa'
import { Drop, Van, Truck, Snowflake, LadderSimple, BatteryFull } from '@phosphor-icons/react'
import { CustomLabel2Props, trailerStatusArrivalsTagProps } from './AppointmentCard.styled'
import { primitives } from 'components/homepage/custom-theme'
import { useTranslation } from 'react-i18next'
import { INBOUND_TYPE, OUTBOUND_FLAG } from 'components/constants/appointment_types'

const itemProps: BlockProps = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center'
}

const DOCK_TYPE_ICON = {
  ['van']: Van,
  ['tanker']: Drop,
  ['flatbed']: Truck,
  ['reefer']: Snowflake,
  ['container']: BatteryFull,
  ['rail']: LadderSimple,
  ['Van (Floor Loaded)']: Van,
  ['Van (Pallet Loaded)']: Van,
  ['van_floor']: Van,
  ['van_pallet']: Van
}

interface AppointmentArrivalsCardProps {
  appointment: any
  selected?: boolean
  onClick?: (event: Event) => any
}

const DEFAULT_ICON_NAME = 'FaPallet'
const PICKUP = 'Pickup'
const DROP = 'Drop'

export const AppointmentArrivalsCard = ({
  appointment,
  selected,
  onClick
}: AppointmentArrivalsCardProps) => {
  const { schedulerName, purchaseOrderIdentifiers } = appointment
  const { t } = useTranslation()
  const IconComponent =
    DOCK_TYPE_ICON[appointment.equipmentType.equipmentTypeName] ||
    Icons[appointment.equipmentType.equipmentTypeIcon] ||
    Icons[DEFAULT_ICON_NAME]

  const getLabelDropOrPick = () => {
    const appointmentType =
      typeof appointment?.appointmentType == 'object' && appointment?.appointmentType?.type
    return appointmentType === OUTBOUND_FLAG ? PICKUP : DROP
  }

  return (
    <Block onClick={onClick}>
      <Card
        overrides={{
          Root: {
            style: {
              backgroundColor: 'white',
              borderColor: selected ? primitives.accent : '#dbe6ff',
              marginTop: '16px',
              width: '100%',
              cursor: onClick ? 'pointer' : 'inherit'
            }
          }
        }}>
        <StyledBody>
          <Block
            minWidth={'100%'}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Block display={'flex'} alignItems={'center'} gridColumnGap={'5px'}>
              <IconComponent size="16px" />
              <LabelSmall>{schedulerName}</LabelSmall>
            </Block>
            <Block
              display="flex"
              flexDirection="row"
              alignItems="center"
              gridColumnGap={'5px'}
              maxWidth={'50%'}>
              <StatefulTooltip accessibilityType={'tooltip'} content={purchaseOrderIdentifiers}>
                <LabelSmall {...CustomLabel2Props}>{purchaseOrderIdentifiers}</LabelSmall>
              </StatefulTooltip>
              <div
                style={{
                  minWidth: '12px'
                }}>
                {appointment.appointmentType.type?.includes(INBOUND_TYPE) ? (
                  <ArrowLineDown size={12} color="#082766" />
                ) : (
                  <ArrowLineUp size={12} color="#082766" />
                )}
              </div>
              {appointment.trailer?.trailerStatus && (
                <Tag kind={KIND.primary} closeable={false} {...trailerStatusArrivalsTagProps}>
                  {appointment.trailer.trailerStatus}
                </Tag>
              )}
            </Block>
          </Block>
          <Block marginTop="scale400" />
          <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale800" flexGridRowGap="scale800">
            <FlexGridItem {...itemProps}>
              <LabelField
                pointer={appointment?.trailer?.slot?.zone ? 'trailer.slot.zone' : null}
                defaultValue={
                  appointment?.trailer?.dock?.name ? t('Common.Fields.Dock.Text') : null
                }
                field={t(`Scheduler.Appointments.AppointmentCard.${getLabelDropOrPick()}.Area`)}
                appointment={appointment}
              />
              <LabelField
                pointer={
                  appointment?.trailer?.slot?.zone ? 'trailer.slot.name' : 'trailer.dock.name'
                }
                field={t(`Scheduler.Appointments.AppointmentCard.${getLabelDropOrPick()}.Spot`)}
                appointment={appointment}
              />
            </FlexGridItem>
          </FlexGrid>
        </StyledBody>
      </Card>
    </Block>
  )
}

export default AppointmentArrivalsCard
