import React from 'react'
import { Calendar } from 'baseui/datepicker'

const VCalendar = ({ overrides, ...props }) => {
  return (
    <Calendar
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            borderTopLeftRadius: $theme.borders.popoverBorderRadius,
            borderTopRightRadius: $theme.borders.popoverBorderRadius,
            borderBottomLeftRadius: $theme.borders.popoverBorderRadius,
            borderBottomRightRadius: $theme.borders.popoverBorderRadius,
            overflow: 'hidden',
            ...$theme.borders.border200
          })
        },
        CalendarContainer: {
          style: ({ $theme }) => ({
            boxSizing: 'border-box',
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0
          })
        },
        CalendarHeader: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.light,
            paddingTop: $theme.sizing.scale300,
            paddingLeft: $theme.sizing.scale300,
            paddingRight: $theme.sizing.scale300,
            paddingBottom: $theme.sizing.scale300
          })
        },

        MonthHeader: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.light
          })
        },
        MonthYearSelectButton: {
          style: ({ $theme }) => ({
            color: $theme.colors.text,
            fontSize: $theme.typography.LabelSmall.fontSize,
            ':focus': {
              backgroundColor: $theme.colors.accent,
              outline: 'none'
            }
          })
        },
        WeekdayHeader: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelSmall.fontSize,
            color: $theme.colors.text,
            height: $theme.sizing.scale1000,
            width: $theme.sizing.scale1000
          })
        },
        PrevButton: {
          style: ({ $theme }) => {
            return {
              color: $theme.colors.text,
              borderRadius: $theme.borders.buttonBorderRadius,
              ':focus': {
                backgroundColor: $theme.colors.white
              }
            }
          }
        },
        NextButton: {
          style: ({ $theme }) => {
            return {
              color: $theme.colors.text,
              borderRadius: $theme.borders.buttonBorderRadius,
              ':focus': {
                backgroundColor: $theme.colors.white
              }
            }
          }
        },
        Day: {
          style: ({ $theme, $selected }) => ({
            fontSize: $theme.typography.LabelSmall.fontSize,
            paddingTop: $theme.sizing.scale200,
            paddingBottom: $theme.sizing.scale200,
            paddingRight: $theme.sizing.scale200,
            paddingLeft: $theme.sizing.scale200,
            height: $selected ? $theme.sizing.scale1000 : $theme.sizing.scale1000,
            width: $theme.sizing.scale1000
          })
        },
        ...overrides
      }}
      range
      {...props}
    />
  )
}
export default VCalendar
