import { toaster } from 'baseui/toast'
import { StatusCodes } from 'components/constants/http-status-codes'
import i18n from 'translations/i18n'

const shouldLoadRecord = (
  props: { match: { params: { handle: string } } },
  record: any
): boolean => {
  const { match } = props
  if (!match) return

  const { params } = match
  if (!params) return

  const { handle } = params
  if (!handle) return

  return handle !== 'new' && Object.keys(record).length === 0 && record.constructor === Object
}

function responseToMessages(messages) {
  return Object.keys(messages).map((key: string) => {
    if (['info', 'base'].includes(key)) {
      return messages[key]
    }
    const messageKey = key.replace(/_|\./g, ' ')
    return `${messageKey.replace(/^\w/, c => c.toUpperCase())} ${messages[key]}`
  })
}

const fancyToast = (jsonResponse: any, status?: number) => {
  const messages = responseToMessages(jsonResponse)
  const messageString = messages.join(', ')
  if (messages.length === 0) {
    toaster.negative(
      `${i18n.t('Constants.SomethingWentWrong.Text')} ${i18n.t(
        'Constants.AssistanceMessage.Text'
      )}`,
      {}
    )
  } else if (status === StatusCodes.CREATED) {
    toaster.positive(messageString, {})
  } else if (
    [null, StatusCodes.OK, StatusCodes.NOT_MODIFIED, StatusCodes.NO_CONTENT].includes(status)
  ) {
    toaster.info(messageString, {})
  } else if (status >= StatusCodes.INTERNAL_SERVER_ERROR) {
    toaster.negative(messageString, {})
  } else {
    toaster.warning(messageString, {})
  }
}

export { shouldLoadRecord, fancyToast }
