import { useStyletron } from 'baseui'
import React from 'react'
import { Prohibit } from '@phosphor-icons/react'
import { Option } from '../../../../shared/types/Option'

interface BlackoutProps {
  setSelectedEvent: (selectedEvent: SelectedEvent) => void
  selectedFacilities: Option<string>[]
  blackouts: any[]
  date: Date
}

const Blackouts = ({ setSelectedEvent, date, selectedFacilities }: BlackoutProps) => {
  const [css, theme] = useStyletron()

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.negative
      })}>
      <Prohibit
        id="blackout-modal-icon"
        color={selectedFacilities?.length > 0 ? 'red' : 'grey'}
        onClick={() => {
          if (selectedFacilities?.length > 0) {
            const initialBlackout: SelectedEvent = {
              arrivalTime: date.toISOString(),
              blackout: true
            }
            setSelectedEvent(initialBlackout)
          }
        }}
        size={theme.sizing.scale800}
      />
    </div>
  )
}

export default Blackouts
