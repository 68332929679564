import React, { useState, useEffect } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Checkbox } from 'baseui/checkbox'
import { HeadingLevel, Heading } from 'baseui/heading'
import { Datepicker } from 'baseui/datepicker'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { Paragraph2 } from 'baseui/typography'
import { RepeatingRules, Day } from '../../../shared/schedule-form'
import { useTranslation } from 'react-i18next'

const ScheduleForm = ({ record, setRecord, timeField }) => {
  const schedule: RepeatingRules = record.schedule
  const { t } = useTranslation()

  const DAYS: Day[] = [
    { id: 0, name: t('Common.Days.Sunday.Text') },
    { id: 1, name: t('Common.Days.Monday.Text') },
    { id: 2, name: t('Common.Days.Tuesday.Text') },
    { id: 3, name: t('Common.Days.Wednesday.Text') },
    { id: 4, name: t('Common.Days.Thursday.Text') },
    { id: 5, name: t('Common.Days.Friday.Text') },
    { id: 6, name: t('Common.Days.Saturday.Text') }
  ]

  const selectedDays =
    schedule && schedule.rrules && schedule.rrules[0] && schedule.rrules[0].validations.day

  const selectDay = index => {
    return e => {
      const newDays = DAYS.filter(day => {
        return (
          (selectedDays.indexOf(day.id) > -1 && day !== DAYS[index]) ||
          (e.currentTarget.checked && day == DAYS[index])
        )
      }).map(day => day.id)

      setRecord({
        ...record,
        schedule: {
          ...schedule,
          rrules: [
            {
              ...schedule.rrules[0],
              validations: { day: newDays }
            }
          ]
        }
      })
    }
  }

  const Weekdays = () => {
    return (
      <>
        <Heading>
          {t('Facilities.CreateRepeatingAppointment.Fields.DaysOfTheWeek.Label.Text')}
        </Heading>
        <FlexGrid
          flexGridColumnCount={[2, 3, 7]}
          flexGridColumnGap="scale200"
          flexGridRowGap="scale400">
          {DAYS.map((day, index) => {
            return (
              <FlexGridItem key={index}>
                <Checkbox
                  overrides={{
                    Label: {
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }
                  }}
                  checked={selectedDays.indexOf(day.id) > -1}
                  onChange={selectDay(index)}>
                  {day.name}
                </Checkbox>
              </FlexGridItem>
            )
          })}
        </FlexGrid>
      </>
    )
  }

  const addExtime = () => {
    const newExtimes = [
      ...schedule.extimes,
      Array.isArray(record[timeField])
        ? record[timeField][0].toISOString()
        : record[timeField].toISOString()
    ]
    setRecord({
      ...record,
      schedule: { ...record.schedule, extimes: newExtimes }
    })
  }

  const onExtimeDateChange = index => {
    return ({ date }) => {
      const newExtimes = [...schedule.extimes]

      newExtimes[index] = Array.isArray(date) ? date[0].toISOString() : date.toISOString()

      setRecord({
        ...record,
        schedule: {
          ...schedule,
          extimes: newExtimes
        }
      })
    }
  }

  return (
    <HeadingLevel>
      <Weekdays />
    </HeadingLevel>
  )
}

export default ScheduleForm
