export const SET_TASKS_AND_COUNTERS = 'SET_TASKS_AND_COUNTERS'
export const SET_TASKS_AND_COUNTERS_PAGINATED = 'SET_TASKS_AND_COUNTERS_PAGINATED'
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN'
export const SET_ACTION_BUTTON_LOADING = 'SET_ACTION_BUTTON_LOADING'
export const SET_COMPLETE_MODAL_OPEN = 'SET_COMPLETE_MODAL_OPEN'
export const SET_ACTION_MODAL_BUTTON_LOADING = 'SET_ACTION_MODAL_BUTTON_LOADING'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const SET_SHIFT_BUTTON_LOADING = 'SET_SHIFT_BUTTON_LOADING'
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING'
export const SET_SHIFT_ACTIVE = 'SET_SHIFT_ACTIVE'
export const SET_FLAG_FILTER_ON = 'SET_FLAG_FILTER_ON'
export const SET_TRAILER_NUMBER = 'SET_TRAILER_NUMBER'
export const SET_TASK_IN_PROGRESS_ID = 'SET_TASK_IN_PROGRESS_ID'
export const SET_NOTES_MODAL_OPEN = 'SET_NOTES_MODAL_OPEN'

export const YardJockeyReducer = (state, action) => {
  switch (action.type) {
    case SET_DRAWER_OPEN: {
      const { drawerOpen, task } = action.payload
      return {
        ...state,
        drawerOpen,
        drawerTask: task
      }
    }
    case SET_NOTES_MODAL_OPEN: {
      const { notesModalOpen, notesModalTask, notesModalAction } = action.payload
      return {
        ...state,
        notesModalOpen,
        notesModalTask,
        notesModalAction
      }
    }
    case SET_ACTION_MODAL_BUTTON_LOADING: {
      const { actionModalButtonLoading } = action.payload
      return {
        ...state,
        actionModalButtonLoading
      }
    }
    case SET_ACTION_MODAL_BUTTON_LOADING: {
      const { actionModalButtonLoading } = action.payload
      return {
        ...state,
        actionModalButtonLoading
      }
    }
    case SET_COMPLETE_MODAL_OPEN: {
      const { completeModalOpen } = action.payload
      return {
        ...state,
        completeModalOpen
      }
    }
    case SET_SHIFT_ACTIVE: {
      const { shiftActive } = action.payload
      return {
        ...state,
        shiftActive
      }
    }
    case SET_ACTION_BUTTON_LOADING: {
      const { actionButtonLoading } = action.payload
      return {
        ...state,
        actionButtonLoading
      }
    }
    case SET_ACTION_BUTTON_LOADING: {
      const { actionButtonLoading } = action.payload
      return {
        ...state,
        actionButtonLoading
      }
    }
    case SET_TASK_IN_PROGRESS_ID: {
      const { taskInProgressId } = action.payload
      return {
        ...state,
        taskInProgressId
      }
    }
    case SET_TASKS_AND_COUNTERS: {
      const { tasks, counters, taskInProgressId, page } = action.payload
      return {
        ...state,
        counters,
        tasks,
        taskInProgressId,
        page
      }
    }
    case SET_TASKS_AND_COUNTERS_PAGINATED: {
      const { tasks, counters, taskInProgressId, page } = action.payload
      return {
        ...state,
        counters,
        tasks: [...state.tasks, ...tasks],
        taskInProgressId,
        page
      }
    }
    case SET_ACTIVE_TAB: {
      const { activeTab } = action.payload
      return {
        ...state,
        activeTab
      }
    }
    case SET_SHIFT_BUTTON_LOADING: {
      const { shiftButtonLoading } = action.payload
      return {
        ...state,
        shiftButtonLoading
      }
    }
    case SET_FLAG_FILTER_ON: {
      const { flagFilterOn } = action.payload
      return {
        ...state,
        flagFilterOn
      }
    }
    case SET_TABLE_LOADING: {
      const { tableLoading } = action.payload
      return {
        ...state,
        tableLoading
      }
    }
    case SET_TRAILER_NUMBER: {
      const { trailerNumber } = action.payload
      return {
        ...state,
        trailerNumber
      }
    }
    default:
      return state
  }
}
