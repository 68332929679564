import http from 'components/utils/authenticated-fetch'
import { Carrier } from 'components/models/Carrier'
import { fancyToast } from 'components/utils'
import { StatusCodes } from 'components/constants/http-status-codes'

class CarrierService {
  getUrl = (carrierId?: string) => `/carriers${carrierId ? `/${carrierId}.json` : '.json'}`

  getCarrierById = (carrierId: string): Promise<[any, number]> =>
    http({
      path: this.getUrl(carrierId)
    })

  getCarriers = (search?: string, scac?: string, name?: string): Promise<[any, number]> => {
    const url = this.getUrl()

    const params = new URLSearchParams()

    if (search) {
      params.append('search', search)
    }

    if (scac) {
      params.append('search_scac', scac)
    }

    if (name) {
      params.append('search_name', name)
    }

    return http({
      path: `${url}?${params.toString()}`
    })
  }

  getCarriersPaginated = (
    search?: string,
    page?: number,
    perPage: number = 10
  ): Promise<[any, number]> => {
    const params = new URLSearchParams()

    if (search) {
      params.append('search', search)
    }
    if (page) {
      params.append('page', page.toString())
    }
    params.append('perPage', perPage.toString())

    return http({
      path: `/carriers/paginate.json?${params.toString()}`
    })
  }

  createCarrier = async (carrier: Carrier): Promise<any> => {
    const [data, status] = await http({
      path: this.getUrl(),
      method: 'POST',
      body: {
        carrier: carrier
      }
    })

    if (status < StatusCodes.BAD_REQUEST && data) {
      fancyToast({ info: 'Carrier created successfully' }, StatusCodes.OK)
      return data
    } else {
      fancyToast({ info: data?.message || 'Something went wrong' }, StatusCodes.BAD_REQUEST)
      return null
    }
  }

  updateCarrier = async (carrier: Carrier): Promise<any> => {
    const [data, status] = await http({
      path: this.getUrl(carrier.id),
      method: 'PATCH',
      body: {
        carrier: carrier
      }
    })

    if (status < StatusCodes.BAD_REQUEST && data) {
      fancyToast({ info: 'Carrier updated successfully' }, StatusCodes.OK)
      return data
    } else {
      fancyToast({ info: data?.message || 'Something went wrong' }, StatusCodes.BAD_REQUEST)
      return null
    }
  }

  deleteCarrier = async (carrier: Carrier): Promise<any> => {
    const [data, status] = await http({
      path: this.getUrl(carrier.id),
      method: 'DELETE'
    })

    if (status < StatusCodes.BAD_REQUEST) {
      fancyToast({ info: 'Carrier deleted successfully' }, StatusCodes.OK)
      return carrier
    } else {
      fancyToast({ info: data?.message || 'Something went wrong' }, StatusCodes.BAD_REQUEST)
      return null
    }
  }
}

export const carrierService = new CarrierService()
