import React from 'react'
import { upperFirst, camelCase } from 'lodash'
import FilterTag from 'components/ui/specific/FilterTag'

const OrderFilterSummary = ({ filters, setFiltersList }) => {
  const mapFiltersParam = (filterParams: any[]): { name: string; value: string }[] => {
    const activeFilters = []
    filterParams?.forEach(attr => {
      attr.items
        .filter(item => item.isActive)
        .forEach(item => {
          activeFilters.push({
            name: attr.attr,
            value: item.label
          })
        })
    })
    return activeFilters
  }

  const deleteQueryParam = (name: string, value: string) => {
    const newFilters = [...filters]
    newFilters?.forEach(attr => {
      attr.items.forEach(item => {
        if (name === attr.attr && value === item.label) {
          item.isActive = false
        }
      })
    })
    setFiltersList(newFilters)
  }

  return (
    <>
      {mapFiltersParam(filters).map(({ name, value }) => (
        <FilterTag
          filterName={upperFirst(camelCase(name))}
          filterValue={upperFirst(value)}
          onActionClick={() => deleteQueryParam(name, value)}
        />
      ))}
    </>
  )
}

export default OrderFilterSummary
