import React from "react";
import { Route } from "react-router-dom";
import InviteShow from "./invite-show";

const InviteRoutes = ({ match }) => (
  <>
    <Route path={`${match.url}/:handle`} exact component={InviteShow} />
  </>
);

export default InviteRoutes;
