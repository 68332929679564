import React, { useCallback, useEffect, useMemo } from 'react'
import { FlexGrid } from 'baseui/flex-grid'
import { Question } from 'components/appointments/types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import QuestionsList from './QuestionsList'

interface AdditionalInformationProps {
  appointment?: any
  appointmentType: any
  userType: string
  finishButtonLabel?: string
  loading?: boolean
  onSaveTemporalFiles?: boolean
  setPreparedDataToUpload?: any
  checkFileUploaderPresence?: any
  customQuestionsContext?: any
  afterSubmit?: Function
  setAppointment?: Function
  showErrors?: boolean
}

const AdditionalInformation = (props: AdditionalInformationProps) => {
  const { appointment, appointmentType, setAppointment, showErrors } = props

  const {
    isDisabled,
    actions: { setQuestions, setAnswers }
  } = useCQStateValue()

  const questions = useMemo(() => {
    return (
      appointmentType?.questions?.filter(question => {
        const { equipmentTypeIds } = question
        if (question.disabled) {
          return false
        }
        return equipmentTypeIds?.includes(appointment.equipmentTypeId)
      }) || []
    )
  }, [appointmentType, appointment.equipmentTypeId])

  const checkQuestionError = question => {
    const permission = question.questionPermissionsAttributes[0]
    return permission ? permission?.required : true
  }

  const loadQuestions = useCallback(() => {
    setQuestions(questions)
    const answers = questions.map((question: Question) => {
      const existingAnswer = appointment?.answersAttributes?.find(
        response => response.questionId === question.id
      )

      return {
        response: existingAnswer ? existingAnswer.response : '',
        questionId: question.id,
        error: checkQuestionError(question)
      }
    })
    setAnswers(answers)
    setAppointment?.(answers)
  }, [questions])

  useEffect(() => {
    loadQuestions()
  }, [questions, loadQuestions])

  return (
    <FlexGrid flexGridRowGap="scale200">
      {questions && questions.length > 0 ? (
        <QuestionsList
          {...{
            appointment,
            setAppointment,
            showErrors
          }}
        />
      ) : null}
    </FlexGrid>
  )
}

export default AdditionalInformation
