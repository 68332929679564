import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Modal, ModalHeader, ModalBody } from 'baseui/modal'
import { Appointment } from 'components/appointments/types'
import {
  CustomLabelsContext,
  INITIAL_CUSTOM_FIELD_NAMES
} from 'components/contexts/custom-labels-context'
import ErrorMessageButton from 'components/shared/error-message-button'
import { fancyToast } from 'components/utils'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import CustomLabelFormControl from '../custom-label-form-control'
import ArrivalTime from '../fields/arrival-time'
import PurchaseOrderIdentifiers from '../fields/purchase-order-identifiers'
import AuditLogs from './audit-logs'
import CancelButton from './cancel-button'
import ReadOnlyAnswers from './answers'
import DockType from './dock-type'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { SHIPPER } from 'components/constants/user-types'
import { CurrentUser } from 'components/users'
import { trim } from 'lodash'
import RecurringFields from '../fields/recurring-fields'
import ConfirmModificationRecurringAppointmentModal from 'components/appointments/modals/confirm-modification-recurring-modal'
import { DateTime } from 'luxon'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { appointmentService } from 'components/services/appointment.service'
import { answerService } from 'components/services/answer.service'
import { getFileName } from 'components/utils/parse-filename'
import { recurringAppointmentBlueprintService } from 'components/services/recurring-appointment-blueprint.service'
import { Tab } from 'baseui/tabs'
import Milestones from './MilestonesSection'
import { TabsWrapper } from './MilestonesSection.styled'
import { INTERNAL } from '../../../../models/Role'
import { DOCUMENT_TYPE } from '../../../../models/Question'
import { checkFileQuestionError } from '../../../../utils/check-question-error'
import { useTranslation } from 'react-i18next'

import Input from 'components/ui/generic/Input'
import Tabs from 'components/ui/generic/Tabs'
import FormControl from 'components/ui/generic/FormControl'

const isShipperUser = (user: CurrentUser) => user?.userType === SHIPPER

export const EditAppointmentModal = (props: ModalProps) => {
  const { selectedEvent, isOpen, close } = props
  const { setCustomLabels } = useContext(CustomLabelsContext)
  const { currentUser } = useContext(CurrentUserContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [appointment, setAppointment] = useState<Appointment>(null)
  const [activeTab, setActiveTab] = useState<string>('0')
  const [availableTimeslots, setAvailableTimeslots] = useState([])
  const [touchedRecurringSection, setTouchedRecurringSection] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [multipleRecurringAppointmentsFlow, setMultipleRecurringAppointmentsFlow] = useState(false)
  const {
    answers,
    temporalFiles,
    documentSelections,
    actions: { updateFileUploadState, resetTemporalFilesState, loadTemporalFiles }
  } = useCQStateValue()

  const shouldValidate = useMemo(
    () => currentUser?.userRole?.shipperId && currentUser?.userRole?.audience === INTERNAL,
    [currentUser]
  )

  const { t } = useTranslation()

  useEffect(() => {
    selectedEvent?.id &&
      !appointment &&
      authenticatedFetch({
        path: `/appointments/${selectedEvent.id}.json`
      }).then(
        ([
          {
            id,
            facilityId,
            dock,
            createdAt,
            updatedAt,
            arrivalTime,
            appointmentTypeId,
            createdByAuditUserName,
            confirmationId,
            schedulerId,
            purchaseOrders,
            answersAttributes,
            customLabels,
            readOnly,
            checkinId,
            facilityName,
            recurringAppointmentBlueprintId,
            recurringAppointmentBlueprint,
            equipmentTypeId,
            carrierId,
            dockTime
          },
          status
        ]) => {
          if ([304, 200].includes(status)) {
            // Whitlisting fields to accept from the server. Ideally we are refactoring the appointment controller in the near future.
            setAppointment({
              id,
              facilityId,
              createdAt: new Date(createdAt),
              updatedAt: new Date(updatedAt),
              arrivalTime: new Date(arrivalTime),
              dockTime: dockTime ? new Date(dockTime) : undefined,
              appointmentTypeId,
              createdByUserName: createdByAuditUserName,
              schedulerId,
              dockType: dock?.dockType,
              dockName: dock?.name,
              purchaseOrdersAttributes: purchaseOrders,
              confirmationId,
              answersAttributes,
              readOnly,
              checkinId,
              facilityName,
              recurringAppointmentBlueprintId,
              recurringAppointmentBlueprint,
              carrierId,
              recurringDays:
                recurringAppointmentBlueprint?.schedule?.rrules?.length > 0
                  ? recurringAppointmentBlueprint?.schedule.rrules[0].validations.day
                  : [],
              endDate: recurringAppointmentBlueprint?.endDate
                ? new Date(recurringAppointmentBlueprint?.endDate)
                : undefined,
              equipmentTypeId
            })

            setCustomLabels({ ...INITIAL_CUSTOM_FIELD_NAMES, ...customLabels })
          }
        }
      )
  }, [appointment, selectedEvent, setCustomLabels])

  useEffect(() => {
    if (!appointment || isShipperUser(currentUser)) {
      return
    }
    const dateParam = `date=${appointment.arrivalTime.toISOString()}`
    const dockTypeParam = appointment?.dockType
      ? `&equipment_type_id=${appointment?.equipmentTypeId}`
      : ''
    const appointmentTypeParam = `&appointment_type_id=${appointment.appointmentTypeId}`

    const availableTimeSlotParams = `${dateParam}${dockTypeParam}${appointmentTypeParam}`
    authenticatedFetch({
      path: `/facilities/${appointment.facilityId}/available_shipper_timeslots.json?${availableTimeSlotParams}`
    }).then(([json]) => {
      setAvailableTimeslots(
        json.filter(({ available }) => available).map(({ timeSlot }) => timeSlot)
      )
    })
  }, [appointment, currentUser])

  useEffect(() => {
    if (appointment?.recurringAppointmentBlueprintId) {
      const schedule = appointment.schedule || appointment.recurringAppointmentBlueprint.schedule

      schedule.start_time = DateTime.fromJSDate(appointment.arrivalTime).toISO()
      schedule.rrules[0].until =
        appointment.endDate &&
        DateTime.fromJSDate(appointment.endDate).set({ hour: 23, minutes: 59, seconds: 59 }).toISO()
      schedule.rrules[0].validations.day = appointment.recurringDays

      setAppointment({
        ...appointment,
        schedule
      })
    }
  }, [appointment?.arrivalTime, appointment?.endDate, appointment?.recurringDays])

  const formErrors = (): { label: string; status: boolean }[] => {
    const errors = [
      {
        label: `${t('Common.Fields.PurchaseOrderIdentifiers.Text')} ${t(
          'Appointments.EditAppointmentModal.Validations.FieldMustBePresent.Text'
        )}`,
        status:
          appointment.purchaseOrdersAttributes.length > 0 ||
          !!appointment.recurringAppointmentBlueprintId
      },
      {
        label: t('Appointments.EditAppointmentModal.Validations.FacilityMustBeSelected.Text'),
        status: !!appointment.facilityId
      },
      {
        label: t('Appointments.EditAppointmentModal.Validations.ArrivalMustBeSet.Text'),
        status: !!appointment.arrivalTime
      },
      {
        label: t('Appointments.EditAppointmentModal.Validations.SchedulerMustBeSelected.Text'),
        status: !!appointment.schedulerId || !!appointment.recurringAppointmentBlueprintId
      },
      {
        label: t('Appointments.EditAppointmentModal.Validations.AppointmentAutomatedCreated.Text'),
        status: !appointment.readOnly
      },
      {
        label: `${t('Common.Fields.PurchaseOrderIdentifiers.Text')} ${t(
          'Appointments.EditAppointmentModal.Validations.FieldCantBeEmpty.Text'
        )}`,
        status:
          appointment.purchaseOrdersAttributes.filter(({ _destroy }) => {
            return !_destroy
          }).length > 0 || !!appointment.recurringAppointmentBlueprintId
      },
      {
        label: `${t('Appointments.EditAppointmentModal.Validations.TimeSlotFull.Text')}`,
        status: isShipperUser(currentUser)
          ? true
          : availableTimeslots.some(momentTimeslot => {
              return moment(momentTimeslot).isSame(moment(appointment.arrivalTime), 'minute')
            })
      }
    ]

    if (shouldValidate) {
      errors.push({
        label: `${t('Appointments.EditAppointmentModal.Errors.CustomQuestions.Text')}`,
        status: !(answers.filter(a => a.error).length > 0)
      })

      const fileTypeAnswers = answers.filter(a => a.question.answerType === DOCUMENT_TYPE)

      if (fileTypeAnswers.length > 0) {
        errors.push({
          label: `${t('Appointments.EditAppointmentModal.Errors.FilesRequired.Text')}`,
          status: !fileTypeAnswers.every(a =>
            checkFileQuestionError(
              a.question,
              a.permission,
              temporalFiles,
              a.attachedDocuments || [],
              documentSelections,
              a.id
            )
          )
        })
      }
    }

    return errors
  }

  const updateAppointment = async () => {
    setLoading(true)
    const body = {
      appointment: {
        ...appointment,
        purchaseOrdersAttributes: appointment.purchaseOrdersAttributes.map(
          (order: { identifier: string; id: string }) => ({
            ...order,
            identifier: trim(order.identifier)
          })
        ),
        ...(answers && { answersAttributes: answers }),
        schedule: null
      }
    }

    const [json, status] = await appointmentService.update(appointment.id, body)
    setLoading(false)

    if (status === 200) {
      fancyToast(
        {
          info: t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.Appointment.Text'),
            action: t('Common.Actions.Updated.Text')
          })
        },
        status
      )
      onCloseModal()
    } else {
      fancyToast(json, status)
    }
  }

  const preloadFiles = async answers => {
    return await Promise.all(
      answers.map(async answer => {
        if (answer?.attachedDocuments && answer?.attachedDocuments?.length > 0) {
          const res = await Promise.all(
            answer?.attachedDocuments?.length &&
              answer?.attachedDocuments.map(async document => {
                const file: any = await fetch(
                  `${window?.location?.href}${document.documentSecureUrl}`
                )
                  .then(r => r.blob())
                  .then(blobFile => {
                    return new File([blobFile], getFileName(document.documentSecureUrl), {
                      type: blobFile.type
                    })
                  })

                if (file) {
                  file.path = getFileName(document.documentSecureUrl)
                  const files = []
                  files.push(file)
                  loadTemporalFiles(answer.questionId, files, true)
                  return { file: 'done' }
                }
                return { file: 'nofile' }
              })
          )
          return { status: res.every((r: any) => r.file === 'done') ? 'saved' : 'error' }
        }

        return { status: 'empty' }
      })
    )
  }

  const updateRecurringAppointment = async (customAnswers?) => {
    setLoading(true)
    const parsedAnswers = answers.map(({ id, ...keepAttrs }) => keepAttrs)
    const [appointmentDB] = await appointmentService.get(appointment.id)
    const [json, status] = await recurringAppointmentBlueprintService.update(
      {
        ...appointment,
        startDate: DateTime.fromJSDate(appointment.arrivalTime).toISO(),
        endDate:
          appointment.endDate &&
          DateTime.fromJSDate(appointment.endDate)
            .set({ hour: 23, minutes: 59, seconds: 59 })
            .toISO(),
        recurringAppointmentBlueprint: undefined,
        recurringAppointmentBlueprintId: undefined,
        recurringDays: undefined,
        id: undefined,
        purchaseOrdersAttributes: appointment?.purchaseOrdersAttributes
          .filter(po => po._destroy !== true)
          .map(po => ({
            ...po,
            id: null
          })),
        ...(answers && {
          answersAttributes: customAnswers?.length ? customAnswers : parsedAnswers
        })
      },
      appointment.recurringAppointmentBlueprintId,
      appointmentDB.arrivalTime
    )
    setLoading(false)
    if (status === 200) {
      fancyToast(
        {
          info: t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.Appointment.Text'),
            action: t('Common.Actions.Updated.Text')
          })
        },
        status
      )
      setOpenConfirmationModal(false)
      onCloseModal()
    }
  }

  const initMultipleRecurringAppointmentsFlow = async () => {
    setMultipleRecurringAppointmentsFlow(true)
    setLoading(true)
    let selectedAnswers = []
    if (documentSelections.length) {
      documentSelections.forEach(({ documentId, questionId }) =>
        answerService.removeFile(documentId, questionId)
      )
      selectedAnswers = answers.map(answer => {
        if (answer?.attachedDocuments?.length) {
          const updatedDocuments = answer.attachedDocuments.filter(document => {
            const foundRemovedDocument = documentSelections.find(
              doc => doc.documentId === document.id
            )
            if (foundRemovedDocument?.documentId !== document.id) {
              return { ...document }
            }
          })
          return { ...answer, attachedDocuments: updatedDocuments }
        }
        return { ...answer }
      })
    }

    if (
      !selectedAnswers?.length &&
      answers.every(answer => answer?.attachedDocuments === null) &&
      temporalFiles?.length
    ) {
      return updateFileUploadState(true)
    }
    const savedToTemporalArray = await preloadFiles(
      selectedAnswers?.length ? selectedAnswers : answers
    )

    if (
      !selectedAnswers.length &&
      !temporalFiles.length &&
      savedToTemporalArray.every((e: any) => e.status === 'empty')
    ) {
      return updateRecurringAppointment()
    }
    if (savedToTemporalArray.some((e: any) => e.status === 'saved')) {
      return updateFileUploadState(true)
    }

    return updateRecurringAppointment()
  }

  const updateAppointmentType = () => {
    if (documentSelections.length) {
      documentSelections.forEach(({ documentId, questionId }) =>
        answerService.removeFile(documentId, questionId)
      )
    }
    if (temporalFiles.length) {
      return updateFileUploadState(true)
    }
    return updateAppointment()
  }

  const filterSelectedFiles = () =>
    answers.map(answer => {
      if (answer?.attachedDocuments?.length) {
        const updatedDocuments = answer.attachedDocuments.filter(document => {
          const foundRemovedDocument = documentSelections.find(
            doc => doc.documentId === document.id
          )
          if (foundRemovedDocument?.documentId !== document.id) {
            return { ...document }
          }
        })
        return { ...answer, attachedDocuments: updatedDocuments }
      }
      return { ...answer }
    })
  useEffect(() => {
    if (
      temporalFiles.length &&
      temporalFiles.every(tf => tf?.uploadState === 'saved') &&
      multipleRecurringAppointmentsFlow
    ) {
      const finalAnswers = filterSelectedFiles().map(({ id, ...keepAttrs }) => keepAttrs)
      if (documentSelections.length) {
        updateRecurringAppointment(finalAnswers)
        return () => {}
      }
      if (!documentSelections.length) {
        updateRecurringAppointment(finalAnswers)
        return () => {}
      }
      return () => {}
    }
    if (temporalFiles.length && temporalFiles.every(tf => tf?.uploadState === 'saved')) {
      if (appointment?.recurringAppointmentBlueprintId) {
        updateAppointment()
      } else {
        updateAppointment()
      }
      resetTemporalFilesState()
    }
  }, [temporalFiles])

  const onCloseModal = () => {
    resetTemporalFilesState()
    close()
  }

  if (!appointment) {
    return <></>
  }

  return (
    <>
      <Modal
        size="auto"
        unstable_ModalBackdropScroll
        onClose={onCloseModal}
        isOpen={isOpen}
        overrides={{
          Dialog: { style: { width: '100%', maxWidth: '1230px' } },
          Close: {
            style: ({ $theme }) => ({
              right: $theme.sizing.scale800,
              top: $theme.sizing.scale800
            })
          }
        }}>
        <ModalHeader>
          {t('Appointments.EditAppointmentModal.Header.Text')} {appointment?.confirmationId}
        </ModalHeader>
        <ModalBody>
          <FlexGrid flexGridRowGap="scale800" flexGridColumnCount={[1, 1, 2]} flexDirection="row">
            <FlexGridItem
              flex={[1, 1, 2]}
              position="relative"
              display="flex"
              alignItems="start"
              justifyContent="start">
              <FlexGrid>
                <FlexGridItem>
                  <FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap="scale200">
                    <FlexGridItem>
                      <CustomLabelFormControl
                        customLabelKey="purchaseOrderIdentifiers"
                        recurring={!!appointment.recurringAppointmentBlueprintId}>
                        <PurchaseOrderIdentifiers record={appointment} setRecord={setAppointment} />
                      </CustomLabelFormControl>
                    </FlexGridItem>
                    <FlexGridItem>
                      <FormControl
                        label={t('Appointments.EditAppointmentModal.Fields.Facility.Label.Text')}>
                        <Input
                          value={appointment.facilityName}
                          overrides={{
                            Input: {
                              style: () => ({
                                userSelect: 'none'
                              })
                            }
                          }}
                        />
                      </FormControl>
                    </FlexGridItem>
                  </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                  <CustomLabelFormControl
                    customLabelKey="scheduler"
                    recurring={!!appointment.recurringAppointmentBlueprintId}>
                    <CyberAutocomplete
                      record={appointment}
                      setRecord={setAppointment}
                      indexName="scheduler"
                    />
                  </CustomLabelFormControl>
                </FlexGridItem>
                <FlexGridItem>
                  <FormControl
                    label={t('Appointments.EditAppointmentModal.Fields.Carrier.Label.Text')}>
                    <CyberAutocomplete
                      record={appointment}
                      setRecord={setAppointment}
                      indexName="carrier"
                      placeholder={t(
                        'Appointments.EditAppointmentModal.Fields.Carrier.Placeholder.Text'
                      )}
                    />
                  </FormControl>
                </FlexGridItem>
                <FlexGridItem>
                  <ArrivalTime record={appointment} setRecord={setAppointment} />
                </FlexGridItem>
                {currentUser?.dockAssignmentActive && (
                  <FlexGridItem>
                    <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200">
                      <FlexGridItem>
                        <FormControl label={t('Common.Fields.DockName.Text')}>
                          <Input value={appointment.dockName} disabled />
                        </FormControl>
                      </FlexGridItem>
                      <FlexGridItem>
                        <FormControl label={t('Common.Fields.DockTime.Text')}>
                          <Input
                            value={
                              appointment.dockTime
                                ? DateTime.fromJSDate(appointment.dockTime).toFormat('h:mm a')
                                : ''
                            }
                            disabled
                          />
                        </FormControl>
                      </FlexGridItem>
                    </FlexGrid>
                  </FlexGridItem>
                )}
                {appointment?.equipmentTypeId && (
                  <FlexGridItem>
                    <DockType
                      record={appointment}
                      setRecord={setAppointment}
                      selectedEvent={appointment}
                    />
                  </FlexGridItem>
                )}
                {appointment.recurringAppointmentBlueprintId && (
                  <FlexGridItem>
                    <RecurringFields
                      record={appointment}
                      setRecord={setAppointment}
                      setTouchedRecurringSection={setTouchedRecurringSection}
                      recurring={!!appointment?.recurringAppointmentBlueprintId}
                      isUpdate={true}
                    />
                  </FlexGridItem>
                )}
                <ReadOnlyAnswers
                  record={appointment as any}
                  setRecord={setAppointment}
                  shouldValidate={shouldValidate}
                />
              </FlexGrid>
            </FlexGridItem>
            <FlexGridItem flex={[null, null, '1']}>
              <TabsWrapper>
                <Tabs
                  onChange={({ activeKey }) => {
                    setActiveTab(activeKey as any)
                  }}
                  activeKey={activeTab}>
                  <Tab title={t('Appointments.EditAppointmentModal.Milestones.Header.Text')}>
                    <Milestones appointment={appointment} setAppointment={setAppointment} />
                  </Tab>
                  <Tab title={t('Appointments.EditAppointmentModal.AuditLogs.Header.Text')}>
                    <AuditLogs appointment={appointment} />
                  </Tab>
                </Tabs>
                <FlexGrid>
                  <FlexGridItem justifyContent="space-between" display="flex" height="48px">
                    <CancelButton
                      appointment={appointment}
                      close={onCloseModal}
                      buttonProps={{ disabled: appointment.readOnly }}
                    />

                    <ErrorMessageButton
                      label={t('Appointments.EditAppointmentModal.UpdateButton.Text')}
                      errors={formErrors()}
                      buttonProps={{
                        isLoading: loading,
                        onClick: appointment.recurringAppointmentBlueprintId
                          ? () => setOpenConfirmationModal(true)
                          : () => updateAppointmentType()
                      }}
                    />
                  </FlexGridItem>
                </FlexGrid>
              </TabsWrapper>
            </FlexGridItem>
          </FlexGrid>
        </ModalBody>
      </Modal>
      <ConfirmModificationRecurringAppointmentModal
        isOpen={openConfirmationModal}
        close={() => {
          setOpenConfirmationModal(false)
        }}
        closeParent={onCloseModal}
        appointment={appointment}
        parentLoading={loading}
        type="UPDATE"
        touchedRecurringSection={touchedRecurringSection}
        onMultipleRecurringAppointmentsUpdate={initMultipleRecurringAppointmentsFlow}
        updateOnlyOneAppointment={updateAppointment}
      />
    </>
  )
}
export default EditAppointmentModal
