import React from 'react'
import NotificationTypesForm from './notification-types'
import SubscriptionsForm from './subscriptions'

const NotificationPreferenceForm = () => {
  return (
    <div>
      <NotificationTypesForm />
      <SubscriptionsForm />
    </div>
  )
}

export default NotificationPreferenceForm
