export const SET_SAVING = 'SET_SAVING'
export const SET_LOADING = 'SET_LOADING'
export const SET_ROLES = 'SET_ROLES'
export const SET_ROLE_TO_UPDATE = 'SET_ROLE_TO_UPDATE'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const SEARCH = 'SEARCH'
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE'
export const SELECT_ROLE = 'SELECT_ROLE'
export const SET_MODAL_STATE = 'SET_MODAL_STATE'

export const userRolesReducer = (state, action) => {
  switch (action.type) {
    case SET_SAVING: {
      return {
        ...state,
        saving: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case SET_ROLES: {
      const roles = action.payload
      return {
        ...state,
        items: roles
      }
    }
    case SEARCH: {
      return {
        ...state,
        items: action.payload,
        isModalActive: false
      }
    }
    case UPDATE_ROLE: {
      const updatedRole = action.payload

      const currentRoles = [...state.items]
      const indexOfUpdatedRole = currentRoles.findIndex(role => role.id === updatedRole.id)
      currentRoles[indexOfUpdatedRole] = updatedRole

      return {
        ...state,
        items: currentRoles,
        isModalActive: false
      }
    }
    case SET_MODAL_TYPE: {
      return {
        ...state,
        modalType: action.payload,
        isModalActive: true
      }
    }
    case SELECT_ROLE: {
      return {
        ...state,
        selectedRole: action.payload
      }
    }
    case SET_MODAL_STATE: {
      return {
        ...state,
        isModalActive: action.payload
      }
    }
    case SET_ROLE_TO_UPDATE: {
      return {
        ...state,
        roleToUpdate: action.payload.role,
        isModalActive: action.payload.isModalActive
      }
    }
    default:
      return state
  }
}

const finalUserRolesReducer = ({ roles }, action) => {
  return {
    roles: userRolesReducer(roles, action)
  }
}

export default finalUserRolesReducer
