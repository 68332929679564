import React from 'react'
import { Drawer, ANCHOR } from 'baseui/drawer'
import {
  FLAG_MODAL_BUTTON,
  NEW_TAB,
  REJECT_MODAL_BUTTON,
  STOP_MODAL_BUTTON,
  useYardJockeyContext
} from '../../contexts/yard-jockey.context'
import { Block } from 'baseui/block'
import { primitives } from '../../homepage/custom-theme'
import {
  YardJockeyDrawerPill,
  YardJockeyDrawerStyles,
  YardJockeyOptionStyles,
  YardJockeyOptionText
} from './YardJockey.styled'
import { useTranslation } from 'react-i18next'
import { FlagPennant, HandPalm, Prohibit } from '@phosphor-icons/react'

const YardJockeyDrawer = () => {
  const {
    activeTab,
    drawerOpen,
    drawerTask,
    actions: { setDrawerOpen, setNotesModalOpen }
  } = useYardJockeyContext()

  const { t } = useTranslation()

  const handleReject = () => {
    const updatedTask = {
      ...drawerTask,
      userId: null,
      startedAt: null
    }

    setNotesModalOpen(true, updatedTask, REJECT_MODAL_BUTTON)
  }

  const handleStop = () => {
    const updatedTask = {
      ...drawerTask,
      startedAt: null
    }

    setNotesModalOpen(true, updatedTask, STOP_MODAL_BUTTON)
  }

  const handleFlag = () => {
    const updatedTask = {
      ...drawerTask,
      flag: true
    }

    setNotesModalOpen(true, updatedTask, FLAG_MODAL_BUTTON)
  }

  return (
    <Drawer
      isOpen={drawerOpen}
      closeable={true}
      anchor={ANCHOR.bottom}
      autoFocus
      overrides={YardJockeyDrawerStyles}
      onClose={() => setDrawerOpen(false)}>
      <YardJockeyDrawerPill />
      <Block
        display="flex"
        justifyContent="left"
        alignItems="center"
        marginBottom="16px"
        height="62px"
        overrides={YardJockeyOptionStyles}
        onClick={handleReject}>
        <Prohibit size={14} color={primitives.primary} />
        <YardJockeyOptionText>{t('YardJockey.Drawer.Options.Reject.Text')}</YardJockeyOptionText>
      </Block>

      {activeTab !== NEW_TAB && drawerTask && drawerTask?.startedAt && (
        <Block
          display="flex"
          justifyContent="left"
          alignItems="center"
          marginBottom="16px"
          height="62px"
          overrides={YardJockeyOptionStyles}
          onClick={handleStop}>
          <HandPalm size={14} color={primitives.primary} />
          <YardJockeyOptionText>{t('YardJockey.Drawer.Options.Stop.Text')}</YardJockeyOptionText>
        </Block>
      )}

      {activeTab !== NEW_TAB && drawerTask && !drawerTask?.flag && (
        <Block
          display="flex"
          justifyContent="left"
          alignItems="center"
          marginBottom="16px"
          height="62px"
          onClick={handleFlag}>
          <FlagPennant size={14} color={primitives.primary} />
          <YardJockeyOptionText>{t('YardJockey.Drawer.Options.Flag.Text')}</YardJockeyOptionText>
        </Block>
      )}
    </Drawer>
  )
}

export default YardJockeyDrawer
