import styled from 'styled-components'

export const PanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const SecondAction = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  text-transform: capitalize;
`
