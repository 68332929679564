import React, { useContext, useEffect } from 'react'
import { Redirect, Route, NavLink, useHistory } from 'react-router-dom'
import { OrdersProvider } from 'components/contexts/orders.context'
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem
} from 'baseui/header-navigation'
import { CalendarBlank, ListChecks, BatteryFull } from '@phosphor-icons/react'
import Trailers from '../YardTrailers'
import Tasks from 'components/pages/YardTasks/index'
import Schedule from '../YardSchedule'
import { OrderPreferencesProvider } from 'components/contexts/order-preferences.context'
import { useStyletron } from 'baseui'
import { YardHeaderNavegationOverrides, YardNavLinkClassName } from './Yard.styled'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from '../../homepage/current-user-context'

const SCHEDULE_URL = '/yard/schedule'
const TRAILERS_URL = '/yard/trailers'
const TASKS_URL = '/yard/tasks'
const YARD_URL = '/yard'

const Yard = () => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const { currentUser } = useContext(CurrentUserContext)

  const history = useHistory()

  useEffect(() => {
    if (currentUser?.yardManagementActive === false) {
      history.push('/')
    }
  }, [currentUser])

  return (
    <OrderPreferencesProvider>
      <HeaderNavigation overrides={YardHeaderNavegationOverrides()}>
        <StyledNavigationList $align={ALIGN.center} />
        <StyledNavigationList $align={ALIGN.right}>
          <StyledNavigationItem>
            <NavLink className={css(YardNavLinkClassName(theme))} to={TRAILERS_URL}>
              <BatteryFull />
              {t('YardPage.Navbar.Trailers.Text')}
            </NavLink>
          </StyledNavigationItem>
          <StyledNavigationItem>
            <NavLink className={css(YardNavLinkClassName(theme))} to={SCHEDULE_URL}>
              <CalendarBlank />
              {t('YardPage.Navbar.Schedule.Text')}
            </NavLink>
          </StyledNavigationItem>
          <StyledNavigationItem>
            <NavLink className={css(YardNavLinkClassName(theme))} to={TASKS_URL}>
              <ListChecks />
              {t('YardPage.Navbar.Tasks.Text')}
            </NavLink>
          </StyledNavigationItem>
        </StyledNavigationList>
      </HeaderNavigation>
      <Route exact path={TRAILERS_URL} component={Trailers} />
      <Route exact path={SCHEDULE_URL} component={Schedule} />
      <Route exact path={TASKS_URL} component={Tasks} />
      <Route exact path={YARD_URL}>
        <Redirect to={TRAILERS_URL} />
      </Route>
    </OrderPreferencesProvider>
  )
}

export default Yard
