import React from 'react'
import { useStyletron } from 'baseui'
import {
  HeaderNavigation,
  StyledNavigationItem,
  StyledNavigationList,
  ALIGN
} from 'baseui/header-navigation'
import { HeadingSmall, ParagraphXSmall } from 'baseui/typography'

const VHeader = ({
  title,
  subtitle,
  leftItems = [],
  centerItems = [],
  rightItems = [],
  overrides
}) => {
  const [css, theme] = useStyletron()
  return (
    <HeaderNavigation
      overrides={{
        Root: {
          style: ({ $theme }) => {
            return {
              paddingTop: $theme.sizing.scale600,
              paddingBottom: $theme.sizing.scale600,
              paddingLeft: $theme.sizing.scale600,
              paddingRight: $theme.sizing.scale600,
              marginLeft: '-24px',
              marginRight: '-24px',
              borderBottomColor: $theme.colors.transparent,
              marginBottom: $theme.sizing.scale800,
              backgroundColor: $theme.colors.white,
              boxShadow:
                '#fff 1000px 0px, #fff -1000px 0, #dbe6ff 1000px 1px,  #dbe6ff -1000px 1px,  #dbe6ff 0 1px',
              [theme.mediaQuery.large]: {
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: 0,
                marginRight: 0
              }
            }
          }
        },
        ...overrides
      }}>
      <StyledNavigationList $align={ALIGN.left}>
        <StyledNavigationItem className={css({ paddingLeft: '0 !important' })}>
          {subtitle && (
            <ParagraphXSmall marginTop={0} marginBottom={0} color={theme.colors.primary}>
              {subtitle}
            </ParagraphXSmall>
          )}
          <HeadingSmall
            marginTop={`${subtitle ? '0' : theme.sizing.scale500}`}
            marginBottom={`${subtitle ? '0' : theme.sizing.scale500}`}
            color={theme.colors.primary}
            {...overrides?.title}>
            {title}
          </HeadingSmall>
        </StyledNavigationItem>
        {leftItems && leftItems.length > 0
          ? leftItems.map((item, index) => (
              <StyledNavigationItem key={index}>{item}</StyledNavigationItem>
            ))
          : ''}
      </StyledNavigationList>
      <StyledNavigationList
        $align={ALIGN.center}
        className={css({
          display: 'none',
          [theme.mediaQuery.large]: {
            display: 'inline'
          }
        })}>
        {centerItems && centerItems.length > 0
          ? centerItems.map((item, index) => (
              <StyledNavigationItem key={index}>{item}</StyledNavigationItem>
            ))
          : ''}
      </StyledNavigationList>
      {rightItems && rightItems.length > 0 ? (
        <StyledNavigationList $align={ALIGN.right} className={css({})}>
          {rightItems.map((item, index) => (
            <StyledNavigationItem key={index}>{item}</StyledNavigationItem>
          ))}
        </StyledNavigationList>
      ) : (
        ''
      )}
    </HeaderNavigation>
  )
}
export default VHeader
