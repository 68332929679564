import http from 'components/utils/authenticated-fetch'

class UnitOfMeasureService {
  getUrl = (unitOfMeasureId?: string) =>
    `/unit_of_measures${unitOfMeasureId ? `/${unitOfMeasureId}.json` : '.json'}`

  getUnitOfMeasures = (): Promise<[any, number]> => {
    const url = this.getUrl()
    return http({
      path: `${url}`
    })
  }
}

export const unitOfMeasureService = new UnitOfMeasureService()
