import React from 'react'

import Checkbox from 'components/ui/generic/Checkbox'
import { CurrentUserContext } from '../../homepage/current-user-context'

interface NotificationOptionProps {
  title: string
  preference: string
  disabled?: boolean
}

const NotificationOption = (props: NotificationOptionProps) => {
  const { title, preference, disabled = false } = props

  return (
    <CurrentUserContext.Consumer>
      {({ currentUser, setCurrentUser }) => {
        return (
          <Checkbox
            disabled={disabled}
            checked={
              currentUser.notificationPreferenceAttributes &&
              currentUser.notificationPreferenceAttributes[preference]
            }
            onChange={e =>
              setCurrentUser({
                ...currentUser,
                notificationPreferenceAttributes: {
                  ...currentUser.notificationPreferenceAttributes,
                  [preference]: e.currentTarget.checked
                }
              })
            }
            label={title}
          />
        )
      }}
    </CurrentUserContext.Consumer>
  )
}

export default NotificationOption
