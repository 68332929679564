import React from 'react'
import { useTranslation } from 'react-i18next'

import Accordion from 'components/ui/generic/Accordion'
import CyberSearchToggleRefinement from './toggle-refinement'

const CyberSearchBooleanRefinement = ({ attributes, setAppliedFilters }) => {
  const { t } = useTranslation()

  return (
    <Accordion title={t('Common.CyberSearchBooleanRefinement.Panel.Title.Text')}>
      {attributes.map((attribute, index) => {
        return (
          <CyberSearchToggleRefinement
            key={index}
            value
            attribute={attribute}
            setAppliedFilters={setAppliedFilters}
          />
        )
      })}
    </Accordion>
  )
}

export default CyberSearchBooleanRefinement
