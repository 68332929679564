import { styled } from 'baseui'

export const DraggableColumnGrid = styled('div', ({ $theme }) => ({
  display: 'flex',
  gridTemplateColumns: '1fr 1fr',
  margin: 0,
  width: '100%',
  gap: $theme.sizing.scale600,
  flexDirection: 'column',
  boxSizing: 'border-box',
  [$theme.mediaQuery.medium]: {
    flexDirection: 'row'
  }
}))

export const CreateCustomCancelReasonButtonContainer = styled('div', () => ({
  textAlign: 'right'
}))
