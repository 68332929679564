import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

import { MagnifyingGlass, CircleNotch } from '@phosphor-icons/react'

import { useStyletron } from 'baseui'
import { AddButton } from 'components/components/AddButton'
import { InviteCarrierUserModal } from 'components/pages/CarriersUsers/InviteCarrierUserModal'
import { useCarrierUserContext } from 'components/contexts/carrier-user.context'
import { useTranslation } from 'react-i18next'

import Input from 'components/ui/generic/Input'

export const CarrierUserInstantSearchInput = connectSearchBox(
  ({ currentRefinement, refine, isSearchStalled }) => {
    const [css, theme] = useStyletron()
    const {
      actions: { setInviteModalState, closeCarrierUserModal }
    } = useCarrierUserContext()

    const { t } = useTranslation()

    const onClose = () => {
      closeCarrierUserModal()
      refine()
    }

    return (
      <>
        <div data-testid="carrier-users-search-bar">
          <InviteCarrierUserModal onClose={onClose} refine={refine} />
          <div className={css({ display: 'flex', gap: theme.sizing.scale800 })}>
            <Input
              startEnhancer={
                isSearchStalled ? (
                  <CircleNotch
                    className={css({
                      animationDuration: '800ms',
                      animationIterationCount: 'infinite',
                      animationTimingFunction: 'linear',
                      animationName: {
                        from: {
                          transform: 'rotate(0deg)'
                        },
                        to: {
                          transform: 'rotate(360deg)'
                        }
                      }
                    })}
                    size={theme.sizing.scale600}
                  />
                ) : (
                  <MagnifyingGlass />
                )
              }
              size="compact"
              placeholder={t('Settings.CarrierUsers.Search.Placeholder.Text')}
              onChange={evt => refine(evt.currentTarget.value)}
              autoComplete="off"
              name="search[name]"
              value={currentRefinement}
            />
            <AddButton
              title={t('Settings.CarrierUsers.Search.CreateCarrier.Text')}
              onClick={() => setInviteModalState(true)}
            />
          </div>
        </div>
      </>
    )
  }
)
