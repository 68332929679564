import Header from 'components/ui/generic/Header'
import { useTranslation } from 'react-i18next'
import { CarriersProvider } from 'components/contexts/carriers.context'
import React from 'react'
import CarriersTable from './CarriersTable'
import { CarriersInputSearch } from './InputSearchBar'

const Carriers = () => {
  const { t } = useTranslation()
  return (
    <CarriersProvider>
      <Header
        title={t('Settings.SideMenu.Titles.Carriers.Text')}
        rightItems={[<CarriersInputSearch />]}
      />
      <CarriersTable />
    </CarriersProvider>
  )
}

export default Carriers
