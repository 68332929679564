import { ModalFooter, ModalHeader } from 'baseui/modal'
import { primitives } from 'components/homepage/custom-theme'
import styled from 'styled-components'

export const ModalProps = {
  overrides: {
    Dialog: { style: { maxWidth: '460px' } },
    Close: {
      style: ({ $theme }) => ({
        right: $theme.sizing.scale800,
        top: $theme.sizing.scale800
      })
    }
  }
}

export const ModalHeaderStyled = styled(ModalHeader)`
  padding-bottom: 12px;
  border-bottom: 1px solid #dbe6ff;
  margin-bottom: 0px;
`
export const ModalFooterStyled = styled(ModalFooter)`
  padding-top: 12px;
  border-top: 1px solid #dbe6ff;
  margin-top: 0px;
`

export const CardAppointmentWrapper = styled('div')`
  width: 100%;
  margin-bottom: 24px;
`

export const LoadingAppointment = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const FromWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 8px 16px;
`

export const FromLabel = styled.div`
  color: ${primitives.text};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`

export const FromText = styled.div`
  color: ${primitives.primary};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 4px;
  line-height: 16px;
`
