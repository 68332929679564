import { ButtonProps } from 'baseui/button'
import React, { Fragment } from 'react'
import { StatefulTooltip } from 'baseui/tooltip'

import Button from 'components/ui/specific/PrimaryButton'
import { Block } from 'baseui/block'

interface ErrorMessageButtonProps {
  label: string
  errors: { label: string; status: boolean }[]
  buttonProps?: ButtonProps
  statefulTooltipProps?: any
  checkPermissions?: any
  placement?: string
  disabled?: boolean
}

const ErrorMessageButton = (props: ErrorMessageButtonProps) => {
  const { label, errors, buttonProps, statefulTooltipProps, checkPermissions, disabled } = props

  const errorsPresent = errors.some(error => {
    return !error.status
  })

  return (
    <StatefulTooltip
      triggerType="hover"
      content={
        errorsPresent &&
        errors
          .filter(e => !e.status)
          .map(({ label }, i) => {
            return (
              <Fragment key={i}>
                - {label}
                <br />
              </Fragment>
            )
          })
      }
      placement={props.placement || 'right'}
      showArrow
      {...statefulTooltipProps}>
      <Block minWidth={['100%', '100%', 'auto']}>
        <Button
          minWidth={'100%'}
          disabled={errorsPresent || checkPermissions || disabled}
          {...buttonProps}>
          {label}
        </Button>
      </Block>
    </StatefulTooltip>
  )
}

export default ErrorMessageButton
