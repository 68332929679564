import React, { useContext, useEffect, useState } from 'react'
import { facilityService } from 'components/services/facility.service'
import { LabelSmall } from 'baseui/typography'
import { CurrentUserContext } from '../../../../homepage/current-user-context'
import StyledSpinner from '../../../../shared/styled-spinner'
import BlockCountsNotShared from './BlockCountsNotShared'
import BlockCountsShared from './BlockCountsShared'
import { DockBlockWithExtraAttributes } from './BlackoutModal'

const checkIfCapacityShared = (
  dockBlock: DockBlockWithExtraAttributes,
  equipmentCapacityShared?: boolean,
  selectedEventId?: string
) => {
  if (selectedEventId && dockBlock.blockCountsNotSharedHelper) {
    return false
  }

  if (!selectedEventId && !equipmentCapacityShared) {
    return false
  }

  return true
}

const SelectBlockCounts = ({ facilityOption, dockBlock, setDockBlock, selectedEvent }) => {
  const [facility, setFacility] = useState(null)
  const [loading, setLoading] = useState(true)
  const { assignedEquipmentTypes } = useContext(CurrentUserContext)

  useEffect(() => {
    if (facilityOption) {
      setLoading(true)
      facilityService
        .getFacility(facilityOption.id)
        .then(facility => {
          setFacility(facility)

          if (facility.appointmentPreference.equipmentCapacityShared) {
            const blockCountsAttributes = []
            assignedEquipmentTypes.forEach(eqTypes => {
              blockCountsAttributes.push({
                count: 0,
                equipmentTypeId: eqTypes.id,
                appointmentTypeId: null
              })
            })
            setDockBlock({
              ...dockBlock,
              blockCountsAttributes
            })
          } else {
            const blockCountsNotSharedHelper = {}

            facility.appointmentTypes.forEach(appType => {
              blockCountsNotSharedHelper[appType.id] = {
                name: appType.name
              }
              assignedEquipmentTypes.forEach(eqType => {
                blockCountsNotSharedHelper[appType.id][eqType.id] = {
                  count: 0,
                  equipmentTypeId: eqType.id,
                  appointmentTypeId: appType.id,
                  limit:
                    facility.appointmentPreference.dockCapacitiesAttributes.filter(
                      capacity =>
                        capacity.appointmentTypeId === appType.id &&
                        capacity.equipmentTypeId === eqType.id
                    )[0]?.limit || 0
                }
              })
            })
            setDockBlock({
              ...dockBlock,
              blockCountsNotSharedHelper
            })
          }

          setLoading(false)
        })
        .catch(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [facilityOption])

  return loading ? (
    <StyledSpinner />
  ) : (
    <>
      <LabelSmall marginBottom="8px">How many slots are impacted by equipment type?</LabelSmall>
      <SelectBlockCountsCases
        facility={facility || dockBlock.facility}
        dockBlock={dockBlock}
        assignedEquipmentTypes={assignedEquipmentTypes}
        setDockBlock={setDockBlock}
        selectedEvent={selectedEvent}
      />
    </>
  )
}

const SelectBlockCountsCases = ({
  facility,
  dockBlock,
  assignedEquipmentTypes,
  setDockBlock,
  selectedEvent
}) =>
  checkIfCapacityShared(
    dockBlock,
    facility?.appointmentPreference?.equipmentCapacityShared,
    selectedEvent?.id
  ) ? (
    <BlockCountsShared
      facility={facility}
      dockBlock={dockBlock}
      assignedEquipmentTypes={assignedEquipmentTypes}
      setDockBlock={setDockBlock}
    />
  ) : (
    <BlockCountsNotShared
      blockCountsNotSharedHelper={dockBlock?.blockCountsNotSharedHelper}
      setDockBlock={setDockBlock}
    />
  )

const MemoizedSelectBlockCounts = React.memo(SelectBlockCounts)
export default MemoizedSelectBlockCounts
