import React from "react";
import { Value } from "baseui/select";

export const CalendarToggleContext = React.createContext({
  calendarToggles: null,
  setCalendarToggles: null,
  selectedDate: null,
  setSelectedDate: null,
  selectedEvent: null,
  setSelectedEvent: null,
});

// TODO(adenta) these types arnt actually being passed to the child components, gotta figure that out
export interface CalendarToggleProps {
  displayDocks: boolean;
  selectedEventTypes: (0 | 1 | 2 | 3 | 4)[];
  selectedView: Value;
  timeRange: { minTime: Value; maxTime: Value };
  hiddenResourceIds: string[];
  hiddenSchedulerIds: string[];
  hiddenOrganizationIds: string[];
}

export const initialCalendarToggles: CalendarToggleProps = {
  displayDocks: false,
  selectedEventTypes: [0, 1, 2, 3, 4],
  selectedView: [{ label: "Timeline", id: "resourceTimelineDay" }],
  timeRange: {
    minTime: [
      {
        id: "1970-01-01T00:00",
        label: "00:00",
      },
    ],
    maxTime: [{ id: "1970-01-01T24:00", label: "24:00" }],
  },
  hiddenResourceIds: [],
  hiddenSchedulerIds: [],
  hiddenOrganizationIds: [],
};

export const CALENDAR_TOGGLES = "calendarToggles";
