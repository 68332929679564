import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import CarrierModal from 'components/pages/Carriers/CarrierModal'
import { useCarrierUserContext } from 'components/contexts/carrier-user.context'
import { InviteCarrierUserModalForm } from './InviteCarrierUserModalForm'
import InviteCarrierUserModalFooter from './InviteCarrierUserModalFooter'
import { useTranslation } from 'react-i18next'

export const InviteCarrierUserModal = ({ onClose, refine }) => {
  const {
    users: { isInviteModalActive },
    actions: {}
  } = useCarrierUserContext()

  const { t } = useTranslation()

  return (
    <>
      <CarrierModal />
      <Modal onClose={onClose} isOpen={isInviteModalActive}>
        <ModalHeader>{t('Settings.CarrierUsers.InviteCarrierUserModal.Header.Text')}</ModalHeader>
        <ModalBody>
          <InviteCarrierUserModalForm />
        </ModalBody>
        <ModalFooter>
          <InviteCarrierUserModalFooter refine={refine} />
        </ModalFooter>
      </Modal>
    </>
  )
}
