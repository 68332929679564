import React, { useState, useEffect, useContext } from 'react'
import { Button } from 'baseui/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import { Select } from 'baseui/select'
import { FormControl } from 'baseui/form-control'
import { Block } from 'baseui/block'
import authenticatedFetch from '../../../utils/authenticated-fetch'
import { fancyToast } from '../../../utils'
import { CurrentUserContext } from '../../../homepage/current-user-context'
import LinkButton from '../../fields/link-button'
import { CalendarReferenceContext } from '../../../contexts/calendar-reference-context'
import { CalendarToggleContext } from '../../../contexts/calendar-toggle-context'
import AppointmentTypeQuestionsAndAnswers from '../../appointment-type-questions-and-answers'
import StyledSpinner from '../../styled-spinner'
import { SHIPPER } from '../../../models/User'

interface QuickCheckoutProps {
  isOpen: boolean
  close: () => void
  event: any
}

const QuickCheckout = (props: QuickCheckoutProps) => {
  const { isOpen, close, event } = props

  const { selectedDate, setSelectedDate } = useContext(CalendarToggleContext)

  const [appointment, setAppointment] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    !appointment &&
      authenticatedFetch({
        path: `/appointments/${event.id}.json`
      }).then(([json, status]) => {
        if (status === 200) {
          setAppointment(json)
        }
      })
  }, [])

  const updateCheckin = async () => {
    const body = {
      checkin: {
        checkedOutAt: new Date()
      }
    }
    const [json, status] = await authenticatedFetch({
      path: `/checkins/${appointment.checkinId}.json`,
      method: 'PATCH',
      body
    })

    if (status === 200) {
      return true
    }
    fancyToast(json)
    return false
  }

  const updateAppointment = async () => {
    const [json, status] = await authenticatedFetch({
      path: `/appointments/${appointment.id}.json`,
      method: 'PATCH',
      body: {
        appointment
      }
    })

    if (status === 200) {
      return true
    }
    fancyToast(json)
    return false
  }

  const performCheckOut = async () => {
    setLoading(true)

    const appointmentUpdated = await updateAppointment()
    const checkinUpdated = await updateCheckin()

    setLoading(false)

    if (appointmentUpdated && checkinUpdated) {
      fancyToast({ info: 'Truck has successfully been checked out.' }, 200)
      setSelectedDate(new Date(selectedDate))
      close()
    }
  }

  if (!appointment) {
    return <></>
  }

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <ModalHeader>Quick Checkout</ModalHeader>
      <ModalBody>
        <AppointmentTypeQuestionsAndAnswers
          audienceType={SHIPPER}
          {...{ appointment, setAppointment }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={
            !appointment.answersAttributes.every(answer => {
              return !!answer?.response || answer?.documents?.length > 0
            })
          }
          onClick={performCheckOut}
          isLoading={loading}>
          Check-out
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default QuickCheckout
