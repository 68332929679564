import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Paragraph3, ParagraphLarge } from 'baseui/typography'
import { useTasksContext } from 'components/contexts/tasks.context'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import StyledSpinner from 'components/shared/styled-spinner'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'
import Table from 'components/ui/generic/Table'
import Tag from 'components/ui/generic/Tag'
import { Task, TASK_UNASSIGNED } from 'components/models/Task'
import { StatefulPopover } from 'baseui/popover'
import Button from 'components/ui/generic/Button'
import { FileSearch, DotsThree, ArrowRight } from '@phosphor-icons/react'
import Pagination from 'components/components/Pagination'
import { PAGE_SIZE } from 'components/constants/page-size'
import { ActionButtonProps, NotFoundContainer } from './TasksList.styled'

const INITIAL_COLUMNS = [
  { name: 'TrailerNumber', position: 1 },
  { name: 'From', position: 2 },
  { name: 'Arrow', position: 3 },
  { name: 'To', position: 4 },
  { name: 'Status', position: 5 },
  { name: 'AssignedTo', position: 6 },
  { name: 'Notes', position: 7 }
]

const TasksList = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const { state, actions } = useTasksContext()
  const {
    tasks: { results, loading, total },
    pagination: { page }
  } = state
  const { setPage } = actions
  const [cachedColumns, setCachedColumns] = useLocalStorage(`TASK_COLUMNS_BY_USER_AND_ORG`, {})
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  const [shownColumns, setShownColumns] = useState(
    cachedColumns &&
      cachedColumns[currentUser?.shipperId] &&
      cachedColumns[currentUser?.shipperId][currentUser?.id]
      ? cachedColumns[currentUser?.shipperId][currentUser?.id]
      : INITIAL_COLUMNS
  )

  const taskRow = (task: Task) => {
    return [
      <strong
        data-testid="task-list-trailer-number"
        className={task?.trailer?.status === 'full' ? 'blue-line' : 'no-line'}>
        {task.trailer?.number}
      </strong>,
      <Paragraph3>
        {task.fromSlot?.name || task.fromDock?.name || t('YardTask.Kanban.Columns.Unassigned')}
      </Paragraph3>,
      <ArrowRight />,
      <Paragraph3>{task.toSlot?.name || task.toDock?.name}</Paragraph3>,
      <Tag
        closeable={false}
        overrides={{
          Text: {
            style: () => ({
              fontSize: '9px'
            })
          }
        }}>
        {task.trailer?.status}
      </Tag>,
      <a href="#" onClick={() => {}}>
        {task.user?.name}
      </a>,
      task.notes
    ]
  }

  const columnLabelCallback = ({ name }) => {
    return t(`YardTask.Table.Columns.${name}.Label.Text`)
  }

  return (
    <Block width={'100%'}>
      <Block paddingBottom="scale200" minHeight="60vh">
        {loading ? (
          <Block display="flex" justifyContent="center" alignItems="center">
            <StyledSpinner />
          </Block>
        ) : results.length === 0 ? (
          <NotFoundContainer>
            <FileSearch size={theme.sizing.scale2400} />
            <ParagraphLarge>{t('YardTask.Table.NoTaskFound.Text')}</ParagraphLarge>
          </NotFoundContainer>
        ) : (
          <Table
            columns={shownColumns.map(columnLabelCallback)}
            data={results.map(task => taskRow(task))}
          />
        )}
      </Block>
      <Pagination total={Math.ceil(total / PAGE_SIZE) || 1} page={page} onPageChange={setPage} />
    </Block>
  )
}

export default TasksList
