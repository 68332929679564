import Button from 'components/ui/generic/Button'
import { FileUploader } from 'baseui/file-uploader'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Plus } from '@phosphor-icons/react'

const MAX_FILE_SIZE_BYTES = 1024 * 1000 // 1MB

const BulkFileUploader = ({ onFileChange }) => {
  const { t } = useTranslation()

  return (
    <FileUploader
      onDrop={onFileChange}
      accept=".xlsx"
      maxSize={MAX_FILE_SIZE_BYTES}
      overrides={{
        FileDragAndDrop: {
          style: ({ $theme }) => {
            return {
              height: '180px',
              marginTop: '8px',
              backgroundColor: $theme.colors.inputFill,
              position: 'relative',
              borderTopWidth: $theme.borders.borderWidth,
              borderBottomWidth: $theme.borders.borderWidth,
              borderLeftWidth: $theme.borders.borderWidth,
              borderRightWidth: $theme.borders.borderWidth,
              borderTopColor: $theme.colors.inputBorder,
              borderBottomColor: $theme.colors.inputBorder,
              borderLeftColor: $theme.colors.inputBorder,
              borderRightColor: $theme.colors.inputBorder
            }
          }
        },
        ContentMessage: () => (
          <p
            style={{
              fontWeight: 400,
              fontSize: '10px',
              lineHeight: 1.25,
              textAlign: 'center',
              opacity: '0.6'
            }}>
            {t('Common.BulkFileUploader.Label.Text')}
          </p>
        ),
        ButtonComponent: props => (
          <Button
            IconStart={<Plus size={24} />}
            {...props}
            label={t('Common.BulkFileUploader.BrowseFilesButton.Text')}
          />
        )
      }}
    />
  )
}

export default BulkFileUploader
