import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { CyberTable } from 'cyber/table'
import { PurchaseOrderLabelsContext } from 'components/pages/CreateAppointmentForExternalUsers'
import Pagination from 'components/ui/generic/Pagination'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'

const MainTable = ({ appointments: { results = [], total = 0 }, currentPage, setCurrentPage }) => {
  const purchaseOrderLabelsContext = useContext(PurchaseOrderLabelsContext)
  const { purchaseOrderLabels } = purchaseOrderLabelsContext
  const numPages = useMemo(() => Math.ceil(total / 10), [total])
  const { t } = useTranslation()

  const [css] = useStyletron()

  return (
    <>
      <CyberTable
        columns={[
          purchaseOrderLabels?.purchase_order_identifiers
            ? purchaseOrderLabels?.purchase_order_identifiers
            : t('Scheduler.Appointments.Table.Columns.UniqueLoaderIdentifier.Label.Text'),
          t('Scheduler.Appointments.Table.Columns.ConfirmationId.Label.Text'),
          t('Scheduler.Appointments.Table.Columns.ArrivalTime.Label.Text'),
          t('Scheduler.Appointments.Table.Columns.CreatedBy.Label.Text'),
          t('Scheduler.Appointments.Table.Columns.Actions.Label.Text')
        ]}
        data={results.map(appointment => {
          const {
            purchaseOrderIdentifiers,
            id,
            humanReadableArrivalTime,
            createdByName,
            schedulerName,
            confirmationId
          } = appointment

          return [
            purchaseOrderIdentifiers,
            confirmationId,
            humanReadableArrivalTime,
            `${createdByName} (${schedulerName})`,
            <Link to={`/appointments/${id}/reschedule`}>Update</Link>
          ]
        })}
      />
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '8px'
        })}>
        <Pagination
          labels={{
            prevButton: t('Common.Table.Pagination.Prev.Text'),
            nextButton: t('Common.Table.Pagination.Next.Text'),
            preposition: t('Common.Table.Pagination.Preposition.Text')
          }}
          numPages={numPages}
          currentPage={currentPage}
          onPageChange={({ nextPage }) => {
            setCurrentPage(Math.min(Math.max(nextPage, 1), numPages))
          }}
        />
      </div>
    </>
  )
}

export default MainTable
