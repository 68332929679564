import React from 'react'
import { useStyletron } from 'baseui'

const Divider = ({ width }) => {
  const [css, theme] = useStyletron()

  return (
    <div>
      <hr
        className={css({
          height: '1px',
          backgroundColor: theme.colors.gray, // Using a theme color for the divider
          width: width || '100%',
          border: 'none',
          marginTop: '0',
          marginBottom: '16px'
        })}
      />
    </div>
  )
}

export default Divider
