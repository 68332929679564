import { useStyletron } from 'baseui'
import { Tag } from 'baseui/tag'
import { ParagraphSmall } from 'baseui/typography'
import { CurrentUser } from 'components/users'
import React, { Dispatch, Fragment, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { connectRefinementList } from 'react-instantsearch-dom'
import { upperFirst, camelCase } from 'lodash'

export interface FilterSummaryProps {
  numberOfHits: number
  styling?: string
  appliedFilters: {
    timeRange?: {
      from?: string
      to?: string
    }
    checkin_status?: string[]
    created_by_name?: string[]
    scheduler_name?: string[]
  }
  currentUser: CurrentUser
  cachedTimeRange: {
    minTime: { id: string; label: string }[]
    maxTime: { id: string; label: string }[]
  }
  setCachedTimeRange: Dispatch<
    SetStateAction<{
      minTime: { id: string; label: string }[]
      maxTime: { id: string; label: string }[]
    }>
  >
}

const NonConnectedRefinementFilter = props => {
  const { t } = useTranslation()

  return (
    <>
      {props.items
        .filter(item => item.isRefined)
        .map(item => (
          <Tag
            overrides={{
              Text: {
                style: ({ $theme }) => ({
                  fontSize: $theme.typography.LabelXSmall.fontSize,
                  maxWidth: 'none'
                })
              },
              Root: {
                style: ({ $theme }) => ({
                  borderTopWidth: $theme.borders.borderWidth,
                  borderBottomWidth: $theme.borders.borderWidth,
                  borderLeftWidth: $theme.borders.borderWidth,
                  borderRightWidth: $theme.borders.borderWidth,
                  marginLeft: 0,
                  marginTop: 0
                })
              }
            }}
            onActionClick={() => props.refine(item.value)}>
            {`${t(`Common.Dropdown.Titles.${upperFirst(camelCase(props.attribute))}.Text`)}:`}
            <strong> {item.label}</strong>
          </Tag>
        ))}
    </>
  )
}

export const ConnectedRefinementFilter = connectRefinementList(NonConnectedRefinementFilter)

const FilterSummary = ({
  numberOfHits,
  appliedFilters,
  currentUser,
  cachedTimeRange,
  setCachedTimeRange,
  styling
}: FilterSummaryProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={styling}>
      {Object.entries(appliedFilters).length > 0 && (
        <ParagraphSmall display="inline" width="auto" marginRight="scale300" color="primary">
          <b>
            {numberOfHits > 1
              ? t('Common.FilterSummary.pluralResults', { numberOfHits })
              : t('Common.FilterSummary.singularResult', { numberOfHits })}
          </b>{' '}
          {t('Common.FilterSummary.lastPart')}
        </ParagraphSmall>
      )}
      {Object.entries(appliedFilters).map(([k, v]) => {
        if (k !== 'timeRange' && Array.isArray(v)) {
          return <ConnectedRefinementFilter attribute={k} key={k} />
        }
        return (
          <Fragment key="timeRange">
            {appliedFilters?.timeRange.from !== '00:00' && (
              <Tag
                overrides={{
                  Text: {
                    style: ({ $theme }) => ({
                      fontSize: $theme.typography.LabelXSmall.fontSize,
                      maxWidth: 'none'
                    })
                  },
                  Root: {
                    style: ({ $theme }) => ({
                      borderTopWidth: $theme.borders.borderWidth,
                      borderBottomWidth: $theme.borders.borderWidth,
                      borderLeftWidth: $theme.borders.borderWidth,
                      borderRightWidth: $theme.borders.borderWidth,
                      marginLeft: 0,
                      marginTop: 0
                    })
                  }
                }}
                onActionClick={() => {
                  setCachedTimeRange({
                    ...cachedTimeRange,
                    [currentUser.shipperId]: {
                      ...(cachedTimeRange[currentUser.shipperId] || {}),
                      [currentUser.id]: {
                        ...((cachedTimeRange[currentUser.shipperId] &&
                          cachedTimeRange[currentUser.shipperId][currentUser.id]) ||
                          cachedTimeRange),
                        minTime: [
                          {
                            id: '1970-01-01T00:00',
                            label: '00:00'
                          }
                        ]
                      }
                    }
                  })
                }}>
                from: <strong>{appliedFilters?.timeRange.from}</strong>
              </Tag>
            )}
            {appliedFilters?.timeRange.to !== '24:00' && (
              <Tag
                overrides={{
                  Text: {
                    style: ({ $theme }) => ({ fontSize: $theme.typography.LabelXSmall.fontSize })
                  },
                  Root: {
                    style: ({ $theme }) => ({
                      borderTopWidth: $theme.borders.borderWidth,
                      borderBottomWidth: $theme.borders.borderWidth,
                      borderLeftWidth: $theme.borders.borderWidth,
                      borderRightWidth: $theme.borders.borderWidth,
                      marginLeft: 0,
                      marginTop: 0
                    })
                  }
                }}
                onActionClick={() => {
                  setCachedTimeRange({
                    ...cachedTimeRange,
                    [currentUser.shipperId]: {
                      ...(cachedTimeRange[currentUser.shipperId] || {}),
                      [currentUser.id]: {
                        ...((cachedTimeRange[currentUser.shipperId] &&
                          cachedTimeRange[currentUser.shipperId][currentUser.id]) ||
                          cachedTimeRange),
                        maxTime: [
                          {
                            id: '1970-01-01T24:00',
                            label: '24:00'
                          }
                        ]
                      }
                    }
                  })
                }}>
                to: <strong>{appliedFilters?.timeRange.to}</strong>
              </Tag>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default FilterSummary
