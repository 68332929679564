import { CurrentUserContext } from 'components/homepage/current-user-context'
import { FlexGrid } from 'baseui/flex-grid'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'

import { useUserRolesContext } from '../../../components/contexts/user-roles.context'
import React, { useContext, useEffect, useState } from 'react'
import ErrorMessageButton from 'components/shared/error-message-button'

import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Checkbox from 'components/ui/generic/Checkbox'
import Select from 'components/ui/generic/Select'

import { toLower } from 'lodash'
import { useTranslation } from 'react-i18next'

interface CreateUserRoleModalProps {
  close: () => void
  isOpen: boolean
  loading: boolean
}

const CreateUserRoleModal = ({ close, isOpen, loading: saveStarts }: CreateUserRoleModalProps) => {
  const {
    roles: { modalType, selectedRole, isModalActive },
    actions: { createRole, updateRole, setRoleToUpdate, search }
  } = useUserRolesContext()
  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation()
  const audiences = [
    { label: t('Settings.UserRoles.Modal.Fields.Audience.Presets.Internal.Text'), id: 'internal' },
    { label: t('Settings.UserRoles.Modal.Fields.Audience.Presets.External.Text'), id: 'external' },
    { label: t('Settings.UserRoles.Modal.Fields.Audience.Presets.Driver.Text'), id: 'driver' }
  ]
  const [enabled, setEnabled] = useState<boolean>(true)
  const [name, setName] = useState<string>('')
  const [audience, setAudience] = useState<
    Array<{
      label: string
      id: string
    }>
  >([audiences[0]])

  const CREATE_LABEL: string = t('Settings.UserRoles.Modal.Header.Alternatives.Create.Text')
  const UPDATE_LABEL: string = t('Settings.UserRoles.Modal.Header.Alternatives.Update.Text')
  const EDIT_LABEL: string = t('Settings.UserRoles.Modal.Header.Alternatives.Edit.Text')

  useEffect(() => {
    if (modalType === EDIT_LABEL) {
      setName(selectedRole?.name)
      setAudience([
        {
          label: audiences.filter(a => a.id === selectedRole?.audience)[0]?.label,
          id: selectedRole?.audience
        }
      ])
      setEnabled(selectedRole?.enabled)
    } else {
      setName('')
      setAudience([audiences[0]])
      setEnabled(true)
    }
  }, [selectedRole, isModalActive])

  const getActionLabel = () => (modalType === EDIT_LABEL ? UPDATE_LABEL : modalType)

  const formErrors = (): { label: string; status: boolean }[] => {
    return [
      { label: t('Settings.UserRoles.Modal.Validations.RoleNameRequired.Text'), status: !!name },
      { label: t('Settings.UserRoles.Modal.Validations.AudienceRequired.Text'), status: !!audience }
    ]
  }

  const handleAction = async () => {
    if (modalType === CREATE_LABEL) {
      await createRole({
        name: name,
        audience: audience[0].id,
        enabled,
        shipperId: currentUser?.shipperId
      })
      search('')
    }

    if (modalType === EDIT_LABEL && !enabled && enabled !== selectedRole.enabled) {
      setRoleToUpdate({
        id: selectedRole?.id,
        name: name,
        audience: audience[0].id,
        enabled,
        shipperId: currentUser?.shipperId
      })
    } else if (modalType === EDIT_LABEL) {
      updateRole(
        {
          id: selectedRole?.id,
          name: name,
          audience: audience[0].id,
          enabled,
          shipperId: currentUser?.shipperId
        },
        false
      )
    }
  }

  return (
    <Modal unstable_ModalBackdropScroll onClose={close} isOpen={isOpen}>
      <ModalHeader>
        {`${getActionLabel()}`} {t('Settings.UserRoles.Modal.Header.Text')}
      </ModalHeader>
      <ModalBody>
        <FlexGrid marginTop="30px">
          <FormControl label={t('Settings.UserRoles.Modal.Fields.RoleName.Label.Text')}>
            <Input
              autoComplete="off"
              name="role[name]"
              value={name}
              onChange={evt => {
                setName(evt.currentTarget.value)
              }}
            />
          </FormControl>
          <FormControl label={t('Settings.UserRoles.Modal.Fields.Audience.Label.Text')}>
            <Select
              clearable={false}
              backspaceClearsInputValue
              maxDropdownHeight="250px"
              deleteRemoves={true}
              value={audience}
              options={audiences}
              disabled={modalType === EDIT_LABEL}
              placeholder=""
              onChange={({ option }) =>
                setAudience([
                  {
                    label: String(option.label),
                    id: String(option.id)
                  }
                ])
              }
            />
          </FormControl>
          <FormControl label={t('Settings.UserRoles.Modal.Fields.Status.Label.Text')}>
            <Checkbox
              checked={enabled}
              onChange={e => setEnabled(e.currentTarget.checked)}
              label={t('Settings.UserRoles.Modal.Fields.Status.CheckBox.Text')}
            />
          </FormControl>
        </FlexGrid>
      </ModalBody>
      <ModalFooter>
        <ErrorMessageButton
          label={getActionLabel()}
          errors={formErrors()}
          buttonProps={{
            isLoading: saveStarts,
            onClick: handleAction
          }}
        />
      </ModalFooter>
    </Modal>
  )
}

export default CreateUserRoleModal
