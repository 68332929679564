import { useCarriersContext } from 'components/contexts/carriers.context'
import React from 'react'
import { MODAL_TYPE_CREATE, MODAL_TYPE_EDIT } from 'components/constants/modal-types'
import { useTranslation } from 'react-i18next'
import { SelectSearch } from 'components/components/SelectSearch'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'

const SCAC_CODE_MAX_LENGTH = 10

const CarrierModalForm = () => {
  const {
    carriers: { externalUser, modalType, carrier, disableFields, isVendorSelectDisabled },
    actions: { searchBy, setCarrier }
  } = useCarriersContext()

  const { t } = useTranslation()
  const onFieldChange = (fieldName: string, e: any, isEvent = true): void => {
    const value = isEvent ? e.currentTarget.value : e
    setCarrier({
      ...carrier,
      [fieldName]: value
    })
  }

  return (
    <>
      <FormControl label={t('Settings.Carriers.CreateCarrierModal.Form.SCACcode.Label')}>
        <Input
          maxLength={SCAC_CODE_MAX_LENGTH}
          autoComplete="off"
          name="scac"
          value={carrier?.scacCode}
          onChange={e => onFieldChange('scacCode', e.currentTarget.value?.toUpperCase(), false)}
          onBlur={() => carrier?.scacCode && searchBy('scac')}
          disabled={modalType === MODAL_TYPE_EDIT}
        />
      </FormControl>
      <FormControl label={t('Settings.Carriers.CreateCarrierModal.Form.Name.Label')}>
        <Input
          autoComplete="off"
          name="name"
          value={carrier?.name}
          onChange={e => onFieldChange('name', e.currentTarget.value, false)}
          onBlur={() => modalType === MODAL_TYPE_CREATE && carrier?.name && searchBy('name')}
        />
      </FormControl>
      {!externalUser && (
        <>
          <FormControl
            label={t('Settings.Carriers.CreateCarrierModal.Form.Email.Label')}
            key="Email">
            <Input
              disabled={disableFields}
              autoComplete="off"
              name="email"
              type="email"
              value={carrier?.email}
              onChange={e => onFieldChange('email', e)}
            />
          </FormControl>
          <FormControl
            label={t('Settings.Carriers.CreateCarrierModal.Form.Address.Label')}
            key="Address">
            <Input
              disabled={disableFields}
              autoComplete="off"
              name="address"
              value={carrier?.address}
              onChange={e => onFieldChange('address', e)}
            />
          </FormControl>
          <FormControl
            key={'Vendors'}
            label={t('Settings.Carriers.CreateCarrierModal.Form.Vendors.Label')}>
            <SelectSearch
              multi
              record={carrier}
              disabled={isVendorSelectDisabled}
              entity="schedulers"
              onValuesChange={ids => onFieldChange('schedulers', ids, false)}
            />
          </FormControl>
        </>
      )}
    </>
  )
}

export default CarrierModalForm
