import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { FlexGridItem } from 'baseui/flex-grid'
import AnsweredQuestion from './answered-question'
import { appointmentService } from '../../../../services/appointment.service'
import { Question } from '../../../../appointments/types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import regexValidations from 'components/utils/regex-validations'
import { DEFAULT_SHIPPER_PERMISSION } from '../../../../constants/default-permissions'
import { CurrentUserContext } from '../../../../homepage/current-user-context'
import { QuestionPermission } from '../../../../models/QuestionPermission'
import { checkFileQuestionError, checkQuestionError } from '../../../../utils/check-question-error'
import { DOCUMENT_TYPE } from '../../../../models/Question'

interface AnswersProps {
  record: {
    id: string
    appointmentTypeId: string
    createdByUserName: string
    equipmentTypeId: string
  }
  setRecord: (record) => void
  shouldValidate?: boolean
}

export interface AnsweredQuestionInterface {
  id: string
  prompt: string
  caption: string
  minLength?: number
  maxLength?: number
  response: string
  questionId: string
  readOnly: boolean
  disabled: boolean
  attachedDocuments: Array<any>
  type: string
  question: Question
  permission: QuestionPermission
  error?: any
  shouldValidate?: boolean
}

const Answers = ({ record, setRecord, shouldValidate }: AnswersProps): ReactElement => {
  const [answeredQuestions, setAnsweredQuestions] = useState<AnsweredQuestionInterface[]>([])
  const {
    temporalFiles,
    actions: { setAnswers }
  } = useCQStateValue()

  const { currentUser } = useContext(CurrentUserContext)

  useEffect(() => {
    if (record?.id) {
      appointmentService.getAnsweredQuestions(record.id).then(([answers, status]) => {
        if ([304, 200].includes(status)) {
          const answersErrorChecked = answers.map(answer => {
            const permission =
              answer.question.questionPermissionsAttributes.filter(
                p => p.userRoleId === currentUser.userRoleId
              )[0] || DEFAULT_SHIPPER_PERMISSION

            return {
              ...answer,
              error:
                answer.question.answerType !== DOCUMENT_TYPE &&
                checkQuestionError(answer.question, permission, answer),
              permission
            }
          })

          setAnswers(answersErrorChecked)
          setAnsweredQuestions(answersErrorChecked)
        }
      })
    }
  }, [record?.id])

  return (
    <>
      {answeredQuestions
        .sort((a, b) => a.question.position - b.question.position)
        .filter(
          question =>
            !(
              question?.disabled ||
              !question?.question?.equipmentTypeIds?.includes(record.equipmentTypeId)
            ) && question.permission.canView
        )
        .map((answeredQuestion: AnsweredQuestionInterface, i) => (
          <FlexGridItem key={i}>
            <AnsweredQuestion
              shouldValidate={shouldValidate}
              answeredQuestion={answeredQuestion}
              record={record}
              setRecord={setRecord}
              setAnsweredQuestions={setAnsweredQuestions}
              answeredQuestions={answeredQuestions}
            />
          </FlexGridItem>
        ))}
    </>
  )
}

export default Answers
