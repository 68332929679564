import React, { createContext, useContext, useReducer } from 'react'
import {
  SET_SELECTED_FACILITY,
  yardScheduleReducer,
  SET_LOADING,
  SET_APP_TYPE,
  SET_APP_TYPES_PER_CLASS,
  OPEN_DROP_MODAL,
  CLOSE_DROP_MODAL,
  SET_DROP_MODAL_LOADING,
  SET_DROP_MODAL_AREA_OPTIONS,
  SET_DROP_MODAL_SELECTED_SLOT,
  SET_DROP_MODAL_SELECTED_AREA,
  SET_CACHE_HITS,
  SET_SELECTED_YARD_JOCKEY,
  SET_DROP_MODAL_DRIVER_ASSIGNMENT,
  UPDATE_DROP_CONFIRMATION_MODAL,
  CLOSE_DROP_MODAL_KEEP_DATA_OPEN_CONFIRMATION_MODAL,
  SET_FACILITY_LOADING
} from 'components/reducers/yard-schedule.reducer'
import { Facility } from '../models/Facility'
import {
  INBOUND_FLAG,
  INBOUND_TYPE,
  OUTBOUND_FLAG,
  OUTBOUND_TYPE
} from '../constants/appointment_types'
import { SET_ZONES } from '../reducers/zones.reducer'
import i18n from '../../translations/i18n'
import { Option } from '../shared/types/Option'
import { slotService } from '../services'
import { useTranslation } from 'react-i18next'
import { FullCalendarEvent } from '../types/full-calendar-event'
import { RefreshSearchContext } from '../../lib/cyber-components/search/search'
import { StatusCodes } from '../constants/http-status-codes'
import { fancyToast } from '../utils'
import { CalendarReferenceContext } from './calendar-reference-context'
import { taskService } from '../services/task.service'

export type ZoneResourceType = { id: string; title: string; position?: number }

export const AppTypeKeys = {
  INBOUND: INBOUND_TYPE,
  OUTBOUND: OUTBOUND_TYPE,
  ALL: 'All'
}

export const FIXED_UNASSIGNED_COLUMN: ZoneResourceType = {
  id: 'unassigned',
  title: i18n.t('Common.Select.DefaultUnassignedColumn.Text'),
  position: 0
}

export const FIXED_DOCK_DOORS_COLUMN: ZoneResourceType = {
  id: 'dock',
  title: i18n.t('YardPage.Calendar.DockDoors.Text'),
  position: 1
}

interface YardScheduleContextProps {
  selectedFacility: Facility[]
  selectedAppType: Option<string>
  appTypeOptions: Option<string>[]
  selectedYardJockey: Option<string>[]
  appTypesPerClass: Record<string, string[]>
  zones: ZoneResourceType[]
  loading: boolean
  facilityLoading: boolean
  dropModalIsOpen: boolean
  dropModalFromZoneName?: string
  dropModalAppointmentId?: string
  dropModalToZoneId?: string
  dropModalToZoneName?: string
  dropModalLoading: boolean
  dropModalForDriver: boolean
  dropModalSelectedArea: Option<string>
  dropModalSelectedSlot: Option<string>
  dropModalDriverAssignment: boolean
  dropModalAreaOptions: []
  selectedAppointment?: FullCalendarEvent
  cacheHits?: []
  dropConfirmationModalIsOpen: boolean
  dropConfirmationModalTitle?: string
  dropConfirmationTaskId?: string
  info?: any
}

export const YardScheduleContext = createContext({} as any)

const initialState: YardScheduleContextProps = {
  selectedFacility: undefined,
  selectedAppType: { value: '', label: '' },
  selectedYardJockey: [],
  appTypeOptions: [
    { value: AppTypeKeys.INBOUND, label: AppTypeKeys.INBOUND },
    { value: AppTypeKeys.OUTBOUND, label: AppTypeKeys.OUTBOUND },
    { value: AppTypeKeys.ALL, label: AppTypeKeys.ALL }
  ],
  appTypesPerClass: {
    [AppTypeKeys.INBOUND]: [],
    [AppTypeKeys.OUTBOUND]: [],
    [AppTypeKeys.ALL]: []
  },
  zones: [],
  loading: false,
  dropModalIsOpen: false,
  facilityLoading: false,
  dropModalFromZoneName: null,
  dropModalAppointmentId: null,
  dropModalToZoneId: null,
  dropModalToZoneName: null,
  dropModalLoading: false,
  dropModalForDriver: false,
  dropModalSelectedArea: { value: '', label: '' },
  dropModalSelectedSlot: { value: '', label: '' },
  dropModalAreaOptions: [],
  selectedAppointment: null,
  cacheHits: [],
  dropModalDriverAssignment: false,
  info: null,
  dropConfirmationModalIsOpen: false,
  dropConfirmationModalTitle: null,
  dropConfirmationTaskId: null
}

export const YardScheduleProvider = ({
  children,
  defaultInitialState
}: {
  children: any
  defaultInitialState?: YardScheduleContextProps
}) => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(yardScheduleReducer, defaultInitialState || initialState)
  const { refresh } = useContext(RefreshSearchContext)
  const { calendarReference } = useContext(CalendarReferenceContext)

  const cancelDropConfirmationModal = () => {
    revertMovement()
    dispatch({
      type: UPDATE_DROP_CONFIRMATION_MODAL,
      payload: {
        dropConfirmationModalIsOpen: false,
        dropConfirmationModalTitle: null,
        dropConfirmationTaskId: null,
        dropConfirmationSlotId: null,
        dropConfirmationDockId: null
      }
    })
  }

  const confirmDropConfirmationModal = () => {
    revertMovement()

    taskService
      .updateTask({
        id: state.dropConfirmationTaskId,
        toDockId:
          state.dropModalSelectedArea.label == FIXED_DOCK_DOORS_COLUMN.title
            ? state.dropModalSelectedSlot.value
            : null,
        toSlotId:
          state.dropModalSelectedArea.label != FIXED_DOCK_DOORS_COLUMN.title
            ? state.dropModalSelectedSlot.value
            : null,
        userId: state.selectedYardJockey?.value || null
      })
      .then(() => {
        dispatch({
          type: UPDATE_DROP_CONFIRMATION_MODAL,
          payload: {
            dropConfirmationModalIsOpen: false,
            dropConfirmationModalTitle: null,
            dropConfirmationTaskId: null,
            dropConfirmationSlotId: null,
            dropConfirmationDockId: null
          }
        })
      })
      .catch(e => console.error(e))
  }

  const setDropModalDriverAssignment = (dropModalDriverAssignment: false) => {
    dispatch({ type: SET_DROP_MODAL_DRIVER_ASSIGNMENT, payload: dropModalDriverAssignment })
  }

  const setFacilityLoading = (facilityLoading: boolean) => {
    dispatch({ type: SET_FACILITY_LOADING, payload: facilityLoading })
  }

  const setSelectedFacility = async (facility: Facility) => {
    dispatch({ type: SET_SELECTED_FACILITY, payload: facility })

    const appTypesPerClass = {
      [AppTypeKeys.INBOUND]: [],
      [AppTypeKeys.OUTBOUND]: [],
      [AppTypeKeys.ALL]: []
    }

    facility.appointmentTypes.forEach(ap => {
      if (ap.type === AppTypeKeys.INBOUND) {
        appTypesPerClass[AppTypeKeys.INBOUND].push(ap.id)
      }

      if (ap.type === AppTypeKeys.OUTBOUND) {
        appTypesPerClass[AppTypeKeys.OUTBOUND].push(ap.id)
      }

      appTypesPerClass[AppTypeKeys.ALL].push(ap.id)
    })

    const zones = await getSlotTotals(facility)
    setZones(zones)
    setAppTypesPerClass(appTypesPerClass)
  }

  const getSlotTotals = async (facility: Facility, appType?: string) => {
    try {
      setLoading(true)
      const [resp, _status] = await slotService.getSlotsTotals(facility.id, appType)
      const { slots, docks } = resp

      const zones = slots.map(slot => ({
        id: slot.zone,
        title: slot.zone,
        extendedProps: {
          occupiedSlots: slot.occupied,
          totalSlots: slot.total
        }
      }))

      if (docks.total > 0) {
        zones.push({
          ...FIXED_DOCK_DOORS_COLUMN,
          extendedProps: {
            occupiedSlots: docks.occupied,
            totalSlots: docks.total
          }
        })
      }

      setLoading(false)
      return zones
    } catch (e) {
      console.error(e)
      setLoading(false)
      return []
    }
  }

  const setSelectedYardJockey = (selectedYardJockey: Option<string>) => {
    dispatch({ type: SET_SELECTED_YARD_JOCKEY, payload: selectedYardJockey })
  }

  const setLoading = (loading: boolean) => {
    dispatch({ type: SET_LOADING, payload: loading })
  }

  const setDropModalLoading = (loading: boolean) => {
    dispatch({ type: SET_DROP_MODAL_LOADING, payload: loading })
  }

  const setZones = (zones: ZoneResourceType[]) => {
    dispatch({ type: SET_ZONES, payload: zones })
  }

  const setAppType = async (appType: Option<string>) => {
    if (state.selectedFacility) {
      dispatch({ type: SET_APP_TYPE, payload: appType })

      const zones = await getSlotTotals(
        state.selectedFacility,
        appType.value === INBOUND_TYPE
          ? INBOUND_FLAG
          : appType.value === OUTBOUND_TYPE
          ? OUTBOUND_FLAG
          : null
      )

      setZones(zones)
    }
  }

  const setAppTypesPerClass = (appTypesPerClass: Record<string, string[]>) => {
    dispatch({ type: SET_APP_TYPES_PER_CLASS, payload: appTypesPerClass })
  }

  const openDropModal = ({
    dropModalFromZoneName,
    dropModalAppointmentId,
    dropModalToZoneId,
    dropModalToZoneName,
    dropModalForDriver,
    selectedAppointment,
    info
  }) => {
    dispatch({
      type: OPEN_DROP_MODAL,
      payload: {
        dropModalFromZoneName,
        dropModalAppointmentId,
        dropModalToZoneId,
        dropModalToZoneName,
        dropModalIsOpen: true,
        dropModalForDriver,
        selectedAppointment,
        info
      }
    })
  }

  const closeDropModal = () => {
    dispatch({
      type: CLOSE_DROP_MODAL,
      payload: {
        dropModalFromZoneName: null,
        dropModalAppointmentId: null,
        dropModalToZoneId: null,
        dropModalToZoneName: null,
        dropModalIsOpen: false,
        selectedAppointment: null,
        dropModalDriverAssignment: false
      }
    })
  }

  const closeDropModalAndKeepDataOpenConfirmationModal = (
    slotName: string,
    zoneName: string,
    dockName: string,
    taskId: string,
    slotId: string,
    dockId: string
  ) => {
    dispatch({
      type: CLOSE_DROP_MODAL_KEEP_DATA_OPEN_CONFIRMATION_MODAL,
      payload: {
        dropModalIsOpen: false,
        dropConfirmationModalIsOpen: true,
        dropConfirmationModalTitle: t('YardSchedule.DropModal.ConfirmationModalTitle.Text', {
          location: slotId
            ? t('YardSchedule.DropModal.ZoneAndSlot.Text', {
                zoneName,
                slotName
              })
            : t('YardSchedule.DropModal.Dock.Text', {
                dockName
              })
        }),
        dropConfirmationTaskId: taskId,
        dropConfirmationSlotId: slotId,
        dropConfirmationDockId: dockId
      }
    })
  }

  const overflowsZoneCapacity = (zoneName: string) => {
    const statsForSelectedZone = state.zones.filter((z: ZoneResourceType) => z.title === zoneName)
    const { occupiedSlots, totalSlots } = statsForSelectedZone[0].extendedProps

    if (occupiedSlots + 1 > totalSlots) {
      return true
    }

    return false
  }

  const setDropModalSelectedArea = (dropModalSelectedArea: Option<string>) => {
    dispatch({
      type: SET_DROP_MODAL_SELECTED_AREA,
      payload: {
        dropModalSelectedArea
      }
    })
  }

  const setDropModalSelectedSlot = (dropModalSelectedSlot: Option<string>) => {
    dispatch({
      type: SET_DROP_MODAL_SELECTED_SLOT,
      payload: {
        dropModalSelectedSlot
      }
    })
  }

  const setDropModalAreaOptions = (dropModalAreaOptions: []) => {
    dispatch({
      type: SET_DROP_MODAL_AREA_OPTIONS,
      payload: {
        dropModalAreaOptions
      }
    })
  }

  const revertMovement = () => {
    state.info.revert()
    refresh()
    calendarReference?.current && calendarReference.current?.getApi()?.removeAllEvents()
  }

  const moveTrailer = async () => {
    if (
      state.dropModalDriverAssignment ||
      state.dropModalFromZoneName === FIXED_UNASSIGNED_COLUMN.title
    ) {
      const [data, status] = await slotService.assignTrailerToSlotOrDock(
        state.selectedAppointment?.extendedProps?.trailerId,
        state.dropModalSelectedArea.label == FIXED_DOCK_DOORS_COLUMN.title
          ? state.dropModalSelectedSlot.value
          : null,
        state.dropModalSelectedArea.label != FIXED_DOCK_DOORS_COLUMN.title
          ? state.dropModalSelectedSlot.value
          : null,
        state.selectedFacility.id,
        state.selectedYardJockey?.value
      )

      if (status === StatusCodes.OK) {
        // We need to update the appointment in the cache, so it is moved to the correct dock resource
        // and persisted in every following render.
        const updatedCacheHits = state.cacheHits.map(item => {
          if (item.id === state.selectedAppointment.id) {
            return {
              ...item,
              trailer_slot_zone: data.trailer_slot_zone,
              trailer_slot_name: data.trailer_slot_name,
              trailer_dock_name: data.trailer_dock_name
            }
          }
          return item
        })
        setCacheHits(updatedCacheHits)

        const zones = await getSlotTotals(
          state.selectedFacility,
          state.selectedAppType.value === INBOUND_TYPE
            ? INBOUND_FLAG
            : state.selectedAppType.value === OUTBOUND_TYPE
            ? OUTBOUND_FLAG
            : null
        )

        setZones(zones)
        refresh()
        calendarReference?.current && calendarReference.current?.getApi()?.removeAllEvents()
        fancyToast({ info: t('YardSchedule.DropModal.SuccessAssignment.Text') }, StatusCodes.OK)
      } else {
        fancyToast(
          { info: t('YardSchedule.DropModal.ErrorAssignment.Text') },
          StatusCodes.INTERNAL_SERVER_ERROR
        )
      }

      closeDropModal()
    } else {
      const [data, status] = await taskService.assign(
        state.selectedAppointment?.extendedProps?.trailerId,
        state.dropModalSelectedArea.label == FIXED_DOCK_DOORS_COLUMN.title
          ? state.dropModalSelectedSlot.value
          : null,
        state.dropModalSelectedArea.label != FIXED_DOCK_DOORS_COLUMN.title
          ? state.dropModalSelectedSlot.value
          : null,
        state.selectedFacility.id,
        state.selectedYardJockey?.value
      )

      if (status === StatusCodes.CREATED) {
        fancyToast({ info: t('YardSchedule.DropModal.SuccessTaskAssignment.Text') }, StatusCodes.OK)
        revertMovement()
        closeDropModal()
      }

      if (status === StatusCodes.NO_CONFLICT && data.message) {
        fancyToast(
          { info: t('YardSchedule.Errors.ExistingTaskInProgress.Text') },
          StatusCodes.INTERNAL_SERVER_ERROR
        )
        revertMovement()
        closeDropModal()
      }

      if (status === StatusCodes.NO_CONFLICT && data.taskId) {
        closeDropModalAndKeepDataOpenConfirmationModal(
          data.slotName,
          data.zoneName,
          data.dockName,
          data.taskId,
          data.slotId,
          data.dockId
        )
      }
    }
  }

  const setCacheHits = cacheHits => {
    dispatch({
      type: SET_CACHE_HITS,
      payload: {
        cacheHits
      }
    })
  }

  const actions = {
    setSelectedFacility,
    setLoading,
    setZones,
    setAppType,
    openDropModal,
    closeDropModal,
    setDropModalLoading,
    overflowsZoneCapacity,
    setDropModalSelectedArea,
    setDropModalSelectedSlot,
    setDropModalAreaOptions,
    moveTrailer,
    setCacheHits,
    setSelectedYardJockey,
    cancelDropConfirmationModal,
    setDropModalDriverAssignment,
    confirmDropConfirmationModal,
    closeDropModalAndKeepDataOpenConfirmationModal,
    setFacilityLoading
  }

  return (
    <YardScheduleContext.Provider value={{ ...state, actions }}>
      {children}
    </YardScheduleContext.Provider>
  )
}

export const useYardScheduleContext = () => useContext(YardScheduleContext)
