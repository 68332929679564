import React, { useEffect } from 'react'
import { connectRange } from 'react-instantsearch-dom'
import moment from 'moment'
import { useState } from 'react'
import { camelCase, upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'
import Input from 'components/ui/generic/Input'
import DatePicker from 'components/ui/generic/DatePicker'
import TimeFormatter from 'components/utils/time-formatter'
// This component is jank because:
//   - the datepicker in baseweb is pretty terrible
//   - typesense makes you store dates as ints
//   - It was really hard to try and just use the currentRefinement values, hense extra state named `value`
// One important thing to note is that 'undefined' is equivilent to 'the farthest min / max' when we are calling
// refine(). Algolia computes that for us automatically
const NonConnectedDateRangePicker = ({
  currentRefinement,
  refine,
  attribute,
  dateRange,
  setDateRange,
  setAppliedFilters
}) => {
  const [initialRefinement, setInitialRefinement] = useState(null)
  const { t } = useTranslation()
  useEffect(() => {
    if (initialRefinement === null && currentRefinement?.min && currentRefinement?.max) {
      // We want to keep track of the farthest min & max values, so we can
      // set the min and max dates on the calendar.
      setInitialRefinement(currentRefinement)
    }
  }, [currentRefinement])

  useEffect(() => {
    if (!initialRefinement) {
      return
    }

    const refinement = {
      min:
        dateRange[0] && Math.max(initialRefinement.min, moment(dateRange[0]).startOf('day').unix()),
      max: dateRange[1] && Math.min(initialRefinement.max, moment(dateRange[1]).endOf('day').unix())
    }

    refine(refinement)
  }, [dateRange])

  useEffect(() => {
    if (setAppliedFilters && dateRange?.length > 0) {
      setAppliedFilters(c => ({
        ...c,
        dateRange: {
          ...(dateRange[0] && { from: new TimeFormatter('shortDate').format(dateRange[0]) }),
          ...(dateRange[1] && { to: new TimeFormatter('shortDate').format(dateRange[1]) })
        }
      }))
    } else if (dateRange?.length === 0) {
      setAppliedFilters(c => {
        const updatedFilters = { ...c }
        delete updatedFilters.dateRange
        return { ...updatedFilters }
      })
    }
  }, [dateRange, setAppliedFilters])

  if (!initialRefinement) {
    return (
      <Input
        placeholder={t(`Common.Input.Titles.${upperFirst(camelCase(attribute))}.Text`)}
        disabled
      />
    )
  }

  return (
    <DatePicker
      placeholder={t(`Common.DatePicker.Titles.${upperFirst(camelCase(attribute))}.Text`)}
      clearable
      range
      value={dateRange}
      onChange={({ date }) => setDateRange(Array.isArray(date) ? date : [date])}
    />
  )
}

const CyberSearchDateRangePicker = connectRange(NonConnectedDateRangePicker)

export default CyberSearchDateRangePicker
