import React, { useState, useEffect } from 'react'
import Header from 'components/ui/generic/Header'
import { Input } from 'baseui/input'
import { Search } from 'baseui/icon'
import { FormControl } from 'baseui/form-control'
import { Table } from 'baseui/table'
import { Link } from 'react-router-dom'
import { Checkbox } from 'baseui/checkbox'
import { Block } from 'baseui/block'
import TimeFormatter from '../utils/time-formatter'

import authenticatedFetch from '../utils/authenticated-fetch'
import { useTranslation } from 'react-i18next'

const AppointmentSearch = () => {
  const [repeating, setRepeating] = useState<boolean>(false)

  const [search, setSearch] = useState<any>()
  const [searching, setSearching] = useState<any>(false)

  const [appointments, setAppointments] = useState<any>([])

  const { t } = useTranslation()

  useEffect(() => {
    if (searching) {
      return
    }

    const runSearch = async () => {
      setSearching(true)
      const path = `/purchase_orders.json?${repeating ? 'repeating=true' : ''}${
        search ? `&identifier=${search}` : ''
      }`
      const [json, status] = await authenticatedFetch({
        path
      })

      if ([200, 204].includes(status)) {
        setAppointments(json)
      }
      setSearching(false)
    }

    runSearch()
  }, [search, repeating])

  return (
    <>
      <Block display="flex" alignItems="center" justifyContent="space-between">
        <Header title={t('Appointments.Header.Text')} />
        <Checkbox
          name="appointments[repeating]"
          checked={repeating}
          onChange={e => setRepeating(e.currentTarget.checked)}>
          Repeating
        </Checkbox>
      </Block>
      <FormControl label="Unique Load Identifier">
        <Input
          startEnhancer={<Search />}
          autoComplete="off"
          name="appointments[purchase_order_identifier]"
          value={search}
          onChange={e => {
            setSearch(e.currentTarget.value)
          }}
        />
      </FormControl>
      <Table
        isLoading={searching}
        columns={[
          'Unique Load Identifiers',
          'Company Name',
          'Arrival Time',
          'Facility',
          'Status',
          'Created By'
        ]}
        data={appointments.map(purchaseOrder => {
          const {
            identifier,
            companyName,
            appointmentId,
            arrivalTime,
            facilityTimeZone,
            facilityId,
            status,
            facilityName,
            createdBy
          } = purchaseOrder

          const formattedArrivalTime = arrivalTime
            ? new TimeFormatter('fullDate').format(arrivalTime, facilityTimeZone)
            : null
          return [
            <Link to={`/appointments/${appointmentId}`}>{identifier}</Link>,
            companyName,
            formattedArrivalTime,
            <Link to={`/facilities/${facilityId}`}>{facilityName}</Link>,
            status,
            createdBy
          ]
        })}
      />
    </>
  )
}

export default AppointmentSearch
