import React, { useState, useEffect } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Checkbox } from 'baseui/checkbox'
import { Block } from 'baseui/block'
import { Value, Select } from 'baseui/select'
import { FormControl } from 'baseui/form-control'
import { Datepicker } from 'baseui/datepicker'
import { TimePicker } from 'baseui/timepicker'
import { Button } from 'baseui/button'
import { Input } from 'baseui/input'
import { Link } from 'react-router-dom'
import CancelAppointment from '../modals/cancel-appointment'
import AppointmentTagSelector from '../fields/appointment-tag-selector'
import FacilitySelect from '../fields/facility-select'
import Responses from '../responses'
import AppointmentDocuments from '../fields/appointment-documents'
import { fancyToast } from '../../utils'
import authenticatedFetch from '../../utils/authenticated-fetch'
import NumberButton from '../../shared/fields/number-button'
import PurchaseOrderIdentifiers from '../fields/purchase-order-identifiers'
import AppointmentType from '../fields/appointment-types'
import DockSelect from '../fields/dock-select'
import CommodityType from 'components/shared/fields/commodity-type'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'

interface AppointmentDetailsProps {
  appointment: any
  setAppointment: any
  onUpdate?: any
}

const AppointmentDetails = (props: AppointmentDetailsProps) => {
  const { appointment, setAppointment, onUpdate } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [purchaseOrders, setPurchaseOrders] = useState<Value>(appointment.purchaseOrders)
  const [tagList, setTagList] = React.useState<Value>(appointment.tags)

  useEffect(() => {
    const { arrivalTime } = appointment
    const newExtimes =
      appointment.schedule &&
      appointment.schedule.extimes.map(extime => {
        const extimeDate = new Date(extime)
        const newExtime = new Date(
          extimeDate.getFullYear(),
          extimeDate.getMonth(),
          extimeDate.getDate(),
          arrivalTime.getHours(),
          arrivalTime.getMinutes(),
          arrivalTime.getSeconds()
        )

        return newExtime
      })

    setAppointment({
      ...appointment,
      schedule: appointment.schedule && {
        ...appointment.schedule,
        extimes: newExtimes
      }
    })
  }, [appointment.arrivalTime])

  const submitAppointment = async () => {
    setLoading(true)
    const { answersAttributes } = appointment

    const [json, status] = await authenticatedFetch({
      path: appointment.id ? `/appointments/${appointment.id}.json` : '/appointments.json',
      method: appointment.id ? 'PATCH' : 'POST',
      body: {
        appointment: {
          ...appointment,
          tagsAttributes: tagList,
          purchaseOrdersAttributes: purchaseOrders,
          answersAttributes: answersAttributes.filter(answer => {
            return answer.response || (answer.documents && answer.documents.length > 0)
          })
        }
      }
    })
    if (status == 200) {
      fancyToast({ info: 'Appointment was successfully updated.' }, status)
      setPurchaseOrders(json.purchaseOrders)
      setTagList(json.tags)
      onUpdate && onUpdate(json)
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const requiredFieldsPresent = () => {
    return (
      purchaseOrders &&
      purchaseOrders.filter(({ _destroy }) => {
        return !_destroy
      }).length > 0 &&
      appointment.facilityId
    )
  }

  return (
    <>
      {appointment && (
        <CancelAppointment
          isOpen={modalOpen}
          close={() => {
            setModalOpen(false)
            onUpdate && onUpdate()
          }}
          {...{ appointment, setAppointment }}
        />
      )}
      {/* For some reason, I have to manually define keys here. Otherwise errors are thrown. */}
      <FlexGrid
        flexGridRowGap="scale400"
        flexGridColumnGap="scale400"
        flexGridColumnCount={[1, 1, 2]}
      >
        <FlexGridItem key="0">
          <PurchaseOrderIdentifiers {...{ purchaseOrders, setPurchaseOrders }} />
        </FlexGridItem>
        <FlexGridItem key="1">
          <AppointmentTagSelector {...{ tagList, setTagList }} />
        </FlexGridItem>
        {appointment?.arrivalTime && (
          <>
            <FlexGridItem key="2">
              <FormControl label="Date" caption="time displayed in your local timezone.">
                <Datepicker
                  value={appointment.arrivalTime}
                  formatString="MM/dd/yyyy"
                  onChange={({ date }) =>
                    setAppointment({
                      ...appointment,
                      arrivalTime: date as Date
                    })
                  }
                />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem key="3">
              <FormControl
                label="Arrival Time"
                caption={<Link to={`/appointments/${appointment.id}/reschedule`}>reschedule?</Link>}
              >
                <TimePicker
                  step={15 * 60}
                  value={appointment.arrivalTime}
                  onChange={date =>
                    setAppointment({
                      ...appointment,
                      arrivalTime: date as Date
                    })
                  }
                />
              </FormControl>
            </FlexGridItem>
          </>
        )}
        <FlexGridItem key="4">
          <FacilitySelect record={appointment} setRecord={setAppointment} />
        </FlexGridItem>
        <FlexGridItem key="5">
          <FormControl label="Dock">
            <DockSelect {...{ appointment, setAppointment }} />
          </FormControl>
        </FlexGridItem>

        <FlexGridItem key="7">
          <FormControl label="Quantity">
            <Input
              autoComplete="off"
              name="appointment[quantity]"
              value={appointment.quantity}
              onChange={e =>
                setAppointment({
                  ...appointment,
                  quantity: e.currentTarget.value
                })
              }
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem key="9">
          <FormControl label="Appointment Type">
            <AppointmentType {...{ appointment, setAppointment }} />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem key="10">
          <FormControl label="Scheduler">
            <CyberAutocomplete
              record={appointment}
              setRecord={setAppointment}
              indexName="scheduler"
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem key="11">
          <CommodityType {...{ appointment, setAppointment }} />
        </FlexGridItem>
      </FlexGrid>
      <FlexGrid flexGridRowGap="scale200">
        {appointment?.appointmentTypeId && (
          <FlexGridItem>
            <Responses {...{ appointment, setAppointment }} />
          </FlexGridItem>
        )}
        <FlexGridItem>
          <Button disabled={!requiredFieldsPresent() || loading} onClick={submitAppointment}>
            Update
          </Button>{' '}
          <Button
            onClick={() => {
              setModalOpen(true)
            }}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    backgroundColor: $theme.colors.negative400
                  }
                }
              }
            }}
          >
            Cancel
          </Button>
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}

export default AppointmentDetails
