import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ACCEPT_BUTTON,
  NEW_TAB,
  PENDING_TAB,
  START_BUTTON,
  useYardJockeyContext
} from '../../contexts/yard-jockey.context'
import Button from 'components/ui/specific/PrimaryButton'
import GenericButton from 'components/ui/generic/Button'
import { CaretRight, Check, UserPlus } from '@phosphor-icons/react'
import { Block } from 'baseui/block'
import { primitives } from '../../homepage/custom-theme'
import { AcceptAndStartButtonStyles, CheckMarkIcon } from './YardJockey.styled'

const YardJockeyActionButton = ({ task }) => {
  const { t } = useTranslation()
  const {
    activeTab,
    actionButtonLoading,
    taskInProgressId,
    actions: { handleButtonClick, setCompleteModalOpen }
  } = useYardJockeyContext()

  const currentTaskIsInProgress = task?.id ? task.id === taskInProgressId : false

  const [beenClicked, setBeenClicked] = useState(false)

  if (activeTab === NEW_TAB) {
    return (
      <GenericButton
        color={primitives.accent}
        onClick={() => {
          handleButtonClick({ ...task, acceptedAt: new Date() }, ACCEPT_BUTTON, () =>
            setBeenClicked(false)
          )
          setBeenClicked(true)
        }}
        isLoading={actionButtonLoading && beenClicked}
        overrides={AcceptAndStartButtonStyles}>
        {t('YardJockey.Table.Buttons.Accept.Text')}
        <Block display="inline" marginLeft="5px">
          <UserPlus size="16px" />
        </Block>
      </GenericButton>
    )
  }

  if (activeTab === PENDING_TAB && currentTaskIsInProgress) {
    return (
      <Button
        isLoading={actionButtonLoading}
        onClick={() => {
          setBeenClicked(true)
          setCompleteModalOpen(true)
        }}>
        {t('YardJockey.Table.Buttons.Complete.Text')}
        <CheckMarkIcon>
          <Check size="14px" color={primitives.white} />
        </CheckMarkIcon>
      </Button>
    )
  }

  if (activeTab === PENDING_TAB && !taskInProgressId) {
    return (
      <GenericButton
        color={primitives.accent}
        onClick={() => {
          handleButtonClick({ ...task, startedAt: new Date() }, START_BUTTON, () =>
            setBeenClicked(false)
          )
          setBeenClicked(true)
        }}
        isLoading={actionButtonLoading && beenClicked}
        overrides={AcceptAndStartButtonStyles}>
        {t('YardJockey.Table.Buttons.Start.Text')}
        <Block display="inline" marginLeft="5px">
          <CaretRight size="16px" />
        </Block>
      </GenericButton>
    )
  }

  return <div></div>
}

export default YardJockeyActionButton
