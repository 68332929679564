// We have to import this @fullcalendar/react before 'Draggable. Something about tree shaking.
// https://github.com/fullcalendar/fullcalendar-angular/issues/306#issuecomment-647591856
import '@fullcalendar/react'
import { useStyletron } from 'baseui'
import React, { useContext, useEffect, useRef, useState } from 'react'
import CyberSearch from 'cyber/search/search'
import { CalendarReferenceContext } from 'components/contexts/calendar-reference-context'
import { useLocalStorage } from 'react-use'
import {
  CustomLabels,
  INITIAL_CUSTOM_FIELD_NAMES,
  CustomLabelKey,
  CustomLabelsContext
} from 'components/contexts/custom-labels-context'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { FacilitiesContext } from '../../shipper/instant-calendar/context/facilities-context'
import { CalendarSelectedOptionsProvider } from '../../contexts/calendar-selected-options.context'
import { DEFAULT_LOCALE_STRING_LANGUAGE } from '../../constants/default-locale-string-language'
import moment from 'moment'
import DockAssignmentCalendar from './DockAssignmentCalendar'
import DockAssignmentHeader from './DockAssignmentHeader'
import { DockAssignmentProvider } from '../../contexts/dock-assigment.context'
import { useHistory } from 'react-router-dom'
import FilterSummary from 'components/shipper/instant-calendar/filter-summary'
import DockAssignmentModals from './DockAssignmentModals'
import useDefaultFacilities from 'components/shipper/instant-calendar/hooks/use-default-facilities'
import useFacilityInfo from 'components/shipper/instant-calendar/hooks/use-facility-info'

const LOCAL_STORAGE_DATE_KEY = 'DOCK_ASSIGN_SELECTED_DATE_BY_USER_AND_ORG'

const DockAssignment = () => {
  const calendarReference = useRef(null)
  const { currentUser } = useContext(CurrentUserContext)
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent>(null)
  const [numberOfHits, setNumberOfHits] = useState<number>(0)
  const [appliedFilters, setAppliedFilters] = useState({})
  const [selectedDate, setSelectedDate] = useState(new Date())

  const history = useHistory()

  useEffect(() => {
    if (currentUser?.dockAssignmentActive === false) {
      history.push('/')
    }
  }, [currentUser])

  const [cachedTimeRange, setCachedTimeRange] = useLocalStorage(
    `DOCK_ASSIGNMENT_TIME_RANGE_BY_USER_AND_ORG`,
    {
      minTime: [
        {
          id: '1970-01-01T00:00',
          label: '00:00'
        }
      ],
      maxTime: [{ id: '1970-01-01T24:00', label: '24:00' }]
    }
  )

  const [customLabels, setCustomLabels] = useState<CustomLabels>(INITIAL_CUSTOM_FIELD_NAMES)

  const customLabel = (customLabelKey: CustomLabelKey) => customLabels[customLabelKey]
  const [css, theme] = useStyletron()

  const initialDate = [
    moment(new Date().toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE)).startOf('day').toDate(),
    moment(new Date().toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE)).endOf('day').toDate()
  ]

  const [cacheDate, setCacheDate] = useLocalStorage(LOCAL_STORAGE_DATE_KEY, {})

  const [dateInCalendarFilter, setDateInCalendarFilter] = useState(
    cacheDate &&
      cacheDate[currentUser.shipperId] &&
      cacheDate[currentUser.shipperId][currentUser.id] &&
      cacheDate[currentUser.shipperId][currentUser.id][0] &&
      cacheDate[currentUser.shipperId][currentUser.id][1]
      ? [
          moment(
            new Date(cacheDate[currentUser.shipperId][currentUser.id][0]).toLocaleString(
              DEFAULT_LOCALE_STRING_LANGUAGE
            )
          )
            .startOf('day')
            .toDate(),
          moment(
            new Date(cacheDate[currentUser.shipperId][currentUser.id][1]).toLocaleString(
              DEFAULT_LOCALE_STRING_LANGUAGE
            )
          )
            .endOf('day')
            .toDate()
        ]
      : initialDate
  )

  useEffect(() => {
    if (
      cacheDate &&
      cacheDate[currentUser.shipperId] &&
      cacheDate[currentUser.shipperId][currentUser.id] &&
      cacheDate[currentUser.shipperId][currentUser.id][0]
    ) {
      setSelectedDate(new Date(cacheDate[currentUser.shipperId][currentUser.id][0]))
    }
  }, [cacheDate])

  return (
    <CustomLabelsContext.Provider value={{ customLabels, setCustomLabels, customLabel }}>
      <CalendarReferenceContext.Provider
        value={{
          calendarReference,
          cacheDate,
          setCacheDate,
          setDateInCalendarFilter,
          dateInCalendarFilter
        }}>
        <FacilitiesContext.Provider
          value={{ facilities: [], slotDuration: '', selectedDate, setSelectedDate }}>
          <DockAssignmentProvider>
            <CalendarSelectedOptionsProvider>
              <CyberSearch
                indexName="appointment"
                queryBy="purchase_order_identifiers"
                hitsPerPage={100}>
                <DockAssignmentHeader
                  cachedTimeRange={cachedTimeRange}
                  setCachedTimeRange={setCachedTimeRange}
                  setSelectedEvent={setSelectedEvent}
                  selectedDate={selectedDate}
                  setAppliedFilters={setAppliedFilters}
                  appliedFilters={appliedFilters}
                />
                <DockAssignmentModals
                  selectedEvent={selectedEvent as any}
                  setSelectedEvent={setSelectedEvent}
                />
                <div className={css({ position: 'relative' })}>
                  <FilterSummary
                    numberOfHits={numberOfHits}
                    appliedFilters={appliedFilters}
                    currentUser={currentUser}
                    cachedTimeRange={cachedTimeRange}
                    setCachedTimeRange={setCachedTimeRange}
                    styling={css({
                      marginBottom: theme.sizing.scale600
                    })}
                  />
                  <DockAssignmentCalendar
                    selectedEvent={selectedEvent}
                    setSelectedDate={setSelectedDate}
                    selectedDate={selectedDate}
                    setSelectedEvent={setSelectedEvent}
                    timeRange={
                      cachedTimeRange[currentUser.shipperId] &&
                      cachedTimeRange[currentUser.shipperId][currentUser.id]
                        ? cachedTimeRange[currentUser.shipperId][currentUser.id]
                        : cachedTimeRange
                    }
                    setNumberOfHits={setNumberOfHits}
                  />
                </div>
              </CyberSearch>
            </CalendarSelectedOptionsProvider>
          </DockAssignmentProvider>
        </FacilitiesContext.Provider>
      </CalendarReferenceContext.Provider>
    </CustomLabelsContext.Provider>
  )
}

export default DockAssignment
