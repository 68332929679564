import { styled } from 'baseui'

export const YardMetricCardProps = ({ color }) => ({
  overrides: {
    Root: {
      style: ({ $theme }) => ({
        borderRadius: '5px !important',
        backgroundColor: $theme.colors.gray,
        color: color,
        padding: '5px'
      })
    },
    Text: {
      style: ({ $theme }) => ({
        fontSize: '16px'
      })
    },
    StartEnhancerContainer: {
      style: ({ $theme }) => ({
        paddingRight: '5px'
      })
    }
  }
})

export const TitleMetricCard = styled('small', {
  fontSize: '10px'
})
