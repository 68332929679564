import React, { useState, useEffect, useContext } from 'react'
import { Button } from 'baseui/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import { Select } from 'baseui/select'
import { FormControl } from 'baseui/form-control'
import { Block } from 'baseui/block'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { fancyToast } from '../../utils'
import { CurrentUserContext } from '../../homepage/current-user-context'
import LinkButton from '../fields/link-button'
import { CalendarReferenceContext } from '../../contexts/calendar-reference-context'
import { CalendarToggleContext } from '../../contexts/calendar-toggle-context'

interface GuestCheckinModalProps {
  isOpen: boolean
  close: () => void
  event: any
}

const GuestCheckinModal = (props: GuestCheckinModalProps) => {
  const { selectedDate, setSelectedDate } = useContext(CalendarToggleContext)
  const { isOpen, close, event } = props
  const { currentUser } = useContext(CurrentUserContext)

  const [purchaseOrders, setPurchaseOrders] = useState<any>([])
  const [purchaseOrder, setPurchaseOrder] = useState<any>([])

  const runSearch = async e => {
    const identifier = e && e.currentTarget.value
    const query = identifier ? `?identifier=${identifier}` : ''
    const path = `/purchase_orders.json${query}`
    const [json, status] = await authenticatedFetch({
      path
    })

    if ([200, 204].includes(status)) {
      setPurchaseOrders(json)
    }
  }

  useEffect(() => {
    currentUser && currentUser.id && runSearch(null)
  }, [])

  const linkCheckinAndAppointment = async () => {
    const firstPurchaseOrder = purchaseOrder[0]

    const path = `/checkins/${event.id}.json`
    const [json, status] = await authenticatedFetch({
      path,
      method: 'PATCH',
      body: {
        checkin: {
          id: event.id,
          appointmentId: firstPurchaseOrder.appointmentId
        }
      }
    })

    if (status === 200) {
      fancyToast({ info: 'Appointment was linked to checkin.' }, status)
      setSelectedDate(new Date(selectedDate))
      close()
    } else {
      fancyToast(json, status)
    }
  }

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <ModalHeader>Check Driver In</ModalHeader>
      <ModalBody>
        <FormControl label="Unique Load Identifier">
          <Select
            options={purchaseOrders.filter(purchaseOrder => {
              return !purchaseOrder.checkedIn
            })}
            value={purchaseOrder}
            onChange={params => setPurchaseOrder(params.value)}
            onInputChange={runSearch}
            labelKey="identifier"
            valueKey="identifier"
            maxDropdownHeight="190px"
            id="select-purchase-order"
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Block display="flex" justifyContent="space-between">
          {event && event.id && (
            <LinkButton href={`/checkins/${event.id}`} label="Message Driver" />
          )}
          <Button disabled={purchaseOrder.length === 0} onClick={linkCheckinAndAppointment}>
            Link
          </Button>
        </Block>
      </ModalFooter>
    </Modal>
  )
}

export default GuestCheckinModal
