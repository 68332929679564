import React, { Dispatch } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import { AppointmentType } from 'components/models/AppointmentType'
import { useTranslation } from 'react-i18next'

import Input from 'components/ui/generic/Input'
import { LabelXSmall } from 'baseui/typography'

interface AppointmentTypesCustomLabelsProps {
  appointmentType: AppointmentType
  setAppointmentType: Dispatch<AppointmentType>
}

export const AppointmentTypesCustomLabels = ({
  appointmentType,
  setAppointmentType
}: AppointmentTypesCustomLabelsProps) => {
  const { t } = useTranslation()

  return (
    <FlexGrid maxWidth="640px">
      <FlexGridItem flexGridRowGap="scale600" flexGridColumnGap="scale600">
        <FlexGrid
          alignItems="center"
          justifyContent="center"
          flexGridColumnGap="scale600"
          flexGridColumnCount={3}
          marginBottom="scale600">
          <FlexGridItem></FlexGridItem>
          <FlexGridItem>
            <LabelXSmall>{t('Settings.AppointmentTypes.CustomQuestions.Label')}</LabelXSmall>
          </FlexGridItem>
          <FlexGridItem>
            <LabelXSmall>{t('Settings.AppointmentTypes.CustomQuestions.Caption')}</LabelXSmall>
          </FlexGridItem>
        </FlexGrid>
        <FlexGrid
          alignItems="center"
          justifyContent="center"
          flexGridColumnGap="scale600"
          flexGridColumnCount={3}
          marginBottom="scale600">
          <FlexGridItem>
            <LabelXSmall>
              {t('Settings.AppointmentTypes.CustomQuestions.UniqueLoadIdentifier')}
            </LabelXSmall>
          </FlexGridItem>
          <FlexGridItem>
            <Input
              autoComplete="off"
              name="uniqueLoadIdentifierLabel"
              value={appointmentType?.customLabels?.purchaseOrderIdentifiers}
              onChange={evt => {
                setAppointmentType({
                  ...appointmentType,
                  customLabels: {
                    ...appointmentType.customLabels,
                    purchaseOrderIdentifiers: evt.currentTarget.value
                  }
                })
              }}
            />
          </FlexGridItem>
          <FlexGridItem>
            <Input
              autoComplete="off"
              name="uniqueLoadIdentifierCaption"
              value={appointmentType?.customLabels?.purchaseOrderIdentifiersCaption}
              onChange={evt => {
                setAppointmentType({
                  ...appointmentType,
                  customLabels: {
                    ...appointmentType.customLabels,
                    purchaseOrderIdentifiersCaption: evt.currentTarget.value
                  }
                })
              }}
            />
          </FlexGridItem>
        </FlexGrid>
        <FlexGrid
          alignItems="center"
          justifyContent="center"
          flexGridColumnGap="scale600"
          flexGridColumnCount={3}
          marginBottom="scale600">
          <FlexGridItem>
            <LabelXSmall>{t('Settings.AppointmentTypes.CustomQuestions.Scheduler')}</LabelXSmall>
          </FlexGridItem>
          <FlexGridItem>
            <Input
              autoComplete="off"
              name="schedulerLabel"
              value={appointmentType?.customLabels?.scheduler}
              onChange={evt => {
                setAppointmentType({
                  ...appointmentType,
                  customLabels: {
                    ...appointmentType.customLabels,
                    scheduler: evt.currentTarget.value
                  }
                })
              }}
            />
          </FlexGridItem>
          <FlexGridItem>
            <Input
              autoComplete="off"
              name="schedulerLabel"
              value={appointmentType?.customLabels?.schedulerCaption}
              onChange={evt => {
                setAppointmentType({
                  ...appointmentType,
                  customLabels: {
                    ...appointmentType.customLabels,
                    schedulerCaption: evt.currentTarget.value
                  }
                })
              }}
            />
          </FlexGridItem>
        </FlexGrid>
      </FlexGridItem>
    </FlexGrid>
  )
}
