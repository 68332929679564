import packageJson from '../../../../package.json'

const clearCacheOnNewAppVersion = async () => {
  try {
    const newPackageVersion = (packageJson as any).version

    const localStorageVersion = localStorage.getItem('REACT_VSLOT_VERSION')

    if (newPackageVersion !== localStorageVersion) {
      clearCache()
      localStorage.setItem('REACT_VSLOT_VERSION', newPackageVersion)
    }
  } catch (error) {
    console.error('Error checking package.json version:', error)
  }
}

const clearCache = () => {
  try {
    if ('caches' in window) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName)
        })
      })
    }
    window.location.reload()
  } catch (error) {
    console.error('Error clearing cache:', error)
  }
}

export default clearCacheOnNewAppVersion
