import React, { useState, useEffect, useContext, useMemo, FC } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import Imageloader from 'components/shared/file-uploader'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { shipperService } from 'components/services/shipper.service'
import { useTranslation } from 'react-i18next'

import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Textarea from 'components/ui/generic/Textarea'
import { Trash } from '@phosphor-icons/react'
import {
  ShipperSettingsImageContainer,
  CustomPreviewImage,
  CustomTrashIcon
} from './styles/shipper.styled'
import StyledSpinner from '../shared/styled-spinner'
import RecordForm from '../shared/record-form'
import authenticatedFetch from '../utils/authenticated-fetch'

interface RemoveImageIconProps {
  data
  shipperId: string
  isLogo?: boolean
  onSuccess?: () => void
  onDelete?: () => void
  isPreview?: boolean
}

const RemoveImageIcon: FC<RemoveImageIconProps> = ({
  data,
  shipperId,
  isLogo = false,
  onSuccess,
  onDelete,
  isPreview = false
}) => {
  const { t } = useTranslation()

  const handleRemove = () => {
    const type = isLogo ? 'logo' : 'background'
    shipperService
      .removeShipperSettingsImage(data.id, shipperId, type)
      .then(res =>
        res?.shipper ? onSuccess && onSuccess() : console.warn(t('Common.Errors.Default.Text'))
      )
  }

  const handleRemovePreview = () => {
    onDelete && onDelete()
    onSuccess && onSuccess()
  }

  const onClick = () => {
    isPreview ? handleRemovePreview() : handleRemove()
  }

  const alt = t('Settings.ShipperProfile.Form.DeleteButton.Text')

  return (
    <CustomTrashIcon isLogo={isLogo} onClick={onClick} alt={alt}>
      <Trash size={24} color="white" />
    </CustomTrashIcon>
  )
}

const CustomInput: FC<{
  value: string
  shipper: any
  onChange: (value: string) => any
}> = ({ value, shipper, onChange }) => (
  <Input
    autoComplete="off"
    required
    name={`shipper[${value}]`}
    value={shipper[value] || ''}
    onChange={onChange(value)}
  />
)

const ShipperForm = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation()

  const { shipperId } = currentUser || { shipperId: undefined }
  const [shipper, setShipper] = useState<any>({})
  const [media, setMedia] = useState<any>({
    logos: [],
    backgrounds: []
  })
  const [previewImages, setPreviewImages] = useState<any>({
    logo: [],
    background: []
  })

  const ALLOW_LOGOS = useMemo(() => media?.logos?.length > 0, [media?.logos?.length])

  const ALLOW_BACKGROUNDS = useMemo(
    () => media?.backgrounds?.length > 0,
    [media?.backgrounds?.length]
  )

  const ALLOW_IMAGE_LOADER_BACKGROUNDS = useMemo(() => {
    const backgroundsLength = media?.backgrounds?.length ?? 0
    const previewBackgroundLength = previewImages?.background?.length ?? 0
    return backgroundsLength + previewBackgroundLength < 3
  }, [media?.backgrounds?.length, previewImages?.background?.length])

  const ALLOW_IMAGE_LOADER_IMAGES = useMemo(() => {
    const logosLength = media?.logos?.length ?? 0
    const previewlogoLength = previewImages?.logo?.length ?? 0
    return logosLength + previewlogoLength < 3
  }, [media?.logos?.length, previewImages?.logo?.length])

  const { logos, backgrounds } = useMemo(
    () => ({ logos: media?.logos, backgrounds: media?.backgrounds }),
    [media]
  )

  useEffect(() => loadShipper(), [])

  const updateShipper = data => {
    setShipper({ ...shipper, ...data })
  }

  const updateMedia = data => {
    setMedia({ ...media, ...data })
  }

  const updatePreviewImages = data => {
    setPreviewImages({ ...previewImages, ...data })
  }

  const checkSecureUrl = (element, secureUrl, type = 'logoSecureUrl' || 'backgroundSecureUrl') => {
    return element[type] === secureUrl
      ? { ...element, isActive: true }
      : { ...element, isActive: false }
  }

  const setActiveLogo = ({ id, logoSecureUrl }) => {
    updateShipper({ activeLogo: id })

    updateMedia({
      logos: media.logos.map(logo => checkSecureUrl(logo, logoSecureUrl, 'logoSecureUrl'))
    })
  }

  const setActiveBackground = ({ id, backgroundSecureUrl }) => {
    updateShipper({ activeBackground: id })
    updateMedia({
      backgrounds: media.backgrounds.map(background =>
        checkSecureUrl(background, backgroundSecureUrl, 'backgroundSecureUrl')
      )
    })
  }

  const loadShipper = () => {
    updatePreviewImages({ logo: [], background: [] })
    authenticatedFetch({
      path: `/shippers/${shipperId}.json`
    })
      .then(([shipper, _status]) => {
        const oneLogo = shipper?.logo?.length === 1
        if (oneLogo && !shipper?.activeLogo) {
          shipperService.updateShipperInitialSettings({
            id: shipper.id,
            logo: shipper.logo[0].id
          })
        }

        const oneBackground = shipper?.background?.length === 1
        if (oneBackground && !shipper?.activeBackground) {
          shipperService.updateShipperInitialSettings({
            id: shipper.id,
            background: shipper.background[0].id
          })
        }

        updateShipper(shipper)

        const logos = shipper?.logo?.length
          ? shipper?.logo.map(logo => checkSecureUrl(logo, shipper?.activeLogoUrl, 'logoSecureUrl'))
          : []
        const backgrounds = shipper?.background?.length
          ? shipper?.background.map(background =>
              checkSecureUrl(background, shipper?.activeBackgroundUrl, 'backgroundSecureUrl')
            )
          : []

        setMedia({ logos, backgrounds })
      })
      .catch(console.log)
  }

  const previewImageOnSuccess = (index: number, type: 'logo' | 'background') => {
    return () => {
      const filterFn = (_e, idx) => idx !== index
      const elements = shipper?.[type]?.filter(filterFn) || []
      updateShipper({ [type]: elements })
    }
  }

  const previewImageOnDelete = (element, type: 'logo' | 'background') => {
    return () => {
      const filterFn = data => data !== element
      const elements = previewImages?.[type]?.filter(filterFn) || []
      updatePreviewImages({ [type]: elements })
    }
  }

  const removeImageOnSuccess = (index: number, type: 'logos' | 'backgrounds') => {
    return () => {
      const filterFn = (_e, idx) => idx !== index
      const elements = media?.[type]?.filter(filterFn) || []
      updateMedia({ [type]: elements })
    }
  }

  const imageLoaderOnSuccess = (type: 'logo' | 'background') => {
    return data => {
      const filterFn = element => typeof element === 'string'
      const shipperElement = shipper?.[type]?.filter(filterFn) || []
      const element = [...shipperElement, ...data]
      updateShipper({ [type]: element })
    }
  }

  const imageLoaderOnPreview = (type: 'logo' | 'background') => {
    return preview => {
      updatePreviewImages({ [type]: [...previewImages[type], preview] })
    }
  }

  const onChange = (value: string) => {
    return e => {
      updateShipper({ [value]: e.currentTarget.value })
    }
  }

  if (!currentUser) {
    return <StyledSpinner />
  }

  return (
    <RecordForm
      recordName={t('Settings.ShipperProfile.Header.Text')}
      routeName="shippers"
      record={shipper}
      setRecord={setShipper}
      recordId={shipperId}
      requiredFieldsPresent
      match={{ params: { handle: shipperId } }}
      customPath="/settings/shipper"
      onSuccess={loadShipper}>
      <FlexGrid flexGridColumnCount={1} maxWidth="640px">
        <FlexGridItem>
          <FormControl label={t('Settings.ShipperProfile.Form.Fields.CompanyName.Label.Text')}>
            <CustomInput value="name" shipper={shipper} onChange={onChange} />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl label={t('Settings.ShipperProfile.Form.Fields.WarehouseCode.Label.Text')}>
            <CustomInput value="warehouseCode" shipper={shipper} onChange={onChange} />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl label={t('Settings.ShipperProfile.Form.Fields.AccountName.Label.Text')}>
            <CustomInput value="accountName" shipper={shipper} onChange={onChange} />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl
            label={t('Settings.ShipperProfile.Form.Fields.DefaultLogo.Label.Text')}
            caption={t('Settings.ShipperProfile.Form.Fields.DefaultLogo.SubTitle.Text')}>
            <FlexGrid
              flexGridColumnCount={[1, 1, 1, 4]}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale800">
              {ALLOW_LOGOS &&
                logos.map((logo, idx) => (
                  <ShipperSettingsImageContainer
                    type="logo"
                    isActive={logo?.isActive}
                    onClick={() => setActiveLogo(logo)}>
                    <RemoveImageIcon
                      data={logo}
                      shipperId={shipper.id}
                      isLogo
                      onSuccess={removeImageOnSuccess(idx, 'logos')}
                    />
                    <img
                      width={80}
                      height={22}
                      src={logo.logoSecureUrl}
                      style={{
                        transform: 'translate(38%, 55%)'
                      }}
                    />
                  </ShipperSettingsImageContainer>
                ))}

              {previewImages?.logo &&
                previewImages?.logo.map((logo, idx) => (
                  <CustomPreviewImage type="logo">
                    <RemoveImageIcon
                      data={logo}
                      shipperId={shipper.id}
                      onSuccess={previewImageOnSuccess(idx, 'logo')}
                      onDelete={previewImageOnDelete(logo, 'logo')}
                      isPreview
                      isLogo
                    />
                    <img height={30} src={logo} />
                  </CustomPreviewImage>
                ))}

              {ALLOW_IMAGE_LOADER_IMAGES && (
                <FlexGridItem>
                  <Imageloader
                    onError={console.log}
                    removePreviewFile
                    onSuccess={imageLoaderOnSuccess('logo')}
                    onPreview={imageLoaderOnPreview('logo')}
                    options={{
                      maxHeight: '160px',
                      maxWidth: '160px',
                      iconMarginTop: '0',
                      labelMarginTop: '0',
                      addFile: shipper?.logo?.length > 0,
                      type: 'IMAGE'
                    }}
                  />
                </FlexGridItem>
              )}
            </FlexGrid>
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl
            label={t('Settings.ShipperProfile.Form.Fields.DefaultImage.Label.Text')}
            caption={t('Settings.ShipperProfile.Form.Fields.DefaultImage.SubTitle.Text')}>
            <FlexGrid
              flexGridColumnCount={[1, 1, 1, 3]}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale800">
              {ALLOW_BACKGROUNDS &&
                backgrounds.map((background, idx) => (
                  <ShipperSettingsImageContainer
                    isActive={background?.isActive}
                    onClick={() => setActiveBackground(background)}>
                    <RemoveImageIcon
                      data={background}
                      shipperId={shipper.id}
                      onSuccess={removeImageOnSuccess(idx, 'backgrounds')}
                    />
                    <img width={220} height={240} src={background.backgroundSecureUrl} />
                  </ShipperSettingsImageContainer>
                ))}

              {previewImages?.background?.length &&
                previewImages?.background.map((background, idx) => (
                  <CustomPreviewImage>
                    <RemoveImageIcon
                      data={background}
                      shipperId={shipper.id}
                      onSuccess={previewImageOnSuccess(idx, 'background')}
                      onDelete={previewImageOnDelete(background, 'background')}
                      isPreview
                    />
                    <img width={220} height={240} src={background} />
                  </CustomPreviewImage>
                ))}

              {ALLOW_IMAGE_LOADER_BACKGROUNDS && (
                <FlexGridItem>
                  <Imageloader
                    onError={console.log}
                    onSuccess={imageLoaderOnSuccess('background')}
                    onPreview={imageLoaderOnPreview('background')}
                    removePreviewFile
                    options={{
                      maxHeight: '245px',
                      maxWidth: '220px',
                      iconMarginTop: '0px',
                      addFile: shipper?.background?.length > 0,
                      type: 'IMAGE'
                    }}
                  />
                </FlexGridItem>
              )}
            </FlexGrid>
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl label={t('Settings.ShipperProfile.Form.Fields.LoginText.Label.Text')}>
            <Textarea
              autoComplete="off"
              required
              name="shipper[loginText]"
              value={shipper.loginText}
              onChange={onChange('loginText')}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl
            label={t('Settings.ShipperProfile.Form.Fields.OpenSchedulingButtonLabel.Label.Text')}>
            <CustomInput value="schedulingBtnLabel" shipper={shipper} onChange={onChange} />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl
            label={t('Settings.ShipperProfile.Form.Fields.OpenSchedulingText.Label.Text')}>
            <CustomInput value="schedulingText" shipper={shipper} onChange={onChange} />
          </FormControl>
        </FlexGridItem>
      </FlexGrid>
    </RecordForm>
  )
}

export default ShipperForm
