import { FormControl } from 'baseui/form-control'
import Select from 'components/ui/generic/Select'
import React from 'react'
import { useTranslation } from 'react-i18next'
import getOrderFieldMap from './getOrderFieldMap'

const FieldsFromOrderSelect = ({ value, setValue, orderPreference }): JSX.Element => {
  const { t } = useTranslation()
  const fieldsArr = Object.entries(getOrderFieldMap())

  const options = fieldsArr.map(([k, v]) => {
    if (k === 'custom_field_one' && orderPreference?.enable_custom_fields) {
      return { id: orderPreference?.custom_field_label_one, field: k }
    }

    if (k === 'custom_field_two' && orderPreference?.enable_custom_fields) {
      return { id: orderPreference?.custom_field_label_two, field: k }
    }

    return { id: `${v}`, field: k }
  })

  return (
    <FormControl label={t('AppointmentPreferences.Form.Fields.EmailInvitation.SelectFields')}>
      <Select
        options={options}
        labelKey="id"
        valueKey="field"
        onChange={({ value }) => setValue(value)}
        value={value}
        multi
      />
    </FormControl>
  )
}

export default FieldsFromOrderSelect
