import React, { useContext } from 'react'
import { AppNavBar } from 'baseui/app-nav-bar'
import Logo from 'components/logo'
import { Block } from 'baseui/block'
import Header from 'components/ui/generic/Header'
import { primitives } from 'components/homepage/custom-theme'
import { DriversCheckinContext } from 'components/contexts/drivers-checkin.context'
import { useTranslation } from 'react-i18next'
import { DriverCheckinHeaderProps, DriverCheckinNavBarProps } from './DriversNavbar.styled'

export const Navbar = (): JSX.Element => {
  return <AppNavBar title={<Logo ids={[]} />} {...DriverCheckinNavBarProps} />
}

const DriversNavbar = ({ children }): JSX.Element => {
  const { state } = useContext(DriversCheckinContext)
  const { shipper } = state
  const { t } = useTranslation()
  return (
    <Block minWidth="94%" padding="scale600">
      <Navbar />
      <Header
        {...DriverCheckinHeaderProps}
        title={t('DriversCheckin.Header.Subtitle', { shipper: shipper?.name || '' })}
      />
      <Block width={'100%'}>{children}</Block>
    </Block>
  )
}

export default DriversNavbar
