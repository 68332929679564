import React from 'react'
import {
  BaseFileContainer,
  FileNameLabel
} from 'components/shipper/instant-calendar/modals/styled/TemporalFile.styled'
import { Trash } from '@phosphor-icons/react'
import { parseLabelMaxChars } from 'components/utils/label-char-limit-formatter'
import { TooltipBox, TooltipCard, TooltipText } from 'components/shared/styles/CustomTooltip.styled'
import Button from 'components/ui/generic/Button'

interface TemporalFileProps {
  file: any
  onRemoveFile: any
  isHidden?: any
}

const TemporalFile = ({ file, onRemoveFile, isHidden }: TemporalFileProps) => {
  return (
    <BaseFileContainer isHidden={isHidden}>
      <TooltipCard>
        <TooltipText>
          <FileNameLabel>
            {parseLabelMaxChars(file?.path, 8)} is ready to be uploaded.
          </FileNameLabel>
        </TooltipText>
        <TooltipBox>
          <p style={{ wordBreak: 'break-all', fontSize: '12px' }}>
            {(file?.path).replace(/%20/g, ' ')}
          </p>
        </TooltipBox>
      </TooltipCard>

      <Button onClick={() => onRemoveFile(file)} kind="tertiary">
        <Trash size={16} data-testid="temporal-file-delete-icon" />
      </Button>
    </BaseFileContainer>
  )
}

export default TemporalFile
