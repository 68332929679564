import Select from 'components/ui/generic/Select'
import React, { ReactElement } from 'react'

interface Option {
  label: string
  value: string
}
interface DropdownListTypeProps {
  value: any
  options: Option[]
  onChange: (e) => void
  disabled?: boolean
  label?: string
}

const DropdownListType = ({
  value,
  onChange,
  disabled,
  options,
  label
}: DropdownListTypeProps): ReactElement => {
  return (
    <Select
      options={options}
      value={value}
      placeholder="Select answer"
      onChange={onChange}
      disabled={disabled}
      labelKey="label"
      valueKey="value"
      searchable={false}
      clearable={false}
      label={label}
    />
  )
}
export default DropdownListType
