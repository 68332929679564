import React from 'react'
import { Block } from 'baseui/block'
import { ButtonProps } from 'baseui/button'
import Button from 'components/ui/generic/Button'
import { Minus, Plus } from '@phosphor-icons/react'

interface NumberButtonProps {
  count: number
  setCount: Function
  limit?: number
  buttonProps?: ButtonProps
  label?: string
}

const NumberButton = ({ count, setCount, limit, buttonProps, label }: NumberButtonProps) => {
  return (
    <Block flex={0} gridGap="scale400" display="flex" flexDirection="row" alignItems="center">
      <Button
        {...buttonProps}
        shape="circle"
        onClick={() => count > 0 && setCount(count - 1)}
        overrides={label && { Root: { props: { 'data-testid': `${label}-minus` } } }}>
        <Minus />
      </Button>

      <Block display="flex" alignItems="center" justifyContent="center" flex="0 0 16px">
        {count || 0}
      </Block>
      <Button
        {...buttonProps}
        shape="circle"
        onClick={() => {
          if (limit !== undefined && limit !== null && count + 1 > limit) {
            return
          }
          setCount(count + 1)
        }}
        overrides={label && { Root: { props: { 'data-testid': `${label}-plus` } } }}>
        <Plus />
      </Button>
    </Block>
  )
}

export default NumberButton
