import { HeadingLevel } from 'baseui/heading'
import { DEFAULT_BACKGROUND_IMG } from 'components/constants/default-media'
import { useCurrentFacilityContext } from 'components/contexts/current-facility.context'
import CurrentFacilityBook from 'components/facilities/current-facility-book'
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { shipperService } from '../../services/shipper.service'
import { CurrentUserContext } from '../current-user-context'
import { useStyletron } from 'baseui'
import StyledSpinner from 'components/shared/styled-spinner'
import MainContainer from './MainContainer'

export interface AccountInfoResponse {
  accountName?: string
  activeBackgroundUrl?: string
  activeLogoUrl?: string
  loginText?: string
  logoUrl?: string
  name?: string
  schedulingBtnLabel?: string
  schedulingText?: string
  warehouseCode?: string
}

const SignUp = () => {
  const { currentFacility } = useCurrentFacilityContext()
  const subdomain = window.location.hostname.split('.')[0]
  const [shipper, setShipper] = useState<AccountInfoResponse>({})
  const { currentUser } = useContext(CurrentUserContext)
  const [css] = useStyletron()

  useEffect(() => {
    shipperService.getSignUpInfo(subdomain).then(signRes => {
      const { activeBackgroundUrl, background, activeLogoUrl, logo } = signRes

      const finalActiveBackgroundUrl = activeBackgroundUrl
        ? activeBackgroundUrl
        : background?.length
        ? background[background?.length - 1]?.backgroundSecureUrl
        : DEFAULT_BACKGROUND_IMG

      const finalActiveLogoUrl = activeLogoUrl
        ? activeLogoUrl
        : logo?.length
        ? logo[logo?.length - 1]?.logoSecureUrl
        : null

      setShipper({
        ...signRes,
        activeLogoUrl: finalActiveLogoUrl,
        activeBackgroundUrl: finalActiveBackgroundUrl
      })
    })
  }, [])

  if (currentFacility && currentUser) {
    return <CurrentFacilityBook />
  }

  return (
    <HeadingLevel>
      <Suspense
        fallback={
          <div
            className={css({
              height: '100vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            })}>
            <StyledSpinner />
          </div>
        }>
        <MainContainer shipper={shipper} />
      </Suspense>
    </HeadingLevel>
  )
}

export default SignUp
