import { User } from 'components/users'
import { useCallback, useState } from 'react'
import { useLocalStorage } from 'react-use'

export interface FacilityOption {
  id: string
  name: string
}

function useDefaultFacilities({ id, shipperId }: User) {
  const [cacheFacilities, setCacheFacilities] = useLocalStorage(
    `DASHBOARD_FACILITIES_AUTOCOMPLETE_BY_USER_AND_ORG`,
    {}
  )

  const [facilityOptions, setFacilityOptions] = useState(
    (cacheFacilities && cacheFacilities[shipperId] && cacheFacilities[shipperId][id]) || []
  )

  const setFacilityOptionsHandler = useCallback((options: FacilityOption) => {
    setFacilityOptions(options)
    setCacheFacilities({
      ...cacheFacilities,
      [shipperId]: {
        ...((cacheFacilities && cacheFacilities[shipperId]) || {}),
        [id]: options
      }
    })
  }, [])

  return [facilityOptions, setFacilityOptionsHandler]
}

export default useDefaultFacilities
