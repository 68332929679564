import http from 'components/utils/authenticated-fetch'
import { fancyToast } from '../utils'
import { HttpMethods } from '../constants/http-methods'
class AuthService {
  logout = (callback?: () => void) => {
    http({
      path: '/users/validate_logout.json',
      method: HttpMethods.PUT
    })
      .then(([{ message }, status]) => {
        if (status === 200) {
          if (callback) {
            callback()
          }
          window.location.href = `https://${process.env.AUTH0_DOMAIN}/logout?client_id=${process.env.AUTH0_CLIENT_ID}&returnTo=${window.location.origin}/logout`
        } else {
          fancyToast({ info: message }, status)
        }
      })
      .catch(console.error)
  }
}

export const authService = new AuthService()
