import React, { useCallback, useContext, useState } from 'react'
import Firebase from 'firebase/app'
import 'firebase/messaging'
import { Button } from 'baseui/button'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { fancyToast } from '../../utils'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { SHIPPER } from '../../models/User'
import { useTranslation } from 'react-i18next'

const FirebaseMessagingToggle = () => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const tokenUpdateFailed = (
    message = t('Settings.UserProfile.Errors.PushNotifications.Text'),
    status = 500
  ) => {
    fancyToast({ negative: message }, status)
  }

  const toggleFirebaseMessaging = useCallback(() => {
    const setUserToken = async (firebaseToken: string, showToast = true) => {
      // Only update the firebaseToken; sending the entire user may save the form
      authenticatedFetch({
        path: `/users/${currentUser.id}.json`,
        method: 'PATCH',
        body: {
          user: { firebaseToken }
        }
      })
        .then(([json, status]) => {
          if (status >= 200 && status < 300) {
            setCurrentUser({
              ...currentUser,
              firebaseEnabled: !!firebaseToken
            })

            if (showToast) {
              fancyToast(
                {
                  base: `${t('Settings.UserProfile.Info.PushNotifications.Text')} ${
                    firebaseToken
                      ? t('Settings.UserProfile.Info.PushNotifications.Alternatives.Enabled.Text')
                      : t('Settings.UserProfile.Info.PushNotifications.Alternatives.Disabled.Text')
                  }`
                },
                201
              )
            }
            return
          }
          tokenUpdateFailed(json)
        })
        .catch(err => {
          tokenUpdateFailed(err)
        })
    }

    setLoading(true)

    Firebase.messaging()
      .getToken()
      .then(firebaseToken => {
        if (currentUser.firebaseEnabled) {
          Firebase.messaging()
            .deleteToken(firebaseToken)
            .then(() => {
              setUserToken(null)
            })
        } else {
          Firebase.messaging().getToken().then(setUserToken)

          Firebase.messaging().onTokenRefresh(() => {
            Firebase.messaging()
              .getToken()
              .then(newToken => {
                setUserToken(newToken, false)
              })
          })
        }
      })
      .catch(err => {
        return tokenUpdateFailed(err)
      })
      .then(() => {
        setLoading(false)
      })
  }, [currentUser, setCurrentUser])

  if (currentUser?.userType !== SHIPPER || !Firebase.messaging.isSupported()) {
    return null
  }

  return (
    <Button isLoading={loading} size="mini" onClick={toggleFirebaseMessaging}>
      {`${
        currentUser.firebaseEnabled
          ? t('Settings.UserProfile.Form.Fields.Emails.EnableButton.Alternatives.Disable.Text')
          : t('Settings.UserProfile.Form.Fields.Emails.EnableButton.Alternatives.Enable.Text')
      } ${t('Settings.UserProfile.Form.Fields.Emails.EnableButton.Text')}`}
    </Button>
  )
}

export default FirebaseMessagingToggle
