import React from 'react'
import { Block } from 'baseui/block'
import { StyledSpinnerNext } from 'baseui/spinner'

const StyledSpinner = () => (
  <Block display="flex" justifyContent="center" paddingTop="10px" paddingBottom="30px">
    <StyledSpinnerNext />
  </Block>
)

export default StyledSpinner
