import React, { useContext, useEffect } from 'react'
import { AddButton } from 'components/components/AddButton'
import { Block } from 'baseui/block'

import { CurrentUserContext } from 'components/homepage/current-user-context'
import { Rows, Columns, Selection, UserPlus, Check } from '@phosphor-icons/react'
import { ParagraphLarge } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import StyledSpinner from '../../shared/styled-spinner'
import { YardSearchButtonContainer } from '../YardTrailers/Trailers.styled'
import Button from 'components/ui/generic/Button'
import Header from 'components/ui/generic/Header'
import { useTasksContext } from 'components/contexts/tasks.context'
import TaskForm from './TaskForm'
import TasksList from './TasksList'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { COLUMN, ROW } from 'components/types/viewType'
import TasksBoardKanban from './TasksBoardKanban'
import YardTaskFacilitySelect from './YardTaskFacilitySelect'
import TaskDatePicker from './TaskDatePicker'
import YardMetricsCards from 'components/components/YardMetricCard/YardMetricsCards'
import { MetricCardProps } from 'components/components/YardMetricCard/YardMetricCard'
import {
  metricAccent,
  metricPrimary,
  metricSuccess
} from 'components/constants/tasks_metrics_types'

const TasksContainer = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const { state, actions } = useTasksContext()
  const {
    tasks: { view, unassigned, assigned, completed }
  } = state
  const { setModal, setView } = actions

  const { t } = useTranslation()

  const [css, theme] = useStyletron()

  const cards: MetricCardProps[] = [
    {
      title: t('YardTask.Summary.Unassigned.Text'),
      value: unassigned,
      icon: <Selection />,
      kind: metricPrimary
    },
    {
      title: t('YardTask.Summary.Assigned.Text'),
      value: assigned,
      icon: <UserPlus />,
      kind: metricAccent
    },
    {
      title: t('YardTask.Summary.Completed.Text'),
      value: completed,
      icon: <Check />,
      kind: metricSuccess
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const cleanTaskPreviousSelection = () => {
    setModal(true)
  }

  if (!currentUser) {
    return <StyledSpinner />
  }

  return (
    <>
      <Header
        title={t('YardTask.Header.Text')}
        subtitle={t('YardTask.Header.Subtitle')}
        leftItems={[<YardTaskFacilitySelect />, <div />, <div />]}
        rightItems={[
          currentUser && currentUser.admin && (
            <YardSearchButtonContainer>
              <AddButton
                onClick={() => cleanTaskPreviousSelection()}
                title={t('YardTask.Create.Text')}
              />
            </YardSearchButtonContainer>
          )
        ]}
      />

      <Block
        width="100%"
        marginBottom="15px"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <FlexGrid
          flexGridColumnCount={2}
          flexGridColumnGap="scale800"
          alignItems="center"
          width="100%"
          backgroundColor="backgroundPrimary">
          <FlexGridItem display="flex" justifyContent="flex-start">
            <TaskDatePicker />
          </FlexGridItem>
          <FlexGridItem gridGap="scale200" display="flex" justifyContent="flex-end">
            <YardMetricsCards cards={cards} />
            <Button
              data-testid="task-header-row-view"
              style={{
                backgroundColor: view === ROW ? theme.colors.accent100 : 'inherit'
              }}
              onClick={() => {
                setView(ROW)
              }}
              shape="circle">
              <Rows size={32} />
            </Button>
            <Button
              data-testid="task-header-column-view"
              style={{
                backgroundColor: view === COLUMN ? theme.colors.accent100 : 'inherit'
              }}
              onClick={() => {
                setView(COLUMN)
              }}
              shape="circle">
              <Columns size={32} />
            </Button>
          </FlexGridItem>
        </FlexGrid>
      </Block>
      {view === COLUMN && (
        <Block width="100%">
          <TasksBoardKanban />
        </Block>
      )}
      {view === ROW && (
        <Block width="100%">
          <TasksList />
        </Block>
      )}
      <TaskForm />
    </>
  )
}

export default TasksContainer
