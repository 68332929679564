import React, { Dispatch, SetStateAction, useState } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { CaretDown } from '@phosphor-icons/react'

import FormControl from 'components/ui/generic/FormControl'
import DatePicker from 'components/ui/generic/DatePicker'
import Checkbox from 'components/ui/generic/Checkbox'

import {
  RecurringPanel,
  RecurringDays,
  RecurringCheckboxWrapper,
  RecurringToggleWrapper
} from './RecurringFields.styled'

const getDay = (index: number) => {
  switch (index) {
    case 0:
      return 'Sun'
    case 1:
      return 'Mon'
    case 2:
      return 'Tue'
    case 3:
      return 'Wed'
    case 4:
      return 'Thu'
    case 5:
      return 'Fri'
    case 6:
      return 'Sat'
  }
}

interface RecurringFieldsProps {
  record: any
  setRecord: any
  recurring: boolean
  setRecurring?: (value: boolean) => void
  setTouchedRecurringSection?: Dispatch<SetStateAction<boolean>>
  isUpdate?: boolean
}
const RecurringFields = ({
  record,
  setRecord,
  setTouchedRecurringSection,
  recurring,
  setRecurring,
  isUpdate
}: RecurringFieldsProps) => {
  const { recurringDays } = record

  const [toggle, setToggle] = useState<boolean>(false)

  return (
    <>
      <FlexGridItem>
        <RecurringCheckboxWrapper>
          <Checkbox
            checked={recurring}
            onChange={() => {
              if (setRecurring) {
                setRecurring(!recurring)
              }
            }}
            label={'Recurring'}
            disabled={isUpdate}
          />
          {isUpdate && (
            <RecurringToggleWrapper toggle={toggle} onClick={() => setToggle(!toggle)}>
              <CaretDown size={24} />
            </RecurringToggleWrapper>
          )}
        </RecurringCheckboxWrapper>
      </FlexGridItem>

      <FlexGridItem>
        <RecurringPanel isUpdate={isUpdate} toggle={toggle} recurring={recurring}>
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200">
            <FlexGridItem
              overrides={{
                Block: {
                  style: () => ({
                    width: '40%',
                    flexGrow: 0
                  })
                }
              }}>
              <FormControl label="End Date">
                <DatePicker
                  minDate={record.arrivalTime}
                  value={record.endDate || []}
                  formatString="MM/dd/yyyy"
                  onChange={({ date }) => {
                    setRecord({
                      ...record,
                      endDate: date as Date
                    })
                    if (setTouchedRecurringSection) {
                      setTouchedRecurringSection(true)
                    }
                  }}
                />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem>
              <FormControl label="Repeat On">
                <RecurringDays>
                  {[0, 1, 2, 3, 4, 5, 6].map((day, i) => (
                    <Checkbox
                      key={`${day}${i}`}
                      checked={recurringDays.includes(day)}
                      onChange={() => {
                        if (recurringDays.includes(day)) {
                          setRecord({
                            ...record,
                            recurringDays: recurringDays.filter(
                              (selectedDay: number) => selectedDay !== day
                            )
                          })
                        } else {
                          setRecord({ ...record, recurringDays: [...recurringDays, day] })
                        }
                        if (setTouchedRecurringSection) {
                          setTouchedRecurringSection(true)
                        }
                      }}
                      label={getDay(day)}
                    />
                  ))}
                </RecurringDays>
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
        </RecurringPanel>
      </FlexGridItem>
    </>
  )
}

export default RecurringFields
