import { CaretLeft } from '@phosphor-icons/react'
import { useStyletron } from 'baseui'
import { HeadingSmall, ParagraphXSmall } from 'baseui/typography'
import { useAppointmentContext } from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import StyledSpinner from 'components/shared/styled-spinner'
import { Block } from 'baseui/block'
import Card from 'components/components/Card'
import Header from 'components/ui/generic/Header'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AppointmentReusableForm from './UpdateAppointmentReusableForm'
import { Link } from 'react-router-dom'
import AppointmentConfirmation from './AppointmentConfirmation'
import { BlockLoadingSpinner, HeaderContainer } from './UpdateAppointmentExternalForm.styled'

const HeaderComponent: React.FC = () => {
  const {
    state: { facility }
  } = useFacilityContext()
  const {
    actions: { resetAppointmentStates }
  } = useAppointmentContext()
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  return (
    <Header
      title={
        <HeaderContainer>
          <Link onClick={resetAppointmentStates} to={`/appointments`}>
            <CaretLeft />
          </Link>{' '}
          <div>
            <ParagraphXSmall margin={`0`}>{`Updating`}</ParagraphXSmall>
            <HeadingSmall margin={`0 0 ${theme.sizing.scale400} 0`}>
              {facility?.name && `${facility?.name} Appointment`}
            </HeadingSmall>
          </div>
        </HeaderContainer>
      }
      overrides={{
        title: {
          margin: `0 0 ${theme.sizing.scale400} 0`
        }
      }}
    />
  )
}

const UpdateAppointmentExternalForm = ({ match }) => {
  const { actions } = useAppointmentContext()
  const { setEditAppointment, setIsTimeSet } = actions

  useEffect(() => {
    if (match?.params?.handle) {
      setEditAppointment(match.params.handle, true)
      setIsTimeSet(true)
    }
  }, [match?.params?.handle])

  return (
    <>
      <HeaderComponent />
      <AppointmentForm />
    </>
  )
}

export default UpdateAppointmentExternalForm

const AppointmentForm = () => {
  const { state } = useAppointmentContext()
  const { loading, confirmationIds } = state
  return (
    <>
      {confirmationIds.length > 0 ? (
        <AppointmentConfirmation confirmationIds={confirmationIds} />
      ) : (
        <Card>
          {!loading ? (
            <AppointmentReusableForm />
          ) : (
            <BlockLoadingSpinner>
              <StyledSpinner />
            </BlockLoadingSpinner>
          )}
        </Card>
      )}
    </>
  )
}
