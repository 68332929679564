import React, { useState } from 'react'

import { DockCapacity } from 'components/models/DockCapacity'
import { ButtonGroup, SIZE, SHAPE } from 'baseui/button-group'
import { capitalize } from 'lodash'
import StyledSpinner from 'components/shared/styled-spinner'
import Button from 'components/ui/generic/Button'
import FormControl from 'components/ui/generic/FormControl'

interface EquipmentType {
  record: any
  setRecord: any
  dockCapacitiesAttributes: DockCapacity[]
  label: string
  recordAttributeName: string
}

const EquipmentType = ({
  record,
  setRecord,
  dockCapacitiesAttributes,
  label,
  recordAttributeName
}: EquipmentType) => {
  const [selected, setSelected] = useState<number>(
    dockCapacitiesAttributes?.findIndex(
      element => element.equipmentTypeId === record.equipmentTypeId
    )
  )

  return (
    <>
      {dockCapacitiesAttributes ? (
        <FormControl label={label}>
          <ButtonGroup
            onClick={(_event, index) => {
              setSelected(index)
              setRecord({
                ...record,
                [recordAttributeName]:
                  dockCapacitiesAttributes && dockCapacitiesAttributes[index]?.equipmentTypeId
              })
            }}
            overrides={{
              Root: { style: { flexWrap: 'wrap', gap: '10px' } }
            }}
            selected={selected}
            size={SIZE.compact}
            shape={SHAPE.pill}>
            {dockCapacitiesAttributes?.map(
              (capacities: DockCapacity, index: number) =>
                !capacities.appointmentTypeId && (
                  <Button key={index}>{capitalize(capacities.equipmentType.name)}</Button>
                )
            )}
          </ButtonGroup>
        </FormControl>
      ) : (
        <StyledSpinner />
      )}
    </>
  )
}

export default EquipmentType
