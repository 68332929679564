import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import EditAppointmentModal from 'components/shipper/instant-calendar/modals/edit-appointment-modal'
import {
  CustomLabels,
  INITIAL_CUSTOM_FIELD_NAMES,
  CustomLabelKey,
  CustomLabelsContext
} from 'components/contexts/custom-labels-context'
import { CarriersProvider } from '../contexts/carriers.context'

export const AppointmentContext = React.createContext({
  appointment: null,

  setAppointment: null
})

const AppointmentForm = ({ match }) => {
  const [redirect, setRedirect] = useState(false)
  const [customLabels, setCustomLabels] = useState<CustomLabels>(INITIAL_CUSTOM_FIELD_NAMES)

  const customLabel = (customLabelKey: CustomLabelKey) =>
    customLabels?.[customLabelKey] || INITIAL_CUSTOM_FIELD_NAMES[customLabelKey]

  if (redirect) {
    return <Redirect to={'/appointments'} />
  }

  return (
    <CustomLabelsContext.Provider value={{ customLabels, setCustomLabels, customLabel }}>
      <CarriersProvider>
        <EditAppointmentModal
          selectedEvent={{ id: match.params.handle }}
          isOpen
          close={() => setRedirect(true)}
        />
      </CarriersProvider>
    </CustomLabelsContext.Provider>
  )
}

export default AppointmentForm
