import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import React from 'react'
import { KIND as ButtonKind } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { useCarriersContext } from 'components/contexts/carriers.context'

const DeleteCarrierConfirmationModal = () => {
  const {
    carriers: { carrierToDelete },
    actions: { setCarrierToDelete, handleDeleteCarrier }
  } = useCarriersContext()
  const { t } = useTranslation()

  return (
    <Modal
      onClose={() => setCarrierToDelete(undefined, false)}
      closeable
      isOpen={!!carrierToDelete}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      unstable_ModalBackdropScroll>
      <ModalHeader>{`${t('Settings.Carriers.ConfirmRemoveModal.Header.Text')} - ${
        carrierToDelete?.name
      }`}</ModalHeader>
      <ModalBody>{t('Settings.Carriers.ConfirmRemoveModal.SubTitle.Text')}</ModalBody>
      <ModalFooter>
        <ModalButton
          kind={ButtonKind.tertiary}
          onClick={() => {
            setCarrierToDelete(undefined, false)
          }}>
          {t('Settings.Carriers.ConfirmRemoveModal.CancelButton.Text')}
        </ModalButton>
        <ModalButton
          onClick={() => {
            handleDeleteCarrier()
          }}>
          {t('Settings.Carriers.ConfirmRemoveModal.ConfirmButton.Text')}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteCarrierConfirmationModal
