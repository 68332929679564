import { endsWith, camelCase } from "lodash";

const hitTransformer = (hit) => {
  const keys = Object.keys(hit);
  const formattedHit = {};
  keys.map((key) => {
    const camelCasedKey = camelCase(key);
    if (
      (endsWith(key, "at") || endsWith(key, "time")) && // In Ruby on Rails-Landia, date fields usually end in the word 'at' (eg: createdAt, updatedAt, etc.)
      typeof hit[key] === "number" &&
      hit[key] > 1600000000
    ) {
      formattedHit[camelCasedKey] =
        hit[key] === -1 ? null : new Date(hit[key] * 1000);
    } else if (hit[key] === "") {
      // Typesense gets mad if we try to serialize null values.
      formattedHit[camelCasedKey] = null;
    } else {
      formattedHit[camelCasedKey] = hit[key];
    }
  });
  return formattedHit;
};

export default hitTransformer;
