export interface Question {
  id: string
  answer_type: string
  audience_type?: string | null
  appointment_type_id: string
  caption?: string | null
  disabled?: boolean | null
  dock_types: string[]
  min_length?: number | null
  max_length?: number | null
  position: number
  prompt: string
  created_at: Date | string
  updated_at: Date | string
}

export const TEXT_TYPE = 'text'
export const DOCUMENT_TYPE = 'document'
export const ADDRESS_TYPE = 'address'
export const CONFIRMATION_TYPE = 'confirmation'
export const DATE_TYPE = 'date_picker'
export const DROPDOWN_LIST = 'dropdown_list'

export type AnswerType =
  | typeof TEXT_TYPE
  | typeof DOCUMENT_TYPE
  | typeof ADDRESS_TYPE
  | typeof CONFIRMATION_TYPE
  | typeof DATE_TYPE
  | typeof DROPDOWN_LIST

export const ANSWER_TYPES = [
  TEXT_TYPE,
  DOCUMENT_TYPE,
  // ADDRESS_TYPE,  currently not working nor is it rendering something in the FE
  // CONFIRMATION_TYPE,  currently not working nor is it rendering something in the FE
  DATE_TYPE,
  DROPDOWN_LIST
]

export type QuestionForm = Omit<Question, 'id' | 'created_at' | 'updated_at'>
