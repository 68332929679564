export const validateUser = user => {
  return (
    !user?.email_address?.trim() ||
    !user?.name?.trim() ||
    !user?.facilities?.length ||
    new Date(user.end_shift_date) < new Date(user.start_shift_date) ||
    compareTimes(user)
  )
}

export const validateCarrierUser = user => {
  return !user?.email_address?.trim() || !user?.name?.trim()
}

const compareTimes = user => {
  if (user?.start_shift_date && user?.end_shift_date) {
    const startTimeOnly = new Date(user?.start_shift_date)
    const endTimeOnly = new Date(user?.end_shift_date)

    startTimeOnly.setFullYear(1970, 0, 1)
    endTimeOnly.setFullYear(1970, 0, 1)

    if (endTimeOnly < startTimeOnly) {
      return true
    }
  }

  return false
}
