import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import React from 'react'
import { FlexContainerProps, RecurringDays } from './recurring-field.styled'
import { DatePicker } from 'baseui/datepicker'
import Checkbox from 'components/ui/generic/Checkbox'
import { useAppointmentContext } from 'components/contexts/appointment-context'
import { useTranslation } from 'react-i18next'

const getDay = (index: number) => {
  switch (index) {
    case 0:
      return 'Sun'
    case 1:
      return 'Mon'
    case 2:
      return 'Tue'
    case 3:
      return 'Wed'
    case 4:
      return 'Thu'
    case 5:
      return 'Fri'
    case 6:
      return 'Sat'
  }
}

const RecurringField = () => {
  const { state, actions } = useAppointmentContext()
  const { t } = useTranslation()

  const {
    appointments: {
      inbound: { arrivalTime }
    },
    recurring: { endDate, recurringDays }
  } = state
  const { setRecurring } = actions

  return (
    <>
      <FlexGridItem>
        <div>
          <FlexGrid flexGridColumnCount={[1, 1, 1, 2]} flexGridColumnGap="scale200">
            <FlexGridItem {...FlexContainerProps}>
              <FormControl
                label={t(
                  'Appointments.CreateAppointmentModal.Fields.Recurring.EndDate.Label.Text'
                )}>
                <DatePicker
                  minDate={arrivalTime}
                  value={endDate || []}
                  formatString="MM/dd/yyyy"
                  onChange={({ date }) => {
                    setRecurring({
                      endDate: date
                    })
                  }}
                />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem>
              <FormControl
                label={t(
                  'Appointments.CreateAppointmentModal.Fields.Recurring.RepeatOn.Label.Text'
                )}>
                <RecurringDays>
                  {[0, 1, 2, 3, 4, 5, 6].map((day, i) => (
                    <Checkbox
                      key={`${day}${i}`}
                      checked={recurringDays.includes(day)}
                      onChange={() => {
                        if (recurringDays.includes(day)) {
                          setRecurring({
                            recurringDays: recurringDays.filter(
                              (selectedDay: number) => selectedDay !== day
                            )
                          })
                        } else {
                          setRecurring({ recurringDays: [...recurringDays, day] })
                        }
                      }}
                      label={getDay(day)}
                    />
                  ))}
                </RecurringDays>
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
        </div>
      </FlexGridItem>
    </>
  )
}

export default RecurringField
