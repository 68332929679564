import React, { useState, useRef, useContext } from 'react'
import { Value } from 'baseui/select'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from '../hooks/use-local-storage'
import { CHECKIN, SLOT, FTL, LTL, DOCK_BLOCK } from './toggles/event-type'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { fancyToast } from '../../utils'
import { DOCK } from './toggles/dock'
import {
  CALENDAR_TOGGLES,
  initialCalendarToggles,
  CalendarToggleContext
} from '../../contexts/calendar-toggle-context'
import { CalendarReferenceContext } from '../../contexts/calendar-reference-context'
import GuestCheckinModal from '../modals/guest-checkin-modal'
import CreateAppointment from '../modals/create-appointment'
import EditAppointment from '../modals/edit-appointment'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { ShipperIdContext } from '../../contexts/shipper-id-context'
import DesktopCalendar from './calendar-components/desktop-calendar'
import ModalSwitcher from '../modals/modal-switcher'

const AdjustableCalendar = ({ shipperId }) => {
  const history = useHistory()
  const [selectedEvent, setSelectedEvent] = useState(null)
  const { currentUser } = useContext(CurrentUserContext)

  const [open, setOpen] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [calendarToggles, setCalendarToggles] = useLocalStorage(
    CALENDAR_TOGGLES,
    initialCalendarToggles
  )

  const calendarReference = useRef(null)

  const onEventClick = props => {
    if (!currentUser || !currentUser.id) {
      return
    }

    const { event } = props
    const { id, start, backgroundColor } = event
    const {
      type,
      facilityId,
      dockId,
      index,
      purchaseOrderIdentifiers,
      slot,
      repeated,
      customerName
    } = event.extendedProps
    const { metaKey, ctrlKey } = props.jsEvent
    const eventClicked = [SLOT, FTL, LTL].includes(type)
    if (type === CHECKIN) {
      setSelectedEvent({
        id,
        type
      })
    } else if (eventClicked && (metaKey || ctrlKey)) {
      window.open(`/appointments/${id}`)
    } else if (type === DOCK_BLOCK) {
      history.push(`/dock_blocks/${id}`)
    } else if (eventClicked) {
      setSelectedEvent({
        id: slot || repeated ? null : id,
        facilityId,
        dockId,
        arrivalTime: start,
        index,
        color: backgroundColor,
        type: slot ? SLOT : type,
        purchaseOrderIdentifier: purchaseOrderIdentifiers,
        customerName,
        originalId: id
      })
    }
  }

  const onDateClick = props => {
    const { resource, date } = props
    const { facilityId, dockId, dockType } = resource.extendedProps
    if (currentUser && currentUser.id && selectedEvent === null) {
      setSelectedEvent({
        facilityId,
        dockId,
        dockType,
        arrivalTime: date,
        color: '#000000', // setting a default color, so the color picker knows to populate.
        type: SLOT
      })
    }
  }

  const drop = async props => {
    if (!currentUser || !currentUser.id) {
      return
    }
    const { resource, date } = props
    const { dockId } = resource.extendedProps
    const endTime = new Date(date.getTime())
    endTime.setHours(endTime.getHours() + 1)

    const dockBlock = {
      name: 'Exception',
      startTime: date,
      endTime,
      dockId
    }

    const [dockBlockResponse, status] = await authenticatedFetch({
      path: `/dock_blocks.json`,
      method: 'POST',
      body: {
        dockBlock
      }
    })

    if (status === 201) {
      setSelectedDate(new Date(selectedDate))
    } else {
      fancyToast(dockBlockResponse, status)
    }
  }

  const eventAllow = (dragInfo, droppedEvent) => {
    const dockBlock = droppedEvent.extendedProps.type === DOCK_BLOCK
    if (!dockBlock || !currentUser) {
      return false
    }

    if (droppedEvent.id) {
      return dragInfo.resource.id === droppedEvent.extendedProps.dockId
    }
    return dragInfo.resource.extendedProps.type === DOCK
  }

  // Ok, so we need this, because otherwise we would end up with two events on the calendar.
  // One that gets added and managed with the rest of the event information, and another
  // that fullcalendar adds to it's internal state. We need to remove the internal state one
  const eventReceive = ({ event }) => {
    event.remove()
  }

  const updateEvent = ({ event }) => {
    authenticatedFetch({
      path: `/dock_blocks/${event.id}.json`,
      method: 'PATCH',
      body: {
        dockBlock: { startTime: event.start, endTime: event.end }
      }
    })
  }

  return (
    <ShipperIdContext.Provider value={{ shipperId }}>
      <CalendarToggleContext.Provider
        value={{
          calendarToggles,
          setCalendarToggles,
          selectedDate,
          setSelectedDate,
          selectedEvent,
          setSelectedEvent
        }}>
        <CalendarReferenceContext.Provider value={{ calendarReference }}>
          <ModalSwitcher />
          <DesktopCalendar
            {...{
              open,
              setOpen,
              onEventClick,
              onDateClick,
              drop,
              eventAllow,
              eventReceive,
              updateEvent
            }}
          />
        </CalendarReferenceContext.Provider>
      </CalendarToggleContext.Provider>
    </ShipperIdContext.Provider>
  )
}
export default AdjustableCalendar
