import { Button } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { FormControl } from 'baseui/form-control'

import * as React from 'react'

const DockType = ({ facility, appointment, setAppointment }) => {
  return (
    <FormControl label="Equipment Type">
      <ButtonGroup
        mode="radio"
        selected={facility.dockTypes.indexOf(appointment.dockType)}
        onClick={(_event, index) => {
          setAppointment({
            ...appointment,
            dockType: facility.dockTypes[index]
          })
        }}
      >
        {facility.dockTypes.map((dockType, index) => {
          return <Button key={index}>{dockType}</Button>
        })}
      </ButtonGroup>
    </FormControl>
  )
}

export default DockType
