import { useStyletron } from 'baseui'
import { Tag, VARIANT } from 'baseui/tag'
import React from 'react'
import Checkbox from './Checkbox'

const VCheckboxItem = ({ label, value, count, isRefined, refine }) => {
  const [css, theme] = useStyletron()
  return (
    <div
      className={css({
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.sizing.scale0
      })}>
      <Checkbox
        overrides={{
          Label: {
            style: ({ $theme }) => ({
              fontSize: $theme.typography.LabelXSmall.fontSize,
              textTransform: 'capitalize'
            })
          }
        }}
        label={label}
        checked={isRefined}
        onChange={() => refine(value)}
      />
      <Tag
        closeable={false}
        variant={VARIANT.solid}
        overrides={{
          Text: {
            style: ({ $theme }) => ({
              fontSize: $theme.sizing.scale400,
              color: $theme.colors.primary
            })
          },
          Root: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.backgroundTertiary,
              marginBottom: 0,
              marginTop: '0',
              marginRight: '0'
            })
          }
        }}>
        {count}
      </Tag>
    </div>
  )
}
export default VCheckboxItem
