export const SET_ORDER_PREFERENCES = 'SET_ORDER_PREFERENCES'
export const SET_LOADING = 'SET_LOADING'

export const orderPreferencesReducer = (state, action) => {
  switch (action.type) {
    case SET_ORDER_PREFERENCES: {
      return {
        ...state,
        orderPreference: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    default:
      return state
  }
}

const finalOrderPreferencesReducer = ({ orderPreferences }, action) => {
  return {
    orderPreferences: orderPreferencesReducer(orderPreferences, action)
  }
}

export default finalOrderPreferencesReducer
