import http from 'components/utils/authenticated-fetch'
import { Trailer, TrailerDetails } from 'components/models/Trailer'
import { fancyToast } from 'components/utils'
import { StatusCodes } from 'components/constants/http-status-codes'
import { PAGE_SIZE } from 'components/constants/page-size'
import { PaginationResponse } from 'components/types/pagination-response'

const DEFAULT_PARAMS = {
  search: '',
  page: 1,
  pageSize: PAGE_SIZE,
  queryParamsFilters: '',
  facilityId: [],
  active: false
}

class TrailerService {
  getUrl = (trailerId?: string) => `/trailers${trailerId ? `/${trailerId}.json` : '.json'}`

  getTrailerById = (trailerId: string): Promise<[any, number]> =>
    http({
      path: this.getUrl(trailerId)
    })

  getTrailerDetailsById = (trailerId: string): Promise<[TrailerDetails, number]> =>
    http({ path: `/trailers/${trailerId}/details.json` })

  getTrailers = (active: boolean = false, search: string = null): Promise<[any, number]> => {
    const url = this.getUrl()

    const params = new URLSearchParams()
    if (search) {
      params.append('search', search)
    }
    if (active) {
      params.append('active', 'true')
    }
    return http({
      path: `${url}?${params.toString()}`
    }).then(resp => resp)
  }

  getTrailerPaginated = (params = DEFAULT_PARAMS): Promise<PaginationResponse<Trailer>> => {
    const query = new URLSearchParams()
    const { queryParamsFilters } = params
    if (params?.page) query.set('page', String(params.page))
    if (params?.pageSize) query.set('page_size', String(params.pageSize))
    if (params?.search) query.set('search', params.search)
    if (params?.active) query.set('active', 'true')
    params?.facilityId.forEach(id => {
      query.append(`facility_ids[]`, id)
    })

    return http({
      path: `/trailers/paginate.json?${query.toString()}${
        queryParamsFilters ? queryParamsFilters : ''
      }`,
      method: 'GET'
    }).then(([data]) => data)
  }

  getTrailerOpenScheduling = (
    subdomain: string,
    facilityId: string,
    search?: string
  ): Promise<[any, number]> => {
    const params = new URLSearchParams()

    if (search) {
      params.append('search', search)
    }
    if (subdomain) {
      params.append('subdomain', subdomain)
    }
    if (facilityId) {
      params.append('facility_id', facilityId)
    }
    return http({
      path: `/trailers/trailers_open_scheduling.json?${params.toString()}`
    })
  }

  searchTrailers = (search: string): Promise<any> =>
    http({
      path: `/search.json`,
      method: 'POST',
      body: {
        search: {
          indexName: 'trailers',
          params: {
            query: search
          }
        }
      }
    }).then(([{ hits }, status]) => [hits, status])
}

export const trailerService = new TrailerService()
