import { useStyletron } from 'baseui'
import { SHAPE } from 'baseui/button'
import { InstantAppointment } from 'components/appointments/types'
import { useCalendarSelectedOptionsContext } from 'components/contexts/calendar-selected-options.context'
import { CustomLabelsContext } from 'components/contexts/custom-labels-context'
import { useEquipmentTypesContext } from 'components/contexts/equipment-types.context'
import TimeFormatter from 'components/utils/time-formatter'
import hitTransformer from 'cyber/search/hit-transformer'
import { SearchStateContext } from 'cyber/search/search'
import { compact, sortBy, capitalize } from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Printer } from '@phosphor-icons/react'
import { connectHits } from 'react-instantsearch-dom'
import { useReactToPrint } from 'react-to-print'
import './print.scss'
import { SearchStatusContext } from 'components/shipper/instant-calendar/toggles/quick-actions'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { appointmentService } from 'components/services/appointment.service'
import { DATE_TYPE } from 'components/models/Question'
import moment from 'moment'
import DEFAULT_DATE_FORMAT from 'components/constants/default-date-format'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'
import { Block } from 'baseui/block'
import { CalendarReferenceContext } from 'components/contexts/calendar-reference-context'
import { getDateFilterFormat } from '../../calendar-hits'

export const NonConnectedPrintButton = ({ hits, selectedDate }) => {
  const { customLabel } = useContext(CustomLabelsContext)
  const [css] = useStyletron()
  const componentRef = useRef()
  const [loading, setLoading] = useState<boolean>(false)
  const [shipper, setShipper] = useState(null)
  const [readyToPrint, setReadyToPrint] = useState<boolean>(false)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    onAfterPrint: () => {
      setLoading(false)
      setReadyToPrint(false)
    }
  })
  const [, setPrintCounter] = useState(0)
  const [appointmentType, setAppointmentType] = useState(null)
  const [appointmentAnswers, setAppointmentAnswers] = useState({})
  const { currentUser } = useContext(CurrentUserContext)
  const { weekMode, cacheDate } = useContext(CalendarReferenceContext)

  const { shipperId } = currentUser

  const { isSearchStalled } = useContext(SearchStatusContext)

  const { t } = useTranslation()

  useEffect(() => {
    if (!isSearchStalled) setPrintCounter(prevState => prevState + 1)
  }, [isSearchStalled])

  const { equipmentTypeState, actions } = useEquipmentTypesContext()
  const { calendarSelectedOptionsState } = useCalendarSelectedOptionsContext()
  const searchState = useContext(SearchStateContext)

  useEffect(() => {
    if (
      calendarSelectedOptionsState.appType.length === 1 &&
      calendarSelectedOptionsState?.appType[0]?.name !== 'All'
    ) {
      authenticatedFetch({
        path: `/appointment_types/${calendarSelectedOptionsState.appType[0].id}.json`
      }).then(([json]) => {
        setAppointmentType(json)
      })
    }
  }, [calendarSelectedOptionsState])

  useEffect(() => {
    readyToPrint && handlePrint()
  }, [readyToPrint])

  useEffect(() => {
    setLoading(true)
    actions.fetchAllAvailableEqTypes()
    authenticatedFetch({ path: `/shippers/${shipperId}.json` })
      .then(([shipper]) => {
        setShipper(shipper)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }, [shipperId])

  const print = async () => {
    setLoading(true)
    const results: Array<Promise<any>> = []
    hits.forEach(result => {
      results.push(getAnsweredQuestions(result))
    })
    const responses = await Promise.all(results)
    const finalAsnwers = Object.assign({}, ...responses)
    setAppointmentAnswers(finalAsnwers)
    setReadyToPrint(true)
  }

  const getAnsweredQuestions = async record => {
    const [answer, status] = await appointmentService.getAnsweredQuestions(record.id)
    if ([304, 200].includes(status)) {
      return {
        [record.id]: answer?.filter(ans => ans?.question?.showOnPrint)
      }
    }
  }

  const title = compact([
    calendarSelectedOptionsState?.appType[0]?.name !== 'All'
      ? calendarSelectedOptionsState?.appType[0]?.name
      : '',
    `Schedule for `,
    cacheDate &&
    cacheDate[currentUser.shipperId] &&
    cacheDate[currentUser.shipperId][currentUser.id] &&
    cacheDate[currentUser.shipperId][currentUser.id][0] &&
    cacheDate[currentUser.shipperId][currentUser.id][1]
      ? ` ${
          !weekMode
            ? new TimeFormatter('shortDate').format(
                new Date(cacheDate[currentUser.shipperId][currentUser.id][0])
              )
            : `${new TimeFormatter('shortDate').format(
                getDateFilterFormat(selectedDate, true)[0]
              )} -  ${new TimeFormatter('shortDate').format(
                getDateFilterFormat(selectedDate, true)[1]
              )}`
        }`
      : new TimeFormatter('shortDate').format(calendarSelectedOptionsState.date)
  ]).join(' ')

  return (
    <>
      {/* Blagh, this table cant be a sub component. The react-to-print library requires
          child component to be a class component. I cant figure out
          how to pass the ref to a class component. SAD! */}
      <div className={css({ display: 'none' })}>
        <style type="text/css">{'@media print{@page {size: landscape}}'}</style>
        <div className="printable-table" ref={componentRef}>
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            })}>
            <span>
              {hits
                .map(hit => hitTransformer(hit))
                .map(hit => hit.facilityName)
                .filter((hit, index, arr) => arr.indexOf(hit) === index)
                .join(' - ')}
            </span>
            <span>{shipper && shipper.name}</span>
          </div>
          <h1>
            {weekMode && '(Week) '}
            {title}
          </h1>
          <table>
            <colgroup>
              <col className="arrival-time" />
              <col className="appointment-id" />
              {calendarSelectedOptionsState?.appType[0]?.name ===
                t('Common.AppointmentTypeToggle.AllOption.Text') && (
                <col className="appointment-type" />
              )}
              <col className="purchase-order-identifiers" />
              <col className="equipment-type" />
              <col className="scheduler" />
            </colgroup>
            <thead>
              <tr>
                <th>Arrival Time</th>
                <th>Appointment Id</th>
                {calendarSelectedOptionsState?.appType[0]?.name ===
                  t('Common.AppointmentTypeToggle.AllOption.Text') && <th>Appointment Type</th>}
                <th>
                  {calendarSelectedOptionsState?.appType[0]?.name ===
                  t('Common.AppointmentTypeToggle.AllOption.Text')
                    ? 'Order/Reference Number'
                    : customLabel('purchaseOrderIdentifiers') || 'Order/Reference Number'}
                </th>
                <th>Equipment Type</th>
                <th>
                  {calendarSelectedOptionsState?.appType[0]?.name ===
                  t('Common.AppointmentTypeToggle.AllOption.Text')
                    ? 'Company'
                    : customLabel('scheduler') || 'Company'}
                </th>
                <th>{t('Common.Fields.CarrierName.Text')}</th>
                {calendarSelectedOptionsState?.appType[0]?.name !==
                  t('Common.AppointmentTypeToggle.AllOption.Text') &&
                  appointmentType?.questions
                    ?.filter(q => q?.showOnPrint)
                    ?.map(q => <th>{q.prompt}</th>)}
              </tr>
            </thead>
            <tbody>
              {sortBy(hits, 'arrival_time')
                .map(hitTransformer)
                .map((hit: InstantAppointment, i) => {
                  return (
                    <tr key={i}>
                      <td>{new TimeFormatter('localCalendarTime').format(hit.arrivalTime)}</td>
                      <td>{hit.confirmationId}</td>
                      {calendarSelectedOptionsState?.appType[0]?.name ===
                        t('Common.AppointmentTypeToggle.AllOption.Text') && (
                        <td>{capitalize(hit.appointmentTypeName)}</td>
                      )}
                      <td>{hit.purchaseOrderIdentifiers.join(', ')}</td>
                      <td>
                        {capitalize(
                          equipmentTypeState.allAvailableEqTypes?.find(
                            type => type.id === hit.equipmentTypeId
                          )?.name
                        )}
                      </td>
                      <td>{hit.schedulerName}</td>
                      <td>{hit?.carrierName}</td>
                      {calendarSelectedOptionsState?.appType[0]?.name !== 'All' &&
                        appointmentAnswers[hit.id]
                          ?.sort((a, b) => a.question.position - b.question.position)
                          ?.map(({ type, response }) => (
                            <td>
                              {type === DATE_TYPE
                                ? moment(response).format(DEFAULT_DATE_FORMAT)
                                : response}
                            </td>
                          ))}
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>

      <Block display={['none', 'none', 'block']}>
        <Button
          data-testid={'print-button'}
          className="print-button"
          disabled={!searchState?.range}
          isLoading={loading}
          onClick={() => {
            print()
          }}
          shape={SHAPE.circle}>
          <Printer />
        </Button>
      </Block>
    </>
  )
}

const PrintButton = connectHits(NonConnectedPrintButton) as any

export default PrintButton
