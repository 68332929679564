import React, { Dispatch } from 'react'

interface FacilitiesContextProps {
  facilities: any
  slotDuration: string
  selectedDate: Date
  reloadOverCapacityAlertToggle?: boolean
  setSelectedDate: Dispatch<Date>
  setReloadOverCapacityAlertToggle?: (value: boolean) => void
}

export const FacilitiesContext = React.createContext<FacilitiesContextProps>({
  facilities: {},
  slotDuration: '00:30',
  selectedDate: new Date(),
  reloadOverCapacityAlertToggle: false,
  setReloadOverCapacityAlertToggle: (value: boolean) => '',
  setSelectedDate: () => ''
})
