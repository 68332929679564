import React from "react";
import { HeadingLevel } from "baseui/heading";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import TwilioPhoneNumber from "../../fields/twilio-phone-number";

const CreateDriver = ({ user, setUser }) => {
  return (
    <HeadingLevel>
      <FormControl label="Carrier Name">
        <Input
          placeholder="J.B. Hunt, Ryder, Landstar, etc."
          autoComplete="off"
          name="user[carrier_name]"
          required
          value={user?.carrierName}
          onChange={(e) => {
            setUser({ ...user, carrierName: e.currentTarget.value });
          }}
        />
      </FormControl>
      <FormControl label="Name">
        <Input
          placeholder="John Smith"
          autoComplete="off"
          name="user[name]"
          required
          value={user?.name || ""}
          onChange={(e) => {
            setUser({ ...user, name: e.currentTarget.value });
          }}
        />
      </FormControl>
      <FormControl label="Phone Number">
        <TwilioPhoneNumber record={user} setRecord={setUser} />
      </FormControl>
    </HeadingLevel>
  );
};

export default CreateDriver;
