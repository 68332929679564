import React, { useContext } from "react";
import { Checkbox } from "baseui/checkbox";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { CalendarToggleContext } from "../../../contexts/calendar-toggle-context";
import { CalendarReferenceContext } from "../../../contexts/calendar-reference-context";

export const FTL = "FTL";
export const LTL = "LTL";
export const DOCK_BLOCK = "Blocks";
export const CHECKIN = "Checkins";
export const SLOT = "Slot";

export const EVENT_TYPES = [FTL, LTL, DOCK_BLOCK, CHECKIN];

const EventType = () => {
  const { calendarReference } = useContext(CalendarReferenceContext);
  const { calendarToggles, setCalendarToggles } = useContext(
    CalendarToggleContext
  );
  const { selectedEventTypes } = calendarToggles;

  return (
    <FlexGrid flexGridColumnCount={2}>
      {EVENT_TYPES.map((eventType, index) => {
        return (
          <FlexGridItem key={index}>
            <Checkbox
              checked={selectedEventTypes.indexOf(index) > -1}
              onChange={() => {
                if (!selectedEventTypes.includes(index)) {
                  setCalendarToggles({
                    ...calendarToggles,
                    selectedEventTypes: [...selectedEventTypes, index],
                  });
                } else {
                  setCalendarToggles({
                    ...calendarToggles,
                    selectedEventTypes: selectedEventTypes.filter(
                      (value) => value !== index
                    ),
                  });
                }
              }}
            >
              {eventType}
            </Checkbox>
          </FlexGridItem>
        );
      })}
    </FlexGrid>
  );
};

export default EventType;
