export const DEFAULT_SHIPPER_PERMISSION = {
  canEdit: true,
  canView: true,
  required: false,
  showOnCreate: true
}
export const DEFAULT_SCHEDULER_PERMISSION = {
  canEdit: true,
  canView: true,
  required: true,
  showOnCreate: true
}

export const DEFAULT_DRIVER_PERMISSION = {
  canEdit: true,
  canView: true,
  required: true,
  showOnCreate: true,
  postCheckin: false
}
