import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import GenericButton from 'components/ui/generic/Button'
import {
  COMPLETE_TAB,
  NEW_TAB,
  PENDING_TAB,
  useYardJockeyContext
} from '../../contexts/yard-jockey.context'
import { PAGE_SIZE } from '../../constants/page-size'
import { PaginationButtonStyles } from './YardJockey.styled'
import { Block } from 'baseui/block'

const YardJockeyPagination = () => {
  const { t } = useTranslation()

  const {
    page,
    counters,
    actions: { loadMoreTasks }
  } = useYardJockeyContext()

  const areThereMoreTasks = useMemo(() => {
    const totalNumberOfTasks = counters[COMPLETE_TAB] + counters[NEW_TAB] + counters[PENDING_TAB]
    return !(totalNumberOfTasks < page * PAGE_SIZE)
  }, [page, counters])

  return (
    <Block display="flex" width="100%" justifyContent="center" alignItems="center">
      {areThereMoreTasks && (
        <GenericButton
          disabled={!areThereMoreTasks}
          onClick={loadMoreTasks}
          overrides={PaginationButtonStyles}>
          {t('YardJockey.Table.Pagination.Text')}
        </GenericButton>
      )}
    </Block>
  )
}

export default YardJockeyPagination
