import React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { useTranslation } from 'react-i18next'
import FormControl from 'components/ui/generic/FormControl'
import NotificationOption from './notification-option'

const SubscriptionsForm = () => {
  const { t } = useTranslation()

  return (
    <FormControl label={t('Settings.UserProfile.Form.Fields.NotificationSubscription.Label.Text')}>
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale400" flexGridRowGap="scale400">
        <FlexGridItem>
          <NotificationOption
            title={t(
              'Settings.UserProfile.Form.Fields.NotificationSubscription.Fields.DriverChecksIn.Label.Text'
            )}
            preference="driverCheckedIn"
          />
        </FlexGridItem>
        <FlexGridItem>
          <NotificationOption
            title={t(
              'Settings.UserProfile.Form.Fields.NotificationSubscription.Fields.DriverChecksOut.Label.Text'
            )}
            preference="driverCheckedOut"
          />
        </FlexGridItem>
        <FlexGridItem>
          <NotificationOption
            title={t(
              'Settings.UserProfile.Form.Fields.NotificationSubscription.Fields.AppointmentRescheduled.Label.Text'
            )}
            preference="appointmentRescheduled"
          />
        </FlexGridItem>
        <FlexGridItem>
          <NotificationOption
            title={t(
              'Settings.UserProfile.Form.Fields.NotificationSubscription.Fields.AppointmentCancelled.Label.Text'
            )}
            preference="appointmentCancelled"
          />
        </FlexGridItem>
      </FlexGrid>
    </FormControl>
  )
}

export default SubscriptionsForm
