import React, { useState } from 'react'
import { MagnifyingGlass } from '@phosphor-icons/react'
import Input from 'components/ui/generic/Input'
import { Block } from 'baseui/block'
import { MagnifyingGlassIcon, YardJockeySearchBoxStyles } from './YardJockey.styled'
import { useDebounce } from 'react-use'
import { useYardJockeyContext } from '../../contexts/yard-jockey.context'
import { SIZE } from 'baseui/input'

const YardJockeySearchbox = ({ placeholder, collapsable }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [searchText, setSearchText] = useState(undefined)

  const {
    shiftActive,
    actions: { setTrailerNumber }
  } = useYardJockeyContext()

  useDebounce(
    () => {
      setTrailerNumber(searchText)
    },
    200,
    [searchText]
  )

  return (
    <Block display="flex">
      <Input
        startEnhancer={() => (
          <MagnifyingGlassIcon
            onClick={() => {
              if (shiftActive) {
                setIsExpanded(!isExpanded)
              }
            }}>
            <MagnifyingGlass size="14px" color="#81848a" />
          </MagnifyingGlassIcon>
        )}
        placeholder={placeholder}
        value={searchText}
        size={SIZE.default}
        onChange={e => {
          setSearchText(e.currentTarget.value)
        }}
        overrides={YardJockeySearchBoxStyles(collapsable, isExpanded)}
      />
    </Block>
  )
}

export default YardJockeySearchbox
