import {
  CONTAINER,
  FLATBED,
  RAIL,
  REEFER,
  TANKER,
  VAN,
  VAN_FLOOR,
  VAN_PALLET
} from 'components/docks/types'

export const DEFAULT_APPOINTMENT_DURATIONS = {
  [VAN]: 60,
  [FLATBED]: 60,
  [REEFER]: 60,
  [TANKER]: 120,
  [RAIL]: 120,
  [CONTAINER]: 60,
  [VAN_FLOOR]: 60,
  [VAN_PALLET]: 60
}
