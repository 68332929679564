import React, { useEffect } from 'react'
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid'
import CustomTimePicker from 'components/shared/custom-time-picker'
import { LabelSmall } from 'baseui/typography'
import { useTranslation } from 'react-i18next'

const TimeRange = ({ cachedTimeRange, setCachedTimeRange, currentUser, setAppliedFilters }) => {
  const timeRange =
    cachedTimeRange[currentUser.shipperId] && cachedTimeRange[currentUser.shipperId][currentUser.id]
      ? cachedTimeRange[currentUser.shipperId][currentUser.id]
      : cachedTimeRange
  const { t } = useTranslation()

  useEffect(() => {
    function syncCount() {
      if (
        setAppliedFilters &&
        (timeRange.minTime[0].label !== '00:00' || timeRange.maxTime[0].label !== '24:00')
      ) {
        setAppliedFilters(c => ({
          ...c,
          timeRange: { from: timeRange.minTime[0].label, to: timeRange.maxTime[0].label }
        }))
      } else if (
        setAppliedFilters &&
        timeRange.minTime[0].label === '00:00' &&
        timeRange.maxTime[0].label === '24:00'
      ) {
        setAppliedFilters(c => {
          const updatedFilters = { ...c }
          delete updatedFilters.timeRange
          return { ...updatedFilters }
        })
      }
    }
    syncCount()
  }, [setAppliedFilters, timeRange])

  return (
    <>
      <LabelSmall marginTop="scale200" marginBottom="scale400">
        {t('Common.FormControl.Titles.TimeRange.Text')}
      </LabelSmall>
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale400">
        <FlexGridItem>
          <CustomTimePicker
            value={timeRange.minTime}
            onChange={({ value }) => {
              setCachedTimeRange({
                ...cachedTimeRange,
                [currentUser.shipperId]: {
                  ...(cachedTimeRange[currentUser.shipperId] || {}),
                  [currentUser.id]: {
                    ...((cachedTimeRange[currentUser.shipperId] &&
                      cachedTimeRange[currentUser.shipperId][currentUser.id]) ||
                      cachedTimeRange),
                    minTime: value
                  }
                }
              })
            }}
          />
        </FlexGridItem>
        <FlexGridItem>
          <CustomTimePicker
            value={timeRange.maxTime}
            onChange={({ value }) => {
              setCachedTimeRange({
                ...cachedTimeRange,
                [currentUser.shipperId]: {
                  ...(cachedTimeRange[currentUser.shipperId] || {}),
                  [currentUser.id]: {
                    ...((cachedTimeRange[currentUser.shipperId] &&
                      cachedTimeRange[currentUser.shipperId][currentUser.id]) ||
                      cachedTimeRange),
                    maxTime: value
                  }
                }
              })
            }}
          />
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}

export default TimeRange
