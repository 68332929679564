import { CalendarSelectedOptionsState } from '../contexts/calendar-selected-options.context'

export const SET_DATE = 'SET_DATE'
export const SET_APP_TYPE = 'SET_APP_TYPE'

export const CalendarSelectedOptionsReducer = (state: CalendarSelectedOptionsState, action) => {
  switch (action.type) {
    case SET_DATE:
      return {
        ...state,
        date: action.payload
      }
    case SET_APP_TYPE:
      return {
        ...state,
        appType: action.payload
      }
    default:
      return state
  }
}
