import React, { useContext, useState, useEffect } from "react";
import { Input } from "baseui/input";
import { AppointmentContext } from "../../appointment-form";
import { findIndex } from "lodash";
import { Combobox } from "baseui/combobox";
import authenticatedFetch from "../../../utils/authenticated-fetch";

const useDebounce = function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value]
  );

  return debouncedValue;
};

const AddressAnswer = ({ question, answer }) => {
  const { appointment, setAppointment } = useContext(AppointmentContext);
  const [addresses, setAddresses] = useState<any[]>([]);
  const { response } = answer;
  const debouncedResponse = useDebounce(response, 250);
  const { id } = question;
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    const runSearch = async () => {
      const path = `/geocoder.json/?location=${response}`;
      const [json, status] = await authenticatedFetch({
        path,
      });

      if ([200, 204, 304].includes(status)) {
        setAddresses(json);
      }
    };

    if (debouncedResponse && !clicked) {
      runSearch();
    } else {
      setClicked(false);
      setAddresses([]);
    }
  }, [debouncedResponse]);

  return (
    <Combobox
      overrides={{ Input: { props: { placeholder: "Start typing..." } } }}
      name={`answer[${id}]`}
      value={response}
      onChange={(nextValue) => {
        let { answersAttributes } = appointment;

        const answerIndex = findIndex(answersAttributes, { questionId: id });
        answersAttributes[answerIndex].response = nextValue;
        setAppointment({ ...appointment, answersAttributes });
      }}
      options={addresses}
      mapOptionToString={(option) => option.label}
      mapOptionToNode={({ option }) => {
        return (
          <span
            onClick={() => {
              setClicked(true);
            }}
          >
            {option.label}
          </span>
        );
      }}
    />
  );
};

export default AddressAnswer;
