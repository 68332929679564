import React, { useCallback, useEffect } from 'react'
import { ParagraphSmall } from 'baseui/typography'
import { useTranslation } from 'react-i18next'
import FormControl from 'components/ui/generic/FormControl'
import Select from 'components/ui/generic/Select'
import { useFacilityContext } from 'components/contexts/facility-context'

type FacilitySelectProps = {
  disabled?: boolean
  selectedEvent?: any
}
const FacilitySelect = ({ disabled = false, selectedEvent }: FacilitySelectProps) => {
  const { t } = useTranslation()

  const { state, actions } = useFacilityContext()
  const { facility, facilityOptions, facilityRequestDone } = state
  const { setFacility } = actions

  useEffect(() => {
    if (selectedEvent?.facilityId && facilityOptions?.length > 0) {
      setFacility(facilityOptions.filter(f => f.id === selectedEvent?.facilityId)[0])
    }
  }, [selectedEvent?.facilityId, JSON.stringify(facilityOptions)])

  const handleChange = useCallback(
    params => {
      setFacility(params.option)
    },
    [setFacility]
  )

  if (facilityOptions.length === 0 && facilityRequestDone) {
    return (
      <ParagraphSmall color="red">
        {t(
          'Scheduler.OpenScheduling.Steps.Fields.Facility.Validations.NoFacilitiesAllowedWithOpenSchedulling.Text'
        )}
      </ParagraphSmall>
    )
  }

  return (
    <FormControl>
      <Select
        disabled={disabled}
        options={facilityOptions}
        value={[facility]}
        searchable={false}
        clearable={false}
        onChange={handleChange}
        label={t('Scheduler.OpenScheduling.Steps.Fields.Facility.Label.Text')}
      />
    </FormControl>
  )
}

export default FacilitySelect
