import React from 'react'
import { Tag } from 'baseui/tag'

const VTag = ({ children, overrides, ...props }) => {
  return (
    <Tag
      overrides={{
        ...overrides,
        Root: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.backgroundTertiary,
            color: $theme.colors.contentSecondary,
            border: 'none',
            ...overrides?.Root?.style()
          })
        },
        Text: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize,
            maxWidth: '13em',
            ...overrides?.Text?.style()
          })
        }
      }}
      {...props}>
      {children}
    </Tag>
  )
}
export default VTag
