import React, { useContext, useEffect, useState } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { CyberTable } from 'cyber/table'
import CopyButton from 'components/shared/fields/copy-button'
import { endsWith, camelCase } from 'lodash'
import { ParagraphLarge } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { FileSearch } from '@phosphor-icons/react'
import { StyledLink } from 'baseui/link'
import CarrierUserModal from './CarrierUserModal'
import { useCarrierUserContext } from 'components/contexts/carrier-user.context'
import { TYPESENSE_TIMESTAMP_THRESHOLD } from '../../constants/typesense-timestamp-threshold'
import { useTranslation } from 'react-i18next'
import StyledSpinner from '../../shared/styled-spinner'
import { MILISECONDS_FOR_SPINNER_IN_SETTINGS } from '../../constants/miliseconds-for-spinner-in-settings'
import { CurrentUserContext } from 'components/homepage/current-user-context'
const CARRIER_USER_DISABLED_KEY = 'carrierDisabled'
const Hits = props => {
  const {
    users: { userToUpdate },
    actions: { setCarrierUserToUpdate }
  } = useCarrierUserContext()
  const [css, theme] = useStyletron()
  const { hits, setNumberOfHits } = props
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const { currentUser } = useContext(CurrentUserContext)

  const getDisabledUser = user => {
    return !user?.carrierUsersShippersActive?.includes(currentUser.shipperId)
  }

  const formattedHits = hits.map(hit => {
    const keys = Object.keys(hit)
    const formattedHit = {}
    keys.map(key => {
      const camelCasedKey = camelCase(key)
      if (
        endsWith(key, 'at') &&
        typeof hit[key] === 'number' &&
        hit[key] > TYPESENSE_TIMESTAMP_THRESHOLD
      ) {
        formattedHit[camelCasedKey] = new Date(hit[key] * 1000).toISOString()
      } else if (hit[key] === '') {
        formattedHit[camelCasedKey] = null
      } else {
        formattedHit[camelCasedKey] = hit[key]
      }
    })
    formattedHit[CARRIER_USER_DISABLED_KEY] = getDisabledUser(formattedHit)
    return formattedHit
  })

  useEffect(() => {
    if (setNumberOfHits) {
      setNumberOfHits(hits.length)
    }
    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, MILISECONDS_FOR_SPINNER_IN_SETTINGS)

    return () => clearTimeout(timeoutId)
  }, [hits, setNumberOfHits])

  if (loading) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: theme.sizing.scale1600
        })}>
        <StyledSpinner />
      </div>
    )
  }

  if (hits.length === 0) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: theme.sizing.scale1600
        })}>
        <FileSearch size={theme.sizing.scale2400} />
        <ParagraphLarge>{t('Settings.CarrierUsers.Table.NoCarrierUsersFound.Text')}</ParagraphLarge>
      </div>
    )
  }

  return (
    <>
      {userToUpdate && <CarrierUserModal />}
      <CyberTable
        columns={[
          t('Settings.CarrierUsers.Table.Columns.Name.Label.Text'),
          t('Settings.CarrierUsers.Table.Columns.Carrier.Label.Text'),
          t('Settings.CarrierUsers.Table.Columns.Email.Label.Text'),
          t('Settings.CarrierUsers.Table.Columns.Active.Label.Text'),
          t('Settings.CarrierUsers.Table.Columns.Invite.Label.Text')
        ]}
        data={formattedHits.map(hit => {
          const { id, name, emailAddress, inviteId, carrierName } = hit
          return [
            <StyledLink
              data-testid={'carrier-user-name-link-button'}
              className={css({ cursor: 'pointer' })}
              onClick={() => setCarrierUserToUpdate(id)}>
              {name}
            </StyledLink>,
            carrierName,
            emailAddress,
            hit[CARRIER_USER_DISABLED_KEY]
              ? t('Settings.CarrierUsers.Table.Columns.Active.Alternatives.Inactive.Text')
              : t('Settings.CarrierUsers.Table.Columns.Active.Alternatives.Active.Text'),
            inviteId && (
              <CopyButton
                label={t('Settings.CarrierUsers.Table.Buttons.CopyButton.Text')}
                href={`${window.location.origin}/invites/${inviteId}`}
              />
            )
          ]
        })}
      />
    </>
  )
}

const ConnectedHits = connectHits(Hits) as any

export default ConnectedHits
