import React, { useEffect } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'

import { useTranslation } from 'react-i18next'
import Accordion from 'components/ui/generic/Accordion'
import CheckboxItem from 'components/ui/generic/CheckboxItem'

const RefinementList = props => {
  const { items, attribute, refine, setAppliedFilters } = props
  const refined = items.some(({ isRefined }) => isRefined)
  const { t } = useTranslation()

  useEffect(() => {
    if (refined && setAppliedFilters) {
      setAppliedFilters(c => ({
        ...c,
        [attribute]: items.filter(item => item.isRefined).map(item => item.label)
      }))
    } else if (setAppliedFilters) {
      setAppliedFilters(c => {
        const updatedFilters = { ...c }
        delete updatedFilters[attribute]
        return { ...updatedFilters }
      })
    }
  }, [refined, setAppliedFilters, attribute, items])

  return (
    <Accordion title={t('Settings.CarrierUsers.Table.Filters.Carrier.Text')}>
      {items.map((item, i) => {
        return <CheckboxItem {...item} refine={refine} key={i} />
      })}
    </Accordion>
  )
}

const ConnectedRefinementList = connectRefinementList(RefinementList) as any

export default ConnectedRefinementList
