import { InstantAppointment } from 'components/appointments/types'
import { getAnswers } from 'components/utils/format-answer-identifiers'
import hitTransformer from 'cyber/search/hit-transformer'
import moment from 'moment'
import {
  FIXED_DOCK_DOORS_COLUMN,
  FIXED_UNASSIGNED_COLUMN
} from '../../../contexts/yard-schedule.context'
import { FullCalendarEvent } from '../../../types/full-calendar-event'
import { DROP } from '../../../constants/handling_method'
import { OUTBOUND_TYPE } from '../../../constants/appointment_types'

export const INTEGRATIONS = 'integrations'
export const DAILY_PLAN = 'DAILY_PLAN'
export const DOCK_ASSIGNMENT = 'DOCK_ASSIGNMENT'
export const APPOINTMENT_TYPE = 'Appointment'
const APPOINTMENT_DURATION_FALLBACK = 60

function getTrailers(hits, facility) {
  let occupiedSlots = 0
  return {
    trailers: hits
      .map(hitTransformer)
      .filter(({ handlingMethod, appointmentTypeClass, shouldDisplayForDropOutbound }) => {
        return (
          handlingMethod === DROP &&
          (appointmentTypeClass === OUTBOUND_TYPE ? shouldDisplayForDropOutbound : true)
        )
      })
      .map((hit: InstantAppointment): FullCalendarEvent => {
        const {
          id,
          arrivalTime,
          purchaseOrderIdentifiers,
          equipmentTypeId,
          color,
          schedulerName,
          appointmentTypeId,
          recurringAppointmentBlueprintId,
          confirmationId,
          status,
          appointmentTypeName,
          dockName,
          dockTime,
          carrierName,
          identifiersJson,
          commodityType,
          carrierScac,
          handlingMethod,
          trailerNumber,
          trailerSlotZone,
          trailerSlotName,
          trailerDockName,
          trailerStatus,
          taskTime,
          driverName,
          trailerYardJockey,
          trailerId
        } = hit

        if (!trailerSlotName && !trailerDockName) {
          occupiedSlots = occupiedSlots + 1
        }

        const title =
          schedulerName?.toLowerCase() === INTEGRATIONS || !schedulerName ? '' : schedulerName
        const subtitle = [carrierName].filter(item => item).join(' | ')

        let duration =
          facility?.appointmentPreference?.appointmentDropDuration || APPOINTMENT_DURATION_FALLBACK

        const purchaseOrders =
          purchaseOrderIdentifiers.length > 0 ? purchaseOrderIdentifiers.join(', ') : null

        const identifiers = getAnswers(identifiersJson)

        return {
          id,
          color,
          start: arrivalTime.toISOString(),
          end: moment(arrivalTime).add(duration, 'minutes').toISOString(),
          title,
          resourceId:
            trailerSlotZone ||
            (trailerDockName ? FIXED_DOCK_DOORS_COLUMN.id : FIXED_UNASSIGNED_COLUMN.id),
          extendedProps: {
            handlingMethod: handlingMethod,
            purchaseOrderIdentifiers,
            equipmentTypeId,
            subtitle,
            appointmentTypeId,
            commodityType,
            type: APPOINTMENT_TYPE,
            recurringAppointmentBlueprintId,
            confirmationId,
            schedulerName,
            status,
            appointmentTypeName,
            purchaseOrders,
            carrierName,
            identifiers,
            carrierScac,
            dockTime,
            trailerNumber,
            trailerStatus,
            dockName,
            yardScheduleView: true,
            taskTime,
            driverName,
            trailerYardJockey,
            trailerId,
            slotName: trailerSlotName || trailerDockName
          }
        }
      }),
    unassignedTotals: { occupiedSlots }
  }
}

export default getTrailers
