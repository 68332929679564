import { CurrentUser } from '../users'

export const checkIfUserShiftIsActive = (currentUser: CurrentUser) => {
  if (currentUser?.lastShiftDateLog) {
    const shiftDate = new Date(currentUser?.lastShiftDateLog)
    const today = new Date()

    shiftDate.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)

    if (
      shiftDate.getFullYear() === today.getFullYear() &&
      shiftDate.getMonth() === today.getMonth() &&
      shiftDate.getDate() === today.getDate()
    ) {
      return true
    }
  }
  return false
}
