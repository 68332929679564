import scStyled from 'styled-components'

export const DraggableItem = scStyled.div`
  margin: 0 0 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #fff;
`

export const DraggableItemActive = scStyled(DraggableItem)`
  border: solid 1px #B5BED1;
`

export const TypeNameLabel = scStyled.div`
  margin-left: 8px;
  font-size: 14px;
`
