import React from 'react'
import { Step } from 'baseui/progress-steps'

const VStep = ({ title, children, overrides, ...props }) => {
  return (
    <Step
      title={title}
      overrides={{
        Title: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelSmall.fontSize,
            fontFamily: $theme.typography.HeadingXSmall.fontFamily
          })
        },
        ...overrides
      }}
      {...props}>
      {children}
    </Step>
  )
}
export default VStep
