import http from 'components/utils/authenticated-fetch'
import pluralize from 'pluralize'

class SearchService {
  getEntity = (entity: string, id: string): Promise<any> =>
    http({
      path: `/${pluralize(entity)}/${id}.json`
    }).then(([data]) => data)

  search = (entity: string, search: string): Promise<any> =>
    http({
      path: '/search.json',
      method: 'POST',
      body: {
        search: {
          indexName: entity,
          params: {
            query: search
          }
        }
      }
    }).then(([data]) => data)
}

export const searchService = new SearchService()
