import React from "react";
import { Block } from "baseui/block";
import { ChevronLeft, Menu, Overflow } from "baseui/icon";
import { useStyletron } from "baseui";

const MenuLayout = ({ sidebar, content, open, setOpen }) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: "flex",
      })}
    >
      {/* TODO(adenta): This used to be sticky, so as you scrolled throughout the page it would scroll with you */}
      <Block
        display="block"
        overrides={{
          Block: {
            style: {
              flexGrow: open ? 1 : 0,
              width: open ? "250px" : "0",
              transition: theme.animation.timing100,
              marginLeft: open ? "-5%" : null,
              overflowX: "hidden",
            },
          },
        }}
      >
        {sidebar}
      </Block>
      <div
        className={css({
          width: open ? "calc(100% - 250px)" : "100%",
          transform: `translate3d(0,0,0)`, // I don't know why I have to do this, to get the animation to work. A guide online told me to, and like ,it makes the animation look prettier
          flexGrow: 9,
        })}
      >
        {content}
      </div>
    </div>
  );
};

export default MenuLayout;
