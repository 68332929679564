import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { HeadingXSmall } from 'baseui/typography'
import { useDebounce } from 'react-use'
import { DocksSearchInput } from './DocksSearchInput'
import { facilityService } from 'components/services/facility.service'
import { docksService } from 'components/services/docks.service'
import { Spinner } from 'baseui/icon'
import { useStyletron } from 'baseui'
import { AddButton } from 'components/components/AddButton'
import { DockModal } from '../DockModal'
import { appointmentTypeService } from 'components/services/appointment-type.service'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { useTranslation } from 'react-i18next'
import DocksSearchHits from './DocksSearchHits'
import { FlexRow } from './DocksSearch.styled'
import StyledSpinner from 'components/shared/styled-spinner'

export const DocksSearch = ({ facility = null }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const isAdmin = useMemo(() => !!currentUser?.admin, [currentUser?.admin])
  const [css, theme] = useStyletron()
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [hits, setHits] = useState([])
  const [appointmentTypes, setAppointmentTypes] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    appointmentTypeService.getAppointmentTypes().then(types => setAppointmentTypes(types))
  }, [])

  const handleClose = () => {
    searchDocks()
    setOpen(false)
  }

  const searchDocks = async () => {
    setLoading(true)
    let hits = []
    let status = 200
    if (facility) {
      const docks = await facilityService.getFacilityDocks(facility.id)
      hits = docks
    } else {
      const [docks, _status] = await docksService.searchDocks(search)
      hits = docks
      status = _status
    }
    setLoading(false)
    if ([200, 304].includes(status)) {
      setHits(hits)
    }
  }

  useDebounce(searchDocks, 200, [search])

  return (
    <>
      <DockModal
        open={open}
        selectedDock={null}
        onModalClose={handleClose}
        appointmentTypes={appointmentTypes}
        facilityId={facility?.id}
      />

      <FlexRow>
        <HeadingXSmall marginTop="0" marginBottom="0">
          {facility ? facility.name : ''} {t('Docks.DocksSearch.Header.Text')}
        </HeadingXSmall>
        {facility && isAdmin && <AddButton onClick={() => setOpen(true)} />}
      </FlexRow>

      <FlexGrid flexGridRowGap="scale200">
        {!facility ? (
          <FlexGridItem>
            <DocksSearchInput search={search} onSearch={setSearch} loading={loading} />
          </FlexGridItem>
        ) : loading ? (
          <StyledSpinner />
        ) : null}
        <FlexGridItem>
          <DocksSearchHits onSearch={searchDocks} hits={hits} includeFacility={!facility} />
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}
