import React, { useState, useEffect, Suspense } from 'react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { BaseProvider } from 'baseui'
import Cookies from 'universal-cookie'
import { ToasterContainer, PLACEMENT } from 'baseui/toast'
import Footer from './footer'
import Router from './router'
import { CurrentUserContext } from './current-user-context'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { EN_US, LocaleContext, LocaleString } from 'components/contexts/locale-context'
import { useLocalStorage } from 'components/shared/hooks/use-local-storage'
import { customTheme } from './custom-theme'
import { CurrentFacilityProvider } from 'components/contexts/current-facility.context'
import StyledSpinner from 'components/shared/styled-spinner'
import {
  fetchAllEquipmentTypes,
  fetchAssignedEquipmentTypes
} from 'components/services/equipment-type.service'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import protocolRedirect from 'components/utils/protocol-redirect'
import { PROD_ENV } from 'components/utils/environments'
import clearCacheOnNewAppVersion from '../utils/clear-cache-on-new-version'
import { YARD_JOCKEY_AUDIENCE } from '../models/Role'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
})

declare var Localize

// Adding a prefix here, because I generated enough styles to get to
// `.fc`, and that class is used by FullCalendar, so there is a collission.
// Making the name really verbose, because I am afraid of another collission
const engine = new Styletron({ prefix: 'st-' })

export const TypesenseHostContext = React.createContext<{
  typesenseHost?: string
}>({
  typesenseHost: null
})

if (process.env.RAILS_ENV === PROD_ENV) {
  protocolRedirect()
}

const HomepageRoot = ({ TYPESENSE_HOST }) => {
  const [loadingUser, setLoadingUser] = useState<boolean>(true)
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [currentFacility, setCurrentFacility] = useState<any>(null)
  const [equipmentTypes, setEquipmentTypes] = useState(null)
  const [assignedEquipmentTypes, setAssignedEquipmentTypes] = useState(null)
  const [locale, setLocale] = useLocalStorage<LocaleString>('LOCALIZE', EN_US)

  const cookies = new Cookies()

  useEffect(() => {
    if (!currentUser) {
      fetch('/user_profile.json', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': cookies.get('X-CSRF-Token'),
          'X-Lang': localStorage.getItem('i18nextLng') || 'en'
        }
      })
        .then(res => res.json())
        .then(user => {
          if (user && user.id) {
            setCurrentUser(user)
          }
          setLoadingUser(false)

          return user
        })
        .then(user => {
          fetchAssignedEquipmentTypes().then(([json, status]) => {
            if ([200, 304].includes(status) && json) {
              setAssignedEquipmentTypes(json)
            }
          })
          fetchAllEquipmentTypes().then(([json, status]) => {
            if ([200, 304].includes(status) && json) {
              setEquipmentTypes(json)
            }
          })
        })
        .catch(err => {
          console.log({ err })
          setLoadingUser(false)
        })
    }
  }, [])

  useEffect(() => {
    if (currentFacility === null) {
      authenticatedFetch({
        path: '/facilities/current_facility.json'
      }).then(([json, status]) => {
        if ([200, 304].includes(status) && json) {
          setCurrentFacility(json)
        }
      })
    }
    clearCacheOnNewAppVersion()
  }, [])

  useEffect(() => {
    if (process.env.RAILS_ENV !== 'test') {
      Localize?.setLanguage(locale)
    }
  }, [locale])

  useEffect(() => {
    if (currentUser && (window as any).pendo) {
      ;(window as any).pendo.initialize({
        visitor: {
          id: currentUser.id,
          email: currentUser.emailAddress,
          full_name: currentUser.name,
          role: currentUser.userType
        },
        account: {
          id: currentUser.shipperId,
          name: currentUser.shipperName
        }
      })
    }
  }, [currentUser, (window as any).pendo])

  if (loadingUser) {
    return <StyledSpinner />
  }

  return (
    <Suspense
      fallback={
        <div
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <StyledSpinner />
        </div>
      }>
      <StyletronProvider value={engine}>
        <BaseProvider theme={customTheme}>
          <ToasterContainer
            autoHideDuration={7500}
            placement={PLACEMENT.bottomRight}
            overrides={{
              ToastBody: {
                style: ({ $theme, $kind }) => {
                  let bgColor
                  let outlineColor

                  if ($kind === 'positive') {
                    bgColor = '#F2FFFC'
                    outlineColor = $theme.colors.positive
                  } else if ($kind === 'negative') {
                    bgColor = '#FFF2F8'
                    outlineColor = $theme.colors.negative
                  } else if ($kind === 'warning') {
                    bgColor = '#FFF6F2'
                    outlineColor = $theme.colors.warning
                  } else {
                    bgColor = '#F2F6FF'
                    outlineColor = $theme.colors.accent
                  }
                  return {
                    width: '40%',
                    maxWidth: '518px',
                    backgroundColor: bgColor,
                    borderTopRightRadius: $theme.borders.radius300,
                    borderTopLeftRadius: $theme.borders.radius300,
                    borderBottomRightRadius: $theme.borders.radius300,
                    borderBottomLeftRadius: $theme.borders.radius300,
                    outline: `${outlineColor} 1px solid`
                  }
                }
              },
              ToastInnerContainer: {
                style: ({ $theme }) => ({
                  fontSize: $theme.typography.LabelSmall.fontSize,
                  color: $theme.colors.text
                })
              },
              ToastCloseIcon: {
                style: ({ $theme }) => ({
                  color: $theme.colors.primary
                })
              }
            }}>
            <CurrentUserContext.Provider
              value={{
                currentUser,
                setCurrentUser,
                shipperEquipmentTypes: equipmentTypes,
                setShipperEquipmentTypes: setEquipmentTypes,
                assignedEquipmentTypes: assignedEquipmentTypes,
                setAssignedEquipmentTypes: setAssignedEquipmentTypes
              }}>
              <CurrentFacilityProvider>
                <TypesenseHostContext.Provider value={{ typesenseHost: TYPESENSE_HOST }}>
                  <LocaleContext.Provider
                    value={{
                      locale,
                      setLocale: setLocale as (localeString: LocaleString) => void
                    }}>
                    <div className="site-container">
                      <div className="container" id="wrapper-container">
                        <Router />
                      </div>
                      {(!currentUser ||
                        ![YARD_JOCKEY_AUDIENCE].includes(currentUser?.userType)) && <Footer />}
                    </div>
                  </LocaleContext.Provider>
                </TypesenseHostContext.Provider>
              </CurrentFacilityProvider>
            </CurrentUserContext.Provider>
          </ToasterContainer>
        </BaseProvider>
      </StyletronProvider>
    </Suspense>
  )
}

export default HomepageRoot
