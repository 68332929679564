import React, { useState } from 'react'
import { ButtonProps } from 'baseui/button'
import Button from 'components/ui/generic/Button'

type Props = {
  label?: string
  onDelete: () => void
  confirmation?: boolean
} & ButtonProps

export const RemoveButton = ({
  label = 'Remove',
  confirmation = true,
  onDelete,
  ...props
}: Props) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false)

  const handleAttemptDelete = () => {
    if (confirmation && !isConfirm) {
      setIsConfirm(true)
    } else {
      setIsConfirm(false)
      onDelete()
    }
  }

  return (
    <Button onClick={handleAttemptDelete} {...props}>
      {confirmation && isConfirm ? 'Confirm?' : label}
    </Button>
  )
}
