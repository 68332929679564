import { EquipmentType } from 'components/models/DockCapacity'
import authenticatedFetch from 'components/utils/authenticated-fetch'

const BASE_PATH = '/equipment_types'

export const saveAssignedEquipmentTypes = (equipmentTypeIds: string[]) => {
  return authenticatedFetch({
    path: `${BASE_PATH}/assign.json`,
    method: 'post',
    body: { equipmentTypeIds }
  })
}

export const fetchAllEquipmentTypes = () => {
  return authenticatedFetch({
    path: `${BASE_PATH}.json`,
    method: 'get'
  })
}

export const fetchAssignedEquipmentTypes = () => {
  return authenticatedFetch({
    path: `//${location.host}${BASE_PATH}/get_assigned.json`
  })
}

export const createEquipmentType = (type: EquipmentType) => {
  return authenticatedFetch({
    path: `${BASE_PATH}.json`,
    method: 'post',
    body: { equipmentType: type }
  })
}

export const updateEquipmentType = (type: EquipmentType) => {
  return authenticatedFetch({
    path: `${BASE_PATH}/${type.id}.json`,
    method: 'put',
    body: { equipmentType: type }
  })
}

export const saveEquipmentType = (record: EquipmentType) => {
  return record.id ? updateEquipmentType(record) : createEquipmentType(record)
}

export const fetchAffectedDocks = (equipmentTypeIds: string[]) => {
  return authenticatedFetch({
    path: `${BASE_PATH}/get_affected_docks.json`,
    method: 'post',
    body: { equipmentTypeIds }
  })
}
