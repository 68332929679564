import { useStyletron } from 'baseui'
import useSearchClient from 'components/shared/hooks/use-search-client'
import StyledSpinner from 'components/shared/styled-spinner'
import React, { useEffect, useState } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

export const RefreshSearchContext = React.createContext<{
  refresh: () => void
}>({
  refresh: () => {}
})

export const SearchStateContext = React.createContext(null)

interface CyberSearchProps {
  queryBy: string
  indexName: string
  children: any
  hitsPerPage?: number
  numTypos?: number
}

const CyberSearch = (props: CyberSearchProps) => {
  const { queryBy, numTypos, indexName, children, hitsPerPage } = props
  const [css, theme] = useStyletron()
  const [refreshToggle, setRefreshToggle] = useState<boolean>(false)
  const [searchState, setSearchState] = useState(null)
  const searchClient = useSearchClient({ queryBy, numTypos, exhaustiveSearch: true })

  useEffect(() => {
    refreshToggle === true && setRefreshToggle(false)
  }, [refreshToggle])

  if (!searchClient) {
    return <StyledSpinner />
  }

  return (
    <RefreshSearchContext.Provider
      value={{
        refresh: () => {
          setTimeout(() => {
            setRefreshToggle(true)
          }, 500)
        }
      }}>
      <SearchStateContext.Provider value={searchState}>
        <div className={css({ position: 'relative' })}>
          <InstantSearch
            refresh={refreshToggle}
            indexName={`${indexName}_${process.env.RAILS_ENV}`}
            searchClient={searchClient}
            onSearchStateChange={searchState => setSearchState(searchState)}>
            {hitsPerPage && <Configure hitsPerPage={hitsPerPage} />}
            {children}
          </InstantSearch>
        </div>
      </SearchStateContext.Provider>
    </RefreshSearchContext.Provider>
  )
}

export default CyberSearch
