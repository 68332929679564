import React, { useState, useEffect } from 'react'

import { FlexGridItem } from 'baseui/flex-grid'
import { useStyletron } from 'baseui'
import { Redirect, useHistory } from 'react-router-dom'
import pluralize from 'pluralize'
import { AnimatedFadeIn } from 'components/animation/fadeIn.styled'
import { useTranslation } from 'react-i18next'

import Header from 'components/ui/generic/Header'
import Button from 'components/ui/specific/PrimaryButton'
import authenticatedFetch from '../utils/authenticated-fetch'
import { shouldLoadRecord, fancyToast } from '../utils'

interface RecordFormProps {
  children?: any
  recordName: string
  routeName: string
  record: any
  setRecord: Function
  recordId: string
  requiredFieldsPresent: boolean
  match: { params: { handle: string } }
  customPath?: string
  onSuccess?: Function
}

const RecordForm = (props: RecordFormProps) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const {
    children,
    recordName,
    routeName,
    record,
    setRecord,
    recordId,
    requiredFieldsPresent,
    customPath,
    onSuccess
  } = props
  const [redirect, setRedirect] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const capitalizedRecordName = `${recordName.charAt(0).toUpperCase()}${recordName.slice(1)}`
  const history = useHistory()
  useEffect(() => {
    if (shouldLoadRecord(props, record)) {
      authenticatedFetch({
        path: `/${routeName}/${recordId}.json`
      }).then(([json, _status]) => setRecord(json))
    }
  }, [])

  const submitRecordForm = async () => {
    const mappedRecord = {}
    mappedRecord[recordName] = record
    setDisableSubmit(true)
    const [json, status] = await authenticatedFetch({
      path: record.id ? `/${routeName}/${record.id}.json` : `/${routeName}.json`,
      method: record.id ? 'PATCH' : 'POST',
      body: mappedRecord
    })

    if (status === 201) {
      setTimeout(() => {
        setRedirect(true)
      }, 2000)
      fancyToast(
        {
          info: `${t('Common.Info.Interpolated.Text', {
            model: capitalizedRecordName,
            action: t('Common.Actions.Created.Text')
          })}`
        },
        status
      )
    } else if (status === 200) {
      setDisableSubmit(false)
      fancyToast(
        {
          info: `${t('Common.Info.Interpolated.Text', {
            model: capitalizedRecordName,
            action: t('Common.Actions.Updated.Text')
          })}`
        },
        status
      )
      if (customPath) onSuccess()
    } else {
      setDisableSubmit(false)
      fancyToast(json, status)
    }
  }

  if (redirect) {
    return <Redirect to={`/${routeName}`} />
  }
  return (
    <>
      <Header title={capitalizedRecordName} />

      <AnimatedFadeIn>
        {children}

        <div
          className={css({
            textAlignLast: 'end',
            marginTop: '1rem',
            maxWidth: '720px'
          })}>
          <FlexGridItem>
            <Button disabled={disableSubmit || !requiredFieldsPresent} onClick={submitRecordForm}>
              {record.id ? t('Common.Button.Save.Text') : t('Common.Button.Create.Text')}
            </Button>
          </FlexGridItem>
        </div>
      </AnimatedFadeIn>
    </>
  )
}

export default RecordForm
