import { Plus } from 'baseui/icon'
import { Input } from 'baseui/input'
import { IoMdTrash } from 'react-icons/io'
import { LabelSmall } from 'baseui/typography'
import React, { ReactElement, useEffect } from 'react'
import { Control, Controller, FieldValues, useFieldArray } from 'react-hook-form'
import { AddValue, AnswerInputWrapper, AnswerWrapper, Icon, TrashIcon } from './DropdownList.styled'

interface DropdownListProps {
  index: number
  control: Control<FieldValues, any>
  setValue: (name: string, value: unknown, config?: any) => void
}

const DropdownList = ({ index, control, setValue }: DropdownListProps): ReactElement => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${index}`
  })

  useEffect(() => {
    if (fields.length === 0) {
      append({ label: '', value: '' })
    }
  }, [fields, append])

  return (
    <AnswerWrapper>
      <LabelSmall marginBottom="7px">Answers</LabelSmall>
      {fields.map((item, itemIndex) => {
        return (
          <AnswerInputWrapper key={item.id}>
            <Controller
              name={`${index}.${itemIndex}.value`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Input
                  {...field}
                  autoComplete="off"
                  placeholder="Click to edit"
                  onChange={e => {
                    field.onChange(e)
                    setValue(`${index}.${itemIndex}.label`, e.currentTarget.value)
                  }}
                  inputRef={ref}
                />
              )}
            />
            {itemIndex !== 0 && (
              <TrashIcon onClick={() => remove(itemIndex)}>
                <IoMdTrash size={25} />
              </TrashIcon>
            )}
          </AnswerInputWrapper>
        )
      })}
      <AddValue
        onClick={() => {
          append({ label: '', value: '' })
        }}
      >
        <Icon>
          <Plus size={25} color="rgb(1, 73, 145)" />
        </Icon>
        <LabelSmall color="rgb(1, 73, 145)" display="inline-block">
          Add Another Field
        </LabelSmall>
      </AddValue>
    </AnswerWrapper>
  )
}

export default DropdownList
