import React from "react";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import QuestionAndAnswer from "./question-and-answer";

const QuestionsAndAnswersList = ({ questionsAndAnswers }) => {
  return (
    <FlexGrid
      flexGridRowGap="scale200"
      flexGridColumnGap="scale600"
      flexGridColumnCount={[1, 1, 2]}
    >
      {questionsAndAnswers.map((questionAndAnswer, index) => {
        return (
          <FlexGridItem key={index}>
            <QuestionAndAnswer {...questionAndAnswer} />
          </FlexGridItem>
        );
      })}
    </FlexGrid>
  );
};

export default QuestionsAndAnswersList;
