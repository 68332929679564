import styled, { keyframes } from 'styled-components'

const textFade = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

export const AnimatedFadeIn = styled.div`
  animation: ${textFade} 1s forwards ease-in-out;
`
