import React from 'react'
import { OrdersProvider } from 'components/contexts/orders.context'
import OrderList from './OrderList'
import { OrderPreferencesProvider } from 'components/contexts/order-preferences.context'

const Orders = () => {
  return (
    <OrdersProvider>
      <OrderPreferencesProvider>
        <OrderList />
      </OrderPreferencesProvider>
    </OrdersProvider>
  )
}

export default Orders
