import React, { useEffect } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { CyberTable } from 'cyber/table'
import { endsWith, camelCase } from 'lodash'
import { ParagraphLarge } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { FileSearch } from '@phosphor-icons/react'
import TimeFormatter from 'components/utils/time-formatter'
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box'
import { InstantAppointment } from '../types'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'

const DATE_FINISH_AT = 'at'
const DATE_FINISH_TIME = 'time'

const NonConnectedHits = props => {
  const [css, theme] = useStyletron()
  const { hits, setNumberOfHits, setSelectedAppointment } = props
  const { t } = useTranslation()

  const formattedHits = hits.reverse().map(hit => {
    const keys = Object.keys(hit)
    const formattedHit = {}
    keys.map(key => {
      const camelCasedKey = camelCase(key)
      if (
        (endsWith(key, DATE_FINISH_AT) || endsWith(key, DATE_FINISH_TIME)) && // In Ruby on Rails-Landia, date fields usually end in the word 'at' (eg: createdAt, updatedAt, etc.)
        typeof hit[key] === 'number' &&
        hit[key] > 1600000000
      ) {
        formattedHit[camelCasedKey] =
          hit[key] === -1 ? null : new Date(hit[key] * 1000).toISOString()
      } else if (hit[key] === '') {
        // Typesense gets mad if we try to serialize null values.
        formattedHit[camelCasedKey] = null
      } else {
        formattedHit[camelCasedKey] = hit[key]
      }
    })
    return formattedHit
  })

  useEffect(() => {
    if (setNumberOfHits) {
      setNumberOfHits(hits.length)
    }
  }, [hits, setNumberOfHits])

  if (hits.length === 0) {
    return (
      <AspectRatioBox aspectRatio={2}>
        <AspectRatioBoxBody>
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingTop: theme.sizing.scale1600
            })}>
            <FileSearch size={theme.sizing.scale2400} />
            <ParagraphLarge>{t('Appointments.Table.NoAppointmentsFound.Text')}</ParagraphLarge>
          </div>
        </AspectRatioBoxBody>
      </AspectRatioBox>
    )
  }

  return (
    <>
      <CyberTable
        gridTemplateColumns={['104px', '104px', 'auto', 'auto', '140px', 'auto', 'auto', 'auto']}
        columns={[
          t('Appointments.Table.Columns.ArrivalDate.Label.Text'),
          t('Appointments.Table.Columns.ArrivalTime.Label.Text'),
          t('Appointments.Table.Columns.AppointmentID.Label.Text'),
          t('Appointments.Table.Columns.PurchaseSalesOrder.Label.Text'),
          t('Appointments.Table.Columns.Facility.Label.Text'),
          t('Appointments.Table.Columns.VendorCompany.Label.Text'),
          t('Appointments.Table.Columns.CreatedBy.Label.Text'),
          t('Appointments.Table.Columns.LastUpdated.Label.Text')
        ]}
        data={formattedHits.reverse().map((hit: InstantAppointment) => {
          const {
            id,
            purchaseOrderIdentifiers,
            identifiersJson,
            arrivalTime,
            schedulerName,
            createdByName,
            updatedAt,
            timeZone,
            facilityName,
            confirmationId
          } = hit
          return [
            arrivalTime && new TimeFormatter('shortDate').format(arrivalTime, timeZone),
            arrivalTime && new TimeFormatter('localCalendarTime').format(arrivalTime, timeZone),
            <Button size="mini" onClick={() => setSelectedAppointment(hit)}>
              {confirmationId ?? ''}
            </Button>,
            [...purchaseOrderIdentifiers].join(', '),
            facilityName,
            schedulerName,
            createdByName,
            new TimeFormatter('fullDate').format(updatedAt, timeZone)
          ]
        })}
      />
    </>
  )
}

const Hits = connectHits(React.memo(NonConnectedHits)) as any

export default Hits
