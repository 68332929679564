import React, { useState, useContext } from 'react'
import { Block } from 'baseui/block'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { CalendarBlank, MagnifyingGlass } from '@phosphor-icons/react'
import { StatefulTooltip } from 'baseui/tooltip'
import { useStyletron } from 'baseui'
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AddButton } from 'components/components/AddButton'
import { usePagination } from 'components/hooks/use-pagination'
import Pagination from 'components/components/Pagination/Pagination'
import { PAGE_SIZE } from 'components/constants/page-size'
import { useDebounce } from 'react-use'
import { facilityService } from 'components/services'
import { PaginationResponse } from 'components/types/pagination-response'
import { useHttp } from 'components/hooks/use-http'

import Input from 'components/ui/generic/Input'
import Header from 'components/ui/generic/Header'
import Table from 'components/ui/generic/Table'
import Button from 'components/ui/generic/Button'
import StyledSpinner from '../../shared/styled-spinner'
import LinkButton from '../../shared/fields/link-button'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { FacilitySearchButtonContainer, FacilitySearchContainer } from './FacilitySearch.styled'

export const FacilitySearch = () => {
  const [_, theme] = useStyletron()
  const { currentUser } = useContext(CurrentUserContext)
  const [search, setSearch] = useState<any>()
  const { page, pageSize, setPage } = usePagination()
  const [findFacilties, { data, loading }] = useHttp<PaginationResponse<any>>(
    facilityService.findFacilitiies
  )

  const { t } = useTranslation()
  const REGULAR_HEADERS = [
    t('Facilities.Table.Columns.RegularColumns.Name.Label.Text'),
    t('Facilities.Table.Columns.RegularColumns.RecievingManager.Label.Text'),
    t('Facilities.Table.Columns.RegularColumns.Address.Label.Text'),
    t('Facilities.Table.Columns.RegularColumns.OpenScheduling.Label.Text'),
    t('Facilities.Table.Columns.RegularColumns.Empty.Label.Text')
  ]
  const SHIPPER_HEADERS = [
    t('Facilities.Table.Columns.ShipperColumns.FacilityName.Label.Text'),
    t('Facilities.Table.Columns.ShipperColumns.FacilityManager.Label.Text'),
    t('Facilities.Table.Columns.ShipperColumns.Address.Label.Text'),
    t('Facilities.Table.Columns.ShipperColumns.Links.Label.Text'),
    t('Facilities.Table.Columns.ShipperColumns.OpenScheduling.Label.Text')
  ]
  const history = useHistory()
  const results = data?.results || []

  useDebounce(() => findFacilties({ page, pageSize, search }), 200, [page, pageSize, search])

  const shipperRow = facility => {
    const { id, name, owner, address, latitude, longitude, openScheduling } = facility
    return [
      <Link to={`/facilities/${id}`}>{name}</Link>,
      owner.name,
      latitude && longitude && (
        <a
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}>
          {address}
        </a>
      ),
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap="scale200">
        <FlexGridItem>
          <StatefulTooltip
            placement="leftTop"
            content={t(
              'Facilities.Table.Columns.ShipperColumns.FacilityManager.Items.WeeklySchedule.Text'
            )}>
            <Link to={`/facilities/${id}/schedule`}>
              <Button shape="circle">
                <CalendarBlank size={theme.sizing.scale600} />
              </Button>
            </Link>
          </StatefulTooltip>
        </FlexGridItem>
      </FlexGrid>,
      openScheduling
        ? t('Facilities.Table.Columns.ShipperColumns.OpenScheduling.Items.Allowed.Text')
        : t('Facilities.Table.Columns.ShipperColumns.OpenScheduling.Items.NotAllowed.Text')
    ]
  }

  const regularRow = facility => {
    const { id, name, owner, address, openScheduling } = facility
    return [
      name,
      owner.name,
      address,
      openScheduling
        ? t('Facilities.Table.Columns.RegularColumns.OpenScheduling.Items.Allowed.Text')
        : t('Facilities.Table.Columns.RegularColumns.OpenScheduling.Items.NotAllowed.Text'),
      <LinkButton href={`/facilities/${id}/book`} label="Schedule" positive />
    ]
  }

  if (!currentUser) {
    return <StyledSpinner />
  }

  return (
    <>
      <Header
        title={t('HeaderNavigation.NavBarLinks.Facilities.Text')}
        rightItems={[
          <FacilitySearchContainer className={_({ display: 'flex', gap: theme.sizing.scale800 })}>
            <Input
              startEnhancer={<MagnifyingGlass />}
              autoComplete="off"
              name="facilities[name]"
              value={search}
              onChange={evt => {
                setPage(1)
                setSearch(evt.currentTarget.value)
              }}
              placeholder={t('Facilities.SearchBox.Title.Text')}
            />
            {currentUser.admin && (
              <FacilitySearchButtonContainer>
                <AddButton onClick={() => history.push('/facilities/new')} />
              </FacilitySearchButtonContainer>
            )}
          </FacilitySearchContainer>
        ]}
      />

      {loading ? (
        <Block display="flex" justifyContent="center" alignItems="center">
          <StyledSpinner />
        </Block>
      ) : (
        <>
          <Block minHeight="60vh">
            <Table
              columns={currentUser.userType === 'shipper' ? SHIPPER_HEADERS : REGULAR_HEADERS}
              data={results?.map(facility =>
                currentUser.userType === 'shipper' ? shipperRow(facility) : regularRow(facility)
              )}
            />
          </Block>
          <Pagination
            total={Math.ceil(data?.total / PAGE_SIZE) || 1}
            page={page}
            onPageChange={setPage}
          />
        </>
      )}
    </>
  )
}
