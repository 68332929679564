import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'
import Header from 'components/ui/generic/Header'
import CreateButton from 'components/ui/specific/CreateButton'
import { Tag } from 'baseui/tag'
import { HeadingXSmall } from 'baseui/typography'
import { CaretDown } from '@phosphor-icons/react'
import { slotService } from 'components/services'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { FacilityContext } from '../../contexts/facility.context'
import { Facility } from '../../models/Facility'
import { Slot } from '../../models/Slot'
import { Pencil } from '@phosphor-icons/react'
import SlotModal from './SlotModal'
import ZonesTable from './ZonesTable'
import SlotsTable from './SlotsTable'
import { useHistory } from 'react-router-dom'

interface ZonesProps {
  facility: Facility
}

const Zones = ({ facility }: ZonesProps) => {
  const [slots, setSlots] = useState([])
  const [view, setView] = useState('Slots')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [editSlot, setEditSlot] = useState<Slot | undefined>(null)
  const [refresh, setRefresh] = useState<boolean>(false)
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    if (!facility?.appointmentPreference?.enableYardManagement) {
      history.push('/')
    }
  }, [facility])

  const { shipperEquipmentTypes } = useContext(CurrentUserContext)
  const equipmentTypesMap = new Map(
    shipperEquipmentTypes?.map(item => {
      return [item.id, item.name]
    })
  )

  const zonesMap = new Map(
    slots.map(s => {
      return [s.zone, s.zone]
    })
  )

  const appointmentTypesMap = new Map(
    facility.appointmentTypes.map(at => {
      return [at.id, at.name]
    })
  )

  const handleTitleClick = () => {
    setView(view === 'Slots' ? 'Zones' : 'Slots')
  }

  const openModal = (slot = null) => {
    setEditSlot(slot)
    setModalOpen(true)
  }

  const closeModal = () => {
    setEditSlot(null)
    setModalOpen(false)
  }

  const handleSubmit = slot => {
    if (editSlot) {
      slotService.updateSlot(slot)
    } else {
      slotService.createSlot(facility.id, slot)
    }
    setRefresh(true)
    closeModal()
  }

  const getAppointmentTypeNames = ids => {
    return ids
      .map(id => {
        return appointmentTypesMap.get(id)
      })
      .join(', ')
  }

  const getEquipmentTypeNames = ids => {
    return ids
      .map(id => {
        return equipmentTypesMap.get(id)
      })
      .join(', ')
  }

  const formattedData = slots.map(slot => {
    return [
      slot.name,
      slot.zone,
      getAppointmentTypeNames(slot.appointmentTypeIds),
      slot.active ? (
        <Tag variant="solid" closeable={false}>
          {t('Common.Fields.Enabled.Text')}
        </Tag>
      ) : (
        <Tag
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                backgroundColor: '#DBE6FF',
                color: $theme.colors.primary
              })
            }
          }}
          variant="solid"
          closeable={false}>
          {t('Common.Fields.Disabled.Text')}
        </Tag>
      ),
      getEquipmentTypeNames(slot.equipmentTypeIds),
      <Button
        title="Edit"
        onClick={() => {
          openModal(slot)
        }}>
        <Pencil />
      </Button>
    ]
  })

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        setLoading(true)
        const [resp, _status] = await slotService.getAllSlots(facility.id)
        if (resp.results) {
          setSlots(resp.results)
        }
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
        setRefresh(false)
      }
    }
    fetchSlots()
  }, [facility.id, refresh])

  return (
    <FacilityContext.Provider value={{ ...facility }}>
      <SlotModal
        open={modalOpen}
        selectedSlot={editSlot}
        zones={Array.from(zonesMap.keys())}
        onSubmit={handleSubmit}
        close={closeModal}
      />
      <Header
        title={
          <HeadingXSmall marginTop="0" marginBottom="0" onClick={handleTitleClick}>
            {t(`Settings.SideMenu.Titles.Yard.${view}.Text`)}
            <CaretDown />
          </HeadingXSmall>
        }
        rightItems={[<CreateButton onClick={() => openModal()} />]}
      />
      {view === 'Slots' ? <SlotsTable data={formattedData} /> : <ZonesTable data={slots} />}
    </FacilityContext.Provider>
  )
}

export default Zones
