import React, { useState } from 'react'
import { ButtonGroup } from 'baseui/button-group'
import Button from 'components/ui/generic/Button'
import FormControl from 'components/ui/generic/FormControl'
import { findIndex } from 'lodash'
import { startCase, camelCase } from 'lodash'
import { ANSWER_TYPES } from '../../models/Question'
import { useTranslation } from 'react-i18next'

const AnswerType = ({ questions, setQuestions, index }) => {
  const question = questions[index]
  const answerTypeIndex = findIndex(ANSWER_TYPES, a => a === question.answerType)
  const [selected, setSelected] = useState<number>(answerTypeIndex)
  const { t } = useTranslation()

  return (
    <FormControl label={t('Settings.AppointmentTypes.CustomQuestions.AnswerType')}>
      <ButtonGroup
        onClick={(_event, answerTypeIndex) => {
          setSelected(answerTypeIndex)
          const newQuestions = [...questions]
          newQuestions[index].answerType = ANSWER_TYPES[answerTypeIndex]
          setQuestions(newQuestions)
        }}
        size="compact"
        overrides={{
          Root: {
            style: {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: '8px'
            }
          }
        }}
        selected={selected}>
        {ANSWER_TYPES.map((answerType, index) => {
          return (
            <Button key={index} type="button">
              {startCase(camelCase(answerType))}
            </Button>
          )
        })}
      </ButtonGroup>
    </FormControl>
  )
}

export default AnswerType
