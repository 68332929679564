import React, { useState, useContext } from 'react'
import { ProgressSteps, Step } from 'baseui/progress-steps'
import { Button } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Paragraph1 } from 'baseui/typography'
import { HeadingLevel, Heading } from 'baseui/heading'
import { Redirect } from 'react-router-dom'
import authenticatedFetch from '../../utils/authenticated-fetch'
import StyledSpinner from '../../shared/styled-spinner'
import { fancyToast } from '../../utils'
import { CurrentUserContext } from '../../homepage/current-user-context'

const DriverOnboarding = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const [user, setUser] = useState<any>(currentUser)
  const [redirectPath, setRedirectPath] = useState<string>()

  if (!currentUser) {
    return <StyledSpinner />
  }

  const [currentPage, setCurrentPage] = useState<number>(0)
  if (!currentUser) {
    return <StyledSpinner />
  }

  const setUserName = async () => {
    const [json, status] = await authenticatedFetch({
      path: `/users/${currentUser.id}.json`,
      method: 'PATCH',
      body: { user: { ...currentUser, ...user } }
    })

    if (status === 200) {
      setCurrentPage(1)
      setTimeout(() => {
        setRedirectPath('/')
      }, 5000)
    } else {
      fancyToast(json, status)
    }
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} />
  }

  return (
    <HeadingLevel>
      <Heading>Welcome to Velostics</Heading>
      <ProgressSteps current={currentPage}>
        <Step title="Account Details">
          <FormControl label="Carrier Name">
            <Input
              autoComplete="off"
              name="user[carrier_name]"
              required
              value={user.carrierName}
              onChange={e => {
                setUser({ ...user, carrierName: e.currentTarget.value })
              }}
            />
          </FormControl>{' '}
          <FormControl label="Your Name">
            <Input
              autoComplete="off"
              name="user[name]"
              required
              value={user.name}
              onChange={e => {
                setUser({ ...user, name: e.currentTarget.value })
              }}
            />
          </FormControl>{' '}
          <Button size="compact" disabled={!user.name} onClick={setUserName}>
            Next
          </Button>
        </Step>
        <Step title="Activation">
          <Paragraph1>
            You can now check loads in at a facility. You will be redirected shortly.
          </Paragraph1>
        </Step>
      </ProgressSteps>
    </HeadingLevel>
  )
}

export default DriverOnboarding
