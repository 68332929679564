import React from 'react'
import MenuLayout from '../menu-layout'
import SideCalendarNav from './side-calendar-nav'
import FullCalendarWrapper from './full-calendar-wrapper'

const DesktopCalendar = ({
  open,
  setOpen,
  onEventClick,
  onDateClick,
  drop,
  eventAllow,
  eventReceive,
  updateEvent
}) => {
  return (
    <MenuLayout
      {...{ open, setOpen }}
      sidebar={<SideCalendarNav {...{ open, setOpen }} />}
      content={<div></div>}
    />
  )
}

export default DesktopCalendar
