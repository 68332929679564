import { primitives } from 'components/homepage/custom-theme'

export const DriverCheckinNavBarProps = {
  overrides: {
    Root: {
      style: ({ $theme }) => ({
        boxShadow: `0 1px 0 ${$theme.colors.gray}`,
        backgroundColor: $theme.colors.backgroundSecondary,
        paddingLeft: 0,
        zIndex: 3
      })
    },
    Spacing: {
      style: ({ $theme }) => ({
        paddingTop: $theme.sizing.scale500,
        paddingBottom: $theme.sizing.scale500,
        [$theme.mediaQuery.medium]: {
          paddingTop: $theme.sizing.scale500,
          paddingBottom: $theme.sizing.scale500
        }
      })
    }
  }
}

export const DriverCheckinHeaderProps = {
  overrides: {
    title: {
      overrides: {
        Block: {
          style: {
            fontSize: '32px',
            color: primitives.primary,
            lineHeight: '35px'
          }
        }
      }
    }
  }
}
