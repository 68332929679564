import { DockBlock } from 'components/models/DockBlock'
import http from 'components/utils/authenticated-fetch'

class DockBlockService {
  createDockBlock = (dockBlock: DockBlock): Promise<any> =>
    http({
      path: `/dock_blocks.json`,
      body: {
        dock_block: dockBlock
      },
      method: 'POST'
    }).then(([result, status]) => [result, status])

  getDockBlock = (dockBlockId: string): Promise<any> =>
    http({
      path: `/dock_blocks/${dockBlockId}.json`,
      method: 'GET'
    }).then(([result, status]) => [result, status])

  getDockBlocks = (dateStart: string, dateEnd: string, dockAssignment = false): Promise<any> => {
    const formData = new URLSearchParams()
    formData.set('date_start', dateStart)
    formData.set('date_end', dateEnd)

    if (dockAssignment) {
      formData.set('dock_assignment', dockAssignment.toString())
    }

    return http({
      path: `/dock_blocks.json?${formData.toString()}`,
      method: 'GET'
    }).then(([result, status]) => [result, status])
  }

  deleteDockBlock = (dockBlockId: string): Promise<any> =>
    http({
      path: `/dock_blocks/${dockBlockId}.json`,
      method: 'DELETE'
    }).then(([result, status]) => [result, status])

  updateDockBlock = (dockBlock: DockBlock): Promise<any> =>
    http({
      path: `/dock_blocks/${dockBlock.id}.json`,
      body: {
        dockBlock
      },
      method: 'PATCH'
    }).then(([result, status]) => [result, status])
}

export const dockBlockService = new DockBlockService()
