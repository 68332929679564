import React from 'react'

import { MagnifyingGlass, CircleNotch } from '@phosphor-icons/react'

import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import { AddButton } from 'components/components/AddButton'
import Input from 'components/ui/generic/Input'

type Props = {
  onPlusClick: () => void
  onSearch: (search: string) => void
  loading: boolean
}

export const MetabaseAnalyticsSettingsSearch = ({
  onPlusClick,
  onSearch,
  loading = false
}: Props) => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  return (
    <div className={css({ display: 'flex', gap: theme.sizing.scale800 })}>
      <Input
        startEnhancer={
          loading ? (
            <CircleNotch
              className={css({
                animationDuration: '800ms',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'linear',
                animationName: {
                  from: {
                    transform: 'rotate(0deg)'
                  },
                  to: {
                    transform: 'rotate(360deg)'
                  }
                }
              })}
              size={theme.sizing.scale600}
            />
          ) : (
            <MagnifyingGlass />
          )
        }
        placeholder={t('Settings.Analytics.SearchBox.PlaceHolder.Text')}
        autoComplete="off"
        name="search[name]"
        onChange={evt => onSearch(evt.currentTarget.value)}
      />
      <AddButton onClick={onPlusClick} />
    </div>
  )
}
