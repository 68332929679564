import React, { useContext, useEffect } from 'react'
import { connectRange } from 'react-instantsearch-dom'
import moment from 'moment'
import { CalendarReferenceContext } from 'components/contexts/calendar-reference-context'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { DEFAULT_LOCALE_STRING_LANGUAGE } from 'components/constants/default-locale-string-language'
import { FacilitiesContext } from '../context/facilities-context'
import { useCalendarSelectedOptionsContext } from '../../../contexts/calendar-selected-options.context'
import i18n from 'translations/i18n'
import { dateTranslations } from 'translations/date-languages'
import Calendar from 'components/ui/generic/Calendar'
import { DAY_KEY, SATURDAY_ID, WEEK_KEY } from '../calendar-hits'

const NonConnectedInstantDatePicker = ({ min, max, refine }) => {
  const {
    calendarReference,
    dateInCalendarFilter: value,
    setDateInCalendarFilter: setValue,
    cacheDate,
    setCacheDate,
    weekMode
  } = useContext(CalendarReferenceContext)
  const { currentUser } = useContext(CurrentUserContext)
  const { setSelectedDate } = useContext(FacilitiesContext)
  const dTranslation = dateTranslations[i18n.language]

  const {
    actions: { setDate }
  } = useCalendarSelectedOptionsContext()

  useEffect(() => {
    if (calendarReference) {
      calendarReference?.current
        .getApi()
        .gotoDate(
          moment(value[0].toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE)).startOf(DAY_KEY).toDate()
        )
    }
  }, [value])

  useEffect(() => {
    const newMin = moment(value[0]).startOf(DAY_KEY).unix()

    const newMax = moment(value[1]).endOf(DAY_KEY).unix()

    const refinement = {
      min: min ? Math.max(min, newMin) : newMin,
      max: max ? Math.min(max, newMax) : newMax
    }

    refine(refinement)
  }, [value])

  return (
    <Calendar
      locale={dTranslation}
      value={value}
      range
      onChange={({ date }) => {
        const singleDate = Array.isArray(date) ? date[0] : date
        const minDate = moment(singleDate.toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE))
          .startOf(weekMode ? WEEK_KEY : DAY_KEY)
          .toDate()
        const maxDate = weekMode
          ? moment(singleDate.toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE))
              .endOf(WEEK_KEY)
              .day(SATURDAY_ID)
              .endOf(DAY_KEY)
              .toDate()
          : moment(singleDate.toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE))
              .endOf(DAY_KEY)
              .toDate()
        setValue([minDate, maxDate])
        setDate(minDate)
        setSelectedDate(minDate)
        setCacheDate({
          ...cacheDate,
          [currentUser.shipperId]: {
            ...((cacheDate && cacheDate[currentUser.shipperId]) || {}),
            [currentUser.id]: [minDate, maxDate]
          }
        })
      }}
    />
  )
}

const InstantDatePicker = connectRange(NonConnectedInstantDatePicker)

export default InstantDatePicker
