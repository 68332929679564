import React, { useState, useEffect } from "react";
import { FormControl } from "baseui/form-control";
import { ButtonGroup } from "baseui/button-group";
import { Button } from "baseui/button";
import { findIndex } from "lodash";
import authenticatedFetch from "../../utils/authenticated-fetch";

const AppointmentType = ({ appointment, setAppointment }) => {
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [selected, setSelected] = useState<number>(null);

  useEffect(() => {
    authenticatedFetch({
      path: `/appointment_types.json`,
    }).then(([json, status]) => {
      setAppointmentTypes(json);
      const index = findIndex(json, { id: appointment.appointmentTypeId });
      setSelected(index);
    });
  }, []);

  return (
    <ButtonGroup
      onClick={(event, index) => {
        const appointmentType = appointmentTypes[index];
        setSelected(index);
        setAppointment({
          ...appointment,
          appointmentTypeId: appointmentType.id,
        });
      }}
      selected={selected}
    >
      {appointmentTypes.map((appointmentType, index) => {
        return <Button key={index}>{appointmentType.name}</Button>;
      })}
    </ButtonGroup>
  );
};

export default AppointmentType;
