import React from 'react'
import { Tabs } from 'baseui/tabs'

const VTabs = ({ children, position, overrides, ...props }) => {
  return (
    <Tabs
      overrides={{
        Tab: {
          style: ({ $theme }) => ({
            lineHeight: $theme.sizing.scale1000,
            fontSize: $theme.typography.LabelXSmall.fontSize,
            marginLeft: 0,
            marginRight: 0,
            [$theme.mediaQuery.large]: {
              fontSize: $theme.typography.LabelSmall.fontSize
            }
          })
        },
        TabContent: {
          style: ({ $theme }) => ({
            paddingTop: $theme.sizing.scale600,
            paddingLeft: 0,
            paddingRight: 0
          })
        },
        TabBar: {
          style: () => ({ paddingLeft: 0, paddingRight: 0 })
        },
        ...overrides
      }}
      {...props}>
      {children}
    </Tabs>
  )
}
export default VTabs
