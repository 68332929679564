import { primitives } from 'components/homepage/custom-theme'
import styled from 'styled-components'

export const TaskEventStyled = styled.div<{ status: string }>`
color: ${({ status }) => (status === 'created' ? primitives.primary : primitives.white)} };
background-color: ${({ status }) =>
  status === 'created'
    ? primitives.gray
    : status === 'accepted'
    ? primitives.primary
    : status === 'started'
    ? primitives.accent
    : status === 'completed'
    ? primitives.positive
    : primitives.error};
`
