import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Modal, ModalBody, ModalFooter, ModalHeader, SIZE } from 'baseui/modal'
import { useTasksContext } from 'components/contexts/tasks.context'
import React from 'react'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import { CheckSquareOffset } from '@phosphor-icons/react'
import SelectFacilityField from './Fields/SelectFacilityField'
import SelectTrailerField from './Fields/SelectTrailerField'
import SelectToField from './Fields/SelectToField'
import SelectUserField from './Fields/SelectUserField'
import { Spinner } from 'baseui/spinner'
import { useTranslation } from 'react-i18next'
import {
  CardAppointmentWrapper,
  FromLabel,
  FromText,
  FromWrapper,
  LoadingAppointment,
  ModalHeaderStyled,
  ModalProps
} from './TaskForm.styled'
import { AppointmentCardSmall } from 'components/components/AppointmentCard'

const TaskForm = () => {
  const { state, actions } = useTasksContext()
  const {
    taskModal: {
      task,
      isOpen,
      isEdit,
      loading,
      trailer: { loading: loadingTrailer, trailerInfo }
    }
  } = state

  const { createTask, onConfirmUpdateTask, closeTaskModal } = actions

  const { t } = useTranslation()

  const requiredFieldsPresent = [
    {
      label: t('YardTask.Kanban.TaskModal.Errors.FacilityNotSelected.Text'),
      status: !!task?.facilityId
    },
    {
      label: t('YardTask.Kanban.TaskModal.Errors.TrailerNotSelected.Text'),
      status: !!task?.trailerId
    },
    {
      label: t('YardTask.Kanban.TaskModal.Errors.DestinationNotSelected.Text'),
      status: !!task?.toDockId || !!task?.toSlotId
    }
  ]

  return (
    <Modal
      unstable_ModalBackdropScroll
      onClose={closeTaskModal}
      isOpen={isOpen}
      size={SIZE.auto}
      {...ModalProps}>
      <ModalHeaderStyled>
        {' '}
        {isEdit
          ? t('YardTask.Kanban.TaskModal.Header.Edit.Text')
          : t('YardTask.Kanban.TaskModal.Header.Create.Text')}
      </ModalHeaderStyled>
      <ModalBody>
        <FlexGrid flexGridColumnCount={1} flexGridRowGap={'scale600'}>
          <FlexGridItem>{t('YardTask.Kanban.TaskModal.Subtile.Text')}</FlexGridItem>
          <FlexGridItem>
            <SelectFacilityField />
          </FlexGridItem>
          <FlexGridItem>
            <SelectTrailerField />
          </FlexGridItem>
          <FlexGridItem>
            {loadingTrailer && (
              <LoadingAppointment>
                <Spinner size={24} />
              </LoadingAppointment>
            )}
            {trailerInfo && (
              <div>
                {trailerInfo?.appointments?.map(appointment => (
                  <CardAppointmentWrapper>
                    <AppointmentCardSmall appointment={appointment} showAnswers={true} />
                  </CardAppointmentWrapper>
                ))}
                <FromWrapper>
                  <FromLabel>{t('YardTask.Kanban.TaskModal.Fields.From.Text')}</FromLabel>
                  <FromText data-testid="task-form-from">
                    {task?.fromDock?.name ||
                      task?.fromSlot?.name ||
                      t('YardTask.Kanban.TaskModal.Fields.From.Default')}
                  </FromText>
                </FromWrapper>
              </div>
            )}
          </FlexGridItem>
          <FlexGridItem>
            <SelectToField />
          </FlexGridItem>
          <FlexGridItem>
            <SelectUserField />
          </FlexGridItem>
        </FlexGrid>
      </ModalBody>
      <ModalFooter>
        <ErrorMessageButton
          data-testid="task-form-create-button"
          endEnhancer={<CheckSquareOffset />}
          errors={requiredFieldsPresent}
          onClick={isEdit ? onConfirmUpdateTask : createTask}
          isLoading={loading}
          statefulTooltipProps={{ placement: 'top' }}
          label={t('YardTask.Kanban.TaskModal.Buttons.Assign')}
        />
      </ModalFooter>
    </Modal>
  )
}

export default TaskForm
