import React, { useContext, useState } from 'react'

import Input from 'components/ui/generic/Input'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { PurchaseOrderLabelsContext } from 'components/pages/CreateAppointmentForExternalUsers'
import { useDebounce } from 'react-use'
import { useTranslation } from 'react-i18next'
import { appointmentService } from 'components/services'
import { MagnifyingGlass } from '@phosphor-icons/react'

const SearchBar = ({
  setAppointments,
  currentPage = 1,
  setCurrentPage,
  sethasDoneAtLeastOneSearch = (value: boolean) => {}
}) => {
  const { currentUser } = useContext(CurrentUserContext)
  const purchaseOrderLabelsContext = useContext(PurchaseOrderLabelsContext)
  const { purchaseOrderLabels } = purchaseOrderLabelsContext
  const { t } = useTranslation()

  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState<any>(false)

  const runSearch = async () => {
    try {
      if (search === null) {
        return
      }

      setSearching(true)
      const [json, status] = await appointmentService.getAppointmentOpenScheduling(search)

      if ([200, 204].includes(status)) {
        sethasDoneAtLeastOneSearch(true)
        setAppointments(json)
      }
      setSearching(false)
    } catch (e) {
      console.log(e)
    }
  }

  useDebounce(
    async () => {
      if (searching) {
        return
      }
      if (search) {
        runSearch()
      }
    },
    200,
    [search, currentUser, currentPage]
  )

  return (
    <Input
      endEnhancer={<MagnifyingGlass size="16px" />}
      autoComplete="off"
      name="purchase_order[identifier]"
      value={search}
      onChange={e => {
        setSearch(e.currentTarget.value)
      }}
      label={
        purchaseOrderLabels?.purchase_order_identifiers
          ? purchaseOrderLabels?.purchase_order_identifiers
          : t('Scheduler.Appointments.SearchBox.Header.Text')
      }
    />
  )
}
export default SearchBar
