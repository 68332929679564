import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import React from 'react'
import { capitalize } from 'lodash'
import NumberButton from '../../../../shared/fields/number-button'
import FormControl from 'components/ui/generic/FormControl'

const BlockCountsShared = ({ facility, setDockBlock, dockBlock, assignedEquipmentTypes }) => (
  <FlexGrid flexGridColumnCount={[1, 2, 5]} flexGridColumnGap="scale100" marginBottom="16px">
    {dockBlock?.blockCountsAttributes?.map(({ count, equipmentTypeId }, index) => {
      const label = assignedEquipmentTypes?.filter(eqType => eqType.id === equipmentTypeId)[0]?.name
      const limit =
        facility?.appointmentPreference?.dockCapacitiesAttributes?.filter(
          capacity =>
            capacity.appointmentTypeId === null && capacity.equipmentTypeId === equipmentTypeId
        )[0]?.limit || 0

      const setCount = (count: number) => {
        const newBlockCountsAttributes = dockBlock?.blockCountsAttributes
        newBlockCountsAttributes[index] = {
          ...newBlockCountsAttributes[index],
          count
        }
        setDockBlock({ ...dockBlock, blockCountsAttributes: newBlockCountsAttributes })
      }
      return (
        (limit || count) && (
          <FlexGridItem key={equipmentTypeId}>
            <FormControl label={capitalize(label)}>
              <NumberButton
                label={label}
                count={count}
                setCount={(count: number) => setCount(count)}
                limit={limit}
              />
            </FormControl>
          </FlexGridItem>
        )
      )
    })}
  </FlexGrid>
)

export default BlockCountsShared
