import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Homepage from '../homepage/Homepage'
import AppointmentRoutes from '../appointments/appointment-routes'
import UserRoutes from '../users/user-routes'
import UserOnboarding from '../users/user-onboarding'
import PublicCalendar from '../homepage/public-calendar'
import { MetabaseAnalytics } from 'components/pages/MetabaseAnalytics'
import { PrivateRoute } from 'components/components/PrivateRoute'
import { SHIPPER } from 'components/constants/user-types'
import OrdersList from '../pages/OrderList'
import DockAssignment from '../pages/DockAssignment'
import Yard from '../pages/Yard'

const ViewOnlyRoutes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        roles={[SHIPPER]}
        path={[`${match.url}analytics`, `${match.url}analytics/:id`]}
        component={MetabaseAnalytics}
      />
      <Route path={`${match.url}appointments`} component={AppointmentRoutes} />
      <Route path={`${match.url}yard`} component={Yard} />
      <Route path={`${match.url}dock_assignments`} component={DockAssignment} />
      <Route path={`${match.url}calendar`} component={PublicCalendar} />
      <Route path={`${match.url}onboarding`} component={UserOnboarding} />
      <Route exact path={`${match.url}orders`} component={OrdersList} />
      <Route path={`${match.url}user`} component={UserRoutes} />
      <Route exact path={match.url} component={Homepage} />
      <Route component={() => <Redirect to="/" />} />
    </Switch>
  </>
)

export default ViewOnlyRoutes
