import React from "react";
import { Route } from "react-router-dom";
import ScoreCards from "./score-cards";

const ShipperRoutes = ({ match }) => (
  <>
    <Route
      exact
      path={`${match.url}/:handle/score_cards`}
      component={ScoreCards}
    />
  </>
);

export default ShipperRoutes;
