import React from 'react'
import { Block } from 'baseui/block'
import { YardJockeyHeaderFlagIcon } from './YardJockey.styled'
import Header from 'components/ui/generic/Header'
import { useTranslation } from 'react-i18next'
import {
  COMPLETE_TAB,
  NEW_TAB,
  PENDING_TAB,
  useYardJockeyContext
} from '../../contexts/yard-jockey.context'
import { FlagPennant } from '@phosphor-icons/react'
import YardJockeyShiftButton from './YardJockeyShiftButton'
import { primitives } from '../../homepage/custom-theme'
import YardJockeySearchbox from './YardJockeySearchbox'
import YardJockeyTab from './YardJockeyTab'

const YardJockeyHeader = () => {
  const { t } = useTranslation()

  const {
    shiftActive,
    flagFilterOn,
    actions: { setFlagFilterOn }
  } = useYardJockeyContext()

  return (
    <Header
      leftItems={[
        <Block display="flex" gridColumnGap="24px">
          <YardJockeyTab tabName={NEW_TAB} />
          <YardJockeyTab tabName={PENDING_TAB} />
          <YardJockeyTab tabName={COMPLETE_TAB} />
        </Block>
      ]}
      rightItems={[
        <Block display="flex" gridColumnGap="24px">
          <Block>
            <YardJockeyShiftButton />
          </Block>
          <Block>
            <YardJockeyHeaderFlagIcon
              active={flagFilterOn}
              onClick={() => {
                if (shiftActive) {
                  setFlagFilterOn(!flagFilterOn)
                }
              }}>
              <FlagPennant size="14px" color={flagFilterOn ? primitives.white : ''} />
            </YardJockeyHeaderFlagIcon>
          </Block>
          <Block>
            <YardJockeySearchbox
              placeholder={t('YardJockey.Header.SearchPlaceholder.Text')}
              collapsable={true}
            />
          </Block>
        </Block>
      ]}
    />
  )
}
export default YardJockeyHeader
