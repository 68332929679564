import { useCallback, useState } from 'react'

type UseHttp<T> = [(body?: unknown) => Promise<T>, { data: T; loading: boolean }]

export const useHttp = <T>(request: (body: unknown) => Promise<T>): UseHttp<T> => {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(false)

  const makeRequest = useCallback(
    async (body?: unknown) => {
      try {
        setLoading(true)
        const res = await request(body)
        setData(res)
        return res
      } catch (err) {
        throw err
      } finally {
        setLoading(false)
      }
    },
    [request]
  )

  return [makeRequest, { data, loading }]
}
