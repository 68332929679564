import React, { useContext, useEffect, useState } from 'react'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { SCHEDULER } from 'components/models/User'
import TwilioPhoneNumber from 'components/shared/fields/twilio-phone-number'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import { inviteService } from 'components/services/invite.service'
import { toast } from 'components/utils/toast'
import { SelectSearch } from 'components/components/SelectSearch'
import CreateOrUpdateVendorModal from 'components/schedulers/SchedulerInstantSearch/create-modal'

import { useStyletron } from 'baseui'

import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'

import Button from 'components/ui/generic/Button'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Select from 'components/ui/generic/Select'
import { EXTERNAL } from '../../models/Role'
import { useUserRolesContext } from '../../contexts/user-roles.context'

export const InviteSchedulerModal = ({ isOpen, close }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [user, setUser] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [createVendorModalOpen, setCreateVendorModalOpen] = useState<boolean>(false)
  const [css, theme] = useStyletron()
  const [selectedExternalUserRole, setSelectedExternalUserRole] = useState<any>([])
  const [externalUsersRoles, setExternalUsersRoles] = useState<any>([])
  const { t } = useTranslation()
  const {
    roles: { items },
    actions: { search }
  } = useUserRolesContext()

  useEffect(() => {
    search(undefined, [EXTERNAL])
  }, [])

  useEffect(() => {
    if (items && items.length) {
      setExternalUsersRoles(
        items.map(item => {
          return { label: item.name, id: item.id }
        })
      )
    }
  }, [items])

  const onClose = () => {
    close()
    setUser({})
    setSelectedExternalUserRole([])
  }

  const inviteUser = async () => {
    setLoading(true)
    await inviteService.inviteUser(
      {
        ...user,
        userType: SCHEDULER
      },
      currentUser.shipperId,
      onClose
    )
    setLoading(false)
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalHeader>
          {t('Settings.VendorCompanyUsers.InviteCompanyVendorModal.Header.Text')}
        </ModalHeader>
        <ModalBody>
          <FormControl
            label={t(
              'Settings.VendorCompanyUsers.InviteCompanyVendorModal.Fields.Name.Label.Text'
            )}>
            <Input
              autoComplete="off"
              name="user[name]"
              value={user.name}
              onChange={evt =>
                setUser({
                  ...user,
                  name: evt.currentTarget.value
                })
              }
            />
          </FormControl>
          <div className={css({ position: 'relative', marginBottom: theme.sizing.scale600 })}>
            <FormControl
              label={t(
                'Settings.VendorCompanyUsers.InviteCompanyVendorModal.Fields.VendorCompany.Label.Text'
              )}>
              <CyberAutocomplete
                placeholder={t(
                  'Settings.VendorCompanyUsers.InviteCompanyVendorModal.Fields.VendorCompany.PlaceHolder.Text'
                )}
                record={user}
                setRecord={setUser}
                indexName="scheduler"
              />
            </FormControl>
            <Button
              onClick={() => {
                setCreateVendorModalOpen(true)
              }}>
              {t(
                'Settings.VendorCompanyUsers.InviteCompanyVendorModal.CreateVendorCompanyButton.Text'
              )}
            </Button>
          </div>
          <FormControl
            label={t(
              'Settings.VendorCompanyUsers.InviteCompanyVendorModal.Fields.EmailAddress.Label.Text'
            )}>
            <Input
              autoComplete="off"
              name="user[email_address]"
              value={user.emailAddress}
              onChange={evt =>
                setUser({
                  ...user,
                  emailAddress: evt.currentTarget.value
                })
              }
            />
          </FormControl>
          <FormControl
            label={t(
              'Settings.VendorCompanyUsers.InviteCompanyVendorModal.Fields.PhoneNumber.Label.Text'
            )}>
            <TwilioPhoneNumber record={user} setRecord={setUser} />
          </FormControl>
          <FormControl
            label={t(
              'Settings.VendorCompanyUsers.InviteCompanyVendorModal.Fields.Facility.Label.Text'
            )}>
            <SelectSearch
              multi
              record={user}
              entity="facility"
              placeholder={t(
                'Settings.VendorCompanyUsers.InviteCompanyVendorModal.Fields.Facility.PlaceHolder.Text'
              )}
              onValuesChange={ids =>
                setUser(user => ({
                  ...user,
                  facilities: ids
                }))
              }
            />
          </FormControl>
          <FormControl
            label={t(
              'Settings.VendorCompanyUsers.InviteCompanyVendorModal.Fields.Role.Label.Text'
            )}>
            <Select
              clearable={false}
              placeholder={t(
                'Settings.VendorCompanyUsers.InviteCompanyVendorModal.Fields.Role.PlaceHolder.Text'
              )}
              backspaceClearsInputValue
              maxDropdownHeight="250px"
              deleteRemoves={true}
              value={selectedExternalUserRole}
              options={externalUsersRoles}
              onChange={({ option, type }) => {
                setSelectedExternalUserRole([option])
                setUser({ ...user, userRoleId: option.id })
              }}
            />
          </FormControl>
          <ModalFooter>
            <Button
              disabled={
                !user.emailAddress ||
                !user.name ||
                !user.schedulerId ||
                user?.facilities?.length === 0
              }
              isLoading={loading}
              onClick={inviteUser}>
              {t('Settings.VendorCompanyUsers.InviteCompanyVendorModal.InviteButton.Text')}
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      <CreateOrUpdateVendorModal
        isOpen={createVendorModalOpen}
        close={() => setCreateVendorModalOpen(false)}
        getVendor={vendor => {
          setUser({
            ...user,
            schedulerId: vendor?.id,
            schedulerName: vendor?.name
          })
        }}
      />
    </>
  )
}
