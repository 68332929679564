import { Block } from 'baseui/block'

import { ParagraphSmall } from 'baseui/typography'

import { Modal } from 'baseui/modal'
import { useEquipmentTypesContext } from 'components/contexts/equipment-types.context'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import StyledSpinner from 'components/shared/styled-spinner'
import { Plus } from '@phosphor-icons/react'
import React, { useCallback, useContext, useEffect } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/specific/PrimaryButton'
import Header from 'components/ui/generic/Header'
import { AssigmentChangeConfirmationModal } from './AssignmentChangeConfirmationModal'
import { Column } from './DroppableColumn'
import { EquipmentTypeForm } from './EquipmentType.form'
import { CreateEqTypeButtonContainer, DraggableColumnGrid } from './EquipmentTypesManage.styled'

export const EquipmentTypeManage = () => {
  const { assignedEquipmentTypes } = useContext(CurrentUserContext)
  const { equipmentTypeState, actions } = useEquipmentTypesContext()
  const {
    allAvailableEqTypes,
    selectedEquipmentType,
    columns: { available, selected },
    loading,
    isFormOpen,
    confirmationNeeded
  } = equipmentTypeState

  const onConfirm = useCallback(() => {
    actions.syncAssigned(selected.list.map(type => type.id))
  }, [selected])

  const onCancel = useCallback(() => {
    actions.recalculateColumns()
    actions.setConfirmationNeeded(false)
  }, [])

  const { t } = useTranslation()

  useEffect(() => {
    if (!allAvailableEqTypes.length) {
      actions.fetchAllAvailableEqTypes()
    }
    actions.recalculateColumns()
    actions.setLoading(false)
    actions.setConfirmationNeeded(false)
  }, [assignedEquipmentTypes])

  const closeModal = useCallback(() => {
    actions.setFormOpen(false)
    actions.setSelectedEquipmentType(undefined)
  }, [])

  return (
    <>
      <Header
        title={t('Settings.EquipmentTypes.Header.Text')}
        rightItems={[
          <CreateEqTypeButtonContainer>
            <Button
              onClick={() => {
                actions.setFormOpen(true)
              }}
              startEnhancer={() => <Plus />}
              size="compact">
              {t('Settings.EquipmentTypes.Buttons.NewEquipmentType.Text')}
            </Button>
          </CreateEqTypeButtonContainer>
        ]}
      />
      <ParagraphSmall margin="0 0 16px">
        {t('Settings.EquipmentTypes.SubTitle.Text')}{' '}
      </ParagraphSmall>
      {loading ? (
        <Block display="flex" justifyContent="center" alignItems="center">
          <StyledSpinner />
        </Block>
      ) : (
        <DragDropContext onDragEnd={actions.onDragEnd} margin="0">
          <DraggableColumnGrid>
            <Column col={available} key={available.id} />
            <Column col={selected} key={selected.id} />
          </DraggableColumnGrid>
        </DragDropContext>
      )}
      <Modal isOpen={isFormOpen} onClose={closeModal} closeable animate autoFocus>
        <EquipmentTypeForm id={selectedEquipmentType?.id} onClose={closeModal} />
      </Modal>
      <AssigmentChangeConfirmationModal
        needConfirmation={confirmationNeeded}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  )
}
