import React, { useContext, useMemo } from 'react'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { Redirect, Route, RouteProps } from 'react-router-dom'

type Props = RouteProps & {
  roles: string[]
}

export const PrivateRoute = ({ roles, children, component: Component, ...props }: Props) => {
  const { currentUser } = useContext(CurrentUserContext)
  const isAuthorized = useMemo(
    () => currentUser?.admin || roles.includes(currentUser?.userType),
    [currentUser?.admin, currentUser?.userType, roles]
  )

  return (
    <Route
      {...props}
      render={({ location, ...props }) =>
        isAuthorized ? (
          Component ? (
            <Component location={location} {...props} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
