import React, { ChangeEvent } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import moment from 'moment'
import { DayCheckboxStyled } from './BlackoutModal.styled'
import { useTranslation } from 'react-i18next'
import Checkbox from 'components/ui/generic/Checkbox'

export interface RepeatingRules {
  rrules: [{ validations: { day: [0 | 1 | 2 | 3 | 4 | 5 | 6] } }]
  extimes: any[]
}

export interface Day {
  id: 0 | 1 | 2 | 3 | 4 | 5 | 6
  name: string
}

const DaysOfWeek = ({ record, setRecord }) => {
  const { t } = useTranslation()

  const DAYS: Day[] = [
    { id: 0, name: t('Common.Days.Sunday.Text') },
    { id: 1, name: t('Common.Days.Monday.Text') },
    { id: 2, name: t('Common.Days.Tuesday.Text') },
    { id: 3, name: t('Common.Days.Wednesday.Text') },
    { id: 4, name: t('Common.Days.Thursday.Text') },
    { id: 5, name: t('Common.Days.Friday.Text') },
    { id: 6, name: t('Common.Days.Saturday.Text') }
  ]
  const schedule: RepeatingRules = record.schedule || {
    rrules: [
      {
        validations: { day: [] },
        ruleType: 'IceCube::WeeklyRule',
        interval: 1,
        weekStart: 0,
        until: moment(record.endTime).add(1, 'd')
      }
    ],
    rtimes: [],
    extimes: [],
    start_time: record.startTime
  }

  const selectedDays =
    schedule && schedule.rrules && schedule.rrules[0] && schedule.rrules[0].validations.day

  const selectDay = (index: number) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const newDays = DAYS.filter(day => {
        return (
          (selectedDays.indexOf(day.id) > -1 && day !== DAYS[index]) ||
          (e.currentTarget.checked && day == DAYS[index])
        )
      }).map(day => day.id)

      setRecord({
        ...record,
        schedule: {
          ...schedule,
          rrules: [
            {
              ...schedule.rrules[0],
              validations: { day: newDays }
            }
          ]
        }
      })
    }
  }

  return (
    <FlexGrid
      flexGridColumnCount={4}
      flexGridColumnGap="scale400"
      flexGridRowGap="scale400"
      marginTop="40px">
      {DAYS.map((day, index) => {
        return (
          <FlexGridItem key={index}>
            <Checkbox
              checked={selectedDays.indexOf(day.id) > -1}
              onChange={selectDay(index)}
              label={day.name}
            />
          </FlexGridItem>
        )
      })}
    </FlexGrid>
  )
}

export default DaysOfWeek
