import { useStyletron } from 'baseui'
import { MagnifyingGlass, CircleNotch } from '@phosphor-icons/react'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddButton } from 'components/components/AddButton'
import useDebounce from 'components/hooks/use-debounce'
import Input from 'components/ui/generic/Input'
import { useUserRolesContext } from '../../contexts/user-roles.context'
import { CurrentUserContext } from '../../../components/homepage/current-user-context'

export const UserRolesInputSearch = () => {
  const [css, theme] = useStyletron()
  const [value, setValue] = useDebounce()
  const [loading, setLoading] = useState(false)
  const {
    actions: { search, setModalType, selectedRole }
  } = useUserRolesContext()
  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation()
  const handleAddButton = () => {
    setModalType(t('Settings.UserRoles.Modal.Header.Alternatives.Create.Text'))
    selectedRole({
      audience: 'Internal'
    })
  }
  const PLACEHOLDER = t('Settings.UserRoles.SearchBox.PlaceHolder.Text')

  useEffect(() => {
    search(value)
  }, [value])

  return (
    <div
      className={css({ display: 'flex', gap: theme.sizing.scale800, width: '400px' })}
      data-testid="user-role-search-bar">
      <Input
        startEnhancer={
          loading ? (
            <CircleNotch
              className={css({
                animationDuration: '800ms',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'linear',
                animationName: {
                  from: {
                    transform: 'rotate(0deg)'
                  },
                  to: {
                    transform: 'rotate(360deg)'
                  }
                }
              })}
              size={theme.sizing.scale600}
            />
          ) : (
            <MagnifyingGlass />
          )
        }
        placeholder={PLACEHOLDER}
        autoComplete="off"
        name="search[name]"
        onChange={evt => {
          setValue(evt.currentTarget.value)
        }}
      />
      {currentUser?.admin && <AddButton onClick={handleAddButton} />}
    </div>
  )
}
