import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import React, { useContext } from 'react'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import { DATE_FORMAT } from 'components/constants/date-format'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import { HeadingLevel } from 'baseui/heading'
import { KIND, SIZE } from 'baseui/button'

import { Textarea } from 'baseui/textarea'
import { dateTranslations } from 'translations/date-languages'
import i18n from 'translations/i18n'
import { useOrdersContext } from 'components/contexts/orders.context'
import { useTranslation } from 'react-i18next'

import Input from 'components/ui/generic/Input'
import Datepicker from 'components/ui/generic/DatePicker'
import FormControl from 'components/ui/generic/FormControl'
import Select from 'components/ui/generic/Select'
import OrderSelectFacilityField from './OrderSelectFacilityField'
import { CurrentUserContext } from '../../homepage/current-user-context'
import StyledSpinner from '../../shared/styled-spinner'

const OrderForm = ({ onClose }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const {
    orders: {
      order,
      selectedFacility,
      loading,
      unitOfMeasureOptions,
      selectedUnitOfMeasure,
      weightUnitOfMeasureOptions,
      selectedWeightUnitOfMeasure,
      orderPreferences
    },
    actions: { setFacility, setOrder, setUnitOfMeasure, setWeightUnitOfMeasure, saveChanges }
  } = useOrdersContext()

  const { t } = useTranslation()
  const dTranslation = dateTranslations[i18n.language]
  const requiredFieldsPresent = [
    {
      label: t('Orders.CreateEditOrderModal.Validations.PriorityRequired.Text'),
      status: !!order?.priority
    },
    {
      label: t('Orders.CreateEditOrderModal.Validations.TypeRequired.Text'),
      status: !!order?.orderType
    },
    {
      label: t('Orders.CreateEditOrderModal.Validations.StatusRequired.Text'),
      status: !!order?.status
    },
    {
      label: t('Orders.CreateEditOrderModal.Validations.OrderNumberRequired.Text'),
      status: !!order?.number
    },
    {
      label: t('Orders.CreateEditOrderModal.Validations.FacilityRequired.Text'),
      status: !!order?.facilitiesId
    },
    {
      label: t('Orders.CreateEditOrderModal.Validations.StartDateBeforeEndDate.Text'),
      status:
        order?.startDatePeriod && order?.endDatePeriod
          ? new Date(order?.startDatePeriod) <= new Date(order?.endDatePeriod)
          : true
    }
  ]

  const submitOrderForm = async () => {
    await saveChanges()
    onClose()
  }

  if (!currentUser) {
    return <StyledSpinner />
  }

  return (
    <HeadingLevel>
      <ModalHeader data-testid="create-edit-modal-header">
        {order.id
          ? t('Orders.CreateEditOrderModal.Header.Alternatives.Update.Text')
          : t('Orders.CreateEditOrderModal.Header.Alternatives.Create.Text')}{' '}
        {t('Orders.CreateEditOrderModal.Header.Text')}
      </ModalHeader>
      <ModalBody>
        <FlexGrid
          flexGridColumnCount={[1, 1, 2]}
          flexDirection="row"
          flexGridRowGap="scale200"
          flexGridColumnGap="scale1000">
          <FlexGridItem flex={[null, null, '2']}>
            <FlexGrid
              flexGridColumnCount={2}
              flexGridRowGap="scale200"
              flexGridColumnGap="scale1000">
              <FlexGridItem>
                <FormControl
                  htmlFor="idNumber"
                  label={t('Orders.CreateEditOrderModal.Fields.OrderNumber.Label.Text')}>
                  <Input
                    disabled={currentUser?.viewOnly}
                    id="idNumber"
                    autoComplete="off"
                    name="idNumber"
                    value={order?.number}
                    onChange={e => {
                      setOrder({
                        ...order,
                        number: e.currentTarget.value
                      })
                    }}
                    maxLength={30}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="orderTypeSelect"
                  label={t('Orders.CreateEditOrderModal.Fields.OrderType.Label.Text')}>
                  <Select
                    disabled={currentUser?.viewOnly}
                    id="orderTypeSelect"
                    required
                    options={[
                      {
                        label: t('Orders.CreateEditOrderModal.Fields.OrderType.Items.Sales.Text'),
                        id: 'sales'
                      },
                      {
                        label: t(
                          'Orders.CreateEditOrderModal.Fields.OrderType.Items.Purchase.Text'
                        ),
                        id: 'purchase'
                      },
                      {
                        label: t(
                          'Orders.CreateEditOrderModal.Fields.OrderType.Items.Receiving.Text'
                        ),
                        id: 'receiving'
                      }
                    ]}
                    value={order?.orderType ? [{ id: order.orderType }] : [{ id: 'purchase' }]}
                    placeholder=""
                    searchable={false}
                    clearable={false}
                    onChange={params => {
                      setOrder({
                        ...order,
                        orderType: params.option.id as string
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <OrderSelectFacilityField
                  order={order}
                  setOrder={setOrder}
                  facility={selectedFacility}
                  setFacility={setFacility}
                  disabled={currentUser?.viewOnly}
                />
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="prioritySelect"
                  label={t('Orders.CreateEditOrderModal.Fields.Priority.Label.Text')}>
                  <Select
                    id="prioritySelect"
                    required
                    disabled={currentUser?.viewOnly}
                    options={[
                      {
                        label: t('Orders.CreateEditOrderModal.Fields.Priority.Items.High.Text'),
                        id: 'high'
                      },
                      {
                        label: t('Orders.CreateEditOrderModal.Fields.Priority.Items.Regular.Text'),
                        id: 'regular'
                      },
                      {
                        label: t('Orders.CreateEditOrderModal.Fields.Priority.Items.Low.Text'),
                        id: 'low'
                      }
                    ]}
                    value={order?.priority ? [{ id: order.priority }] : [{ id: 'regular' }]}
                    placeholder=""
                    searchable={false}
                    clearable={false}
                    onChange={params => {
                      setOrder({
                        ...order,
                        priority: params.option.id as string
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
            </FlexGrid>
            <FlexGrid
              flexGridColumnCount={2}
              flexGridRowGap="scale200"
              flexGridColumnGap="scale1000">
              <FlexGridItem>
                <FormControl
                  htmlFor="statusSelect"
                  label={t('Orders.CreateEditOrderModal.Fields.Status.Label.Text')}>
                  <Select
                    id="statusSelect"
                    required
                    disabled={currentUser?.viewOnly}
                    options={[
                      {
                        label: t('Orders.CreateEditOrderModal.Fields.Status.Items.Open.Text'),
                        id: 'open'
                      },
                      {
                        label: t('Orders.CreateEditOrderModal.Fields.Status.Items.Closed.Text'),
                        id: 'closed'
                      }
                    ]}
                    value={order?.status ? [{ id: order.status }] : [{ id: 'open' }]}
                    placeholder=""
                    searchable={false}
                    clearable={false}
                    onChange={params => {
                      setOrder({
                        ...order,
                        status: params.option.id as string
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="schedulerSelect"
                  label={t('Orders.CreateEditOrderModal.Fields.Customer.Label.Text')}>
                  <CyberAutocomplete
                    id="schedulerSelect"
                    record={order}
                    placeholder={t('Orders.CreateEditOrderModal.Fields.Customer.PlaceHolder.Text')}
                    setRecord={setOrder}
                    indexName="scheduler"
                    disabled={currentUser?.viewOnly}
                  />
                </FormControl>
              </FlexGridItem>
            </FlexGrid>
            <FlexGrid
              flexGridColumnCount={2}
              flexGridRowGap="scale200"
              flexGridColumnGap="scale1200">
              <FlexGridItem>
                <FormControl
                  label={t(
                    'Orders.CreateEditOrderModal.Fields.RequestedAppointmentPeriodStart.Label.Text'
                  )}>
                  <Datepicker
                    id="startDate"
                    locale={dTranslation}
                    disabled={currentUser?.viewOnly}
                    value={order?.startDatePeriod ? [order?.startDatePeriod] : []}
                    formatString={DATE_FORMAT}
                    placeholder={DATE_FORMAT}
                    maxDate={order?.endDatePeriod ? order.endDatePeriod : undefined}
                    onChange={({ date }) =>
                      setOrder({
                        ...order,
                        startDatePeriod: date as Date
                      })
                    }
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  label={t(
                    'Orders.CreateEditOrderModal.Fields.RequestedAppointmentPeriodEnd.Label.Text'
                  )}>
                  <Datepicker
                    locale={dTranslation}
                    disabled={currentUser?.viewOnly}
                    value={order?.endDatePeriod ? [order.endDatePeriod] : []}
                    formatString={DATE_FORMAT}
                    placeholder={DATE_FORMAT}
                    minDate={order?.startDatePeriod ? order?.startDatePeriod : undefined}
                    onChange={({ date }) =>
                      setOrder({
                        ...order,
                        endDatePeriod: date as Date
                      })
                    }
                  />
                </FormControl>
              </FlexGridItem>
            </FlexGrid>
          </FlexGridItem>
          <FlexGridItem flex={[null, null, '1']}>
            <FlexGrid
              flexGridColumnCount={2}
              flexGridRowGap="scale200"
              flexGridColumnGap="scale1000">
              <FlexGridItem>
                <FormControl
                  htmlFor="customerPo"
                  label={t('Orders.CreateEditOrderModal.Fields.CustomerPO.Label.Text')}>
                  <Input
                    id="customerPo"
                    autoComplete="off"
                    name="customerPo"
                    value={order?.customerPo}
                    disabled={currentUser?.viewOnly}
                    onChange={e => {
                      setOrder({
                        ...order,
                        customerPo: e.currentTarget.value
                      })
                    }}
                    maxLength={30}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="loadId"
                  label={t('Orders.CreateEditOrderModal.Fields.LoadId.Label.Text')}>
                  <Input
                    id="loadId"
                    autoComplete="off"
                    name="loadId"
                    value={order?.loadId}
                    disabled={currentUser?.viewOnly}
                    onChange={e => {
                      setOrder({
                        ...order,
                        loadId: e.currentTarget.value
                      })
                    }}
                    maxLength={30}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="products"
                  label={t('Orders.CreateEditOrderModal.Fields.Products.Label.Text')}>
                  <Input
                    id="products"
                    autoComplete="off"
                    name="products"
                    value={order?.products}
                    disabled={currentUser?.viewOnly}
                    onChange={e => {
                      setOrder({
                        ...order,
                        products: e.currentTarget.value
                      })
                    }}
                    maxLength={30}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="scacSelect"
                  label={t('Orders.CreateEditOrderModal.Fields.SCACcode.Label.Text')}>
                  <CyberAutocomplete
                    id="scacSelect"
                    record={order}
                    setRecord={setOrder}
                    disabled={currentUser?.viewOnly}
                    indexName="carrier"
                    placeholder={t(
                      'Settings.CarrierUsers.CarrierUserModal.Fields.Carrier.Placeholder.Text'
                    )}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="unitOfMeasureSelect"
                  label={t('Orders.CreateEditOrderModal.Fields.UnitOfMeasure.Label.Text')}>
                  <Select
                    id="unitOfMeasureSelect"
                    required
                    options={unitOfMeasureOptions}
                    value={selectedUnitOfMeasure}
                    disabled={currentUser?.viewOnly}
                    placeholder=""
                    searchable={false}
                    clearable
                    onChange={params => {
                      setUnitOfMeasure(params.option)
                      setOrder({
                        ...order,
                        quantityUomId: params.option.id as string
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="quantity"
                  label={t('Orders.CreateEditOrderModal.Fields.Quantity.Label.Text')}>
                  <Input
                    id="quantity"
                    autoComplete="off"
                    name="quantity"
                    value={order?.quantity}
                    disabled={currentUser?.viewOnly}
                    type="number"
                    onChange={e => {
                      setOrder({
                        ...order,
                        quantity: parseFloat(e.currentTarget.value)
                      })
                    }}
                    maxLength={30}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="weightUnitOfMeasureSelect"
                  label={t('Orders.CreateEditOrderModal.Fields.WeightUnitOfMeasure.Label.Text')}>
                  <Select
                    id="weightUnitOfMeasureSelect"
                    required
                    options={weightUnitOfMeasureOptions}
                    value={selectedWeightUnitOfMeasure}
                    placeholder=""
                    searchable={false}
                    disabled={currentUser?.viewOnly}
                    clearable
                    onChange={params => {
                      setWeightUnitOfMeasure(params.option)
                      setOrder({
                        ...order,
                        weightUomId: params.option.id as string
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  htmlFor="weight"
                  label={t('Orders.CreateEditOrderModal.Fields.Weight.Label.Text')}>
                  <Input
                    id="weight"
                    autoComplete="off"
                    name="weight"
                    value={order?.weight}
                    disabled={currentUser?.viewOnly}
                    type="number"
                    onChange={e => {
                      setOrder({
                        ...order,
                        weight: parseFloat(e.currentTarget.value)
                      })
                    }}
                    maxLength={30}
                  />
                </FormControl>
              </FlexGridItem>
              {orderPreferences?.enableCustomFields ? (
                <>
                  <FlexGridItem key={'customFieldOne'}>
                    <FormControl
                      htmlFor="customFieldOne"
                      label={orderPreferences?.customFieldLabelOne}>
                      <Input
                        id="customFieldOne"
                        autoComplete="off"
                        name="customFieldOne"
                        value={order?.customFieldOne}
                        disabled={currentUser?.viewOnly}
                        onChange={e => {
                          setOrder({
                            ...order,
                            customFieldOne: e.currentTarget.value
                          })
                        }}
                        maxLength={30}
                      />
                    </FormControl>
                  </FlexGridItem>
                  <FlexGridItem key={'customFieldTwo'}>
                    <FormControl
                      htmlFor="customFieldTwo"
                      label={orderPreferences?.customFieldLabelTwo}>
                      <Input
                        id="customFieldTwo"
                        autoComplete="off"
                        name="customFieldTwo"
                        value={order?.customFieldTwo}
                        disabled={currentUser?.viewOnly}
                        onChange={e => {
                          setOrder({
                            ...order,
                            customFieldTwo: e.currentTarget.value
                          })
                        }}
                        maxLength={30}
                      />
                    </FormControl>
                  </FlexGridItem>
                </>
              ) : null}
            </FlexGrid>
            <FlexGrid
              flexGridColumnCount={1}
              flexGridRowGap="scale200"
              flexGridColumnGap="scale1000">
              <FlexGridItem>
                <FormControl
                  htmlFor="deliveryInstructions"
                  label={t('Orders.CreateEditOrderModal.Fields.DeliveryInstructions.Label.Text')}>
                  <Textarea
                    id="deliveryInstructions"
                    name="deliveryInstructions"
                    value={order?.deliveryInstructions}
                    disabled={currentUser?.viewOnly}
                    onChange={e => {
                      setOrder({
                        ...order,
                        deliveryInstructions: e.currentTarget.value
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
            </FlexGrid>
          </FlexGridItem>
        </FlexGrid>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          overrides={{ BaseButton: { style: { marginLeft: '10px' } } }}
          kind={KIND.secondary}
          onClick={onClose}
          size={SIZE.compact}>
          {t('Orders.CreateEditOrderModal.Buttons.Cancel.Text')}
        </ModalButton>
        {!currentUser?.viewOnly && (
          <ErrorMessageButton
            errors={requiredFieldsPresent}
            onClick={submitOrderForm}
            isLoading={loading}
            statefulTooltipProps={{ placement: 'top' }}
            label={
              order.id
                ? t('Orders.CreateEditOrderModal.Header.Alternatives.Update.Text')
                : t('Orders.CreateEditOrderModal.Header.Alternatives.Create.Text')
            }
          />
        )}
      </ModalFooter>
    </HeadingLevel>
  )
}

export default OrderForm
