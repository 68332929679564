// utils/validation.js

import { IS_DROP } from 'components/contexts/appointment-context'
import { t } from 'i18next'

export const validationChecks = [
  {
    key: 'equipmentTypeId',
    label: t(
      'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.EquipmentTypeMustBeSelected.Text'
    ),
    validate: value => value !== null && value !== undefined && value.trim() !== ''
  },
  {
    key: 'appointmentTypeId',
    label: t(
      'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.AppointmentTypeMustBeSelected.Text'
    ),
    validate: value => value !== null && value !== undefined && value.trim() !== ''
  },
  {
    key: 'arrivalTime',
    label: t(
      'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.ArrivalDateRequired.Text'
    ),
    validate: value => value !== null && value !== undefined && !isNaN(new Date(value).getTime())
  },
  {
    key: 'purchaseOrdersAttributes',
    label: t(
      'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.OrderValidation.Text'
    ),
    validate: value =>
      Array.isArray(value) &&
      value.length > 0 &&
      value.every(order => order.identifier.trim() !== '')
  },
  {
    key: 'answersAttributes',
    label: t(
      'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.AdditionalQuestionsRequired.Text'
    ),
    validate: value => {
      return (
        !Array.isArray(value) ||
        value.length === 0 ||
        value.every(answer => !answer.error || answer.response.trim() !== '')
      )
    }
  }
]

export const additionalChecks = [
  {
    key: 'isTimeSet',
    label: t(
      'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.ArrivalTimeRequired.Text'
    ),
    validate: value => value === true
  },
  {
    key: 'isInboundUniqueLoadIdentifierError',
    label: t(
      'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.AdditionalQuestionsRequired.Text'
    ),
    validate: value => value === false
  },
  {
    key: 'isOutboundUniqueLoadIdentifierError',
    label: t(
      'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.AdditionalQuestionsRequired.Text'
    ),
    validate: value => value === false
  }
]

const capitalize = text => text && text.charAt(0).toUpperCase() + text.slice(1)

export const validateAppointment = state => {
  const {
    appointments,
    appointmentDirections,
    createAppointmentButtonDisableFlags,
    handlingMethod
  } = state

  const isDrop = handlingMethod === IS_DROP
  const validateFields = (appointment, direction) => {
    return validationChecks.map(({ key, validate, label }) => ({
      key,
      label: capitalize(`${isDrop ? direction + ' ' : ''}${label}`),
      status: validate(appointment[key])
    }))
  }

  const validateAdditionalChecks = () => {
    return additionalChecks.map(({ key, validate, label }) => ({
      key,
      label: capitalize(label),
      status: validate(createAppointmentButtonDisableFlags[key])
    }))
  }

  const validationResults = appointmentDirections.flatMap(direction =>
    validateFields(appointments[direction], direction)
  )

  const additionalResults = validateAdditionalChecks()

  const allValidationResults = [...validationResults, ...additionalResults]
  const isValid = allValidationResults.every(result => result.status)
  const invalidFields = allValidationResults
    .filter(result => !result.status)
    .map(result => ({ label: result.label, status: !result.status }))

  return {
    isDisabled: !isValid,
    invalidFields
  }
}
