import React, { useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import { MagnifyingGlass, CircleNotch } from '@phosphor-icons/react'
import { AddButton } from 'components/components/AddButton'
import Input from 'components/ui/generic/Input'
import CreateOrUpdateVendorModal from './create-modal'

const SchedulerInstantSearchInput = ({
  currentRefinement,
  refine,
  isSearchStalled,
  vendor,
  isOpen,
  setIsOpen,
  setSelectedScheduler
}) => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  return (
    <>
      <CreateOrUpdateVendorModal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false)
          setSelectedScheduler(undefined)
          refine()
        }}
        vendor={vendor}
      />
      <div className={css({ display: 'flex', gap: theme.sizing.scale800 })}>
        <Input
          startEnhancer={
            isSearchStalled ? (
              <CircleNotch
                className={css({
                  animationDuration: '800ms',
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear',
                  animationName: {
                    from: {
                      transform: 'rotate(0deg)'
                    },
                    to: {
                      transform: 'rotate(360deg)'
                    }
                  }
                })}
                size={theme.sizing.scale600}
              />
            ) : (
              <MagnifyingGlass />
            )
          }
          size="compact"
          placeholder={t('Settings.VendorCompanies.SearchBox.PlaceHolder.Text')}
          autoComplete="off"
          name="search[name]"
          value={currentRefinement}
          onChange={evt => refine(evt.currentTarget.value)}
        />
        <AddButton
          onClick={() => setIsOpen(true)}
          title={`${t(
            'Settings.VendorCompanyUsers.VendorCompanyModal.Header.Alternatives.Create.Text'
          )}`}
        />
      </div>
    </>
  )
}

// The 'as any' here is so I can pass params to this connected component that are not in it's signature
const connectedSchedulerInstantSearchInput = connectSearchBox(SchedulerInstantSearchInput) as any

export default connectedSchedulerInstantSearchInput
