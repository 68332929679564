import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FIXED_DOCK_DOORS_COLUMN,
  useYardScheduleContext
} from '../../contexts/yard-schedule.context'
import Checkbox from 'components/ui/generic/Checkbox'
import { capitalize } from 'lodash'
import { appointmentService } from '../../services'
import { Appointment } from '../../appointments/types'
import { ParagraphMedium } from 'baseui/typography'
import StyledSpinner from '../../shared/styled-spinner'
import { Block } from 'baseui/block'
import VButton from '../../ui/generic/Button'
import { ArrowLineDown, ArrowLineUp, CheckSquareOffset } from '@phosphor-icons/react'
import Tag from '../../ui/specific/Tag'
import YardScheduleDropModalSlotDockSelect from './YardScheduleDropModalSlotDockSelect'
import Divider from '../../ui/generic/Divider'
import {
  FromWrapper,
  FromLabel,
  FromText,
  ModalBodyStyle,
  ModalTitle,
  Paragraph
} from './YardScheduleDropModal.styled'
import YardScheduleDropModalYardJockey from './YardScheduleDropModalYardJockey'
import { AppointmentCardSmall } from 'components/components/AppointmentCard'

const ICONS_SIZE = 10

const YardScheduleDropModal = () => {
  const {
    dropModalFromZoneName,
    dropModalAppointmentId,
    dropModalToZoneName,
    dropModalIsOpen,
    dropModalLoading,
    dropModalForDriver,
    dropModalDriverAssignment,
    actions: { closeDropModal, setDropModalLoading, moveTrailer, setDropModalDriverAssignment }
  } = useYardScheduleContext()

  const { t } = useTranslation()

  const [appointment, setAppointment] = useState<Appointment>(null)

  useEffect(() => {
    if (dropModalAppointmentId) {
      setDropModalLoading(true)
      appointmentService
        .get(dropModalAppointmentId)
        .then(([data, status]) => {
          if ([304, 200].includes(status)) {
            setAppointment(data)
          }
        })
        .finally(() => {
          setDropModalLoading(false)
        })
    }
  }, [dropModalAppointmentId])

  return (
    <Modal
      onClose={closeDropModal}
      closeable={true}
      isOpen={dropModalIsOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      unstable_ModalBackdropScroll>
      {dropModalLoading ? (
        <Block display="flex" justifyContent="center" alignItems="center" height="500px">
          <StyledSpinner />
        </Block>
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>{appointment?.confirmationId}</ModalTitle>
            <ParagraphMedium margin={0}>
              {t('YardSchedule.DropModal.Title.Text', {
                type:
                  dropModalToZoneName === FIXED_DOCK_DOORS_COLUMN.title
                    ? t('Common.Fields.Dock.Text')
                    : t('Common.Fields.Yard.Text'),
                place:
                  dropModalToZoneName === FIXED_DOCK_DOORS_COLUMN.title
                    ? t('Common.Fields.Door.Text')
                    : t('Common.Fields.Spot.Text')
              })}
            </ParagraphMedium>
            <Divider />
          </ModalHeader>
          <ModalBody $style={ModalBodyStyle}>
            <Paragraph>
              {dropModalForDriver
                ? t('YardSchedule.DropModal.DriverBody.Text', {
                    place:
                      dropModalToZoneName === FIXED_DOCK_DOORS_COLUMN
                        ? t('YardSchedule.DropModal.DockPlace.Text')
                        : t('YardSchedule.DropModal.YardPlace.Text')
                  })
                : t('YardSchedule.DropModal.YardJockeyBody.Text', {
                    place:
                      dropModalToZoneName === FIXED_DOCK_DOORS_COLUMN
                        ? t('YardSchedule.DropModal.DockPlace.Text')
                        : t('YardSchedule.DropModal.YardPlace.Text')
                  })}
            </Paragraph>
            <AppointmentCardSmall appointment={appointment} showAnswers={true} />
            <FromWrapper>
              <FromLabel>{t('Common.Fields.From.Text')}</FromLabel>
              <FromText>{dropModalFromZoneName}</FromText>
            </FromWrapper>
            <YardScheduleDropModalSlotDockSelect />
            {!dropModalForDriver && (
              <Block marginTop="10px">
                <Checkbox
                  checked={dropModalDriverAssignment}
                  onChange={e => {
                    setDropModalDriverAssignment(e.target.checked)
                  }}
                  label={t('YardSchedule.DropModal.DriverAssignment.Text')}
                />
              </Block>
            )}
            {!dropModalForDriver && !dropModalDriverAssignment && (
              <YardScheduleDropModalYardJockey />
            )}
          </ModalBody>
          <ModalFooter>
            <VButton
              kind="primary"
              endEnhancer={<CheckSquareOffset size={16} />}
              onClick={moveTrailer}>
              {t('YardSchedule.DropModal.AssignButton.Text', {
                type:
                  dropModalToZoneName === FIXED_DOCK_DOORS_COLUMN
                    ? t('Common.Fields.Dock.Text')
                    : t('Common.Fields.Yard.Text'),
                place:
                  dropModalToZoneName === FIXED_DOCK_DOORS_COLUMN
                    ? t('Common.Fields.Door.Text')
                    : t('Common.Fields.Spot.Text')
              })}
            </VButton>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}

export default YardScheduleDropModal
