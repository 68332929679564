import React, { useState } from 'react'
import { ButtonGroup } from 'baseui/button-group'
import useSizing from './hooks/use-sizing'

import Button from 'components/ui/generic/Button'
import FormControl from 'components/ui/generic/FormControl'

interface AppointmentTypeProps {
  appointmentTypes: any[]
  label?: string
  onClick: Function
}

const AppointmentType = (props: AppointmentTypeProps) => {
  const { appointmentTypes, label, onClick } = props
  const { customBreakPoints } = useSizing()
  const [selected, setSelected] = useState<number>(null)
  if (!appointmentTypes || appointmentTypes.length < 2) {
    return <></>
  }
  return (
    <FormControl label={label || 'Appointment Type'}>
      <ButtonGroup
        onClick={(event, index) => {
          setSelected(index)
          onClick(event, index)
        }}
        selected={selected}
        overrides={{
          Root: { style: { flexWrap: 'wrap', gap: '10px' } }
        }}
        size="compact">
        {appointmentTypes.map((appointmentType, index) => {
          return <Button key={index}>{appointmentType.name}</Button>
        })}
      </ButtonGroup>
    </FormControl>
  )
}

export default AppointmentType
