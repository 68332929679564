import { DEFAULT_SCHEDULER_PERMISSION } from '../constants/default-permissions'
import { DOCUMENT_TYPE } from '../models/Question'

const checkAnswersRequireness = (
  answers,
  temporalFiles,
  documentSelections,
  listOfErrors
): void => {
  answers.forEach(({ question, response, prompt, attachedDocuments }) => {
    const permission = question.questionPermissionsAttributes[0] || DEFAULT_SCHEDULER_PERMISSION

    if (permission?.required) {
      if (question.answerType === DOCUMENT_TYPE) {
        const answerTemporalFiles =
          temporalFiles.filter(tf => tf?.questionId === question?.id && tf.files.length > 0) ?? []

        const deletedIds = documentSelections.map(ds => ds?.documentId)
        const remainingIds = attachedDocuments?.filter(ad => !deletedIds.includes(ad?.id)) ?? []

        if (answerTemporalFiles?.length === 0 && remainingIds?.length === 0) {
          listOfErrors.push({
            label: `Question (${prompt}) must be uploaded`,
            status: false
          })
        }
      } else if (!response) {
        listOfErrors.push({
          label: `Question (${prompt}) must be answered`,
          status: false
        })
      }
    }
  })
}

export default checkAnswersRequireness
