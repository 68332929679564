import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import Header from 'components/ui/generic/Header'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Button from 'components/ui/specific/PrimaryButton'
import Checkbox from 'components/ui/generic/Checkbox'
import { useOrderPreferencesContext } from 'components/contexts/order-preferences.context'
import React from 'react'
import { useTranslation } from 'react-i18next'

const OrderPreferences = () => {
  const {
    orderPreferences: { loading, orderPreference },
    actions: { setOrderPreferences, saveChanges }
  } = useOrderPreferencesContext()
  const { t } = useTranslation()
  return (
    <>
      <Header title={t('Settings.OrderPreferences.Header.Text')} />

      <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400" maxWidth="640px">
        <FlexGridItem>
          <FormControl>
            <Checkbox
              checked={orderPreference?.enableCustomFields}
              onChange={e =>
                setOrderPreferences({
                  ...orderPreference,
                  enableCustomFields: e.currentTarget.checked
                })
              }
              label={t('Settings.OrderPreferences.Form.Fields.EnableCustomFields.Label.Text')}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl
            htmlFor="customFieldLabelOne"
            label={t('Settings.OrderPreferences.Form.Fields.CustomFieldLabelOne.Label.Text')}>
            <Input
              id="customFieldLabelOne"
              autoComplete="off"
              name="customFieldLabelOne"
              value={orderPreference?.customFieldLabelOne}
              onChange={e => {
                setOrderPreferences({
                  ...orderPreference,
                  customFieldLabelOne: e.currentTarget.value
                })
              }}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl
            htmlFor="customFieldLabelTwo"
            label={t('Settings.OrderPreferences.Form.Fields.CustomFieldLabelOne.Label.Text')}>
            <Input
              id="customFieldLabelTwo"
              autoComplete="off"
              name="customFieldLabelTwo"
              value={orderPreference?.customFieldLabelTwo}
              onChange={e => {
                setOrderPreferences({
                  ...orderPreference,
                  customFieldLabelTwo: e.currentTarget.value
                })
              }}
            />
          </FormControl>
        </FlexGridItem>
      </FlexGrid>
      <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale800">
        <FlexGridItem>
          <Button onClick={saveChanges} isLoading={loading} size="compact">
            {t('Settings.OrderPreferences.Buttons.SaveButton.Title')}
          </Button>
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}

export default OrderPreferences
