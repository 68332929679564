import React, { useContext } from 'react'
import TimeFormatter from 'components/utils/time-formatter'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { CalendarEventProps } from 'components/ui/specific/EventTile'

import {
  CustomLabelsContext,
  INITIAL_CUSTOM_FIELD_NAMES
} from 'components/contexts/custom-labels-context'

import EventIcon from 'components/ui/specific/EventIcon'
import { Door } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import { useDockAssigmentContext } from '../../contexts/dock-assigment.context'
import { Button } from 'baseui/button'
import { CurrentUserContext } from '../../homepage/current-user-context'

export const UNASSIGN_VALUE = 'N/A'

const EventTooltip = ({ event }: CalendarEventProps) => {
  const {
    start,
    allDay,
    extendedProps: {
      dockName,
      confirmationId,
      appointmentTypeName,
      schedulerName,
      status,
      purchaseOrders,
      carrierScac,
      identifiers,
      dockTime
    }
  } = event
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  const { customLabels } = useContext(CustomLabelsContext)
  const label =
    customLabels.purchaseOrderIdentifiers || INITIAL_CUSTOM_FIELD_NAMES.purchaseOrderIdentifiers

  const dockAssignmentContext = useDockAssigmentContext()
  const { currentUser } = useContext(CurrentUserContext)
  const { actions: { setCompatibleDocks } = { setCompatibleDocks: undefined } } =
    dockAssignmentContext || {}

  const informationItemStyles = css({
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize'
  })

  return (
    <Block minWidth="250px" maxWidth="320px" position="relative">
      <div
        className={css({
          marginBottom: theme.sizing.scale300,
          display: 'flex',
          flexDirection: 'column',
          gap: theme.sizing.scale300,
          overflow: 'hidden'
        })}>
        <div
          className={css({
            fontSize: '1rem',
            display: 'flex',
            gap: theme.sizing.scale300,
            alignItems: 'center'
          })}>
          <EventIcon event={event} /> {schedulerName}
        </div>
        <div
          className={css({
            fontSize: theme.typography.LabelXSmall.fontSize
          })}>
          {new TimeFormatter('humanDate').format(start)}{' '}
          {!allDay && new TimeFormatter('localCalendarTime').format(start)}
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.sizing.scale100,
          fontSize: theme.typography.LabelXSmall.fontSize
        })}>
        <div className={informationItemStyles}>
          <small>{label}</small>
          <span>{purchaseOrders}</span>
        </div>
        {appointmentTypeName ? (
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between'
            })}>
            <small>{'Type of appointment '}</small>
            <span>{appointmentTypeName}</span>
          </div>
        ) : null}
        {status ? (
          <div className={informationItemStyles}>
            <small>{'Status '}</small>
            <span>{status}</span>
          </div>
        ) : null}
        {confirmationId ? (
          <div className={informationItemStyles}>
            <small>{'Confirmation Id '}</small>
            <span>{confirmationId}</span>
          </div>
        ) : null}
        {identifiers?.map(idenitfier => (
          <div className={informationItemStyles}>
            <small>{`${idenitfier.prompt}: `}</small>
            <span>{idenitfier.answer}</span>
          </div>
        ))}
        {carrierScac ? (
          <div className={informationItemStyles}>
            <small>{'SCAC'}</small>
            <span>{carrierScac}</span>
          </div>
        ) : null}
        {currentUser?.dockAssignmentActive ? (
          <div className={informationItemStyles}>
            <small>{'Dock Name'}</small>
            <span>{dockName || UNASSIGN_VALUE}</span>
          </div>
        ) : null}
        {currentUser?.dockAssignmentActive ? (
          <div className={informationItemStyles}>
            <small>{'Dock Time'}</small>
            <span>
              {(dockTime as any) !== -1
                ? new TimeFormatter('localCalendarTime').format(dockTime)
                : UNASSIGN_VALUE}
            </span>
          </div>
        ) : null}
        {setCompatibleDocks !== undefined ? (
          <div
            data-testid={'set-available-docks'}
            className={css({
              marginTop: theme.sizing.scale200,
              paddingLeft: theme.sizing.scale300,
              paddingRight: theme.sizing.scale300,
              paddingTop: theme.sizing.scale100,
              paddingBottom: theme.sizing.scale100,
              display: 'flex',
              alignItems: 'center',
              gap: theme.sizing.scale300,
              backgroundColor: '#E5EDFF',
              borderRadius: theme.borders.radius400,
              fontSize: '11px'
            })}>
            <Door size={14} />
            {t('DockAssignment.CalendarEvent.Tooltip.AssignDock')}
            <Button
              onClick={() => setCompatibleDocks(event)}
              kind="tertiary"
              size="mini"
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({ color: $theme.colors.accent, fontSize: '10px' })
                }
              }}>
              {t('DockAssignment.CalendarEvent.Tooltip.ViewDocks')}
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
    </Block>
  )
}

export default EventTooltip
