import { EquipmentTypesProvider } from 'components/contexts/equipment-types.context'
import React from 'react'
import { EquipmentTypeManage } from './EquipmentTypesManage'

export const EquipmentTypesMain = () => {
  return (
    <EquipmentTypesProvider>
      <EquipmentTypeManage />
    </EquipmentTypesProvider>
  )
}
