import { ButtonProps } from 'baseui/button'
import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/ui/generic/Button'

interface LinkButtonProps {
  href: string
  label: string
  positive?: boolean
  external?: boolean
  buttonProps?: ButtonProps
}

const LinkButton = (props: LinkButtonProps) => {
  const { href, label, positive, external, ...buttonProps } = props

  const button = (
    <Button
      overrides={
        positive && {
          BaseButton: {
            style: ({ $theme }) => {
              return {
                backgroundColor: $theme.colors.positive
              }
            }
          }
        }
      }
      {...buttonProps}>
      {label}
    </Button>
  )

  return external ? (
    <a href={href} style={{ textDecoration: 'none' }}>
      {button}
    </a>
  ) : (
    <Link to={href} style={{ textDecoration: 'none' }}>
      {button}
    </Link>
  )
}

export default LinkButton
