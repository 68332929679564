import React, { ReactElement, useContext } from 'react'
import { DATE_TYPE, DROPDOWN_LIST, TEXT_TYPE } from '../../models/Question'
import DateType from './DateType'
import DropdownListType from './DropdownListType'
import TextType from './TextType'
import { Answer, Question } from './Questions.types'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { SCHEDULER, SHIPPER } from '../../models/User'

interface QuestionsProps {
  question: Question
  answer?: Answer
  setAnswer: (answer: Answer) => void
  disabled?: boolean
  displayError?: boolean
  label?: string
}

const Questions = ({
  question,
  answer,
  setAnswer,
  disabled,
  displayError = false,
  label
}: QuestionsProps): ReactElement => {
  const { id, answerType } = question
  const { currentUser } = useContext(CurrentUserContext)
  const permission = question.questionPermissionsAttributes.filter(
    qpa => qpa.userType === (currentUser?.userType || SCHEDULER)
  )[0]

  const isRequired = () => {
    if (permission) {
      return permission.required
    }

    if (currentUser?.userType === SHIPPER) {
      return false
    }

    return true
  }

  const getValueDropdown = () => {
    const response = answer?.response || question?.response
    return (
      (question?.answerList as any)?.filter(
        question => question == response || question.value == response
      ) || []
    )
  }

  return (
    <>
      {answerType === DATE_TYPE && (
        <DateType
          value={answer?.response || question?.response}
          disabled={disabled}
          label={label}
          onChange={({ date }) =>
            setAnswer({
              questionId: id,
              response: date as any
            })
          }
        />
      )}
      {answerType === TEXT_TYPE && (
        <TextType
          prompt={question.prompt}
          value={answer?.response || question?.response}
          disabled={disabled}
          label={label}
          onChange={e => {
            setAnswer({
              questionId: id,
              response: e.currentTarget.value,
              error:
                isRequired() && e.currentTarget.value.length === 0
                  ? false
                  : (question?.minLength
                      ? question?.minLength > e.currentTarget.value.length
                      : false) ||
                    (question?.maxLength
                      ? question?.maxLength < e.currentTarget.value.length
                      : false) ||
                    (question?.numericOnly ? !/^[0-9]+$/.test(e.currentTarget.value) : false)
            })
          }}
          error={
            displayError
              ? (question?.minLength ? question?.minLength > answer?.response?.length : false) ||
                (question?.maxLength ? question?.maxLength < answer?.response?.length : false) ||
                (question?.numericOnly ? !/^[0-9]+$/.test(answer?.response) : false)
              : undefined
          }
        />
      )}
      {answerType === DROPDOWN_LIST && (
        <DropdownListType
          options={question?.answerList as any}
          label={label}
          disabled={disabled}
          onChange={e => {
            setAnswer({ questionId: id, response: e.option?.value })
          }}
          value={getValueDropdown()}
        />
      )}
    </>
  )
}

export default Questions
