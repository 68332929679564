import i18n from 'translations/i18n'

const getOrderFieldMap = () => ({
  number: i18n.t('Orders.CreateEditOrderModal.Fields.OrderNumber.Label.Text'),
  facility: i18n.t('Orders.CreateEditOrderModal.Fields.Facility.Label.Text'),
  status: i18n.t('Orders.CreateEditOrderModal.Fields.Status.Label.Text'),
  start_date_period: i18n.t(
    'Orders.CreateEditOrderModal.Fields.RequestedAppointmentPeriodStart.Label.Text'
  ),
  end_date_period: i18n.t(
    'Orders.CreateEditOrderModal.Fields.RequestedAppointmentPeriodEnd.Label.Text'
  ),
  order_type: i18n.t('Orders.CreateEditOrderModal.Fields.OrderType.Label.Text'),
  priority: i18n.t('Orders.CreateEditOrderModal.Fields.Priority.Label.Text'),
  customer: i18n.t('Orders.CreateEditOrderModal.Fields.Customer.Label.Text'),
  customer_po: i18n.t('Orders.CreateEditOrderModal.Fields.CustomerPO.Label.Text'),
  load_id: i18n.t('Orders.CreateEditOrderModal.Fields.LoadId.Label.Text'),
  products: i18n.t('Orders.CreateEditOrderModal.Fields.Products.Label.Text'),
  quantity: i18n.t('Orders.CreateEditOrderModal.Fields.Quantity.Label.Text'),
  weight: i18n.t('Orders.CreateEditOrderModal.Fields.Weight.Label.Text'),
  quantity_uom: i18n.t('Orders.CreateEditOrderModal.Fields.UnitOfMeasure.Label.Text'),
  weight_uom: i18n.t('Orders.CreateEditOrderModal.Fields.WeightUnitOfMeasure.Label.Text'),
  carrier: i18n.t('Orders.CreateEditOrderModal.Fields.SCACcode.Label.Text'),
  delivery_instructions: i18n.t(
    'Orders.CreateEditOrderModal.Fields.DeliveryInstructions.Label.Text'
  ),
  custom_field_one: i18n.t('Settings.OrderPreferences.Form.Fields.CustomFieldLabelOne.Label.Text'),
  custom_field_two: i18n.t('Settings.OrderPreferences.Form.Fields.CustomFieldLabelTwo.Label.Text')
})

export default getOrderFieldMap
