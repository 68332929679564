import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'
import { useCarrierUserContext } from '../../contexts/carrier-user.context'

const InviteCarrierUserModalFooter = ({ refine }) => {
  const {
    users: { loading, selectedUser: user },
    actions: { inviteUser }
  } = useCarrierUserContext()

  const { t } = useTranslation()

  const onSubmit = async () => {
    await inviteUser()
    refine()
  }
  return (
    <Button
      data-testid="invite-carrier-user-button"
      disabled={!user?.emailAddress || !user?.name || !user?.carrierId}
      isLoading={loading}
      onClick={onSubmit}>
      {t('Settings.CarrierUsers.InviteCarrierUserModal.InviteButton.Text')}
    </Button>
  )
}

export default InviteCarrierUserModalFooter
