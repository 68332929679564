import React from "react";
import { Route, Switch } from "react-router-dom";
import InsightsIndex from "./insights-index";

const InsightsRoutes = ({ match }) => (
  <>
    <Switch>
      <Route exact path={match.url} component={InsightsIndex} />
    </Switch>
  </>
);

export default InsightsRoutes;
