import React, { useContext, useMemo } from 'react'
import { Route } from 'react-router-dom'
import ShipperForm from '../shipper/shipper-form'
import UserProfile from '../users/user-profile'
import { SchedulerUserInstantSearch } from 'components/schedulers/SchedulerUserInstantSearch'
import { ShipperUserInstantSearch } from 'components/shipper/ShipperUserInstantSearch'
import { SchedulerInstantSearch } from 'components/schedulers/SchedulerInstantSearch'
import { Block } from 'baseui/block'
import { useLocation } from 'react-router-dom'
import { Navigation } from 'baseui/side-navigation'
import { MetabaseAnalyticsSettings } from 'components/pages/MetabaseAnalyticsSettings'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { EquipmentTypesMain } from 'components/pages/EquipmentTypes'
import UserRolesPages from 'components/pages/UserRoles'
import Carriers from 'components/pages/Carriers/Carriers'
import CarrierUserInstantSearch from 'components/pages/CarriersUsers/CarrierUserInstantSearch'
import { useTranslation } from 'react-i18next'
import OrderPreferences from 'components/pages/OrderPreferences/index'
import { LocaleContext } from 'components/contexts/locale-context'
import AppointmentTypeRoutes from 'components/appointment-types/appointment-type-routes'
import { CustomCancelReasons } from '../pages/CustomCancelReasons'

export const SettingsRoutes = ({ history }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const { locale } = useContext(LocaleContext)
  const { currentUser } = useContext(CurrentUserContext)

  const sidebarItems = useMemo(() => {
    const items = [
      {
        title: t('Settings.SideMenu.Titles.ShipperProfile.Text'),
        itemId: '/settings/shipper'
      },
      {
        title: t('Settings.SideMenu.Titles.UserRoles.Text'),
        itemId: '/settings/user_roles'
      },
      {
        title: t('Settings.SideMenu.Titles.InternalUsers.Text'),
        itemId: '/settings/internal_users'
      },
      {
        title: t('Settings.SideMenu.Titles.VendorCompanies.Text'),
        itemId: '/settings/schedulers'
      },
      {
        title: t('Settings.SideMenu.Titles.VendorCompanyUsers.Text'),
        itemId: '/settings/scheduler_users'
      },
      {
        title: t('Settings.SideMenu.Titles.Carriers.Text'),
        itemId: '/settings/carriers'
      },
      {
        title: t('Settings.SideMenu.Titles.CarrierUsers.Text'),
        itemId: '/settings/carrier_users'
      },
      {
        title: t('Settings.SideMenu.Titles.UserProfile.Text'),
        itemId: '/settings/user'
      },
      {
        title: t('Settings.SideMenu.Titles.EquipmentTypes.Text'),
        itemId: '/settings/equipment_types'
      },
      {
        title: t('Settings.SideMenu.Titles.CustomCancelReasons.Text'),
        itemId: '/settings/custom_cancel_reasons'
      },
      {
        title: t('Settings.SideMenu.Titles.AppointmentTypes.Text'),
        itemId: '/settings/appointment_types'
      },
      {
        title: t('Settings.SideMenu.Titles.OrderPreferences.Text'),
        itemId: '/settings/orders'
      }
    ]

    if (currentUser?.admin) {
      items.push({
        title: t('Settings.SideMenu.Titles.Analytics.Text'),
        itemId: '/settings/setup_metabase'
      })
    }

    return items
  }, [currentUser?.admin, locale])

  const pathArray = location.pathname.split('/')
  const secondLevelLocation = pathArray[1]
  const thirdLevelLocation = pathArray[2]

  return (
    <Block
      display="flex"
      gridGap="scale800"
      flexDirection={['column-reverse', 'column-reverse', 'row']}>
      <Block width={['100%', '100%', '230px']} padding="104px 0">
        <Navigation
          items={sidebarItems}
          activeItemId={`/${secondLevelLocation}/${thirdLevelLocation}`}
          onChange={({ event, item }) => {
            event.preventDefault()
            history.push(item.itemId)
          }}
          overrides={{
            NavItem: {
              style: ({ $active, $theme }) => ({
                fontSize: $theme.typography.LabelXSmall.fontSize,
                backgroundImage: 'none',
                backgroundColor: $active
                  ? $theme.colors.backgroundSecondary
                  : $theme.colors.backgroundPrimary,
                borderLeftColor: $active ? $theme.colors.accent : $theme.colors.transparent,
                color: $active ? $theme.colors.accent : '',
                borderRadius: $theme.borders.radius100
              })
            }
          }}
        />
      </Block>
      <Block flex="1">
        <Route exact path="/settings/shipper" component={ShipperForm} />
        <Route exact path="/settings/internal_users" component={ShipperUserInstantSearch} />
        <Route exact path="/settings/scheduler_users" component={SchedulerUserInstantSearch} />
        <Route exact path="/settings/schedulers" component={SchedulerInstantSearch} />
        <Route exact path="/settings/user" component={UserProfile} />
        <Route exact path="/settings/setup_metabase" component={MetabaseAnalyticsSettings} />
        <Route exact path="/settings/equipment_types" component={EquipmentTypesMain} />
        <Route exact path="/settings/custom_cancel_reasons" component={CustomCancelReasons} />
        <Route exact path="/settings/user_roles" component={UserRolesPages} />
        <Route exact path="/settings/orders" component={OrderPreferences} />
        <Route exact path="/settings/carriers" component={Carriers} />
        <Route exact path="/settings/carrier_users" component={CarrierUserInstantSearch} />
        <Route path="/settings/appointment_types" component={AppointmentTypeRoutes} />
      </Block>
    </Block>
  )
}
