import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import moment from 'moment'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import ErrorMessageButton from 'components/shared/error-message-button'
import { fancyToast } from 'components/utils'
import CyberInput from 'cyber/input'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { DockBlock } from 'components/models/DockBlock'
import { dockBlockService } from 'components/services/dock-blocks.service'
import DeleteButton from 'components/shared/fields/delete-button'
import { BlockCounts } from 'components/models/BlockCounts'
import combineDateAndTime from 'components/utils/combine-date-and-time'
import extractFromDate from 'components/utils/extract-from-date'
import { HH_MM_SS, YYYY_MM_DD } from 'components/utils/time-formats'
import Select from 'components/ui/generic/Select'
import FormControl from 'components/ui/generic/FormControl'
import Checkbox from 'components/ui/generic/Checkbox'
import DaysOfWeek from './DaysOfWeek'
import { FacilityOption } from '../../hooks/use-default-facilities'
import { CurrentUserContext } from '../../../../homepage/current-user-context'
import StyledSpinner from '../../../../shared/styled-spinner'
import {
  CancelButtonStyled,
  DatepickerStyled,
  TimePickerStyled,
  FlexRow
} from './BlackoutModal.styled'
import MemoizedSelectBlockCounts from './SelectBlockCounts'
import { FacilitiesContext } from '../../context/facilities-context'

interface BlockCountsWithExtraAttributes extends BlockCounts {
  alreadyCreated?: boolean
}
export interface DockBlockWithExtraAttributes extends DockBlock {
  blockCountsNotSharedHelper?: Record<string, unknown>
  blockCountsAttributes: BlockCountsWithExtraAttributes[]
}

const UnconnectedCreateBlackoutModal = ({
  selectedFacilities,
  selectedEvent,
  isOpen,
  close
}: ModalProps & { items: { attribute: string; currentRefinement: any }[] } & {
  selectedFacilities: FacilityOption[]
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [facilityOption, setFacilityOption] = useState(undefined)
  const { assignedEquipmentTypes } = useContext(CurrentUserContext)
  const [dockBlockReason, setDockBlockReason] = useState(undefined)
  const { selectedDate, facilities } = useContext(FacilitiesContext)
  const [dockBlock, setDockBlock] = useState<DockBlockWithExtraAttributes>({
    startTime: new Date(moment(selectedDate).startOf('day').toString()),
    endTime: new Date(moment(selectedDate).endOf('day').toString()),
    blockCountsAttributes: []
  })

  useEffect(() => {
    if (selectedEvent.id) {
      setLoading(true)
      dockBlockService.getDockBlock(selectedEvent.id).then(([dockBlockResponse, status]) => {
        if (status === 200) {
          const startTime = combineDateAndTime(
            dockBlockResponse.startDate,
            dockBlockResponse.startTime
          )
          const endTime = combineDateAndTime(dockBlockResponse.endDate, dockBlockResponse.endTime)
          const createdAsShared = !dockBlockResponse.blockCountsAttributes[0].appointmentTypeId
          if (createdAsShared) {
            const blockCountsAttributes = []
            assignedEquipmentTypes.forEach(eqTypes => {
              const dockBlockSearched = dockBlockResponse.blockCountsAttributes.filter(
                blockCount => blockCount.equipmentTypeId === eqTypes.id
              )[0]
              blockCountsAttributes.push(
                dockBlockSearched
                  ? {
                      ...dockBlockSearched,
                      count: Number(dockBlockSearched.count),
                      alreadyCreated: true
                    }
                  : {
                      count: 0,
                      equipmentTypeId: eqTypes.id,
                      appointmentTypeId: null
                    }
              )
            })
            setDockBlockReason(dockBlockResponse.name)
            setDockBlock({
              ...dockBlockResponse,
              startTime,
              endTime,
              blockCountsAttributes
            })
            setLoading(false)
          } else {
            const blockCountsNotSharedHelper = {}
            dockBlockResponse.facility.appointmentTypes.forEach(appType => {
              blockCountsNotSharedHelper[appType.id] = {
                name: appType.name
              }
              assignedEquipmentTypes.forEach(eqType => {
                const dockBlockSearched = dockBlockResponse.blockCountsAttributes.filter(
                  blockCount =>
                    blockCount.equipmentTypeId === eqType.id &&
                    blockCount.appointmentTypeId === appType.id
                )[0]
                blockCountsNotSharedHelper[appType.id][eqType.id] = dockBlockSearched
                  ? {
                      ...dockBlockSearched,
                      count: Number(dockBlockSearched.count),
                      alreadyCreated: true,
                      limit:
                        dockBlockResponse?.facility?.appointmentPreference?.dockCapacitiesAttributes?.filter(
                          capacity =>
                            capacity.appointmentTypeId === appType.id &&
                            capacity.equipmentTypeId === eqType.id
                        )[0]?.limit || 0
                    }
                  : {
                      count: 0,
                      equipmentTypeId: eqType.id,
                      appointmentTypeId: appType.id,
                      limit:
                        dockBlockResponse?.facility?.appointmentPreference?.dockCapacitiesAttributes?.filter(
                          capacity =>
                            capacity.appointmentTypeId === appType.id &&
                            capacity.equipmentTypeId === eqType.id
                        )[0]?.limit || 0
                    }
              })
            })
            setDockBlockReason(dockBlockResponse.name)
            setDockBlock({
              ...dockBlockResponse,
              startTime,
              endTime,
              blockCountsNotSharedHelper
            })
            setLoading(false)
          }
        }
      })
    }
  }, [selectedEvent])

  useEffect(() => {
    const { startTime, endTime, schedule } = dockBlock
    if (schedule) {
      setDockBlock({
        ...dockBlock,
        schedule: {
          ...schedule,
          start_time: startTime,
          rrules: [
            {
              ...dockBlock.schedule.rrules[0],
              until: moment(endTime).add(1, 'd').format()
            }
          ]
        }
      })
    }
  }, [dockBlock.startTime, dockBlock.endTime])

  useEffect(() => {
    if (selectedFacilities?.length === 1 && !selectedEvent?.id) {
      setFacilityOption(selectedFacilities[0])
      setDockBlock({
        ...dockBlock,
        facilityId: selectedFacilities[0].id
      })
    }
  }, [selectedFacilities])

  const createFormErrors = (): { label: string; status: boolean }[] => {
    return [
      {
        label: 'Reason must be present.',
        status: !!dockBlockReason && dockBlockReason?.trim().length > 0
      },
      { label: 'Facility must be selected.', status: !!facilityOption },
      { label: 'Start Date/Time must be provided', status: !!dockBlock.startTime },
      { label: 'End Date/Time must be provided', status: !!dockBlock.endTime },
      {
        label: 'Start Date/Time must be before End Date/Time.',
        status: dockBlock.startTime < dockBlock.endTime
      }
    ]
  }

  const updateFormErrors = (): { label: string; status: boolean }[] => {
    return [
      {
        label: 'Reason must be present.',
        status: !!dockBlockReason && dockBlockReason?.trim().length > 0
      },
      { label: 'Start Date/Time must be provided', status: !!dockBlock.startTime },
      { label: 'End Date/Time must be provided', status: !!dockBlock.endTime },
      {
        label: 'Start Date/Time must be before End Date/Time.',
        status: dockBlock.startTime < dockBlock.endTime
      }
    ]
  }

  const createBlock = async () => {
    setLoading(true)
    let blockCountsAttributes = []

    if (dockBlock.blockCountsNotSharedHelper) {
      Object.keys(dockBlock.blockCountsNotSharedHelper).forEach(appTypeId =>
        Object.keys(dockBlock.blockCountsNotSharedHelper[appTypeId]).forEach(eqTypeId => {
          if (
            eqTypeId !== 'name' &&
            dockBlock.blockCountsNotSharedHelper[appTypeId][eqTypeId].count > 0
          ) {
            blockCountsAttributes.push({
              ...dockBlock.blockCountsNotSharedHelper[appTypeId][eqTypeId]
            })
          }
        })
      )
    } else {
      blockCountsAttributes = dockBlock.blockCountsAttributes.filter(
        blockCount => blockCount.count > 0
      )
    }

    const allCountsAreZero = blockCountsAttributes.filter(value => value.count !== 0)[0]

    if (!allCountsAreZero) {
      fancyToast({ info: 'At least one slot must be selected.' }, 500)
      setLoading(false)
      return
    }

    dockBlockService
      .createDockBlock({
        ...dockBlock,
        startDate: extractFromDate(dockBlock.startTime, YYYY_MM_DD),
        startTime: extractFromDate(dockBlock.startTime, HH_MM_SS),
        endDate: extractFromDate(dockBlock.endTime, YYYY_MM_DD),
        endTime: extractFromDate(dockBlock.endTime, HH_MM_SS),
        blockCountsAttributes,
        name: dockBlockReason
      })
      .then(([result, status]) => {
        if (status === 201) {
          fancyToast({ info: 'Blackout was successfully created.' }, status)
        } else {
          fancyToast(result, status)
        }
        setLoading(false)
        close()
      })
  }

  const updateBlock = async () => {
    setLoading(true)
    let blockCountsAttributes = []

    if (dockBlock.blockCountsNotSharedHelper) {
      Object.keys(dockBlock.blockCountsNotSharedHelper).forEach(appTypeId =>
        Object.keys(dockBlock.blockCountsNotSharedHelper[appTypeId]).forEach(eqTypeId => {
          const eqType = dockBlock.blockCountsNotSharedHelper[appTypeId][eqTypeId]
          if (eqTypeId !== 'name' && (eqType.alreadyCreated || eqType.count > 0)) {
            blockCountsAttributes.push(eqType)
          }
        })
      )
    } else {
      blockCountsAttributes = dockBlock.blockCountsAttributes.filter(
        blockCount => blockCount.alreadyCreated || blockCount.count > 0
      )
    }

    const allCountsAreZero = blockCountsAttributes.filter(value => value.count !== 0)[0]

    if (!allCountsAreZero) {
      fancyToast({ info: 'At least one slot must be selected.' }, 500)
      setLoading(false)
      return
    }

    dockBlockService
      .updateDockBlock({
        ...dockBlock,
        startDate: extractFromDate(dockBlock.startTime, YYYY_MM_DD),
        startTime: extractFromDate(dockBlock.startTime, HH_MM_SS),
        endDate: extractFromDate(dockBlock.endTime, YYYY_MM_DD),
        endTime: extractFromDate(dockBlock.endTime, HH_MM_SS),
        blockCountsAttributes,
        name: dockBlockReason
      })
      .then(([json, status]) => {
        if (status === 200) {
          fancyToast({ info: 'Blackout was successfully updated' }, status)
          close()
        } else {
          fancyToast(json, status)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteBlock = async () => {
    setLoading(true)
    dockBlockService.deleteDockBlock(dockBlock.id).then(([result, status]) => {
      if (status === 204) {
        close()
        fancyToast({ info: 'Blackout was successfully deleted.' }, status)
      } else {
        fancyToast(result, status)
      }
    })
    setLoading(false)
  }

  return (
    <Modal
      unstable_ModalBackdropScroll
      onClose={close}
      isOpen={isOpen}
      closeable
      animate
      size="auto"
      autoFocus={false}
      overrides={{
        Dialog: { style: { width: '100%', maxWidth: '1230px' } },
        Close: {
          style: ({ $theme }) => ({
            right: $theme.sizing.scale800,
            top: $theme.sizing.scale800
          })
        }
      }}>
      <ModalHeader>{selectedEvent.id ? 'Update Blackout' : 'Create Blackouts'}</ModalHeader>
      <ModalBody>
        {loading ? (
          <StyledSpinner />
        ) : (
          <>
            <FlexGrid
              flexGridColumnCount={[1, 2]}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale200">
              <FlexGridItem marginBottom="scale600">
                <FormControl
                  label="Reason"
                  caption="Why are you blocking this time off? This will appear on your calendar.">
                  <CyberInput
                    value={dockBlockReason}
                    name="dock_block[reason]"
                    onChange={e => setDockBlockReason(e.currentTarget.value)}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                {!selectedEvent.id && (
                  <FormControl label="Facility">
                    <Select
                      id="facility-select"
                      onChange={({ value }) => {
                        setFacilityOption(value[0])
                        setDockBlock({
                          ...dockBlock,
                          facilityId: value[0].id as any
                        })
                      }}
                      value={facilityOption}
                      options={selectedFacilities}
                      labelKey="name"
                      placeholder=""
                    />
                  </FormControl>
                )}
              </FlexGridItem>
            </FlexGrid>
            {(facilityOption || selectedEvent?.id) && (
              <MemoizedSelectBlockCounts
                {...{ facilityOption, dockBlock, setDockBlock, selectedEvent }}
              />
            )}
            <FlexGrid
              flexGridColumnCount={2}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale200">
              <FlexGridItem>
                <FormControl label="Start Date">
                  <DatepickerStyled
                    value={dockBlock?.startTime || undefined}
                    formatString="MM/dd/yyyy"
                    onChange={({ date }) => {
                      setDockBlock({
                        ...dockBlock,
                        startTime: date as Date
                      })
                    }}
                    maxDate={dockBlock?.endTime}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  label="Start Time"
                  caption={`Time displayed in the facility's time zone ${facilities[
                    dockBlock.facilityId
                  ]?.timeZone.toUpperCase()}`}>
                  <TimePickerStyled
                    step={15 * 60}
                    value={dockBlock?.startTime || undefined}
                    disabled={dockBlock.allDay}
                    onChange={date => {
                      setDockBlock({
                        ...dockBlock,
                        startTime: date as Date
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl label="End Date">
                  <DatepickerStyled
                    value={dockBlock?.endTime || undefined}
                    formatString="MM/dd/yyyy"
                    onChange={({ date }) => {
                      setDockBlock({
                        ...dockBlock,
                        endTime: date as Date
                      })
                    }}
                    minDate={dockBlock?.startTime}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  label="End Time"
                  caption={`Time displayed in the facility's time zone ${facilities[
                    dockBlock.facilityId
                  ]?.timeZone.toUpperCase()}`}>
                  <TimePickerStyled
                    step={15 * 60}
                    value={dockBlock?.endTime || null}
                    disabled={dockBlock.allDay}
                    onChange={date => {
                      setDockBlock({
                        ...dockBlock,
                        endTime: date as Date
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
            </FlexGrid>
            <FlexGridItem>
              <Checkbox
                checked={dockBlock.allDay}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.currentTarget.checked) {
                    setDockBlock({
                      ...dockBlock,
                      startTime: dockBlock.startTime
                        ? new Date(moment(dockBlock.startTime).startOf('day').toString())
                        : new Date(),
                      endTime: dockBlock.endTime
                        ? new Date(moment(dockBlock.endTime).endOf('day').toString())
                        : new Date(),
                      allDay: true
                    })
                  } else {
                    setDockBlock({
                      ...dockBlock,
                      allDay: false
                    })
                  }
                }}
                label="All Day"
              />
            </FlexGridItem>
            <FlexGridItem>
              <Checkbox
                checked={dockBlock?.daysOfWeek}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setDockBlock({
                    ...dockBlock,
                    daysOfWeek: e.currentTarget.checked,
                    schedule: e.currentTarget.checked ? dockBlock.schedule : undefined
                  })
                }
                label="Days of Week"
              />
            </FlexGridItem>
            {dockBlock.daysOfWeek && <DaysOfWeek record={dockBlock} setRecord={setDockBlock} />}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {!loading && (
          <FlexRow>
            {selectedEvent.id ? (
              <DeleteButton onDelete={deleteBlock} />
            ) : (
              <CancelButtonStyled onClick={close} isLoading={loading}>
                Cancel
              </CancelButtonStyled>
            )}
            <ErrorMessageButton
              label={selectedEvent.id ? 'Update' : 'Create'}
              errors={selectedEvent.id ? updateFormErrors() : createFormErrors()}
              placement="left"
              buttonProps={{
                onClick: selectedEvent.id ? updateBlock : createBlock,
                type: 'submit'
              }}
            />
          </FlexRow>
        )}
      </ModalFooter>
    </Modal>
  )
}

const CreateBlackoutModal = connectCurrentRefinements(UnconnectedCreateBlackoutModal as any)

export default CreateBlackoutModal
