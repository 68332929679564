import React from "react";
import { Route } from "react-router-dom";
import SchedulerShow from "./show";

const OldSchedulerRoutes = ({ match }) => (
  <>
    <Route path={`${match.url}/:handle`} exact component={SchedulerShow} />
  </>
);

export default OldSchedulerRoutes;
