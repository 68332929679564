import { FormControl } from 'baseui/form-control'
import React, { useState, useEffect, useContext } from 'react'
import { Select } from 'baseui/select'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { SHIPPER } from 'components/models/User'

interface FacilitySelectProps {
  record: any
  setRecord: Function
  setDock?: any
  setDockInput?: Function
}

const FacilitySelect = (props: FacilitySelectProps) => {
  const { record, setRecord } = props
  const { currentUser } = useContext(CurrentUserContext)

  const [selectedFacility, setSelectedFacility] = useState<any>({})
  const [facilityLabels, setFacilityLabels] = useState([])

  useEffect(() => {
    const selectedFacility = facilityLabels.find(facility => facility.id === record.facilityId)
    if (selectedFacility) {
      setSelectedFacility([selectedFacility])
      setRecord({ ...record, facilityId: selectedFacility.id })
    }
  }, [facilityLabels, record.id])

  useEffect(() => {
    currentUser?.shipperId &&
      currentUser.userType === SHIPPER &&
      authenticatedFetch({
        path: `/shippers/${currentUser.shipperId}/facilities_list.json`
      })
        .then(([facilityLabels, _status]) => {
          setFacilityLabels(facilityLabels)
        })
        .catch(console.log)
  }, [])

  return (
    <FormControl label="Facility">
      <Select
        options={facilityLabels}
        value={Array.isArray(selectedFacility) ? selectedFacility : [selectedFacility]}
        searchable={false}
        clearable={false}
        onChange={params => {
          setSelectedFacility(params.value)
          setRecord({
            ...record,
            facilityId: params.option.id
          })
        }}
      />
    </FormControl>
  )
}

export default FacilitySelect
