import { Modal, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import React from 'react'
import { KIND as ButtonKind } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { useDockAssigmentContext } from 'components/contexts/dock-assigment.context'

const DockAssignmentConfirmationModal = () => {
  const {
    confirmationModal,
    actions: { confirmConfirmationModal, closeConfirmationModal }
  } = useDockAssigmentContext()
  const { t } = useTranslation()

  return (
    <Modal
      onClose={closeConfirmationModal}
      closeable={false}
      isOpen={confirmationModal}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      unstable_ModalBackdropScroll>
      <ModalHeader>{t('DockAssignment.ConfirmDialog.Header.Text')}</ModalHeader>
      <ModalFooter>
        <ModalButton kind={ButtonKind.tertiary} onClick={closeConfirmationModal}>
          {t('DockAssignment.ConfirmDialog.CancelButton.Text')}
        </ModalButton>
        <ModalButton onClick={confirmConfirmationModal}>
          {t('DockAssignment.ConfirmDialog.ConfirmButton.Text')}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default DockAssignmentConfirmationModal
