import React, { createContext, useContext, useReducer } from 'react'
import { fancyToast } from 'components/utils'
import carrierUsersReducer, {
  CREATE_CARRIER_USER,
  UPDATE_STATUS_CARRIER_USER,
  SELECT_CARRIER_USER,
  SET_CARRIER_USER_TO_UPDATE,
  SET_EDIT_MODAL_STATE,
  SET_EDIT_MODAL_STATE_AND_CLEAR_SELECTED_USER,
  SET_INVITE_MODAL_STATE,
  SET_LOADING,
  UPDATE_CARRIER_USER,
  UPDATE_SELECTED_USER,
  UPDATE_SELECTED_USER_AND_DISABLE_FIELDS,
  DELETE_CARRIER_USER
} from 'components/reducers/carrier-user.reducer'
import { User } from 'components/users'
import { inviteService } from 'components/services/invite.service'
import { CARRIER } from 'components/models/User'
import { StatusCodes } from 'components/constants/http-status-codes'
import i18n from 'translations/i18n'

interface CarrierUsersContextProps {
  users: {
    searchUser?: User
    selectedUser?: Partial<User>
    isEditModalActive: boolean
    isInviteModalActive: boolean
    loading: boolean
    saving: boolean
    userToUpdate?: User
    disableCarrierUserModalFormFields: boolean
  }
}

const initialState: CarrierUsersContextProps = {
  users: {
    loading: false,
    saving: false,
    selectedUser: null,
    userToUpdate: undefined,
    isEditModalActive: false,
    isInviteModalActive: false,
    disableCarrierUserModalFormFields: false
  }
}

export const CarrierUsersContext = createContext({} as any)

export const CarrierUsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(carrierUsersReducer, initialState)

  const setSelectedUserAndDisableFields = (value: {
    user?: Partial<User>
    disableFields: boolean
  }) => {
    dispatch({ type: UPDATE_SELECTED_USER_AND_DISABLE_FIELDS, payload: value })
  }

  const setSelectedUser = (value: Partial<User>) => {
    dispatch({ type: UPDATE_SELECTED_USER, payload: value })
  }

  const setInviteModalState = value => {
    dispatch({ type: SET_INVITE_MODAL_STATE, payload: value })
  }

  const setEditModalState = value => {
    dispatch({ type: SET_EDIT_MODAL_STATE, payload: value })
  }

  const setLoading = async value => {
    dispatch({ type: SET_LOADING, payload: value })
  }

  const createCarrierUser = async (user: User) => {
    setLoading(true)

    const [data, status] = await inviteService.inviteCarrierUser({
      ...user,
      userType: CARRIER
    })

    if (status >= 200) {
      fancyToast({ info: i18n.t('Settings.CarrierUsers.Alerts.UserInvited.Text') }, 200)
      dispatch({
        type: CREATE_CARRIER_USER,
        payload: data
      })
    } else {
      return fancyToast(
        { info: data?.message || i18n.t('Settings.CarrierUsers.Alerts.SomethingWentWrong.Text') },
        412
      )
    }

    setLoading(false)
  }

  const updateCarrierUser = async (user: User, refine?: () => void) => {
    setLoading(true)

    const [data, status] = await inviteService.updateCarrierUser({
      ...user
    })
    if (status < StatusCodes.BAD_REQUEST) {
      fancyToast({ info: i18n.t('Settings.CarrierUsers.Alerts.UserUpdated.Text') }, 200)
      dispatch({ type: UPDATE_CARRIER_USER, payload: data })
      if (refine) {
        refine()
      }
    } else {
      fancyToast(data, status)
    }
    setLoading(false)
  }

  const searchByCarrierUser = async () => {
    const user = state.users.selectedUser
    const [data] = await inviteService.getCarrierUserByEmail(user.emailAddress)

    if (user.emailAddress && data?.length > 0) {
      setSelectedUserAndDisableFields({
        user: {
          ...user,
          name: data[0].name,
          phone_number: data[0].phoneNumber,
          carrierId: data[0].carrier.id
        },
        disableFields: true
      })
    } else {
      setSelectedUserAndDisableFields({
        user: {
          ...user
        },
        disableFields: false
      })
    }
  }

  const setCarrierUserToUpdate = async (userId: string) => {
    setLoading(true)
    const [data, status] = await inviteService.getCarrierUserById(userId)
    if (status >= 200) {
      dispatch({
        type: SET_CARRIER_USER_TO_UPDATE,
        payload: { user: data, isEditModalActive: true }
      })
    } else {
      return fancyToast(
        { info: data?.message || i18n.t('Settings.CarrierUsers.Alerts.SomethingWentWrong.Text') },
        412
      )
    }
    setLoading(false)
  }

  const selectedCarrierUser = (user: User) => {
    dispatch({ type: SELECT_CARRIER_USER, payload: user })
  }

  const revokeAccessCarrierUser = async (user: User, disabled: boolean) => {
    setLoading(true)

    const [data, status] = await inviteService.updateStatusCarrierUser({
      ...user,
      disabled
    })
    if (status >= 200) {
      fancyToast(
        {
          info: disabled
            ? i18n.t('Settings.CarrierUsers.Alerts.UserAccessRevoked.Text')
            : i18n.t('Settings.CarrierUsers.Alerts.UserAccessGranted.Text')
        },
        200
      )
      dispatch({ type: UPDATE_STATUS_CARRIER_USER, payload: data })
    } else {
      return fancyToast(
        { info: data?.message || i18n.t('Settings.CarrierUsers.Alerts.SomethingWentWrong.Text') },
        412
      )
    }
    setLoading(false)
  }

  const removeCarrierUser = async (user: User) => {
    setLoading(true)

    const [data, status] = await inviteService.deleteCarrierUser({
      ...user
    })
    if (status >= 200) {
      fancyToast(
        {
          info: i18n.t('Settings.CarrierUsers.Alerts.UserAccessRemoved.Text')
        },
        200
      )
      dispatch({ type: DELETE_CARRIER_USER, payload: data })
    } else {
      return fancyToast(
        { info: data?.message || i18n.t('Settings.CarrierUsers.Alerts.SomethingWentWrong.Text') },
        412
      )
    }
    setLoading(false)
  }

  const inviteUser = async () => {
    setLoading(true)
    const [data, status] = await inviteService.inviteCarrierUser({
      ...state.users.selectedUser,
      userType: CARRIER
    })
    setLoading(false)
    if (status === 201) {
      fancyToast({ info: i18n.t('Settings.CarrierUsers.Alerts.UserInvited.Text') }, 200)
      closeCarrierUserModal()
    } else {
      fancyToast(data, status)
    }
  }

  const clearUserToUpdate = () => {
    dispatch({ type: SET_EDIT_MODAL_STATE_AND_CLEAR_SELECTED_USER })
  }

  const closeCarrierUserModal = () => {
    setSelectedUserAndDisableFields({
      user: {},
      disableFields: false
    })
    setInviteModalState(false)
  }

  const actions = {
    setInviteModalState,
    setEditModalState,
    clearUserToUpdate,
    createCarrierUser,
    updateCarrierUser,
    searchByCarrierUser,
    selectedCarrierUser,
    setCarrierUserToUpdate,
    revokeAccessCarrierUser,
    inviteUser,
    setSelectedUserAndDisableFields,
    setSelectedUser,
    closeCarrierUserModal,
    removeCarrierUser
  }

  return (
    <CarrierUsersContext.Provider value={{ ...state, actions }}>
      {children}
    </CarrierUsersContext.Provider>
  )
}

export const useCarrierUserContext = () => useContext(CarrierUsersContext)
