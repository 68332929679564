import { useStyletron } from 'baseui'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { isMyQInstance } from '../utils/is-myQ'
import { MYQ_LOGO } from '../constants/myQ-logo'

const logoFile = require('../../../assets/images/velostics-logo.svg') as string

const Logo = ({ ids }) => {
  const [css] = useStyletron()
  const [location, setLocation] = useState(null)
  const [logo, setLogo] = useState(null)

  useEffect(() => {
    setLocation(
      window && !document?.getElementsByClassName('fc-view-harness')[0]
        ? window?.location?.pathname
        : null
    )
  }, [location])

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogo(isMyQInstance(ids) ? MYQ_LOGO : logoFile)
    }, 1000)

    return () => clearTimeout(timer)
  }, [ids])

  return (
    logo && (
      <NavLink to="/" activeStyle={{ display: 'block' }}>
        <img
          className={css({
            width: isMyQInstance(ids) ? '120px' : '80px',
            height: 'auto',
            top: '25px'
          })}
          src={logo}
          id="appLogo01"
          alt="Velostics Logo"
        />
      </NavLink>
    )
  )
}

export default Logo
