import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import Table from 'components/ui/generic/Table'
import { ParagraphLarge } from 'baseui/typography'
import authenticatedFetch from '../utils/authenticated-fetch'

import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'

import Header from 'components/ui/generic/Header'
import CreateButton from 'components/ui/specific/CreateButton'
import { Circle } from '@phosphor-icons/react'

const AppointmentTypeSearch = () => {
  const [appointmentTypes, setAppointmentTypes] = useState<any>([])
  const [css] = useStyletron()
  const { t } = useTranslation()

  useEffect(() => {
    const runSearch = async () => {
      const path = '/appointment_types.json?filter=all'

      const [json, status] = await authenticatedFetch({
        path
      })

      if ([200, 204].includes(status)) {
        setAppointmentTypes(json)
      }
    }

    runSearch()
  }, [])

  return (
    <>
      <Header
        title={t('Settings.AppointmentTypes.Header.Text')}
        rightItems={[<CreateButton $as="a" href="/settings/appointment_types/new" />]}
      />

      <div className={css({ height: '60vh' })}>
        <Table
          columns={[
            t('Settings.AppointmentTypes.Table.Name'),
            t('Settings.AppointmentTypes.Table.Color'),
            t('Settings.AppointmentTypes.Table.FacilityName')
          ]}
          data={appointmentTypes.map(appointmentType => {
            const { id, name, color, facilityNames } = appointmentType

            return [
              <Link to={`/settings/appointment_types/${id}`}>{name}</Link>,
              <Circle fill={color} weight="fill" />,
              facilityNames
            ]
          })}
        />
      </div>
    </>
  )
}

export default AppointmentTypeSearch
