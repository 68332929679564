import * as React from 'react'
import { useStyletron } from 'baseui'
import { Input, StyledInput } from 'baseui/input'
import { Tag, VARIANT as TAG_VARIANT } from 'baseui/tag'
import { PurchaseOrderRequest } from 'components/appointments/types'
import CyberInput from 'cyber/input'
interface PurchaseOrderIdentifiersProps {
  record: {
    purchaseOrdersAttributes: PurchaseOrderRequest[]
  }
  setRecord: (record) => void
}

const InputReplacement = React.forwardRef(
  ({ purchaseOrdersAttributes, removeTag, ...restProps }: any, ref) => {
    const [css] = useStyletron()
    const inputRef = React.useRef<HTMLInputElement>(null)
    return (
      <div
        className={css({
          flex: '1 1 0%',
          flexWrap: 'wrap',
          display: 'flex',
          alignItems: 'center'
        })}>
        {purchaseOrdersAttributes
          .filter(({ _destroy }: PurchaseOrderRequest) => !_destroy)
          .map((purchaseOrderRequest: PurchaseOrderRequest, index: number) => (
            <Tag
              variant={TAG_VARIANT.solid}
              onActionClick={() => removeTag(purchaseOrderRequest)}
              key={index}>
              {purchaseOrderRequest.identifier}
            </Tag>
          ))}
        <StyledInput ref={inputRef} {...restProps} />
      </div>
    )
  }
)
const PurchaseOrderIdentifiers = (props: PurchaseOrderIdentifiersProps) => {
  const { record, setRecord } = props
  const [value, setValue] = React.useState('')
  const addTag = (purchaseOrderIdentfier: string) => {
    setRecord({
      ...record,
      purchaseOrdersAttributes: [
        ...record.purchaseOrdersAttributes,
        { id: null, identifier: purchaseOrderIdentfier }
      ]
    })
  }
  const removeTag = (purchaseOrderRequestToRemove: PurchaseOrderRequest) => {
    const purchaseOrdersAttributes = record.purchaseOrdersAttributes
      .map(purchaseOrderRequest => {
        if (purchaseOrderRequest === purchaseOrderRequestToRemove && !!purchaseOrderRequest.id) {
          return { ...purchaseOrderRequestToRemove, _destroy: true }
        } else if (purchaseOrderRequest === purchaseOrderRequestToRemove) {
          return undefined
        } else {
          return purchaseOrderRequest
        }
      })
      .filter(e => !!e)

    setRecord({
      ...record,
      purchaseOrdersAttributes
    })
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const filteredPurchaseOrders = record.purchaseOrdersAttributes.filter(
      ({ _destroy }: PurchaseOrderRequest) => !_destroy
    )
    switch (event.keyCode) {
      // Enter
      case 13: {
        if (!value) return
        addTag(value)
        setValue('')
        return
      }
      // Tab
      case 9: {
        if (!value) return
        addTag(value)
        setValue('')
        return
      }
      // Backspace
      case 8: {
        if (value || !filteredPurchaseOrders.length) return
        removeTag(filteredPurchaseOrders[filteredPurchaseOrders.length - 1])
        return
      }
    }
  }

  const handleBlur = () => {
    if (!value) return
    addTag(value)
    setValue('')
    return
  }

  return (
    <CyberInput
      name="appointment[purchase_orders]"
      placeholder={record.purchaseOrdersAttributes.length ? '' : 'Start typing...'}
      value={value}
      onChange={e => setValue(e.currentTarget.value)}
      {...({
        overrides: {
          Root: {
            style: ({ $theme }) => ({
              borderTopWidth: $theme.borders.borderWidth,
              borderBottomWidth: $theme.borders.borderWidth,
              borderLeftWidth: $theme.borders.borderWidth,
              borderRightWidth: $theme.borders.borderWidth,
              height: $theme.sizing.scale1000
            })
          },
          Input: {
            style: ({ $theme, $isFocused }) => ({
              width: 'auto',
              flexGrow: 1,
              borderColor: $theme.borders.borderColor
            }),
            component: InputReplacement,
            props: {
              purchaseOrdersAttributes: record.purchaseOrdersAttributes,
              removeTag: removeTag,
              onKeyDown: handleKeyDown,
              onBlur: handleBlur
            }
          },
          InputContainer: {
            style: ({ $theme }) => ({
              borderColor: $theme.borders.borderColor
            })
          }
        }
      } as any)}
    />
  )
}

export default PurchaseOrderIdentifiers
