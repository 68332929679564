import React, { useContext, useState } from 'react'
import { Block } from 'baseui/block'
import StyledSpinner from 'components/shared/styled-spinner'
import { CurrentUserContext } from 'components/homepage/current-user-context'

import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'
import Table from 'components/ui/generic/Table'
import Tag from 'components/ui/generic/Tag'
import { FileSearch, ArrowArcRight, Selection, FlagPennant } from '@phosphor-icons/react'
import Pagination from 'components/components/Pagination'
import { PAGE_SIZE } from 'components/constants/page-size'
import { ParagraphLarge } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { useTrailerContext } from 'components/contexts/yard-trailer.context'
import { AssignButtonProps, NotFoundContainer, TagTrailerStateProps } from './TrailersList.styled'
import { TrailerEvent } from 'components/models/Trailer'
import TimeFormatter from 'components/utils/time-formatter'
import PrimaryButton from 'components/ui/specific/PrimaryButton'
import { KIND, SIZE } from 'baseui/button'
import Button from 'components/ui/generic/Button'
import { primitives } from '../../homepage/custom-theme'

const INITIAL_COLUMNS = [
  { name: 'TrailerNumber', position: 1 },
  { name: 'Owner', position: 2 },
  { name: 'Arrival', position: 3 },
  { name: 'Time', position: 4 },
  { name: 'Facility', position: 5 },
  { name: 'InboundAppointment', position: 6 },
  { name: 'Location', position: 7 },
  { name: 'Status', position: 8 },
  { name: 'RedFlag', position: 9 },
  { name: 'Notes', position: 10 },
  { name: 'OutboundAppointment', position: 11 },
  { name: 'Button', position: 12 }
]

const TrailersList = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const [cachedColumns, setCachedColumns] = useLocalStorage(`TASK_COLUMNS_BY_USER_AND_ORG`, {})
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  const {
    state: {
      trailers: { total, loading, trailers },
      pagination: { page }
    },
    actions: { setPage, openMoveTrailer }
  } = useTrailerContext()

  const [shownColumns, setShownColumns] = useState(
    cachedColumns &&
      cachedColumns[currentUser?.shipperId] &&
      cachedColumns[currentUser?.shipperId][currentUser?.id]
      ? cachedColumns[currentUser?.shipperId][currentUser?.id]
      : INITIAL_COLUMNS
  )

  const trailerRow = (trailer: TrailerEvent) => {
    const trailerRow = [
      <strong
        data-testid="trailer-list-trailer-number"
        className={trailer?.status === 'full' ? 'blue-line' : 'no-line'}>
        {trailer.number}
      </strong>,
      trailer.inboundAppointment?.carrierName,
      trailer.arrivalTime ? new TimeFormatter('shortDate').format(trailer.arrivalTime) : '',
      trailer.time +
        ' ' +
        (trailer.time > 1
          ? t(`YardTrailer.Table.Columns.Time.Days.Text`)
          : t(`YardTrailer.Table.Columns.Time.Day.Text`)),
      trailer.facility?.name,
      trailer.inboundAppointment?.confirmationId,
      trailer.slot?.name || trailer.dock?.name,
      <Tag closeable={false} {...TagTrailerStateProps(trailer?.status)}>
        {trailer?.status}
      </Tag>,
      trailer.tasks?.length > 0 && trailer.tasks[0].flag && (
        <FlagPennant color={primitives.error} size="12px" />
      ),
      trailer.tasks?.length > 0 && trailer.tasks[0].flag && trailer.tasks[0].notes,
      trailer.outboundAppointment?.confirmationId
    ]

    if (!currentUser?.viewOnly) {
      trailerRow.push(
        trailer.isUnassigned ? (
          <Button
            data-testid="trailer-list-assign-button"
            onClick={() => {
              openMoveTrailer(trailer)
            }}
            IconStart={() => <Selection size={16} />}
            {...AssignButtonProps}>
            {t(`YardTrailer.Table.Action.Assign.Text`)}
          </Button>
        ) : (
          <PrimaryButton
            data-testid="trailer-list-move-button"
            onClick={() => {
              openMoveTrailer(trailer)
            }}
            size={SIZE.mini}
            kind={KIND.minimal}
            IconStart={() => <ArrowArcRight size={16} />}>
            {t(`YardTrailer.Table.Action.Move.Text`)}
          </PrimaryButton>
        )
      )
    }
    return trailerRow
  }

  const columnLabelCallback = ({ name }) => {
    return t(`YardTrailer.Table.Columns.${name}.Label.Text`)
  }

  return (
    <Block width={'100%'}>
      <Block paddingBottom="scale200" minHeight="60vh">
        {loading ? (
          <Block display="flex" justifyContent="center" alignItems="center">
            <StyledSpinner />
          </Block>
        ) : trailers.length === 0 ? (
          <NotFoundContainer>
            <FileSearch size={theme.sizing.scale2400} />
            <ParagraphLarge>{t('YardTrailer.Table.NoTrailerFound.Text')}</ParagraphLarge>
          </NotFoundContainer>
        ) : (
          <Table
            columns={shownColumns.map(columnLabelCallback)}
            data={trailers.map(task => trailerRow(task))}
          />
        )}
      </Block>
      <Pagination total={Math.ceil(total / PAGE_SIZE) || 1} page={page} onPageChange={setPage} />
    </Block>
  )
}

export default TrailersList
