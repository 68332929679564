import { styled } from 'baseui'

export const QuestionPermissionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column'
})

export const QuestionPermissionsTitle = styled('div', {
  display: 'flex',
  height: '24px',
  justifyContent: 'space-between',
  maxWidth: '420px'
})

export const PermissionRowContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '420px',
  marginBottom: '10px',
  gap: '8px'
})

export const FieldContainer = styled('div', ({ justifyContent }) => ({
  width: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent
}))

export const QuestionPermissionsDivider = styled('hr', ({ $theme }) => ({
  width: '100%',
  margin: '8px 0',
  borderColor: $theme.colors.inputBorder,
  borderStyle: $theme.borders.borderStyle,
  borderWidth: `0 0 ${$theme.borders.borderWidth}`
}))
