import React from 'react'
import { FlexGrid } from 'baseui/flex-grid'
import YardMetricCard, { MetricCardProps } from './YardMetricCard'

const YardMetricsCards = ({ cards }: { cards: MetricCardProps[] }) => {
  return (
    <FlexGrid flexGridColumnCount={3} gridColumnGap={'5px'}>
      {cards.map((card, index) => (
        <YardMetricCard
          title={card.title}
          value={card.value}
          icon={card.icon}
          key={index}
          kind={card.kind}
        />
      ))}
    </FlexGrid>
  )
}

export default YardMetricsCards
