import React, { useState, useEffect, useContext } from "react";
import { Button } from "baseui/button";
import { Modal, ModalHeader, ModalBody, ModalFooter, SIZE } from "baseui/modal";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { FormControl } from "baseui/form-control";
import { TimePicker } from "baseui/timepicker";
import { fancyToast } from "../../utils";
import authenticatedFetch from "../../utils/authenticated-fetch";
import ScheduleForm from "../../shared/schedule-form";
import { Link } from "react-router-dom";

interface EditRepeatingAppointment {
  isOpen: boolean;
  close: () => void;
  event: any;
}

const EditRepeatingAppointment = (props: EditRepeatingAppointment) => {
  const { isOpen, close, event } = props;
  const [appointment, setAppointment] = useState<any>(null);

  useEffect(() => {
    event &&
      event.id &&
      authenticatedFetch({
        path: `/appointments/${event.extendedProps.eventId}.json`,
      }).then(([json, status]) => {
        if (![200, 304].includes(status)) fancyToast(json, status);
        setAppointment({
          ...json,
          arrivalTime: new Date(json.arrivalTime),
        });
      });
  }, [event]);

  const updateAppointment = async () => {
    const [res, status] = await authenticatedFetch({
      path: `/appointments/${appointment.id}.json`,
      method: "PATCH",
      body: {
        appointment: {
          schedule: appointment.schedule,
          arrivalTime: appointment.arrivalTime,
        },
      },
    });
    if (![200].includes(status)) fancyToast(res, status);
    else fancyToast({ info: "Appointment successfully updated" }, status);
  };

  if (!appointment) return <></>;

  return (
    <Modal onClose={close} isOpen={isOpen} size={SIZE.auto}>
      <ModalHeader>
        <span>
          <Link to={`/appointments/${appointment.id}`}>Appointment</Link> : Edit
          Repeating Days
        </span>
      </ModalHeader>
      <ModalBody>
        <FormControl label="Arrival Time">
          <TimePicker
            step={30 * 60}
            value={appointment.arrivalTime}
            onChange={(date) =>
              setAppointment({
                ...appointment,
                arrivalTime: date as Date,
              })
            }
          />
        </FormControl>
        <ScheduleForm
          record={{ ...appointment, answersAttributes: null }}
          setRecord={setAppointment}
          timeField="arrivalTime"
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => updateAppointment().then(() => close())}>
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditRepeatingAppointment;
