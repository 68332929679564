import React, { useContext, useEffect, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { MagnifyingGlass, Plus, CircleNotch } from '@phosphor-icons/react'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'
import { CurrentUserContext } from 'components/homepage/current-user-context'

import Input from 'components/ui/generic/Input'
import Button from 'components/ui/generic/Button'

export const APPOINTMENTS_PAGE = 'APPOINTMENTS_SEARCH_TEXT_BY_USER_AND_ORG'
export const DOCK_ASSIGNMENT_PAGE = 'DOCKS_SEARCH_TEXT_BY_USER_AND_ORG'

export const NonConnectedSearchbox = ({
  currentRefinement,
  refine,
  isSearchStalled,
  placeholder,
  onCreate,
  collapsable,
  cacheKey
}) => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const { currentUser } = useContext(CurrentUserContext)
  const [isExpanded, setIsExpanded] = useState(false)

  const [cachedSearchTextsByUser, setCachedSearchTextsByUser] = useLocalStorage(cacheKey, undefined)

  const cachedSearchText =
    cachedSearchTextsByUser &&
    cachedSearchTextsByUser[currentUser.shipperId] &&
    cachedSearchTextsByUser[currentUser.shipperId][currentUser.id]

  const [searchText, setSearchText] = useState(cachedSearchText || currentRefinement)

  useEffect(() => {
    refine(cachedSearchText || currentRefinement)
  }, [])

  useEffect(() => {
    setCachedSearchTextsByUser({
      ...cachedSearchTextsByUser,
      [currentUser.shipperId]: {
        ...((cachedSearchTextsByUser && cachedSearchTextsByUser[currentUser.shipperId]) || {}),
        [currentUser.id]: currentRefinement
      }
    })
  }, [currentRefinement])

  return (
    <div className={css({ display: 'flex', gap: theme.sizing.scale800 })}>
      <Input
        startEnhancer={() => (
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className={css({
              cursor: 'pointer'
            })}>
            {isSearchStalled ? (
              <CircleNotch
                className={css({
                  animationDuration: '800ms',
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear',
                  animationName: {
                    from: {
                      transform: 'rotate(0deg)'
                    },
                    to: {
                      transform: 'rotate(360deg)'
                    }
                  }
                })}
                size={theme.sizing.scale600}
              />
            ) : (
              <MagnifyingGlass />
            )}
          </div>
        )}
        placeholder={placeholder}
        autoComplete="off"
        name="searchbox"
        value={searchText}
        size="compact"
        onChange={e => {
          refine(e.currentTarget.value)
          setSearchText(e.currentTarget.value)
        }}
        overrides={{
          Root: {
            style: collapsable && {
              width: isExpanded ? '200px' : '40px',
              transition: 'width 0.3s ease-in-out, padding 0.3s ease-in-out',
              paddingLeft: '2px',
              paddingTop: '2px'
            }
          },
          Input: {
            style: {
              paddingLeft: '0'
            }
          }
        }}
      />
      {onCreate ? (
        <Button
          onClick={onCreate}
          shape="pill"
          size="compact"
          startEnhancer={() => <Plus />}
          kind="primary">
          {t('Common.Button.Create.Text')}
        </Button>
      ) : null}
    </div>
  )
}

const CyberSearchBox = connectSearchBox(NonConnectedSearchbox as any)

const CyberSearchBoxWithProps = props => <CyberSearchBox {...props} />

export default CyberSearchBoxWithProps as any
