import { captureException, withProfiler } from '@sentry/react'
import { Component, ReactNode } from 'react'

interface Props {
  fallback: ReactNode
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error): void {
    captureException(error)
    this.setState({ hasError: true })
  }

  render(): ReactNode {
    const { fallback, children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return fallback
    }

    return children
  }
}

export default withProfiler(ErrorBoundary)
