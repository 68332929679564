import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal'
import VButton from 'components/ui/generic/Button'
import { useAppointmentContext } from 'components/contexts/appointment-context'
import { CheckSquareOffset } from '@phosphor-icons/react'
import { termsOfServiceStyles } from './TermsOfServiceModal.styled'
import { useTranslation } from 'react-i18next'

const TermsOfServiceModal = ({ termsOfServiceText }: { termsOfServiceText: string }) => {
  const { t } = useTranslation()
  const { state, actions } = useAppointmentContext()
  const { setShowTermsOfServiceModal, setIsTermsOfServiceChecked } = actions
  const { termsOfService } = state

  function close() {
    setShowTermsOfServiceModal(false)
  }

  function acceptTermsOfService() {
    setIsTermsOfServiceChecked(true)
    close()
  }

  return (
    <>
      <Modal
        onClose={close}
        isOpen={termsOfService.showTermsOfServiceModal}
        unstable_ModalBackdropScroll={true}
        overrides={termsOfServiceStyles.modal}>
        <ModalHeader>Terms of service</ModalHeader>
        <ModalBody style={termsOfServiceStyles.modalBody}>
          <div dangerouslySetInnerHTML={{ __html: termsOfServiceText }} />
        </ModalBody>
        <ModalFooter>
          <VButton
            onClick={acceptTermsOfService}
            endEnhancer={<CheckSquareOffset size={16} />}
            kind="primary">
            {t('Scheduler.OpenScheduling.Steps.TermsOfService.TermsOfServiceAceptButtonLabel.Text')}
          </VButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default TermsOfServiceModal
