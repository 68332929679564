import React from 'react'
import { Tag as RawTag, VARIANT } from 'baseui/tag'

import { useStyletron } from 'baseui'
import { IconContainer, TAGS_TYPES, TagIconStyled, TagStyled } from './Tag.styled'

const Tag = ({
  type = 'primary',
  closeable = false,
  variant = VARIANT.solid,
  icon,
  children
}: {
  closeable?: boolean
  variant?: 'solid' | 'light' | 'outlined'
  type?: 'warning' | 'primary' | 'secondary'
  icon?: any
  children: any
}) => {
  const [css, theme] = useStyletron()
  return (
    <RawTag closeable={closeable} variant={variant} {...TagStyled(type)}>
      {icon && <IconContainer className={TagIconStyled(type)}>{icon}</IconContainer>}
      {children}
    </RawTag>
  )
}

export default Tag
