import React, { useState, useEffect, useContext, Dispatch, useCallback } from 'react'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { SelectOption } from 'components/shared/types/Option'
import { shipperService } from 'components/services/shipper.service'
import { useTranslation } from 'react-i18next'

import FormControl from 'components/ui/generic/FormControl'
import Select from 'components/ui/generic/Select'
import { useTasksContext } from 'components/contexts/tasks.context'
import useIsMounted from 'components/hooks/use-is-mounted'

const SelectFacilityField = () => {
  const { state, actions } = useTasksContext()
  const {
    taskModal: { task, isEdit }
  } = state
  const { setTask } = actions

  const isMounted = useIsMounted()
  const { currentUser } = useContext(CurrentUserContext)
  const [facilityOptions, setFacilityOptions] = useState<SelectOption[]>([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const fetchRecords = useCallback(async () => {
    setLoading(true)
    if (currentUser?.shipperId) {
      shipperService
        .getFacilityList(currentUser.shipperId)
        .then(facilities => {
          setFacilityOptions(facilities)
          setLoading(false)
        })
        .catch(console.log)
    }
  }, [isMounted])

  useEffect(() => {
    if (isMounted.current) {
      fetchRecords()
    }

    return () => {
      isMounted.current = false
    }
  }, [fetchRecords, isMounted, currentUser, isEdit])

  return (
    <FormControl>
      <Select
        isLoading={loading}
        id="facilitySelect"
        options={facilityOptions}
        value={facilityOptions.find(f => f.id === task?.facilityId) || []}
        searchable={false}
        clearable={false}
        placeholder=""
        label={t('YardTask.Kanban.TaskModal.Fields.Facility.Text')}
        onChange={value => {
          setTask({
            ...task,
            facilityId: value.option.id,
            toDockId: null,
            toSlotId: null
          })
        }}
      />
    </FormControl>
  )
}

export default SelectFacilityField
