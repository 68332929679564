import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import {
  SET_SELECTED_FACILITY,
  SET_DOCK_OPTIONS,
  SET_SELECTED_DOCKS,
  dockAssignmentReducer,
  SET_LOADING,
  SET_DOCK_BLACKOUTS,
  SET_BLACKOUT_MODAL_LOADING,
  SET_DOCK_AND_TIME_FOR_BLACKOUT_MODAL,
  CLOSE_BLACKOUT_MODAL,
  OPEN_BLACKOUT_MODAL_FOR_UPDATE,
  REFRESH_BLACKOUTS,
  SET_CONFIRMATION_MODAL
} from 'components/reducers/dock-assignment.reducer'
import { Facility } from '../models/Facility'
import { Dock } from '../models/Dock'
import i18n from 'translations/i18n'
import { CurrentUserContext } from '../homepage/current-user-context'
import { facilityService } from '../services/facility.service'
import { BlockCounts } from '../models/BlockCounts'
import { DockBlock } from '../models/DockBlock'
import { Option } from '../shared/types/Option'
import { dockBlockService } from '../services'
import extractFromDate from '../utils/extract-from-date'
import { HH_MM_SS, YYYY_MM_DD } from '../utils/time-formats'
import { fancyToast } from '../utils'
import { useTranslation } from 'react-i18next'
import { StatusCodes } from '../constants/http-status-codes'
import moment from 'moment'
import { RefreshSearchContext } from '../../lib/cyber-components/search/search'
import combineDateAndTime from '../utils/combine-date-and-time'

export interface DockBlackout extends Omit<DockBlock, 'blockCountsAttributes'> {
  blockCountsAttributes?: BlockCounts[]
  dockId: string
}

interface DockAssignmentContextProps {
  selectedFacility: Facility[]
  selectedDocks: { id: string; label: string }[]
  dockOptions: Dock[]
  loading: boolean
  dockBlackout: DockBlackout
  dockBlackoutModalLoading: boolean
  selectedDockForBlackoutModal: Option<Dock>
  blackoutModalIsOpenForCreate: boolean
  blackoutModalIsOpenForUpdate: boolean
  blackoutRefresh: boolean
  confirmationModal: boolean
  confirmModalFunction: Function
}

export const DEFAULT_UNASSIGNED_DOCK_OPTION = {
  id: 'all-docks',
  label: i18n.t('Common.Select.DefaultUnassignedOption.Text'),
  commodityTypes: [],
  equipmentTypeIds: [],
  appointmentTypeIds: []
}

export const FIXED_UNASSIGNED_COLUMN = {
  id: 'unassigned',
  title: 'Common.Select.DefaultUnassignedColumn.Text',
  position: 0
}

const initialState: DockAssignmentContextProps = {
  selectedFacility: undefined,
  selectedDocks: [DEFAULT_UNASSIGNED_DOCK_OPTION],
  dockOptions: [],
  loading: false,
  dockBlackout: null,
  dockBlackoutModalLoading: false,
  selectedDockForBlackoutModal: null,
  blackoutModalIsOpenForCreate: false,
  blackoutModalIsOpenForUpdate: false,
  blackoutRefresh: false,
  confirmationModal: false,
  confirmModalFunction: () => {}
}

export const DockAssignmentContext = createContext({} as any)

export const FACILITY_KEY = 'DOCK_ASSIGMENT_FACILITY_KEY'

export const BLACKOUT_OVERLAP_ERROR = 'blackout-overlap'

export const DockAssignmentProvider = ({
  children,
  defaultInitialState
}: {
  children: any
  defaultInitialState?: DockAssignmentContextProps
}) => {
  const [state, dispatch] = useReducer(dockAssignmentReducer, defaultInitialState || initialState)
  const { currentUser } = useContext(CurrentUserContext)
  const { refresh } = useContext(RefreshSearchContext)
  const [defaultFacilities, setDefaultFacilities] = useState({})
  const { t } = useTranslation()

  useEffect(() => {
    if (currentUser) {
      const defaultFacilitiesByOrgAndUser = JSON.parse(localStorage.getItem(FACILITY_KEY) || '{}')
      const defaultFacility =
        (defaultFacilitiesByOrgAndUser &&
          defaultFacilitiesByOrgAndUser[currentUser.shipperId] &&
          defaultFacilitiesByOrgAndUser[currentUser.shipperId][currentUser.id]) ||
        undefined

      if (defaultFacility) {
        facilityService.getFacility(defaultFacility.id).then(result => {
          if (result) {
            setSelectedFacility(result)
          }
        })
      }
      setDefaultFacilities(defaultFacilitiesByOrgAndUser)
    }
  }, [currentUser])

  const setSelectedFacility = (value: Facility) => {
    dispatch({ type: SET_SELECTED_FACILITY, payload: value })
    localStorage.setItem(
      FACILITY_KEY,
      JSON.stringify({
        ...defaultFacilities,
        [currentUser.shipperId]: {
          ...((defaultFacilities && defaultFacilities[currentUser.shipperId]) || {}),
          [currentUser.id]: value
        }
      })
    )
  }

  const setSelectedDocks = (value: Dock[]) => {
    dispatch({ type: SET_SELECTED_DOCKS, payload: value })
  }

  const setDockBlackout = value => {
    const { startTime, dockBlackout, endTime } = state

    if (
      dockBlackout?.schedule &&
      (value.hasOwnProperty('startTime') || value.hasOwnProperty('endTime'))
    ) {
      const newSchedule = {
        ...dockBlackout.schedule,
        start_time: value.startTime ? value.startTime : startTime,
        rrules: [
          {
            ...dockBlackout.schedule.rrules[0],
            until: moment(value.endTime ? value.endTime : endTime)
              .add(1, 'd')
              .format()
          }
        ]
      }

      dispatch({
        type: SET_DOCK_BLACKOUTS,
        payload: {
          ...value,
          schedule: newSchedule
        }
      })
    } else {
      dispatch({ type: SET_DOCK_BLACKOUTS, payload: value })
    }
  }

  const setLoading = (loading: boolean) => {
    dispatch({ type: SET_LOADING, payload: loading })
  }

  const setBlackoutModalLoading = (loading: boolean) => {
    dispatch({ type: SET_BLACKOUT_MODAL_LOADING, payload: loading })
  }

  const setCompatibleDocks = ({ extendedProps }) => {
    const { dockOptions } = state
    const { equipmentTypeId, appointmentTypeId, commodityType } = extendedProps

    const compatibleDocks = dockOptions.filter(
      dock =>
        dock.appointmentTypeIds.includes(appointmentTypeId) &&
        dock.commodityTypes.includes(commodityType) &&
        dock.equipmentTypeIds.includes(equipmentTypeId)
    )

    setSelectedDocks(compatibleDocks)
  }

  const setSelectedDockAndTimeForBlackoutModal = value => {
    dispatch({ type: SET_DOCK_AND_TIME_FOR_BLACKOUT_MODAL, payload: value })
  }

  const openBlackoutModalForUpdate = ({ id }) => {
    const { dockOptions } = state
    setBlackoutModalLoading(true)

    dockBlockService
      .getDockBlock(id)
      .then(([blackout, status]) => {
        if (status === StatusCodes.OK) {
          const startTime = combineDateAndTime(blackout.startDate, blackout.startTime)
          const endTime = combineDateAndTime(blackout.endDate, blackout.endTime)
          const dock = dispatch({
            type: OPEN_BLACKOUT_MODAL_FOR_UPDATE,
            payload: {
              dockBlackout: {
                ...blackout,
                startTime,
                endTime,
                dock: dockOptions.filter(dockOption => dockOption.id === blackout.dockId)[0]
              }
            }
          })
        }
      })
      .finally(() => {
        setBlackoutModalLoading(false)
      })
  }

  const setDockOptions = (value: Dock[]) => {
    dispatch({ type: SET_DOCK_OPTIONS, payload: value })
  }

  const setBlackoutRefresh = (value: boolean) => {
    dispatch({ type: REFRESH_BLACKOUTS, payload: value })
  }

  const closeDockBlackoutModal = () => {
    refresh()
    setBlackoutRefresh(!state.blackoutRefresh)
    dispatch({ type: CLOSE_BLACKOUT_MODAL })
  }

  const createDockBlackout = () => {
    setBlackoutModalLoading(true)

    const { dockBlackout, selectedDockForBlackoutModal, selectedFacility } = state

    dockBlockService
      .createDockBlock({
        ...dockBlackout,
        startDate: extractFromDate(dockBlackout.startTime, YYYY_MM_DD),
        startTime: extractFromDate(dockBlackout.startTime, HH_MM_SS),
        endDate: extractFromDate(dockBlackout.endTime, YYYY_MM_DD),
        endTime: extractFromDate(dockBlackout.endTime, HH_MM_SS),
        dockId: selectedDockForBlackoutModal[0].id,
        facilityId: selectedFacility.id
      })
      .then(([result, status]) => {
        if (status === StatusCodes.CREATED) {
          fancyToast({ info: t('DockAssignment.BlackoutModal.SuccessCreate') }, status)
        } else if (result?.code === BLACKOUT_OVERLAP_ERROR) {
          fancyToast({ info: t('DockAssignment.Errors.BlackoutOverlap') }, status)
        } else {
          fancyToast(result, status)
        }
        closeDockBlackoutModal()
      })
      .finally(() => setBlackoutModalLoading(false))
  }

  const updateDockBlackout = async () => {
    setBlackoutModalLoading(true)
    const { dockBlackout, selectedFacility } = state
    dockBlockService
      .updateDockBlock({
        ...dockBlackout,
        startDate: extractFromDate(dockBlackout.startTime, YYYY_MM_DD),
        startTime: extractFromDate(dockBlackout.startTime, HH_MM_SS),
        endDate: extractFromDate(dockBlackout.endTime, YYYY_MM_DD),
        endTime: extractFromDate(dockBlackout.endTime, HH_MM_SS),
        facilityId: selectedFacility.id
      })
      .then(([json, status]) => {
        if (status === StatusCodes.OK) {
          fancyToast({ info: t('DockAssignment.BlackoutModal.SuccessUpdate') }, status)
        } else if (json?.code === BLACKOUT_OVERLAP_ERROR) {
          fancyToast({ info: t('DockAssignment.Errors.BlackoutOverlap') }, status)
        } else {
          fancyToast(json, status)
        }
        closeDockBlackoutModal()
      })
      .finally(() => {
        setBlackoutModalLoading(false)
      })
  }

  const deleteDockBlackout = async () => {
    setBlackoutModalLoading(true)
    dockBlockService
      .deleteDockBlock(state.dockBlackout.id)
      .then(([result, status]) => {
        if (status === StatusCodes.NO_CONTENT) {
          close()
          fancyToast({ info: t('DockAssignment.BlackoutModal.SuccessDelete') }, status)
        } else {
          fancyToast(result, status)
        }
        closeDockBlackoutModal()
      })
      .finally(() => setBlackoutModalLoading(false))
  }

  const dockBlackoutModalformErrors = (): { label: string; status: boolean }[] => {
    const { dockBlackout } = state

    return [
      {
        label: t('DockAssignment.BlackoutModal.Errors.Reason'),
        status: !!dockBlackout?.name && dockBlackout?.name?.trim().length > 0
      },
      {
        label: t('DockAssignment.BlackoutModal.Errors.StartDate'),
        status: !!dockBlackout?.startTime
      },
      { label: t('DockAssignment.BlackoutModal.Errors.EndDate'), status: !!dockBlackout?.endTime },
      {
        label: t('DockAssignment.BlackoutModal.Errors.StartAndEndTime'),
        status: dockBlackout?.startTime < dockBlackout?.endTime
      }
    ]
  }

  const setConfirmationModal = (value: boolean, confirmFunc: Function) => {
    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        value,
        confirmFunc
      }
    })
  }

  const closeConfirmationModal = () => {
    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        value: false,
        confirmFunc: () => {}
      }
    })
  }

  const confirmConfirmationModal = () => {
    const { confirmModalFunction } = state
    confirmModalFunction()

    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        value: false,
        confirmFunc: () => {}
      }
    })
  }

  const actions = {
    setSelectedFacility,
    setSelectedDocks,
    setDockOptions,
    setCompatibleDocks,
    setLoading,
    setDockBlackout,
    setBlackoutModalLoading,
    setSelectedDockAndTimeForBlackoutModal,
    closeDockBlackoutModal,
    createDockBlackout,
    deleteDockBlackout,
    dockBlackoutModalformErrors,
    updateDockBlackout,
    openBlackoutModalForUpdate,
    setBlackoutRefresh,
    setConfirmationModal,
    closeConfirmationModal,
    confirmConfirmationModal
  }

  return (
    <DockAssignmentContext.Provider value={{ ...state, actions }}>
      {children}
    </DockAssignmentContext.Provider>
  )
}

export const useDockAssigmentContext = () => useContext(DockAssignmentContext)
