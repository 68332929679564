import React from 'react'
import { useStyletron } from 'baseui'
import { Button, KIND } from 'baseui/button'
import { Modal, ModalFooter, ModalHeader } from 'baseui/modal'
import { toast } from 'components/utils/toast'
import { MetabaseReport } from 'components/models/MetabaseReport'
import { reportsService } from 'components/services/reports.service'
import { useHttp } from 'components/hooks/use-http'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  report?: MetabaseReport | null
  onFinish?: () => void
}

export const MetabaseAnalyticsSettingsConfirmationModal = ({
  open,
  onClose,
  report,
  onFinish
}: Props) => {
  const [css] = useStyletron()
  const [deleteReport, { loading }] = useHttp(reportsService.deleteReport)

  const handleSubmit = async () => {
    const [data, status] = await deleteReport(report.id)
    if (status === 200) {
      toast({ info: t('Settings.Analytics.DeleteModal.Info.Default.Text') }, 200)
      onFinish()
    } else {
      toast(data, status)
    }
    onClose()
  }

  const { t } = useTranslation()

  return (
    <Modal autoFocus={false} isOpen={open} onClose={onClose}>
      <ModalHeader>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          })}>
          <span>{t('Settings.Analytics.DeleteModal.SubTitle.Text')}</span>
        </div>
      </ModalHeader>
      <ModalFooter>
        <Button kind={KIND.minimal} onClick={onClose}>
          {t('Settings.Analytics.DeleteModal.ConfirmButton.Cancel.Text')}
        </Button>
        <Button isLoading={loading} onClick={handleSubmit}>
          {t('Settings.Analytics.DeleteModal.ConfirmButton.Delete.Text')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
