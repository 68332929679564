import React, { useContext, useState, useEffect } from "react";
import GuestCheckinModal from "./guest-checkin-modal";
import {
  CHECKIN,
  FTL,
  LTL,
  SLOT,
} from "../adjustable-calendar/toggles/event-type";
import CreateAppointment from "./create-appointment";
import EditAppointment from "./edit-appointment";
import { CalendarToggleContext } from "../../contexts/calendar-toggle-context";
import QuickCheckout from "./quick-checkout";
import GatehouseCheckin from "./gatehouse-checkin";

const ModalSwitcher = () => {
  const { selectedEvent, setSelectedEvent } = useContext(CalendarToggleContext);

  if (selectedEvent === null) {
    return <></>;
  }

  const type = selectedEvent?.type;
  const id = selectedEvent?.id;
  const appointmentEvent = [FTL, LTL].includes(type);

  if (type === CHECKIN && selectedEvent?.appointmentId) {
    return (
      <GatehouseCheckin
        isOpen
        close={() => {
          setSelectedEvent(null);
        }}
        event={selectedEvent}
      />
    );
  } else if (type === CHECKIN) {
    return (
      <GuestCheckinModal
        isOpen
        close={() => {
          setSelectedEvent(null);
        }}
        event={selectedEvent}
      />
    );
  } else if (appointmentEvent && id && selectedEvent?.quickCheckout) {
    return (
      <QuickCheckout
        isOpen
        close={() => {
          setSelectedEvent(null);
        }}
        event={selectedEvent}
      />
    );
  } else if ((appointmentEvent || type === SLOT) && (!id || id === "null")) {
    return (
      <CreateAppointment
        isOpen
        close={() => {
          setSelectedEvent(null);
        }}
        event={selectedEvent}
      />
    );
  } else if (id !== "null" && appointmentEvent) {
    return (
      <EditAppointment
        isOpen
        close={() => {
          setSelectedEvent(null);
        }}
        event={selectedEvent}
      />
    );
  } else {
    console.error("Event is selected, but no modal can display it.");
    return <></>;
  }
};

export default ModalSwitcher;
