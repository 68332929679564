import React, { useState, useEffect, useContext } from 'react'
import { Paragraph1 } from 'baseui/typography'
import { Button } from 'baseui/button'
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid'
import { RadioGroup, ALIGN, Radio } from 'baseui/radio'
import authenticatedFetch from '../utils/authenticated-fetch'
import StyledSpinner from '../shared/styled-spinner'
import { DRIVER } from '../models/User'
import TimeFormatter from '../utils/time-formatter'
import { CurrentUserContext } from '../homepage/current-user-context'
import QuestionsAndAnswersList from '../appointments/responses/questions-and-answers-list'
import { startCase, findIndex } from 'lodash'
import { CHECKIN_STATUSES, ON_TIME } from './check-in-statuses'

const CheckDriverIn = ({ appointment, setAppointment }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const { appointmentTypeId, answersAttributes } = appointment

  const [checkin, setCheckin] = useState<any>({})
  const [appointmentType, setAppointmentType] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [driverQuestionsAndAnswers, setDriverQuestionsAndAnswers] = useState<[]>([])

  useEffect(() => {
    appointment &&
      appointmentTypeId &&
      !appointmentType &&
      authenticatedFetch({
        path: `/appointment_types/${appointmentTypeId}.json`
      }).then(([json, status]) => setAppointmentType(json))
  }, [appointment])

  useEffect(() => {
    if (!appointmentType || !appointmentType.questions) {
      return
    }
    const { questions } = appointmentType
    const { answersAttributes } = appointment
    const fullAnswersAttributes = questions
      .filter(q => q.audienceType === DRIVER && !q.disabled)
      .map((question, index) => {
        const { id } = question
        const answerIndex = findIndex(answersAttributes, { questionId: id })
        return answerIndex === -1 ? { questionId: id } : answersAttributes[answerIndex]
      })
    setAppointment({
      ...appointment,
      answersAttributes: fullAnswersAttributes
    })
  }, [appointmentType])

  useEffect(() => {
    if (!appointmentType) {
      return
    }

    const driverQuestionsAndAnswers = answersAttributes.map((answer, index) => {
      const { questions } = appointmentType
      const question = questions[findIndex(questions, { id: answer.questionId })]
      return { question, answer }
    })
    setDriverQuestionsAndAnswers(driverQuestionsAndAnswers)
  }, [answersAttributes])

  const checkDriverIn = async () => {
    setLoading(true)
    const body = {
      checkin: {
        appointmentId: appointment.id,
        facilityId: appointment.facilityId,
        userId: currentUser.id,
        status: checkin.status
      }
    }
    const [checkinJson, checkinStatus] = await authenticatedFetch({
      path: `/checkins.json`,
      method: 'POST',
      body
    })

    // TODO(adenta) we shoudl be able to run these in paralell
    // This is also happening, to set the driver questions.
    if (checkinStatus === 201 && appointment.answersAttributes) {
      await authenticatedFetch({
        path: `/appointments/${appointment.id}.json`,
        method: 'PATCH',
        body: {
          appointment
        }
      })
    }

    if (checkinStatus === 201) {
      window.location.reload()
    }

    setLoading(false)
  }

  if (!appointment || (appointment && appointmentTypeId && !appointmentType)) {
    return <StyledSpinner />
  }

  return (
    <FlexGrid>
      <FlexGridItem>
        <QuestionsAndAnswersList questionsAndAnswers={driverQuestionsAndAnswers} />
      </FlexGridItem>
      <FlexGridItem>
        <Paragraph1>
          This appointment was scheduled for{' '}
          <code>
            {new TimeFormatter('fullDate').format(appointment.arrivalTime, appointment.timeZone)}
          </code>
          . Was it on time, early, or late?{' '}
        </Paragraph1>
      </FlexGridItem>
      <FlexGridItem>
        <RadioGroup
          value={checkin.status || ON_TIME}
          onChange={e => setCheckin({ ...checkin, status: e.currentTarget.value })}
          align={ALIGN.horizontal}>
          {CHECKIN_STATUSES.map((checkinStatus, index) => {
            return (
              <Radio key={index} value={checkinStatus}>
                {startCase(checkinStatus)}
              </Radio>
            )
          })}
        </RadioGroup>
        <Button onClick={checkDriverIn} isLoading={loading}>
          Check-in
        </Button>
      </FlexGridItem>
    </FlexGrid>
  )
}

export default CheckDriverIn
