import React, { useState, useEffect } from "react";
import { HeadingLevel, Heading } from "baseui/heading";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Table } from "baseui/table";
import { Button } from "baseui/button";
import authenticatedFetch from "../../utils/authenticated-fetch";
import { fancyToast } from "../../utils";

const ScoreCards = (props) => {
  return (
    <HeadingLevel>
      <Heading>Scorecards</Heading>
      <div style={{ overflow: "hidden", position: "relative" }}>
        <iframe
          style={{ border: 0, height: "700px", width: "100%" }}
          src={`https://terusama.retool.com/embedded/public/d5d19563-2c90-47ba-8d87-ed028498c472#shipperId=${props.match.params.handle}`}
        />
      </div>
    </HeadingLevel>
  );
};

export default ScoreCards;
