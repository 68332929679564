import React, { useEffect, useState, useContext } from 'react'
import { HeadingLevel, Heading } from 'baseui/heading'
import moment from 'moment-timezone'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import { Block } from 'baseui/block'
import { StyledSpinnerNext } from 'baseui/spinner'
import { fancyToast } from '../../utils'
import StyledSpinner from '../../shared/styled-spinner'
import authenticatedFetch from '../../utils/authenticated-fetch'
import EditAppointmentModal from '../modals/edit-repeating-appointment'
import CreateAppointmentModal from '../modals/create-repeating-appointment'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { SLOT } from '../../shared/adjustable-calendar/toggles/event-type'
import { calendarTranslations } from 'translations/calendar-languages'
import i18n from 'translations/i18n'
import { useTranslation } from 'react-i18next'
import { upperFirst } from 'lodash'

const RepeatingAppointmentSchedule = ({ match }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { currentUser } = useContext(CurrentUserContext)
  const [facility, setFacility] = useState(null)
  const [appointmentPreference, setAppointmentPreference] = useState(null)
  const [events, setEvents] = useState<any[]>([])
  const [repeatingAppointments, setRepeatingAppointments] = useState<any[]>([])
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const { t } = useTranslation()

  const DayHeaderContent = ({ date }) => {
    return <div>{upperFirst(date.toLocaleDateString(i18n.language, { weekday: 'long' }))}</div>
  }

  useEffect(() => {
    setLoading(true)
    authenticatedFetch({
      path: `/facilities/${match.params.handle}.json`
    }).then(([json, _status]) => {
      // TODO(adenta) this is disgusting. Open time and close time are being serialized in this manner because
      // they are used by selects in facility#book
      json.openTime = json.openTime[0].id
      json.closeTime = json.closeTime[0].id
      setFacility(json)
    })
    setLoading(false)
  }, [])

  useEffect(() => {
    setLoading(true)
    facility &&
      !appointmentPreference &&
      authenticatedFetch({
        path: `/appointment_preferences/${facility.appointmentPreferenceId}.json`
      }).then(([json, _status]) => {
        setAppointmentPreference(json)
      })
    setLoading(false)
  }, [facility])

  useEffect(() => {
    setLoading(true)
    // Limit repeat fetches to selectedEvent being null, i.e. after an edit modal is closed.
    !selectedEvent &&
      authenticatedFetch({
        path: `/facilities/${match.params.handle}/repeating_appointments.json`
      }).then(([json, status]) => {
        ;[200, 304].includes(status) ? setRepeatingAppointments(json) : fancyToast(json, status)
      })
    setLoading(false)
  }, [facility, selectedEvent])

  useEffect(() => {
    const formattedRepeatingAppointments =
      facility &&
      repeatingAppointments.flatMap(appointment => {
        return appointment.schedule.rrules[0].validations.day.map((id: string) => {
          const startTime = moment(appointment.arrivalTime)
          const endTime = moment(appointment.endTime)
          return {
            id,
            eventId: appointment.id,
            groupId: 'recurring-' + appointment.uuid,
            title: appointment.purchaseOrderIdentifiers,
            durationEditable: false,
            start: moment()
              .startOf('week')
              .add(id, 'days')
              .set({
                hour: startTime.hour(),
                minute: startTime.minute(),
                second: startTime.second()
              })
              .toDate(),
            end: moment()
              .startOf('week')
              .add(id, 'days')
              .set({
                hour: endTime.hour(),
                minute: endTime.minute(),
                second: endTime.second()
              })
              .toDate()
          }
        })
      })
    if (formattedRepeatingAppointments) setEvents(formattedRepeatingAppointments)
  }, [facility, appointmentPreference, repeatingAppointments])

  if (!facility || !appointmentPreference) return <StyledSpinner />

  return (
    <>
      <CreateAppointmentModal
        isOpen={openCreateModal}
        close={() => {
          setOpenCreateModal(false)
          setSelectedEvent(null)
        }}
        event={selectedEvent}
      />
      <EditAppointmentModal
        isOpen={openEditModal}
        close={() => {
          setOpenEditModal(false)
          setSelectedEvent(null)
        }}
        event={selectedEvent}
      />
      <HeadingLevel>
        <Block display="flex" alignItems="center" justifyContent="space-between">
          <Heading>
            {facility.name} {t('Facilities.RepeatingAppointments.Header.Text')}
          </Heading>
          {loading && <StyledSpinnerNext />}
        </Block>
        <FullCalendar
          locale={calendarTranslations[i18n.language]}
          height="auto"
          dayHeaderContent={DayHeaderContent}
          windowResizeDelay={250}
          editable={false}
          businessHours={{
            daysOfWeek: appointmentPreference.daysOfWeek,
            startTime: moment.utc(facility.openTime).format('HH:mm'),
            endTime: moment.utc(facility.closeTime).format('HH:mm')
          }}
          initialView="timeGridWeek"
          plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
          headerToolbar={false}
          allDaySlot={false}
          dayHeaderFormat={'dddd'}
          slotLabelFormat={
            // `hourCycle` is pretty new, not in the TS defs. Likewise it dosen't work in Safari.
            // If we use `hour12` instead of `hourCycle`, then hour12 takes priority. This means that we would see
            // `00:00` instead of `24:00` at the beginning of the day. As such, we are deciding to not worry about Safari
            // and are ok that it will be formatted with AM/PM times, in favor of not screwing up `00:00`.
            {
              hourCycle: 'h23',
              hour: '2-digit',
              minute: '2-digit'
            } as any
          }
          events={events.filter(({ id }) => {
            return appointmentPreference.daysOfWeek.indexOf(id) > -1
          })}
          dateClick={e => {
            if (!e.date) return
            setSelectedEvent({
              facility,
              dockId: 0,
              arrivalTime: e.date,
              createdByEmailAddress: currentUser.emailAddress,
              color: '#000000',
              type: SLOT
            })
            setOpenCreateModal(true)
          }}
          eventClick={e => {
            if (!e.event) return
            setSelectedEvent(e.event)
            setOpenEditModal(true)
          }}
        />
      </HeadingLevel>
    </>
  )
}

export default RepeatingAppointmentSchedule
