import React, { useState, useEffect, useContext } from 'react'
import { Modal, ModalHeader, ModalBody } from 'baseui/modal'
import { Link } from 'react-router-dom'
import { useStyletron } from 'baseui'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { CurrentUserContext } from '../../homepage/current-user-context'
import AppointmentDetails from '../../appointments/tabs/appointment-details'
import { AppointmentContext } from '../../appointments/appointment-form'
import { CalendarReferenceContext } from '../../contexts/calendar-reference-context'
import { CalendarToggleContext } from '../../contexts/calendar-toggle-context'
import { ON_TIME, EARLY, LATE } from 'components/checkins/check-in-statuses'
import { fancyToast } from 'components/utils'
import moment from 'moment'
import { Button } from 'baseui/button'

interface EditAppointmentProps {
  event: any
  isOpen: boolean
  close: () => void
}

const EditAppointment = (props: EditAppointmentProps) => {
  const { selectedDate, setSelectedDate } = useContext(CalendarToggleContext)
  const { event, isOpen, close } = props

  const { currentUser } = useContext(CurrentUserContext)
  const [css, theme] = useStyletron()

  const [appointment, setAppointment] = useState<any>(null)
  const [checkin, setCheckin] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    currentUser &&
      event &&
      event.id &&
      authenticatedFetch({
        path: `/appointments/${event.id}.json`
      }).then(([json, status]) => {
        const { arrivalTime } = json
        setAppointment({
          ...appointment,
          ...json,
          arrivalTime: arrivalTime ? new Date(arrivalTime) : new Date(Date.now())
        })
      })
  }, [currentUser])

  useEffect(() => {
    appointment &&
      appointment.checkinId &&
      !checkin &&
      authenticatedFetch({
        path: `/checkins/${appointment.checkinId}.json`
      }).then(([json, status]) => setCheckin(json))
  }, [appointment])

  const createCheckin = async createdUser => {
    setLoading(true)
    const arrivalTime = moment(appointment.arrivalTime)

    let checkinStatus = ON_TIME

    if (arrivalTime.diff(moment(), 'hours') > 1) {
      checkinStatus = EARLY
    }

    if (arrivalTime.diff(moment(), 'minutes') < -30) {
      checkinStatus = LATE
    }

    const body = {
      checkin: {
        appointmentId: appointment.id,
        facilityId: appointment.facilityId,
        userId: createdUser.id,
        status: checkinStatus
      }
    }
    const [json, status] = await authenticatedFetch({
      path: `/checkins.json`,
      method: 'POST',
      body
    })
    setLoading(false)

    if (status === 201) {
      setSelectedDate(new Date(selectedDate))
      close()
    } else {
      fancyToast(json, status)
    }
  }

  const completeAppointment = async () => {
    setLoading(true)
    const path = `/checkins/${checkin.id}.json`
    const [json, status] = await authenticatedFetch({
      path,
      method: 'PATCH',
      body: {
        checkin: {
          id: checkin.id,
          checkedOutAt: new Date().toISOString()
        }
      }
    })
    setLoading(false)
    if (status === 200) {
      close()
      setSelectedDate(new Date(selectedDate))
    } else {
      fancyToast(json, status)
    }
  }

  if (!currentUser || !appointment) {
    return <></>
  }

  return (
    <Modal size="full" onClose={close} isOpen={isOpen}>
      <ModalHeader>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          })}>
          <Link to={`/appointments/${appointment.id}`}>Appointment</Link>
          {checkin?.id && !checkin?.checkedOutAt && (
            <Button
              size="compact"
              kind="secondary"
              isLoading={loading}
              onClick={completeAppointment}>
              Check-out
            </Button>
          )}
          {!checkin && (
            <Button size="compact" kind="secondary" isLoading={loading} onClick={createCheckin}>
              Check-in
            </Button>
          )}
        </div>
      </ModalHeader>
      <ModalBody>
        <AppointmentContext.Provider value={{ appointment, setAppointment }}>
          <AppointmentDetails
            appointment={appointment}
            setAppointment={setAppointment}
            onUpdate={() => {
              setSelectedDate(new Date(selectedDate))
              close()
            }}
          />
        </AppointmentContext.Provider>
      </ModalBody>
    </Modal>
  )
}

export default EditAppointment
