import { useCarriersContext } from 'components/contexts/carriers.context'
import StyledSpinner from 'components/shared/styled-spinner'
import { CyberTable } from 'cyber/table'
import { useTranslation } from 'react-i18next'
import { styled, useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import React, { useMemo } from 'react'
import { PencilSimple, TrashSimple } from '@phosphor-icons/react'
import { ConfirmationModal } from 'components/components/ConfirmationModal'
import Pagination from 'components/ui/generic/Pagination'
import Button from 'components/ui/generic/Button'
import { SpinnerWrapper } from '../UserRoles/UserRolesTable.styled'
import CarrierModal from './CarrierModal'
import DeleteCarrierConfirmationModal from './DeleteCarrierConfirmationModal'

const customStyles = { paddingTop: '0' }
const PaginationDiv = styled('div', () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))
const ActionButtonStyle = styled('div', () => ({
  display: 'flex',
  gap: '5px'
}))

const CarriersTable = () => {
  const {
    carriers: { items, loading, carrierToUpdate, total, currentPage },
    actions: {
      setCarrierToEdit,
      setDeleteCarrier,
      setCarrierToUpdate,
      updateCarrier,
      setCurrentPage
    }
  } = useCarriersContext()

  const numPages = useMemo(() => Math.ceil(total / 10), [total])
  const { t } = useTranslation()

  return (
    <>
      {loading ? (
        <SpinnerWrapper>
          <StyledSpinner />
        </SpinnerWrapper>
      ) : (
        <>
          <Block minHeight="60vh">
            <CyberTable
              columns={[
                t('Settings.Carriers.Table.Columns.Name.Label.Text'),
                t('Settings.Carriers.Table.Columns.SCACCode.Label.Text'),
                ``
              ]}
              data={
                Array.isArray(items)
                  ? items.map((element: any) => [
                      <div style={customStyles}>{element.name}</div>,
                      <div style={customStyles}>{element.scacCode}</div>,
                      <ActionButtonStyle>
                        <Button
                          data-testid="edit-icon-carrier"
                          shape="circle"
                          onClick={() => setCarrierToEdit(element)}>
                          <PencilSimple />
                        </Button>
                        <Button
                          data-testid="remove-icon-carrier"
                          shape="circle"
                          onClick={() => setDeleteCarrier(element)}>
                          <TrashSimple />
                        </Button>
                      </ActionButtonStyle>
                    ])
                  : []
              }
            />
          </Block>
          <PaginationDiv>
            <Pagination
              labels={{
                prevButton: t('Common.Table.Pagination.Prev.Text'),
                nextButton: t('Common.Table.Pagination.Next.Text'),
                preposition: t('Common.Table.Pagination.Preposition.Text')
              }}
              numPages={numPages}
              currentPage={currentPage}
              onPageChange={({ nextPage }) => {
                setCurrentPage(Math.min(Math.max(nextPage, 1), numPages))
              }}
            />
          </PaginationDiv>

          <CarrierModal />
          <ConfirmationModal
            isOpen={!!carrierToUpdate}
            loading={loading}
            title={t('Settings.Carriers.ConfirmModal.ConfirmCarrierEdit.Text')}
            cancelAction={() => setCarrierToUpdate(undefined, true)}
            confirmAction={() => updateCarrier(carrierToUpdate)}
          />
          <DeleteCarrierConfirmationModal />
        </>
      )}
    </>
  )
}

export default CarriersTable
