import React from 'react'
import { FormControl } from 'baseui/form-control'

const VFormControl = ({ label, overrides, children, ...props }) => {
  return (
    <FormControl
      label={label}
      overrides={{
        Label: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize,
            [$theme.mediaQuery.large]: {
              fontSize: $theme.typography.LabelSmall.fontSize
            }
          })
        },
        ...overrides
      }}
      {...props}>
      {children}
    </FormControl>
  )
}
export default VFormControl
