import React, { useEffect } from 'react'
import { connectToggleRefinement } from 'react-instantsearch-dom'

import { camelCase, upperFirst } from 'lodash'
import Checkbox from 'components/ui/generic/Checkbox'

const NonConnectedToggleRefinement = props => {
  const { attribute, currentRefinement, refine, setAppliedFilters } = props

  useEffect(() => {
    if (currentRefinement && setAppliedFilters) {
      setAppliedFilters(c => ({
        ...c,
        [attribute]: currentRefinement
      }))
    } else if (setAppliedFilters) {
      setAppliedFilters(c => {
        const updatedState = { ...c }
        delete updatedState[attribute]
        return updatedState
      })
    }
  }, [setAppliedFilters, attribute, currentRefinement])

  return (
    <Checkbox
      checked={currentRefinement}
      onChange={() => refine(!currentRefinement)}
      label={upperFirst(camelCase(attribute))}
    />
  )
}

const CyberSearchToggleRefinement = connectToggleRefinement(NonConnectedToggleRefinement)

export default CyberSearchToggleRefinement
