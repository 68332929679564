import React, { useState, useEffect } from 'react'
import { Button } from 'baseui/button'
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid'
import { Input } from 'baseui/input'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { useCurrentFacilityContext } from 'components/contexts/current-facility.context'

const PurchaseOrderSearch = ({ checkin, setCheckin, onClickOther }) => {
  const [purchaseOrders, setPurchaseOrders] = useState<any>([])
  const [selected, setSelected] = useState<number>()
  const { currentFacility } = useCurrentFacilityContext()

  const runSearch = async () => {
    const [json, status] = await authenticatedFetch({
      path: `/facilities/${currentFacility.id}/todays_remaining_purchase_orders.json`
    })

    setPurchaseOrders(json)
  }

  useEffect(() => {
    currentFacility && currentFacility.id && runSearch()
  }, [currentFacility])

  if (purchaseOrders.length === 0) {
    return (
      <Input
        autoComplete="off"
        name="checkin[purchase_order_number]"
        value={checkin.purchaseOrderNumber}
        onChange={e => {
          setCheckin({
            ...checkin,
            purchaseOrderNumber: e.currentTarget.value
          })
        }}
      />
    )
  }

  return (
    <FlexGrid flexGridColumnCount={3} flexGridColumnGap="scale200" flexGridRowGap="scale200">
      {purchaseOrders.map((purchaseOrder, index) => {
        return (
          <FlexGridItem key={index}>
            <Button
              kind={selected === index ? 'secondary' : 'minimal'}
              overrides={{ BaseButton: { style: { width: '100%' } } }}
              onClick={() => {
                setSelected(() => purchaseOrders.indexOf(purchaseOrder))
                setCheckin({
                  ...checkin,
                  purchaseOrderNumber: purchaseOrders[index].identifier,
                  purchaseOrderId: purchaseOrders[index].id
                })
              }}
            >
              {purchaseOrder.identifier}
            </Button>
          </FlexGridItem>
        )
      })}
      {currentFacility && currentFacility.guestMode && (
        <FlexGridItem>
          <Button
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    backgroundColor: $theme.colors.negative400,
                    width: '100%'
                  }
                }
              }
            }}
            onClick={onClickOther}
          >
            Other
          </Button>
        </FlexGridItem>
      )}
    </FlexGrid>
  )
}

export default PurchaseOrderSearch
