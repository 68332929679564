import { ButtonProps } from 'baseui/button'
import React, { Fragment } from 'react'
import { StatefulTooltip, StatefulTooltipProps } from 'baseui/tooltip'
import Button from 'components/ui/specific/PrimaryButton'

type Props = ButtonProps & {
  label: string
  errors: { label: string; status: boolean }[]
  statefulTooltipProps?: StatefulTooltipProps
}

export const ErrorMessageButton = ({ label, errors, statefulTooltipProps, ...props }: Props) => {
  const errorsPresent = errors.some(error => !error.status)

  return (
    <StatefulTooltip
      triggerType="hover"
      content={
        errorsPresent &&
        errors
          .filter(error => !error.status)
          .map(({ label }, i) => {
            return (
              <Fragment key={i}>
                - {label}
                <br />
              </Fragment>
            )
          })
      }
      placement="right"
      showArrow
      {...statefulTooltipProps}>
      <span>
        <Button disabled={errorsPresent} {...props}>
          {label}
        </Button>
      </span>
    </StatefulTooltip>
  )
}
