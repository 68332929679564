import { HeadingXSmall } from 'baseui/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import VTable from 'components/ui/generic/Table'
import EmptyMessageForTable from '../../components/EmptyMessageForTable'

const ZonesTable = ({ data }) => {
  const { t } = useTranslation()
  const zones = data.reduce((acc, slot) => {
    acc[slot.zone] = (acc[slot.zone] || 0) + 1
    return acc
  }, {})

  return (
    <VTable
      columns={[t('Yard.Table.Columns.Zones.Label.Text'), t('Yard.Table.Columns.Slots.Label.Text')]}
      data={Object.entries(zones)}
      emptyMessage={<EmptyMessageForTable />}
    />
  )
}

export default ZonesTable
