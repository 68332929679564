import React, { useContext, useEffect, useState } from 'react'
import Select from 'components/ui/generic/Select'
import { useDebounce } from 'react-use'
import { facilityService } from '../../services'
import { sortBy } from 'lodash'
import { useTasksContext } from 'components/contexts/tasks.context'
import { CurrentUserContext } from 'components/homepage/current-user-context'

const JBUILDER_TEMPLATE_PREFIX = 'hits'
const TASK_FACILITY_KEY = 'TASKS_FACILITY_KEY'

const YardTaskFacilitySelect = () => {
  const [search, setSearch] = useState<string>('')
  const [options, setOptions] = useState([])
  const [facility, setFacility] = useState([])
  const [hasSetFacilityFirstTime, setHasSetFacilityFirstTime] = useState(false)

  const { currentUser } = useContext(CurrentUserContext)

  const {
    state: { selectedFacility },
    actions: { setSelectedFacility }
  } = useTasksContext()

  useDebounce(
    async () => {
      if (!hasSetFacilityFirstTime) {
        const defaultFacilityByUser = JSON.parse(localStorage.getItem(TASK_FACILITY_KEY) || '{}')

        const defaultFacility = defaultFacilityByUser[currentUser?.id]

        if (defaultFacility) {
          onChange(defaultFacility)
        }

        setHasSetFacilityFirstTime(true)
      }

      const { hits } = await facilityService.findFacilitiesWithoutPagination({
        search,
        filters: [
          {
            appointment_preferences: {
              enable_yard_management: true
            }
          }
        ],
        template: JBUILDER_TEMPLATE_PREFIX
      })
      setOptions(sortBy(hits, 'createdAt'))
    },
    200,
    [search]
  )

  useEffect(() => {
    if (selectedFacility) {
      setFacility(selectedFacility)
    }
  }, [selectedFacility])

  const onChange = (
    value: {
      id: string
      label: string
    }[]
  ) => {
    setSearch('')
    setSelectedFacility(value)
    const defaultFacilityByUser = JSON.parse(localStorage.getItem(TASK_FACILITY_KEY) || '{}')

    localStorage.setItem(
      TASK_FACILITY_KEY,
      JSON.stringify({
        ...defaultFacilityByUser,
        [currentUser.id]: value
      })
    )
  }

  return (
    <Select
      clearable={false}
      backspaceClearsInputValue
      maxDropdownHeight="250px"
      deleteRemoves
      value={facility}
      onChange={({ value }) => {
        onChange(value)
      }}
      placeholder=""
      options={options}
      labelKey="name"
      onInputChange={e => {
        setSearch(e.currentTarget.value)
      }}
      minWidth="135px"
      maxWidth="298px"
    />
  )
}

export default YardTaskFacilitySelect
