import Button from 'components/ui/generic/Button'
import PrimaryButton from 'components/ui/specific/PrimaryButton'
import FormControl from 'components/ui/generic/FormControl'
import Select from 'components/ui/generic/Select'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'

import { SelectSearch } from 'components/components/SelectSearch'
import DeleteButton from 'components/shared/fields/delete-button'
import TwilioPhoneNumber from 'components/shared/fields/twilio-phone-number'
import StyledSpinner from 'components/shared/styled-spinner'
import { User } from 'components/users'
import { fancyToast } from 'components/utils'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import CyberInput from 'cyber/input'
import React, { useEffect, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { useUserRolesContext } from '../../contexts/user-roles.context'
import { DEFAULT_ROLE_NAMES, EXTERNAL } from '../../models/Role'
import CreateOrUpdateVendorModal from '../SchedulerInstantSearch/create-modal'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { validateUser } from 'components/utils/user-validations'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import ErrorButton from 'components/ui/specific/ErrorButton'
import { ConfirmationModal } from 'components/components/ConfirmationModal'

export const SchedulerModal = ({ userId, setUserId, refine }) => {
  const [user, setUser] = useState<User>(null)
  const [createVendorModalOpen, setCreateVendorModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [selectedExternalUserRole, setSelectedExternalUserRole] = useState<any>([])
  const [externalUsersRoles, setExternalUsersRoles] = useState<any>([])
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const { t } = useTranslation()

  const {
    roles: { items },
    actions: { search }
  } = useUserRolesContext()

  useEffect(() => {
    search(undefined, [EXTERNAL])
  }, [])

  useEffect(() => {
    setLoading(true)

    if (userId) {
      authenticatedFetch({ path: `/users/${userId}.json` }).then(([json, status]) => {
        if ([200, 304].includes(status)) {
          setUser(json)
          setLoading(false)
          const role = items?.filter(i => i.id === json.user_role_id)[0]
          if (role) {
            setSelectedExternalUserRole([
              {
                id: role.id,
                label: capitalize(role.name)
              }
            ])
          }
        }
      })
    }
  }, [userId])

  useEffect(() => {
    if (items && items.length) {
      setExternalUsersRoles(
        items.map(item => {
          return {
            label: DEFAULT_ROLE_NAMES.includes(item.name) ? capitalize(item.name) : item.name,
            id: item.id
          }
        })
      )
    }
  }, [items])

  const onClose = () => {
    setUser(null)
    setUserId(null)
    setSelectedExternalUserRole([])
    refine()
  }

  const onRemove = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/old/schedulers/${userId}.json`,
      method: 'DELETE'
    })
    if (status == 204) {
      fancyToast(
        {
          info: t(
            'Settings.VendorCompanyUsers.VendorCompanyUserModal.Alerts.UserAccessRemoved.Text'
          )
        },
        status
      )
      onClose()
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const onInactivate = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/users/${userId}/update_status_user.json`,
      method: 'PATCH',
      body: {
        user: {
          disabled: true
        }
      }
    })
    if (status == 204) {
      fancyToast(
        {
          info: t(
            'Settings.VendorCompanyUsers.VendorCompanyUserModal.Alerts.UserAccessRevoked.Text'
          )
        },
        status
      )
      onClose()
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const onActivate = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/users/${userId}/update_status_user.json`,
      method: 'PATCH',
      body: {
        user: {
          disabled: false
        }
      }
    })
    if (status == 204) {
      fancyToast(
        {
          info: t(
            'Settings.VendorCompanyUsers.VendorCompanyUserModal.Alerts.UserAccessGranted.Text'
          )
        },
        status
      )
      onClose()
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const saveModal = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/users/${user.id}.json`,
      method: 'PATCH',
      body: { user: user }
    })

    setLoading(false)

    if (status === 200) {
      fancyToast(
        { info: t('Settings.VendorCompanyUsers.VendorCompanyUserModal.Alerts.UserUpdated.Text') },
        status
      )
      onClose()
    } else {
      fancyToast(json, status)
    }
  }

  const onChageRole = option => {
    setUser({
      ...user,
      userRoleId: option.id as string,
      user_role_id: option.id as string
    })
    setSelectedExternalUserRole([option])
  }

  const getRoleValue = () =>
    selectedExternalUserRole.length
      ? selectedExternalUserRole
      : [
          {
            id: user?.userRoleId
          }
        ]

  return (
    <>
      <Modal
        isOpen={!!user && !createVendorModalOpen}
        unstable_ModalBackdropScroll
        onClose={onClose}>
        <ModalHeader>
          {t('Settings.VendorCompanyUsers.VendorCompanyUserModal.Header.Text')}
        </ModalHeader>

        <ModalBody>
          {loading ? (
            <StyledSpinner />
          ) : (
            <>
              <FormControl
                label={t(
                  'Settings.VendorCompanyUsers.VendorCompanyUserModal.Fields.Name.Label.Text'
                )}>
                <CyberInput
                  name="user[name]"
                  value={user?.name}
                  disabled={loading}
                  onChange={e => {
                    setUser({
                      ...user,
                      name: e.currentTarget.value
                    })
                  }}
                />
              </FormControl>
              <div style={{ position: 'relative', marginBottom: '16px' }}>
                <FormControl
                  label={t(
                    'Settings.VendorCompanyUsers.VendorCompanyUserModal.Fields.VendorCompany.Label.Text'
                  )}>
                  <CyberAutocomplete record={user} setRecord={setUser} indexName="scheduler" />
                </FormControl>
                <Button
                  onClick={() => {
                    setCreateVendorModalOpen(true)
                  }}
                  size="mini">
                  {t(
                    'Settings.VendorCompanyUsers.VendorCompanyUserModal.Fields.VendorCompany.AlternativesCreate.Text'
                  )}
                </Button>
              </div>
              <FormControl
                label={t(
                  'Settings.VendorCompanyUsers.VendorCompanyUserModal.Fields.EmailAddress.Label.Text'
                )}>
                <CyberInput
                  autoComplete="off"
                  name="user[email_address]"
                  value={user?.email_address}
                  onChange={evt =>
                    setUser({
                      ...user,
                      email_address: evt.currentTarget.value
                    })
                  }
                />
              </FormControl>
              <FormControl
                label={t(
                  'Settings.VendorCompanyUsers.VendorCompanyUserModal.Fields.PhoneNumber.Label.Text'
                )}>
                <TwilioPhoneNumber disabled={loading} record={user} setRecord={setUser} />
              </FormControl>
              <FormControl
                label={t(
                  'Settings.VendorCompanyUsers.VendorCompanyUserModal.Fields.Facility.Label.Text'
                )}>
                <SelectSearch
                  multi
                  record={user}
                  entity="facilities"
                  onValuesChange={ids =>
                    setUser(user => ({
                      ...user,
                      facilities: ids
                    }))
                  }
                />
              </FormControl>
              <FormControl
                label={t(
                  'Settings.VendorCompanyUsers.VendorCompanyUserModal.Fields.Role.Label.Text'
                )}>
                <Select
                  clearable={false}
                  backspaceClearsInputValue
                  maxDropdownHeight="250px"
                  deleteRemoves={true}
                  value={getRoleValue()}
                  options={externalUsersRoles}
                  onChange={({ option }) => onChageRole(option)}
                />
              </FormControl>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <FlexGrid flexGridColumnCount={1}>
            <FlexGridItem display="flex" justifyContent="space-between">
              <ErrorButton
                style={{ marginRight: 'auto' }}
                kind="secondary"
                onClick={() => {
                  setOpenConfirmModal(true)
                }}>
                {t('Settings.VendorCompanyUsers.VendorCompanyUserModal.Buttons.RemoveUser.Text')}
              </ErrorButton>
              {user?.disabled ? (
                <DeleteButton
                  label={t(
                    'Settings.VendorCompanyUsers.VendorCompanyUserModal.Buttons.ActivateUser.Text'
                  )}
                  onDelete={onActivate}
                  buttonProps={{ disabled: loading }}
                />
              ) : (
                <DeleteButton
                  label={t(
                    'Settings.VendorCompanyUsers.VendorCompanyUserModal.Buttons.InactivateUser.Text'
                  )}
                  onDelete={onInactivate}
                  buttonProps={{ disabled: loading }}
                />
              )}{' '}
              <PrimaryButton
                disabled={validateUser(user)}
                kind="primary"
                size="compact"
                isLoading={loading}
                onClick={saveModal}>
                {t('Settings.VendorCompanyUsers.VendorCompanyUserModal.Buttons.Save.Text')}
              </PrimaryButton>
            </FlexGridItem>
          </FlexGrid>
        </ModalFooter>
      </Modal>
      <CreateOrUpdateVendorModal
        isOpen={createVendorModalOpen}
        close={() => setCreateVendorModalOpen(false)}
        getVendor={vendor => {
          setUser({
            ...user,
            schedulerId: vendor?.id
          })
        }}
      />
      <ConfirmationModal
        isOpen={openConfirmModal}
        loading={loading}
        title={t('Settings.VendorCompanyUsers.VendorCompanyUserModal.ConfirmationModal.Title.Text')}
        cancelAction={() => setOpenConfirmModal(false)}
        confirmAction={onRemove}
      />
    </>
  )
}

export default connectSearchBox(SchedulerModal)
