import React from 'react'
import { DEFAULT, DISABLED, ENABLED } from '../../models/Role'
import { Pill } from './UserRolesTable.styled'

const getStatusPills = (enabled: boolean, shipperId: string) => {
  let label = DISABLED
  let color = '#DBE6FF'

  if (!shipperId) {
    label = DEFAULT
    color = '#082766'
  }

  if (shipperId && enabled) {
    label = ENABLED
    color = '#0C8564'
  }

  return <Pill color={color}>{label}</Pill>
}

const TablePill = ({ enabled, shipperId }) => <div>{getStatusPills(enabled, shipperId)}</div>

export default TablePill
