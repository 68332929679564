import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import React from 'react'
import DriversCheckinForm from './DriversCheckinForm'
import DriversCheckinPostForm from './DriversCheckinPostForm'
import DriversCheckinDetailsForm from './DriversCheckinDetailsForm'

const index = ({ match, postCheckin = false, details = false }) => {
  return (
    <>
      <CustomQuestionsProvider>
        {!postCheckin && !details && <DriversCheckinForm match={match} />}
      </CustomQuestionsProvider>
      <CustomQuestionsProvider>
        {postCheckin && <DriversCheckinPostForm match={match} />}
      </CustomQuestionsProvider>
      <CustomQuestionsProvider>
        {details && <DriversCheckinDetailsForm match={match} />}
      </CustomQuestionsProvider>
    </>
  )
}

export default index
