import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { DraggableItem, DraggableItemActive, TypeNameLabel } from './Item.styled'
import { capitalize } from 'lodash'
import { EquipmentType } from 'components/models/DockCapacity'
import { BsPencil } from 'react-icons/bs'
import { GiHamburgerMenu } from 'react-icons/gi'

import { useEquipmentTypesContext } from 'components/contexts/equipment-types.context'
import { List, PencilSimple } from '@phosphor-icons/react'

interface ItemProps {
  equipmentType: EquipmentType
  index: number
  isActive: boolean
}

export const Item: React.FC<ItemProps> = ({ equipmentType, index, isActive }) => {
  const {
    actions: { setSelectedEquipmentType, setFormOpen }
  } = useEquipmentTypesContext()

  const onClick = (equipmentType: EquipmentType) => {
    setSelectedEquipmentType(equipmentType)
    setFormOpen(true)
  }

  const ItemToUse = isActive ? DraggableItemActive : DraggableItem

  return (
    <Draggable draggableId={equipmentType.name} index={index}>
      {provided => (
        <ItemToUse
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <List style={{ marginTop: '-2px' }} />
              {'  '}
              <TypeNameLabel>{capitalize(equipmentType.name)}</TypeNameLabel>
            </div>
            <div>
              {equipmentType.shipperId && <PencilSimple onClick={() => onClick(equipmentType)} />}
            </div>
          </div>
        </ItemToUse>
      )}
    </Draggable>
  )
}
