import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { KIND as ButtonKind, SHAPE as ButtonShape } from "baseui/button";
import { ProgressSteps, Step } from "baseui/progress-steps";
import { fancyToast } from "../../utils";

interface IntegrationModalProps {
  integrationName: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  resolve?: (value?: unknown) => void;
  reject?: (reason?: unknown) => void;
}

const STATUS_MESSAGES = [
  "Connecting...",
  "Sending data...",
  "Waiting for response...",
  "Done!",
];

const IntegrationModal = (props: IntegrationModalProps): JSX.Element => {
  const { integrationName, isOpen, setIsOpen, resolve, reject } = props;
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [resolveTimeout, setResolveTimeout] = useState<NodeJS.Timeout>(null);

  const clearResolveTimeout = useCallback(() => {
    if (resolveTimeout) {
      clearTimeout(resolveTimeout);
      setResolveTimeout(null);
    }
  }, [resolveTimeout]);

  const integrationFailed = () => {
    fancyToast(
      {
        checkin: `with ${integrationName} failed`,
      },
      500
    );
  };

  const close = () => {
    clearResolveTimeout();
    setIsOpen(false);
    setCurrentStep(0);
  };

  const fail = () => {
    clearResolveTimeout();
    setIsOpen(false);
    setCurrentStep(0);
    integrationFailed();
    reject();
  };

  useEffect(() => {
    if (isOpen && currentStep === 0) {
      setIsOpen(false);
      resolve();
      return;
    } else if (!isOpen) {
      clearResolveTimeout();

      setCurrentStep(0);
    }
  }, [clearResolveTimeout, resolve, currentStep, isOpen, setIsOpen]);

  useEffect(() => {
    if (isOpen && currentStep >= STATUS_MESSAGES.length - 1) {
      setResolveTimeout(
        setTimeout(() => {
          setIsOpen(false);
          resolve();
        }, 750 * STATUS_MESSAGES.length)
      );
    } else if (!isOpen) {
      setCurrentStep(0);
    }
  }, [currentStep, resolve, isOpen, setIsOpen]);

  const allSteps = STATUS_MESSAGES.map((value, index) => {
    return <Step title={value} key={index.toString()} />;
  });

  return (
    <Modal isOpen={isOpen} onClose={close} closeable animate autoFocus>
      <ModalHeader>{`Checking in with ${integrationName}`}</ModalHeader>
      <ModalBody>
        <ProgressSteps current={currentStep}>{allSteps}</ProgressSteps>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          kind={ButtonKind.tertiary}
          shape={ButtonShape.round}
          isLoading
        >
          Loading
        </ModalButton>
        <ModalButton kind={ButtonKind.secondary} onClick={fail}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default IntegrationModal;
