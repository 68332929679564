import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { CARRIER } from 'components/models/User'
import { schedulerService } from 'components/services/scheduler.service'
import { fancyToast } from 'components/utils'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import regexValidations from '../../utils/regex-validations'

import { KIND, SIZE } from 'baseui/button'

import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Button from 'components/ui/specific/PrimaryButton'

interface CreateModalProps {
  isOpen: boolean
  close: () => void
  vendor?: any
  getVendor?: (vendor: any) => void
  shipperId?: string
  createdAsOther?: boolean
}

const CreateOrUpdateVendorModal = ({
  isOpen,
  close,
  vendor,
  getVendor,
  shipperId,
  createdAsOther
}: CreateModalProps) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [scheduler, setScheduler] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (vendor) {
      setScheduler({
        name: vendor.name,
        email: vendor.email
      })
    }
  }, [vendor])

  const onClose = () => {
    close()
    setScheduler(undefined)
  }

  const createScheduler = async () => {
    const schedulerData: any = {
      name: scheduler?.name,
      shipperId: currentUser.userType === CARRIER ? shipperId : currentUser.shipperId,
      email: scheduler?.email
    }
    if (createdAsOther) schedulerData.createdAsOther = true

    setLoading(true)
    schedulerService.createScheduler(schedulerData).then(([json, status]) => {
      if (status === 201) {
        fancyToast(
          {
            info: t('Common.Info.Interpolated.Text', {
              model: t('Common.ModelType.Scheduler.Text'),
              action: t('Common.Actions.Created.Text')
            })
          },
          status
        )
        if (getVendor) {
          getVendor(json)
        }
        onClose()
      } else {
        fancyToast(json, status)
      }
      setLoading(false)
    })
  }

  const isEmailValid = () => {
    const email = scheduler?.email?.trim()
    if (!email) {
      return true
    }

    return regexValidations.email.test(email)
  }

  const updateScheduler = async () => {
    setLoading(true)
    schedulerService
      .updateScheduler({
        id: vendor?.id,
        name: scheduler?.name,
        shipperId: currentUser.shipperId,
        email: scheduler?.email
      })
      .then(([json, status]) => {
        if (status === 201) {
          fancyToast(
            {
              info: t('Common.Info.Interpolated.Text', {
                model: t('Common.ModelType.Scheduler.Text'),
                action: t('Common.Actions.Updated.Text')
              })
            },
            status
          )
          onClose()
        } else {
          fancyToast(json, status)
        }
        setLoading(false)
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{`${
        vendor
          ? t('Settings.VendorCompanyUsers.VendorCompanyModal.Header.Alternatives.Update.Text')
          : t('Settings.VendorCompanyUsers.VendorCompanyModal.Header.Alternatives.Create.Text')
      }
         ${t('Settings.VendorCompanyUsers.VendorCompanyModal.Header.Text')}`}</ModalHeader>
      <ModalBody>
        <FormControl
          label={t(
            'Settings.VendorCompanyUsers.VendorCompanyModal.Fields.VendorCompanyName.Label.Text'
          )}>
          <Input
            autoComplete="off"
            name="scheduler[name]"
            value={scheduler?.name}
            onChange={e => {
              setScheduler({
                ...scheduler,
                name: e.currentTarget.value
              })
            }}
          />
        </FormControl>
        <FormControl
          label={t(
            'Settings.VendorCompanyUsers.VendorCompanyModal.Fields.VendorCompanyName.Label.Email'
          )}>
          <Input
            autoComplete="off"
            name="scheduler[email]"
            value={scheduler?.email}
            onChange={e => {
              setScheduler({
                ...scheduler,
                email: e.currentTarget.value
              })
            }}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={KIND.tertiary} onClick={onClose} size={SIZE.compact}>
          {t('Orders.CreateEditOrderModal.Buttons.Cancel.Text')}
        </ModalButton>
        <Button
          disabled={!scheduler?.name || !isEmailValid()}
          isLoading={loading}
          onClick={vendor ? updateScheduler : createScheduler}>
          {vendor
            ? t('Settings.VendorCompanyUsers.VendorCompanyModal.UpdateButton.Text')
            : t('Settings.VendorCompanyUsers.VendorCompanyModal.CreateButton.Text')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateOrUpdateVendorModal
