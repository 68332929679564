import styled from 'styled-components'
import { primitives } from '../../../homepage/custom-theme'

export const searchContainerProps = {
  overrides: {
    Block: {
      style: {
        backgroundColor: 'inherit',
        margin: '0px',
        border: '0px'
      }
    }
  }
}

export const searchInputProps = {
  overrides: {
    InputContainer: {
      style: () => ({
        backgroundColor: 'transparent',
        marginTop: '7px'
      })
    },
    Root: {
      style: ({ $theme }) => ({
        backgroundColor: $theme.colors.white,
        height: '50px'
      })
    },
    EndEnhancer: {
      style: ({ $theme }) => ({
        opacity: '0.6',
        backgroundColor: 'transparent'
      })
    }
  }
}

export const notFoundLabelProps = {
  overrides: {
    Block: {
      style: () => ({
        color: '#6f7480'
      })
    }
  }
}

export const ErrorCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  align-items: center;
`

export const PreAndPostCheckinMessage = styled.div`
  font-family: ${primitives.primaryFontFamily};
  color: ${primitives.primary};
`
