import { styled } from 'baseui'

export const ContentWrapper = styled('div', ({ $theme }) => ({
  marginBottom: $theme.sizing.scale300,
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.scale300,
  overflow: 'hidden'
}))

export const Header = styled('div', ({ $theme }) => ({
  fontSize: '1rem',
  display: 'flex',
  gap: $theme.sizing.scale300,
  alignItems: 'center'
}))

export const InformationContainer = styled('div', ({ $theme }) => ({
  ddisplay: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.scale100,
  fontSize: $theme.typography.LabelXSmall.fontSize
}))

export const InformationItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between'
})

export const InformationItemCapitalized = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  textTransform: 'capitalize'
})
