import React from 'react'
import { Block } from 'baseui/block'
import { useTranslation } from 'react-i18next'
import Header from 'components/ui/generic/Header'
import { UserRolesProvider } from '../../../components/contexts/user-roles.context'
import { UserRolesInputSearch } from './InputSearchBar'
import { UserRolesTable } from './UserRolesTable'

const UserRoles = () => {
  const { t } = useTranslation()

  return (
    <UserRolesProvider>
      <Header
        title={t('Settings.SideMenu.Titles.UserRoles.Text')}
        rightItems={[<UserRolesInputSearch />]}
      />

      <Block paddingTop="scale200" display="flex" flexDirection="row">
        <Block marginLeft="scale200" width="100%">
          <Block paddingBottom="scale200" minHeight="60vh">
            <UserRolesTable />
          </Block>
        </Block>
      </Block>
    </UserRolesProvider>
  )
}

export default UserRoles
