import React, { useContext, useEffect, useMemo } from 'react'
import Button from 'components/ui/generic/Button'
import { useTranslation } from 'react-i18next'
import { useYardJockeyContext } from '../../contexts/yard-jockey.context'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { checkIfUserShiftIsActive } from '../../utils/check-if-user-shift-is-active'
import { KIND, SIZE } from 'baseui/button'

const YardJockeyShiftButton = () => {
  const { t } = useTranslation()
  const { currentUser } = useContext(CurrentUserContext)

  const {
    shiftButtonLoading,
    actions: { setShiftActive, startOrEndShift }
  } = useYardJockeyContext()

  useEffect(() => {
    setShiftActive(activeShift)
  }, [])

  const activeShift = useMemo(() => {
    return checkIfUserShiftIsActive(currentUser)
  }, [currentUser.lastShiftDateLog])

  const updateShift = () => {
    startOrEndShift(!activeShift)
  }

  return (
    <Button
      onClick={updateShift}
      isLoading={shiftButtonLoading}
      kind={KIND.secondary}
      size={SIZE.large}>
      {activeShift
        ? t('YardJockey.Header.EndShiftButton.Text')
        : t('YardJockey.Header.StartShiftButton.Text')}
    </Button>
  )
}

export default YardJockeyShiftButton
