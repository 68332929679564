import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import { AppointmentRequest, Question } from 'components/appointments/types'
import { fancyToast } from 'components/utils'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import CustomLabelFormControl from 'components/shipper/instant-calendar/modals/custom-label-form-control'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import ArrivalTime from './fields/arrival-time'
import PurchaseOrderIdentifiers from './fields/purchase-order-identifiers'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import { find } from 'lodash'
import Answers from './fields/answers'
import ErrorMessageButton from 'components/shared/error-message-button'
import { CustomLabelsContext } from 'components/contexts/custom-labels-context'
import { FacilitiesContext } from '../context/facilities-context'
import EquipmentType from 'components/components/EquipmentType'
import { trim } from 'lodash'
import RecurringFields from './fields/recurring-fields'
import { recurringAppointmentBlueprintService } from 'components/services/recurring-appointment-blueprint.service'
import { DateTime } from 'luxon'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { appointmentService } from 'components/services/appointment.service'
import { appointmentTypeService } from 'components/services/appointment-type.service'
import { useCalendarSelectedOptionsContext } from '../../../contexts/calendar-selected-options.context'
import { questionService } from '../../../services/question.service'
import { INTERNAL } from '../../../models/Role'
import { checkFileQuestionError, checkQuestionError } from '../../../utils/check-question-error'
import { DOCUMENT_TYPE } from '../../../models/Question'
import { DEFAULT_SHIPPER_PERMISSION } from '../../../constants/default-permissions'
import { useTranslation } from 'react-i18next'
import Select from 'components/ui/generic/Select'
import { FormControl } from 'baseui/form-control'
import { useDockAssigmentContext } from '../../../contexts/dock-assigment.context'
import i18n from 'translations/i18n'

export const APPOINTMENT_TYPE_ALL_OPTION = {
  name: i18n.t('Common.AppointmentTypeToggle.AllOption.Text'),
  id: 0,
  customLabels: {
    purchaseOrderIdentifiers: null,
    purchaseOrderIdentifiersCaption: null,
    scheduler: null,
    schedulerCaption: null
  }
}

export const UnconnectedCreateAppointmentModal = (
  props: ModalProps & { items: { attribute: string; currentRefinement: any }[] }
) => {
  const { selectedEvent, isOpen, close, items } = props
  const { setCustomLabels, customLabels } = useContext(CustomLabelsContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [recurring, setRecurring] = useState<boolean>(false)
  const [questions, setQuestions] = useState<Question[]>([])
  const [appointmentTypes, setAppointmentTypes] = useState<any[]>([])
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<any>()
  const { calendarSelectedOptionsState } = useCalendarSelectedOptionsContext()
  const { facilities } = useContext(FacilitiesContext)
  const { selectedFacility } = useDockAssigmentContext()
  const { facilityId } = selectedEvent
  const { t } = useTranslation()
  const createdAppointmentMessage = {
    info: t('Common.Info.Interpolated.Text', {
      model: t('Common.ModelType.Appointment.Text'),
      action: t('Common.Actions.Created.Text')
    })
  }
  const allOptionSelectedForAppType =
    APPOINTMENT_TYPE_ALL_OPTION.name === calendarSelectedOptionsState.appType[0]?.name

  let dockCapacities = []
  if ((facilities && facilityId) || selectedFacility) {
    dockCapacities =
      facilities[facilityId]?.appointmentPreference?.dockCapacitiesAttributes ||
      selectedFacility?.appointmentPreference?.dockCapacitiesAttributes
  }

  const [appointmentRequest, setAppointmentRequest] = useState<AppointmentRequest>({
    facilityId:
      selectedEvent.facilityId || find(items, ['attribute', 'facility_id'])?.currentRefinement[0],
    arrivalTime: moment(selectedEvent.arrivalTime).toDate(),
    purchaseOrdersAttributes: [],
    appointmentTypeId:
      items[0]?.currentRefinement?.length > 1
        ? null
        : find(items, ['attribute', 'appointment_type_id'])?.currentRefinement[0],
    answersAttributes: [],
    equipmentTypeId: dockCapacities?.map(dockCapacity => dockCapacity.equipmentTypeId)[0],
    recurringDays: [],
    schedule: undefined
  })

  const { currentUser } = useContext(CurrentUserContext)

  const {
    answers,
    temporalFiles,
    settings,
    actions: { setQuestions: setCtxQuestions, setAnswers, updateFileUploadState }
  } = useCQStateValue()

  const shouldValidate = useMemo(
    () => currentUser?.userRole?.shipperId && currentUser?.userRole?.audience === INTERNAL,
    [currentUser]
  )

  const setAllValuesRelatedToQuestions = (
    questions,
    appTypeId = appointmentRequest?.appointmentTypeId
  ) => {
    const tempQuestions = questions.filter(question => !question.disabled)
    setQuestions(tempQuestions)
    setAppointmentRequest({
      ...appointmentRequest,
      appointmentTypeId: appTypeId,
      answersAttributes: tempQuestions.map((question: Question) => ({
        response: '',
        questionId: question.id
      }))
    })
    setCtxQuestions(tempQuestions)
    setAnswers(
      tempQuestions.map((question: Question) => ({
        response: '',
        questionId: question.id,
        error:
          question.answerType !== DOCUMENT_TYPE &&
          checkQuestionError(
            question,
            question.questionPermissionsAttributes?.filter(
              qpa => qpa.userRoleId === currentUser?.userRoleId
            )[0] || DEFAULT_SHIPPER_PERMISSION,
            { response: '' }
          )
      }))
    )
  }

  useEffect(() => {
    const schedule = appointmentRequest.schedule || {
      rrules: [
        {
          until: undefined,
          interval: 1,
          rule_type: 'IceCube::WeeklyRule',
          week_start: 0,
          validations: {
            day: []
          }
        }
      ],
      rtimes: [],
      extimes: [],
      start_time: undefined
    }

    if (recurring) {
      schedule.start_time = DateTime.fromJSDate(appointmentRequest.arrivalTime).toISO()
      schedule.rrules[0].until =
        appointmentRequest.endDate &&
        DateTime.fromJSDate(appointmentRequest.endDate)
          .set({ hour: 23, minutes: 59, seconds: 59 })
          .toISO()
      schedule.rrules[0].validations.day = appointmentRequest.recurringDays

      setAppointmentRequest({
        ...appointmentRequest,
        schedule
      })
    }
  }, [appointmentRequest.arrivalTime, appointmentRequest.endDate, appointmentRequest.recurringDays])

  useEffect(() => {
    if (allOptionSelectedForAppType) {
      appointmentTypeService
        .getAppointmentTypes()
        .then(appointmentTypes => setAppointmentTypes(appointmentTypes))
    } else {
      questionService
        .listQuestions(appointmentRequest?.appointmentTypeId)
        .then(([data, status]) => {
          if ([304, 200].includes(status)) {
            setAllValuesRelatedToQuestions(data)
          }
        })
    }
  }, [])

  const formErrors = (): { label: string; status: boolean }[] => {
    const errors = [
      {
        label: `${customLabels.purchaseOrderIdentifiers} must be present.`,
        status: appointmentRequest.purchaseOrdersAttributes.length > 0 || recurring
      },
      {
        label: 'Appointment type must be selected.',
        status: !!appointmentRequest.appointmentTypeId
      },
      {
        label: 'Facility must be selected.',
        status: !!appointmentRequest.facilityId
      },
      {
        label: 'Arrival time must be set.',
        status: !!appointmentRequest.arrivalTime
      },
      {
        label: `${customLabels.scheduler} must be selected.`,
        status: !!appointmentRequest.schedulerId || recurring
      },
      {
        label: 'Equipment type must be selected.',
        status: !!appointmentRequest.equipmentTypeId
      }
    ]

    if (shouldValidate) {
      errors.push({
        label: 'Some custom questions have validation errors',
        status: !(answers.filter(a => a.error).length > 0)
      })

      const fileTypeAnswers = questions.filter(q => q.answerType === DOCUMENT_TYPE)

      if (fileTypeAnswers.length > 0) {
        errors.push({
          label: 'Some file(s) are required',
          status: !fileTypeAnswers.every(q =>
            checkFileQuestionError(
              q,
              q.questionPermissionsAttributes?.filter(
                qpa => qpa.userRoleId === currentUser?.userRoleId
              )[0] || DEFAULT_SHIPPER_PERMISSION,
              temporalFiles,
              [],
              [],
              undefined
            )
          )
        })
      }
    }

    return errors
  }

  const createAppointment = async () => {
    setLoading(true)
    const { recurringDays, ...appointmentWithoutRecurring } = appointmentRequest
    const body = {
      appointment: {
        ...appointmentWithoutRecurring,
        ...(answers && { answersAttributes: answers }),
        purchaseOrdersAttributes: appointmentRequest.purchaseOrdersAttributes.map(
          (order: { identifier: string; id: string }) => ({
            ...order,
            identifier: trim(order.identifier)
          })
        ),
        customerName: currentUser.userType
      }
    }

    const [json, status] = await appointmentService.create(body)
    setLoading(false)
    if (status === 201) {
      fancyToast(createdAppointmentMessage, status)
      close()
    } else {
      fancyToast(json, status)
    }
  }

  const createRecurringAppointment = async () => {
    setLoading(true)

    const [json, status] = await recurringAppointmentBlueprintService.create({
      ...appointmentRequest,
      ...(answers && { answersAttributes: answers }),
      purchaseOrdersAttributes: appointmentRequest.purchaseOrdersAttributes.map(
        (order: { identifier: string; id: string }) => ({
          ...order,
          identifier: trim(order.identifier)
        })
      ),
      startDate: DateTime.fromJSDate(appointmentRequest.arrivalTime).toISO(),
      endDate:
        appointmentRequest.endDate &&
        DateTime.fromJSDate(appointmentRequest.endDate)
          .set({ hour: 23, minutes: 59, seconds: 59 })
          .toISO(),
      arrivalTime: undefined,
      recurringDays: undefined,
      commodityType: 'MISC',
      equipmentTypeId: appointmentRequest.equipmentTypeId
    })
    setLoading(false)

    if (status === 201) {
      fancyToast(createdAppointmentMessage, status)
      close()
    } else {
      fancyToast({ error: `${json?.message}` }, status)
    }
  }

  const createAppointmentType = () => {
    if (temporalFiles.length && !settings.startFileUpload) {
      return updateFileUploadState(true)
    }
    return recurring ? createRecurringAppointment() : createAppointment()
  }

  const handleAppointmentTypeChange = ({ option, value }) => {
    setSelectedAppointmentType(value)
    setAllValuesRelatedToQuestions(value[0].questions, option?.id)
    setCustomLabels(value[0].customLabels)
  }

  useEffect(() => {
    if (temporalFiles.length && temporalFiles.every(tf => tf?.uploadState === 'saved')) {
      if (recurring) {
        createRecurringAppointment()
      }
      if (!recurring) {
        createAppointment()
      }
    }
  }, [answers, temporalFiles])

  return (
    <Modal
      unstable_ModalBackdropScroll
      onClose={close}
      isOpen={isOpen}
      overrides={{
        Close: {
          style: ({ $theme }) => ({
            right: $theme.sizing.scale800,
            top: $theme.sizing.scale800
          })
        }
      }}>
      <ModalHeader>{t('HeaderNavigation.NavBarLinks.CreateAppointment.Text')}</ModalHeader>
      <ModalBody>
        <FlexGrid>
          {allOptionSelectedForAppType ? (
            <FlexGridItem>
              <CustomLabelFormControl customLabelKey="appTypes">
                <Select
                  options={appointmentTypes.map(appType => {
                    return { ...appType, label: appType.name }
                  })}
                  value={selectedAppointmentType}
                  placeholder="Select appointment type"
                  onChange={handleAppointmentTypeChange}
                  clearable={false}
                />
              </CustomLabelFormControl>
            </FlexGridItem>
          ) : (
            ''
          )}
          <FlexGridItem>
            <CustomLabelFormControl customLabelKey="purchaseOrderIdentifiers" recurring={recurring}>
              <PurchaseOrderIdentifiers
                record={appointmentRequest}
                setRecord={setAppointmentRequest}
              />
            </CustomLabelFormControl>
          </FlexGridItem>
          <FlexGridItem>
            <CustomLabelFormControl customLabelKey="scheduler" recurring={recurring}>
              <CyberAutocomplete
                record={appointmentRequest}
                setRecord={setAppointmentRequest}
                indexName="scheduler"
              />
            </CustomLabelFormControl>
          </FlexGridItem>
          <FlexGridItem>
            <FormControl label={t('Appointments.EditAppointmentModal.Fields.Carrier.Label.Text')}>
              <CyberAutocomplete
                record={appointmentRequest}
                setRecord={setAppointmentRequest}
                indexName="carrier"
                placeholder={t('Appointments.EditAppointmentModal.Fields.Carrier.Placeholder.Text')}
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <ArrivalTime record={appointmentRequest} setRecord={setAppointmentRequest} />
          </FlexGridItem>
          <FlexGridItem>
            <EquipmentType
              record={appointmentRequest}
              setRecord={setAppointmentRequest}
              dockCapacitiesAttributes={dockCapacities}
              label="Equipment Type"
              recordAttributeName="equipmentTypeId"
            />
          </FlexGridItem>
          <FlexGridItem>
            <RecurringFields
              record={appointmentRequest}
              setRecord={setAppointmentRequest}
              recurring={recurring}
              setRecurring={setRecurring}
            />
          </FlexGridItem>
          {appointmentRequest?.equipmentTypeId && (
            <Answers
              questions={questions.map(q => ({
                ...q,
                permission:
                  q.questionPermissionsAttributes.filter(
                    qpa => qpa.userRoleId === currentUser.userRoleId
                  )[0] || DEFAULT_SHIPPER_PERMISSION
              }))}
              record={appointmentRequest}
              setRecord={setAppointmentRequest}
              shouldValidate={shouldValidate}
            />
          )}
        </FlexGrid>
      </ModalBody>
      <ModalFooter>
        <ErrorMessageButton
          label="Create"
          errors={formErrors()}
          buttonProps={{
            isLoading: loading,
            onClick: createAppointmentType
          }}
        />
      </ModalFooter>
    </Modal>
  )
}

const CreateAppointmentModal = connectCurrentRefinements(UnconnectedCreateAppointmentModal as any)

export default CreateAppointmentModal
