// custom-questions.context.tsx

import React, { createContext, useContext, useReducer, ReactNode } from 'react'
import customQuestionsReducers from '../reducers/customQuestions.reducer'
import { Question } from '../models/Question'
import { Answer } from 'components/components/Questions/Questions.types'

interface AnswerAttrContextProps {
  temporalFiles: Array<any>
  documentSelections: Array<any>
  answers: Array<Answer>
  questions: Array<Question>
  settings: {
    startFileUpload: boolean
  }
  isDisabled: boolean
}

const initialState: AnswerAttrContextProps = {
  temporalFiles: [],
  documentSelections: [],
  answers: [],
  questions: [],
  settings: {
    startFileUpload: false
  },
  isDisabled: false
}

const CustomQuestionsContext = createContext<any | undefined>(undefined)

export const CustomQuestionsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(customQuestionsReducers, initialState)

  const setAnswers = (answers: Answer[]) => {
    dispatch({ type: 'SET_ANSWERS', payload: answers })
  }

  const updateAnswer = (answer: Answer) => {
    dispatch({ type: 'UPDATE_ANSWERS', payload: answer })
  }

  const setQuestions = (questions: Question[]) => {
    dispatch({ type: 'SET_QUESTIONS', payload: questions })
  }

  const updateQuestion = (question: Question) => {
    dispatch({ type: 'UPDATE_QUESTION', payload: question })
  }

  const loadTemporalFiles = (questionId: any, files: any, isHidden?: boolean) => {
    dispatch({
      type: 'SET_TEMPORAL_FILES',
      payload: {
        questionId,
        files,
        isHidden
      }
    })
  }

  const updateTemporalFiles = (questionId: any) => {
    dispatch({
      type: 'UPDATE_TEMPORAL_FILES_STATE',
      payload: {
        questionId
      }
    })
  }

  const removeTemporalFile = (questionId: any, id: any) => {
    dispatch({
      type: 'REMOVE_TEMPORAL_FILES',
      payload: { questionId, fileId: id }
    })
  }

  const resetTemporalFilesState = () => {
    dispatch({
      type: 'RESET_TEMPORAL_FILES',
      payload: true
    })
  }

  const addFileSelection = (documentId: any, questionId: any) => {
    dispatch({
      type: 'ADD_FILE_SELECTION',
      payload: { documentId, questionId }
    })
  }

  const updateFileUploadState = (value: any) => {
    dispatch({
      type: 'FILE_UPLOAD_STATE',
      payload: value
    })
  }

  const actions = {
    setAnswers,
    updateAnswer,
    setQuestions,
    updateQuestion,
    loadTemporalFiles,
    updateTemporalFiles,
    removeTemporalFile,
    resetTemporalFilesState,
    updateFileUploadState,
    addFileSelection
  }

  return (
    <CustomQuestionsContext.Provider value={{ ...state, actions }}>
      {children}
    </CustomQuestionsContext.Provider>
  )
}

export const useCQStateValue = () => useContext(CustomQuestionsContext)
