import React from 'react'

export const createContext: () => [() => any, React.Provider<any>] = () => {
  const context = React.createContext(undefined)
  const useContext = () => {
    const contextIsDefined = React.useContext(context)
    if (!contextIsDefined) throw new Error('useContext must be used within a Provider')
    return contextIsDefined
  }
  return [useContext, context.Provider]
}
