import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import React, { useState } from "react";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Button } from "baseui/button";
import authenticatedFetch from "../../utils/authenticated-fetch";
import { fancyToast } from "../../utils";

const WarehouseCodeModal = ({ shipperId, setShipperId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [warehouseCode, setWarehouseCode] = useState<string>("");

  const findByWarehouseCode = async () => {
    setLoading(true);
    const [json, status] = await authenticatedFetch({
      path: `/shippers/lookup_by_warehouse_code.json?warehouse_code=${warehouseCode}`,
    });

    setLoading(false);

    if (status == 200) {
      setShipperId(json.id);
    } else {
      setWarehouseCode("");
      fancyToast({ info: "Unable to find warehouse." }, status);
    }
  };

  return (
    <Modal isOpen={!shipperId} closeable={false}>
      <ModalHeader>Calendar</ModalHeader>
      <ModalBody>
        <FormControl
          label="Warehouse Code"
          caption="This is the unique identifier for your warehouse."
        >
          <Input
            name="shipper[warehouse_code]"
            value={warehouseCode}
            onChange={(e) => {
              setWarehouseCode(e.currentTarget.value);
            }}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!warehouseCode}
          isLoading={loading}
          onClick={findByWarehouseCode}
        >
          Find
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WarehouseCodeModal;
