import React, { useContext } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { useTranslation } from 'react-i18next'
import FormControl from 'components/ui/generic/FormControl'
import { CurrentUserContext } from '../../homepage/current-user-context'
import NotificationOption from './notification-option'
import FirebaseMessagingToggle from './firebase-messaging-toggle'

const NotificationTypesForm = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation()

  if (!currentUser || !currentUser.notificationPreferenceAttributes) {
    return null
  }

  return (
    <FlexGridItem>
      <FormControl label={t('Settings.UserProfile.Form.Fields.NotificationPreferences.Label.Text')}>
        <FlexGrid flexGridColumnCount={2} flexGridRowGap="scale400" flexGridColumnGap="scale400">
          <FlexGridItem>
            <NotificationOption
              title={t('Settings.UserProfile.Form.Fields.Emails.Label.Text')}
              preference="emails"
            />
          </FlexGridItem>
          <FlexGridItem>
            <FirebaseMessagingToggle />
          </FlexGridItem>
        </FlexGrid>
      </FormControl>
    </FlexGridItem>
  )
}

export default NotificationTypesForm
