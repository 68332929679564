import { ChevronDown, ChevronUp } from 'baseui/icon'
import React, { useState } from 'react'
import Tag from 'components/ui/specific/Tag'
import { PanelTitle, SecondAction } from './custom-panel.styled'
import { StatefulPanel } from 'baseui/accordion'
import { PanelProps } from './Panel.styled'

const CustomPanel = ({ children, ...props }) => {
  const { title, tag, initialState } = props
  const [expanded, setExpanded] = useState(initialState?.expanded || false)
  return (
    <StatefulPanel
      {...PanelProps}
      {...props}
      onChange={e => setExpanded(e?.expanded)}
      title={
        <PanelTitle>
          <span>
            <small>{title}</small>
          </span>
          <SecondAction>
            {tag && <Tag type="secondary">{tag}</Tag>}
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </SecondAction>
        </PanelTitle>
      }>
      {children}
    </StatefulPanel>
  )
}

export default CustomPanel
