import React from 'react'
import { Tag, KIND, VARIANT } from 'baseui/tag'

const EventTag = ({ children, kind, variant, overrides, ...props }) => {
  return (
    <Tag
      closeable={false}
      kind={kind || KIND.accent}
      variant={variant || VARIANT.light}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            backgroundColor: kind === 'accent' ? $theme.colors.gray : '',
            borderTopColor: $theme.colors.transparent,
            borderBottomColor: $theme.colors.transparent,
            borderRightColor: $theme.colors.transparent,
            borderLeftColor: $theme.colors.transparent,
            paddingTop: $theme.sizing.scale0,
            paddingBottom: $theme.sizing.scale0,
            paddingRight: $theme.sizing.scale0,
            paddingLeft: $theme.sizing.scale0,
            marginTop: $theme.sizing.scale0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            borderTopLeftRadius: $theme.borders.radius50,
            borderTopRightRadius: $theme.borders.radius50,
            borderBottomLeftRadius: $theme.borders.radius50,
            borderBottomRightRadius: $theme.borders.radius50
          })
        },
        StartEnhancerContainer: {
          style: ({ $theme }) => ({
            paddingRight: $theme.sizing.scale100
          })
        }
      }}
      {...props}>
      {children}
    </Tag>
  )
}

export default EventTag
