import React, { useEffect } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'

import { useTranslation } from 'react-i18next'
import Accordion from 'components/ui/generic/Accordion'
import CheckboxItem from 'components/ui/generic/CheckboxItem'

const RefinementList = props => {
  const { items, refine, attribute, setAppliedFilters } = props
  const { t } = useTranslation()
  const refined = items.some(({ isRefined }) => isRefined)
  const VENDOR = t('Settings.VendorCompanyUsers.Label.VendorCompanyName.Text')
  const ROLE = t('Settings.VendorCompanyUsers.Label.Role.Text')
  const ATTRIBUTE = 'scheduler_name'

  useEffect(() => {
    if (refined && setAppliedFilters) {
      setAppliedFilters(c => ({
        ...c,
        [attribute]: items.filter(item => item.isRefined).map(item => item.label)
      }))
    } else if (setAppliedFilters) {
      setAppliedFilters(c => {
        const updatedFilters = { ...c }
        delete updatedFilters[attribute]
        return { ...updatedFilters }
      })
    }
  }, [refined, setAppliedFilters, attribute, items])

  const label = attribute === ATTRIBUTE ? VENDOR : ROLE
  return (
    <Accordion title={label}>
      {items
        .filter(item => item.label)
        .map((item, i) => {
          return <CheckboxItem {...item} refine={refine} key={i} doNotCapitalize={label === ROLE} />
        })}
    </Accordion>
  )
}

const ConnectedRefinementList = connectRefinementList(RefinementList)

export default ConnectedRefinementList as any
