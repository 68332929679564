import React, { useContext } from 'react'
import { DROP, LIVE } from 'components/constants/handling_method'
import { Truck, BatteryFull } from '@phosphor-icons/react'
import { upperFirst } from 'lodash'
import { Facility } from 'components/models/Facility'

export const handlingMethodButtons = (facillity: Facility) => {
  const isYardActive = facillity?.appointmentPreference?.enableYardManagement

  const options = [
    {
      label: upperFirst(LIVE),
      value: LIVE,
      icon: <Truck size={16} />
    }
  ]

  if (isYardActive) {
    options.push({
      label: upperFirst(DROP),
      value: DROP,
      icon: <BatteryFull size={16} />
    })
  }

  return options
}
