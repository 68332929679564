import React, { useContext, useEffect, useState } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { CyberTable } from 'cyber/table'
import CopyButton from 'components/shared/fields/copy-button'
import { useStyletron } from 'baseui'
import { ParagraphLarge } from 'baseui/typography'
import { FileSearch } from '@phosphor-icons/react'
import ShipperModal from './shipper-modal'
import { StyledLink } from 'baseui/link'
import hitTransformer from 'cyber/search/hit-transformer'
import { capitalize } from 'lodash'
import { DEFAULT_ROLE_NAMES } from '../../models/Role'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { useTranslation } from 'react-i18next'
import StyledSpinner from '../../shared/styled-spinner'
import { MILISECONDS_FOR_SPINNER_IN_SETTINGS } from '../../constants/miliseconds-for-spinner-in-settings'

export const Hits = ({ hits = [], setNumberOfHits }) => {
  const [userId, setUserId] = useState<string>(null)
  const [loading, setLoading] = useState(true)
  const [css, theme] = useStyletron()
  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation()
  const COLUMNS = [
    t('Settings.InternalUsers.Table.Columns.Name.Label.Text'),
    t('Settings.InternalUsers.Table.Columns.EmailAddress.Label.Text'),
    t('Settings.InternalUsers.Table.Columns.PhoneNumber.Label.Text'),
    t('Settings.InternalUsers.Table.Columns.Facility.Label.Text'),
    t('Settings.InternalUsers.Table.Columns.Role.Label.Text'),
    t('Settings.InternalUsers.Table.Columns.Admin.Label.Text'),
    t('Settings.InternalUsers.Table.Columns.Active.Label.Text'),
    t('Settings.InternalUsers.Table.Columns.Invite.Label.Text')
  ]

  useEffect(() => {
    if (setNumberOfHits) {
      setNumberOfHits(hits.length)
    }
    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, MILISECONDS_FOR_SPINNER_IN_SETTINGS)

    return () => clearTimeout(timeoutId)
  }, [hits, setNumberOfHits])

  return (
    <>
      {userId && <ShipperModal userId={userId} setUserId={setUserId} />}
      {loading ? (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: theme.sizing.scale1600
          })}>
          <StyledSpinner />
        </div>
      ) : hits.length === 0 ? (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: theme.sizing.scale1600
          })}>
          <FileSearch size={theme.sizing.scale2400} />
          <ParagraphLarge>
            {t('Settings.InternalUsers.Table.NoShipperUsersFound.Text')}
          </ParagraphLarge>
        </div>
      ) : (
        <CyberTable
          columns={COLUMNS}
          data={hits.map(hitTransformer).map(hit => {
            const {
              id,
              name,
              emailAddress,
              phoneNumber,
              facilities,
              inviteId,
              admin,
              disabled,
              userRoleName
            } = hit as any
            return [
              currentUser?.admin ? (
                <StyledLink
                  className={css({ cursor: 'pointer', textDecoration: 'none !important' })}
                  onClick={() => setUserId(id)}>
                  {name}
                </StyledLink>
              ) : (
                name
              ),
              emailAddress,
              phoneNumber,
              (facilities || []).map(facility => facility.name).join(', '),
              DEFAULT_ROLE_NAMES.includes(userRoleName) ? capitalize(userRoleName) : userRoleName,
              admin
                ? t('Settings.InternalUsers.Table.Columns.Admin.Alternatives.Admin.Text')
                : t('Settings.InternalUsers.Table.Columns.Admin.Alternatives.NonAdmin.Text'),
              disabled
                ? t('Settings.InternalUsers.Table.Columns.Active.Alternatives.Inactive.Text')
                : t('Settings.InternalUsers.Table.Columns.Active.Alternatives.Active.Text'),
              inviteId && (
                <CopyButton
                  label={t('Settings.InternalUsers.Buttons.CopyButton.Text')}
                  href={`${window.location.origin}/invites/${inviteId}`}
                />
              )
            ]
          })}
        />
      )}
    </>
  )
}

const ConnectedHits = connectHits(Hits as any)

export default ConnectedHits as any
