import { TasksContextProps } from 'components/contexts/tasks.context'

export const SET_TASKS = 'SET_TASKS'
export const SET_LOADING = 'SET_LOADING'
export const SET_TASK = 'SET_TASK'
export const SET_DATE = 'SET_DATE'
export const SET_MODAL_LOADING = 'SET_MODAL_LOADING'
export const SET_MODAL = 'SET_MODAL'
export const SET_VIEW = 'SET_VIEW'
export const SET_CONFIRMATION_MODAL = 'SET_CONFIRMATION_MODAL'
export const SET_KANBAN_TASKS = 'SET_KANBAN_TASKS'
export const SET_CONFIRMATION_LOADING = 'SET_CONFIRMATION_LOADING'
export const SET_TASK_MODAL = 'SET_TASK_MODAL'
export const SET_FACILITY = 'SET_FACILITY'
export const SET_TRAILER_DETAILS = 'SET_TRAILER_DETAILS'

export const tasksReducer = (state: TasksContextProps, action) => {
  switch (action.type) {
    case SET_FACILITY: {
      return {
        ...state,
        selectedFacility: action.payload
      }
    }

    case SET_TASKS: {
      return {
        ...state,
        tasks: action.payload
      }
    }

    case SET_DATE: {
      return {
        ...state,
        selectedDate: action.payload
      }
    }

    case SET_LOADING: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: action.payload
        }
      }
    }

    case SET_VIEW: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          view: action.payload,
          results: [],
          events: [],
          total: 0
        }
      }
    }

    case SET_KANBAN_TASKS: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          events: action.payload
        }
      }
    }

    case SET_CONFIRMATION_MODAL: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          confirmationModal: {
            ...state.tasks.confirmationModal,
            ...action.payload
          }
        }
      }
    }
    case SET_CONFIRMATION_LOADING: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          confirmationModal: {
            ...state.tasks.confirmationModal,
            loading: action.payload
          }
        }
      }
    }

    case SET_TASK: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          task: action.payload
        }
      }
    }

    case SET_MODAL_LOADING: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          loading: action.payload
        }
      }
    }

    case SET_MODAL: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          isOpen: action.payload
        }
      }
    }

    case SET_TASK_MODAL: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          ...action.payload
        }
      }
    }

    case SET_TRAILER_DETAILS: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          trailer: {
            ...state.taskModal.trailer,
            ...action.payload
          }
        }
      }
    }

    default:
      return state
  }
}

export default tasksReducer
