import { DockType } from 'components/docks/types'
import http from 'components/utils/authenticated-fetch'

class DocksService {
  searchDocks = (search: string): Promise<any> =>
    http({
      path: `/search.json`,
      method: 'POST',
      body: {
        search: {
          indexName: 'Dock',
          params: {
            query: search
          }
        }
      }
    }).then(([{ hits }, status]) => [hits, status])

  getDockById = (dockId: string): Promise<any> =>
    http({ path: `/docks/${dockId}.json` }).then(([data]) => data)

  createDock = async (dock: any): Promise<any> =>
    http({
      path: `/docks.json`,
      method: 'POST',
      body: { dock }
    })
}

export const docksService = new DocksService()
