import { useCallback, useEffect, useRef } from 'react'

export const useSafeDispatch = dispatch => {
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const safeDispatch = useCallback(
    (...args) => {
      if (mountedRef.current) {
        dispatch(...args)
      }
    },
    [dispatch]
  )

  return safeDispatch
}
