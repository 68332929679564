import { KanbanComponent, KanbanModel, KanbanTypecast } from '@syncfusion/ej2-react-kanban'
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base'
import { registerLicense } from '@syncfusion/ej2-base'
import '@syncfusion/ej2-base/styles/bootstrap5.css'
import '@syncfusion/ej2-layouts/styles/bootstrap5.css'
import '@syncfusion/ej2-react-kanban/styles/bootstrap5.css'
import React from 'react'

registerLicense(process.env.REACT_KANBAN_LICENSE_KEY)

const ReactKanban = (
  props: Readonly<{
    children?: React.ReactNode | React.ReactNode[]
  }> &
    Readonly<KanbanModel | DefaultHtmlAttributes | KanbanTypecast>
) => {
  return <KanbanComponent {...props}>{props.children}</KanbanComponent>
}

export default ReactKanban
