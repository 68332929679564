import { Answer, Question } from 'components/appointments/types'
import React, { useContext } from 'react'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import QuestionAttributes from './question-attributes'
import { checkShowOnCreateProperty } from 'components/utils/questions-validations'
import { CurrentUserContext } from '../../../../../homepage/current-user-context'
import { checkQuestionError } from '../../../../../utils/check-question-error'

interface AnswersProps {
  record: {
    appointmentTypeId?: string
    answersAttributes: Answer[]
    equipmentTypeId?: string
  }
  questions?: Array<Question>
  setRecord: (record) => void
  shouldValidate?: boolean
}

const Answers = ({ questions, record, setRecord, shouldValidate }: AnswersProps) => {
  const questionsList: Array<Question> = checkShowOnCreateProperty(questions)
  const { currentUser } = useContext(CurrentUserContext)
  const {
    actions: { updateAnswer }
  } = useCQStateValue()

  if (!record.answersAttributes) {
    return <></>
  }

  const handleAnswerUpdate = (question, i, answer: Answer) => {
    const newAnswersAttributes = [...record.answersAttributes]
    newAnswersAttributes[i] = answer
    setRecord({ ...record, answersAttributes: newAnswersAttributes })
    updateAnswer({
      questionId: question.id,
      response: answer.response,
      permission: question.permission,
      error: checkQuestionError(question, question.permission, answer)
    })
  }

  const shouldDisplayQuestion = question => {
    const permission = question.questionPermissionsAttributes?.filter(
      qpa => qpa.userRoleId === currentUser?.userRoleId
    )[0]

    if (
      question.questionPermissionsAttributes?.length === 0 ||
      !permission ||
      permission?.showOnCreate
    ) {
      return true
    }

    return false
  }

  return (
    <>
      {questionsList
        .filter(
          question =>
            !(question?.disabled || !question?.equipmentTypeIds?.includes(record.equipmentTypeId))
        )
        .map((question: Question, i) => (
          <>
            {shouldDisplayQuestion(question) && (
              <QuestionAttributes
                key={i}
                question={question}
                shouldValidate={shouldValidate}
                answer={record.answersAttributes[i]}
                setAnswer={(answer: Answer) => handleAnswerUpdate(question, i, answer)}
              />
            )}
          </>
        ))}
    </>
  )
}

export default Answers
