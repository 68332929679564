import Cookies from 'universal-cookie'

interface AuthenticatedFetchOptions {
  path: string
  method?: string
  body?: any
  loadingState?: [boolean, Function]
  signal?: AbortSignal
}

const authenticatedFetch = async ({
  path,
  method,
  body,
  loadingState,
  signal
}: AuthenticatedFetchOptions): Promise<[any, number]> => {
  const cookies = new Cookies()
  const [, setLoading] = loadingState || [null, () => {}]

  setLoading(true)

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': cookies.get('X-CSRF-Token'),
      'X-Lang': localStorage.getItem('i18nextLng') || 'en'
    },
    body: JSON.stringify(body),
    method,
    signal
  }

  const response = await fetch(path, requestOptions)
  setLoading(false)
  try {
    return [await response.json(), response.status]
  } catch {
    return [{}, response.status]
  }
}

export const uploadFile = async ({
  path,
  method,
  body,
  loadingState
}: AuthenticatedFetchOptions) => {
  const cookies = new Cookies()
  const [, setLoading] = loadingState || [null, () => {}]

  setLoading(true)

  const requestOptions = {
    headers: {
      'Content-Disposition': 'multipart/form-data',
      'X-CSRF-Token': cookies.get('X-CSRF-Token'),
      'X-Lang': localStorage.getItem('i18nextLng') || 'en'
    },
    body: body,
    method
  }

  const response = await fetch(path, requestOptions)
  setLoading(false)
  try {
    return [await response.json(), response.status]
  } catch {
    return [{}, response.status]
  }
}

export const downloadFile = async ({
  path,
  method,
  body,
  loadingState
}: AuthenticatedFetchOptions) => {
  const cookies = new Cookies()
  const [, setLoading] = loadingState || [null, () => {}]

  setLoading(true)

  const requestOptions = {
    headers: {
      'X-CSRF-Token': cookies.get('X-CSRF-Token'),
      'X-Lang': localStorage.getItem('i18nextLng') || 'en'
    },
    body: body,
    method
  }

  const response = await fetch(path, requestOptions)
  setLoading(false)
  try {
    return [await response.blob(), response.status]
  } catch {
    return [{}, response.status]
  }
}

export default authenticatedFetch
