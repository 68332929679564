import SlotRoutes from 'components/routes/SlotRoutes'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import Fallback from 'components/shared/Fallback'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SidebarNavbarTemplate from '../templates/SidebarNavbarTemplate'
import SignUp from './signup'
import DriversCheckinRoutes from 'components/routes/DriversCheckinRoutes'
import { useContext } from 'react'
import { CurrentUserContext } from './current-user-context'
import { OpenSchedulingRoutes } from '../routes/OpenShedulingRoutes'
import InviteRoutes from 'components/invites/invite-routes'
import ViewOnlyRoutes from 'components/routes/ViewOnlyRoutes'

const Router = () => {
  const { currentUser } = useContext(CurrentUserContext)

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/openscheduling" component={OpenSchedulingRoutes} />
        <Route path="/invites" component={InviteRoutes} />
        <Route path="/pass/:siteCode?" component={DriversCheckinRoutes} />
        {!currentUser && <Route path="/" component={SignUp} />}
        <SidebarNavbarTemplate>
          <ErrorBoundary fallback={<Fallback />}>
            <Route path="/" component={currentUser?.viewOnly ? ViewOnlyRoutes : SlotRoutes} />
          </ErrorBoundary>
        </SidebarNavbarTemplate>
      </Switch>
    </BrowserRouter>
  )
}

export default React.memo(Router)
