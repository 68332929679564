import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { EN_US } from 'components/contexts/locale-context'
import translationEN from '../../../public/locales/en-US/translation.json'
import translationES from '../../../public/locales/es/translation.json'

export const resources = {
  'en-US': {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || EN_US,
    fallbackLng: localStorage.getItem('i18nextLng') || EN_US,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
