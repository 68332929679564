import { useEffect, useRef } from 'react'

const useIsMounted = () => {
  const isMountedRef = useRef(true)

  useEffect(() => {
    // Set to true when the component is mounted
    isMountedRef.current = true

    return () => {
      // Set to false when the component is unmounted
      isMountedRef.current = false
    }
  }, [])

  return isMountedRef
}

export default useIsMounted
