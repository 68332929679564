import React, { useEffect, useState } from 'react'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'

import { useTranslation } from 'react-i18next'
import Header from 'components/ui/generic/Header'
import { PaginationResponse } from 'components/types/pagination-response'
import { PAGE_SIZE } from 'components/constants/page-size'
import { useHttp } from 'components/hooks/use-http'
import { usePagination } from 'components/hooks/use-pagination'
import useDebounce from 'components/hooks/use-debounce'
import { reportsService } from 'components/services/reports.service'
import { MetabaseReport } from 'components/models/MetabaseReport'
import StyledSpinner from 'components/shared/styled-spinner'
import { MetabaseAnalyticsSettingsSearch } from './MetabaseAnalyticsSettingsSearch'
import { MetabaseAnalyticsSettingsTable } from './MetabaseAnalyticsSettingsTable'
import { MetabaseAnalyticsSettingsPagination } from './MetabaseAnalyticsSettingsPagination'
import { MetabaseAnalyticsSettingsModal } from './MetabaseAnalyticsSettingsModal'

export const MetabaseAnalyticsSettings = () => {
  const [open, setOpen] = useState<boolean>(false)
  const { page, pageSize, setPage } = usePagination()
  const [search, setSearch] = useDebounce()
  const [findReports, { data, loading }] = useHttp<PaginationResponse<MetabaseReport>>(
    reportsService.findReports
  )
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const results = data?.results || []

  useEffect(() => {
    findReports({ page, pageSize, search })
  }, [findReports, page, pageSize, search])

  return (
    <>
      <Header
        title={t('Settings.SideMenu.Titles.Analytics.Text')}
        rightItems={[
          <MetabaseAnalyticsSettingsSearch
            loading={loading}
            onSearch={setSearch}
            onPlusClick={() => setOpen(true)}
          />
        ]}
      />
      <MetabaseAnalyticsSettingsModal
        open={open}
        onClose={() => setOpen(false)}
        onFinish={findReports}
      />

      <Block paddingTop="scale200" display="flex" flexDirection="column">
        <Block marginLeft="scale200" width="100%">
          <Block paddingBottom="scale200" minHeight="60vh">
            {loading ? (
              <StyledSpinner />
            ) : (
              <MetabaseAnalyticsSettingsTable refresh={findReports} data={results} />
            )}
          </Block>
        </Block>
        <MetabaseAnalyticsSettingsPagination
          total={Math.ceil(data?.total / PAGE_SIZE) || 1}
          page={page}
          onPageChange={setPage}
        />
      </Block>
    </>
  )
}
