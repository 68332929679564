import React, { useContext } from 'react'
import { useAppointmentContext } from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import { CalendarCheck } from '@phosphor-icons/react'
import { Block } from 'baseui/block'
import VButton from 'components/ui/generic/Button'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CurrentUserContext } from '../../homepage/current-user-context'

const AppointmentConfirmation = ({ confirmationIds }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { currentUser } = useContext(CurrentUserContext)

  const {
    actions: { setAppointmentState }
  } = useAppointmentContext()

  const {
    state: { facility }
  } = useFacilityContext()

  const handleScheduleAnotherAppointment = () => {
    setAppointmentState({
      confirmationIds: []
    })
  }

  const mapConfirmations = () => {
    const mappedConfirmations = confirmationIds.map(confirmation => {
      const appointmentTypeName = facility.appointmentTypes.find(
        appointment => appointment.id === confirmation.appointmentTypeId
      ).name
      return { ...confirmation, appointmentTypeName }
    })
    return mappedConfirmations.map(confirmation => {
      return `${confirmation.appointmentTypeName} ${confirmation.confirmationId}`
    })
  }

  const updateAppointment = () => {
    if (confirmationIds[0]?.appointmentId) {
      history.push(
        `/${currentUser ? 'appointments' : 'openscheduling'}/${
          confirmationIds[0]?.appointmentId
        }/schedule`
      )
    }
  }

  return (
    <div>
      <Block height="100px" />
      <Block
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        maxWidth="670px"
        margin="auto"
        width="100%"
        padding="24px 32px">
        <CalendarCheck size={120} />
        <Block display="flex" flexDirection="column" justifyContent="flex-start">
          {/** TODO: check if there is an existing component to remove this styles */}
          <div
            style={{
              color: '#082766',
              fontSize: '32px',
              fontFamily: 'Rubik',
              fontWeight: 600,
              lineHeight: '40px',
              letterSpacing: '0.15px',
              wordWrap: 'break-word',
              marginBottom: '.5rem'
            }}>
            {t('Scheduler.Appointments.Finish.SubTitles.YouAreAllSet.Text')}
          </div>
          <div
            style={{
              color: '#082766',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: '24px',
              wordWrap: 'break-word',
              marginBottom: '.5rem'
            }}>
            {t('Scheduler.Appointments.Finish.SubTitles.YourAppointment.Text')}
            <span style={{ fontWeight: 700 }}>{` ${mapConfirmations()} `}</span>
            {t('Scheduler.Appointments.Finish.SubTitles.HasBeenScheduled.Text')}
          </div>
          <Block
            display="flex"
            justifyContent="flex-start"
            flexDirection="row"
            alignItems="center"
            gridGap="scale500"
            marginTop="scale600">
            <VButton kind="primary" onClick={handleScheduleAnotherAppointment}>
              {t('Scheduler.Appointments.Finish.Buttons.ScheduleAnotherAppointment.Text')}
            </VButton>
            <VButton onClick={updateAppointment}>
              {t('Scheduler.Appointments.Finish.Buttons.UpdateAppointment.Text')}
            </VButton>
          </Block>
        </Block>
      </Block>
    </div>
  )
}

export default AppointmentConfirmation
