import { useEffect } from 'react'
import { FlexGridItem } from 'baseui/flex-grid'
import FormControl from 'components/ui/generic/FormControl'
import { Appointment, Question } from 'components/appointments/types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { checkShowOnCreateProperty } from 'components/utils/questions-validations'
import React from 'react'
import Questions from '../../../components/Questions/Questions'
import { DEFAULT_SCHEDULER_PERMISSION } from '../../../constants/default-permissions'
import formatQuestionCaption from 'components/utils/format-question-caption'
import { DOCUMENT_TYPE } from 'components/models/Question'
import Document from 'components/shared/fields/questions/document'
import formatQuestionLabel from 'components/utils/format-question-label'
import { checkQuestionError } from '../../../utils/check-question-error'

interface QuestionListProps {
  appointment: Appointment
  setAppointment: Function
  userType?: String
  showErrors?: boolean
}

const QuestionsList = ({ appointment, setAppointment, showErrors = true }: QuestionListProps) => {
  const {
    questions,
    answers,
    actions: { updateAnswer, updateQuestion }
  } = useCQStateValue()
  const questionsList: Array<Question> = checkShowOnCreateProperty(questions)

  const handleUpdateAnswer = (question, answer) => {
    const permission = question.questionPermissionsAttributes[0] || DEFAULT_SCHEDULER_PERMISSION
    updateAnswer({
      questionId: question.id,
      response: answer.response,
      error: checkQuestionError(question, permission, answer)
    })
    updateQuestion({
      questionId: question.id,
      response: answer.response,
      appointmentId: appointment.id
    })
  }

  useEffect(() => {
    setAppointment?.(answers)
  }, [answers])

  return (
    <>
      {questionsList.map((question, index) => {
        if (
          !question.disabled &&
          (!question.questionPermissionsAttributes[0] ||
            (question.questionPermissionsAttributes[0]?.canView &&
              question.questionPermissionsAttributes[0]?.showOnCreate))
        ) {
          const { answerType, prompt, documentUrl, documentName, questionPermissionsAttributes } =
            question
          const permission = questionPermissionsAttributes[0]
          return (
            <FlexGridItem key={index}>
              <>
                {answerType === DOCUMENT_TYPE ? (
                  <Document
                    question={question}
                    answer={{ documentName, documentUrl }}
                    disabled={permission && !permission?.canEdit}
                    updateAnswer={updateAnswer}
                    label={prompt}
                  />
                ) : (
                  <FormControl caption={formatQuestionCaption({ question })}>
                    <Questions
                      question={question}
                      disabled={permission && !permission?.canEdit}
                      answer={answers[index]}
                      setAnswer={answer => handleUpdateAnswer(question, answer)}
                      displayError={showErrors}
                      label={prompt}
                    />
                  </FormControl>
                )}
              </>
            </FlexGridItem>
          )
        }
      })}
    </>
  )
}

export default QuestionsList
