import React, { useState, useEffect } from 'react'
import Select from 'components/ui/generic/Select'
import { useTranslation } from 'react-i18next'
import { useTasksContext } from 'components/contexts/tasks.context'
import { FormControl } from 'baseui/form-control'
import { trailerService } from 'components/services/trailer.service'
import { StatusCodes } from 'components/constants/http-status-codes'
import { useDebounce } from 'react-use'

const SelectTrailerField = () => {
  const { state, actions } = useTasksContext()
  const {
    taskModal: {
      task,
      trailer: { loading: loadingTrailer }
    }
  } = state
  const { setTask } = actions

  const [options, setOptions] = useState([])
  const [search, setSearch] = useState(null)
  const { t } = useTranslation()

  const convertToSelect = array => {
    return Array.isArray(array)
      ? array.map(item => ({ ...item, label: item.number, value: item.id }))
      : []
  }

  const searchTrailer = (number: string) =>
    trailerService
      .getTrailers(true, number)
      .then(([data, result]) => {
        if (result === StatusCodes.OK) {
          setOptions(convertToSelect(data))
        }
      })
      .catch(e => console.error(e))

  useEffect(() => {
    searchTrailer(null)
  }, [])

  useDebounce(
    () => {
      setSearch(search)
      searchTrailer(search)
    },
    200,
    [search]
  )

  return (
    <FormControl>
      <Select
        disabled={loadingTrailer}
        searchable={true}
        maxDropdownHeight="250px"
        label={t('YardTask.Kanban.TaskModal.Fields.Trailer.Text')}
        value={options.find(f => f.value === task?.trailerId)}
        clearable={false}
        placeholder=""
        onChange={({ option }) => {
          setTask({
            ...task,
            trailerId: option.id
          })
        }}
        options={options}
        onInputChange={evt => {
          setSearch(evt.currentTarget.value)
        }}
      />
    </FormControl>
  )
}

export default SelectTrailerField
