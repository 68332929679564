export const SET_SCHEDULER = 'SET_SCHEDULER'
export const SET_ORDER = 'SET_ORDER'
export const SET_FACILITY = 'SET_FACILITY'
export const SET_LOADING = 'SET_LOADING'
export const SET_UOM_OPTIONS = 'SET_UOM_OPTIONS'
export const SET_UOM = 'SET_UOM'
export const SET_WEIGHT_UOM = 'SET_WEIGHT_UOM'
export const SET_MODAL = 'SET_MODAL'
export const SET_CARRIER = 'SET_CARRIER'
export const SET_ORDER_PREFERENCES = 'SET_ORDER_PREFERENCES'

export const ordersReducer = (state, action) => {
  switch (action.type) {
    case SET_ORDER_PREFERENCES: {
      return {
        ...state,
        orderPreferences: action.payload
      }
    }
    case SET_SCHEDULER: {
      return {
        ...state,
        selectedScheduler: action.payload
      }
    }
    case SET_CARRIER: {
      return {
        ...state,
        selectedCarrier: action.payload
      }
    }
    case SET_FACILITY: {
      return {
        ...state,
        selectedFacility: action.payload
      }
    }
    case SET_ORDER: {
      return {
        ...state,
        order: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case SET_UOM_OPTIONS: {
      return {
        ...state,
        unitOfMeasureOptions: action.payload.package,
        weightUnitOfMeasureOptions: action.payload.weight
      }
    }
    case SET_UOM: {
      return {
        ...state,
        selectedUnitOfMeasure: action.payload
      }
    }
    case SET_WEIGHT_UOM: {
      return {
        ...state,
        selectedWeightUnitOfMeasure: action.payload
      }
    }
    case SET_MODAL: {
      return {
        ...state,
        isModalOpen: action.payload
      }
    }
    default:
      return state
  }
}

const finalOrdersReducer = ({ orders }, action) => {
  return {
    orders: ordersReducer(orders, action)
  }
}

export default finalOrdersReducer
