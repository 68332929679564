import React, { useEffect, useState } from "react";
import { FormControl } from "baseui/form-control";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Checkbox } from "baseui/checkbox";
import authenticatedFetch from "../../utils/authenticated-fetch";

const DocksSelect = ({ docks, setDocks, facility }) => {
  useEffect(() => {
    facility &&
      docks &&
      authenticatedFetch({
        path: `/facilities/${facility.id}/docks.json`,
      }).then(([json, status]) => {
        setDocks(
          json.map((dock) => {
            return { ...dock, checked: true };
          })
        );
      });
  }, []);

  return (
    <FormControl label="Docks">
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap="scale200">
        {docks.map((dock, index) => {
          return (
            <FlexGridItem key={index}>
              <Checkbox
                checked={docks[index].checked}
                onChange={(e) => {
                  const newDocks = [...docks];
                  docks[index].checked = e.currentTarget.checked;
                  setDocks(newDocks);
                }}
              >
                {dock.name}
              </Checkbox>
            </FlexGridItem>
          );
        })}
      </FlexGrid>
    </FormControl>
  );
};

export default DocksSelect;
