import React from 'react'
import { ArrowLineDown, ArrowLineUp } from '@phosphor-icons/react'
import { INBOUND_TYPE, OUTBOUND_TYPE } from 'components/constants/appointment_types'
import { INBOUND, OUTBOUND } from 'components/contexts/appointment-context'

export const appointmentTypesButtons = [
  {
    label: INBOUND_TYPE,
    value: INBOUND,
    icon: <ArrowLineDown size={16} />
  },
  {
    label: OUTBOUND_TYPE,
    value: OUTBOUND,
    icon: <ArrowLineUp size={16} />
  }
]
