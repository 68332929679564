import React, { useEffect, useState } from 'react'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { createContext } from '../utils/create-context'

export const [useCurrentFacilityContext, CurrentFacilityContextProvider] = createContext()

export const CurrentFacilityProvider = ({ children }) => {
  const [currentFacility, setCurrentFacility] = useState<any>(null)

  useEffect(() => {
    if (currentFacility === null) {
      authenticatedFetch({
        path: '/facilities/current_facility.json'
      }).then(([json, status]) => {
        if ([200, 304].includes(status) && json) {
          setCurrentFacility(json)
        }
      })
    }
  }, [])

  return (
    <CurrentFacilityContextProvider value={{ currentFacility, setCurrentFacility }}>
      {children}
    </CurrentFacilityContextProvider>
  )
}
