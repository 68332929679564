import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import ShipperDashboard from '../shipper/shipper-dashboard'
import { CurrentUserContext } from './current-user-context'
import CurrentFacilityBook from 'components/facilities/current-facility-book'
import { BROKER, CARRIER, SCHEDULER } from 'components/models/User'
import { YARD_JOCKEY_AUDIENCE } from '../models/Role'
import YardJockey from '../pages/YardJockey'

const Homepage = ({ match }) => {
  const { currentUser } = useContext(CurrentUserContext)

  if ([YARD_JOCKEY_AUDIENCE].includes(currentUser?.userType)) {
    return <Route path={match.url} exact component={YardJockey} />
  }

  if ([BROKER, CARRIER, SCHEDULER].includes(currentUser?.userType) || currentUser?.schedulerMode) {
    return <Route path={match.url} exact component={CurrentFacilityBook} />
  }

  if (currentUser?.shipperId) {
    return <Route path={match.url} exact component={ShipperDashboard} />
  }

  return <Redirect to="/" />
}

export default Homepage
