import authenticatedFetch from 'components/utils/authenticated-fetch'
import React, { useContext, useEffect, useState } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { SIZE } from 'baseui/select'
import { sortBy } from 'lodash'
import {
  CustomLabelsContext,
  INITIAL_CUSTOM_FIELD_NAMES
} from 'components/contexts/custom-labels-context'
import { useLocalStorage } from 'react-use'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { AppointmentType } from 'components/models/AppointmentType'
import { useCalendarSelectedOptionsContext } from '../../../contexts/calendar-selected-options.context'
import i18n from 'translations/i18n'
import { useTranslation } from 'react-i18next'
import Select from 'components/ui/generic/Select'

export const NonConnectedAppointmentTypeToggle = props => {
  const { refine } = props
  const { currentUser } = useContext(CurrentUserContext)
  const { setCustomLabels } = useContext(CustomLabelsContext)
  const [index, setIndex] = useState<number>(0)
  const { t } = useTranslation()
  const APPOINTMENT_TYPE_ALL_OPTION = {
    name: i18n.t('Common.AppointmentTypeToggle.AllOption.Text'),
    id: 0,
    customLabels: {
      purchaseOrderIdentifiers: null,
      purchaseOrderIdentifiersCaption: null,
      scheduler: null,
      schedulerCaption: null
    }
  }
  const {
    actions: { setAppType }
  } = useCalendarSelectedOptionsContext()

  const [cacheSelectedAppointmentType, setCacheSelectedAppointmentType] = useLocalStorage(
    `DASHBOARD_APPOINTMENT_TYPE_BY_USER_AND_ORG`,
    undefined
  )

  const [appointmentTypes, setAppointmentTypes] = useState([])
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<any>()

  const searchAppointmentTypes = async () => {
    const [{ hits }, status] = await authenticatedFetch({
      path: `/search.json`,
      method: 'POST',
      body: {
        search: {
          indexName: 'appointment_type',
          params: {
            query: ''
          }
        }
      }
    })

    if ([200, 304].includes(status)) {
      const sortedHits = sortBy(hits, 'name')
      const appTypesStack = [...sortedHits, APPOINTMENT_TYPE_ALL_OPTION]
      if (
        cacheSelectedAppointmentType &&
        cacheSelectedAppointmentType[currentUser.shipperId] &&
        cacheSelectedAppointmentType[currentUser.shipperId][currentUser.id]
      ) {
        const appTypeIndex = sortedHits
          .map((appType: AppointmentType) => appType.id)
          .indexOf(cacheSelectedAppointmentType[currentUser.shipperId][currentUser.id])
        setSelectedAppointmentType([appTypesStack[appTypeIndex]])
        setAppType([appTypesStack[appTypeIndex]])
        refine(sortedHits[appTypeIndex].id)
        setCustomLabels &&
          setCustomLabels(
            Object.keys(appTypesStack[appTypeIndex].customLabels).length === 0
              ? INITIAL_CUSTOM_FIELD_NAMES
              : appTypesStack[appTypeIndex].customLabels
          )
      } else {
        setSelectedAppointmentType([APPOINTMENT_TYPE_ALL_OPTION])
        setAppType([APPOINTMENT_TYPE_ALL_OPTION])
        const arrayOfIds = []

        appointmentTypes.forEach(at => {
          if (at.name !== t('Common.AppointmentTypeToggle.AllOption.Text')) {
            arrayOfIds.push(at.id)
          }
        })

        refine(arrayOfIds)
      }

      setAppointmentTypes(appTypesStack)
    }
  }

  useEffect(() => {
    searchAppointmentTypes()
  }, [])

  const sortByAppointmentType = appType => {
    const { index: idx } = appType
    setCacheSelectedAppointmentType({
      ...(cacheSelectedAppointmentType || {}),
      [currentUser.shipperId]: {
        ...((cacheSelectedAppointmentType && cacheSelectedAppointmentType[currentUser.shipperId]) ||
          {}),
        [currentUser.id]: appointmentTypes[idx].id
      }
    })
    setSelectedAppointmentType([appType])
    setAppType([appType])

    setCustomLabels && setCustomLabels(appointmentTypes[idx].customLabels)

    if (appointmentTypes[idx]?.name === t('Common.AppointmentTypeToggle.AllOption.Text')) {
      const arrayOfIds = []

      appointmentTypes.forEach(at => {
        if (at.name !== t('Common.AppointmentTypeToggle.AllOption.Text')) {
          arrayOfIds.push(at.id)
        }
      })

      refine(arrayOfIds)
    } else {
      refine(appointmentTypes[idx].id)
    }
  }

  return (
    <Select
      clearable={false}
      searchable={false}
      options={appointmentTypes.map((appType, idx) => {
        return { ...appType, label: appType.name, index: idx }
      })}
      value={selectedAppointmentType}
      placeholder={t('Common.AppointmentTypeToggle.SelectAppointmentType.PlaceHolder.Text')}
      onChange={params => sortByAppointmentType(params?.value[0])}
    />
  )
}

const AppointmentTypeToggleConnectedComponent = connectRefinementList(
  NonConnectedAppointmentTypeToggle
)

const AppointmentTypeToggle = () => {
  return (
    <AppointmentTypeToggleConnectedComponent
      attribute="appointment_type_id"
      defaultRefinement={['appointment_type_id']}
    />
  )
}

export default AppointmentTypeToggle
