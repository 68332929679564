// reducers/appointmentReducer.js

import { validateAppointment } from '../pages/CreateAppointmentForExternalUsers/utils/validations'

export const SET_HANDLING_METHOD = 'SET_HANDLING_METHOD'
export const SET_APPOINTMENT_DIRECTION = 'SET_APPOINTMENT_DIRECTION'
export const SET_SCHEDULER_EMAIL = 'SET_SCHEDULER_EMAIL'
export const SET_LOADING = 'SET_LOADING'
export const SET_APPOINTMENT = 'SET_APPOINTMENT'
export const RESET_APPOINTMENT_DIRECTION = 'RESET_APPOINTMENT_DIRECTION'
export const SET_IS_TIME_SET = 'SET_IS_TIME_SET'
export const SET_APPOINTMENT_STATE = 'SET_APPOINTMENT_STATE'
export const SET_SHOW_RECURRING = 'SET_SHOW_RECURRING'
export const SET_CHECKED_RECURRING = 'SET_CHECKED_RECURRING'
export const SET_OPEN_RECURRING = 'SET_OPEN_RECURRING'
export const SET_RECURRING = 'SET_RECURRING'
export const OPEN_TERMS_OF_SERVICE_MODAL = 'OPEN_TERMS_OF_SERVICE_MODAL'
export const IS_TERMS_OF_SERVICE_CHECKED = 'IS_TERMS_OF_SERVICE_CHECKED'
export const SET_EDIT_APPOINTMENT_ID = 'SET_EDIT_APPOINTMENT_ID'
export const SET_EDIT_APPOINTMENT_STATE = 'SET_EDIT_APPOINTMENT_STATE'
export const SET_FACILITY_EDIT_APPOINTMENT = 'SET_FACILITY_EDIT_APPOINTMENT'
export const SET_LOADING_DATA_APPOINTMENT = 'SET_LOADING_DATA_APPOINTMENT'
export const SET_RESCHEDULE_REASON = 'SET_RESCHEDULE_REASON'
export const SET_UNIQUE_LOAD_IDENTIFIER_ERROR = 'SET_UNIQUE_LOAD_IDENTIFIER_ERROR'
export const SET_RECAPTCHA = 'SET_RECAPTCHA'
export const SET_APPOINTMENT_CHECKED_OUT = 'SET_APPOINTMENT_CHECKED_OUT'

export const QUESTION_DISABLED = 'QuestionDisabled'

const updateFields = (appointment, payload) => {
  const trailerFields = ['state', 'number', 'status']
  return Object.entries(payload).reduce((updatedAppointment, [field, value]) => {
    if (trailerFields.includes(field)) {
      return {
        ...updatedAppointment,
        trailer: {
          ...updatedAppointment.trailer,
          [field]: value
        }
      }
    } else {
      return {
        ...updatedAppointment,
        [field]: value
      }
    }
  }, appointment)
}

const applyAppointmentStates = (state, payload, direction) => {
  const updateAppointment = appointment => updateFields(appointment, payload)

  const updatedAppointments = direction
    ? { [direction]: updateAppointment(state.appointments[direction]) }
    : {
        inbound: updateAppointment(state.appointments.inbound),
        outbound: updateAppointment(state.appointments.outbound)
      }

  const newState = {
    ...state,
    appointments: { ...state.appointments, ...updatedAppointments }
  }

  const validationResult = validateAppointment(newState)
  return {
    ...newState,
    createAppointmentButtonDisableFlags: {
      ...newState.createAppointmentButtonDisableFlags,
      isCreateAppointmentButtonDisabled: validationResult.isDisabled,
      errors: validationResult.invalidFields
    }
  }
}

const appointmentReducer = (state, action) => {
  let newState

  switch (action.type) {
    case SET_APPOINTMENT_STATE:
      newState = { ...state, ...action.payload }
      break

    case SET_APPOINTMENT:
      newState = applyAppointmentStates(state, action.payload, action.direction)
      break

    case SET_SCHEDULER_EMAIL:
      newState = { ...state, [action.payload.field]: action.payload.value }
      break

    case SET_APPOINTMENT_DIRECTION:
      const isInboundArrival = !!state.appointments.inbound.arrivalTime
      const isOutboundArrival = !!state.appointments.outbound.arrivalTime

      newState = {
        ...state,
        appointments: {
          ...state.appointments,
          ...(isOutboundArrival && {
            inbound: {
              ...state.appointments.inbound,
              arrivalTime: state.appointments.outbound.arrivalTime
            }
          }),
          ...(isInboundArrival && {
            outbound: {
              ...state.appointments.outbound,
              arrivalTime: state.appointments.inbound.arrivalTime
            }
          })
        },
        appointmentDirections: action.payload
      }
      break

    case SET_LOADING:
      newState = { ...state, loading: action.payload }
      break

    case SET_HANDLING_METHOD:
      newState = {
        ...state,
        handlingMethod: action.payload,
        appointments: {
          inbound: { ...state.appointments.inbound, handlingMethod: action.payload },
          outbound: { ...state.appointments.outbound, handlingMethod: action.payload }
        },
        appointmentDirections: action.payload === 'drop' ? ['inbound'] : ['inbound']
      }
      break

    case RESET_APPOINTMENT_DIRECTION:
      newState = {
        ...state,
        appointments: {
          ...state.appointments,
          [action.direction]: {
            ...state.appointments[action.direction],
            ...action.payload,
            [action.direction + QUESTION_DISABLED]: false
          }
        }
      }
      break

    case SET_IS_TIME_SET:
      newState = {
        ...state,
        createAppointmentButtonDisableFlags: {
          ...state.createAppointmentButtonDisableFlags,
          isTimeSet: action.payload
        }
      }
      break

    case SET_SHOW_RECURRING:
      newState = {
        ...state,
        recurring: {
          ...state.recurring,
          showRecurring: action.payload
        }
      }
      break

    case SET_CHECKED_RECURRING:
      newState = {
        ...state,
        recurring: {
          ...state.recurring,
          checked: action.payload
        }
      }
      break

    case SET_OPEN_RECURRING:
      newState = {
        ...state,
        recurring: {
          ...state.recurring,
          showRecurringFields: action.payload
        }
      }
      break

    case SET_RECURRING:
      newState = {
        ...state,
        recurring: {
          ...state.recurring,
          ...action.payload
        }
      }
      break

    case OPEN_TERMS_OF_SERVICE_MODAL:
      return {
        ...state,
        termsOfService: {
          ...state.termsOfService,
          showTermsOfServiceModal: action.payload
        }
      }

    case IS_TERMS_OF_SERVICE_CHECKED:
      return {
        ...state,
        termsOfService: {
          ...state.termsOfService,
          isTermsOfServiceChecked: action.payload
        }
      }

    case OPEN_TERMS_OF_SERVICE_MODAL:
      return {
        ...state,
        termsOfService: {
          ...state.termsOfService,
          showTermsOfServiceModal: action.payload
        }
      }

    case IS_TERMS_OF_SERVICE_CHECKED:
      return {
        ...state,
        termsOfService: {
          ...state.termsOfService,
          isTermsOfServiceChecked: action.payload
        }
      }

    case SET_EDIT_APPOINTMENT_ID:
      newState = { ...state, appointmentId: action.payload }
      break

    case SET_EDIT_APPOINTMENT_STATE:
      newState = { ...state, ...action.payload }
      break

    case SET_FACILITY_EDIT_APPOINTMENT:
      newState = {
        ...state,
        appointmentEdit: {
          ...state.appointmentEdit,
          facilityId: action.payload
        }
      }
      break

    case SET_APPOINTMENT_CHECKED_OUT:
      newState = {
        ...state,
        appointmentEdit: {
          ...state.appointmentEdit,
          appointmentCheckedOut: action.payload
        }
      }
      break

    case SET_LOADING_DATA_APPOINTMENT:
      newState = {
        ...state,
        appointmentEdit: {
          ...state.appointmentEdit,
          loadingData: action.payload
        }
      }
      break

    case SET_RESCHEDULE_REASON:
      newState = {
        ...state,
        appointmentEdit: {
          ...state.appointmentEdit,
          rescheduleReason: action.payload
        }
      }
      break

    case SET_RECAPTCHA:
      newState = {
        ...state,
        appointmentEdit: {
          ...state.appointmentEdit,
          recaptcha: action.payload
        }
      }
      break

    case SET_UNIQUE_LOAD_IDENTIFIER_ERROR:
      newState = {
        ...state,
        createAppointmentButtonDisableFlags: {
          ...state.createAppointmentButtonDisableFlags,
          [`is${action.direction}UniqueLoadIdentifierError`]: action.payload
        }
      }
      break

    default:
      return state
  }

  const validationResult = validateAppointment(newState)
  return {
    ...newState,
    createAppointmentButtonDisableFlags: {
      ...newState.createAppointmentButtonDisableFlags,
      isCreateAppointmentButtonDisabled: validationResult.isDisabled,
      errors: validationResult.invalidFields
    }
  }
}

export default appointmentReducer
