import React, { Dispatch, useContext, useRef } from 'react'
import { AppointmentType } from 'components/models/AppointmentType'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { AppointmentDuration } from 'components/models/AppointmentDuration'
import { Block, BlockProps } from 'baseui/block'
import { LabelXSmall, ParagraphSmall } from 'baseui/typography'
import { Option } from 'baseui/select'
import { durationOptions, getDurationLabel } from './AppointmentDurationConstants'
import { LightTheme } from 'baseui'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { EquipmentType } from 'components/models/DockCapacity'
import { useTranslation } from 'react-i18next'

import Select from 'components/ui/generic/Select'

interface AppointmentDurationTableProps {
  equipmentTypes: string[]
  appointmentTypes: AppointmentType[]
  appointmentDurationsAttributes: AppointmentDuration[]
  setAppointmentPreference: (appointmentPreference: any) => void
  appointmentPreference: any
  setShouldUpdateAppointmentDurations: Dispatch<boolean>
}

export const AppointmentDurationTable = ({
  equipmentTypes,
  appointmentTypes,
  appointmentDurationsAttributes,
  setAppointmentPreference,
  appointmentPreference,
  setShouldUpdateAppointmentDurations
}: AppointmentDurationTableProps) => {
  const ref = useRef(null)

  const scroll = scrollOffset => {
    ref.current.scrollLeft += scrollOffset
  }

  const { t } = useTranslation()

  if (equipmentTypes?.length === 0 || appointmentTypes?.length === 0) {
    return (
      <ParagraphSmall color={'red'}>
        {t('AppointmentPreferences.AppointmentDurationTable.NoAppointmentTypes.Text')}
      </ParagraphSmall>
    )
  }

  return (
    <Block display="flex" gridColumnGap="scale800" overflow="scrollX" maxWidth="100%">
      <Header equipmentTypes={equipmentTypes} />
      <Content
        appointmentTypes={appointmentTypes}
        appointmentDurationsAttributes={appointmentDurationsAttributes}
        equipmentTypes={equipmentTypes}
        setAppointmentPreference={setAppointmentPreference}
        appointmentPreference={appointmentPreference}
        setShouldUpdateAppointmentDurations={setShouldUpdateAppointmentDurations}
      />
    </Block>
  )
}

const itemProps: BlockProps = {
  height: 'scale1000',
  display: 'flex',
  alignItems: 'center'
}

const narrowItemProps = {
  ...itemProps,
  overrides: {
    Block: {
      style: ({ $theme }: { $theme: typeof LightTheme }) => ({
        width: $theme.sizing.scale2400,
        flexGrow: 0
      })
    }
  }
}

const Header = ({ equipmentTypes }) => (
  <FlexGrid
    flexDirection="column"
    flexGridColumnGap="scale800"
    flexGridRowGap="scale800"
    marginBottom="20px">
    <FlexGridItem {...narrowItemProps} width="scale1600"></FlexGridItem>
    {equipmentTypes.map((type: EquipmentType, idx: number) => (
      <FlexGridItem {...itemProps} key={`${idx}-${type}`}>
        <LabelXSmall>
          <span
            style={{
              textTransform: 'capitalize',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              textAlign: 'left'
            }}>
            {type.name}
          </span>
        </LabelXSmall>
      </FlexGridItem>
    ))}
  </FlexGrid>
)

const Content = ({
  appointmentTypes,
  appointmentDurationsAttributes,
  equipmentTypes,
  setAppointmentPreference,
  appointmentPreference,
  setShouldUpdateAppointmentDurations
}) => {
  const { currentUser } = useContext(CurrentUserContext)

  return (
    <form>
      <Block display="flex" gridColumnGap="scale800">
        {appointmentTypes.map((appointmentType: AppointmentType) => (
          <FlexGrid
            flexDirection="column"
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
            key={`${appointmentType.id}`}>
            <FlexGridItem {...narrowItemProps}>
              <LabelXSmall $style={{ width: '100%' }}>
                <div style={{ textTransform: 'capitalize', wordWrap: 'break-word' }}>
                  {appointmentType.name}
                </div>
              </LabelXSmall>
            </FlexGridItem>
            {equipmentTypes.map((equipmentType: EquipmentType, idx: number) => (
              <FlexGridItem {...itemProps} key={`${idx}-${appointmentType?.name}-${equipmentType}`}>
                <Cell
                  equipmentType={equipmentType}
                  appointmentType={appointmentType}
                  listOfAppointmentDurations={appointmentDurationsAttributes}
                  setAppointmentPreference={setAppointmentPreference}
                  appointmentPreference={appointmentPreference}
                  currentUser={currentUser}
                  setShouldUpdateAppointmentDurations={setShouldUpdateAppointmentDurations}
                />
              </FlexGridItem>
            ))}
          </FlexGrid>
        ))}
      </Block>
    </form>
  )
}

const Cell = ({
  equipmentType,
  appointmentType,
  listOfAppointmentDurations,
  setAppointmentPreference,
  appointmentPreference,
  currentUser,
  setShouldUpdateAppointmentDurations
}) => {
  const appointmentDuration = listOfAppointmentDurations?.filter(
    (appointmentDuration: AppointmentDuration) =>
      equipmentType.id === appointmentDuration.equipmentType.id &&
      appointmentType.id === appointmentDuration.appointmentTypesId
  )[0]

  const [value, setValue] = React.useState([
    {
      id: appointmentDuration?.duration || Math.floor(equipmentType.default_duration),
      label: getDurationLabel(
        appointmentDuration?.duration || Math.floor(equipmentType.default_duration)
      )
    }
  ])

  const handleOnChange = (selectedOption: Option) => {
    if (appointmentDuration) {
      const listWithoutCurrentAppointmetDuration = listOfAppointmentDurations.filter(
        (appointmentDurationItem: AppointmentDuration) =>
          appointmentDuration.id !== appointmentDurationItem.id
      )
      setAppointmentPreference({
        ...appointmentPreference,
        appointmentDurationsAttributes: [
          ...listWithoutCurrentAppointmetDuration,
          {
            ...appointmentDuration,
            duration: selectedOption.id
          }
        ]
      })
    } else {
      setAppointmentPreference({
        ...appointmentPreference,
        appointmentDurationsAttributes: [
          ...listOfAppointmentDurations,
          {
            appointmentTypesId: appointmentType.id,
            equipmentTypeId: equipmentType.id,
            equipmentType,
            appointmentPreferenceId: appointmentPreference.id,
            duration: selectedOption.id
          }
        ]
      })
    }
    setShouldUpdateAppointmentDurations(true)
    setValue([selectedOption as any])
  }

  return (
    <Select
      error={value[0]?.id < appointmentPreference?.appointmentDurationInMinutes}
      options={durationOptions}
      placeholder=""
      value={value}
      clearable={false}
      disabled={!currentUser?.admin}
      onChange={params => handleOnChange(params.value[0])}
      maxWidth="95%"
      maxDropdownHeight="160px"
    />
  )
}
