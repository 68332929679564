import styled from 'styled-components'
import { breakpoints } from '../../constants/breakpoints'
import { SIDEBAR_MINIMIZE, SIDEBAR_MAXIMIZE } from '../../constants/layout'

export const SidebarContainer = styled.div<{ open: boolean }>`
  position: relative;
  transition: padding 0.2s ease-out;
  padding: 0 40px;
  @media (max-width: ${breakpoints.md.max}px) {
    display: flex;
  }
`

export const SidebarStyled = styled.div<{ open: boolean }>`
  position: fixed;
  height: 100vh;
  background-color: #014991;
  transition: width 0.2s ease-out;
  width: ${({ open }) => (open ? SIDEBAR_MAXIMIZE : SIDEBAR_MINIMIZE)}px;
  top: 0px;
  left: 0px;
  z-index: 999;
  display: none;

  @media (max-width: ${breakpoints.md.max}px) {
    width: ${({ open }) => (open ? SIDEBAR_MAXIMIZE : 0)}px;
    left: auto;
    right: 0px;
    display: block;
    padding-top: 35px;
  }
`

export const SidebarContent = styled.main<{ open: boolean }>`
  width: 100%;
  z-index: 998;

  @media (max-width: ${breakpoints.md.max}px) {
    position: absolute;
    transition: right 0.2s ease-out;
    right: ${({ open }) => (open ? SIDEBAR_MAXIMIZE : 0)}px;
  }
`

export const SidebarOverlay = styled.main<{ open: boolean }>`
  visibility: hidden;

  @media (max-width: ${breakpoints.md.max}px) {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99;

    transition: opacity 0.2s ease-out;
    background-color: black;
    opacity: 0;

    ${({ open }) =>
      open
        ? `
        visibility: visible;
        width: 100%;
        height: 100%;
        opacity: 0.35;
    `
        : ''}
  }
`

export const Content = styled.div`
   {
    padding: 0 16px;
    position: relative;
    @media (min-width: ${breakpoints.md.min}px) {
      padding: 0 24px;
    }
    @media (min-width: ${breakpoints.lg.max}px) {
      padding: 0;
    }
  }
`
