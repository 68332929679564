import React, { useContext, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, SIZE } from 'baseui/modal'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { RefreshSearchContext } from 'cyber/search/search'
import { FormControl } from 'baseui/form-control'
import { useForm } from 'react-hook-form'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { fancyToast } from 'components/utils'
import { StatusCodes } from 'components/constants/http-status-codes'
import {
  InfoBoxContainer,
  InfoBoxUnorderList,
  InfoBoxWrapper,
  ModalLeftForm,
  ModalTopLevel
} from 'components/shipper/instant-calendar/modals/bulk-upload/BulkUploadModal.styled'
import ErrorMessageButton from 'components/shared/error-message-button'
import BulkFileUploader from 'components/shipper/instant-calendar/modals/bulk-upload/BulkFileUploader'
import { FileNameLabel } from 'components/shipper/instant-calendar/modals/styled/TemporalFile.styled'
import { orderService } from 'components/services/order'
import { useTranslation } from 'react-i18next'
import { StyledLink } from 'baseui/link'

const BULK_UPLOAD_TEMPLATE =
  'https://storage.googleapis.com/terusama-public/excel_templates/orders_template.xlsx'

const BulkUploadOrdersModal = ({ showBulkModal, onClose }) => {
  const [file, setFile] = useState(null)
  const { refresh } = useContext(RefreshSearchContext)
  const [uploadButtonHasBeenClicked, setUploadButtonHasBeenClicked] = useState(false)
  const { handleSubmit } = useForm()
  const { t } = useTranslation()

  const onModalClose = () => {
    setFile(null)
    onClose()
  }

  const onSubmit = () => {
    setUploadButtonHasBeenClicked(true)
    const formData = new FormData()
    formData.append('file', file, file.name)

    orderService
      .bulkUploadOrder(formData)
      .then(([data, status]) => {
        if (status >= 200 && status < 300)
          fancyToast(
            { info: t('Orders.UploadOrdersModal.Info.FileProcessed.Text') },
            StatusCodes.OK
          )
        else
          fancyToast(
            { info: data?.error ?? t('Orders.UploadOrdersModal.Info.FileNotProcessed.Text') },
            StatusCodes.BAD_REQUEST
          )
      })
      .finally(() => {
        setUploadButtonHasBeenClicked(false)
        onModalClose()
        refresh()
      })
  }

  const onFileChange = (accepted, _rejected) => {
    setFile(accepted[0])
  }

  const uploadFormErrors = () => [
    { label: t('Orders.UploadOrdersModal.Error.FileMustBeUploaded.Text'), status: !!file }
  ]

  return (
    <Modal
      isOpen={showBulkModal}
      onClose={onModalClose}
      size={SIZE.auto}
      unstable_ModalBackdropScroll>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{t('Orders.UploadOrdersModal.Header.Text')}</ModalHeader>
        <ModalBody>
          <FlexGrid width="612px">
            <ModalTopLevel>
              <ModalLeftForm>
                <InfoBox />
              </ModalLeftForm>
            </ModalTopLevel>
            <FlexGridItem>
              {file?.name && <FileNameLabel>{file.name}</FileNameLabel>}
              <FormControl
                label={t('Orders.UploadOrdersModal.Fields.UploadSpreedSheet.Label.Text')}>
                <BulkFileUploader onFileChange={onFileChange} />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
        </ModalBody>
        <ModalFooter>
          <ErrorMessageButton
            label={t('Orders.UploadOrdersModal.UploadButton.Text')}
            disabled={uploadButtonHasBeenClicked}
            errors={uploadFormErrors()}
            buttonProps={{
              type: 'submit'
            }}
          />
        </ModalFooter>
      </form>
    </Modal>
  )
}

const InfoBox = () => {
  const { t } = useTranslation()

  const onDownloadTemplate = async () => {
    try {
      const [resp, status] = await orderService.generate_template()
      if (status == StatusCodes.OK) {
        const url = URL.createObjectURL(resp)
        const link = document.createElement('a')
        link.href = url
        link.download = 'orders_template.xlsx'
        link.click()
      }
    } catch (error) {
      fancyToast({ error: t('Orders.UploadOrdersModal.Errors.TemplateDownloadError.Text') })
    }
  }
  return (
    <InfoBoxWrapper>
      <BsFillInfoCircleFill color="#183d89" />
      <InfoBoxContainer>
        <InfoBoxUnorderList>
          <li>{t('Orders.UploadOrdersModal.UploadInfo.MaxRecords.Text')}</li>
          <li>{t('Orders.UploadOrdersModal.UploadInfo.DateFormat.Text')}</li>
          <li>
            <StyledLink onClick={onDownloadTemplate}>
              {t('Orders.UploadOrdersModal.UploadInfo.ClickTemplate.Text')}
            </StyledLink>
          </li>
        </InfoBoxUnorderList>
      </InfoBoxContainer>
    </InfoBoxWrapper>
  )
}

export default BulkUploadOrdersModal
