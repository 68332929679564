import React, { useEffect, useState, useContext } from 'react'
import { SelectProps } from 'baseui/select'
import { useDebounce } from 'react-use'
import { searchService } from 'components/services/search.service'

import Select from 'components/ui/generic/Select'
import { CurrentUserContext } from 'components/homepage/current-user-context'

type Props = {
  record: any
  onValuesChange?: (values: string[]) => void
  entity: string
  disabled?: boolean
} & SelectProps

type SelectValue = {
  name: string
  id: string
}

export const SelectSearch = ({ record, entity, onValuesChange, disabled, ...props }: Props) => {
  const { currentUser } = useContext(CurrentUserContext)
  const indexNameId = `${entity}Id`
  const [search, setSearch] = useState<string>(null)
  const [values, setValues] = useState<SelectValue[]>(
    disabled
      ? [
          {
            id: currentUser.schedulerId,
            name: currentUser.organizationName
          }
        ]
      : []
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [hits, setHits] = useState([])

  useDebounce(
    async () => {
      setLoading(true)
      const data = await searchService.search(entity, search)
      setLoading(false)
      setHits(data?.hits)
    },
    200,
    [search]
  )

  useEffect(() => {
    const getEntity = async () => {
      if (record && record[indexNameId]) {
        const data = await searchService.getEntity(entity, record[indexNameId])
        setSearch(data?.name)
      }
      if (Array.isArray(record && record[entity])) {
        setValues(
          record[entity].map(value => {
            if (typeof value === 'string') {
              return {
                id: value,
                name: ''
              }
            }
            return {
              id: String(value.id),
              name: String(value.name)
            }
          })
        )
      }
    }
    getEntity()
  }, [])

  useEffect(() => {
    if (onValuesChange) {
      onValuesChange(values.map(value => value.id))
    }
  }, [values])

  return (
    <Select
      id={`${entity}-select`}
      isLoading={loading}
      clearable={false}
      backspaceClearsInputValue
      maxDropdownHeight="250px"
      deleteRemoves={true}
      value={values}
      onOpen={() => setSearch('')}
      disabled={disabled}
      onChange={({ option, type }) => {
        if (type == 'remove') {
          setSearch('')
          setValues(values => values.filter(opt => opt.id !== option.id))
        } else {
          setSearch(option.name)
          setValues(values => [
            ...values,
            {
              name: String(option.name),
              id: String(option.id)
            }
          ])
        }
      }}
      onInputChange={evt => setSearch(evt.currentTarget.value)}
      options={hits}
      labelKey="name"
      {...props}
    />
  )
}
