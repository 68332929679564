import React, { useState } from 'react'

import Cookies from 'universal-cookie'
import { Layer } from 'baseui/layer'
import { useStyletron } from 'baseui'
import { SCHEDULER, SHIPPER } from '../models/User'
import Button from 'components/ui/specific/PrimaryButton'

export const LoginForm = ({
  title,
  signupMethod,
  userType,
  initialScreen,
  allowSignUp,
  buttonProps,
  prefillEmail
}: {
  title: string
  signupMethod: 'sms' | 'email' | 'password'
  userType?: typeof SCHEDULER | typeof SHIPPER
  initialScreen?: 'login' | 'signUp'
  allowSignUp?: boolean
  buttonProps?: any
  prefillEmail?: string
}) => {
  const cookies = new Cookies()
  const [css, theme] = useStyletron()
  const [showAnimation, setShowAnimation] = useState<boolean>(false)
  return (
    <>
      <Layer>
        <div
          className={css({
            pointerEvents: 'none',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: '#40404b',
            textAlign: 'center',
            transition: theme.animation.timing400,
            transform: 'translate3d(0,0,0)',
            opacity: showAnimation ? '1' : '0'
          })}
        />
      </Layer>
      <form
        action={`/auth/auth0?prefill_email=${
          prefillEmail || ''
        }&allow_sign_up=${allowSignUp}&signup_method=${signupMethod}&user_type=${userType}&initial_screen=${initialScreen}`}
        method="POST">
        <input type="hidden" name="authenticity_token" value={cookies.get('X-CSRF-Token')} />
        <Button
          onClick={() => {
            setShowAnimation(true)
          }}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: '100%',
                maxWidth: '420px',
                backgroundColor: $theme.colors.accent
              })
            }
          }}
          {...buttonProps}>
          {title}
        </Button>
      </form>
    </>
  )
}
