import { styled } from 'baseui'
import { Block } from 'baseui/block'

export const BlockConfirmationContainer = styled(Block, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  maxWidth: '670px',
  margin: 'auto',
  width: '100%',
  padding: '24px 32px'
})

export const ConfirmationTitle = styled('div', {
  color: '#082766',
  fontSize: '32px',
  fontFamily: 'Rubik',
  fontWeight: 600,
  lineHeight: '40px',
  letterSpacing: '0.15px',
  wordWrap: 'break-word',
  marginBottom: '.5rem'
})

export const ConfirmationBody = styled('div', {
  color: '#082766',
  fontSize: '16px',
  fontFamily: 'Inter',
  fontWeight: '500',
  lineHeight: '24px',
  wordWrap: 'break-word',
  marginBottom: '.5rem'
})

export const BlockConfirmationButtons = styled(Block, {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  alignItems: 'center',
  gridGap: 'scale500',
  marginTop: 'scale600'
})
