import React from 'react'
import Select from 'components/ui/generic/Select'

const hardcodedTimeOptions = [
  {
    id: '1970-01-01T00:00',
    label: '00:00'
  },
  { id: '1970-01-01T00:30', label: '00:30' },
  {
    id: '1970-01-01T01:00',
    label: '01:00'
  },
  { id: '1970-01-01T01:30', label: '01:30' },
  {
    id: '1970-01-01T02:00',
    label: '02:00'
  },
  { id: '1970-01-01T02:30', label: '02:30' },
  {
    id: '1970-01-01T03:00',
    label: '03:00'
  },
  { id: '1970-01-01T03:30', label: '03:30' },
  {
    id: '1970-01-01T04:00',
    label: '04:00'
  },
  { id: '1970-01-01T04:30', label: '04:30' },
  {
    id: '1970-01-01T05:00',
    label: '05:00'
  },
  { id: '1970-01-01T05:30', label: '05:30' },
  {
    id: '1970-01-01T06:00',
    label: '06:00'
  },
  { id: '1970-01-01T06:30', label: '06:30' },
  {
    id: '1970-01-01T07:00',
    label: '07:00'
  },
  { id: '1970-01-01T07:30', label: '07:30' },
  {
    id: '1970-01-01T08:00',
    label: '08:00'
  },
  { id: '1970-01-01T08:30', label: '08:30' },
  {
    id: '1970-01-01T09:00',
    label: '09:00'
  },
  { id: '1970-01-01T09:30', label: '09:30' },
  {
    id: '1970-01-01T10:00',
    label: '10:00'
  },
  { id: '1970-01-01T10:30', label: '10:30' },
  {
    id: '1970-01-01T11:00',
    label: '11:00'
  },
  { id: '1970-01-01T11:30', label: '11:30' },
  {
    id: '1970-01-01T12:00',
    label: '12:00'
  },
  { id: '1970-01-01T12:30', label: '12:30' },
  {
    id: '1970-01-01T13:00',
    label: '13:00'
  },
  { id: '1970-01-01T13:30', label: '13:30' },
  {
    id: '1970-01-01T14:00',
    label: '14:00'
  },
  { id: '1970-01-01T14:30', label: '14:30' },
  {
    id: '1970-01-01T15:00',
    label: '15:00'
  },
  { id: '1970-01-01T15:30', label: '15:30' },
  {
    id: '1970-01-01T16:00',
    label: '16:00'
  },
  { id: '1970-01-01T16:30', label: '16:30' },
  {
    id: '1970-01-01T17:00',
    label: '17:00'
  },
  { id: '1970-01-01T17:30', label: '17:30' },
  {
    id: '1970-01-01T18:00',
    label: '18:00'
  },
  { id: '1970-01-01T18:30', label: '18:30' },
  {
    id: '1970-01-01T19:00',
    label: '19:00'
  },
  { id: '1970-01-01T19:30', label: '19:30' },
  {
    id: '1970-01-01T20:00',
    label: '20:00'
  },
  { id: '1970-01-01T20:30', label: '20:30' },
  {
    id: '1970-01-01T21:00',
    label: '21:00'
  },
  { id: '1970-01-01T21:30', label: '21:30' },
  {
    id: '1970-01-01T22:00',
    label: '22:00'
  },
  { id: '1970-01-01T22:30', label: '22:30' },
  {
    id: '1970-01-01T23:00',
    label: '23:00'
  },
  { id: '1970-01-01T23:30', label: '23:30' },
  { id: '1970-01-01T24:00', label: '24:00' }
]

const CustomTimePicker = props => {
  const { value, disabled, onChange, ...restProps } = props

  return (
    <Select
      disabled={disabled}
      clearable={false}
      nullable
      value={value}
      onChange={onChange}
      options={hardcodedTimeOptions}
    />
  )
}

export default CustomTimePicker
