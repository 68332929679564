import React, { useState, useEffect } from "react";
import { Paragraph1 } from "baseui/typography";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { FormControl } from "baseui/form-control";
import { Button } from "baseui/button";
import { StatefulTooltip } from "baseui/tooltip";
import { startCase } from "lodash";
import authenticatedFetch from "../../utils/authenticated-fetch";
import TimeFormatter from "../../utils/time-formatter";
import { fancyToast } from "../../utils";
import CheckDriverIn from "../../checkins/check-driver-in";

const DriverInfo = ({ appointment, setAppointment }) => {
  const { checkinId } = appointment;

  const [checkin, setCheckin] = useState<any>(null);
  const [driver, setDriver] = useState<any>(null);
  const [facility, setFacility] = useState<any>(null);

  useEffect(() => {
    checkinId &&
      authenticatedFetch({
        path: `/checkins/${checkinId}.json`,
      }).then(([json, status]) => setCheckin(json));
  }, []);

  useEffect(() => {
    checkin &&
      !driver &&
      authenticatedFetch({
        path: `/users/${checkin.createdById}.json`,
      }).then(([json, status]) => setDriver(json));
  }, [checkin]);

  useEffect(() => {
    checkin &&
      !facility &&
      authenticatedFetch({
        path: `/facilities/${checkin.facilityId}.json`,
      }).then(([json, status]) => setFacility(json));
  }, [checkin]);

  const unlinkAppointment = async () => {
    const path = `/checkins/${checkin.id}.json`;
    const [json, status] = await authenticatedFetch({
      path,
      method: "PATCH",
      body: {
        checkin: {
          id: checkin.id,
          appointmentId: null,
          user: null,
          checkedOutAt: null,
        },
      },
    });

    if ([200, 204].includes(status)) {
      fancyToast({ info: "Appointment was unlinked." }, status);
      setCheckin(null);
    } else {
      fancyToast(json, status);
    }
  };

  const deleteCheckin = async () => {
    const [json, status] = await authenticatedFetch({
      path: `/checkins/${checkin.id}.json`,
      method: "DELETE",
    });
    if (status === 204) {
      fancyToast({ info: "Checkin was deleted." }, status);
      setCheckin(null);
    } else {
      fancyToast(json, status);
    }
  };

  if (!checkin || !driver || !facility) {
    return <CheckDriverIn {...{ appointment, setAppointment }} />;
  }

  return (
    <>
      <FlexGrid
        flexGridRowGap="scale400"
        flexGridColumnGap="scale400"
        flexGridColumnCount={3}
      >
        <FlexGridItem>
          <FormControl label="Driver Name">
            <Paragraph1>{driver.name}</Paragraph1>
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl label="Driver Phone Number">
            <Paragraph1>{driver.phoneNumber}</Paragraph1>
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl label="Carrier Name">
            <Paragraph1>{driver.carrierName}</Paragraph1>
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl label="Status">
            <Paragraph1>{startCase(checkin.status)}</Paragraph1>
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl label="Checked-in at">
            <Paragraph1>
              {new TimeFormatter("fullDate").format(
                checkin.createdAt,
                facility.timeZone
              )}
            </Paragraph1>
          </FormControl>
        </FlexGridItem>
        {checkin.checkedOutAt && (
          <FlexGridItem>
            <FormControl label="Checked-out at">
              <Paragraph1>
                {new TimeFormatter("fullDate").format(
                  checkin.checkedOutAt,
                  facility.timeZone
                )}
              </Paragraph1>
            </FormControl>
          </FlexGridItem>
        )}
      </FlexGrid>

      {!!checkin && (
        <>
          <StatefulTooltip
            triggerType="hover"
            placement="bottom"
            content="Unlink this driver from this appointment. This driver will show up on the dashboard, and can be linked to a different appointment."
          >
            <Button
              onClick={unlinkAppointment}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => {
                    return {
                      backgroundColor: $theme.colors.accent,
                      ":hover": {
                        backgroundColor: $theme.colors.accent500,
                      },
                      ":active": {
                        backgroundColor: $theme.colors.accent300,
                      },
                    };
                  },
                },
              }}
            >
              Unlink
            </Button>
          </StatefulTooltip>{" "}
          <StatefulTooltip
            triggerType="hover"
            content="Delete this checkin. This checkin & driver will be destroyed, and unable to be linked to a different appointment."
            placement="bottom"
          >
            <Button
              onClick={deleteCheckin}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => {
                    return {
                      backgroundColor: $theme.colors.negative,
                      ":hover": {
                        backgroundColor: $theme.colors.negative500,
                      },
                      ":active": {
                        backgroundColor: $theme.colors.negative300,
                      },
                    };
                  },
                },
              }}
            >
              Delete
            </Button>
          </StatefulTooltip>
        </>
      )}
    </>
  );
};

export default DriverInfo;
