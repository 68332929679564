import { StatusCodes } from 'components/constants/http-status-codes'
import { OrderPreference } from 'components/models/OrderPreference'
import orderPreferencesReducer, {
  SET_LOADING,
  SET_ORDER_PREFERENCES
} from 'components/reducers/orderPreferences.reducer'
import { orderPreferencesService } from 'components/services/order/orderPreferences.service'
import { fancyToast } from 'components/utils'
import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react'

export interface OrdersContextProps {
  orderPreferences: {
    orderPreference: OrderPreference
    loading: boolean
  }
}

const initialState: OrdersContextProps = {
  orderPreferences: {
    orderPreference: {
      customFieldLabelOne: '',
      customFieldLabelTwo: '',
      enableCustomFields: false
    },
    loading: false
  }
}

export const OrderPreferencesContext = createContext({} as any)

export const OrderPreferencesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(orderPreferencesReducer, initialState)

  useEffect(() => {
    loadOrderPreferences()
  }, [])

  const loadOrderPreferences = async () => {
    const [data, status] = await orderPreferencesService.getOrderPreferences()
    if (status === StatusCodes.OK && data.length > 0) {
      setOrderPreferences(data[0])
    }
  }

  const setOrderPreferences = value => {
    dispatch({ type: SET_ORDER_PREFERENCES, payload: value })
  }

  const setLoading = async value => {
    dispatch({ type: SET_LOADING, payload: value })
  }

  const saveChanges = useCallback(async () => {
    setLoading(true)
    if (state.orderPreferences.orderPreference?.id) {
      const [data, status] = await orderPreferencesService.updateOrderPreferences(
        state.orderPreferences.orderPreference
      )
      if (status === StatusCodes.OK) {
        fancyToast({ info: 'Preferences were successfully updated' }, status)
      }
    } else {
      const [data, status] = await orderPreferencesService.saveOrderPreferences(
        state.orderPreferences.orderPreference
      )
      if (status === StatusCodes.CREATED) {
        fancyToast({ info: 'Preferences were successfully updated' }, status)
      }
    }
    setLoading(false)
  }, [state.orderPreferences.orderPreference])

  const actions = {
    setLoading,
    setOrderPreferences,
    saveChanges
  }

  return (
    <OrderPreferencesContext.Provider value={{ ...state, actions }}>
      {children}
    </OrderPreferencesContext.Provider>
  )
}

export const useOrderPreferencesContext = () => useContext(OrderPreferencesContext)
