import { useCallback, useState } from 'react'
import { DEBOUNCE_SEARCH_TIME } from '../constants/debounce-search-time'
import _ from 'lodash'

function useDebounce(
  defaultValue = '',
  ms = DEBOUNCE_SEARCH_TIME
): [string, (value: string) => void] {
  const [value, setValue] = useState<string>(defaultValue)
  const [debouncedValue, setDebouncedValue] = useState<string>(defaultValue)

  const debounce = _.debounce((value: string) => {
    setDebouncedValue(value)
  }, ms)

  const setDebouncedCallback = useCallback((value: string) => {
    setValue(value)
    debounce(value)
  }, [])

  return [debouncedValue, setDebouncedCallback]
}

export default useDebounce
