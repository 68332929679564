export const isMyQInstance = (ids: string[]) => {
  const myQIdentifiers = [
    'a4f5816e-f043-4cde-b16d-3b503dffc6c2',
    'a9713010-f3bf-4218-945f-c34b89c79dc1',
    'myQ Enterprise',
    'The Chamberlain Group LLC'
  ]

  return ids.some(id => myQIdentifiers.includes(id))
}
