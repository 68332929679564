import { useContext, useEffect, useMemo, useState } from 'react'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { FacilityContext } from '../../contexts/facility.context'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import {
  ArrowLineDown,
  ArrowLineUp,
  BatteryFull,
  Check,
  Drop,
  LadderSimple,
  Question,
  Snowflake,
  Square,
  Truck,
  Van
} from '@phosphor-icons/react'
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { Block } from 'baseui/block'
import Select from 'components/ui/generic/Select'
import Divider from 'components/ui/generic/Divider'
import VInput from 'components/ui/generic/Input'
import ButtonGroup from 'components/components/ButtonGroup'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { ParagraphXSmall } from 'baseui/typography'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'

const SlotModal = ({ open, selectedSlot, zones, onSubmit, close }) => {
  const emptySlot = {
    id: '',
    name: '',
    zone: '',
    appointmentTypeIds: [],
    active: true,
    equipmentTypeIds: [],
    numberOfSlots: 1
  }
  const [slot, setSlot] = useState(selectedSlot || emptySlot)
  const { currentUser, assignedEquipmentTypes } = useContext(CurrentUserContext)
  const facility = useContext(FacilityContext)
  const { appointmentTypes } = facility
  const isAdmin = useMemo(() => !!currentUser?.admin, [currentUser?.admin])
  const [zoneValue, setZoneValue] = useState([{ label: slot?.zone, id: slot?.zone }])
  const [loading, setLoading] = useState<boolean>(false)
  const [numOfSlots, setNumOfSlots] = useState(1)
  const [css] = useStyletron()
  const { t } = useTranslation()
  const zoneOptions = zones.map(zone => ({ label: zone, id: zone }))

  useEffect(() => {
    if (selectedSlot) {
      setSlot(selectedSlot)
      setZoneValue([{ label: selectedSlot.zone, id: selectedSlot.zone }])
    }
  }, [selectedSlot])

  const onClose = () => {
    setSlot(emptySlot)
    setZoneValue([])
    close()
  }

  const handleSubmit = e => {
    setLoading(true)
    e.preventDefault()
    onSubmit(slot)
    setSlot(emptySlot)
    setZoneValue([])
    setLoading(false)
  }

  const equipmentTypeIcon = (icon: string) => {
    switch (icon) {
      case 'GiWoodenCrate':
      case 'FaTruckLoading':
      case 'FaPallet':
        return Van
      case 'Truck':
        return Truck
      case 'Snowflake':
        return Snowflake
      case 'GiWaterDrop':
        return Drop
      case 'LadderSimple':
        return LadderSimple
      case 'BatteryFull':
        return BatteryFull
      default:
        return Square
    }
  }

  const equipmentTypeButtons = assignedEquipmentTypes.map(equipmentType => {
    const IconComponent = equipmentTypeIcon(equipmentType.icon)
    return {
      label: equipmentType.name,
      value: equipmentType.id,
      icon: <IconComponent size={16} />
    }
  })

  const appointmentTypeIcon = (type: string) => {
    switch (type) {
      case 'Inbound':
        return ArrowLineDown
      case 'Outbound':
        return ArrowLineUp
      default:
        return Question
    }
  }

  const appointmentTypesButtons = appointmentTypes.map(appointmentType => {
    const IconComponent = appointmentTypeIcon(appointmentType.type)
    return {
      label: appointmentType.name,
      value: appointmentType.id,
      icon: <IconComponent size={16} />
    }
  })

  const formValidations = [
    {
      label: t('Yard.Modal.FormValidations.Zone.Text'),
      status: slot.zone !== ''
    },
    {
      label: t('Yard.Modal.FormValidations.AppTypes.Text'),
      status: slot.appointmentTypeIds?.length > 0
    },
    {
      label: t('Yard.Modal.FormValidations.EqTypes.Text'),
      status: slot.equipmentTypeIds?.length > 0
    },
    {
      label: t('Yard.Modal.FormValidations.NumberOfSlots.Text'),
      status: selectedSlot ? true : slot.numberOfSlots > 0
    },
    {
      label: t('Yard.Modal.FormValidations.Name.Text'),
      status: slot.name !== ''
    }
  ]

  return (
    <Modal unstable_ModalBackdropScroll isOpen={open} onClose={onClose}>
      <ModalHeader>
        <div
          data-testid="slot-modal-header"
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          })}>
          {selectedSlot ? t('Yard.Modal.Header.Update.Text') : t('Yard.Modal.Header.Create.Text')}
        </div>
        <Divider />
      </ModalHeader>

      <ModalBody>
        <Block marginBottom="scale800">{t('Yard.Modal.Body.Text')}</Block>
        <Select
          isLoading={loading}
          maxDropdownHeight="250px"
          creatable
          deleteRemoves
          backspaceClearsInputValue
          label="Yard zone"
          required
          value={zoneValue}
          onChange={params => {
            setZoneValue(params.value)
            setSlot({
              ...slot,
              zone: params.value[0].id
            })
          }}
          options={zoneOptions}
        />
        <Block marginBottom="scale800" />
        {selectedSlot ? (
          <VInput
            label={t('Yard.Modal.Fields.Name.Label.Text')}
            name="name"
            value={slot?.name}
            onChange={e => {
              setSlot({ ...slot, name: e.target.value })
            }}
          />
        ) : (
          <Block display="flex">
            <VInput
              disabled={loading}
              label={t('Yard.Modal.Fields.Name.Label.BulkText')}
              name="name"
              value={slot?.name}
              onChange={e => {
                setSlot({ ...slot, name: e.target.value })
              }}
            />
            <Block marginRight="scale300" />
            <VInput
              disabled={loading}
              label={t('Yard.Modal.Fields.NumberOfSlots.Label.Text')}
              name="numOfSlots"
              type="number"
              min="1"
              value={numOfSlots}
              onChange={e => {
                setNumOfSlots(e.target.value)
                setSlot({ ...slot, numberOfSlots: e.target.value })
              }}
            />
          </Block>
        )}
        <Block marginBottom="scale800" />
        <ButtonGroup
          mode="checkbox"
          label={t('Yard.Modal.Fields.EquipmentType.Label.Text')}
          buttons={equipmentTypeButtons}
          onChange={values => {
            const ids = values as string[]
            setSlot({ ...slot, equipmentTypeIds: ids.filter(v => v.length > 0) })
          }}
          value={slot.equipmentTypeIds}
        />
        <Block marginBottom="scale800" />
        <ButtonGroup
          disabled={loading}
          mode="checkbox"
          label={t('Yard.Modal.Fields.AppointmentType.Label.Text')}
          buttons={appointmentTypesButtons}
          onChange={values => {
            const ids = values as string[]
            setSlot({ ...slot, appointmentTypeIds: ids.filter(v => v.length > 0) })
          }}
          value={slot.appointmentTypeIds}
        />
        <Block marginBottom="scale800" />
        <Checkbox
          disabled={loading}
          checked={slot.active}
          onChange={() => setSlot({ ...slot, active: !slot.active })}
          labelPlacement={LABEL_PLACEMENT.left}>
          <ParagraphXSmall
            overrides={{
              Block: {
                style: () => ({
                  fontSize: '11px',
                  color: '#04112B',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '12px',
                  opacity: 0.6,
                  marginLeft: '6px'
                })
              }
            }}>
            {t('Yard.Modal.Fields.Status.Label.Text')}
          </ParagraphXSmall>
        </Checkbox>
      </ModalBody>

      <ModalFooter>
        {isAdmin && (
          <ErrorMessageButton
            endEnhancer={<Check />}
            errors={formValidations}
            onClick={handleSubmit}
            isLoading={loading}
            statefulTooltipProps={{ placement: 'top' }}
            label={
              selectedSlot
                ? t('Yard.Modal.Fields.Submit.Update.Text')
                : t('Yard.Modal.Fields.Submit.Create.Text')
            }
          />
        )}
      </ModalFooter>
    </Modal>
  )
}

export default SlotModal
