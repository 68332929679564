import { useStyletron } from 'baseui'
import { StyledLink } from 'baseui/link'
import React from 'react'
import { Link } from 'react-router-dom'

interface CyberLinkProps {
  href: string
  children: any
}
const CyberLink = (props: CyberLinkProps) => {
  const { href, children } = props
  const [css, theme] = useStyletron()
  return (
    <StyledLink to={href} $as={Link} className={css({ textDecoration: 'none !important' })}>
      {children}
    </StyledLink>
  )
}

export default CyberLink
