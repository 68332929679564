import React, { useState, useEffect } from 'react'
import Select from 'components/ui/generic/Select'
import { useTranslation } from 'react-i18next'
import { userService } from 'components/services/user.service'
import { StatusCodes } from 'components/constants/http-status-codes'
import { YARD_JOCKEY_AUDIENCE } from 'components/models/Role'
import { useDebounce } from 'react-use'
import { Block } from 'baseui/block'
import { useTrailerContext } from 'components/contexts/yard-trailer.context'

const SelectUserField = () => {
  const { state, actions } = useTrailerContext()
  const {
    taskModal: { task }
  } = state
  const { setTask } = actions

  const [options, setOptions] = useState([])
  const [search, setSearch] = useState(null)
  const { t } = useTranslation()

  const searchUsers = (name: string) =>
    userService
      .getUsersByRoleAudience([YARD_JOCKEY_AUDIENCE], name)
      .then(([data, result]) => {
        if (result === StatusCodes.OK) {
          setOptions(data)
        }
      })
      .catch(e => console.error(e))

  useEffect(() => {
    searchUsers(null)
  }, [])

  useDebounce(
    () => {
      setSearch(search)
      searchUsers(search)
    },
    200,
    [search]
  )

  return (
    <Block>
      <Select
        searchable={true}
        valueKey="value"
        label={t('YardTask.Kanban.TaskModal.Fields.AssignedTo.Text')}
        options={options}
        value={options.find(f => f.value === task?.userId)}
        placeholder=""
        onChange={params => {
          setTask({
            ...task,
            userId: params.value[0].value
          })
        }}
        onInputChange={evt => {
          setSearch(evt.currentTarget.value)
        }}
      />
    </Block>
  )
}

export default SelectUserField
