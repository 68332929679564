import http from 'components/utils/authenticated-fetch'
import { toast } from '../utils/toast'
import i18n from 'translations/i18n'
import { StatusCodes } from '../constants/http-status-codes'

class InviteService {
  inviteUser = async (
    user: Record<string, unknown>,
    shipperId: string,
    onClose?: () => void
  ): Promise<any> => {
    const [data, status] = await http({
      path: '/invites.json',
      method: 'POST',
      body: {
        invite: {
          userAttributes: {
            ...user,
            shipperId
          }
        }
      }
    })

    if (status === StatusCodes.CREATED) {
      toast(
        {
          info: i18n.t('Common.Info.Interpolated.Text', {
            model: i18n.t('Common.ModelType.CompanyVendorUser.Text'),
            action: i18n.t('Common.Actions.Invited.Text')
          })
        },
        200
      )
      if (onClose) {
        onClose()
      }
    } else {
      toast(
        {
          info: i18n.t('Common.Errors.Interpolated.Text', {
            model: i18n.t('Common.ModelType.VendorCompanyUser.Text'),
            action: i18n.t('Common.Actions.Created.Text')
          })
        },
        500
      )
    }
  }

  inviteCarrierUser = (user: Record<string, unknown>): Promise<any> =>
    http({
      path: '/invites/create_carrier_user.json',
      method: 'POST',
      body: {
        invite: {
          userAttributes: {
            ...user
          }
        }
      }
    }).then(data => data)

  updateCarrierUser = (user: Record<string, unknown>): Promise<any> =>
    http({
      path: `/users/${user.id}/update_carrier_user.json`,
      method: 'PATCH',
      body: { user: user }
    }).then(data => data)

  getCarrierUserByEmail = (email: string): Promise<any> => {
    let url = '/users.json'
    if (email) {
      const params = new URLSearchParams()
      params.append('search_carrier_user', email)
      url += `?${params.toString()}`
    }
    return http({
      path: url
    })
  }

  getCarrierUserById = (userId: string): Promise<any> => {
    let url = `/users/${userId}.json`
    return http({
      path: url
    })
  }

  deleteCarrierUser = (user: Record<string, unknown>): Promise<any> =>
    http({
      path: `/users/${user.id}/destroy_carrier_user.json`,
      method: 'DELETE'
    }).then(data => data)

  updateStatusCarrierUser = (user: Record<string, unknown>): Promise<any> =>
    http({
      path: `/users/${user.id}/update_status_carrier_user.json`,
      method: 'PATCH',
      body: {
        user: {
          disabled: user.disabled
        }
      }
    }).then(data => data)
}

export const inviteService = new InviteService()
