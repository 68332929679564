import http from 'components/utils/authenticated-fetch'
import { Task } from 'components/models/Task'
import { fancyToast } from 'components/utils'
import { StatusCodes } from 'components/constants/http-status-codes'
import { PAGE_SIZE } from 'components/constants/page-size'
import { TasksPaginationResponse } from 'components/types/pagination-response'

const DEFAULT_PARAMS = {
  search: '',
  page: 1,
  pageSize: PAGE_SIZE,
  queryParamsFilters: '',
  facilityId: '',
  date: null
}

class TaskService {
  getUrl = (taskId?: string) => `/tasks${taskId ? `/${taskId}.json` : '.json'}`

  getTaskById = (taskId: string): Promise<[any, number]> =>
    http({
      path: this.getUrl(taskId)
    })

  getTaskAndAppointmentById = (taskId: string): any =>
    http({
      path: `/tasks/${taskId}/get_task_and_appointment.json`
    })

  getTasks = (params: any): Promise<[any, number]> => {
    const url = this.getUrl()

    const query = new URLSearchParams()

    if (params?.facilityId) query.set('facility_id', params.facilityId)
    if (params?.date) query.set('date', params.date.toISOString())

    return http({
      path: `${url}?${query.toString()}`
    }).then(([tasks]) => tasks)
  }

  getTasksPaginated = (params = DEFAULT_PARAMS): Promise<TasksPaginationResponse<Task>> => {
    const query = new URLSearchParams()
    const { queryParamsFilters } = params
    if (params?.page) query.set('page', String(params.page))
    if (params?.pageSize) query.set('page_size', String(params.pageSize))
    if (params?.search) query.set('search', params.search)
    if (params?.facilityId) query.set('facility_id', params.facilityId)
    if (params?.date) query.set('date', params.date.toISOString())

    return http({
      path: `/tasks/paginate.json?${query.toString()}${
        queryParamsFilters ? queryParamsFilters : ''
      }`,
      method: 'GET'
    }).then(([data]) => data)
  }

  getTasksByUserPaginated = (params): Promise<any> => {
    const query = new URLSearchParams()
    if (params?.page) query.set('page', String(params.page))
    if (params?.pageSize) query.set('page_size', String(params.pageSize))
    if (params?.trailerNumber) query.set('trailer_number', params.trailerNumber)
    if (params?.activeTab) query.set('active_tab', params.activeTab)
    if (params?.flag) query.set('flag', params.flag)

    return http({
      path: `/tasks/get_tasks_for_user.json?${query.toString()}`,
      method: 'GET'
    }).then(([data]) => data)
  }

  createTask = async (task: Task): Promise<any> => {
    const [data, status] = await http({
      path: this.getUrl(),
      method: 'POST',
      body: {
        task: task
      }
    })

    if (status < StatusCodes.BAD_REQUEST && data) {
      fancyToast({ info: 'Task created successfully' }, StatusCodes.OK)
      return data
    } else {
      fancyToast({ info: data?.message || 'Something went wrong' }, StatusCodes.BAD_REQUEST)
      return null
    }
  }

  updateTask = async (task: Task): Promise<any> => {
    const [data, status] = await http({
      path: this.getUrl(task.id),
      method: 'PATCH',
      body: {
        task: task
      }
    })

    if (status < StatusCodes.BAD_REQUEST && data) {
      fancyToast({ info: 'Task updated successfully' }, StatusCodes.OK)
      return data
    } else {
      fancyToast({ info: data?.message || 'Something went wrong' }, StatusCodes.BAD_REQUEST)
      return null
    }
  }

  updateAssignedTask = async (task: Task): Promise<any> => {
    const [data, status] = await http({
      path: `/tasks/${task.id}/update_assigned_task.json`,
      method: 'PUT',
      body: {
        task: task
      }
    })

    return [data, status]
  }

  deleteTask = async (task: Task): Promise<any> => {
    const [data, status] = await http({
      path: this.getUrl(task.id),
      method: 'DELETE'
    })

    if (status < StatusCodes.BAD_REQUEST) {
      fancyToast({ info: 'Task deleted successfully' }, StatusCodes.OK)
      return task
    } else {
      fancyToast({ info: data?.message || 'Something went wrong' }, StatusCodes.BAD_REQUEST)
      return null
    }
  }

  assign = async (
    trailerId: string,
    dockId: string,
    slotId: string,
    facilityId: string,
    userId: string
  ): Promise<any> => {
    const params = new URLSearchParams()

    if (trailerId) {
      params.append('trailer_id', trailerId)
    }

    if (facilityId) {
      params.append('facility_id', facilityId)
    }

    if (dockId) {
      params.append('dock_id', dockId.toString())
    }

    if (slotId) {
      params.append('slot_id', slotId.toString())
    }

    if (userId) {
      params.append('user_id', userId)
    }

    return http({
      path: `/tasks/assign.json?${params.toString()}`,
      method: 'PUT'
    })
  }
}

export const taskService = new TaskService()
