import { HeadingXSmall } from 'baseui/typography'
import { selectedColumnId } from 'components/contexts/equipment-types.context'
import { EquipmentType } from 'components/models/DockCapacity'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { DragZoneContainer } from './DroppableColumn.styled'
import { Item } from './Item'

interface ColumnProps {
  col: {
    id: string
    title: string
    list: EquipmentType[]
  }
}

export const Column: React.FC<ColumnProps> = ({ col: { id, title, list } }) => {
  const isActive = id === selectedColumnId
  return (
    <Droppable droppableId={id}>
      {provided => (
        <DragZoneContainer>
          <HeadingXSmall margin="0 0 8px">{title}</HeadingXSmall>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '180px'
            }}
            {...provided.droppableProps}
            ref={provided.innerRef}>
            {list.map((item, index) => (
              <Item key={item.id} equipmentType={item} index={index} isActive={isActive} />
            ))}
            {provided.placeholder}
          </div>
        </DragZoneContainer>
      )}
    </Droppable>
  )
}
