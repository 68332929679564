import React from "react";
import { Route } from "react-router-dom";
import CreateCheckin from "./CreateCheckin";
import CheckinForm from "./checkin-form";

const CheckinRoutes = ({ match }) => (
  <>
    <Route path={match.url} exact component={CreateCheckin} />
    <Route path={`${match.url}/:handle`} component={CheckinForm} />
  </>
);

export default CheckinRoutes;
