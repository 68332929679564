import http from 'components/utils/authenticated-fetch'
import { Milestones } from '../models/Milestones'

class MilestoneService {
  get = (id: string): Promise<[any, number]> =>
    http({
      path: `/checkins/${id}.json`,
      method: 'GET'
    })

  update = async (checkin: Milestones): Promise<any> =>
    http({
      path: `/checkins.json`,
      method: 'POST',
      body: { checkin }
    })
}

export const milestoneService = new MilestoneService()
