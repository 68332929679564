import { styled } from 'baseui'

export const InfoBoxContainer = styled('div', ({ $theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: '#F2F6FF',
  border: $theme.borders.border200,
  borderRadius: $theme.borders.popoverBorderRadius,
  padding: $theme.sizing.scale400
}))

export const InfoBoxWrapper = styled('div', {})

export const ModalTopLevel = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  rowGap: '10px'
})

export const ModalLeftForm = styled('div', {
  marginRight: '60px',
  maxWidth: '300px'
})

export const InfoBoxUnorderList = styled('ul', {
  marginLeft: '20px',
  fontSize: '10px'
})
