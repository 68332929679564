import { KIND } from 'baseui/button'
import { FlexGrid } from 'baseui/flex-grid'
import { HeadingLevel } from 'baseui/heading'
import { ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import React, { useState } from 'react'

import { IconList, IconPicker } from 'react-fa-icon-picker'
import { Color, ColorPicker, toColor } from 'react-color-palette'
import 'react-color-palette/lib/css/styles.css'
import { useEquipmentTypesContext } from 'components/contexts/equipment-types.context'
import { trim } from 'lodash'
import { useTranslation } from 'react-i18next'

import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'

type EqTypeForm = {
  id?: string
  name?: string
  icon?: IconList
  color?: Color
}

const initialValue: EqTypeForm = {
  name: '',
  color: toColor('hex', '#ffffff'),
  icon: 'Fa500Px'
}

export const EquipmentTypeForm = ({ id, onClose }) => {
  const {
    equipmentTypeState,
    actions: { createEqType, updateEqType }
  } = useEquipmentTypesContext()
  const { t } = useTranslation()
  const { selectedEquipmentType, saving } = equipmentTypeState
  const [equipmentType, setEqupmentType] = useState<EqTypeForm>(
    id
      ? {
          ...selectedEquipmentType,
          color: toColor('hex', selectedEquipmentType.color)
        }
      : initialValue
  )

  const onSubmit = () => {
    const { color, name } = equipmentType
    const eqType = { ...equipmentType, color: color.hex, name: trim(name) }
    if (equipmentType.id) {
      updateEqType(eqType)
    } else {
      createEqType(eqType)
    }
  }

  const requiredFieldsPresent = [
    {
      label: t('Settings.EquipmentTypes.Modal.Validations.NameRequired.Text'),
      status: !!equipmentType?.name
    },
    {
      label: t('Settings.EquipmentTypes.Modal.Validations.IconRequired.Text'),
      status: !!equipmentType?.icon
    },
    {
      label: t('Settings.EquipmentTypes.Modal.Validations.ColorRequired.Text'),
      status: !!equipmentType?.color
    }
  ]

  const onFieldChange = (e, fieldName: string, isEvent = false): void => {
    const value = isEvent ? e.currentTarget.value : e
    setEqupmentType({
      ...equipmentType,
      [fieldName]: value
    })
  }

  return (
    <HeadingLevel>
      <ModalHeader>
        {id
          ? t('Settings.EquipmentTypes.Modal.Header.Alternatives.Update.Text')
          : t('Settings.EquipmentTypes.Modal.Header.Alternatives.Create.Text')}{' '}
        {t('Settings.EquipmentTypes.Modal.Header.Text')}
      </ModalHeader>
      <ModalBody>
        <FlexGrid flexGridColumnCount={2} flexGridRowGap="scale200" flexGridColumnGap="scale1200">
          <FormControl label={t('Settings.EquipmentTypes.Modal.Fields.Name.Label.Text')}>
            <Input
              id="name"
              autoComplete="off"
              value={equipmentType?.name}
              onChange={e => {
                onFieldChange(e, 'name', true)
              }}
              maxLength={30}
            />
          </FormControl>
          <FormControl label={t('Settings.EquipmentTypes.Modal.Fields.Icon.Label.Text')}>
            <IconPicker
              value={equipmentType.icon}
              onChange={e => {
                onFieldChange(e, 'icon')
              }}></IconPicker>
          </FormControl>
          <FormControl label={t('Settings.EquipmentTypes.Modal.Fields.Color.Label.Text')}>
            <ColorPicker
              width={456}
              height={228}
              color={equipmentType.color}
              onChange={e => {
                onFieldChange(e, 'color')
              }}
            />
          </FormControl>
        </FlexGrid>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          overrides={{ BaseButton: { style: { marginRight: '10px' } } }}
          kind={KIND.secondary}
          size="compact"
          onClick={onClose}>
          {t('Settings.EquipmentTypes.Modal.CancelButton.Text')}
        </ModalButton>
        <ErrorMessageButton
          errors={requiredFieldsPresent}
          onClick={onSubmit}
          isLoading={saving}
          statefulTooltipProps={{ placement: 'top' }}
          label={
            id
              ? t('Settings.EquipmentTypes.Modal.ConfirmButton.Update.Text')
              : t('Settings.EquipmentTypes.Modal.ConfirmButton.Create.Text')
          }
        />
      </ModalFooter>
    </HeadingLevel>
  )
}
