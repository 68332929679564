import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { HeadingMedium, ParagraphMedium } from 'baseui/typography'
import React, { ReactElement } from 'react'

const Fallback = (): ReactElement => (
  <FlexGrid justifyContent="center" alignItems="center">
    <FlexGridItem justifyContent="center" display="flex" alignItems="center" paddingTop="20vh">
      <div
        style={{
          backgroundColor: 'rgb(1, 73, 145)',
          maxWidth: '450px',
          margin: 15,
          padding: '10px 25px 25px',
          textAlign: 'center'
        }}>
        <HeadingMedium color="var(--fc-event-text-color)">
          Oops, Something Went Wrong!
        </HeadingMedium>
        <ParagraphMedium color="var(--fc-event-text-color)" $style={{ opacity: '0.7' }}>
          We&apos;re experiencing technical difficulties. The page encountered a rendering error and
          couldn&apos;t load properly. Our team is aware and working to fix it. Please try again
          later or contact support. Thank you for your patience.
        </ParagraphMedium>
      </div>
    </FlexGridItem>
  </FlexGrid>
)

export default Fallback
