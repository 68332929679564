import { useStyletron } from 'baseui'
import { BLACKOUT_TYPE } from 'components/constants/blackout-type'
import { combineDateAndTimeInISO } from 'components/utils/combine-date-and-time'
import { DateTime } from 'luxon'
import { useContext, useEffect, useState } from 'react'
import { RRule } from 'rrule'
import { StatusCodes } from '../../../constants/http-status-codes'
import { dockBlockService } from '../../../services/dock-blocks.service'
import { FacilitiesContext } from '../context/facilities-context'
import { FullCalendarEvent } from '../types'
import { FacilityOption } from './use-default-facilities'
import { useDockAssigmentContext } from '../../../contexts/dock-assigment.context'

interface DockBlock {
  dockId: string
  endDate: string
  endTime: string
  allDay: boolean
  facilityId: string
  id: string
  name: string
  startDate: string
  startTime: string
  dock: { equipmentTypeId: string }
  daysOfWeek?: boolean
  schedule: Record<string, unknown>
  rrule: RRule
}

const resolveDaysOfWeek = (daysOfWeek: number[]) =>
  daysOfWeek.map(day => {
    switch (day) {
      case 0:
        return RRule.SU
      case 1:
        return RRule.MO
      case 2:
        return RRule.TU
      case 3:
        return RRule.WE
      case 4:
        return RRule.TH
      case 5:
        return RRule.FR
      case 6:
        return RRule.SA
      default:
        return RRule.MO
    }
  })

const DAY_IN_MILISECONDS = 60 * 60 * 24 * 1000
const WEEK_IN_MILISECONDS = 60 * 60 * 24 * 1000 * 6
const TILES_IN_CALENDAR = 1

function useBlackouts({
  selectedEvent,
  selectedFacilities,
  weekMode = false,
  dockAssigment = false,
  blackoutRefresh = false
}) {
  const [, theme] = useStyletron()
  const [blackouts, setBlackouts] = useState([])
  const { selectedDate, facilities } = useContext(FacilitiesContext)
  const [firstSelectedDateChange, setFirstSelectedDateChange] = useState(true)
  const { selectedFacility } = useDockAssigmentContext()

  useEffect(() => {
    if (!firstSelectedDateChange) {
      dockBlockService
        .getDockBlocks(
          selectedDate.toISOString(),
          new Date(
            selectedDate.getTime() + DAY_IN_MILISECONDS + (weekMode && WEEK_IN_MILISECONDS)
          ).toISOString(),
          dockAssigment
        )
        .then(([json, status]) => {
          if (StatusCodes.OK === status) {
            setBlackouts(json)
          }
        })
    } else {
      setFirstSelectedDateChange(false)
    }
  }, [selectedEvent, selectedDate, firstSelectedDateChange, blackoutRefresh, weekMode])

  const selectedFacilitiesIds = selectedFacilities.map((facility: FacilityOption) => facility?.id)

  return blackouts
    ?.filter(({ facilityId }) => selectedFacilitiesIds?.includes(facilityId))
    .map((blackout: DockBlock): FullCalendarEvent => {
      const {
        id,
        startDate,
        startTime,
        allDay,
        endDate,
        endTime,
        name,
        facilityId,
        daysOfWeek,
        schedule,
        dockId
      } = blackout

      const timezone = facilities[facilityId]?.timeZone ?? selectedFacility?.timeZone

      const startTimeDate = DateTime.fromISO(
        combineDateAndTimeInISO(
          (!allDay && schedule) || daysOfWeek ? selectedDate : startDate,
          startTime
        ),
        {
          zone: timezone
        }
      )
      const endTimeDate = DateTime.fromISO(
        combineDateAndTimeInISO(
          (!allDay && schedule) || daysOfWeek ? selectedDate : endDate,
          endTime
        ),
        {
          zone: timezone
        }
      )

      let rrule
      rrule = {
        freq: RRule.WEEKLY,
        ...(schedule && { byweekday: resolveDaysOfWeek(schedule.rrules[0].validations.day) }),
        dtstart: combineDateAndTimeInISO(startDate, startTime),
        until: combineDateAndTimeInISO(endDate, endTime),
        tzid: timezone,
        ...(!daysOfWeek && { count: TILES_IN_CALENDAR })
      }

      const diff = endTimeDate.diff(startTimeDate, ['hours', 'minutes'])

      return {
        id,
        color: theme.colors.mono800,
        start: startTimeDate.toISO(),
        end: endTimeDate.toISO(),
        duration: {
          hours: Math.floor(diff.hours),
          minutes: Math.floor(diff.minutes % 60)
        },
        title: `Closed:  ${name}`,
        extendedProps: {
          type: BLACKOUT_TYPE,
          daysOfWeek,
          name
        },
        resourceId: dockAssigment ? dockId : facilityId,
        allDay: false,
        rrule
      } as FullCalendarEvent
    })
}

export default useBlackouts
