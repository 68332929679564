/* eslint-disable react/jsx-props-no-spreading */
import { StatefulPanel } from 'baseui/accordion'
import { Block } from 'baseui/block'
import { Card } from 'baseui/card'
import { LABEL_PLACEMENT } from 'baseui/checkbox'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Checkbox from 'components/ui/generic/Checkbox'
import { AddButton } from 'components/components/AddButton'
import { RemoveButton } from 'components/components/RemoveButton'
import { CurrentUserContext } from 'components/homepage/current-user-context'

import React, { useContext, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { DOCUMENT_TYPE, DROPDOWN_LIST, TEXT_TYPE } from '../../models/Question'
import { userRoleService } from '../../services/user-roles.service'
import AnswerType from './answer-type'
import DockTypes from './dock-types'
import DropdownList from './DropdownList'
import MaxLengthInput from './max-length'
import MinLengthInput from './min-length'
import QuestionPermissions from './QuestionPermissions/QuestionPermissions'
import { DotsSix, CaretUp, CaretDown } from '@phosphor-icons/react'

const CreateCustomQuestions = ({
  appointmentType,
  onAppointmentTypeChange,
  control,
  setValue,
  getValues,
  reset
}) => {
  const [questions, setQuestions] = useState(appointmentType.questions)
  const { shipperEquipmentTypes } = useContext(CurrentUserContext)
  const [listOfEnabledRoles, setListOfEnabledRoles] = useState([])
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  const reorderQuestions = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result.map((question: any, index: number) => {
      return { ...question, position: index + 1 }
    })
  }

  const reorderDropdownLists = (list, startIndex, endIndex) => {
    const dropdownValues = Array(list.length)
    Object.entries(getValues()).forEach(([key, value]) => {
      dropdownValues[key] = value
    })
    const [removedList] = dropdownValues.splice(startIndex, 1)
    dropdownValues.splice(endIndex, 0, removedList)
    const updatedValues = {}
    dropdownValues.forEach((valuesList, index) => {
      if (valuesList) {
        updatedValues[index] = valuesList
      }
    })
    reset()
    Object.entries(updatedValues).forEach(([k, v]) => {
      setValue(k, v)
    })
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    outline: `1px auto ${isDragging ? theme.colors.accent : 'transparent'}`,
    marginBottom: theme.sizing.scale0,
    ...draggableStyle
  })

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? theme.colors.backgroundTertiary : theme.colors.inputFill,
    borderRadius: theme.borders.radius100,
    overflow: 'hidden',
    width: '100%'
  })

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    setQuestions(reorderQuestions(questions, result.source.index, result.destination.index))
    reorderDropdownLists(questions, result.source.index, result.destination.index)
  }

  useEffect(() => {
    onAppointmentTypeChange({
      ...appointmentType,
      questionsAttributes: questions,
      questions
    })
  }, [questions])

  useEffect(() => {
    setQuestions(appointmentType.questions)
  }, [appointmentType.questions])

  useEffect(() => {
    userRoleService
      .getUserRoles(undefined, undefined, true)
      .then(([roles]) => setListOfEnabledRoles(roles))
  }, [])

  const handleAddQuestion = e => {
    e.preventDefault()
    setQuestions(prevQuestions => [
      ...prevQuestions,
      {
        answerType: 'text',
        dockTypes: shipperEquipmentTypes,
        disabled: false,
        isNew: true,
        position: prevQuestions.length + 1,
        prompt: '-'
      }
    ])
  }

  const handleRemoveQuestion = (position: number) => {
    setQuestions(questions => questions.filter((_, i: number) => i !== position))
  }

  const handleUpdateTextField = (evt, index, fieldName) => {
    const newQuestions = [...questions]
    newQuestions[index][fieldName] = evt.currentTarget.value
    setQuestions(newQuestions)
  }

  const handleUpdateField = (key: string, value: unknown, position: number) => {
    setQuestions(questions =>
      questions.map((question: any, i: number) =>
        i === position ? { ...question, [key]: value } : question
      )
    )
  }

  return (
    <Block>
      <Block display="flex" marginTop="scale400" marginBottom="scale600">
        <AddButton onClick={handleAddQuestion} title="Create custom question" />
      </Block>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}>
              {questions.map((question, index) => (
                <Draggable
                  key={String(question.position)}
                  draggableId={String(question.position)}
                  index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                      <StatefulPanel
                        title={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span {...provided.dragHandleProps}>
                              <DotsSix style={{ marginRight: '20px' }} />
                            </span>
                            {question.prompt}
                          </div>
                        }
                        key={question.id ?? question.position}
                        overrides={{
                          ToggleIcon: ({ $expanded }) => {
                            if ($expanded) {
                              return <CaretUp />
                            }
                            return <CaretDown />
                          }
                        }}>
                        <Card
                          overrides={{
                            Root: {
                              style: ({ $theme }) => ({
                                borderTopColor: $theme.colors.inputBorder,
                                borderBottomColor: $theme.colors.inputBorder,
                                borderLeftColor: $theme.colors.inputBorder,
                                borderRightColor: $theme.colors.inputBorder,
                                borderTopWidth: $theme.borders.borderWidth,
                                borderBottomWidth: $theme.borders.borderWidth,
                                borderLeftWidth: $theme.borders.borderWidth,
                                borderRightWidth: $theme.borders.borderWidth
                              })
                            }
                          }}>
                          <FlexGrid
                            flexGridColumnCount={1}
                            flexGridColumnGap="scale200"
                            flexGridRowGap="scale200">
                            <FlexGridItem
                              {...{
                                display: 'flex'
                              }}>
                              <FlexGrid
                                flexGridColumnCount={[1, 1, 1, 2]}
                                flexGridRowGap="scale200"
                                flexGridColumnGap="scale800">
                                <FlexGridItem>
                                  <FormControl
                                    label={t(
                                      'Settings.AppointmentTypes.CustomQuestions.NameOfQuestion'
                                    )}>
                                    <Input
                                      autoComplete="off"
                                      name={`question[${question.id}][prompt]`}
                                      value={question.prompt}
                                      onChange={evt => handleUpdateTextField(evt, index, 'prompt')}
                                    />
                                  </FormControl>
                                  <AnswerType
                                    questions={questions}
                                    setQuestions={setQuestions}
                                    index={index}
                                  />
                                </FlexGridItem>
                                <FlexGridItem>
                                  <FormControl
                                    label={t('Settings.AppointmentTypes.CustomQuestions.Caption')}>
                                    <Input
                                      autoComplete="off"
                                      name={`question[${question.id}][caption]`}
                                      value={question.caption}
                                      onChange={evt => handleUpdateTextField(evt, index, 'caption')}
                                    />
                                  </FormControl>
                                  {question.answerType === TEXT_TYPE && (
                                    <FlexGrid
                                      flexGridColumnCount={2}
                                      flexGridRowGap="scale200"
                                      flexGridColumnGap="scale200">
                                      <FlexGridItem>
                                        <MinLengthInput
                                          questions={questions}
                                          setQuestions={setQuestions}
                                          index={index}
                                        />
                                      </FlexGridItem>
                                      <FlexGridItem>
                                        <MaxLengthInput
                                          questions={questions}
                                          setQuestions={setQuestions}
                                          index={index}
                                        />
                                      </FlexGridItem>
                                    </FlexGrid>
                                  )}
                                </FlexGridItem>
                                <FlexGridItem>
                                  <DockTypes
                                    questions={questions}
                                    setQuestions={setQuestions}
                                    index={index}
                                  />
                                </FlexGridItem>
                                <FlexGridItem>
                                  {question.answerType !== DOCUMENT_TYPE && (
                                    <>
                                      <Checkbox
                                        checked={question.identifier}
                                        onChange={() => {
                                          const newQuestions = [...questions]
                                          newQuestions[index].identifier =
                                            !newQuestions[index].identifier
                                          setQuestions(newQuestions)
                                        }}
                                        labelPlacement={LABEL_PLACEMENT.right}
                                        label={t(
                                          'Settings.AppointmentTypes.CustomQuestions.Identifier'
                                        )}
                                      />
                                      {question.AnswerType !== DOCUMENT_TYPE && (
                                        <Checkbox
                                          checked={question.showOnPrint}
                                          onChange={() => {
                                            const newQuestions = [...questions]
                                            newQuestions[index].showOnPrint =
                                              !newQuestions[index].showOnPrint
                                            setQuestions(newQuestions)
                                          }}
                                          labelPlacement={LABEL_PLACEMENT.right}
                                          label={t(
                                            'Settings.AppointmentTypes.CustomQuestions.ShowOnPrintSchedule'
                                          )}
                                        />
                                      )}
                                      {question.answerType === TEXT_TYPE && (
                                        <Checkbox
                                          checked={question.numericOnly}
                                          onChange={() => {
                                            const newQuestions = [...questions]
                                            newQuestions[index].numericOnly =
                                              !newQuestions[index].numericOnly
                                            setQuestions(newQuestions)
                                          }}
                                          labelPlacement={LABEL_PLACEMENT.right}
                                          label={t(
                                            'Settings.AppointmentTypes.CustomQuestions.Numeric'
                                          )}
                                        />
                                      )}
                                    </>
                                  )}
                                  <Checkbox
                                    checked={question?.disabled}
                                    onChange={evt =>
                                      handleUpdateField(
                                        'disabled',
                                        evt.currentTarget.checked,
                                        index
                                      )
                                    }
                                    labelPlacement={LABEL_PLACEMENT.right}
                                    label={t('Settings.AppointmentTypes.CustomQuestions.Disabled')}
                                  />
                                </FlexGridItem>
                                <FlexGridItem>
                                  <Block>
                                    {question?.isNew && (
                                      <RemoveButton
                                        confirmation={false}
                                        onDelete={() => handleRemoveQuestion(index)}
                                      />
                                    )}
                                  </Block>
                                </FlexGridItem>
                              </FlexGrid>
                            </FlexGridItem>
                            <FlexGridItem
                              {...{
                                display: 'flex',
                                justifyContent:
                                  questions[index]?.answerType === DROPDOWN_LIST
                                    ? 'space-around'
                                    : 'left',
                                marginLeft: '10px'
                              }}>
                              {questions[index]?.answerType === DROPDOWN_LIST && (
                                <DropdownList index={index} control={control} setValue={setValue} />
                              )}
                              <QuestionPermissions
                                questions={questions}
                                listOfEnabledRoles={listOfEnabledRoles}
                                setQuestions={setQuestions}
                                index={index}
                              />
                            </FlexGridItem>
                          </FlexGrid>
                        </Card>
                      </StatefulPanel>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Block>
  )
}

export default CreateCustomQuestions
