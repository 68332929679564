import { SearchScope } from 'components/constants/scoped-key-type'
import { TypesenseHostContext } from 'components/homepage'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { useContext, useEffect, useState } from 'react'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'

export const GLOBAL_SCOPE = 'GLOBAL_SCOPE'

const useSearchClient = (additionalSearchParameters, scoped_key: SearchScope = GLOBAL_SCOPE) => {
  const { currentUser } = useContext(CurrentUserContext)
  const { typesenseHost } = useContext(TypesenseHostContext)
  const [searchClient, setSearchClient] = useState(null)

  const getSearchScopeKey = () => {
    return scoped_key === 'CARRIER_USER_SCOPE'
      ? currentUser.carrierUsersScopedSearchKey
      : currentUser.scopedSearchKey
  }

  useEffect(() => {
    if (currentUser) {
      const typesenseInstantSearchAdapter = new TypesenseInstantSearchAdapter({
        additionalSearchParameters,
        server: {
          // Be sure to use the search-only-api-key
          apiKey: getSearchScopeKey(),
          nodes: [
            {
              host: typesenseHost,
              port: '443',
              protocol: 'https'
            }
          ]
        }
      })
      setSearchClient(typesenseInstantSearchAdapter.searchClient)
    }
  }, [currentUser])

  return searchClient
}

export default useSearchClient
