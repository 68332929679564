import http from 'components/utils/authenticated-fetch'

class RecurringAppointmentBlueprintService {
  create = (recurringAppointmentBlueprint: Record<string, unknown>): Promise<[any, number]> =>
    http({
      path: `/recurring_appointment_blueprint.json`,
      method: 'POST',
      body: {
        recurringAppointmentBlueprint
      }
    })

  delete = (
    recurringAppointmentBlueprint: Record<string, unknown>,
    id: string
  ): Promise<[any, number]> =>
    http({
      path: `/recurring_appointment_blueprint/${id}.json`,
      method: 'DELETE',
      body: {
        recurringAppointmentBlueprint
      }
    })

  update = (
    recurringAppointmentBlueprint: Record<string, unknown>,
    id: string,
    deleteFromDate?: string
  ): Promise<[any, number]> =>
    http({
      path: `/recurring_appointment_blueprint/${id}.json?delete_from_date=${deleteFromDate}`,
      method: 'PATCH',
      body: {
        recurringAppointmentBlueprint
      }
    })
}

export const recurringAppointmentBlueprintService = new RecurringAppointmentBlueprintService()
