import React from 'react'
import { CustomCancelReasonsContainer } from './CustomCancelReasonsContainer'
import { CustomCancelReasonsProvider } from '../../contexts/custom-cancel-reason.context'

export const CustomCancelReasons = () => {
  return (
    <CustomCancelReasonsProvider>
      <CustomCancelReasonsContainer />
    </CustomCancelReasonsProvider>
  )
}
