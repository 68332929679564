import React, { useContext } from "react";
import { findIndex } from "lodash";
import { useStyletron } from "baseui";
import FileUploader from "../../../shared/file-uploader";
import { AppointmentContext } from "../../appointment-form";

const DocumentAnswer = ({ answer, question }) => {
  const [css] = useStyletron();
  const { attachedDocuments, documents } = answer;
  const { id } = question;
  const { appointment, setAppointment } = useContext(AppointmentContext);

  return (
    <>
      <FileUploader
        multiple
        onError={console.log}
        onSuccess={(signedIds) => {
          const { answersAttributes } = appointment;
          const newAnswersAttributes = answersAttributes
            ? [...answersAttributes]
            : [];
          const existingAnswerIndex = findIndex(newAnswersAttributes, {
            questionId: id,
          });
          const newAnswer =
            existingAnswerIndex === -1
              ? { ...answer }
              : newAnswersAttributes[existingAnswerIndex];
          newAnswer.questionId = id;
          newAnswer.documents = signedIds;
          newAnswersAttributes.splice(existingAnswerIndex, 1, newAnswer);
          setAppointment({
            ...appointment,
            answersAttributes: newAnswersAttributes,
          });
        }}
      />
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
        })}
      >
        {attachedDocuments &&
          !documents &&
          attachedDocuments.map((attachedDocument, index) => {
            const { url, name } = attachedDocument;
            return (
              <a key={index} href={url}>
                {name}
              </a>
            );
          })}
      </div>
    </>
  );
};

export default DocumentAnswer;
