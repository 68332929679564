import React from 'react'

export const EN_US = 'en-US'
export const FR_CA = 'fr-CA'
export const ES = 'es'

export type LocaleString = typeof EN_US | typeof FR_CA | typeof ES

interface LocaleContextProps {
  locale: LocaleString
  setLocale: (localeString: LocaleString) => void
}

export const LocaleContext = React.createContext<LocaleContextProps>({
  locale: EN_US,
  setLocale: () => {}
})
