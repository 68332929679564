import React, { useContext, useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import { useStyletron } from 'baseui'
import moment from 'moment'
import { Checkbox } from 'baseui/checkbox'
import { CalendarToggleContext } from '../../../contexts/calendar-toggle-context'
import authenticatedFetch from '../../../utils/authenticated-fetch'
import { fancyToast } from '../../../utils'
import AppointmentTypeQuestionsAndAnswers from '../../appointment-type-questions-and-answers'
import { DRIVER } from '../../../models/User'
import CreateDriver from './create-driver'
import ErrorMessageButton from '../../error-message-button'
import IntegrationModal from '../../../checkins/integration-modal'
import { ON_TIME, EARLY, LATE } from '../../../checkins/check-in-statuses'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { CurrentUserContext } from '../../../homepage/current-user-context'

interface GatehouseCheckinProps {
  isOpen: boolean
  close: () => void
  event: {
    appointmentId: string
    [key: string]: unknown
  }
}

const CREATE_DRIVER = 'createDriver'

const GatehouseCheckin = (props: GatehouseCheckinProps): JSX.Element => {
  const [css, theme] = useStyletron()

  const { isOpen, close, event } = props

  const { selectedDate, setSelectedDate } = useContext(CalendarToggleContext)
  const { currentUser } = useContext(CurrentUserContext)

  const [appointment, setAppointment] = useState(null)
  const [user, setUser] = useState<{
    id?: string
    name?: string
    carrierName?: string
    phoneNumber?: string
    carrierId?: string
  }>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [checkingIn, setCheckingIn] = useState<boolean>(false)
  const [waitInYard, setWaitInYard] = useState<boolean>(false)
  const [createDriver, setCreateDriver] = useLocalStorage<boolean>(CREATE_DRIVER, true)

  const checkInErrors = [
    {
      label: 'Driver must have a carrier, name, & phone number.',
      status: !createDriver || (user.name && user.carrierName && user.phoneNumber)
    },
    {
      label: 'All questions need to be answered.',
      status: appointment?.answersAttributes?.every(answer => {
        return !!answer?.response
      })
    }
  ]

  useEffect(() => {
    if (appointment) {
      return
    }

    authenticatedFetch({
      path: `/appointments/${event.appointmentId}.json`
    }).then(([json, status]) => {
      if (status === 200) {
        setAppointment(json)
      }
    })
  }, [appointment, event.appointmentId])

  useEffect(() => {
    if (createDriver) {
      setUser({})
    } else {
      setUser(currentUser)
    }
  }, [createDriver, currentUser])

  const createUser = async () => {
    const body = {
      user: {
        ...user,
        userType: DRIVER
      }
    }
    const [json, status] = await authenticatedFetch({
      path: `/users.json`,
      method: 'POST',
      body
    })

    if (status === 201) {
      return json
    }
    fancyToast(json, status)
    return null
  }

  const createCheckin = async createdUser => {
    const arrivalTime = moment(appointment.arrivalTime)

    let checkinStatus = ON_TIME

    if (arrivalTime.diff(moment(), 'hours') > 1) {
      checkinStatus = EARLY
    }

    if (arrivalTime.diff(moment(), 'minutes') < -30) {
      checkinStatus = LATE
    }

    const body = {
      checkin: {
        appointmentId: appointment.id,
        facilityId: appointment.facilityId,
        userId: createdUser.id,
        status: checkinStatus
      }
    }
    const [json, status] = await authenticatedFetch({
      path: `/checkins.json`,
      method: 'POST',
      body
    })

    if (status === 201) {
      return json
    }
    fancyToast(
      {
        info: `you have been successfully checked in, and will be contacted at ${json.phoneNumber}.`
      },
      status
    )
    return null
  }

  const updateAppointment = async () => {
    const [json, status] = await authenticatedFetch({
      path: `/appointments/${appointment.id}.json`,
      method: 'PATCH',
      body: {
        appointment
      }
    })

    if (status === 200) {
      return true
    }
    fancyToast(json)
    return false
  }

  const sendTextToDriver = async ({ id, dockName }) => {
    if (!id || !dockName) {
      return false
    }
    const [, status] = await authenticatedFetch({
      path: `/checkins/${id}/message_driver.json`,
      method: 'POST',
      body: {
        checkin: {
          message: `Please proceed to ${dockName}`
        }
      }
    })
    return status === 200
  }

  const performCheckin = async () => {
    const checkinUser = createDriver ? await createUser() : currentUser

    if (!checkinUser) {
      fancyToast({ info: 'Unable to create user.' }, 500)
      setLoading(false)
      return
    }

    const checkin = await createCheckin(checkinUser)

    if (!checkin) {
      fancyToast({ info: 'Unable to check driver in.' }, 500)
      setLoading(false)
      return
    }

    const appointmentUpdated = await updateAppointment()

    if (!appointmentUpdated) {
      fancyToast({ info: 'Appointment checked in, but questions left unanswered.' }, 500)
      close()
      return
    }

    const driverTexted = waitInYard || (await sendTextToDriver(checkin))

    if (driverTexted) {
      fancyToast({ info: 'Appointment has been successfully checked in.' }, 200)
    } else {
      fancyToast({
        info: 'Appointment checked in, but driver has not recieved a text message.'
      })
    }

    setSelectedDate(new Date(selectedDate))
    close()
  }

  const proceed = () => {
    setLoading(true)
    setCheckingIn(true)
  }

  if (!appointment) {
    return <></>
  }

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <ModalHeader>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          })}>
          <div>Gatehouse Checkin</div>
          <Checkbox
            overrides={{
              ToggleTrack: {
                style: ({ $checked }) => {
                  return {
                    backgroundColor: $checked ? theme.colors.accent : theme.colors.sliderTrackFill
                  }
                }
              }
            }}
            labelPlacement="left"
            checkmarkType="toggle"
            checked={createDriver}
            onChange={e => {
              setCreateDriver(e.currentTarget.checked)
            }}>
            Create Driver
          </Checkbox>
        </div>
      </ModalHeader>
      <ModalBody>
        <IntegrationModal
          integrationName="Elettric80"
          isOpen={checkingIn}
          setIsOpen={setCheckingIn}
          resolve={performCheckin}
          reject={() => {
            setLoading(false)
          }}
        />
        {createDriver && <CreateDriver {...{ user, setUser }} />}
        <AppointmentTypeQuestionsAndAnswers
          audienceType={DRIVER}
          {...{ appointment, setAppointment }}
        />
      </ModalBody>
      <ModalFooter>
        <ErrorMessageButton
          errors={checkInErrors}
          buttonProps={{
            onClick: () => {
              setWaitInYard(true)
              proceed()
            },
            isLoading: loading,
            overrides: {
              BaseButton: {
                style: {
                  backgroundColor: theme.colors.warning,
                  ':hover': {
                    backgroundColor: theme.colors.warning300
                  },
                  ':active': {
                    backgroundColor: theme.colors.warning500
                  }
                }
              }
            }
          }}
          label="Wait in Yard"
        />{' '}
        <ErrorMessageButton
          errors={checkInErrors}
          buttonProps={{
            onClick: () => {
              setWaitInYard(false)
              proceed()
            },
            isLoading: loading
          }}
          label="Proceed to Dock"
        />
      </ModalFooter>
    </Modal>
  )
}

export default GatehouseCheckin
