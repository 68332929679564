import React, { useEffect, useState } from 'react'
import { FormControl } from 'baseui/form-control'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import Checkbox from 'components/ui/generic/Checkbox'
import StyledSpinner from 'components/shared/styled-spinner'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { useTranslation } from 'react-i18next'

const FacilitiesSelect = ({ appointmentType, setAppointmentType }) => {
  const facilityIds = (appointmentType && appointmentType.facilityIds) || []
  const [facilities, setFacilities] = useState<any[]>([])
  const [loadingFacilities, setLoadingFacilities] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    setLoadingFacilities(true)
    authenticatedFetch({ path: `/facilities.json` })
      .then(([json, status]) => {
        const selectedFacilities = json.map(facility => {
          const { id } = facility
          const checked = facilityIds.indexOf(id) > -1
          return { ...facility, checked }
        })
        setFacilities(selectedFacilities)
      })
      .finally(() => setLoadingFacilities(false))
  }, [])

  return (
    <FormControl label={t('Settings.AppointmentTypes.Details.Facility')}>
      <FlexGrid flexGridColumnCount={[1, 2, 3]} flexGridColumnGap="scale200">
        {loadingFacilities ? (
          <StyledSpinner />
        ) : (
          facilities.map((facility, index) => {
            return (
              <FlexGridItem key={index} overflow="hidden">
                <Checkbox
                  checked={facilities[index].checked}
                  onChange={e => {
                    const newFacilities = [...facilities]
                    facilities[index].checked = e.currentTarget.checked
                    setFacilities(newFacilities)
                    const facilityIds = facilities
                      .filter(facility => {
                        return facility.checked
                      })
                      .map(facility => {
                        return facility.id
                      })
                    setAppointmentType({
                      ...appointmentType,
                      facilityIds
                    })
                  }}
                  label={facility.name}
                />
              </FlexGridItem>
            )
          })
        )}
      </FlexGrid>
    </FormControl>
  )
}

export default FacilitiesSelect
