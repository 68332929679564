import { PAGE_SIZE } from 'components/constants/page-size'
import { useState } from 'react'

export function usePagination(defaultPage = 1, defaultPageSize = PAGE_SIZE) {
  const [page, setPage] = useState(defaultPage)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  return {
    page,
    setPage,
    pageSize,
    setPageSize
  }
}
