import React from 'react'
import { connectPagination } from 'react-instantsearch-dom'
import { useStyletron } from 'baseui'
import Pagination from 'components/ui/generic/Pagination'

const NonConnectedPagination = ({ currentRefinement, refine, nbPages }) => {
  const [css, theme] = useStyletron()
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      })}>
      <Pagination
        numPages={nbPages}
        currentPage={currentRefinement}
        onPageChange={({ nextPage }) => {
          refine(nextPage)
        }}
      />
    </div>
  )
}

const CyberSearchPagination = connectPagination(NonConnectedPagination)

export default CyberSearchPagination
