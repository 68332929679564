import React, { useMemo } from 'react'
import { Block } from 'baseui/block'
import { ParagraphMedium } from 'baseui/typography'
import {
  YardJockeyTabCounter,
  YardJockeyTabCounterNumber,
  YardJockeyTabName
} from './YardJockey.styled'
import { useTranslation } from 'react-i18next'
import {
  COMPLETE_TAB,
  NEW_TAB,
  PENDING_TAB,
  useYardJockeyContext
} from '../../contexts/yard-jockey.context'

const YardJockeyTab = ({ tabName }) => {
  const { t } = useTranslation()

  const {
    activeTab,
    counters,
    shiftActive,
    actions: { setActiveTab }
  } = useYardJockeyContext()

  const YardJockeyTabNameStyles = useMemo(
    () => YardJockeyTabName(tabName === activeTab),
    [tabName, activeTab]
  )

  const YardJockeyTabCounterNumberStyles = useMemo(
    () => YardJockeyTabCounterNumber(tabName === activeTab),
    [tabName, activeTab]
  )

  const changeTab = () => {
    if (shiftActive) {
      setActiveTab(tabName)
    }
  }

  return (
    <Block display="flex" onClick={changeTab}>
      <ParagraphMedium overrides={YardJockeyTabNameStyles}>
        {tabName === NEW_TAB && t('YardJockey.Tabs.New.Text')}
        {tabName === PENDING_TAB && t('YardJockey.Tabs.Pending.Text')}
        {tabName === COMPLETE_TAB && t('YardJockey.Tabs.Complete.Text')}
      </ParagraphMedium>
      <YardJockeyTabCounter active={tabName === activeTab}>
        <ParagraphMedium overrides={YardJockeyTabCounterNumberStyles}>
          {counters ? counters[tabName] : 0}
        </ParagraphMedium>
      </YardJockeyTabCounter>
    </Block>
  )
}

export default YardJockeyTab
