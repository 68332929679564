import { Model } from './Model'

export interface User extends Model {
  id: string
  name?: string
  emailAddress?: string
  disabled?: boolean
  deleted?: boolean
}

const SHIPPER = 'shipper'
const BROKER = 'broker'
const CARRIER = 'carrier'
const DRIVER = 'driver'
const SCHEDULER = 'scheduler'
const YARD_JOCKEY = 'yard_jockey'
export const YARD_JOCKEY_LABEL = 'yard jockey'
const USER_TYPES = [SHIPPER, BROKER, CARRIER, DRIVER, SCHEDULER, YARD_JOCKEY]

export { SHIPPER, BROKER, CARRIER, DRIVER, SCHEDULER, USER_TYPES }
