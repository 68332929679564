import React from 'react'
import { TasksProvider } from 'components/contexts/tasks.context'
import TasksContainer from './Tasks'

const Tasks = () => {
  return (
    <TasksProvider>
      <TasksContainer />
    </TasksProvider>
  )
}

export default Tasks
