import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import React, { Dispatch } from 'react'
import { KIND as ButtonKind } from 'baseui/button'
import { Order } from 'components/models/Order'
import { useTranslation } from 'react-i18next'

interface DeleteOrderConfirmationModalProps {
  order: Order
  setSelectedOrderToDelete: Dispatch<Order>
  onConfirm: (id: string) => Promise<void>
}

const DeleteOrderConfirmationModal = ({
  order,
  setSelectedOrderToDelete,
  onConfirm
}: DeleteOrderConfirmationModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      onClose={() => setSelectedOrderToDelete(undefined)}
      closeable
      isOpen={!!order}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      unstable_ModalBackdropScroll>
      <ModalHeader>{`${t('Orders.DeleteOrderModal.Header.Text')} - ${order?.number}`}</ModalHeader>
      <ModalBody>{t('Orders.DeleteOrderModal.SubTitle.Text')}</ModalBody>
      <ModalFooter>
        <ModalButton
          kind={ButtonKind.tertiary}
          onClick={() => {
            setSelectedOrderToDelete(undefined)
          }}>
          {t('Orders.DeleteOrderModal.CancelButton.Text')}
        </ModalButton>
        <ModalButton
          onClick={() => {
            onConfirm(order.id)
            setSelectedOrderToDelete(undefined)
          }}>
          {t('Orders.DeleteOrderModal.ConfirmButton.Text')}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteOrderConfirmationModal
