import { ModalFooter, ModalHeader } from 'baseui/modal'
import styled from 'styled-components'

export const ModalProps = {
  overrides: {
    Dialog: { style: { maxWidth: '1000px' } },
    Close: {
      style: ({ $theme }) => ({
        right: $theme.sizing.scale800,
        top: $theme.sizing.scale800
      })
    }
  }
}

export const ModalHeaderStyled = styled(ModalHeader)`
  padding-bottom: 12px;
  border-bottom: 1px solid #dbe6ff;
  margin-bottom: 0px;
`
export const ModalFooterStyled = styled(ModalFooter)`
  padding-top: 12px;
  border-top: 1px solid #dbe6ff;
  margin-top: 0px;
`

export const SubtitleFlexContainerProps = {
  overrides: {
    Block: {
      style: ({ $theme }) => ({
        marginBottom: $theme.sizing.scale600
      })
    }
  }
}

export const FlexContainerFormProps = {
  overrides: {
    Block: {
      style: ({ $theme }) => ({
        backgroundColor: $theme.colors.darklight,
        borderRadius: $theme.borders.radius200,
        display: 'flex',
        itemAlign: 'center'
      })
    }
  }
}

export const PanelRecurringFormProps = {
  overrides: {
    PanelContainer: {
      style: {
        borderBottom: '0px'
      }
    },
    Header: {
      style: {
        display: 'none'
      }
    },
    Content: {
      style: {
        backgroundColor: 'inherit',
        padding: 'unset'
      }
    }
  }
}
