import * as React from 'react'
import { ButtonGroup, SIZE, SHAPE } from 'baseui/button-group'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { capitalize, find } from 'lodash'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'
import FormControl from 'components/ui/generic/FormControl'
import { FacilitiesContext } from '../../context/facilities-context'
import StyledSpinner from '../../../../shared/styled-spinner'

interface DockTypeProps {
  record: any
  setRecord: any
  selectedEvent: any
  openScheduling?: boolean
}

const DockType = ({ record, setRecord, selectedEvent, openScheduling }: DockTypeProps) => {
  const [docks, setDocks] = React.useState([])
  const [dockTypeSubset, setDockTypeSubset] = React.useState([])
  const { facilities } = React.useContext(FacilitiesContext)
  const { facilityId } = selectedEvent
  const { t } = useTranslation()

  React.useEffect(() => {
    if (facilityId && facilities && facilities[facilityId]) {
      const { dockCapacitiesAttributes } = facilities[facilityId]?.appointmentPreference
      setDockTypeSubset(
        dockCapacitiesAttributes
          .filter(dockCapacity => dockCapacity.appointmentTypeId === null)
          .map(dockCapacity => dockCapacity.equipmentType)
      )
    } else if (facilityId) {
      authenticatedFetch({
        path: `/facilities.json/?id[]=${facilityId}`
      }).then(([json, status]) => {
        if (status === 200) {
          const { dockCapacitiesAttributes } = json[0].appointmentPreference
          setDockTypeSubset(
            dockCapacitiesAttributes
              .filter(dockCapacity => dockCapacity.appointmentTypeId === null)
              .map(dockCapacity => dockCapacity.equipmentType)
          )
        }
      })
    } else if (selectedEvent?.id && selectedEvent.appointmentPreference) {
      const { dockCapacitiesAttributes } = selectedEvent.appointmentPreference
      setDockTypeSubset(
        dockCapacitiesAttributes
          .filter(dockCapacity => dockCapacity.appointmentTypeId === null)
          .map(dockCapacity => dockCapacity.equipmentType)
      )
    }
  }, [facilityId, facilities])

  React.useEffect(() => {
    authenticatedFetch({
      path: `/facilities/dock_types${openScheduling ? '_open_sheduling' : ''}.json?appointment_id=${
        record.id
      }`
    }).then(([json, status]) => {
      setDocks(json)
    })
  }, [])

  const equipmentTypeId = record.equipmentTypeId

  return (
    <FormControl label={t('Common.Fields.EquipmentType.Text')}>
      {dockTypeSubset.length > 0 ? (
        <ButtonGroup
          mode="radio"
          size={SIZE.compact}
          shape={SHAPE.pill}
          selected={dockTypeSubset.findIndex(eqType => eqType.id === equipmentTypeId)}
          onClick={(_event, index) => {
            const dockType = dockTypeSubset[index]
            const dockId = find(docks, ['equipmentTypeId', dockType.id])?.id

            setRecord({
              ...record,
              equipmentTypeId: dockType.id,
              dockId: dockId ? dockId : record.dockId
            })
          }}>
          {dockTypeSubset.map((dockType, index) => {
            return <Button key={index}>{capitalize(dockType.name)}</Button>
          })}
        </ButtonGroup>
      ) : (
        <StyledSpinner />
      )}
    </FormControl>
  )
}

export default DockType
