import React, { useState, useEffect } from "react";
import { Accordion, Panel } from "baseui/accordion";
import { findIndex } from "lodash";
import authenticatedFetch from "../../utils/authenticated-fetch";
import QuestionsAndAnswersList from "./questions-and-answers-list";
import { Paragraph1 } from "baseui/typography";

const Responses = ({ appointment, setAppointment }) => {
  const { appointmentTypeId, answersAttributes } = appointment;
  const [appointmentType, setAppointmentType] = useState<any>(null);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<[]>([]);

  useEffect(() => {
    appointmentTypeId &&
      authenticatedFetch({
        path: `/appointment_types/${appointmentTypeId}.json`,
      }).then(([json, status]) => {
        setAppointmentType(json);
      });
  }, [appointmentTypeId]);

  useEffect(() => {
    if (!appointmentType || !appointmentType.questions) {
      return;
    }
    const { questions } = appointmentType;
    const { answersAttributes } = appointment;
    const fullAnswersAttributes = questions.map((question, index) => {
      const { id } = question;
      const answerIndex = findIndex(answersAttributes, { questionId: id });
      return answerIndex === -1
        ? { questionId: id }
        : answersAttributes[answerIndex];
    });
    setAppointment({
      ...appointment,
      answersAttributes: fullAnswersAttributes,
    });
  }, [appointmentType]);

  useEffect(() => {
    if (!appointmentType) {
      return;
    }
    const questionsAndAnswers = answersAttributes.map((answer, index) => {
      const { questions } = appointmentType;
      const question =
        questions[findIndex(questions, { id: answer.questionId })];
      return { question, answer };
    });
    setQuestionsAndAnswers(questionsAndAnswers);
  }, [answersAttributes]);

  return (
    <Accordion
      overrides={{
        Root: {
          style: ({ $theme }) => {
            return { border: `solid 1px ${$theme.colors.mono600}` };
          },
        },
      }}
    >
      <Panel
        title="Responses"
        overrides={{
          Content: {
            style: ({ $theme }) => {
              return { backgroundColor: $theme.colors.mono100 };
            },
          },
        }}
      >
        {questionsAndAnswers.length === 0 ? (
          <Paragraph1>
            This Appointment Type does not have any linked questions.
          </Paragraph1>
        ) : (
          <QuestionsAndAnswersList {...{ questionsAndAnswers }} />
        )}
      </Panel>
    </Accordion>
  );
};

export default Responses;
