export const durationOptions = [
  { label: '15', id: 15 },
  { label: '30', id: 30 },
  { label: '45', id: 45 },
  { label: '60', id: 60 },
  { label: '75 (1h 15min)', id: 75 },
  { label: '90 (1h 30min)', id: 90 },
  { label: '105 (1h 45min)', id: 105 },
  { label: '120 (2h)', id: 120 },
  { label: '135 (2h 15min)', id: 135 },
  { label: '150 (2h 30min)', id: 150 },
  { label: '165 (2h 45min)', id: 165 },
  { label: '180 (3h)', id: 180 },
  { label: '195 (3h 15min)', id: 195 },
  { label: '210 (3h 30min)', id: 210 },
  { label: '225 (3h 45min)', id: 225 },
  { label: '240 (4h)', id: 240 },
  { label: '255 (4h 15min)', id: 255 },
  { label: '270 (4h 30min)', id: 270 },
  { label: '285 (4h 30min)', id: 285 },
  { label: '300 (4h 30min)', id: 300 }
]

export const getDurationLabel = (id: number) =>
  durationOptions.filter(option => option.id === id)[0]
