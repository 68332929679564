import React from "react";
import AdjustableCalendar from "../shared/adjustable-calendar";
import WarehouseCodeModal from "../shared/modals/warehouse-code-modal";
import { useLocalStorage } from "../shared/hooks/use-local-storage";

const SHIPPER_ID_KEY = "shipperId";

const PublicCalendar = () => {
  const [shipperId, setShipperId] = useLocalStorage<String>(SHIPPER_ID_KEY, "");

  if (!shipperId) {
    return <WarehouseCodeModal {...{ shipperId, setShipperId }} />;
  }

  return <AdjustableCalendar shipperId={shipperId} />;
};

export default PublicCalendar;
