import { useTasksContext } from 'components/contexts/tasks.context'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Calendar from 'components/ui/generic/Calendar'
import { dateTranslations } from 'translations/date-languages'
import i18n from 'translations/i18n'
import CalendarPopover from 'components/shipper/instant-calendar/calendar-popover'
import TimeFormatter from 'components/utils/time-formatter'
import Button from 'components/ui/generic/Button'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import { KIND, SIZE } from 'baseui/button'

const TaskDatePicker = () => {
  const { state, actions } = useTasksContext()
  const { selectedDate } = state

  const { setDate } = actions
  const dTranslation = dateTranslations[i18n.language]
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()

  return (
    <>
      <CalendarPopover
        isOpen={open}
        togglePopover={() => {
          setOpen(!open)
        }}>
        <Calendar
          locale={dTranslation}
          value={selectedDate}
          onChange={({ date }) => {
            const singleDate = Array.isArray(date) ? date[0] : date
            setDate(singleDate)
            setOpen(false)
          }}
        />
      </CalendarPopover>
      <Button
        IconEnd={open ? <CaretUp size={20} /> : <CaretDown size={20} />}
        size={SIZE.mini}
        kind={KIND.minimal}
        overrides={{ BaseButton: { style: { paddingLeft: '0px', textAlign: 'start' } } }}
        style={{
          backgroundColor: 'inherit'
        }}
        onClick={() => {
          setOpen(!open)
        }}>
        <strong className="e-calendar-date">
          {new TimeFormatter('shortHumanDate').format(selectedDate)}
        </strong>
      </Button>
    </>
  )
}

export default TaskDatePicker
