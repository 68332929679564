import React, { useState, useEffect, useContext } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, SIZE } from 'baseui/modal'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Datepicker } from 'baseui/datepicker'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { TimePicker } from 'baseui/timepicker'
import { Block } from 'baseui/block'
import { Select } from 'baseui/select'
import { Checkbox } from 'baseui/checkbox'
import authenticatedFetch from '../../../utils/authenticated-fetch'
import { fancyToast } from '../../../utils'
import QuestionsList from '../../book/additional-information/QuestionsList'
import { FTL, LTL } from '../../../shared/adjustable-calendar/toggles/event-type'
import ErrorMessageButton from '../../../shared/error-message-button'
import ScheduleForm from './schedule-form'
import { useStyletron } from 'baseui'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import { useTranslation } from 'react-i18next'

interface CreateRepeatingAppointment {
  event: any
  isOpen: boolean
  close: () => void
}

const CreateRepeatingAppointment = (props: CreateRepeatingAppointment) => {
  const [css] = useStyletron()
  const { event, isOpen, close } = props
  const { t } = useTranslation()

  const [appointment, setAppointment] = useState<any>({
    createdByEmailAddress: '',
    purchaseOrderIdentifier: '',
    arrivalTime: new Date(Date.now()),
    customerName: ''
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [facility, setFacility] = useState<any>(null)
  const [questions, setQuestions] = useState([])
  const [dockInput, setDockInput] = useState<any>(appointment.dock && appointment.dock.name)
  const [docks, setDocks] = useState<any>([])
  const [dock, setDock] = useState<any>([{ ...appointment.dock }])
  useEffect(() => {
    if (!event || event.id) return
    const { arrivalTime, createdByEmailAddress } = event
    setFacility(event.facility)
    setAppointment({
      ...appointment,
      createdByEmailAddress,
      arrivalTime,
      facilityId: event.facility.id,
      dock: 0,
      schedule: {
        rrules: [
          {
            validations: { day: [new Date(arrivalTime).getDay()] },
            ruleType: 'IceCube::WeeklyRule',
            interval: 1,
            weekStart: 0
          }
        ],
        rtimes: [],
        extimes: [],
        startTime: new Date().toISOString()
      }
    })
  }, [event])

  useEffect(() => {
    authenticatedFetch({
      path: `/facilities/${appointment.facilityId}/docks.json${
        dockInput ? `?term=${dockInput}` : ''
      }`
    }).then(([docks, status]) => setDocks(docks))
  }, [dockInput, appointment.facilityId])

  if (!facility || !appointment) {
    return <></>
  }

  const { createdByEmailAddress, purchaseOrderIdentifier, arrivalTime, customerName } = appointment

  const createAppointment = async () => {
    setLoading(true)
    const { answersAttributes } = appointment
    let { appointmentTypeId } = appointment
    if (facility.appointmentTypes && facility.appointmentTypes.length === 1) {
      appointmentTypeId = facility.appointmentTypes[0].id
    }
    const purchaseOrdersAttributes = purchaseOrderIdentifier
      ? [{ identifier: purchaseOrderIdentifier }]
      : null
    let appointmentPayload = {
      ...appointment,
      lessThanTruckload: appointment.type == LTL,
      purchaseOrdersAttributes,
      appointmentTypeId,
      dockId: dock && dock[0] && dock[0].id,
      answersAttributes:
        answersAttributes &&
        answersAttributes.filter(answer => {
          return answer.response || (answer.documents && answer.documents.length > 0)
        }),
      originalId: event.originalId
    }
    delete appointmentPayload.purchaseOrderIdentifier

    authenticatedFetch({
      path: '/appointments.json',
      method: 'POST',
      body: {
        appointment: appointmentPayload
      }
    }).then(([json, status]) => {
      setLoading(false)
      if (![201].includes(status)) {
        fancyToast(json, status)
      } else {
        fancyToast(
          {
            info: t('Common.Info.Interpolated.Text', {
              model: t('Common.ModelType.RepeatingAppointment.Text'),
              action: t('Common.Actions.Created.Text')
            })
          },
          201
        )
        setAppointment({})
        close()
      }
    })
  }
  return (
    <Modal
      onClose={() => {
        close()
        setAppointment({})
      }}
      isOpen={isOpen}
      size={SIZE.auto}>
      <ModalHeader>{t('Facilities.CreateRepeatingAppointment.Header.Text')}</ModalHeader>
      <ModalBody>
        <FlexGrid
          flexGridColumnCount={[1, 1, 2]}
          flexGridRowGap="scale400"
          flexGridColumnGap="scale200">
          <FlexGridItem key="0">
            <FormControl
              label={t('Facilities.CreateRepeatingAppointment.Fields.Facility.Label.Text')}>
              <Input value={appointment && facility?.name} disabled />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem key="1">
            <FormControl
              label={t(
                'Facilities.CreateRepeatingAppointment.Fields.UniqueLoadIdentifier.Label.Text'
              )}>
              <Input
                autoFocus
                id="appointment[purchase_order_identifier]"
                autoComplete="off"
                name="appointment[purchase_order_identifier]"
                value={appointment && purchaseOrderIdentifier}
                onChange={event =>
                  setAppointment({
                    ...appointment,
                    purchaseOrderIdentifier: event.currentTarget.value
                  })
                }
              />
            </FormControl>
          </FlexGridItem>
          {appointment && arrivalTime && (
            <>
              <FlexGridItem key="2">
                <FormControl
                  label={t('Facilities.CreateRepeatingAppointment.Fields.ArrivalDate.Label.Text')}>
                  <Datepicker
                    value={arrivalTime}
                    formatString="MM/dd/yyyy"
                    onChange={({ date }) =>
                      setAppointment({
                        ...appointment,
                        arrivalTime: date as Date
                      })
                    }
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem key="3">
                <FormControl
                  label={t('Facilities.CreateRepeatingAppointment.Fields.ArrivalTime.Label.Text')}>
                  <TimePicker
                    step={30 * 60}
                    value={arrivalTime}
                    onChange={date =>
                      setAppointment({
                        ...appointment,
                        arrivalTime: date as Date
                      })
                    }
                  />
                </FormControl>
              </FlexGridItem>
            </>
          )}
          <FlexGridItem key="5">
            <FormControl
              label={t('Facilities.CreateRepeatingAppointment.Fields.CustomerName.Label.Text')}>
              <Input
                autoComplete="off"
                name="appointment[customer_name]"
                value={customerName}
                onChange={e =>
                  setAppointment({
                    ...appointment,
                    customerName: e.currentTarget.value
                  })
                }
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem key="6">
            <FormControl label={t('Facilities.CreateRepeatingAppointment.Fields.Dock.Label.Text')}>
              <Select
                disabled={!appointment.facilityId}
                type="search"
                options={docks}
                labelKey="name"
                valueKey="id"
                placeholder="Dock"
                maxDropdownHeight="300px"
                onChange={({ value }) => setDock(value)}
                onInputChange={event => setDockInput(event.currentTarget.value)}
                value={dock}
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <FormControl
              label={t('Facilities.CreateRepeatingAppointment.Fields.Scheduler.Label.Text')}>
              <CyberAutocomplete
                record={appointment}
                placeholder={t(
                  'Facilities.CreateRepeatingAppointment.Fields.Scheduler.PlaceHolder.Text'
                )}
                setRecord={setAppointment}
                indexName="scheduler"
              />
            </FormControl>
          </FlexGridItem>
        </FlexGrid>
        <FlexGrid>
          {facility && facility.id && questions && questions.length > 0 && (
            <QuestionsList {...{ appointment, setAppointment, questions, setQuestions }} />
          )}
        </FlexGrid>
        {appointment?.schedule && (
          <ScheduleForm
            record={{ ...appointment, answersAttributes: null }}
            setRecord={setAppointment}
            timeField="arrivalTime"
          />
        )}
      </ModalBody>
      <ModalFooter>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          })}>
          <ErrorMessageButton
            errors={[
              {
                label: t(
                  'Facilities.CreateRepeatingAppointment.Validations.ArrivalTimeMustBePresent.Text'
                ),
                status: !!arrivalTime
              },
              {
                label: t(
                  'Facilities.CreateRepeatingAppointment.Validations.FacilityMustBepresent.Text'
                ),
                status: !!facility?.id
              },
              {
                label: t(
                  'Facilities.CreateRepeatingAppointment.Validations.CreatedByMustBePresent.Text'
                ),
                status: !!createdByEmailAddress
              },
              {
                label: t(
                  'Facilities.CreateRepeatingAppointment.Validations.UniqueLoadIdentifierMustBePresent.Text'
                ),
                status: !!purchaseOrderIdentifier || !!customerName
              }
            ]}
            buttonProps={{ isLoading: loading, onClick: createAppointment }}
            label={t('Facilities.CreateRepeatingAppointment.CreateButton.Text')}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default CreateRepeatingAppointment
