import React from 'react'
import { StyledSpinnerNext as Spinner } from 'baseui/spinner'
import Button from 'components/ui/generic/Button'
import { Plus } from '@phosphor-icons/react'

const fileUploaderStyling = (options, t, ready) => ({
  FileDragAndDrop: {
    style: ({ $theme }) => {
      return {
        backgroundColor: $theme.colors.transparent,
        position: 'relative',
        borderTopWidth: $theme.borders.borderWidth,
        borderBottomWidth: $theme.borders.borderWidth,
        borderLeftWidth: $theme.borders.borderWidth,
        borderRightWidth: $theme.borders.borderWidth,
        borderTopColor: $theme.colors.inputBorder,
        borderBottomColor: $theme.colors.inputBorder,
        borderLeftColor: $theme.colors.inputBorder,
        borderRightColor: $theme.colors.inputBorder
      }
    }
  },
  ContentMessage: () => (
    <>
      <p
        style={{
          fontSize: '10px',
          lineHeight: 1.25,
          textAlign: 'center',
          zIndex: 1,
          left: 0,
          right: 0,
          top: '50%'
        }}>
        {!options.addFile
          ? t('Common.FileUploader.Label.Text')
          : options.maxHeight === '49px'
          ? `${options.type === 'document' ? options?.customLabel : 'OTHER LOGO'}`
          : `${options.type === 'document' ? options?.customLabel : 'OTHER IMAGE'}`}
      </p>
    </>
  ),
  ButtonComponent: props => {
    return (
      <>
        <Button
          {...props}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                height: '100%',
                width: '100%',
                borderTopWidth: $theme.borders.borderWidth,
                borderBottomWidth: $theme.borders.borderWidth,
                borderLeftWidth: $theme.borders.borderWidth,
                borderRightWidth: $theme.borders.borderWidth,
                backgroundColor: 'unset !important',
                ':hover': {
                  backgroundColor: 'unset !important'
                }
              })
            }
          }}>
          {ready ? (
            <Plus size={32} />
          ) : (
            <Spinner
              size={24}
              overrides={{
                Svg: {
                  style: () => ({
                    marginTop: options?.iconMarginTop ? options?.iconMarginTop : '-90px',
                    zIndex: 2,
                    ...((options.type === 'document' || options.type === 'IMAGE') && {
                      position: 'absolute'
                    })
                  })
                }
              }}
            />
          )}
        </Button>
        {options.type === 'document' && (
          <Button
            {...props}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => ({
                  backgroundColor: $theme.colors.transparent,
                  outline: `1px ${$theme.borders.borderColor} solid`
                })
              }
            }}>
            {t('Common.FileUploader.Caption')}
          </Button>
        )}
      </>
    )
  }
})

export default fileUploaderStyling
