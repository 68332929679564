import React, { useState } from 'react'
import { CyberTable } from 'cyber/table'
import { ParagraphLarge } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { FileSearch, Trash } from '@phosphor-icons/react'
import { StyledLink } from 'baseui/link'
import hitTransformer from 'cyber/search/hit-transformer'
import { MetabaseReport } from 'components/models/MetabaseReport'
import Button from 'components/ui/generic/Button'

import { useTranslation } from 'react-i18next'
import { MetabaseAnalyticsSettingsModal } from './MetabaseAnalyticsSettingsModal'
import { MetabaseAnalyticsSettingsConfirmationModal } from './MetabaseAnalyticsSettingsConfirmationModal'

type Props = {
  data: MetabaseReport[]
  refresh: () => Promise<unknown>
}

export const MetabaseAnalyticsSettingsTable = ({ data = [], refresh }: Props) => {
  const [report, setReport] = useState<MetabaseReport>(null)
  const [reportToDelete, setReportToDelete] = useState<MetabaseReport>(null)
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const COLUMNS = [
    t('Settings.Analytics.Table.Columns.Name.Label.Text'),
    t('Settings.Analytics.Table.Columns.ReportUrl.Label.Text'),
    t('Settings.Analytics.Table.Columns.Actions.Label.Text')
  ]

  if (data.length === 0) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: theme.sizing.scale1600
        })}>
        <FileSearch size={theme.sizing.scale2400} />
        <ParagraphLarge>{t('Settings.Analytics.Table.NoReportsFound.Text')}</ParagraphLarge>
      </div>
    )
  }

  return (
    <>
      <MetabaseAnalyticsSettingsModal
        open={!!report}
        report={report}
        onClose={() => setReport(null)}
        onFinish={refresh}
      />
      <MetabaseAnalyticsSettingsConfirmationModal
        open={!!reportToDelete}
        report={reportToDelete}
        onClose={() => setReportToDelete(null)}
        onFinish={refresh}
      />

      <CyberTable
        columns={COLUMNS}
        data={
          Array.isArray(data)
            ? data.map(hitTransformer).map((report: MetabaseReport) => [
                <StyledLink
                  className={css({ cursor: 'pointer' })}
                  onClick={() => setReport(report)}>
                  {report.name}
                </StyledLink>,
                report.uri,
                <Button shape="circle" onClick={() => setReportToDelete(report)}>
                  <Trash />
                </Button>
              ])
            : []
        }
      />
    </>
  )
}
