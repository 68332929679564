import React from "react";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import ResponseAnswer from "./answers/response-answer";
import DocumentAnswer from "./answers/document-answer";
import ConfirmationAnswer from "./answers/confirmation-answer";
import AddressAnswer from "./answers/address-answer";

const QuestionAndAnswer = ({ question, answer }) => {
  const { prompt, caption, answerType } = question;
  const AnswerComponent = {
    identifier: ResponseAnswer,
    address: AddressAnswer,
    response: ResponseAnswer,
    document: DocumentAnswer,
    confirmation: ConfirmationAnswer,
  }[answerType];

  return (
    <FormControl label={prompt} caption={caption}>
      <AnswerComponent {...{ question, answer }} />
    </FormControl>
  );
};

export default QuestionAndAnswer;
