import React from 'react'
import ColumnsPopover from './ColumnsPopover'
import { Block } from 'baseui/block'
import Checkbox from 'components/ui/generic/Checkbox'
import { HeadingXSmall } from 'baseui/typography'
import { useTranslation } from 'react-i18next'

const ColumnsSelector = ({ labelCallback, shownColumns, initialColumns, toggleColumn }) => {
  const { t } = useTranslation()

  return (
    <ColumnsPopover buttonText={t('Common.Table.Columns.Text')}>
      <Block
        backgroundColor="#fff"
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              maxWidth: '284px',
              [$theme.mediaQuery.medium]: {
                left: 'auto',
                right: 0
              }
            })
          }
        }}>
        <HeadingXSmall margin="0 0 8px">{t('Common.Table.Columns.Text')}</HeadingXSmall>
        {initialColumns.map(({ name, position, disabled }) => (
          <Checkbox
            key={name}
            label={labelCallback ? labelCallback({ name, position, disabled }) : name}
            onChange={e => toggleColumn(e.target.checked, { name, position })}
            checked={shownColumns.some(c => c.name === name)}
            disabled={disabled}
            overrides={{
              Root: {
                style: () => ({
                  marginBottom: '4px'
                })
              }
            }}
          />
        ))}
      </Block>
    </ColumnsPopover>
  )
}

export default ColumnsSelector
