import { createConsumer } from '@rails/actioncable'
import { MutableRefObject } from 'react'

interface Data {
  appointmentId: string
  arrivalTime: Date
  dockId: string
}

export const subscribeToRTNotifications = (
  shipper_id: string,
  refreshAppointments: () => void,
  selectedDateRef: MutableRefObject<Date>
) => {
  let refreshTimeout: NodeJS.Timeout | null = null

  const handleRefresh = () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout)
    }
    refreshTimeout = setTimeout(() => {
      refreshAppointments()
      clearTimeout(refreshTimeout)
    }, 800)
  }

  createConsumer(`/cable`).subscriptions.create(
    { channel: 'AppointmentsChannel', room: `${shipper_id}` },
    {
      connected() {
        console.log('connected to appointment updates channel.')
      },
      received(data: Data) {
        this.appendLine(data)
      },
      appendLine({ arrivalTime, dockId }: Data) {
        if (
          selectedDateRef.current.toLocaleDateString() ===
          new Date(arrivalTime).toLocaleDateString()
        ) {
          handleRefresh()
        }
      }
    }
  )
}
