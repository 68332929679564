import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import React from 'react'
import { KIND as ButtonKind } from 'baseui/button'

interface AssigmentChangeConfirmationModalProps {
  needConfirmation: false
  onCancel: () => void
  onConfirm: () => void
}

export const AssigmentChangeConfirmationModal = ({
  needConfirmation,
  onCancel,
  onConfirm
}: AssigmentChangeConfirmationModalProps) => (
  <Modal
    onClose={onCancel}
    closeable
    isOpen={needConfirmation}
    animate
    autoFocus
    size={SIZE.default}
    role={ROLE.dialog}
  >
    <ModalHeader>{'Remove Equipment Type Assignment'}</ModalHeader>
    <ModalBody>
      There are docks that are using the equipment type that was removed, those will be disabled. Do
      you want to continue?
    </ModalBody>
    <ModalFooter>
      <ModalButton kind={ButtonKind.tertiary} onClick={onCancel}>
        Cancel
      </ModalButton>
      <ModalButton onClick={onConfirm}>Confirm</ModalButton>
    </ModalFooter>
  </Modal>
)
