import React, { useContext } from "react";
import { Input } from "baseui/input";
import { findIndex } from "lodash";
import { Checkbox } from "baseui/checkbox";
import { useStyletron } from "baseui";
import { Textarea } from "baseui/textarea";
import { Button } from "baseui/button";
import { Upload } from "baseui/icon";
import TimeFormatter from "../../../../utils/time-formatter";
import { AppointmentContext } from "../../../appointment-form";
import UploadButton from "./upload-button";
import UploadButtonWrapper from "./upload-button-wrapper";

export const CONFIRMED = "~~CONFIRMED~~";

const ConfirmationAnswer = ({ question, answer }) => {
  const [css, theme] = useStyletron();
  const { appointment, setAppointment } = useContext(AppointmentContext);
  const { response, attachedDocuments, documents } = answer;
  const { id } = question;

  const errorState = response !== CONFIRMED && response !== undefined;
  return (
    <div
      className={css({
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
      })}
    >
      <div>
        <Checkbox
          labelPlacement="right"
          overrides={{
            Root: { style: { display: "flex", alignItems: "center" } },
            Checkmark: {
              style: {
                width: theme.sizing.scale900,
                height: theme.sizing.scale900,
              },
            },
            Label: {
              style: {
                opacity: errorState ? "0" : "1",
              },
            },
          }}
          isError={errorState}
          isIndeterminate={response === undefined}
          name={`answer[${id}]`}
          onChange={(e) => {
            const { answersAttributes } = appointment;
            const newAnswersAttributes = answersAttributes
              ? [...answersAttributes]
              : [];
            const existingAnswerIndex = findIndex(newAnswersAttributes, {
              questionId: answer.questionId,
            });

            // This component assumes the answers attributes have already been set, so they all have an index;
            const newAnswer = newAnswersAttributes[existingAnswerIndex];
            newAnswer.questionId = id;
            newAnswer.response = e.currentTarget.checked ? CONFIRMED : "";
            newAnswersAttributes.splice(existingAnswerIndex, 1, newAnswer);
            setAppointment({
              ...appointment,
              answersAttributes: newAnswersAttributes,
            });
          }}
          checked={response === CONFIRMED}
        >
          {response === CONFIRMED &&
            `${new TimeFormatter("fullDate").format(new Date())}`}
        </Checkbox>
        {errorState && <UploadButtonWrapper {...{ question, answer }} />}
      </div>
      <Textarea
        overrides={{
          InputContainer: {
            style: {
              opacity: errorState ? "1" : "0",
              width: errorState ? "100%" : "0",
            },
          },
        }}
        value={response === CONFIRMED ? "" : response} // This is a hack, so when fading in/out, it dosen't show the word confirmed
        onChange={(e) => {
          const { answersAttributes } = appointment;

          const answerIndex = findIndex(answersAttributes, {
            questionId: id,
          });
          answersAttributes[answerIndex].response = e.currentTarget.value;
          setAppointment({ ...appointment, answersAttributes });
        }}
        placeholder="explanation"
      />
    </div>
  );
};

export default ConfirmationAnswer;
