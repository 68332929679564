import React from 'react'
import { Block } from 'baseui/block'
import { useTranslation } from 'react-i18next'
import i18n from 'translations/i18n'
import { Task } from '../../models/Task'
import { ParagraphLarge } from 'baseui/typography'
import { ArrowRight, DotsThree, FileSearch, FlagPennant, Prohibit } from '@phosphor-icons/react'
import Tag from 'components/ui/generic/Tag'
import StyledSpinner from 'components/shared/styled-spinner'
import {
  NotesTableField,
  PointerDiv,
  StrongTableField,
  YardJockeyTableNotFoundContainer,
  YardJockeyTableStatusIcon,
  YardJockeyTableStyles
} from './YardJockey.styled'
import { useStyletron } from 'baseui'
import Table from 'components/ui/generic/Table'
import { COMPLETE_TAB, useYardJockeyContext } from '../../contexts/yard-jockey.context'
import { primitives } from '../../homepage/custom-theme'
import YardJockeyActionButton from './YardJockeyActionButton'
import YardJockeyPagination from './YardJockeyPagination'
import { capitalize } from 'lodash'
import { TRAILER_STATUS } from '../YardTrailers/TrailersList.styled'

const COLUMNS = [
  i18n.t('YardJockey.Table.Columns.Trailer.Text'),
  i18n.t('YardJockey.Table.Columns.From.Text'),
  '',
  i18n.t('YardJockey.Table.Columns.To.Text'),
  i18n.t('YardJockey.Table.Columns.Status.Text'),
  i18n.t('YardJockey.Table.Columns.State.Text'),
  i18n.t('YardJockey.Table.Columns.Flag.Text'),
  i18n.t('YardJockey.Table.Columns.Notes.Text'),
  '',
  ''
]

const YardJockeyTableCustomRow = (task: Task) => {
  const {
    activeTab,
    actions: { setDrawerOpen }
  } = useYardJockeyContext()

  return [
    <StrongTableField fontSize="14px">{task.trailer?.number}</StrongTableField>,
    <StrongTableField fontSize="20px">
      {task.fromSlot?.name || task.fromDock?.name || i18n.t('YardTask.Kanban.Columns.Unassigned')}
    </StrongTableField>,
    <ArrowRight size="13px" />,
    <StrongTableField fontSize="20px">{task.toSlot?.name || task.toDock?.name}</StrongTableField>,
    <Tag
      closeable={false}
      overrides={YardJockeyTableStatusIcon([TRAILER_STATUS.Full].includes(task.trailer?.status))}>
      {task.trailer?.status ? capitalize(task.trailer?.status) : ''}
    </Tag>,
    <Tag closeable={false} overrides={YardJockeyTableStatusIcon}>
      {task.trailer?.state ? capitalize(task.trailer?.state) : ''}
    </Tag>,
    task.flag && <FlagPennant color={primitives.error} size="12px" />,
    <NotesTableField>{task.notes}</NotesTableField>,
    <YardJockeyActionButton task={task} />,
    activeTab !== COMPLETE_TAB ? (
      <PointerDiv onClick={() => setDrawerOpen(true, task)}>
        <DotsThree size="16px" />
      </PointerDiv>
    ) : (
      ''
    )
  ]
}

const YardJockeyTable = () => {
  const { t } = useTranslation()
  const [_, theme] = useStyletron()

  const { tasks, tableLoading, shiftActive } = useYardJockeyContext()

  if (tableLoading) {
    return (
      <Block width="100%">
        <Block paddingBottom="scale200" minHeight="60vh">
          <Block display="flex" justifyContent="center" alignItems="center">
            <StyledSpinner />
          </Block>
        </Block>
      </Block>
    )
  }

  if (tasks.length === 0) {
    return (
      <Block width="100%">
        <Block paddingBottom="scale200" minHeight="60vh">
          <YardJockeyTableNotFoundContainer>
            <FileSearch size={theme.sizing.scale2400} />
            <ParagraphLarge>{t('YardTask.Table.NoTaskFound.Text')}</ParagraphLarge>
          </YardJockeyTableNotFoundContainer>
        </Block>
      </Block>
    )
  }

  if (!shiftActive) {
    return (
      <Block width="100%">
        <Block paddingBottom="scale200" minHeight="60vh">
          <YardJockeyTableNotFoundContainer>
            <Prohibit size={theme.sizing.scale2400} />
            <ParagraphLarge>{t('YardJockey.Table.Error.StartShift.Text')}</ParagraphLarge>
          </YardJockeyTableNotFoundContainer>
        </Block>
      </Block>
    )
  }

  return (
    <Block width="100%">
      <Block paddingBottom="scale200" minHeight="60vh">
        <Table
          overrides={YardJockeyTableStyles}
          columns={COLUMNS}
          data={tasks.map((task: Task) => YardJockeyTableCustomRow(task))}
        />
        {shiftActive && <YardJockeyPagination />}
      </Block>
    </Block>
  )
}

export default YardJockeyTable
