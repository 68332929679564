import React, { useEffect } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { Datepicker } from 'baseui/datepicker'
import { TimePicker } from 'baseui/timepicker'
import { Input } from 'baseui/input'
import StyledSpinner from '../../shared/styled-spinner'

const DockBlockDetails = ({ dockBlock, setDockBlock }) => {
  useEffect(() => {
    const { startTime } = dockBlock
    const newExtimes =
      dockBlock.schedule &&
      dockBlock.schedule.extimes.map(extime => {
        const extimeDate = new Date(extime)
        const newExtime = new Date(
          extimeDate.getFullYear(),
          extimeDate.getMonth(),
          extimeDate.getDate(),
          startTime.getHours(),
          startTime.getMinutes(),
          startTime.getSeconds()
        )

        return newExtime
      })

    setDockBlock({
      ...dockBlock,
      schedule: dockBlock.schedule && {
        ...dockBlock.schedule,
        extimes: newExtimes
      }
    })
  }, [dockBlock.startTime])

  if (!dockBlock) {
    return <StyledSpinner />
  }

  return (
    <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200" flexGridRowGap="scale200">
      <FlexGridItem>
        <FormControl label="Name">
          <Input
            autoComplete="off"
            name="dock_block[name]"
            value={dockBlock.name}
            onChange={e => {
              setDockBlock({
                ...dockBlock,
                name: e.currentTarget.value
              })
            }}
          />
        </FormControl>
      </FlexGridItem>
      <FlexGridItem />
      <FlexGridItem>
        <FormControl label="Start Date">
          <Datepicker
            value={dockBlock.startTime}
            formatString="MM/dd/yyyy"
            onChange={({ date }) =>
              setDockBlock({
                ...dockBlock,
                startTime: date as Date
              })
            }
          />
        </FormControl>
      </FlexGridItem>
      <FlexGridItem>
        <FormControl label="Start Time" caption="time displayed in your local timezone.">
          <TimePicker
            step={15 * 60}
            value={dockBlock.startTime}
            onChange={date =>
              setDockBlock({
                ...dockBlock,
                startTime: date as Date
              })
            }
          />
        </FormControl>
      </FlexGridItem>
      <FlexGridItem>
        <FormControl label="End Date">
          <Datepicker
            value={dockBlock.endTime}
            formatString="MM/dd/yyyy"
            onChange={({ date }) =>
              setDockBlock({
                ...dockBlock,
                endTime: date as Date
              })
            }
          />
        </FormControl>
      </FlexGridItem>
      <FlexGridItem>
        <FormControl label="End Time" caption="time displayed in your local timezone.">
          <TimePicker
            step={15 * 60}
            value={dockBlock.endTime}
            onChange={date => {
              setDockBlock({
                ...dockBlock,
                endTime: date as Date
              })
            }}
          />
        </FormControl>
      </FlexGridItem>
    </FlexGrid>
  )
}

export default DockBlockDetails
