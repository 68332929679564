import React from 'react'
import Button from '../specific/SecondaryButton'

const ErrorButton = ({ size, shape, IconEnd, IconStart, overrides, ...props }) => {
  return (
    <Button
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            borderColor: $theme.colors.error,
            color: $theme.colors.error,
            borderTopWidth: $theme.borders.borderWidth,
            borderBottomWidth: $theme.borders.borderWidth,
            borderLeftWidth: $theme.borders.borderWidth,
            borderRightWidth: $theme.borders.borderWidth,
            borderTopStyle: $theme.borders.borderStyle,
            borderBottomStyle: $theme.borders.borderStyle,
            borderLeftStyle: $theme.borders.borderStyle,
            borderRightStyle: $theme.borders.borderStyle
          })
        },
        ...overrides
      }}
      {...props}
    />
  )
}
export default ErrorButton
