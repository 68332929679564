import React, { useContext, useEffect } from "react";
import { FlexGridItem, FlexGrid } from "baseui/flex-grid";
import CustomTimePicker from "../../custom-time-picker";
import { CalendarToggleContext } from "../../../contexts/calendar-toggle-context";

const TimeRange = () => {
  const { calendarToggles, setCalendarToggles } = useContext(
    CalendarToggleContext
  );
  const { timeRange } = calendarToggles;

  return (
    <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale100">
      <FlexGridItem>
        <CustomTimePicker
          value={timeRange.minTime}
          onChange={({ value }) => {
            setCalendarToggles({
              ...calendarToggles,
              timeRange: { ...calendarToggles.timeRange, minTime: value },
            });
          }}
        />
      </FlexGridItem>
      <FlexGridItem>
        <CustomTimePicker
          value={timeRange.maxTime}
          onChange={({ value }) => {
            setCalendarToggles({
              ...calendarToggles,
              timeRange: { ...calendarToggles.timeRange, maxTime: value },
            });
          }}
        />
      </FlexGridItem>
    </FlexGrid>
  );
};

export default TimeRange;
