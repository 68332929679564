import React from 'react'
import { Input } from 'baseui/input'
import { Search } from 'baseui/icon'
import { Spinner } from 'baseui/spinner'
import { useStyletron } from 'baseui'

export const DocksSearchInput = ({ search, onSearch, loading }) => {
  const [_, theme] = useStyletron()

  return (
    <Input
      startEnhancer={loading ? <Spinner size={theme.sizing.scale600} /> : <Search />}
      autoComplete="off"
      name="search[name]"
      value={search}
      onChange={evt => onSearch(evt.currentTarget.value)}
    />
  )
}
