/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext } from 'react'
import authenticatedFetch from '../utils/authenticated-fetch'
import { findIndex } from 'lodash'
import QuestionsAndAnswersList from '../appointments/responses/questions-and-answers-list'
import { DRIVER, SHIPPER } from '../models/User'
import { AppointmentContext } from '../appointments/appointment-form'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import QuestionAndAnswer from '../appointments/responses/question-and-answer'

const AppointmentTypeQuestionsAndAnswers = ({ appointment, setAppointment, audienceType }) => {
  const [appointmentType, setAppointmentType] = useState(null)
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<[]>([])

  useEffect(() => {
    appointment?.appointmentTypeId &&
      !appointmentType &&
      authenticatedFetch({
        path: `/appointment_types/${appointment.appointmentTypeId}.json`
      }).then(([json, status]) => {
        if (status === 200) {
          setAppointmentType(json)
        }
      })
  }, [appointment])

  useEffect(() => {
    if (!appointmentType || !appointmentType.questions) {
      return
    }
    const { questions } = appointmentType
    const { answersAttributes } = appointment
    const fullAnswersAttributes = questions
      .filter(q => q.audienceType === audienceType)
      .map((question, index) => {
        const { id } = question
        const answerIndex = findIndex(answersAttributes, { questionId: id })
        return answerIndex === -1 ? { questionId: id } : answersAttributes[answerIndex]
      })
    setAppointment({
      ...appointment,
      answersAttributes: fullAnswersAttributes
    })
  }, [appointmentType])

  useEffect(() => {
    if (!appointmentType) {
      return
    }
    const questionsAndAnswers = appointment.answersAttributes.map((answer, index) => {
      const { questions } = appointmentType
      const question = questions[findIndex(questions, { id: answer.questionId })]
      return { question, answer }
    })
    setQuestionsAndAnswers(questionsAndAnswers)
  }, [appointment?.answersAttributes])

  return (
    <AppointmentContext.Provider value={{ appointment, setAppointment }}>
      <FlexGrid flexGridRowGap="scale200" flexGridColumnGap="scale600">
        {questionsAndAnswers.map((questionAndAnswer, index) => {
          return (
            <FlexGridItem key={index}>
              <QuestionAndAnswer {...(questionAndAnswer as any)} />
            </FlexGridItem>
          )
        })}
      </FlexGrid>
    </AppointmentContext.Provider>
  )
}

export default AppointmentTypeQuestionsAndAnswers
