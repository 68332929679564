import React from 'react'
import TwilioPhoneNumber from '../../shared/fields/twilio-phone-number'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import { useCarriersContext } from 'components/contexts/carriers.context'
import { useCarrierUserContext } from 'components/contexts/carrier-user.context'
import { SelectSearch } from 'components/components/SelectSearch'

import { useTranslation } from 'react-i18next'

import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Button from 'components/ui/generic/Button'

export const InviteCarrierUserModalForm = () => {
  const {
    users: { selectedUser: user, disableCarrierUserModalFormFields: disabledFields },
    actions: { searchByCarrierUser, setSelectedUser: setUser }
  } = useCarrierUserContext()

  const { t } = useTranslation()

  const {
    actions: { setModalState }
  } = useCarriersContext()

  const onFieldChange = (fieldName: string, e: any, isEvent = true): void => {
    const value = isEvent ? e.currentTarget.value : e
    setUser({
      ...user,
      [fieldName]: value
    })
  }

  return (
    <>
      <FormControl
        label={t('Settings.CarrierUsers.InviteCarrierUserModal.Fields.EmailAddress.Label.Text')}>
        <Input
          autoComplete="off"
          name="email_address"
          value={user?.emailAddress}
          onChange={e => onFieldChange('emailAddress', e)}
          onBlur={searchByCarrierUser}
        />
      </FormControl>
      <FormControl label={t('Settings.CarrierUsers.InviteCarrierUserModal.Fields.Name.Label.Text')}>
        <Input
          disabled={disabledFields}
          autoComplete="off"
          name="name"
          value={user?.name}
          onChange={e => onFieldChange('name', e)}
        />
      </FormControl>
      <FormControl
        label={t('Settings.CarrierUsers.InviteCarrierUserModal.Fields.PhoneNumber.Label.Text')}>
        <TwilioPhoneNumber disabled={disabledFields} record={user} setRecord={setUser} />
      </FormControl>
      <FormControl
        label={t('Settings.CarrierUsers.InviteCarrierUserModal.Fields.Facility.Label.Text')}>
        <SelectSearch
          multi
          record={user}
          entity="facility"
          onValuesChange={ids => onFieldChange('facilities', ids, false)}
          placeholder={t(
            'Settings.CarrierUsers.InviteCarrierUserModal.Fields.Facility.Placeholder.Text'
          )}
        />
      </FormControl>
      <FormControl
        label={t('Settings.CarrierUsers.InviteCarrierUserModal.Fields.Carrier.Label.Text')}>
        <CyberAutocomplete
          disabled={disabledFields}
          record={user}
          setRecord={setUser}
          indexName="carrier"
          placeholder={t(
            'Settings.CarrierUsers.InviteCarrierUserModal.Fields.Carrier.Placeholder.Text'
          )}
        />
      </FormControl>
      <Button onClick={() => setModalState(true)}>
        {t('Settings.CarrierUsers.InviteCarrierUserModal.Fields.Carrier.CreateCarrier.Text')}
      </Button>
    </>
  )
}
