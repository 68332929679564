export const SET_SELECTED_FACILITY = 'SET_SELECTED_FACILITY'
export const SET_SELECTED_DOCKS = 'SET_SELECTED_DOCKS'
export const SET_DOCK_OPTIONS = 'SET_DOCK_OPTIONS'
export const SET_LOADING = 'SET_LOADING'
export const SET_DOCK_BLACKOUTS = 'SET_DOCK_BLACKOUTS'
export const SET_BLACKOUT_MODAL_LOADING = 'SET_BLACKOUT_MODAL_LOADING'
export const SET_DOCK_AND_TIME_FOR_BLACKOUT_MODAL = 'SET_DOCK_AND_TIME_FOR_BLACKOUT_MODAL'
export const CLOSE_BLACKOUT_MODAL = 'CLOSE_BLACKOUT_MODAL'
export const REFRESH_BLACKOUTS = 'REFRESH_BLACKOUTS'
export const OPEN_BLACKOUT_MODAL_FOR_UPDATE = 'OPEN_BLACKOUT_MODAL_FOR_UPDATE'
export const SET_CONFIRMATION_MODAL = 'SET_CONFIRMATION_MODAL'

export const dockAssignmentReducer = (state, action) => {
  switch (action.type) {
    case REFRESH_BLACKOUTS: {
      return {
        ...state,
        blackoutRefresh: action.payload
      }
    }
    case OPEN_BLACKOUT_MODAL_FOR_UPDATE: {
      return {
        ...state,
        blackoutModalIsOpenForUpdate: true,
        dockBlackout: action.payload.dockBlackout
      }
    }
    case CLOSE_BLACKOUT_MODAL: {
      return {
        ...state,
        blackoutModalIsOpenForCreate: false,
        blackoutModalIsOpenForUpdate: false,
        dockBlackout: null
      }
    }
    case SET_DOCK_AND_TIME_FOR_BLACKOUT_MODAL: {
      return {
        ...state,
        selectedDockForBlackoutModal: state.dockOptions.filter(
          dock => dock.id === action.payload.dockId
        ),
        dockBlackout: {
          startTime: new Date(action.payload.arrivalTime),
          endTime: new Date(action.payload.arrivalTime)
        },
        blackoutModalIsOpenForCreate: true
      }
    }
    case SET_DOCK_BLACKOUTS: {
      return {
        ...state,
        dockBlackout: {
          ...state.dockBlackout,
          ...action.payload
        }
      }
    }
    case SET_SELECTED_FACILITY: {
      return {
        ...state,
        selectedFacility: action.payload
      }
    }
    case SET_SELECTED_DOCKS: {
      return {
        ...state,
        selectedDocks: action.payload
      }
    }
    case SET_DOCK_OPTIONS: {
      return {
        ...state,
        dockOptions: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case SET_BLACKOUT_MODAL_LOADING: {
      return {
        ...state,
        dockBlackoutModalLoading: action.payload
      }
    }
    case SET_CONFIRMATION_MODAL: {
      return {
        ...state,
        confirmationModal: action.payload.value,
        confirmModalFunction: action.payload.confirmFunc
      }
    }
    default:
      return state
  }
}
