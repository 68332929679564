import React from 'react'
import { KIND } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { Plus } from '@phosphor-icons/react'
import Button from '../generic/Button'

const CreateButton = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <Button kind={KIND.primary} IconStart={<Plus />} {...props}>
      {t('Common.Button.Create.Text')}
    </Button>
  )
}
export default CreateButton
