import React from 'react'

interface CalendarReferenceContextType {
  calendarReference?: any
  cacheDate?: any
  setCacheDate?: any
  setDateInCalendarFilter?: any
  dateInCalendarFilter?: any
  weekMode?: any
  setWeekMode?: any
}

export const CalendarReferenceContext: React.Context<CalendarReferenceContextType> =
  React.createContext({
    calendarReference: null,
    cacheDate: null,
    setCacheDate: null,
    setDateInCalendarFilter: null,
    dateInCalendarFilter: null,
    weekMode: null,
    setWeekMode: null
  })
