import { Block } from 'baseui/block'
import React, { useContext } from 'react'
import DriversNavbar from '../DriversNavbar'
import {
  Paragraph1,
  Paragraph3,
  Paragraph4,
  ParagraphMedium,
  ParagraphSmall
} from 'baseui/typography'
import { primitives } from 'components/homepage/custom-theme'
import Input from 'components/ui/generic/Input'
import { Search } from 'baseui/icon'
import { useTranslation } from 'react-i18next'
import { DriversCheckinContext } from 'components/contexts/drivers-checkin.context'
import { useHistory } from 'react-router-dom'
import { AppointmentCard } from 'components/components/AppointmentCard'
import Button from 'components/ui/generic/Button'
import ButtonPrimary from 'components/ui/specific/PrimaryButton'
import { CallBell, WarningCircle, X } from '@phosphor-icons/react'
import {
  ErrorCard,
  notFoundLabelProps,
  PreAndPostCheckinMessage,
  searchContainerProps,
  searchInputProps
} from './DriversCheckinSearch.styled'
import { SCHEDULED } from 'components/utils/appointment-statuses'
import Select from 'components/ui/generic/Select'
import StyledSpinner from '../../../shared/styled-spinner'

const DriversCheckinSearch = () => {
  const { state, actions } = useContext(DriversCheckinContext)
  const { search, appointments, selectedAppointment, searchAppointment } = state
  const { setSearch, setSelectedAppointment, clearSearch, setSelectFacility } = actions
  const { t } = useTranslation()
  const history = useHistory()

  if (searchAppointment?.loading) {
    return <StyledSpinner />
  }

  if (searchAppointment?.showNoActiveFacilityError) {
    return (
      <ErrorCard>
        <WarningCircle size={150} color={primitives.error} />
        <Paragraph1 marginTop="30px" justifyContent="center" alignItems="center">
          <div style={{ textAlign: 'center' }}>
            {t('DriversCheckin.DriversCheckinConfirmation.NoFacilitiesWithDriverSelfCheckin.Text')}
          </div>
        </Paragraph1>
      </ErrorCard>
    )
  }

  if (searchAppointment?.showFacilityNotFoundError) {
    return (
      <ErrorCard>
        <WarningCircle size={150} color={primitives.error} />
        <Paragraph1 marginTop="30px" justifyContent="center" alignItems="center">
          <div style={{ textAlign: 'center' }}>
            {t('DriversCheckin.DriversCheckinConfirmation.NoFacilitiesWithThatCode.Text')}
          </div>
        </Paragraph1>
      </ErrorCard>
    )
  }

  return (
    <>
      <Block width={'100%'} display={'flex'} justifyContent={'center'}>
        <Block width={['100%', '100%', '535px', '535px']}>
          {searchAppointment?.selectedFacility?.appointmentPreference?.preCheckinDriverMessage && (
            <Block maxWidth={['100%']} marginBottom="30px">
              <PreAndPostCheckinMessage
                dangerouslySetInnerHTML={{
                  __html:
                    searchAppointment?.selectedFacility.appointmentPreference
                      .preCheckinDriverMessage
                }}
              />
            </Block>
          )}
          <Block {...searchContainerProps}>
            {!searchAppointment.hideSelect && (
              <Block maxWidth={['100%']} marginBottom="30px">
                <Select
                  placeholder={t('Common.ModelType.Facility.Text')}
                  searchable={false}
                  clearable={false}
                  labelKey="name"
                  options={searchAppointment?.facilityOptions}
                  value={
                    searchAppointment?.selectedFacility
                      ? [
                          {
                            id: searchAppointment?.selectedFacility.id,
                            label: searchAppointment?.selectedFacility.name
                          }
                        ]
                      : []
                  }
                  onChange={({ option }) => {
                    setSelectFacility(option)
                  }}
                />
              </Block>
            )}
            {searchAppointment?.selectedFacility && (
              <>
                <Block maxWidth={['100%']}>
                  <Input
                    endEnhancer={<Search size={24} />}
                    autoComplete="off"
                    name="purchase_order[identifier]"
                    value={search}
                    onChange={e => {
                      setSearch(e.currentTarget.value)
                    }}
                    label={
                      searchAppointment.inputSearchLabel || t('DriversCheckin.Search.Label.Text')
                    }
                    {...searchInputProps}
                  />
                </Block>
                <Block>
                  {search && (
                    <ParagraphMedium
                      overrides={{ Block: { style: { color: primitives.primary } } }}>
                      {t('DriversCheckin.Search.Results.Text')} <strong>{search}</strong>
                    </ParagraphMedium>
                  )}
                </Block>
                <Block marginTop="scale800">
                  <Block maxWidth={['99%']}>
                    {appointments.results.length > 0 ? (
                      <>
                        {appointments?.results?.map((appointment: any) => (
                          <AppointmentCard
                            key={appointment.id}
                            appointment={appointment}
                            selected={selectedAppointment?.id === appointment.id}
                            onClick={() => {
                              setSelectedAppointment(appointment)
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <Block display="flex" justifyContent="center" alignItems="center">
                        <ParagraphSmall {...notFoundLabelProps}>
                          {t('DriversCheckin.Form.NoResults')}
                        </ParagraphSmall>
                      </Block>
                    )}
                  </Block>
                </Block>
              </>
            )}
          </Block>
          <Block>
            {appointments?.total > 0 && (
              <>
                <Paragraph3
                  overrides={{
                    Block: { style: { fontSize: '20px', color: primitives.primary } }
                  }}>
                  {' '}
                  {t('DriversCheckin.Search.Actions.Confirmation.Text')}
                </Paragraph3>
                <Block width={'100%'} display={'flex'} justifyContent={'end'} gridColumnGap={'5px'}>
                  <Button
                    startEnhancer={<X size={16} />}
                    onClick={() => {
                      clearSearch()
                    }}>
                    {t('DriversCheckin.Search.Actions.Cancel.Text')}
                  </Button>
                  {selectedAppointment?.status === SCHEDULED && (
                    <ButtonPrimary
                      disabled={!selectedAppointment}
                      onClick={() => {
                        history.push(`/pass/${selectedAppointment?.id}/checkin`)
                      }}
                      endEnhancer={<CallBell size={16} />}>
                      {t('DriversCheckin.Search.Actions.Checkin.Text')}
                    </ButtonPrimary>
                  )}
                  {selectedAppointment?.status !== SCHEDULED && (
                    <ButtonPrimary
                      disabled={!selectedAppointment}
                      onClick={() => {
                        history.push(`/pass/${selectedAppointment?.id}/details`)
                      }}>
                      {t('DriversCheckin.Search.Actions.AditionalInformation.Text')}
                    </ButtonPrimary>
                  )}
                </Block>
              </>
            )}
          </Block>
        </Block>
      </Block>
    </>
  )
}

const ContainerDriversCheckinSearch = () => {
  return (
    <DriversNavbar>
      <DriversCheckinSearch />
    </DriversNavbar>
  )
}
export default ContainerDriversCheckinSearch
