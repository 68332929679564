import React, { useState, useContext, useEffect } from 'react'
import { HeadingLevel, Heading } from 'baseui/heading'
import { Tabs, Tab } from 'baseui/tabs'
import { Button } from 'baseui/button'
import { CurrentUserContext } from '../homepage/current-user-context'
import authenticatedFetch from '../utils/authenticated-fetch'
import StyledSpinner from '../shared/styled-spinner'
import DockBlockDetails from './tabs/dock-block-details'
import { fancyToast } from '../utils'
import ScheduleForm from '../shared/schedule-form'
import DeleteButton from '../shared/fields/delete-button'
import { capitalize } from 'lodash'

const DockBlockForm = ({ match }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeKey, setActiveKey] = useState<string>('0')
  const [dockBlock, setDockBlock] = useState<any>(null)

  useEffect(() => {
    currentUser &&
      authenticatedFetch({
        path: `/dock_blocks/${match.params.handle}.json`
      })
        .then(([json, status]) => {
          if (status === 200) {
            const startTime = new Date(json.startTime)
            const endTime = new Date(json.endTime)
            setDockBlock({ ...json, startTime, endTime })
          }
        })
        .catch(console.log)
  }, [currentUser])

  const submitDockBlock = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/dock_blocks/${dockBlock.id}.json`,
      method: 'PATCH',
      body: {
        dockBlock
      }
    })
    if (status == 200) {
      fancyToast({ info: 'block was successfully updated' }, status)
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const onDelete = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/dock_blocks/${dockBlock.id}.json`,
      method: 'DELETE'
    })
    if (status == 204) {
      setTimeout(() => {
        window.location.href = '/'
      }, 2500)
      fancyToast({ info: 'Block was successfully deleted.' }, status)
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  if (!currentUser || !dockBlock) {
    return <StyledSpinner />
  }

  return (
    <HeadingLevel>
      <Heading>{dockBlock.name} Block</Heading>
      <Tabs
        onChange={params => {
          setActiveKey(params.activeKey.toString())
        }}
        activeKey={activeKey}>
        <Tab title="Details">
          <DockBlockDetails {...{ dockBlock, setDockBlock }} />
        </Tab>
        <Tab title="Repeat Options (beta)">
          <ScheduleForm record={dockBlock} setRecord={setDockBlock} timeField="startTime" />
        </Tab>
      </Tabs>
      <Button onClick={submitDockBlock} isLoading={loading}>
        Update
      </Button>{' '}
      <DeleteButton onDelete={onDelete} />
    </HeadingLevel>
  )
}

export default DockBlockForm
