import styled from 'styled-components'

export const AnswerWrapper = styled.div`
  max-width: 400px;
`

export const AddValue = styled.div`
  display: inline-block;
  margin-top: 15px;
  margin-left: 5px;
  cursor: pointer;
  margin-bottom: 40px;
`

export const Icon = styled.div`
  display: inline-block;
  transform: translateY(8px);
  margin-right: 6px;
`

export const TrashIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
`

export const AnswerInputWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`
