import React from "react";
import { Route, Switch } from "react-router-dom";
import DockBlockForm from "./dock-block-form";

const DockBlockRoutes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/:handle`} component={DockBlockForm} />
  </Switch>
);

export default DockBlockRoutes;
