import React, { useReducer } from 'react'
import {
  CalendarSelectedOptionsReducer,
  SET_APP_TYPE,
  SET_DATE
} from '../reducers/calendar-selected-options.reducer'
import { createContext } from '../utils/create-context'
import { APPOINTMENT_TYPE_ALL_OPTION } from '../shipper/instant-calendar/modals/create-appointment-modal'

interface Option {
  id: string
  label: string
  name?: string
}

export interface CalendarSelectedOptionsState {
  facility: Option[]
  appType: Option[]
  date: Date
}

const initialState: CalendarSelectedOptionsState = {
  facility: [],
  appType: [
    {
      label: APPOINTMENT_TYPE_ALL_OPTION?.name,
      id: APPOINTMENT_TYPE_ALL_OPTION?.name,
      name: APPOINTMENT_TYPE_ALL_OPTION?.name
    }
  ],
  date: new Date()
}

export const [useCalendarSelectedOptionsContext, CalendarSelectedOptionsContextProvider] =
  createContext()

export const CalendarSelectedOptionsProvider = ({ children }) => {
  const [calendarSelectedOptionsState, dispatch] = useReducer(
    CalendarSelectedOptionsReducer,
    initialState
  )

  const setDate = (date: Date) => {
    dispatch({ type: SET_DATE, payload: date })
  }

  const setAppType = (appType: Option[] | string) => {
    dispatch({ type: SET_APP_TYPE, payload: appType })
  }

  return (
    <CalendarSelectedOptionsContextProvider
      value={{
        calendarSelectedOptionsState,
        actions: {
          setDate,
          setAppType
        }
      }}>
      {children}
    </CalendarSelectedOptionsContextProvider>
  )
}

export default CalendarSelectedOptionsProvider
