import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import TwilioPhoneNumber from 'components/shared/fields/twilio-phone-number'
import { SelectSearch } from 'components/components/SelectSearch'
import { inviteService } from 'components/services/invite.service'
import { useUserRolesContext } from '../../contexts/user-roles.context'
import { INTERNAL, YARD_JOCKEY_AUDIENCE } from '../../models/Role'
import Select from 'components/ui/generic/Select'
import Button from 'components/ui/generic/Button'
import Checkbox from 'components/ui/generic/Checkbox'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import { useTranslation } from 'react-i18next'
import { getUserTypeForInternals } from '../../utils/get-user-type-for-internals'
import { YARD_JOCKEY_LABEL } from '../../models/User'
import { DatePicker } from 'baseui/datepicker'
import TimePicker from 'components/ui/generic/TimePicker'

export const EXTERNAL_ID_MAX_LENGTH = 15

export const InviteShipperModal = ({ isOpen, close }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [user, setUser] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedInternalUserRole, setSelectedInternalUserRole] = useState<any>([])
  const [internalUsersRoles, setInternalUsersRoles] = useState<any>([])
  const { t } = useTranslation()

  const {
    roles: { items },
    actions: { search }
  } = useUserRolesContext()

  useEffect(() => {
    search(undefined, [INTERNAL, YARD_JOCKEY_AUDIENCE])
  }, [])

  useEffect(() => {
    if (items && items.length) {
      setInternalUsersRoles(
        items.map(item => {
          return { label: item.name, id: item.id, userType: getUserTypeForInternals(item.audience) }
        })
      )
    }
  }, [items])

  const onClose = () => {
    close()
    setUser({})
    setSelectedInternalUserRole([])
  }

  const inviteUser = async () => {
    setLoading(true)
    await inviteService.inviteUser(user, currentUser.shipperId, onClose)
    setLoading(false)
  }

  const compareTimes = () => {
    if (user?.startShiftDate && user?.endShiftDate) {
      const startTimeOnly = new Date(user?.startShiftDate)
      const endTimeOnly = new Date(user?.endShiftDate)

      startTimeOnly.setFullYear(1970, 0, 1)
      endTimeOnly.setFullYear(1970, 0, 1)

      if (endTimeOnly < startTimeOnly) {
        return true
      }
    }

    return false
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{t('Settings.InternalUsers.Modal.Header.Text')}</ModalHeader>
      <ModalBody>
        <FormControl label={t('Settings.InternalUsers.Modal.Fields.Name.Label.Text')}>
          <Input
            autoComplete="off"
            name="user[name]"
            value={user.name}
            onChange={evt => {
              setUser({
                ...user,
                name: evt.currentTarget.value
              })
            }}
          />
        </FormControl>
        <FormControl label={t('Settings.InternalUsers.Modal.Fields.EmailAddress.Label.Text')}>
          <Input
            autoComplete="off"
            name="user[email_address]"
            value={user.emailAddress}
            onChange={evt => {
              setUser({
                ...user,
                emailAddress: evt.currentTarget.value
              })
            }}
          />
        </FormControl>
        <FormControl label={t('Settings.InternalUsers.Modal.Fields.PhoneNumber.Label.Text')}>
          <TwilioPhoneNumber record={user} setRecord={setUser} />
        </FormControl>
        <FormControl label={t('Settings.InternalUsers.Modal.Fields.Facility.Label.Text')}>
          <SelectSearch
            multi
            record={user}
            placeholder={t('Settings.InternalUsers.Modal.Fields.Facility.PlaceHolder.Text')}
            onValuesChange={ids =>
              setUser(user => ({
                ...user,
                facilities: ids
              }))
            }
            entity="facility"
          />
        </FormControl>
        <FormControl label="Role">
          <Select
            clearable={false}
            backspaceClearsInputValue
            maxDropdownHeight="250px"
            deleteRemoves={true}
            value={selectedInternalUserRole}
            placeholder={t('Settings.InternalUsers.Modal.Fields.Role.PlaceHolder.Text')}
            options={internalUsersRoles}
            onChange={({ option }) => {
              setUser({
                ...user,
                userRoleId: option.id,
                userType: option.userType,
                userNameId: option.value
              })
              setSelectedInternalUserRole([option])
            }}
          />
        </FormControl>
        {selectedInternalUserRole[0]?.label !== YARD_JOCKEY_LABEL && (
          <Checkbox
            checked={user.admin}
            onChange={e => {
              setUser({ ...user, admin: e.currentTarget.checked })
            }}
            label={t('Settings.InternalUsers.Modal.AdminCheckBox.Label.Text')}
          />
        )}
        {selectedInternalUserRole[0]?.label === YARD_JOCKEY_LABEL && (
          <>
            <FormControl label={t('Settings.InternalUsers.Modal.Fields.ExternalId.Label.Text')}>
              <Input
                autoComplete="off"
                name="user[external_id]"
                value={user.externalId}
                maxLength={EXTERNAL_ID_MAX_LENGTH}
                onChange={evt => {
                  setUser({
                    ...user,
                    externalId: evt.currentTarget.value
                  })
                }}
              />
            </FormControl>
            <FormControl label={t('Settings.InternalUsers.Modal.Fields.StartShiftDate.Label.Text')}>
              <DatePicker
                value={user.startShiftDate ? new Date(user.startShiftDate) : null}
                onChange={({ date }) =>
                  setUser({
                    ...user,
                    startShiftDate: date
                  })
                }
                placeholder={t('Settings.InternalUsers.Modal.Fields.StartShiftDate.Label.Text')}
                formatString="MM/dd/yyyy"
              />
            </FormControl>
            <FormControl label={t('Settings.InternalUsers.Modal.Fields.EndShiftDate.Label.Text')}>
              <DatePicker
                value={user.endShiftDate ? new Date(user.endShiftDate) : null}
                onChange={({ date }) =>
                  setUser({
                    ...user,
                    endShiftDate: date
                  })
                }
                placeholder={t('Settings.InternalUsers.Modal.Fields.EndShiftDate.Label.Text')}
                formatString="MM/dd/yyyy"
              />
            </FormControl>
            <FormControl label={t('Settings.InternalUsers.Modal.Fields.StartShiftTime.Label.Text')}>
              <TimePicker
                placeholder={t('Settings.InternalUsers.Modal.Fields.StartShiftTime.Label.Text')}
                step={1800}
                nullable
                value={user.startShiftDate ? new Date(user.startShiftDate) : null}
                onChange={params => {
                  setUser({
                    ...user,
                    startShiftDate: params
                  })
                }}
              />
            </FormControl>
            <FormControl label={t('Settings.InternalUsers.Modal.Fields.EndShiftTime.Label.Text')}>
              <TimePicker
                placeholder={t('Settings.InternalUsers.Modal.Fields.EndShiftTime.Label.Text')}
                step={1800}
                nullable
                value={user.endShiftDate ? new Date(user.endShiftDate) : null}
                onChange={params =>
                  setUser({
                    ...user,
                    endShiftDate: params
                  })
                }
              />
            </FormControl>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={
            !user.emailAddress ||
            !user.name ||
            user.facilities?.length === 0 ||
            user.endShiftDate < user.startShiftDate ||
            compareTimes()
          }
          isLoading={loading}
          onClick={inviteUser}>
          {t('Settings.InternalUsers.Modal.InviteButton.Text')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
