import { CustomCancelReason } from 'components/models/CustomCancelReason'
import { CustomCancelReasonState } from '../contexts/custom-cancel-reason.context'

export const SET_LOADING = 'SET_LOADING'
export const SET_FORM_OPEN = 'SET_FORM_OPEN'
export const SET_AVAILABLE_CANCEL_REASONS = 'SET_AVAILABLE_CANCEL_REASONS'
export const SET_ACTIVE_CANCEL_REASONS = 'SET_ACTIVE_CANCEL_REASONS'
export const SET_SELECTED_CUSTOM_CANCEL_REASON = 'SET_SELECTED_CUSTOM_CANCEL_REASON'
export const RECALCULATE_COLUMNS = 'RECALCULATE_COLUMNS'
export const SET_COLUMNS = 'SET_COLUMNS'
export const SET_ALL_CANCEL_REASONS = 'SET_ALL_CANCEL_REASONS'
export const SET_SAVING = 'SET_SAVING'

const extractAvailable = (
  allCancelReasons: CustomCancelReason[],
  assignedCancelReasons: CustomCancelReason[]
): CustomCancelReason[] => {
  const assignedIds = assignedCancelReasons?.map(eqType => eqType.id) || []
  return allCancelReasons?.filter(eqType => !assignedIds.includes(eqType.id))
}

export const CustomCancelReasonReducer = (state: CustomCancelReasonState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SET_FORM_OPEN: {
      return {
        ...state,
        customCancelReason: {
          ...state.customCancelReason,
          isFormOpen: action.payload
        }
      }
    }
    case SET_SELECTED_CUSTOM_CANCEL_REASON: {
      return {
        ...state,
        customCancelReason: {
          ...state.customCancelReason,
          selectedCustomCancelReason: action.payload
        }
      }
    }
    case SET_SAVING:
      return {
        ...state,
        customCancelReason: {
          ...state.customCancelReason,
          saving: action.payload
        }
      }
    case SET_AVAILABLE_CANCEL_REASONS:
      return {
        ...state,
        availableCancelReasons: action.payload
      }
    case SET_ACTIVE_CANCEL_REASONS:
      return {
        ...state,
        activeCancelReasons: action.payload
      }
    case SET_ALL_CANCEL_REASONS:
      return {
        ...state,
        activeCancelReasons: action.payload.activeCancelReasons,
        availableCancelReasons: action.payload.availableCancelReasons
      }
    case RECALCULATE_COLUMNS:
      return {
        ...state,
        columns: {
          ...state.columns,
          selected: {
            ...state.columns.selected,
            list: action.payload.assigned || []
          },
          available: {
            ...state.columns.available,
            list: extractAvailable(action.payload.all, action.payload.assigned) || []
          }
        }
      }
    case SET_COLUMNS:
      return {
        ...state,
        columns: action.payload
      }
    default:
      return state
  }
}
