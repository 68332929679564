import { Checkbox } from 'baseui/checkbox'
import React from 'react'

const VCheckbox = ({ label, value, isRefined, refine, overrides, ...props }) => {
  return (
    <Checkbox
      overrides={{
        Label: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize,
            wordBreak: 'break-word'
          })
        },
        Checkmark: {
          style: ({ $theme }) => ({
            borderTopColor: $theme.colors.inputBorder,
            borderBottomColor: $theme.colors.inputBorder,
            borderLeftColor: $theme.colors.inputBorder,
            borderRightColor: $theme.colors.inputBorder,
            borderTopStyle: $theme.borders.borderStyle,
            borderBottomStyle: $theme.borders.borderStyle,
            borderLeftStyle: $theme.borders.borderStyle,
            borderRightStyle: $theme.borders.borderStyle,
            borderTopWidth: $theme.borders.borderWidth,
            borderBottomWidth: $theme.borders.borderWidth,
            borderLeftWidth: $theme.borders.borderWidth,
            borderRightWidth: $theme.borders.borderWidth,
            borderTopLeftRadius: $theme.borders.radius100,
            borderTopRightRadius: $theme.borders.radius100,
            borderBottomLeftRadius: $theme.borders.radius100,
            borderBottomRightRadius: $theme.borders.radius100
          })
        },
        ToggleTrack: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.gray
          })
        },
        Toggle: {
          style: ({ $checked, $theme }) => ({
            backgroundColor: $checked ? $theme.colors.accent : $theme.colors.white
          })
        },
        ...overrides
      }}
      {...props}>
      {label}
    </Checkbox>
  )
}
export default VCheckbox
