import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tag } from 'baseui/tag'
import { camelCase, upperFirst } from 'lodash'

const FilterTag = ({ filterValue, filterName, onActionClick }) => {
  const { t } = useTranslation()
  return (
    <Tag
      overrides={{
        Text: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize,
            maxWidth: 'none'
          })
        },
        Root: {
          style: ({ $theme }) => ({
            borderTopWidth: $theme.borders.borderWidth,
            borderBottomWidth: $theme.borders.borderWidth,
            borderLeftWidth: $theme.borders.borderWidth,
            borderRightWidth: $theme.borders.borderWidth,
            marginLeft: 0,
            marginTop: 0
          })
        }
      }}
      onActionClick={onActionClick}>
      {`${t(`Common.Dropdown.Titles.${upperFirst(camelCase(filterName))}.Text`)}:`}
      <strong>{filterValue}</strong>
    </Tag>
  )
}

export default FilterTag
