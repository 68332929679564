import React, { useContext, useMemo, useState } from 'react'
import { HeadingXSmall } from 'baseui/typography'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import { Block } from 'baseui/block'
import { useTranslation } from 'react-i18next'
import StyledSpinner from 'components/shared/styled-spinner'
import useSearchClient from 'components/shared/hooks/use-search-client'
import { CARRIER } from 'components/models/User'
import { CarrierUserInstantSearchInput } from './CarrierUserInstantSearchInput'
import ConnectedHits from './ConnectedHits'
import ConnectedRefinementList from './Connected-refinement-list'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { CarriersProvider } from 'components/contexts/carriers.context'
import { CarrierUsersProvider } from 'components/contexts/carrier-user.context'
import {
  INDEX_NAME,
  QUERY_BY,
  generateFacetFilters
} from '../../constants/typesense-connected-hits'
import Header from 'components/ui/generic/Header'
import FiltersPopover from 'components/ui/specific/FiltersPopover'
import FilterSummary from 'components/appointments/appointment-instantsearch/FilterSummary'
import ConnectedPagination from '../../schedulers/SchedulerUserInstantSearch/connected-pagination'

const CarrierUserInstantSearch = () => {
  const { t } = useTranslation()
  const client = useSearchClient({ queryBy: QUERY_BY }, 'CARRIER_USER_SCOPE')
  const { currentUser } = useContext(CurrentUserContext)
  const facetFilters = useMemo(
    () => [...generateFacetFilters(CARRIER), `shipper_ids:${currentUser.shipperId}`],
    [currentUser?.shipperId]
  )
  const [numberOfHits, setNumberOfHits] = useState<number>(0)
  const [appliedFilters, setAppliedFilters] = useState({})

  if (!client) {
    return <StyledSpinner />
  }

  return (
    <InstantSearch indexName={INDEX_NAME} searchClient={client}>
      <CarrierUsersProvider>
        <CarriersProvider>
          <Header
            title={t('Settings.SideMenu.Titles.CarrierUsers.Text')}
            rightItems={[
              <FiltersPopover tag={Object.entries(appliedFilters).length}>
                <Block
                  backgroundColor="#fff"
                  overrides={{
                    Block: {
                      style: () => ({
                        maxWidth: '284px'
                      })
                    }
                  }}>
                  <HeadingXSmall margin="0 0 8px">Filters</HeadingXSmall>
                  <Block display="flex" flexDirection="column" gridRowGap="scale400">
                    <ConnectedRefinementList
                      attribute="carrier_name"
                      setAppliedFilters={setAppliedFilters}
                    />
                  </Block>
                </Block>
              </FiltersPopover>,
              <CarrierUserInstantSearchInput />
            ]}
          />

          <Configure facetFilters={facetFilters} />

          <Block paddingBottom="scale200" minHeight="60vh">
            {Object.keys(appliedFilters).length > 0 && (
              <FilterSummary
                numberOfHits={numberOfHits}
                appliedFilters={appliedFilters}
                label="user"
              />
            )}
            <ConnectedHits setNumberOfHits={setNumberOfHits} />
            <ConnectedPagination />
          </Block>
        </CarriersProvider>
      </CarrierUsersProvider>
    </InstantSearch>
  )
}

export default CarrierUserInstantSearch
