import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AppointmentTypeSearch from './appointment-type-search'
import AppointmentTypeCreate from './appointment-type-create'
import AppointmentTypeForm from './appointment-type-form'

const AppointmentTypeRoutes = ({ match }) => (
  <>
    <Route path={match.url} exact component={AppointmentTypeSearch} />
    <Switch>
      <Route exact path={`${match.url}/new`} component={AppointmentTypeCreate} />
      <Route exact path={`${match.url}/:handle`} component={AppointmentTypeForm} />
    </Switch>
  </>
)

export default AppointmentTypeRoutes
