import React, { FC, useContext } from 'react'
import TimeFormatter from 'components/utils/time-formatter'
import { AuditLog, CREATE, DESTROY, UPDATE } from '..'
import { ParagraphXSmall, LabelXSmall } from 'baseui/typography'
import { CustomLabelsContext } from 'components/contexts/custom-labels-context'
import { t } from 'i18next'

export const DEFAULT_ENTRY_MESSAGE = 'Automated integrations'
export const DEFAULT_LABELS = {
  arrivalTime: 'arrivalTime',
  dock: 'dock'
}
export interface Labels {
  scheduler: string
  arrivalTime: string
  carrier: string
  equipmentType: string
  purchaseOrderIdentfier: string
  dock: string
}

const fullTimeFormatter = value => new TimeFormatter('fullDate').format(value)

const addPrefix = action => `${t('Common.Actions.Prefix.Text')} ${action}`

const validateDockUnassigned = (key, item, value) =>
  key === DEFAULT_LABELS.dock && !item.id ? t('Common.Select.DefaultUnassignedColumn.Text') : value

const getFormattedUser = (username, userEmailAddress, deleted, disabled) => {
  let formattedUser =
    userEmailAddress && !deleted
      ? `${username} (${userEmailAddress})`
      : username || DEFAULT_ENTRY_MESSAGE

  formattedUser =
    deleted || disabled ? `${formattedUser}${getUserStatus(disabled, deleted)}` : formattedUser
  return formattedUser
}

const getUserStatus = (disabled, deleted) => {
  if (deleted) return ` (${t('Common.UserStatus.Deleted.Text')})`
  if (disabled) return ` (${t('Common.UserStatus.Deactivated.Text')})`
}

const buildUpdateMessage = (labels, auditedChanges, response) => {
  const keys = Object.keys(auditedChanges)
  const labelsKeys = Object.keys(labels)
  const filteredKeys = keys.filter(key => labelsKeys.includes(key))

  const fieldsUpdated = filteredKeys.map(key => {
    const auditedChange = auditedChanges[key]
    const label = labels[key]?.toLocaleLowerCase()

    if (auditedChange.length === 1) {
      // It was added a new item

      if (filteredKeys.length === 1) {
        // Remove the "modified" action because it's a single change
        response.pop()
      }

      const [newItem] = auditedChange
      const addedAction = addPrefix(t('Common.Actions.Added.Text'))

      return `${addedAction} ${label}: ${newItem.name}`
    }

    const [oldItem, newItem] = auditedChange
    const isArrivalTime = key === DEFAULT_LABELS.arrivalTime
    let oldValue = isArrivalTime ? fullTimeFormatter(oldItem) : oldItem.name
    let newValue = isArrivalTime ? fullTimeFormatter(newItem) : newItem.name

    oldValue = validateDockUnassigned(key, oldItem, oldValue)
    newValue = validateDockUnassigned(key, newItem, newValue)

    return `${label}: ${oldValue} ${t('Common.Connectors.To.Text')} ${newValue}`
  })

  response.push(fieldsUpdated.join(', '))
}

const auditEntryMessage = ({
  action,
  auditedChanges,
  auditableType,
  comment = null,
  labels,
  userEmailAddress = null,
  username,
  disabled,
  deleted
}) => {
  const formattedUser = getFormattedUser(username, userEmailAddress, deleted, disabled)
  const pastTenseAction = {
    [CREATE]: addPrefix(t('Common.Actions.Created.Text')),
    [UPDATE]: addPrefix(t('Common.Actions.Modified.Text')),
    [DESTROY]: addPrefix(t('Common.Actions.Destroyed.Text'))
  }[action]

  const response = [formattedUser, pastTenseAction]

  if (action === UPDATE) {
    buildUpdateMessage(labels, auditedChanges, response)
  } else {
    const isPurchaseOrder = auditableType === 'PurchaseOrder'
    const labelPOI = labels.purchaseOrderIdentfier

    const formattedAuditableType = isPurchaseOrder && labelPOI ? labelPOI : auditableType

    response.push(formattedAuditableType.toLocaleLowerCase())

    if (isPurchaseOrder) {
      response.push(auditedChanges.identifier)
    }
  }

  if (comment) response.push(`- "${comment}"`)

  return response.join(' ')
}

const AuditLogEntry: FC<AuditLog> = props => {
  const { customLabel } = useContext(CustomLabelsContext)
  const { createdAt, user, username, ...otherProps } = props

  const finalUserName = user ? user.name : username
  const userEmailAddress = user?.emailAddress
  const labels: Labels = {
    arrivalTime: t('Common.Fields.ArrivalTime.Text'),
    carrier: t('Common.Fields.Carrier.Text'),
    equipmentType: t('Common.Fields.EquipmentType.Text'),
    scheduler: customLabel('scheduler'),
    purchaseOrderIdentfier: customLabel('purchaseOrderIdentifiers'),
    dock: t('Common.Fields.Dock.Text')
  }

  const auditLogsMessages = auditEntryMessage({
    labels,
    userEmailAddress,
    username: finalUserName,
    disabled: user?.disabled,
    deleted: user?.deleted,
    ...otherProps
  })
  const dateLabel = fullTimeFormatter(createdAt)

  return (
    <>
      <ParagraphXSmall>{auditLogsMessages}</ParagraphXSmall>
      <LabelXSmall>{dateLabel}</LabelXSmall>
    </>
  )
}

export default AuditLogEntry
