import React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Tag, KIND } from 'baseui/tag'
import { ArrowLineDown, ArrowLineUp } from '@phosphor-icons/react'
import { Block, BlockProps } from 'baseui/block'
import { Card, StyledBody } from 'baseui/card'
import LabelField from './LabelField'
import { Label1, Label2 } from 'baseui/typography'
import { StatefulTooltip } from 'baseui/tooltip'

// Icons
import * as Icons from 'react-icons/fa'
import { Drop, Van, Truck, Snowflake, LadderSimple, BatteryFull } from '@phosphor-icons/react'
import { CustomLabel2Props, trailerStatusTagProps } from './AppointmentCard.styled'
import { primitives } from 'components/homepage/custom-theme'
import { useTranslation } from 'react-i18next'

const itemProps: BlockProps = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center'
}

const DOCK_TYPE_ICON = {
  ['van']: Van,
  ['tanker']: Drop,
  ['flatbed']: Truck,
  ['reefer']: Snowflake,
  ['container']: BatteryFull,
  ['rail']: LadderSimple,
  ['Van (Floor Loaded)']: Van,
  ['Van (Pallet Loaded)']: Van,
  ['van_floor']: Van,
  ['van_pallet']: Van
}

interface AppointmentCardProps {
  appointment: any
  selected?: boolean
  onClick?: (event: Event) => any
}

const DEFAULT_ICON_NAME = 'FaPallet'

export const AppointmentCard = ({ appointment, selected, onClick }: AppointmentCardProps) => {
  const { schedulerName, purchaseOrderIdentifiers } = appointment
  const { t } = useTranslation()
  const fields = [
    {
      key: 'arrivalTime',
      label: t('Scheduler.Appointments.AppointmentCard.ArrivalDate.Text'),
      capitalize: false,
      showIcon: false,
      isDate: true
    },
    {
      key: 'confirmationId',
      label: t('Scheduler.Appointments.AppointmentCard.AppointmentID.Text'),
      capitalize: false
    },
    {
      key: 'handlingMethod',
      label: t('Scheduler.Appointments.AppointmentCard.Handling.Text'),
      capitalize: true
    },
    {
      key: 'appointmentType.type',
      label: t('Scheduler.Appointments.AppointmentCard.AppointmentDirection.Text'),
      capitalize: true
    },
    {
      key: 'appointmentType.appointmentTypeName',
      label: t('Scheduler.Appointments.AppointmentCard.AppointmentType.Text'),
      capitalize: true
    },
    {
      key: 'trailer.trailerNumber',
      label: t('Scheduler.Appointments.AppointmentCard.TrailerNumber.Text'),
      capitalize: false
    },
    {
      key: 'carrier.carrierName',
      label: t('Scheduler.Appointments.AppointmentCard.Carrier.Text'),
      capitalize: false
    },
    {
      key: 'driverName',
      label: t('Scheduler.Appointments.AppointmentCard.DriverName.Text'),
      capitalize: false
    }
  ]
  const IconComponent =
    DOCK_TYPE_ICON[appointment.equipmentType.equipmentTypeName] ||
    Icons[appointment.equipmentType.equipmentTypeIcon] ||
    Icons[DEFAULT_ICON_NAME]

  return (
    <Block onClick={onClick}>
      <Card
        overrides={{
          Root: {
            style: {
              backgroundColor: 'white',
              borderColor: selected ? primitives.accent : '#dbe6ff',
              marginTop: '16px',
              width: '100%',
              cursor: onClick ? 'pointer' : 'inherit'
            }
          }
        }}>
        <StyledBody>
          <Block
            minWidth={'100%'}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Block display={'flex'} alignItems={'center'} gridColumnGap={'5px'}>
              <IconComponent size="16px" />
              <Label1>{schedulerName}</Label1>
            </Block>
            <Block
              display="flex"
              flexDirection="row"
              alignItems="center"
              gridColumnGap={'5px'}
              maxWidth={'50%'}>
              <StatefulTooltip accessibilityType={'tooltip'} content={purchaseOrderIdentifiers}>
                <Label2 {...CustomLabel2Props}>{purchaseOrderIdentifiers}</Label2>
              </StatefulTooltip>
              <div
                style={{
                  minWidth: '12px'
                }}>
                {appointment.appointmentType.type?.includes('Inbound') ? (
                  <ArrowLineDown size={12} color="#082766" />
                ) : (
                  <ArrowLineUp size={12} color="#082766" />
                )}
              </div>
              {appointment.trailer?.trailerStatus && (
                <Tag kind={KIND.blue} closeable={false} {...trailerStatusTagProps}>
                  {appointment.trailer.trailerStatus}
                </Tag>
              )}
            </Block>
          </Block>
          <Block marginTop="scale400" />
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800" flexGridRowGap="scale800">
            <FlexGridItem {...itemProps}>
              {fields.slice(0, 4).map(({ key, label, capitalize, showIcon, isDate }) => (
                <LabelField
                  pointer={key}
                  field={label}
                  appointment={appointment}
                  capitalize={capitalize}
                  showIcon={showIcon}
                  isDate={isDate}
                />
              ))}
            </FlexGridItem>
            <FlexGridItem {...itemProps}>
              {fields.slice(4).map(({ key, label, capitalize, showIcon, isDate }) => (
                <LabelField
                  pointer={key}
                  field={label}
                  isDate={isDate}
                  appointment={appointment}
                  capitalize={capitalize}
                  showIcon={showIcon}
                />
              ))}
            </FlexGridItem>
          </FlexGrid>
        </StyledBody>
      </Card>
    </Block>
  )
}

export default AppointmentCard
