export type Breakpoints = 'sm' | 'md' | 'lg' | 'xl'

type BreakpointRange = Record<Breakpoints, { min: number; max: number }>

export const breakpoints: BreakpointRange = {
  sm: {
    min: 0,
    max: 450
  },
  md: {
    min: 450,
    max: 800
  },
  lg: {
    min: 800,
    max: 1240
  },
  xl: {
    min: 1240,
    max: 1700
  }
}
