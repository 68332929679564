import React, { useContext, useEffect } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { LabelMedium } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { useTranslation } from 'react-i18next'
import Accordion from 'components/ui/generic/Accordion'
import CheckboxItem from 'components/ui/generic/CheckboxItem'

const NonConnectedRefinementList = props => {
  const [, theme] = useStyletron()
  const { items, refine, setAppliedFilters } = props
  const refined = items.some(({ isRefined }) => isRefined)
  const { shipperEquipmentTypes } = useContext(CurrentUserContext)
  const equipmentTypesMap = new Map(
    shipperEquipmentTypes?.map(item => {
      return [item.id, item.name]
    })
  )
  const { t } = useTranslation()

  useEffect(() => {
    if (refined && setAppliedFilters) {
      setAppliedFilters(c => ({
        ...c,
        equipment_type_id: items
          .filter(item => item.isRefined)
          .map(item => equipmentTypesMap.get(item.label))
      }))
    } else if (setAppliedFilters) {
      setAppliedFilters(c => {
        const updatedFilters = { ...c }
        delete updatedFilters.equipment_type_id
        return { ...updatedFilters }
      })
    }
  }, [refined, setAppliedFilters, items])

  return (
    <Accordion disabled={refined} title={t('Common.Dropdown.Titles.EquipmentType.Text')}>
      {items.length > 0 ? (
        items
          .filter(item => equipmentTypesMap.get(item.label))
          .map((item, i) => {
            return (
              <CheckboxItem
                {...{ ...item, label: equipmentTypesMap.get(item.label) }}
                refine={refine}
                key={i}
              />
            )
          })
      ) : (
        <LabelMedium color={theme.colors.contentInverseTertiary}>
          {t('Common.Dropdown.NoOptions.Text')}
        </LabelMedium>
      )}
    </Accordion>
  )
}

const ConnectedDockTypeRefinementList = connectRefinementList(NonConnectedRefinementList)

const DockTypeRefinementList = props => (
  <ConnectedDockTypeRefinementList attribute="equipment_type_id" {...props} />
)

export default DockTypeRefinementList as any
