import React, { useEffect } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { LabelMedium } from 'baseui/typography'
import { useStyletron } from 'baseui'

import { useTranslation } from 'react-i18next'
import Accordion from 'components/ui/generic/Accordion'
import CheckboxItem from 'components/ui/generic/CheckboxItem'

const NonConnectedRefinementList = props => {
  const [, theme] = useStyletron()
  const { items, refine, setAppliedFilters } = props
  const refined = items.some(({ isRefined }) => isRefined)
  const { t } = useTranslation()

  useEffect(() => {
    if (refined && setAppliedFilters) {
      setAppliedFilters(c => ({
        ...c,
        scheduler_name: items.filter(item => item.isRefined).map(item => item.label)
      }))
    } else if (setAppliedFilters) {
      setAppliedFilters(c => {
        const updatedFilters = { ...c }
        delete updatedFilters.scheduler_name
        return { ...updatedFilters }
      })
    }
  }, [refined, setAppliedFilters, items])

  return (
    <Accordion disabled={refined} title={t('Common.Dropdown.Titles.VendorsCompanies.Text')}>
      {items.length > 0 ? (
        items
          .filter(item => item.label)
          .map((item, i) => <CheckboxItem {...item} refine={refine} key={i} />)
      ) : (
        <LabelMedium color={theme.colors.contentInverseTertiary}>
          {t('Common.Dropdown.NoOptions.Text')}
        </LabelMedium>
      )}
    </Accordion>
  )
}

const ConnectedSchedulerRefinementList = connectRefinementList(NonConnectedRefinementList)

const SchedulerRefinementList = props => (
  <ConnectedSchedulerRefinementList attribute="scheduler_name" {...props} />
)

export default SchedulerRefinementList as any
