import { Checkbox } from 'baseui/checkbox'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { CalendarToggleContext } from 'components/contexts/calendar-toggle-context'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { string } from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import SchedulerCheckbox from './scheduler-checkbox'

const SchedulerPicker = () => {
  const { calendarToggles, setCalendarToggles } = useContext(CalendarToggleContext)

  const { currentUser } = useContext(CurrentUserContext)
  const [schedulers, setSchedulers] = useState<{ id: string; name: string }[]>([])
  const schedulerIds = schedulers.map(({ id }) => id)
  const { hiddenSchedulerIds } = calendarToggles
  const hiddenSchedulerIdSet = new Set(hiddenSchedulerIds)

  useEffect(() => {
    const searchSchedlers = async () => {
      const [{ hits }, status] = await authenticatedFetch({
        path: `/search.json`,
        method: 'POST',
        body: {
          search: {
            indexName: 'Scheduler',
            params: {
              query: ''
            }
          }
        }
      })

      if ([200, 304].includes(status)) {
        setSchedulers(hits)
      }
    }
    currentUser && schedulers.length === 0 && searchSchedlers()
  }, [currentUser])

  return (
    <FormControl label="Schedulers">
      <FlexGrid flexGridRowGap="scale100">
        <FlexGridItem paddingLeft="scale400">
          <Checkbox
            checked={!schedulerIds.some(schedulerId => hiddenSchedulerIdSet.has(schedulerId))}
            checkmarkType="toggle"
            labelPlacement="left"
            onChange={e => {
              if (e.currentTarget.checked) {
                setCalendarToggles({
                  ...calendarToggles,
                  hiddenSchedulerIds: []
                })
              } else {
                setCalendarToggles({
                  ...calendarToggles,
                  hiddenSchedulerIds: schedulerIds.map(schedulerId => schedulerId)
                })
              }
            }}>
            All
          </Checkbox>
        </FlexGridItem>
        {schedulers.map((scheduler, i) => {
          return (
            <FlexGridItem key={i}>
              <SchedulerCheckbox scheduler={scheduler} />
            </FlexGridItem>
          )
        })}
      </FlexGrid>
    </FormControl>
  )
}

export default SchedulerPicker
