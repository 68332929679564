import React from 'react'
import UserOnboardingModal from '../../facilities/user-onboarding-modal'
import Card from 'components/components/Card'
import { AppointmentProvider, useAppointmentContext } from 'components/contexts/appointment-context'
import FacilityProvider from 'components/contexts/facility-context'
import { CarriersProvider } from 'components/contexts/carriers.context'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import Header from 'components/ui/generic/Header'
import { useTranslation } from 'react-i18next'

import StyledSpinner from 'components/shared/styled-spinner'
import { Block } from 'baseui/block'
import AppointmentConfirmation from '../CreateAppointmentForExternalUsers/AppointmentConfirmation'
import OpenSchedulingCreateAppointmentDrop from './OpenSchedulingCreateAppointmentDrop'
import { useStyletron } from 'baseui'
import { useHistory } from 'react-router-dom'
import { CaretLeft } from '@phosphor-icons/react'
import { primitives } from '../../homepage/custom-theme'

export const PurchaseOrderLabelsContext = React.createContext({
  purchaseOrderLabels: null
})

const WrappedProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <CustomQuestionsProvider>
    <CarriersProvider>
      <AppointmentProvider>
        <FacilityProvider>{children}</FacilityProvider>
      </AppointmentProvider>
    </CarriersProvider>
  </CustomQuestionsProvider>
)

const FacilityBook: React.FC = () => {
  return (
    <>
      <WrappedProviders>
        <UserOnboardingModal />
        <HeaderComponent />
        <AppointmentConfirmationConditionalComponent />
      </WrappedProviders>
    </>
  )
}

export default FacilityBook

const HeaderComponent: React.FC = () => {
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const history = useHistory()

  return (
    <Header
      title={
        <div className={css({ display: 'flex', gap: theme.sizing.scale400, alignItems: 'center' })}>
          <div
            className={css({ cursor: 'pointer', transform: 'translateY(2px)' })}
            onClick={() => history.goBack()}>
            <CaretLeft color={primitives.primary} />
          </div>
          {t('Scheduler.OpenScheduling.Header.SchedulingPortal.Text')}
        </div>
      }
    />
  )
}

const AppointmentConfirmationConditionalComponent = () => {
  const { state } = useAppointmentContext()
  const { confirmationIds, loading } = state
  return (
    <>
      {confirmationIds.length > 0 ? (
        <AppointmentConfirmation confirmationIds={confirmationIds} />
      ) : (
        <Card>
          {!loading ? (
            <OpenSchedulingCreateAppointmentDrop />
          ) : (
            <Block
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              width="100%">
              <StyledSpinner />
            </Block>
          )}
        </Card>
      )}
    </>
  )
}
