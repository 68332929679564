import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { DriversCheckinProvider } from 'components/contexts/drivers-checkin.context'
import DriversCheckinSearch from 'components/pages/DriversCheckin/DriversCheckinSearch/DriversCheckinSearch'
import DriversCheckinForm from 'components/pages/DriversCheckin/DriversCheckinForm'

type RouteParams = {
  siteCode?: string
}

const DriversCheckinRoutes = () => {
  const { siteCode } = useParams<RouteParams>()

  return (
    <DriversCheckinProvider>
      <Switch>
        <Route
          exact
          path={`/pass${siteCode ? '/:siteCode' : ''}`}
          component={DriversCheckinSearch}
        />
        <Route exact path={`/pass/:handle/checkin`} component={DriversCheckinForm} />
        <Route
          exact
          path={`/pass/:handle/postcheckin`}
          render={props => <DriversCheckinForm {...props} postCheckin={true} />}
        />
        <Route
          exact
          path={`/pass/:handle/details`}
          render={props => <DriversCheckinForm {...props} details={true} />}
        />
      </Switch>
    </DriversCheckinProvider>
  )
}

export default DriversCheckinRoutes
