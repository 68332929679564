import React from 'react'

const RecurringAppIcon = () => (
  <img
    src={'https://storage.googleapis.com/terusama-beta/sync_FILL0_wght400_GRAD0_opsz48%201-2.svg'}
    width="15px"
    height="15px"
  />
)

export default RecurringAppIcon
