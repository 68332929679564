import React, { useState, useEffect } from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import AppointmentSearch from "../appointments/appointment-search";
import RingVideoWrapper from "../shared/ring-video-wrapper";
import authenticatedFetch from "../utils/authenticated-fetch";
import { fancyToast } from "../utils";
import StyledSpinner from "../shared/styled-spinner";

const FacilityCamera = ({ match }) => {
  const [css, theme] = useStyletron();
  const [facility, setFacility] = useState<any>(null);

  useEffect(() => {
    authenticatedFetch({
      path: `/facilities/${match.params.handle}.json`,
    }).then(([json, status]) => {
      if (status === 200) {
        setFacility(json);
      } else {
        fancyToast(json, status);
      }
    });
  }, []);

  useEffect(() => {
    facility &&
      !facility.cameraIdentifier &&
      fancyToast({
        info: `No cameras are currently hooked up at ${facility.name}.`,
      });
  }, [facility]);

  if (!facility || !facility.cameraIdentifier) {
    return <StyledSpinner />;
  }

  return (
    <>
      <AppointmentSearch />
      <div className={css({ right: "1%", bottom: "10%", position: "fixed" })}>
        <RingVideoWrapper cameraIdentifier={facility.cameraIdentifier} />
      </div>
    </>
  );
};

export default FacilityCamera;
