import React, { useContext, useMemo, useState } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import { styled } from 'baseui'

import { useTranslation } from 'react-i18next'
import { Block } from 'baseui/block'
import StyledSpinner from 'components/shared/styled-spinner'
import useSearchClient from 'components/shared/hooks/use-search-client'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import Header from 'components/ui/generic/Header'
import SchedulerInstantSearchInput from './SchedulerInstantSearchInput'

import ConnectedHits from './connected-hits'
import ConnectedPagination from './connected-pagination'

const INDEX_NAME = `scheduler_${process.env.RAILS_ENV}`

export const SchedulerInstantSearch = () => {
  const { t } = useTranslation()
  const client = useSearchClient({ queryBy: 'name' })
  const { currentUser } = useContext(CurrentUserContext)
  const [selectedScheduler, setSelectedScheduler] = useState<any>(undefined)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const facetFilters = useMemo(
    () => [`shipper_id:${currentUser.shipperId}`],
    [currentUser?.shipperId]
  )

  if (!client) {
    return <StyledSpinner />
  }

  return (
    <InstantSearch indexName={INDEX_NAME} searchClient={client}>
      <Header
        title={t('Settings.SideMenu.Titles.VendorCompanies.Text')}
        rightItems={[
          <SchedulerInstantSearchInput
            vendor={selectedScheduler}
            setSelectedScheduler={setSelectedScheduler}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        ]}
      />

      <Configure facetFilters={facetFilters} />

      <Block minHeight="60vh">
        <ConnectedHits setSelectedScheduler={setSelectedScheduler} setIsOpen={setIsOpen} />
      </Block>
      <ConnectedPagination />
    </InstantSearch>
  )
}
