import React from 'react'

import { useStyletron } from 'baseui'
import Pagination from 'components/ui/generic/Pagination'

type Props = {
  page: number
  onPageChange: (page: number) => void
  total: number
}

const TablePagination = ({ page, total, onPageChange }: Props) => {
  const [css, theme] = useStyletron()

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.sizing.scale800
      })}>
      <Pagination
        numPages={total}
        currentPage={page}
        onPageChange={({ nextPage }) => {
          onPageChange(nextPage)
        }}
      />
    </div>
  )
}

export default TablePagination
