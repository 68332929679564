import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Block, BlockProps } from 'baseui/block'
import { LabelLarge, ParagraphXSmall } from 'baseui/typography'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import ButtonGroup from '../../components/ButtonGroup'
import Divider from 'components/ui/generic/Divider'
import VButton from 'components/ui/generic/Button'
import Checkbox from 'components/ui/generic/Checkbox'
import { Check, X } from '@phosphor-icons/react'
import SchedulerEmail from 'components/facilities/steps/fields/scheduler-email'
import FacilitySelect from '../../facilities/steps/fields/facility-select'
import AppointmentForm from './AppointmentForm'
import { DateTime } from './DateTime'
import {
  INBOUND,
  IS_DROP,
  OUTBOUND,
  useAppointmentContext
} from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import { INBOUND_TYPE, OUTBOUND_TYPE } from 'components/constants/appointment_types'
import ErrorMessageButton from 'components/shared/error-message-button'
import TermsOfServiceModal from './TermsOfServiceModal/TermsOfServiceModal'
import { useCarriersContext } from 'components/contexts/carriers.context'
import { handlingMethodButtons } from 'components/shared/buttons/liveDropButtons'
import { appointmentTypesButtons } from 'components/shared/buttons/appointmentTypeButtons'

const itemProps: BlockProps = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
}

const AppointmentReusableForm: React.FC = () => {
  const { state, actions } = useAppointmentContext()
  const {
    actions: { setIsVendorSelectDisabled }
  } = useCarriersContext()

  // AppointmentContext state
  const {
    loading,
    handlingMethod,
    appointmentDirections,
    schedulerEmail,
    createAppointmentButtonDisableFlags,
    termsOfService
  } = state

  // AppointmentContext actions
  const {
    setHandlingMethod,
    setAppointmentDirection,
    setSchedulerEmail,
    createAppointment,
    resetAppointmentStates,
    setShowTermsOfServiceModal,
    shouldDisableCancelButton
  } = actions

  const { isCreateAppointmentButtonDisabled, errors } = createAppointmentButtonDisableFlags

  const { state: facilityState } = useFacilityContext()
  const { facility } = facilityState

  const { t } = useTranslation()

  const is_inbound = appointmentDirections.includes(INBOUND)
  const is_outbound = appointmentDirections.includes(OUTBOUND)
  const hasHandlingMethod = handlingMethod !== ''

  const returnErrors = () => errors.map(error => ({ label: error.label, status: !error.status }))

  useEffect(() => {
    setIsVendorSelectDisabled?.(true)
  }, [])

  return (
    <>
      <Block padding={['scale400', 'scale500', 'scale600']}>
        <HeaderLabel />
        <FlexGrid
          flexGridColumnCount={[1, 1, 2]}
          flexGridColumnGap="scale800"
          flexGridRowGap="0"
          overrides={{
            Block: {
              style: {
                width: '100%',
                display: 'flex',
                itemAlign: 'center'
              }
            }
          }}>
          {/* SCHEDULER EMAIL */}
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              <SchedulerEmail
                schedulerEmail={schedulerEmail}
                setSchedulerEmail={setSchedulerEmail}
              />
            </Block>
          </FlexGridItem>

          {/* FACILITY SELECT */}
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              <FacilitySelect />
            </Block>
          </FlexGridItem>

          {/* HANDLING METHOD */}
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              <ButtonGroup
                mode="radio"
                label={t('Appointments.CreateAppointmentModal.Fields.HandlingMethod.Label.Text')}
                buttons={handlingMethodButtons(facility)}
                value={handlingMethod}
                onChange={setHandlingMethod}
              />
            </Block>
          </FlexGridItem>

          {/* APPOINTMENT CATEGORY */}
          <FlexGridItem
            {...itemProps}
            overrides={{
              Block: { style: { display: handlingMethod === IS_DROP ? 'block' : 'none' } }
            }}>
            <Block minWidth="100%">
              <ButtonGroup
                mode="checkbox"
                label={t(
                  'Appointments.CreateAppointmentModal.Fields.AppointmentCategory.Label.Text'
                )}
                buttons={appointmentTypesButtons}
                value={appointmentDirections}
                onChange={setAppointmentDirection}
              />
            </Block>
          </FlexGridItem>
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              {hasHandlingMethod && (
                <>
                  {!is_inbound && is_outbound ? (
                    <CustomQuestionsProvider>
                      <Divider />
                      <AppointmentForm appointmentDirection={OUTBOUND_TYPE} facility={facility} />
                    </CustomQuestionsProvider>
                  ) : (
                    <CustomQuestionsProvider>
                      <Divider />
                      <AppointmentForm appointmentDirection={INBOUND_TYPE} facility={facility} />
                    </CustomQuestionsProvider>
                  )}
                </>
              )}
            </Block>
          </FlexGridItem>
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              {is_inbound && is_outbound && handlingMethod === IS_DROP && (
                <CustomQuestionsProvider>
                  <Divider />
                  <AppointmentForm appointmentDirection={OUTBOUND_TYPE} facility={facility} />
                </CustomQuestionsProvider>
              )}
            </Block>
          </FlexGridItem>
        </FlexGrid>

        <DateTime />

        {facility.note && (
          <Block minWidth="100%" justifyContent={'flex-end'} display={'flex'}>
            <Checkbox
              checked={termsOfService.isTermsOfServiceChecked}
              onMouseUp={e => setShowTermsOfServiceModal(true)}
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'Scheduler.OpenScheduling.Steps.TermsOfService.TermsOfServiceCheckboxLabel.Text'
                    )
                  }}
                />
              }
            />
          </Block>
        )}
        <Divider width="100%" />
        <Block
          width={'100%'}
          justifyContent={'flex-end'}
          display={'flex'}
          flexDirection={['column-reverse', 'column-reverse', 'row']}
          gridGap={'16px'}>
          <VButton
            startEnhancer={<X size={16} />}
            disabled={shouldDisableCancelButton()}
            onClick={resetAppointmentStates}>
            Cancel
          </VButton>
          <ErrorMessageButton
            label="Create appointment request"
            errors={returnErrors()}
            disabled={
              isCreateAppointmentButtonDisabled ||
              (facility.note && !termsOfService.isTermsOfServiceChecked)
            }
            buttonProps={{
              onClick: createAppointment,
              type: 'submit',
              isLoading: loading,
              startEnhancer: <Check size={16} />
            }}
          />
        </Block>
      </Block>
      <TermsOfServiceModal termsOfServiceText={facility.note} />
    </>
  )
}

export const Gap = () => <Block marginRight="scale300" />
export const RowGap = () => <Block marginBottom="scale800" />

const HeaderLabel = () => (
  <>
    <LabelLarge
      overrides={{
        Block: {
          style: ({ $theme }) => ({
            color: $theme.colors.primary,
            fontSize: '14px',
            [$theme.mediaQuery.medium]: {
              fontSize: '16px'
            }
          })
        }
      }}>
      To create an appointment, complete the following information.
    </LabelLarge>
    <ParagraphXSmall
      overrides={{
        Block: {
          style: ({ $theme }) => ({
            fontSize: '10px',
            color: $theme.colors.primary
          })
        }
      }}>
      * REQUIRED INFORMATION
    </ParagraphXSmall>
  </>
)

export default AppointmentReusableForm
