import { useState, useEffect } from "react";

export const useLocalStorage = function <T>(
  key: string,
  initialValue?: T
): [T, Function] {
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  useEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        setStoredValue(JSON.parse(item));
      }
    } catch (error) {
      console.log(error);
      setStoredValue(initialValue);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(storedValue));
  }, [storedValue]);

  return [storedValue, setStoredValue];
};

const DAILY_STORAGE_KEY = "dailyStorageKey";
const todayKey = () => new Date().toDateString();

export const useDailyStorage = (key: string, initialValue?: any) => {
  const [dailyValue, setDailyValue] = useLocalStorage(DAILY_STORAGE_KEY, {
    [todayKey()]: { [key]: initialValue },
  });

  const storeTodayValue = (value: any) => {
    const todayValues = dailyValue[todayKey()];
    const normalizeTodayValues = todayValues || {};
    const newDailyValue = {
      [todayKey()]: { ...normalizeTodayValues, [key]: value },
    };
    window.localStorage.setItem(
      DAILY_STORAGE_KEY,
      JSON.stringify(newDailyValue)
    );
  };

  const getTodayValue = () => {
    const todayValues = dailyValue[todayKey()];
    if (todayValues && todayValues[key]) {
      return todayValues[key];
    }
    storeTodayValue(initialValue);
    return initialValue;
  };

  const todayValue = getTodayValue();

  return [todayValue, setDailyValue, storeTodayValue];
};
