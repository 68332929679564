import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// context imports
import { CurrentUserContext } from 'components/homepage/current-user-context'

// component imports
import UniqueIdentifiers from './fields/UniqueIndentifiersInput'
import AppointmentTypeSelect from './fields/AppointmentTypeSelect'
import EquipmentTypeWithCapacity from 'components/components/EquipmentType/EquipmentTypeWithCapacity'
import CarrierSelect from 'components/facilities/steps/fields/carrier-select'
import SchedulerSelect from './fields/SchedulerSelect'
import { RowGap } from './CreateAppointmentReusableForm'
import TrailerStatusSection from './TrailerStatusSection'

// Types
import { CARRIER, SCHEDULER } from 'components/models/User'

import { IS_DROP, useAppointmentContext } from 'components/contexts/appointment-context'
import AdditionalInformation from 'components/facilities/book/additional-information'
import { useCarriersContext } from 'components/contexts/carriers.context'

const DROP = IS_DROP

const AppointmentForm = ({ appointmentDirection: title, facility, ...props }) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(CurrentUserContext)
  const userProfileType = currentUser?.userType
  const carrier = userProfileType === CARRIER
  const scheduler = userProfileType === SCHEDULER
  const appointmentDirection = title.toLowerCase()

  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search)

  const {
    state: { appointments, handlingMethod, appointmentId },
    actions: { setAppointment },
    key
  } = useAppointmentContext()

  const appointmentKey = `${key}-${title}`
  const appointment = appointments[appointmentDirection]

  // EXTRACTION AND ASSIGNMENT OF CUSTOM LABELS FOR CUSTOMER AND UNIQUE IDENTIFIERS
  const appointmentTypeIdSelected = appointments[appointmentDirection].appointmentTypeId
  const appointmentTypeSelected = facility.appointmentTypes?.find(
    appointmentType => appointmentType.id === appointmentTypeIdSelected
  )
  const appointmentTypeUniqueIdentifierCustomLabel =
    appointmentTypeSelected?.customLabels?.purchaseOrderIdentifiers || ''
  const appointmentTypeCustomCustomerLabel = appointmentTypeSelected?.customLabels?.scheduler || ''

  const handleChange = (key, value) => {
    setAppointment({ [key]: value }, appointmentDirection)
  }

  const handleCarrierChange = value => {
    setAppointment({ carrierId: value.carrierId || value.id }, appointmentDirection)
  }

  if (appointment.facilityId) {
    return (
      <>
        {scheduler && (
          <CarrierSelect
            schedulerId={currentUser.schedulerId}
            appointment={appointment}
            setAppointment={handleCarrierChange}
            createdAsOther={true}
          />
        )}

        {/** APPOINTMENT TYPE */}
        <AppointmentTypeSelect
          disabled={appointmentId ? true : false}
          appointmentDirection={title}
          onChange={value => {
            handleChange('appointmentTypeId', value?.option.id)
          }}
          appointmentTypes={facility.appointmentTypes}
        />

        {carrier && (
          <>
            {/** THIS IS THE VENDOR/CUSTOMER, PREVIOUS DEV NAMED IT -SCHEDULERSELECT-  */}
            <SchedulerSelect
              appointment={appointment}
              setAppointment={value => handleChange('schedulerId', value)}
              key={appointmentKey}
              label={appointmentTypeCustomCustomerLabel}
            />
          </>
        )}
        {/** EQUIPMENT TYPE */}
        {appointment?.appointmentTypeId &&
          facility?.appointmentPreference &&
          facility?.appointmentPreference?.dockCapacitiesAttributes?.length > 0 && (
            <EquipmentTypeWithCapacity
              record={appointment}
              setRecord={value => handleChange('equipmentTypeId', value.equipmentTypeId)}
              dockCapacitiesAttributes={facility?.appointmentPreference?.dockCapacitiesAttributes}
              equipmentCapacityShared={facility?.appointmentPreference?.equipmentCapacityShared}
              label={t(
                'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Fields.EquipmentType.Text'
              )}
              recordAttributeName="equipmentTypeId"
              openScheduling={props?.openscheduling}
            />
          )}
        {/** TRAILER, STATUS, STATEs */}
        {handlingMethod === DROP && (
          <TrailerStatusSection
            title={title}
            appointment={appointment}
            setAppointment={handleChange}
            appointmentDirection={appointmentDirection}
            appointmentKey={appointmentKey}
          />
        )}

        {/** PURCHASE ORDER UNIQUE IDENTIFIERS */}
        <UniqueIdentifiers
          onChange={value => handleChange('purchaseOrdersAttributes', value)}
          key={appointmentKey}
          label={appointmentTypeUniqueIdentifierCustomLabel}
          currentUser={currentUser}
          appointment={appointment}
          direction={title}
        />
        {appointmentTypeSelected && (
          <AdditionalInformation
            appointment={appointment}
            setAppointment={value => handleChange('answersAttributes', value)}
            userType={(currentUser && currentUser.userType) || 'broker'}
            appointmentType={appointmentTypeSelected}
          />
        )}
      </>
    )
  }

  return null
}

export default AppointmentForm
