import { useStyletron } from 'baseui'

import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import { CurrentUserContext } from 'components/homepage/current-user-context'

import { docksService } from 'components/services/docks.service'
import { fancyToast } from 'components/utils'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import CyberInput from 'cyber/input'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DockModalAppointmentTypeCheckboxes } from './DockModalAppointmentTypeCheckboxes'
import { DockModalCommodityTypes } from './DockModalCommodityTypes'
import { DockModalEquipmentTypeCheckboxes } from './DockModalEquipmentTypeCheckboxes'
import { useTranslation } from 'react-i18next'

import FormControl from 'components/ui/generic/FormControl'
import Button from 'components/ui/generic/Button'
import Checkbox from 'components/ui/generic/Checkbox'
import { KIND, SIZE } from 'baseui/button'

type Props = {
  open?: boolean
  facilityId?: boolean
  selectedDock: any
  onModalClose: any
  appointmentTypes: any
}

export const DockModal = ({
  open,
  facilityId = null,
  selectedDock = null,
  onModalClose,
  appointmentTypes
}: Props) => {
  const { currentUser, assignedEquipmentTypes } = useContext(CurrentUserContext)
  const isAdmin = useMemo(() => !!currentUser?.admin, [currentUser?.admin])
  const [css] = useStyletron()
  const [loading, setLoading] = useState<boolean>(false)
  const [dock, setDock] = useState(
    selectedDock || {
      enabled: true
    }
  )
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedDock) {
      docksService.getDockById(selectedDock.id).then(dock => setDock(dock))
    }
  }, [selectedDock])

  const onClose = () => {
    setDock(null)
    onModalClose()
  }

  const handleSaveModal = async () => {
    setLoading(true)
    if (selectedDock) {
      const [json, status] = await authenticatedFetch({
        path: `/docks/${selectedDock.id}.json`,
        method: 'PATCH',
        body: { dock }
      })
      if (status === 200) {
        fancyToast(
          {
            info: t('Common.Info.Interpolated.Text', {
              model: t('Common.ModelType.Dock.Text'),
              action: t('Common.Actions.Saved.Text')
            })
          },
          status
        )
        onClose()
      } else {
        fancyToast(json, status)
      }
    } else {
      const [data, status] = await docksService.createDock({
        ...dock,
        facilityId
      })
      if (status === 201) {
        fancyToast(
          {
            info: t('Common.Info.Interpolated.Text', {
              model: t('Common.ModelType.Dock.Text'),
              action: t('Common.Actions.Created.Text')
            })
          },
          status
        )
        onClose()
      } else {
        fancyToast(data, status)
      }
    }
    setLoading(false)
  }

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader>
        <div
          data-testid={'dock-modal-header'}
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          })}>
          {t('Docks.Modal.Header.Text')}
          <Checkbox
            autoFocus={false}
            checkmarkType="toggle"
            checked={dock?.enabled}
            disabled={!isAdmin}
            onChange={evt =>
              setDock({
                ...dock,
                enabled: evt.currentTarget.checked
              })
            }
          />
        </div>
      </ModalHeader>

      <ModalBody>
        <FormControl label={t('Docks.Modal.Fields.Name.Label.Text')}>
          <CyberInput
            name="dock[name]"
            value={dock?.name}
            disabled={loading || !dock?.enabled || !isAdmin}
            onChange={evt =>
              setDock({
                ...dock,
                name: evt.currentTarget.value
              })
            }
          />
        </FormControl>
        <FormControl label={t('Docks.Modal.Fields.EquipmentTypes.Label.Text')}>
          <DockModalEquipmentTypeCheckboxes
            equipmentTypes={assignedEquipmentTypes}
            loading={loading}
            disabled={!isAdmin}
            dock={dock}
            onChange={dockEquipmentTypesAttributes => {
              setDock(dock => ({
                ...dock,
                dockEquipmentTypesAttributes
              }))
            }}
          />
        </FormControl>
        <FormControl label={t('Docks.Modal.Fields.AppointmentTypes.Label.Text')}>
          <DockModalAppointmentTypeCheckboxes
            appointmentTypes={appointmentTypes}
            loading={loading}
            disabled={!isAdmin}
            dock={dock}
            onAppointmentChange={appointmentTypes =>
              setDock(dock => ({
                ...dock,
                appointmentTypeIds: appointmentTypes
              }))
            }
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={KIND.secondary} onClick={onClose} size={SIZE.compact}>
          {t('Orders.CreateEditOrderModal.Buttons.Cancel.Text')}
        </ModalButton>
        {isAdmin && (
          <Button isLoading={loading} onClick={handleSaveModal} kind="primary">
            {selectedDock ? t('Docks.Modal.UpdateButton.Text') : t('Docks.Modal.SaveButton.Text')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}
