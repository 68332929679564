import React, { useState, useEffect } from 'react'
import { HeadingLevel, Heading } from 'baseui/heading'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import DockCard from '../dock-card'
import { useParams } from 'react-router-dom'
import { facilityService } from 'components/services/facility.service'

export const FacilityDocks = () => {
  const params = useParams<{ handle: string }>()
  const [facility, setFacility] = useState<any>({})
  const [docks, setDocks] = useState<any>([])

  useEffect(() => {
    facilityService.getFacility(params.handle).then(facility => setFacility(facility))
  }, [])

  useEffect(() => {
    facilityService.getFacilityDocks(params.handle).then(docks => setDocks(docks))
  }, [])

  return (
    <HeadingLevel>
      <Heading>{facility.name} Docks</Heading>
      <FlexGrid flexGridRowGap="scale400">
        {docks.map((dock, index) => {
          return (
            <FlexGridItem key={index}>
              <DockCard initialDock={dock} />
            </FlexGridItem>
          )
        })}
      </FlexGrid>
    </HeadingLevel>
  )
}
