import * as React from 'react'
import { Card as CardBase, StyledBody, StyledAction } from 'baseui/card'

type Props = {
  children: React.ReactNode
  actions?: React.ReactNode
}

const Card: React.FC<Props> = ({ children, actions = null }: Props) => {
  return (
    <CardBase
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.white
          })
        }
      }}>
      <StyledBody>{children}</StyledBody>
      <StyledAction>{actions}</StyledAction>
    </CardBase>
  )
}

export default Card
