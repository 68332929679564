import React, { useContext, useState } from 'react'
import { Card } from 'baseui/card'
import { useStyletron } from 'baseui'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { RadioGroup, Radio } from 'baseui/radio'
import { Button } from 'baseui/button'
import StyledSpinner from '../shared/styled-spinner'
import authenticatedFetch from '../utils/authenticated-fetch'
import { fancyToast } from '../utils'
import { DOCK_TYPES } from 'components/docks/types'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { capitalize } from 'lodash'
import { DockModalEquipmentTypeCheckboxes } from '../docks/DockModal/DockModalEquipmentTypeCheckboxes'

const DockCard = props => {
  const { initialDock } = props
  const [dock, setDock] = useState<any>({ ...initialDock })
  const [loading, setLoading] = useState<boolean>(false)
  const { assignedEquipmentTypes } = useContext(CurrentUserContext)

  if (!dock) {
    return <StyledSpinner />
  }

  const [css] = useStyletron()

  const updateDock = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/docks/${dock.id}.json`,
      body: { dock },
      method: 'PUT'
    })
    setLoading(false)
    fancyToast(json, status)
  }

  return (
    <Card>
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800">
        <FlexGridItem>
          <FormControl label="Name">
            <Input
              id="dock[name]"
              name="dock[name]"
              value={dock.name}
              onChange={e => {
                setDock({
                  ...dock,
                  name: e.currentTarget.value
                })
              }}
              disabled={loading}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl label="Equipment Type">
            <DockModalEquipmentTypeCheckboxes
              equipmentTypes={assignedEquipmentTypes}
              loading={loading}
              disabled={false}
              dock={dock}
              onChange={dockEquipmentTypesAttributes => {
                setDock(dock => ({
                  ...dock,
                  dockEquipmentTypesAttributes
                }))
              }}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem alignSelf="center">
          <Button isLoading={loading} onClick={updateDock}>
            Update
          </Button>
        </FlexGridItem>
      </FlexGrid>
    </Card>
  )
}

export { DOCK_TYPES }
export default DockCard
