import React, { useState, useContext } from 'react'
import { ProgressSteps } from 'baseui/progress-steps'
import { LabelSmall, ParagraphSmall } from 'baseui/typography'
import { ProgressBar, SIZE } from 'baseui/progress-bar'
import { useStyletron } from 'baseui'
import { Redirect } from 'react-router-dom'
import { StyledLink } from 'baseui/link'
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid'
import { Block } from 'baseui/block'
import { useTranslation } from 'react-i18next'
import { CheckCircle } from '@phosphor-icons/react'
import { DEMO_URL } from 'components/shared/velostics_demo'
import Header from 'components/ui/generic/Header'
import Input from 'components/ui/generic/Input'
import Button from 'components/ui/generic/Button'
import Step from 'components/ui/generic/Step'
import FormControl from 'components/ui/generic/FormControl'
import authenticatedFetch from '../../utils/authenticated-fetch'
import StyledSpinner from '../../shared/styled-spinner'
import { BROKER, CARRIER } from '../../models/User'
import { CurrentUserContext } from '../../homepage/current-user-context'
import TwilioPhoneNumber from '../../shared/fields/twilio-phone-number'

const ActivationText = ({ userType, isActivated }) => {
  const [_, theme] = useStyletron()
  const { t } = useTranslation()
  switch (userType) {
    case 'shipper':
      return isActivated ? (
        <>
          <CheckCircle size={40} color={theme.colors.accent} />
          <ParagraphSmall>{t('Common.Fields.Activation.Text')}</ParagraphSmall>
        </>
      ) : (
        <>
          <CheckCircle size={40} color={theme.colors.accent} />
          <ParagraphSmall>
            <StyledLink href={DEMO_URL}>{t('Common.Fields.DemoLink.Text')}</StyledLink>{' '}
            {t('Common.Fields.DemoParagraph.Text')}
          </ParagraphSmall>
        </>
      )

    case 'broker':
    case 'carrier':
    case 'scheduler':
      return (
        <>
          <CheckCircle size={40} color={theme.colors.accent} />
          <ParagraphSmall>{t('Common.Fields.ReadyToSchedule.Text')}</ParagraphSmall>
        </>
      )

    default:
      return (
        <>
          <CheckCircle size={40} color={theme.colors.accent} />
          <ParagraphSmall>
            <StyledLink href={DEMO_URL}>{t('Common.Fields.DemoLink.Text')}</StyledLink>{' '}
            {t('Common.Fields.DemoParagraph.Text')}
          </ParagraphSmall>
        </>
      )
  }
}

const HeaderProgressBar = ({ ...props }) => {
  const [css] = useStyletron()
  return (
    <div className={css({ minWidth: '240px', maxWidth: '100%' })}>
      <ProgressBar size={SIZE.small} {...props} />
    </div>
  )
}

const NonDriverOnboarding = () => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const [user, setUser] = useState<any>(currentUser)
  const [redirectPath, setRedirectPath] = useState<string>()
  const redirectFacilityId = localStorage.getItem('facilityId')
  const { t } = useTranslation()

  const initializeCurrentPage = () => {
    if (currentUser.userType && currentUser.name && currentUser.phoneNumber) {
      return 2
    }
    if (currentUser.userType) {
      return 1
    }
    return 0
  }
  const [currentPage, setCurrentPage] = useState<number>(initializeCurrentPage())

  if (!currentUser) {
    return <StyledSpinner />
  }
  const createdWithEmailAddress: boolean = currentUser && currentUser.emailAddress && true

  const pageProgress = [10, 35, 100]

  const setUserType = async userType => {
    setCurrentPage(1)
    setUser({ ...user, userType })
  }

  const updateUser = async () => {
    const [json, status] = await authenticatedFetch({
      path: `/users/${currentUser.id}.json`,
      method: 'PATCH',
      body: {
        user: { ...currentUser, ...user }
      }
    })

    if (status === 200) {
      setTimeout(() => {
        setCurrentUser(user)
        setRedirectPath('/')
      }, 4000)
    }
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} />
  }

  return (
    <>
      <Header
        title={t('Common.Fields.Welcome.Text')}
        rightItems={[<HeaderProgressBar value={pageProgress[currentPage]} steps={3} />]}
      />

      <ProgressSteps
        current={currentPage}
        overrides={{
          Root: {
            style: () => ({
              width: '100%',
              maxWidth: '460px'
            })
          }
        }}>
        <Step title={t('Common.Fields.UserRoleHeader.Text')}>
          <LabelSmall marginBottom="scale400">{t('Common.Fields.UserRole.Text')}</LabelSmall>
          <Block display="flex" flexDirection="row" gridColumnGap="8px">
            <Button onClick={() => setUserType(CARRIER)}>
              {t('Common.Fields.UserCarrier.Text')}
            </Button>
            <Button onClick={() => setUserType(BROKER)}>
              {t('Common.Fields.UserBroker.Text')}
            </Button>
          </Block>
        </Step>
        <Step title={t('Common.Fields.AccountDetailsHeader.Text')}>
          <FlexGrid flexGridRowGap="scale200">
            {user.userType === 'carrier' && (
              <FlexGridItem>
                <FormControl label={t('Common.Fields.CarrierVendorName.Text')}>
                  <Input
                    autoComplete="off"
                    name="user[carrier_name]"
                    required
                    value={user.carrierName || ''}
                    onChange={e => {
                      setUser({ ...user, carrierName: e.currentTarget.value })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
            )}
            {['broker'].includes(user.userType) ? (
              <FlexGridItem>
                <FormControl label={t('Common.Fields.BrokerName.Text')}>
                  <Input
                    autoComplete="off"
                    name="user[broker_name]"
                    required
                    value={user.brokerName || ''}
                    onChange={e => {
                      setUser({ ...user, brokerName: e.currentTarget.value })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
            ) : null}
            <FlexGridItem>
              <FormControl label={t('Common.Fields.YourName.Text')}>
                <Input
                  autoComplete="off"
                  name="user[name]"
                  required
                  value={user.name}
                  onChange={e => {
                    setUser({ ...user, name: e.currentTarget.value })
                  }}
                />
              </FormControl>
            </FlexGridItem>

            {createdWithEmailAddress ? (
              <FlexGridItem>
                <FormControl label={t('Common.Fields.MobilePhoneNumber.Text')}>
                  <TwilioPhoneNumber record={user} setRecord={setUser} />
                </FormControl>
              </FlexGridItem>
            ) : (
              <FlexGridItem>
                <FormControl label={t('Common.Fields.EmailAddress.Text')}>
                  <Input
                    autoComplete="off"
                    name="user[email_address]"
                    value={user.emailAddress}
                    onChange={e => {
                      setUser({
                        ...user,
                        emailAddress: e.currentTarget.value
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
            )}

            <FlexGridItem>
              <Button
                disabled={
                  !user.name ||
                  !user.emailAddress ||
                  !user.phone_number ||
                  (user.phone_number && user.phone_number.length < 12)
                }
                onClick={updateUser}>
                {t('Common.Fields.NextButton.Text')}
              </Button>
            </FlexGridItem>
          </FlexGrid>
        </Step>
        <Step title={t('Common.Fields.ActivationHeader.Text')}>
          <ActivationText userType={user.userType} isActivated={!!user.shipperId} />
        </Step>
      </ProgressSteps>
    </>
  )
}

export default NonDriverOnboarding
