import { useStyletron } from 'baseui'
import { Tag } from 'baseui/tag'
import { ParagraphSmall } from 'baseui/typography'
import React, { Dispatch, Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { connectRefinementList, connectToggleRefinement } from 'react-instantsearch-dom'
import { upperFirst, camelCase } from 'lodash'
import { CurrentUserContext } from 'components/homepage/current-user-context'

const NonConnectedRefinementFilter = props => {
  const { t } = useTranslation()
  const { shipperEquipmentTypes } = useContext(CurrentUserContext)
  const equipmentTypesMap = new Map(
    shipperEquipmentTypes?.map(item => {
      return [item.id, item.name]
    })
  )

  return (
    <>
      {props.items
        .filter(item => item.isRefined)
        .map(item => (
          <Tag
            overrides={{
              Text: {
                style: ({ $theme }) => ({
                  fontSize: $theme.typography.LabelXSmall.fontSize,
                  maxWidth: 'none'
                })
              },
              Root: {
                style: ({ $theme }) => ({
                  borderTopWidth: $theme.borders.borderWidth,
                  borderBottomWidth: $theme.borders.borderWidth,
                  borderLeftWidth: $theme.borders.borderWidth,
                  borderRightWidth: $theme.borders.borderWidth,
                  marginLeft: 0,
                  marginTop: 0
                })
              }
            }}
            onActionClick={() => props.refine(item.value)}>
            {`${t(`Common.Dropdown.Titles.${upperFirst(camelCase(props.attribute))}.Text`)}:`}
            <strong>
              {' '}
              {props.attribute === 'equipment_type_id'
                ? equipmentTypesMap.get(item.label)
                : item.label}
            </strong>
          </Tag>
        ))}
    </>
  )
}

const ConnectedRefinementFilter = connectRefinementList(NonConnectedRefinementFilter)

const NonConnectedRefinementToggle = props => {
  const { t } = useTranslation()

  return (
    <>
      <Tag
        overrides={{
          Text: {
            style: ({ $theme }) => ({
              fontSize: $theme.typography.LabelXSmall.fontSize,
              maxWidth: 'none'
            })
          },
          Root: {
            style: ({ $theme }) => ({
              borderTopWidth: $theme.borders.borderWidth,
              borderBottomWidth: $theme.borders.borderWidth,
              borderLeftWidth: $theme.borders.borderWidth,
              borderRightWidth: $theme.borders.borderWidth,
              marginLeft: 0,
              marginTop: 0
            })
          }
        }}
        onActionClick={() => props.refine(!props.currentRefinement)}>
        {`${t(`Common.Dropdown.Titles.Toggles.Text`)}: `}
        <strong>{props.attribute}</strong>
      </Tag>
    </>
  )
}

const ConnectedRefinementToggle = connectToggleRefinement(NonConnectedRefinementToggle)

const FilterSummary = ({
  label,
  numberOfHits,
  appliedFilters,
  setDateRange
}: {
  label: string
  numberOfHits: number
  appliedFilters: {
    dateRange?: {
      from?: string
      to?: string
    }
    checkin_status?: string[]
    created_by_name?: string[]
    scheduler_name?: string[]
  }
  setDateRange?: Dispatch<any>
}): JSX.Element => {
  const [css, theme] = useStyletron()

  return (
    <div
      className={css({
        marginBottom: theme.sizing.scale600
      })}>
      <ParagraphSmall display="inline" width="auto" marginRight="scale300">
        Showing{' '}
        <b>
          {numberOfHits} {label}
          {numberOfHits !== 1 && 's'}
        </b>{' '}
      </ParagraphSmall>
      {Object.entries(appliedFilters).map(([k, v]) => {
        if (k !== 'dateRange' && Array.isArray(v)) {
          return <ConnectedRefinementFilter attribute={k} key={k} />
        }

        if (k !== 'dateRange' && !Array.isArray(v)) {
          return <ConnectedRefinementToggle attribute={k} value key={k} />
        }

        return (
          <Fragment key="dateRange">
            {appliedFilters?.dateRange?.from && (
              <Tag
                overrides={{
                  Text: {
                    style: ({ $theme }) => ({
                      fontSize: $theme.typography.LabelXSmall.fontSize,
                      maxWidth: 'none'
                    })
                  },
                  Root: {
                    style: ({ $theme }) => ({
                      borderTopWidth: $theme.borders.borderWidth,
                      borderBottomWidth: $theme.borders.borderWidth,
                      borderLeftWidth: $theme.borders.borderWidth,
                      borderRightWidth: $theme.borders.borderWidth,
                      marginLeft: 0,
                      marginTop: 0
                    })
                  }
                }}
                onActionClick={() => {
                  setDateRange(c => {
                    const updatedState = [...c]
                    updatedState.shift()
                    return updatedState
                  })
                }}>
                from: <strong>{appliedFilters?.dateRange.from}</strong>
              </Tag>
            )}
            {appliedFilters?.dateRange?.to && (
              <Tag
                overrides={{
                  Text: {
                    style: ({ $theme }) => ({ fontSize: $theme.typography.LabelXSmall.fontSize })
                  },
                  Root: {
                    style: ({ $theme }) => ({
                      borderTopWidth: $theme.borders.borderWidth,
                      borderBottomWidth: $theme.borders.borderWidth,
                      borderLeftWidth: $theme.borders.borderWidth,
                      borderRightWidth: $theme.borders.borderWidth,
                      marginLeft: 0,
                      marginTop: 0
                    })
                  }
                }}
                onActionClick={() => {
                  setDateRange(c => {
                    const updatedState = [...c]
                    updatedState.pop()
                    return updatedState
                  })
                }}>
                to: <strong>{appliedFilters?.dateRange.to}</strong>
              </Tag>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default FilterSummary
