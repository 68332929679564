import React, { useEffect } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { LabelSmall } from 'baseui/typography'
import { camelCase, upperFirst } from 'lodash'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import Accordion from 'components/ui/generic/Accordion'
import CheckboxItem from 'components/ui/generic/CheckboxItem'

const NonConnectedRefinementList = props => {
  const [, theme] = useStyletron()

  const { attribute, items, refine, setAppliedFilters } = props
  const refined = items.some(({ isRefined }) => isRefined)
  const { t } = useTranslation()

  useEffect(() => {
    if (refined && setAppliedFilters) {
      setAppliedFilters(c => ({
        ...c,
        [attribute]: items.filter(item => item.isRefined).map(item => item.label)
      }))
    } else if (setAppliedFilters) {
      setAppliedFilters(c => {
        const updatedFilters = { ...c }
        delete updatedFilters[attribute]
        return { ...updatedFilters }
      })
    }
  }, [refined, setAppliedFilters, attribute, items])

  return (
    <Accordion
      disabled={refined}
      title={t(`Common.Dropdown.Titles.${upperFirst(camelCase(attribute))}.Text`)}>
      {items.length > 0 ? (
        items
          .filter(item => item.count > 0 && item.label)
          .sort((first, second) => first.label.localeCompare(second.label))
          .map(item => {
            return <CheckboxItem {...item} refine={refine} key={item.label} />
          })
      ) : (
        <LabelSmall color={theme.colors.contentSecondary}>
          {t('Common.Dropdown.NoOptions.Text')}
        </LabelSmall>
      )}
    </Accordion>
  )
}

const CyberSearchRefinementList = connectRefinementList(NonConnectedRefinementList) as any

export default CyberSearchRefinementList
