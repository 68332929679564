import React from 'react'
import { CurrentUser } from '../users'

export type EquipmentType = {
  id: string
  name: string
  color: string
  icon: string
  defaultDuration: number
  shipperEquipmentType: any[]
}

export const CurrentUserContext = React.createContext<{
  currentUser?: CurrentUser
  setCurrentUser?: any
  shipperEquipmentTypes?: EquipmentType[]
  setShipperEquipmentTypes?: ([EquipmentType]) => void
  assignedEquipmentTypes?: EquipmentType[]
  setAssignedEquipmentTypes?: ([EquipmentType]) => void
}>({
  currentUser: null,
  setCurrentUser: null,
  shipperEquipmentTypes: null,
  setShipperEquipmentTypes: null,
  assignedEquipmentTypes: null,
  setAssignedEquipmentTypes: null
})
