import * as React from 'react'
import { Radio } from 'baseui/radio'

export default function RadioFiller({ checked, onChange, overrides, selectedButtons, ...props }) {
  const radioOverrides = {
    RadioMarkOuter: {
      style: ({ $theme }) => ({
        width: '14px', // Smaller size
        height: '14px',
        backgroundColor: $theme.colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: !checked ? `${$theme.colors.primary} solid 1px` : 'transparent'
      })
    },
    RadioMarkInner: {
      style: ({ $theme }) => ({
        width: '8px', // Larger inner circle
        height: '8px',
        backgroundColor: checked ? $theme.colors.white : 'transparent',
        outline: checked ? `${$theme.colors.primary} solid 2px` : 'transparent'
      })
    }
  }

  return <Radio onChange={onChange} overrides={radioOverrides} {...props} value="valor" />
}
