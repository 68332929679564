import React, { useState } from 'react'
import { DatePicker } from 'baseui/datepicker'
import { SIZE } from 'baseui/input'
import { useStyletron } from 'baseui'

const VDatePicker = ({
  value,
  size,
  formatString,
  label,
  overrides,
  placeholder,
  onChange,
  ...props
}) => {
  const [css, theme] = useStyletron()

  const [focused, setFocused] = useState(false)
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const labelStyles = css({
    position: 'absolute',
    paddingLeft: '20px',
    paddingTop: focused || (value && value instanceof Date) ? '8px' : '18px',
    color: '#04112B',
    fontSize: focused || (value && value instanceof Date) ? '11px' : '14px',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '12px',
    opacity: 0.6,
    zIndex: 1,
    transition: '.3s all ease-in-out',
    boxSizing: 'border-box',
    height: theme.sizing.scale1200,
    width: '100%'
  })

  // Conditional styles for Input and InputContainer if there is a label
  const inputConditionalStyles = label
    ? {
        paddingTop: '12px', // Make space for the floating label
        paddingBottom: '8px', // Maintain vertical rhythm
        paddingLeft: '20px' // Adjust as needed
      }
    : {}

  const inputContainerConditionalStyles = label
    ? {
        paddingTop: '8px', // Maintain vertical rhythm
        paddingLeft: '0px'
      }
    : {}

  const inputRootConditionalStyles = label
    ? {
        borderBottomLeftRadius: '29px',
        borderBottomRightRadius: '29px',
        borderTopLeftRadius: '29px',
        borderTopRightRadius: '29px'
      }
    : {}

  return (
    <div className={css({ position: 'relative', width: '100%' })}>
      {label && (
        <label
          className={labelStyles}
          htmlFor={label ? label.replace(/\s+/g, '-').toLowerCase() : ''}>
          {label}
        </label>
      )}
      <DatePicker
        formatString={formatString || 'MM/dd'}
        size={size || SIZE.compact}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              borderTopLeftRadius: $theme.borders.popoverBorderRadius,
              borderTopRightRadius: $theme.borders.popoverBorderRadius,
              borderBottomLeftRadius: $theme.borders.popoverBorderRadius,
              borderBottomRightRadius: $theme.borders.popoverBorderRadius,
              overflow: 'hidden'
            })
          },
          CalendarContainer: {
            style: () => ({
              boxSizing: 'border-box',
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0
            })
          },
          CalendarHeader: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.light,
              paddingTop: $theme.sizing.scale300,
              paddingLeft: $theme.sizing.scale300,
              paddingRight: $theme.sizing.scale300,
              paddingBottom: $theme.sizing.scale300
            })
          },
          MonthHeader: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.light
            })
          },
          MonthYearSelectButton: {
            style: ({ $theme }) => ({
              color: $theme.colors.text,
              fontSize: $theme.typography.LabelSmall.fontSize,
              ':focus': {
                backgroundColor: $theme.colors.accent,
                outline: 'none'
              }
            })
          },
          WeekdayHeader: {
            style: ({ $theme }) => ({
              fontSize: $theme.typography.LabelSmall.fontSize,
              color: $theme.colors.text,
              height: $theme.sizing.scale1000,
              width: $theme.sizing.scale1000
            })
          },
          PrevButton: {
            style: ({ $theme }) => {
              return {
                color: $theme.colors.text,
                borderRadius: $theme.borders.buttonBorderRadius,
                ':focus': {
                  backgroundColor: $theme.colors.white
                }
              }
            }
          },
          NextButton: {
            style: ({ $theme }) => {
              return {
                color: $theme.colors.text,
                borderRadius: $theme.borders.buttonBorderRadius,
                ':focus': {
                  backgroundColor: $theme.colors.white
                }
              }
            }
          },
          Day: {
            style: ({ $theme, $selected }) => ({
              fontSize: $theme.typography.LabelSmall.fontSize,
              paddingTop: $theme.sizing.scale200,
              paddingBottom: $theme.sizing.scale200,
              paddingRight: $theme.sizing.scale200,
              paddingLeft: $theme.sizing.scale200,
              height: $selected ? $theme.sizing.scale1000 : $theme.sizing.scale1000,
              width: $theme.sizing.scale1000
            })
          },
          Input: {
            props: {
              id: label ? label.replace(/\s+/g, '-').toLowerCase() : '',
              overrides: {
                Root: {
                  style: ({ $theme }) => ({
                    borderTopWidth: $theme.borders.borderWidth,
                    borderBottomWidth: $theme.borders.borderWidth,
                    borderLeftWidth: $theme.borders.borderWidth,
                    borderRightWidth: $theme.borders.borderWidth,
                    minHeight: $theme.sizing.scale1000,
                    ...inputRootConditionalStyles
                  })
                },
                InputContainer: {
                  style: ({ $theme }) => ({
                    position: 'relative',
                    backgroundColor: $theme.colors.transparent,
                    ...inputContainerConditionalStyles
                  })
                },
                Input: {
                  style: {
                    ...inputConditionalStyles
                  }
                }
              }
            }
          },
          ...overrides
        }}
        value={value}
        {...props}
        onOpen={onFocus}
        onClose={onBlur}
        onChange={onChange}
        placeholder={label ? '' : placeholder}
      />
    </div>
  )
}
export default VDatePicker
