import styled from 'styled-components'
import { primitives } from '../../homepage/custom-theme'
import { styled as styledBaseUI } from 'baseui'

export const YardJockeyHeaderContainer = {
  Block: {
    style: ({ $theme }) => ({
      padding: '16px 32px 16px 32px',
      border: '1px 0px 1px 0px',
      backgroundColor: $theme.colors.white,
      borderColor: $theme.colors.gray
    })
  }
}

export const YardJockeyHeaderFlagIcon = styled.div<{ active?: boolean }>`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #b5bed1;
  opacity: 0.6;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? primitives.primary : primitives.white)};
`

export const YardJockeyTabName = (active: boolean) => ({
  Block: {
    style: ({ $theme }) => ({
      color: $theme.colors.primary,
      size: '18px',
      fontWeight: active ? 500 : 400,
      margin: '5px 5px 0 0',
      cursor: 'pointer'
    })
  }
})

export const YardJockeyTabCounterNumber = (active: boolean) => ({
  Block: {
    style: ({ $theme }) => ({
      color: active ? $theme.colors.white : $theme.colors.primary,
      size: '16px',
      weight: 400,
      transform: 'translate(3px, -20px)'
    })
  }
})

export const YardJockeySearchBoxStyles = (collapsable: boolean, isExpanded: boolean) => ({
  Root: {
    style: collapsable && {
      height: '42px',
      width: isExpanded ? '200px' : '42px',
      transition: 'width 0.3s ease-in-out, padding 0.3s ease-in-out',
      paddingLeft: '6px',
      paddingTop: '2px',
      border: '1px solid #b5bed1',
      backgroundColor: primitives.white
    }
  },
  Input: {
    style: ({ $theme }) => ({
      paddingLeft: '0',
      backgroundColor: $theme.colors.white
    })
  },
  StartEnhancer: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.white
    })
  }
})

export const YardJockeyTabCounter = styled.div<{ active?: boolean }>`
  width: 16px;
  height: 16px;
  padding: 8px;
  display: inline-block;
  background-color: ${({ active }) => (active ? primitives.primary : primitives.gray)};
  border-radius: 50%;
`

export const MagnifyingGlassIcon = styled.div`
  cursor: pointer;
  background-color: ${primitives.white};
`

export const PointerDiv = styled.div`
  cursor: pointer;
`

export const YardJockeyTableNotFoundContainer = styledBaseUI('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: $theme.sizing.scale1600
}))

export const YardJockeyTableStatusIcon = (active: boolean) => ({
  Text: {
    style: () => ({
      fontSize: '9px',
      color: active ? primitives.white : primitives.primary,
      padding: 0
    })
  },
  Root: {
    style: () => ({
      padding: '10px',
      backgroundColor: active ? primitives.primary : primitives.gray,
      maxHeight: '9px'
    })
  }
})

export const StrongTableField = styled.strong<{ fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
`

export const NotesTableField = styled.div`
  min-width: 200px;
`

export const CheckMarkIcon = styled.div`
  margin-left: 5px;
  display: inline;
`

export const AcceptAndStartButtonStyles = {
  BaseButton: {
    style: {
      border: `1px solid ${primitives.accent}`,
      backgroundColor: primitives.white,
      color: primitives.accent
    }
  }
}

export const PaginationButtonStyles = {
  BaseButton: {
    style: {
      border: `1px solid ${primitives.accent}`,
      backgroundColor: primitives.light,
      color: primitives.accent,
      fontSize: '18px',
      height: '52px',
      width: '70%',
      borderRadius: '36px',
      marginTop: '20px'
    }
  }
}

export const YardJockeyTableStyles = {
  TableBodyCell: {
    style: {
      borderBottom: `4px solid ${primitives.light}`,
      height: '100px',
      verticalAlign: 'middle',
      boxSizing: 'border-box'
    }
  }
}

export const YardJockeyDrawerStyles = {
  Close: {
    style: () => ({
      display: 'none'
    })
  },
  DrawerBody: {
    style: {
      maxWidth: '459px',
      margin: 0
    }
  },
  DrawerContainer: {
    style: {
      height: '353px',
      width: '459px',
      margin: '0 auto',
      position: 'relative',
      backgroundColor: primitives.white,
      maxWidth: '459px'
    }
  },
  Root: {
    style: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end'
    }
  }
}

export const YardJockeyDrawerPill = styled.div`
  width: 54px;
  height: 4px;
  background-color: ${primitives.gray};
  border-radius: 10px;
  margin: 10px auto;
`

export const YardJockeyOptionStyles = {
  Block: {
    style: {
      borderBottom: `1px solid ${primitives.gray}`
    }
  }
}

export const YardJockeyOptionText = styled.div`
  color: ${primitives.primary};
  margin-left: 5px;
  font-size: 18px;
`

export const YardJockeyCompleteModalTrailerNumber = styled.strong`
  font-size: 24px;
  font-family: Rubik;
  display: block;
  margin-bottom: 10px;
`

export const YardJockeyCompleteModalTitle = styled.span`
  font-size: 22px;
  font-weight: 100;
  font-family: Rubik;
  display: block;
  margin-bottom: 10px;
`

export const YardJockeyCompleteModalHeader = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: Inter;
  margin-bottom: 30px;
  line-height: 35px;
  color: ${primitives.primary};
`

export const YardJockeyCompleteModalLabel = styled.span`
  font-size: 14px;
  opacity: 0.6;
  font-weight: 200;
  color: ${primitives.text};
`
export const YardJockeyCompleteModalSlot = styled.strong`
  font-size: 24px;
  color: ${primitives.primary};
`

export const YardJockeyCompleteModalStyle = {
  Dialog: {
    style: () => ({
      width: '736px'
    })
  }
}

export const YardJockeyCompleteModalProgressBarStyles = {
  Block: {
    style: () => ({
      borderRadius: '10px'
    })
  }
}

export const YardJockeyCompleteModalButtonStyles = {
  BaseButton: {
    style: {
      width: '100%',
      height: '62px',
      backgroundColor: primitives.accent
    }
  }
}
