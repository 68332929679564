import React, { useContext } from "react";
import { Checkbox } from "baseui/checkbox";
import { CalendarToggleContext } from "../../../../contexts/calendar-toggle-context";

const SchedulerCheckbox = ({ scheduler }) => {
  const { calendarToggles, setCalendarToggles } = useContext(
    CalendarToggleContext
  );

  const hiddenSchedulerIds = calendarToggles?.hiddenSchedulerIds || [];
  const checked = !hiddenSchedulerIds.includes(scheduler.id);

  return (
    <Checkbox
      checkmarkType="toggle"
      checked={checked}
      onChange={(e) => {
        let newHiddenSchedulerIds = hiddenSchedulerIds;
        if (e.currentTarget.checked) {
          newHiddenSchedulerIds = newHiddenSchedulerIds.filter(
            (id) => scheduler.id !== id
          );
        } else {
          newHiddenSchedulerIds.push(scheduler.id);
        }

        setCalendarToggles({
          ...calendarToggles,
          hiddenSchedulerIds: newHiddenSchedulerIds,
        });
      }}
    >
      {scheduler.name}
    </Checkbox>
  );
};

export default SchedulerCheckbox;
