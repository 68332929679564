import React, { useContext } from "react";
import { Checkbox } from "baseui/checkbox";
import { CalendarToggleContext } from "../../../contexts/calendar-toggle-context";

export const FACILITY = "Facility";
export const DOCK = "Dock";
export const CALENDAR_VIEWS = [FACILITY, DOCK];

const DockToggle = () => {
  const { calendarToggles, setCalendarToggles } = useContext(
    CalendarToggleContext
  );
  const { displayDocks } = calendarToggles;

  return (
    <Checkbox
      checked={displayDocks}
      onChange={(e) => {
        setCalendarToggles({
          ...calendarToggles,
          displayDocks: e.currentTarget.checked ? 1 : 0,
        });
      }}
      checkmarkType="toggle"
    >
      Show Docks
    </Checkbox>
  );
};

export default DockToggle;
