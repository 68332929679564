import Firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDEYXtQJS4CqCfxNmTw50MH9MRrYX8I2OE",
  authDomain: "gleaming-design-258423.firebaseapp.com",
  databaseURL: "https://gleaming-design-258423.firebaseio.com",
  projectId: "gleaming-design-258423",
  storageBucket: "gleaming-design-258423.appspot.com",
  messagingSenderId: "29311112423",
  appId: "1:29311112423:web:5eca522170c9e6ea683e42",
  measurementId: "G-8MT14GZY64",
};

const initializeFirebase = () => {
  Firebase.initializeApp(firebaseConfig);
  if (Firebase.messaging.isSupported()) {
    Firebase.messaging().usePublicVapidKey(
      "BDDtTm-rLAq_7FYua_nxDQZjBxBH6SBkVIDZJp62V23AezM5FLfrhXMloYIq6Mrz8Sfe2emQpygRlorklpMM3Rg"
    );
  }
};

export default initializeFirebase;
