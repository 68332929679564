import React, { useCallback, useEffect } from 'react'
import { AppointmentType } from 'components/models/AppointmentType'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Block, BlockProps } from 'baseui/block'
import { LabelXSmall, ParagraphSmall } from 'baseui/typography'
import { LightTheme } from 'baseui'
import { DockCapacity, EquipmentType } from 'components/models/DockCapacity'
import { Option } from 'baseui/select'
import { useTranslation } from 'react-i18next'
import Select from 'components/ui/generic/Select'

interface DockCapacitiesTableProps {
  equipmentTypes: string[]
  appointmentTypes: AppointmentType[]
  dockCapacitiesAttributes: DockCapacity[]
  setAppointmentPreference: (appointmentPreference: any) => void
  appointmentPreference: any
  secondCapacityLayer?: boolean
}

const DEFAULT_LIMIT_VALUE = 0

export const DockCapacitiesTable = ({
  equipmentTypes,
  appointmentTypes,
  dockCapacitiesAttributes,
  setAppointmentPreference,
  appointmentPreference,
  secondCapacityLayer
}: DockCapacitiesTableProps) => {
  const { t } = useTranslation()

  if (equipmentTypes?.length === 0 && appointmentTypes?.length === 0) {
    return (
      <ParagraphSmall color={'red'}>
        {t('AppointmentPreferences.DockCapacitiesTable.NoDocksAndAppointments.Text')}
      </ParagraphSmall>
    )
  }

  return (
    <Block display="flex" gridColumnGap="scale800" overflow="scrollX" maxWidth="100%">
      <Header equipmentTypes={equipmentTypes} />
      <Content
        appointmentTypes={appointmentTypes}
        dockCapacitiesAttributes={dockCapacitiesAttributes}
        equipmentTypes={equipmentTypes}
        setAppointmentPreference={setAppointmentPreference}
        appointmentPreference={appointmentPreference}
        secondCapacityLayer={secondCapacityLayer}
      />
    </Block>
  )
}

const itemProps: BlockProps = {
  height: 'scale1000',
  display: 'flex',
  alignItems: 'center'
}

const narrowItemProps = {
  ...itemProps,
  overrides: {
    Block: {
      style: ({ $theme }: { $theme: typeof LightTheme }) => ({
        width: $theme.sizing.scale2400,
        flexGrow: 0
      })
    }
  }
}

const Header = ({ equipmentTypes }) => (
  <FlexGrid
    flexDirection="column"
    flexGridColumnGap="scale800"
    flexGridRowGap="scale800"
    marginBottom="20px">
    <FlexGridItem {...narrowItemProps} width="scale1600"></FlexGridItem>
    {equipmentTypes.map((type: EquipmentType, idx: number) => (
      <FlexGridItem {...itemProps} key={`${idx}-${type.id}`}>
        <LabelXSmall>
          <div style={{ textTransform: 'capitalize' }}>{type.name}</div>
        </LabelXSmall>
      </FlexGridItem>
    ))}
  </FlexGrid>
)

const Content = ({
  appointmentTypes,
  dockCapacitiesAttributes,
  equipmentTypes,
  setAppointmentPreference,
  appointmentPreference,
  secondCapacityLayer
}) => (
  <Block display="flex" gridColumnGap="scale800">
    {appointmentTypes?.map((appointmentType: AppointmentType) => (
      <FlexGrid
        flexDirection="column"
        flexGridColumnGap="scale800"
        flexGridRowGap="scale800"
        key={`${appointmentType.id}`}>
        <FlexGridItem {...narrowItemProps}>
          <LabelXSmall>
            <div style={{ textTransform: 'capitalize', wordWrap: 'break-word' }}>
              {appointmentType.name}
            </div>
          </LabelXSmall>
        </FlexGridItem>
        {equipmentTypes.map((equipmentType: EquipmentType, idx: number) => (
          <FlexGridItem {...itemProps} key={`${idx}-${appointmentType?.name}-${equipmentType.id}`}>
            <DockCapacitySelect
              equipmentType={equipmentType}
              appointmentTypeId={appointmentType?.id}
              dockCapacitiesAttributes={dockCapacitiesAttributes}
              setAppointmentPreference={setAppointmentPreference}
              appointmentPreference={appointmentPreference}
              dockCapacityLimits={appointmentPreference?.dockCapacitiesLimitsNotShared}
              dockCapacityProp={null}
              dockCapacitiesAttributesIndex={null}
              secondCapacityLayer={secondCapacityLayer}
            />
          </FlexGridItem>
        ))}
      </FlexGrid>
    ))}
  </Block>
)

export const DockCapacitySelect = ({
  equipmentType,
  appointmentTypeId,
  dockCapacitiesAttributes,
  setAppointmentPreference,
  appointmentPreference,
  dockCapacityLimits,
  dockCapacityProp,
  dockCapacitiesAttributesIndex,
  secondCapacityLayer
}) => {
  const limit = dockCapacityProp
    ? dockCapacityLimits[equipmentType.id]
    : dockCapacityLimits?.filter(
        capacityLimit => capacityLimit.appointmentTypeId === appointmentTypeId
      )[0][equipmentType.id]

  const limitValue = dockCapacityProp
    ? secondCapacityLayer
      ? dockCapacityProp.secondLayerLimit
      : dockCapacityProp.limit
    : DEFAULT_LIMIT_VALUE

  const [value, setValue] = React.useState([
    {
      id: limitValue,
      label: limitValue
    }
  ])

  useEffect(() => {
    if (!dockCapacityProp) {
      const dockCapacity = dockCapacitiesAttributes?.filter(
        (dockCapacity: DockCapacity) =>
          equipmentType.id === dockCapacity?.equipmentTypeId &&
          appointmentTypeId === dockCapacity?.appointmentTypeId
      )[0]

      const limitValue = dockCapacity
        ? secondCapacityLayer
          ? dockCapacity.secondLayerLimit
          : dockCapacity.limit
        : DEFAULT_LIMIT_VALUE

      setValue([
        {
          id: limitValue,
          label: limitValue
        }
      ])
    }
  }, [])

  const handleOnChange = (selectedOption: Option) => {
    if (dockCapacityProp) {
      const newAppointmentPreference = {
        ...appointmentPreference
      }

      if (secondCapacityLayer) {
        newAppointmentPreference.dockCapacitiesAttributes[
          dockCapacitiesAttributesIndex
        ].secondLayerLimit = selectedOption.id
      } else {
        newAppointmentPreference.dockCapacitiesAttributes[dockCapacitiesAttributesIndex].limit =
          selectedOption.id
      }

      setAppointmentPreference(newAppointmentPreference)
    } else {
      const index = dockCapacitiesAttributes.findIndex(
        (dockCapacity: DockCapacity) =>
          equipmentType.id === dockCapacity?.equipmentTypeId &&
          appointmentTypeId === dockCapacity?.appointmentTypeId
      )

      if (index >= 0) {
        const dockCapacity = { ...dockCapacitiesAttributes[index] }

        if (secondCapacityLayer) {
          dockCapacity.secondLayerLimit = selectedOption.id
        } else {
          dockCapacity.limit = selectedOption.id
        }

        setAppointmentPreference({
          ...appointmentPreference,
          dockCapacitiesAttributes: [
            ...dockCapacitiesAttributes.slice(0, index),
            dockCapacity,
            ...dockCapacitiesAttributes.slice(index + 1)
          ]
        })
      }
    }
    setValue([selectedOption as any])
  }

  const generateOptions = useCallback(() => {
    const options = []
    for (let i = 0; i <= limit; i++) {
      options.push({
        id: i,
        label: i
      })
    }
    return options
  }, [limit])

  return (
    <Select
      options={generateOptions()}
      placeholder=""
      value={value}
      clearable={false}
      onChange={params => handleOnChange(params.value[0])}
      maxWidth="95%"
      maxDropdownHeight={'160px'}
    />
  )
}
