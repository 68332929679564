import React from 'react'
import { Route } from 'react-router-dom'
import { SchedulerInstantSearch } from './SchedulerInstantSearch'

const SchedulerRoutes = ({ match }) => (
  <>
    <Route path={`${match.url}`} exact component={SchedulerInstantSearch} />
  </>
)

export default SchedulerRoutes
