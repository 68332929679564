import React from 'react'
import Input from 'components/ui/generic/Input'
import Checkbox from 'components/ui/generic/Checkbox'
import FormControl from 'components/ui/generic/FormControl'
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid'
import { useTranslation } from 'react-i18next'

const MinLengthInput = ({ questions, setQuestions, index }) => {
  const question = questions[index]
  const { id, minLength } = question
  const { t } = useTranslation()

  const handleCheckboxToggle = e => {
    const newQuestions = [...questions]
    newQuestions[index].minLength = e.currentTarget.checked ? 1 : null
    setQuestions(newQuestions)
  }

  const handleMinLengthUpdate = e => {
    const newQuestions = [...questions]
    if (
      (question?.maxLength && Number(e.target.value) <= question?.maxLength) ||
      !question?.maxLength
    ) {
      newQuestions[index].minLength = e.currentTarget.value.replace(/\D/, '')
      setQuestions(newQuestions)
    }
  }

  return (
    <FormControl
      overrides={{ Label: { style: { marginBottom: '0px' } } }}
      label={
        <Checkbox
          labelPlacement="right"
          checked={!!question.minLength}
          onChange={handleCheckboxToggle}
          label={t('Settings.AppointmentTypes.CustomQuestions.MinLength')}
        />
      }>
      <FlexGrid>
        {minLength ? (
          <FlexGridItem>
            <Input
              autoComplete="off"
              name={`question[${id}][min_length]`}
              value={minLength}
              onChange={handleMinLengthUpdate}
            />
          </FlexGridItem>
        ) : (
          <></>
        )}
      </FlexGrid>
    </FormControl>
  )
}

export default MinLengthInput
