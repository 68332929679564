import React, { useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { useTranslation } from 'react-i18next'
import { MagnifyingGlass, CircleNotch } from '@phosphor-icons/react'
import { useStyletron } from 'baseui'
import { InviteSchedulerModal } from 'components/shipper/modals/InviteSchedulerModal'
import { AddButton } from 'components/components/AddButton'

import Input from 'components/ui/generic/Input'

export const SchedulerUserInstantSearchInput = connectSearchBox(
  ({ currentRefinement, refine, isSearchStalled }) => {
    const [css, theme] = useStyletron()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const { t } = useTranslation()

    return (
      <>
        <InviteSchedulerModal
          isOpen={isOpen}
          close={() => {
            setIsOpen(false)
            refine()
          }}
        />
        <div className={css({ display: 'flex', gap: theme.sizing.scale800 })}>
          <Input
            startEnhancer={
              isSearchStalled ? (
                <CircleNotch
                  className={css({
                    animationDuration: '800ms',
                    animationIterationCount: 'infinite',
                    animationTimingFunction: 'linear',
                    animationName: {
                      from: {
                        transform: 'rotate(0deg)'
                      },
                      to: {
                        transform: 'rotate(360deg)'
                      }
                    }
                  })}
                  size={theme.sizing.scale600}
                />
              ) : (
                <MagnifyingGlass />
              )
            }
            placeholder={t('Settings.VendorCompanyUsers.SearchBox.PlaceHolder.Text')}
            onChange={evt => refine(evt.currentTarget.value)}
            autoComplete="off"
            name="search[name]"
            value={currentRefinement}
          />
          <AddButton onClick={() => setIsOpen(true)} />
        </div>
      </>
    )
  }
)
