import { styled } from 'baseui'
import { Block } from 'baseui/block'

export const HeaderContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  gap: $theme.sizing.scale400,
  alignItems: 'center'
}))

export const BlockLoadingSpinner = styled(Block, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%'
})
