import React, { useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { MagnifyingGlass, CircleNotch } from '@phosphor-icons/react'
import { useStyletron } from 'baseui'
import { AddButton } from 'components/components/AddButton'
import Input from 'components/ui/generic/Input'
import { InviteShipperModal } from '../modals/InviteShipperModal'

export const Searchbox = ({ currentRefinement, isSearchStalled, refine }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [css, theme] = useStyletron()

  return (
    <>
      <InviteShipperModal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false)
          refine()
        }}
      />
      <div className={css({ display: 'flex', gap: theme.sizing.scale800 })}>
        <Input
          startEnhancer={
            isSearchStalled ? (
              <CircleNotch
                className={css({
                  animationDuration: '800ms',
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear',
                  animationName: {
                    from: {
                      transform: 'rotate(0deg)'
                    },
                    to: {
                      transform: 'rotate(360deg)'
                    }
                  }
                })}
                size={theme.sizing.scale600}
              />
            ) : (
              <MagnifyingGlass />
            )
          }
          placeholder="Name, email address or phone number"
          autoComplete="off"
          name="search[name]"
          size="compact"
          value={currentRefinement}
          onChange={evt => {
            refine(evt.currentTarget.value)
          }}
        />
        <AddButton onClick={() => setIsOpen(true)} />
      </div>
    </>
  )
}

export const ConnectedSearchbox = connectSearchBox(Searchbox)
