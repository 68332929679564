import { styled } from 'baseui'
import Button from 'components/ui/generic/Button'
import Checkbox from 'components/ui/generic/Checkbox'
import Datepicker from 'components/ui/generic/DatePicker'
import TimePicker from 'components/ui/generic/TimePicker'

export const DatepickerStyled = styled(Datepicker, () => ({
  Input: {
    props: {
      overrides: {
        Input: {
          style: () => ({
            userSelect: 'none'
          })
        }
      }
    }
  }
}))

export const TimePickerStyled = styled(TimePicker, () => ({
  Select: {
    props: {
      overrides: {
        Input: {
          style: () => ({
            userSelect: 'none'
          })
        }
      }
    }
  }
}))

export const CancelButtonStyled = styled(Button, ({ $theme }) => ({}))

export const DayCheckboxStyled = styled(Checkbox, () => ({
  Label: {
    style: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}))

export const FlexRow = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: $theme.sizing.scale600,
  marginBottom: $theme.sizing.scale400
}))
