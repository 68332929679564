import styled from 'styled-components'
import { primitives } from '../../homepage/custom-theme'

export const ModalTitle = styled.h4`
  color: ${primitives.text};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
`

export const ModalSubTitle = styled.h6`
  color: ${primitives.text};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
`

export const ModalBodyStyle = { margin: '24px 20px 20px 20px' }

export const Paragraph = styled.p`
  color: ${primitives.primary};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

export const CustomerNameTitle = styled.span`
  color: ${primitives.primary};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  margin-left: 5px;
`
export const PurchaseOrderText = styled.span`
  color: ${primitives.primary};
  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
`

export const DateText = styled.span`
  color: ${primitives.text};
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
`

export const ModalContentTopContainer = styled.div`
  padding: 12px;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid rgba(117, 158, 255, 0.2);
  background: rgba(219, 230, 255, 0.2);
  margin-top: '24px';
`

export const ModalContentTopContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModalContentCustomerName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContentPO = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
`

export const FromWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 24px;
  padding: 8px 16px;
`

export const FromLabel = styled.div`
  color: ${primitives.text};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`

export const FromText = styled.div`
  color: ${primitives.primary};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 4px;
  line-height: 16px;
`
