import React, { useEffect, useState } from 'react'
import { useStyletron } from 'baseui'
import { Card } from 'baseui/card'
import { HeadingSmall, ParagraphXSmall, ParagraphSmall } from 'baseui/typography'
import { LoginForm } from 'components/shared/login-helpers'
import StyledSpinner from 'components/shared/styled-spinner'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { useTranslation } from 'react-i18next'
import Header from 'components/ui/generic/Header'
import { IdentificationBadge, UserPlus } from '@phosphor-icons/react'

const InviteShow = ({ match }) => {
  const [css, theme] = useStyletron()
  const [invite, setInvite] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    authenticatedFetch({ path: `/invites/${match.params.handle}.json` }).then(([json, status]) => {
      if ([200, 304].includes(status)) {
        setInvite(json)
      }
    })
  }, [])

  const InviteAvailable = () => {
    const { createdBy, user } = invite
    return (
      <>
        <UserPlus size={theme.sizing.scale3200} />

        <HeadingSmall marginTop="scale400">
          <strong>{createdBy.name} </strong>
          {t('Invite.Signup.Header', { name: user.name, shipper: createdBy.shipperName })}
        </HeadingSmall>
        <ParagraphSmall>{t('Invite.Signup.Paragraph')}</ParagraphSmall>
        <LoginForm
          initialScreen="signUp"
          title={t('Invite.Signup.Button')}
          signupMethod="password"
          prefillEmail={invite.user.emailAddress}
        />
        <ParagraphXSmall>{t('Constants.AssistanceMessage.Text')}</ParagraphXSmall>
      </>
    )
  }

  const InviteComplete = () => {
    const { createdBy, user } = invite
    return (
      <>
        <IdentificationBadge size={theme.sizing.scale4800} />

        <HeadingSmall marginTop="scale400">
          {createdBy.name}{' '}
          {t('Invite.Login.Header', { name: user.name, shipper: createdBy.shipperName })}
        </HeadingSmall>
        <ParagraphSmall>{t('Invite.Login.Paragraph')}</ParagraphSmall>
        <LoginForm
          allowSignUp={false}
          initialScreen="login"
          title={t('Invite.Login.Button')}
          signupMethod="password"
          prefillEmail={invite.user.emailAddress}
        />
        <ParagraphXSmall>{t('Constants.AssistanceMessage.Text')}</ParagraphXSmall>
      </>
    )
  }

  if (!invite) {
    return <StyledSpinner />
  }

  return (
    <>
      <Header
        title={
          invite.inviteCompleted
            ? t('Invite.Login.Title')
            : t('Invite.Signup.Title', { shipper: invite.createdBy.shipperName })
        }
      />
      <Card
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.white,
              borderTopColor: $theme.colors.inputBorder,
              borderBottomColor: $theme.colors.inputBorder,
              borderLeftColor: $theme.colors.inputBorder,
              borderRightColor: $theme.colors.inputBorder,
              borderTopWidth: $theme.borders.borderWidth,
              borderBottomWidth: $theme.borders.borderWidth,
              borderLeftWidth: $theme.borders.borderWidth,
              borderRightWidth: $theme.borders.borderWidth,
              maxWidth: '640px'
            })
          }
        }}>
        {invite.inviteCompleted ? <InviteComplete /> : <InviteAvailable />}
      </Card>
    </>
  )
}

export default InviteShow
