import React from 'react'
import { KIND } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { UploadSimple } from '@phosphor-icons/react'
import Button from '../generic/Button'

const UploadButton = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <Button kind={KIND.secondary} IconStart={<UploadSimple />} {...props}>
      {t('Common.Button.Upload.Text')}
    </Button>
  )
}
export default UploadButton
