import React from 'react'
import { Card, StyledBody, StyledAction } from 'baseui/card'
import Button from 'components/ui/specific/PrimaryButton'
import { useTranslation } from 'react-i18next'

export const DEMO_URL = 'https://meetings.hubspot.com/anastasia-fedorova'

export default function VelosticsDemo() {
  const { t } = useTranslation()
  return (
    <Card
      title={t('Common.Fields.DemoCardTitle.Text')}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.white,
            borderTopColor: 'transparent',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent'
          })
        },
        Body: {
          style: ({ $theme }) => ({
            display: 'flex',
            flexDirection: 'column',
            fontSize: $theme.typography.LabelSmall.fontSize
          })
        },
        Title: {
          style: {
            fontFamiliy: 'Rubik'
          }
        }
      }}>
      <StyledBody>{t('Common.Fields.DemoCardText.Text')}</StyledBody>
      <StyledAction>
        <Button
          onClick={() => {
            window.open(DEMO_URL, '_blank')
          }}>
          {t('Common.Fields.DemoCardCallToAction.Text')}
        </Button>
      </StyledAction>
    </Card>
  )
}
