import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import SidebarNavbarTemplate from 'components/templates/SidebarNavbarTemplate'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import Fallback from 'components/shared/Fallback'
import OpenSchedulingCreateAppointmentDrop from 'components/pages/OpenSchedulingCreateAppointmentDrop'
import OpenSchedulingUpdateAppointmentDrop from 'components/pages/OpenSchedulingUpdateAppointmentDrop'
import UpdateAppointmentOpenSchedulingSearch from 'components/pages/OpenSchedulingUpdateAppointmentDrop/UpdateAppointmentOpenSchedulingSearch'

export const OpenSchedulingRoutes = ({ match }) => {
  return (
    <CustomQuestionsProvider>
      <Switch>
        <SidebarNavbarTemplate>
          <ErrorBoundary fallback={<Fallback />}>
            <Route
              exact
              path={`${match.url}/update`}
              component={UpdateAppointmentOpenSchedulingSearch}
            />
            <Route
              exact
              path={`${match.url}/:handle/schedule`}
              component={OpenSchedulingUpdateAppointmentDrop}
            />
            <Route exact path={match.url} component={OpenSchedulingCreateAppointmentDrop} />
          </ErrorBoundary>
        </SidebarNavbarTemplate>
      </Switch>
    </CustomQuestionsProvider>
  )
}
