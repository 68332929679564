import { styled } from 'baseui'
import { LabelSmall, ParagraphSmall } from 'baseui/typography'

export const CustomLabel1 = styled(ParagraphSmall, {
  color: '#04112B',
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: '400',
  lineHeight: '4px',
  whiteSpace: 'nowrap'
})

export const CustomLabel = styled(LabelSmall, {
  color: '#04112B',
  fontSize: '15px',
  fontFamily: 'Inter',
  fontWeight: '500',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
  textTransform: 'capitalize',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
})

export const CustomLabelCapitalized = styled(LabelSmall, {
  color: '#04112B',
  fontSize: '15px',
  fontFamily: 'Inter',
  fontWeight: '500',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
  textTransform: 'capitalize',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
})

export const CustomLabelContainer = styled('div', {
  maxWidth: '55%'
})

export const ContainerProps = {
  overrides: {
    Block: {
      style: {
        display: 'flex',
        flexWrap: 'nowrap',
        width: '100%'
      }
    }
  }
}

export const LabelContainerProps = {
  overrides: {
    Block: {
      style: {
        display: 'flex',
        flexWrap: 'nowrap',
        width: '100%',
        textAlign: 'end'
      }
    }
  }
}
