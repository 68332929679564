import { DirectUploadProvider } from 'react-activestorage-provider'
import { FileUploader as StyledFileUploader } from 'baseui/file-uploader'
import React, { useEffect, useRef, useState } from 'react'
import pluralize from 'pluralize'
import { fancyToast } from 'components/utils'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { useTranslation } from 'react-i18next'
import fileUploaderStyling from './styles/file-uploader-styling'
import FILE_FORMATS from 'components/utils/file-formats'

type FileUploaderOptions = {
  type: string
  addFile: boolean
  maxHeight?: string
  maxWidth?: string
  labelMarginTop?: string
  labelMarginLeft?: string
  customLabel?: string
  iconMarginTop?: string
}

type FileUploaderProps = {
  onSuccess?: (signedIds: any) => void
  onPreview?: (items: any) => void
  removePreviewFile?: any
  fileCountObserver?: (func: any) => void
  onError?: (error?: any) => void
  multiple?: boolean
  questionId?: string
  options?: FileUploaderOptions
  finalDataLoad?: boolean
  disabled?: boolean
}

const INITIAL_OPTIONS = {
  maxWidth: '350px',
  maxHeight: '600px',
  addFile: false,
  type: null,
  marginTop: null
}
const MAX_ZISE_FILE_LIMIT_BYTES = 30000000
const UPLOADER_METHODS = {
  INITIAL: 'initial',
  REMOVE: 'remove',
  UPDATE: 'update'
}

const FileUploader = ({
  onSuccess,
  onError,
  multiple = false,
  options = INITIAL_OPTIONS,
  onPreview = undefined,
  removePreviewFile = undefined,
  fileCountObserver = undefined,
  questionId = undefined,
  disabled = false
}: FileUploaderProps) => {
  const [uploadedFileCount, setUploadedFileCount] = useState<number>(0)
  const [temporalFiles, setTemporalFiles] = useState<Array<any>>(null)
  const [, setShouldRender] = useState<boolean>(false)
  const customQuestionsCtx = useCQStateValue()
  const { t } = useTranslation()

  const uploaderMethodRef = useRef(UPLOADER_METHODS.INITIAL)

  useEffect(() => {
    if (options.type === FILE_FORMATS.DOCUMENT && customQuestionsCtx?.settings?.startFileUpload) {
      uploaderMethodRef.current = UPLOADER_METHODS.UPDATE
      setShouldRender(s => !s)
    }
  }, [customQuestionsCtx?.settings?.startFileUpload, options.type])

  useEffect(() => {
    if (removePreviewFile !== null) {
      uploaderMethodRef.current = 'remove'
    }
  }, [removePreviewFile])

  useEffect(() => {
    if (fileCountObserver && uploadedFileCount > 10) {
      fancyToast({ info: t('Common.FileUploader.Info.MaxLimit.Text') }, 412)
    }
  }, [uploadedFileCount])

  const restartUploaderConfig = () => {
    setUploadedFileCount(0)
  }

  const handleTemporalFiles = (signedIds?: Array<string>) => {
    if (options.type === FILE_FORMATS.DOCUMENT) {
      const {
        actions: { updateTemporalFiles, updateAnswer }
      } = customQuestionsCtx
      updateAnswer({
        questionId,
        documents: signedIds
      })
      updateTemporalFiles(questionId)
    }
  }

  const handleDropAccepted = (files, handleUpload) => {
    const cleanedFiles = files.filter(file => {
      if (file) {
        const sizeFileLock = file.size > MAX_ZISE_FILE_LIMIT_BYTES
        if (sizeFileLock) {
          fancyToast(
            { Upload: `${file?.path} : ${t('Common.FileUploader.Info.ExceedMB.Text')}` },
            200
          )
        }
        const invalidFileType =
          (file?.type.includes(FILE_FORMATS.VIDEO) || file?.type.includes(FILE_FORMATS.AUDIO)) &&
          (options.type === FILE_FORMATS.IMAGE || options.type === FILE_FORMATS.DOCUMENT)

        if (invalidFileType) {
          fancyToast(
            {
              Upload: `${file.path} : ${t('Common.FileUploader.Info.AudioOrVideoNotAllowed.Text')}`
            },
            200
          )
        }

        if (!sizeFileLock && !invalidFileType) {
          return { ...file }
        }
      }
    })

    const loadLimit = cleanedFiles?.length + temporalFiles?.length

    if (cleanedFiles?.length > 9 || loadLimit > 10) {
      restartUploaderConfig()
      return fancyToast({ Upload: `: ${t('Common.FileUploader.Info.LimitFiles.Text')}` }, 200)
    }
    if (questionId && options.type === FILE_FORMATS.DOCUMENT) {
      const {
        actions: { loadTemporalFiles }
      } = customQuestionsCtx
      loadTemporalFiles(questionId, cleanedFiles)
    }

    if (cleanedFiles?.length && options.type === FILE_FORMATS.IMAGE) {
      setTemporalFiles(cleanedFiles)
      handleUpload(cleanedFiles)
    }
  }

  const handleSuccess = signedIds => {
    if (options.type === FILE_FORMATS.DOCUMENT) {
      handleTemporalFiles(signedIds)
    } else {
      setUploadedFileCount(signedIds.length)
      onSuccess(signedIds)
    }
  }

  const handleUpdateUpload = handleUpload => {
    const cleanedTemporalFiles = customQuestionsCtx?.temporalFiles.filter(tf => tf !== null)
    handleUpload(cleanedTemporalFiles.filter(tf => tf?.questionId === questionId)[0]?.files)
    uploaderMethodRef.current = UPLOADER_METHODS.INITIAL
  }

  const handleRemoveUpload = () => {
    const cleanedFiles = temporalFiles?.length
      ? temporalFiles.filter(file => file?.id !== removePreviewFile?.id)
      : null

    if (!cleanedFiles) {
      setTemporalFiles(null)
      restartUploaderConfig()
    } else {
      setTemporalFiles(cleanedFiles)
      setUploadedFileCount(cleanedFiles?.length)
    }
    uploaderMethodRef.current = UPLOADER_METHODS.INITIAL
  }

  const handleRender = ({ handleUpload, uploads, ready }) => {
    if (uploaderMethodRef.current === UPLOADER_METHODS.UPDATE) {
      handleUpdateUpload(handleUpload)
    }

    if (uploaderMethodRef.current === UPLOADER_METHODS.REMOVE) {
      handleRemoveUpload()
    }

    if (options.type === FILE_FORMATS.IMAGE && uploads[0]?.state === 'finished') {
      const reader = new FileReader()

      reader.onerror = () => onError()
      reader.onload = e => {
        const { result } = e.target
        onPreview(result)
      }
      reader.readAsDataURL(uploads[0]?.file)
    }

    const finishedCount = uploads.filter(upload => upload.state === 'finished').length

    const progressAmount = () => {
      if (options.type === FILE_FORMATS.IMAGE || FILE_FORMATS.DOCUMENT) {
        return null
      }

      if (uploadedFileCount > 0) {
        return 100
      }

      return finishedCount > 0 ? (finishedCount / uploads.length) * 100 : null
    }

    const progressMessage = () => {
      if (options.type === FILE_FORMATS.IMAGE || FILE_FORMATS.DOCUMENT) {
        return null
      }
      const filesCount = Math.max(finishedCount, uploadedFileCount)
      return filesCount > 0
        ? `${filesCount} ${pluralize('file', filesCount)} ${t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.File.Text'),
            action: t('Common.Actions.Attached.Text')
          })}`
        : null
    }

    return (
      <StyledFileUploader
        onCancel={() => restartUploaderConfig()}
        multiple={!!multiple}
        disabled={disabled || !ready}
        onDropAccepted={file => handleDropAccepted(file, handleUpload)}
        progressAmount={progressAmount()}
        progressMessage={progressMessage()}
        overrides={fileUploaderStyling(options, t, ready)}
      />
    )
  }

  return (
    <DirectUploadProvider
      headers={{
        origin: ['*', 'https://velostics.com', 'https://*.velostics.com'],
        method: ['PUT', 'POST', 'GET'],
        responseHeader: ['Content-Type', 'Content-MD5'],
        maxAgeSeconds: 3600
      }}
      onSuccess={handleSuccess}
      render={handleRender}
    />
  )
}

export default FileUploader
