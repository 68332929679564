import React, { useContext, useReducer } from 'react'
import { createContext } from 'components/utils/create-context'
import {
  EquipmentTypeReducer,
  RECALCULATE_COLUMNS,
  SET_ALL_AVAILABLE,
  SET_COLUMNS,
  SET_CONFIRMATION_NEEDED,
  SET_FORM_OPEN,
  SET_LOADING,
  SET_SAVING,
  SET_SELECTED_EQUIPMENT_TYPE
} from 'components/reducers/equipment-type.reducer'
import { EquipmentType } from 'components/models/DockCapacity'
import {
  createEquipmentType,
  fetchAffectedDocks,
  fetchAllEquipmentTypes,
  fetchAssignedEquipmentTypes,
  saveAssignedEquipmentTypes,
  updateEquipmentType
} from 'components/services/equipment-type.service'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { fancyToast } from 'components/utils'
import { useTranslation } from 'react-i18next'

export type DroppableColumn = {
  id: string
  title: string
  list: EquipmentType[]
}

export const selectedColumnId = 'selected'

export type EquipmentTypeState = {
  loading: boolean
  saving: boolean
  confirmationNeeded: boolean
  isFormOpen: boolean
  selectedEquipmentType?: EquipmentType
  allAvailableEqTypes: EquipmentType[]
  assignedEquipmentTypes: EquipmentType[]
  columns: {
    available: DroppableColumn
    selected: DroppableColumn
  }
}

export const [useEquipmentTypesContext, EquipmentTypesContextProvider] = createContext()

export const EquipmentTypesProvider = ({ children }) => {
  const {
    shipperEquipmentTypes,
    setShipperEquipmentTypes,
    assignedEquipmentTypes,
    setAssignedEquipmentTypes
  } = useContext(CurrentUserContext)

  const { t } = useTranslation()

  const initialState: EquipmentTypeState = {
    loading: true,
    saving: false,
    isFormOpen: false,
    confirmationNeeded: false,
    selectedEquipmentType: null,
    allAvailableEqTypes: [],
    assignedEquipmentTypes: [],
    columns: {
      available: {
        id: 'available',
        title: t('Settings.EquipmentTypes.DragAndDrop.Columns.Available.Text'),
        list: []
      },
      selected: {
        id: selectedColumnId,
        title: t('Settings.EquipmentTypes.DragAndDrop.Columns.Active.Text'),
        list: []
      }
    }
  }

  const [equipmentTypeState, dispatch] = useReducer(EquipmentTypeReducer, initialState)
  const { columns } = equipmentTypeState

  const fetchAllAvailableEqTypes = async () => {
    return fetchAllEquipmentTypes().then(([data]) => {
      dispatch({ type: SET_ALL_AVAILABLE, payload: data })
      setShipperEquipmentTypes(data)
    })
  }

  const retrieveAssignedEquipmentTypes = async () => {
    return fetchAssignedEquipmentTypes().then(([json, status]) => {
      if ([200, 304].includes(status) && json) {
        setAssignedEquipmentTypes(json)
      }
    })
  }

  const setColumns = columns => {
    dispatch({ type: SET_COLUMNS, payload: columns })
  }

  const setLoading = (value: boolean) => {
    dispatch({ type: SET_LOADING, payload: value })
  }

  const setSaving = (value: boolean) => {
    dispatch({ type: SET_SAVING, payload: value })
  }

  const syncAssigned = (equipment_type_ids: string[]) => {
    setLoading(true)
    saveAssignedEquipmentTypes(equipment_type_ids)
      .then(([data, status]) => {
        if (status < 300) {
          setAssignedEquipmentTypes(data)
          fancyToast({ info: t('Common.Info.Saved.Default.Text') }, status)
        } else {
          fancyToast({ error: t('Common.Errors.Default.Text') }, status)
        }
      })
      .then(async () => {
        await fetchAllAvailableEqTypes()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const validateSync = (equipment_type_ids: string[]) => {
    getAffectedDocks(equipment_type_ids).then(([data, status]) => {
      if (status >= 400) {
        fancyToast({ error: t('Common.Errors.Default.Text') }, status)
      } else {
        if (data.length > 0) {
          setConfirmationNeeded(true)
        } else {
          syncAssigned(equipment_type_ids)
        }
      }
    })
  }

  const setFormOpen = (flag: boolean) => {
    dispatch({ type: SET_FORM_OPEN, payload: flag })
  }

  const setConfirmationNeeded = (flag: boolean) => {
    dispatch({ type: SET_CONFIRMATION_NEEDED, payload: flag })
  }

  const setSelectedEquipmentType = (record: EquipmentType) => {
    dispatch({ type: SET_SELECTED_EQUIPMENT_TYPE, payload: record })
  }

  const createEqType = (record: EquipmentType) => {
    setSaving(true)
    createEquipmentType(record)
      .then(([data, status]) => {
        if ([200, 304].includes(status) && data) {
          retrieveAssignedEquipmentTypes()
          setSelectedEquipmentType(null)
          setShipperEquipmentTypes([...shipperEquipmentTypes, data] as any)
          recalculateColumns()
          setFormOpen(false)
          fancyToast(
            {
              info: t('Common.Info.Interpolated.Text', {
                model: t('Common.ModelType.EquipmentType.Text'),
                action: t('Common.Actions.Created.Text')
              })
            },
            status
          )
        }
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const getAffectedDocks = (equipmentTypeIds: string[]) => {
    return fetchAffectedDocks(equipmentTypeIds)
  }

  const updateEqType = (record: EquipmentType) => {
    setSaving(true)
    updateEquipmentType(record)
      .then(([data, status]) => {
        if ([204, 304].includes(status) && data) {
          retrieveAssignedEquipmentTypes()
          setSelectedEquipmentType(null)
          setShipperEquipmentTypes(
            shipperEquipmentTypes.map(eq => (eq.id === record.id ? record : eq)) as any
          )
          recalculateColumns()
          setFormOpen(false)
          fancyToast(
            {
              info: t('Common.Info.Interpolated.Text', {
                model: t('Common.ModelType.EquipmentType.Text'),
                action: t('Common.Actions.Updated.Text')
              })
            },
            200
          )
        }
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const recalculateColumns = () => {
    dispatch({
      type: RECALCULATE_COLUMNS,
      payload: { all: shipperEquipmentTypes, assigned: assignedEquipmentTypes }
    })
  }

  const onDragEnd = ({ source, destination }) => {
    if (destination === undefined || destination === null) return null
    if (source.droppableId === destination.droppableId && destination.index === source.index)
      return null

    const start = columns[source.droppableId]
    const end = columns[destination.droppableId]

    if (start === end) {
      const newList = start.list.filter((_: any, idx: number) => idx !== source.index)

      newList.splice(destination.index, 0, start.list[source.index])

      const newCol = {
        id: start.id,
        title: start.title,
        list: newList
      }

      setColumns({ ...columns, [newCol.id]: newCol })
      return null
    } else {
      const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index)

      const newStartCol = {
        id: start.id,
        title: start.title,
        list: newStartList
      }

      const newEndList = end.list

      newEndList.splice(destination.index, 0, start.list[source.index])

      const newEndCol = {
        id: end.id,
        title: end.title,
        list: newEndList
      }

      setColumns({
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol
      })

      const selectedColumn = newStartCol.id === columns.selected.id ? newStartCol : newEndCol

      validateSync(selectedColumn.list.map(type => type.id))
      return null
    }
  }

  return (
    <EquipmentTypesContextProvider
      value={{
        equipmentTypeState,
        actions: {
          fetchAllAvailableEqTypes,
          setColumns,
          setLoading,
          onDragEnd,
          recalculateColumns,
          createEqType,
          updateEqType,
          setSelectedEquipmentType,
          setFormOpen,
          getAffectedDocks,
          syncAssigned,
          setConfirmationNeeded
        }
      }}>
      {children}
    </EquipmentTypesContextProvider>
  )
}
