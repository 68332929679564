import { Heading, HeadingLevel } from "baseui/heading";
import StyledSpinner from "components/shared/styled-spinner";
import moment from "moment";
import authenticatedFetch from "components/utils/authenticated-fetch";
import React, { useEffect, useState } from "react";
import { groupBy } from "lodash";
import { StyledBodyCell, StyledHeadCell, StyledTable } from "baseui/table-grid";
import StyledRows from "./styled-rows";
import TimeFormatter from "components/utils/time-formatter";
import { Breadcrumbs } from "baseui/breadcrumbs";
import CyberLink from "cyber/link";
import { Paragraph1 } from "baseui/typography";

const SchedulerShow = ({ match }) => {
  // HoverIndicies is wayyy too hard to spell.
  const [hoverIndexes, setHoverIndexes] = useState<[number, number]>([
    null,
    null,
  ]);
  const [scheduler, setScheduler] = useState(null);
  const [groupedAppointments, setGroupedAppointments] = useState([]);

  useEffect(() => {
    authenticatedFetch({ path: `/users/${match.params.handle}.json` }).then(
      ([json, status]) => {
        if ([200, 304].includes(status)) {
          setScheduler(json);
        }
      }
    );
  }, []);

  useEffect(() => {
    authenticatedFetch({
      path: `/users/${match.params.handle}/upcoming_appointments.json`,
    }).then(([json, status]) => {
      if ([200, 304].includes(status)) {
        const appointmentHash = groupBy(json, ({ arrivalTime }) =>
          moment(arrivalTime).startOf("day")
        );
        const appointmentRows = Object.keys(appointmentHash)
          .sort((a, b) => {
            return moment(b).diff(moment(a));
          })
          .map((key) => {
            const rows = appointmentHash[key];
            return [key, rows];
          });
        setGroupedAppointments(appointmentRows);
      }
    });
  }, []);

  if (!scheduler) {
    return <StyledSpinner />;
  }

  return (
    <HeadingLevel>
      <Heading marginTop="0">
        <Breadcrumbs>
          <CyberLink href="/settings/schedulers">Schedulers</CyberLink>
          <span>{scheduler.organizationName || "scheduler"}</span>
        </Breadcrumbs>
      </Heading>
      <StyledTable $gridTemplateColumns="150px 150px auto 150px">
        <StyledHeadCell>Date</StyledHeadCell>
        <StyledHeadCell>Time</StyledHeadCell>
        <StyledHeadCell>Purchase Order</StyledHeadCell>
        <StyledHeadCell>Dock</StyledHeadCell>
        {groupedAppointments.map(([date, appointments], index) => {
          return (
            <React.Fragment>
              <StyledBodyCell $gridRow={`span ${appointments.length}`}>
                <u>{new TimeFormatter("shortDate").format(date)}</u>
              </StyledBodyCell>
              <StyledRows
                index={index}
                hoverIndexes={hoverIndexes}
                setHoverIndexes={setHoverIndexes}
                appointments={appointments}
              />
            </React.Fragment>
          );
        })}
      </StyledTable>
    </HeadingLevel>
  );
};

export default SchedulerShow;
