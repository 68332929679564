export const termsOfServiceStyles = {
  modal: {
    Dialog: {
      style: {
        width: '80vw',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  modalBody: {
    flex: '1 1 0',
    overflow: 'auto'
  }
}
