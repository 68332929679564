import React from 'react'
import { Textarea } from 'baseui/textarea'
import { SIZE } from 'baseui/input'

const VTextaArea = ({ overrides, value, startEnhancer, ...props }) => {
  return (
    <Textarea
      autoComplete="off"
      size={SIZE.compact}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            borderTopWidth: $theme.borders.borderWidth,
            borderBottomWidth: $theme.borders.borderWidth,
            borderLeftWidth: $theme.borders.borderWidth,
            borderRightWidth: $theme.borders.borderWidth
          })
        },
        ...overrides
      }}
      {...props}
    />
  )
}
export default VTextaArea
