import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "baseui/modal";
import { Input } from "baseui/input";
import { Search } from "baseui/icon";
import { Table } from "baseui/table";
import { Link } from "react-router-dom";
import { useStyletron } from "baseui";
import TimeFormatter from "../utils/time-formatter";

const SchedulerModal = ({ isOpen, onClose, appointments }) => {
  const [css, theme] = useStyletron();
  return (
    <Modal size="auto" onClose={onClose} isOpen={isOpen}>
      <ModalHeader>Ryder</ModalHeader>
      <ModalBody>
        <div className={css({ height: "400px", minWidth: "500px" })}>
          <Table
            columns={["Unique Load Identifiers", "Status"]}
            data={appointments.map((appointment) => {
              const { id, purchaseOrderIdentifiers, onTime } = appointment;
              return [
                <Link to={`/appointments/${id}`}>
                  {purchaseOrderIdentifiers}
                </Link>,
                onTime,
              ];
            })}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SchedulerModal;
