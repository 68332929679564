import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { LabelXSmall } from 'baseui/typography'
import { ButtonGroup as BaseButtonGroup, SIZE } from 'baseui/button-group'
import VButton from 'components/ui/generic/Button'
import RadioFiller from 'components/ui/generic/Radio'

type ButtonGroupProps = {
  buttons: {
    label: string
    value: string
    icon: React.ReactNode
  }[]
  label: string
  mode?: 'checkbox' | 'radio'
  overrides?: any
  value?: string | string[]
  onChange?: (value: string | string[], string?) => void
  visibility?: boolean
  disabled?: boolean
  onSelectChange?: (value: string | string[]) => void
}

const CHECKBOX = 'checkbox'
const RADIO = 'radio'

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttons,
  label,
  mode = RADIO,
  overrides,
  value = mode === CHECKBOX ? [] : '',
  onChange,
  visibility = true,
  disabled = false
}) => {
  const [selectedButton, setSelectedButton] = useState<string | string[]>(value)

  const handleButtonSelect = (selectedValue: string) => {
    if (mode === CHECKBOX) {
      const arrayPrev = Array.isArray(selectedButton) ? selectedButton : [selectedButton]
      const isSelected = arrayPrev.includes(selectedValue)
      const newSelected = isSelected
        ? arrayPrev.filter(item => item !== selectedValue)
        : [...arrayPrev, selectedValue]
      onChange?.(newSelected)
      setSelectedButton(newSelected)
    } else {
      setSelectedButton(selectedValue)
      onChange?.(selectedValue)
    }
  }

  useEffect(() => {
    if (value && ((mode === CHECKBOX && value.length > 0) || mode === RADIO)) {
      setSelectedButton(value)
    }
  }, [value])

  // Memoize the RadioFiller component to prevent unnecessary re-renders
  const MemoizedRadioFiller = React.memo(({ checked }: { checked: boolean }) => (
    <RadioFiller checked={checked} />
  ))

  return (
    <>
      <LabelXSmall
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              fontSize: '11px',
              color: $theme.colors.text,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '12px',
              opacity: 0.6,
              marginBottom: '12px',
              visibility: visibility ? 'visible' : 'hidden'
            })
          }
        }}>
        {label}
      </LabelXSmall>
      <BaseButtonGroup
        disabled={disabled}
        mode={mode}
        selected={
          mode === CHECKBOX
            ? buttons
                .filter(button => (selectedButton as string[]).includes(button.value))
                .map(button => buttons.indexOf(button))
            : buttons.findIndex(button => button.value === selectedButton)
        }
        size={SIZE.mini}
        overrides={{
          Root: {
            style: {
              marginBottom: '16px',
              visibility: visibility ? 'visible' : 'hidden'
            }
          },
          ...overrides
        }}>
        {buttons.map(button => (
          <VButton
            key={button.value}
            startEnhancer={button.icon}
            endEnhancer={
              <MemoizedRadioFiller
                checked={
                  mode === CHECKBOX
                    ? (selectedButton as string[]).includes(button.value)
                    : selectedButton === button.value
                }
              />
            }
            onClick={() => handleButtonSelect(button.value)}
            overrides={{
              BaseButton: {
                style: {
                  marginRight: '8px'
                }
              },
              ...(overrides?.Root?.style || {})
            }}>
            {button.label}
          </VButton>
        ))}
      </BaseButtonGroup>
    </>
  )
}

export default ButtonGroup
