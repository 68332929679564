import { Block } from 'baseui/block'
import { DriversCheckinContext } from 'components/contexts/drivers-checkin.context'
import React, { useContext } from 'react'
import { Navbar } from '../DriversNavbar'
import LogoHeader from 'components/ui/specific/LogoHeader'
import { HeadingXSmall, ParagraphXSmall } from 'baseui/typography'
import { CaretLeft, CheckFat } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'
import { AppointmentCard } from 'components/components/AppointmentCard'
import { useHistory } from 'react-router-dom'
import {
  DriverConfirmationBody,
  DriverConfirmationIcon,
  DriverConfirmationTitle,
  DynamicLogo,
  PostCheckinMessage
} from './DriversCheckinConfirmation.styled'
import { DROP } from 'components/constants/handling_method'
import { INBOUND_FLAG, OUTBOUND_FLAG } from 'components/constants/appointment_types'
import AppointmentArrivalsCard from 'components/components/AppointmentCard/AppointmentArrivalsCard'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Appointment } from 'components/appointments/types'

const DriversCheckinConfirmation = () => {
  const { state, actions } = useContext(DriversCheckinContext)
  const { checkinForm, shipper, searchAppointment } = state
  const { clearSearch } = actions
  const { t } = useTranslation()
  const history = useHistory()

  const getConfirmationMessage = appointment => {
    if (
      appointment?.handlingMethod === DROP &&
      typeof appointment?.appointmentType == 'object' &&
      (appointment?.trailer?.dock || appointment?.trailer?.slot)
    ) {
      return appointment?.appointmentType?.type === INBOUND_FLAG
        ? t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.MessageDrop')
        : t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.MessagePick')
    }

    return ''
  }

  const getSpot = appointment => {
    return (
      <>
        {appointment.trailer?.dock?.id && (
          <span style={{ fontWeight: 700 }}>
            {t('Common.Fields.Dock.Text')}, {appointment.trailer?.dock?.name || ''}
          </span>
        )}
        {appointment.trailer?.slot?.id && (
          <>
            {t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.Zone')}{' '}
            <span style={{ fontWeight: 700 }}>{appointment.trailer?.slot?.zone || ''}, </span>
            {t('Common.Fields.Slot.Text')}{' '}
            <span style={{ fontWeight: 700 }}>{appointment.trailer?.slot?.name || ''}</span>
          </>
        )}
      </>
    )
  }

  const getDropAndHookConfirmationMessage = () => {
    const dropAppointment: any =
      getAppointmentType(checkinForm.appointment) === INBOUND_FLAG
        ? checkinForm.appointment
        : checkinForm.appointment.relatedAppointment
    const pickAppointment: any =
      getAppointmentType(checkinForm.appointment) === OUTBOUND_FLAG
        ? checkinForm.appointment
        : checkinForm.appointment.relatedAppointment

    if (
      (dropAppointment.trailer?.dock?.id || dropAppointment.trailer?.slot?.id) &&
      (pickAppointment.trailer?.dock?.id || pickAppointment.trailer?.slot?.id)
    ) {
      return (
        <>
          {t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccess.Message')}
          {t(
            'DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.MessageDropHook.Drop'
          )}
          {getSpot(dropAppointment)}
          {t(
            'DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.MessageDropHook.Trailer'
          )}
          <span style={{ fontWeight: 700 }}>{pickAppointment.trailer?.trailerNumber || ''}</span>
          {t(
            'DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.MessageDropHook.From'
          )}
          {getSpot(pickAppointment)}
          {t(
            'DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.MessageDropHook.Pick'
          )}
        </>
      )
    }

    if (dropAppointment.trailer?.dock?.id || dropAppointment.trailer?.slot?.id) {
      return (
        <>
          {t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccess.Message')}
          {getConfirmationMessage(dropAppointment)}
          {getSpot(dropAppointment)}
        </>
      )
    }

    if (pickAppointment.trailer?.dock?.id || pickAppointment.trailer?.slot?.id) {
      return (
        <>
          {t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccess.Message')}
          {getConfirmationMessage(pickAppointment)}
          {getSpot(pickAppointment)}
        </>
      )
    }

    return t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccess.Message')
  }

  const getAppointmentType = (appointment: Appointment) => {
    return typeof appointment?.appointmentType == 'object' && appointment?.appointmentType?.type
  }

  return (
    <Block minWidth="94%" padding="scale600">
      <Navbar />
      <LogoHeader
        logo={
          shipper?.activeLogoUrl?.length > 0 ? (
            <DynamicLogo src={shipper?.activeLogoUrl} />
          ) : (
            <HeadingXSmall>{shipper.name}</HeadingXSmall>
          )
        }
      />
      <Block
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        maxWidth="670px"
        margin="auto"
        width="100%"
        padding="24px 32px">
        <Block marginBottom="scale1000">
          <DriverConfirmationIcon>
            <CheckFat size={40} />
          </DriverConfirmationIcon>
        </Block>
        <Block display="flex" flexDirection="column" justifyContent="flex-start">
          <DriverConfirmationTitle>
            {t('DriversCheckin.DriversCheckinConfirmation.LogoHeader.Welcome', {
              shipper: shipper?.name || ''
            })}
          </DriverConfirmationTitle>

          {searchAppointment?.selectedFacility?.appointmentPreference?.postCheckinDriverMessage && (
            <PostCheckinMessage
              dangerouslySetInnerHTML={{
                __html:
                  searchAppointment?.selectedFacility?.appointmentPreference
                    ?.postCheckinDriverMessage
              }}
            />
          )}

          {!checkinForm?.appointment?.relatedAppointment && (
            <>
              <DriverConfirmationBody>
                {t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccess.Message')}
                {getConfirmationMessage(checkinForm?.appointment)}
                {checkinForm?.appointment?.handlingMethod === DROP &&
                  getSpot(checkinForm?.appointment)}
              </DriverConfirmationBody>
              <Block marginTop="scale800">
                <Block maxWidth={['99%']}>
                  {checkinForm.appointment && (
                    <AppointmentCard appointment={checkinForm.appointment} />
                  )}
                </Block>
              </Block>
            </>
          )}
          {checkinForm?.appointment?.relatedAppointment && (
            <>
              <DriverConfirmationBody>{getDropAndHookConfirmationMessage()}</DriverConfirmationBody>
              <FlexGrid
                flexGridColumnCount={2}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800">
                <FlexGridItem>
                  <Block>
                    <ParagraphXSmall>
                      {getAppointmentType(checkinForm?.appointment) === INBOUND_FLAG
                        ? t(
                            'DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.DropTrailer'
                          )
                        : t(
                            'DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.PickupTrailer'
                          )}
                    </ParagraphXSmall>
                  </Block>
                  {checkinForm.appointment && (
                    <AppointmentArrivalsCard appointment={checkinForm.appointment} />
                  )}
                </FlexGridItem>
                <FlexGridItem>
                  <Block>
                    <ParagraphXSmall>
                      {getAppointmentType(checkinForm?.appointment?.relatedAppointment) ===
                      INBOUND_FLAG
                        ? t(
                            'DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.DropTrailer'
                          )
                        : t(
                            'DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.PickupTrailer'
                          )}
                    </ParagraphXSmall>
                  </Block>
                  {checkinForm.appointment.relatedAppointment && (
                    <AppointmentArrivalsCard
                      appointment={checkinForm.appointment.relatedAppointment}
                    />
                  )}
                </FlexGridItem>
              </FlexGrid>
            </>
          )}
          <Block
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="center"
            gridGap="scale500"
            marginTop="scale600">
            <Button
              startEnhancer={<CaretLeft size={16} />}
              onClick={() => {
                clearSearch()
                history.push(`/pass`)
              }}>
              {t('DriversCheckin.DriversCheckinConfirmation.BackToHome.Text')}
            </Button>
            {checkinForm.requirePostCheckin && (
              <Button
                kind="primary"
                onClick={() => {
                  checkinForm.appointment.id &&
                    history.push(`/pass/${checkinForm.appointment.id}/postcheckin`)
                }}>
                {t('DriversCheckin.DriversCheckinConfirmation.MoreInformationButton.Text')}
              </Button>
            )}
          </Block>
        </Block>
      </Block>
    </Block>
  )
}

export default DriversCheckinConfirmation
