import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeadingXSmall } from 'baseui/typography'
import { Block } from 'baseui/block'

const EmptyMessageForTable = () => {
  const { t } = useTranslation()

  return (
    <Block display="flex" justifyContent="center" alignItems="center">
      <HeadingXSmall>{t('Common.Fields.NoData.Text')}</HeadingXSmall>
    </Block>
  )
}

export default EmptyMessageForTable
