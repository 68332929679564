import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { useStyletron } from 'baseui'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import Checkbox from 'components/ui/generic/Checkbox'
import { Block } from 'baseui/block'
import { StyledSpinnerNext } from 'baseui/spinner'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { calendarTranslations } from 'translations/calendar-languages'
import { useTranslation } from 'react-i18next'
import { upperFirst } from 'lodash'
import { Day } from 'components/shared/schedule-form'
import i18n from 'translations/i18n'
import Header from 'components/ui/generic/Header'
import { facilityService, appointmentPreferencesService } from '../../services'
import StyledSpinner from '../../shared/styled-spinner'
import { fancyToast } from '../../utils'
import { StyledLink } from 'baseui/link'
import { CaretLeft } from '@phosphor-icons/react'

const OPEN_DURATION = 60
const DEFAULT_DURATION = 30
const WeeklySchedule = ({ match }) => {
  const [css, theme] = useStyletron()
  const [loading, setLoading] = useState(false)
  const [facility, setFacility] = useState(null)
  const [appointmentPreference, setAppointmentPreference] = useState(null)
  const [events, setEvents] = useState([])
  const { t } = useTranslation()

  const DAYS: Day[] = [
    { id: 0, name: t('Common.Days.Sunday.Text') },
    { id: 1, name: t('Common.Days.Monday.Text') },
    { id: 2, name: t('Common.Days.Tuesday.Text') },
    { id: 3, name: t('Common.Days.Wednesday.Text') },
    { id: 4, name: t('Common.Days.Thursday.Text') },
    { id: 5, name: t('Common.Days.Friday.Text') },
    { id: 6, name: t('Common.Days.Saturday.Text') }
  ]

  useEffect(() => {
    let isMounted = true

    const fetchFacility = () => {
      facilityService
        .getFacility(match.params.handle)
        .then(result => {
          const updatedResult = {
            ...result,
            openTime: result.openTime[0].id,
            closeTime: result.closeTime[0].id
          }
          if (isMounted) {
            setFacility(updatedResult)
          }
        })
        .catch(error => {
          console.error(
            t('Common.Errors.Interpolated.Text', {
              model: t('Common.ModelType.Facility.Text'),
              action: t('Common.Actions.Retrieved.Text')
            }),
            error
          )
        })
    }

    fetchFacility()

    return () => {
      isMounted = false
    }
  }, [match.params.handle])

  useEffect(() => {
    let isMounted = true

    if (facility) {
      appointmentPreferencesService
        .get(facility.appointmentPreferenceId)
        .then(([result, status]) => {
          if (isMounted) {
            setAppointmentPreference(result)
          }
        })
        .catch(error => {
          console.error(
            t('Common.Errors.Interpolated.Text', {
              model: t('Common.ModelType.AppointmentPreferences.Text'),
              action: t('Common.Actions.Retrieved.Text')
            }),
            error
          )
        })
    }

    return () => {
      isMounted = false
    }
  }, [facility])

  useEffect(() => {
    const formatEvents = () => {
      if (!facility || !appointmentPreference) {
        return null
      }

      return DAYS.map(({ id }) => {
        const baseDate = moment().startOf('week').add(id, 'days')
        const start = baseDate
          .clone()
          .add(moment(facility.openTime).valueOf(), 'milliseconds')
          .toDate()
        const end = baseDate
          .clone()
          .add(moment(facility.closeTime).valueOf(), 'milliseconds')
          .toDate()

        return {
          id,
          groupId: 'everyDay',
          title: 'Open',
          color: theme.colors.accent,
          start,
          end
        }
      })
    }

    const formattedEvents = formatEvents()

    if (formattedEvents) {
      setEvents(formattedEvents)
    }
  }, [facility, appointmentPreference, theme.colors.accent])

  const updateAppointmentPreference = daysOfWeek => {
    setLoading(true)

    appointmentPreferencesService
      .update(appointmentPreference.id, {
        appointmentPreference: { id: appointmentPreference.id, daysOfWeek }
      })
      .then(([result, status]) => {
        setLoading(false)

        if (![200, 304].includes(status)) {
          fancyToast(result, status)
        }
      })
      .catch(error => {
        console.error(t('Common.Errors.Updated.AppointmentPreferences.Single.Text'), error)
        setLoading(false)
      })
  }

  const updateFacility = (openTime, closeTime) => {
    setLoading(true)

    facilityService
      .updateFacility(facility.id, {
        facility: { id: facility.id, openTime, closeTime }
      })
      .then(([result, status]) => {
        setLoading(false)

        if (![200, 304].includes(status)) {
          fancyToast(result, status)
        }
      })
      .catch(error => {
        console.error(t('Common.Errors.Updated.Facilities.Single.Text'), error)
        setLoading(false)
      })
  }

  const selectDay = index => e => {
    const selectedDay = DAYS[index]
    const isChecked = e.currentTarget.checked

    const newDaysOfWeek = DAYS.filter(day => {
      const isDaySelected = day === selectedDay
      const isDayInPreference = appointmentPreference.daysOfWeek.includes(day.id)
      return (isDaySelected && isChecked) || (isDayInPreference && day !== selectedDay)
    }).map(day => day.id)

    setAppointmentPreference(prevPreference => ({
      ...prevPreference,
      daysOfWeek: newDaysOfWeek
    }))
    updateAppointmentPreference(newDaysOfWeek)
  }

  const eventChange = ({ event: { start, end } }) => {
    const startOfDay = moment(start).startOf('day')
    const currentStartTime = moment(start)
    const currentEndTime = moment(end)

    const openTime = currentStartTime.diff(startOfDay, 'milliseconds')
    const closeTime = currentEndTime.diff(startOfDay, 'milliseconds')

    setFacility(prevFacility => ({
      ...prevFacility,
      openTime: new Date(openTime).toISOString(),
      closeTime: new Date(closeTime).toISOString()
    }))
    updateFacility(new Date(openTime).toISOString(), new Date(closeTime).toISOString())
  }

  const renderEventContent = ({ event: { start, end } }) => {
    const appointmentDuration = facility?.appointmentDuration / 60 || DEFAULT_DURATION
    const diff = moment(end).diff(moment(start), 'minutes') / 30

    const contentItems = Array.from({ length: diff }, (_, i) => {
      const content =
        appointmentDuration === OPEN_DURATION ? (
          i % 2 === 0 ? (
            t('Facilities.WeeklySchedule.Event.Open.Text')
          ) : (
            <span>&nbsp;</span>
          )
        ) : (
          t('Facilities.WeeklySchedule.Event.Open.Text')
        )
      return <FlexGridItem key={i}>{content}</FlexGridItem>
    })

    return <FlexGrid height="100%">{contentItems}</FlexGrid>
  }

  const DayHeaderContent = ({ date }) => {
    return (
      appointmentPreference && (
        <Checkbox
          disabled={loading}
          name="day-selector"
          onChange={selectDay(date.getDay())}
          checked={appointmentPreference.daysOfWeek.includes(date.getDay())}
          label={upperFirst(date.toLocaleDateString(i18n.language, { weekday: 'long' }))}
        />
      )
    )
  }

  if (!facility || !appointmentPreference) {
    return <StyledSpinner />
  }

  return (
    <>
      <Header
        title={
          <div
            className={css({ display: 'flex', gap: theme.sizing.scale400, alignItems: 'center' })}>
            <StyledLink data-testid="button-back-facilities" href="/facilities">
              <CaretLeft />
            </StyledLink>{' '}
            {`${facility.name} ${t('Facilities.WeeklySchedule.Header.Text')}`}
          </div>
        }
      />

      <Block display="flex" alignItems="center" justifyContent="space-between">
        {loading && <StyledSpinnerNext />}
      </Block>
      <FullCalendar
        locale={calendarTranslations[i18n.language]}
        height="auto"
        windowResizeDelay={250}
        editable={!loading}
        initialView="timeGridWeek"
        plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
        headerToolbar={false}
        allDaySlot={false}
        eventContent={renderEventContent}
        slotLabelFormat={{
          hourCycle: 'h23',
          hour: '2-digit',
          minute: '2-digit'
        }}
        dayHeaderContent={DayHeaderContent}
        eventResize={eventChange}
        eventDrop={eventChange}
        events={events.filter(({ id }) => {
          return appointmentPreference.daysOfWeek.indexOf(id) > -1
        })}
      />
    </>
  )
}

export default WeeklySchedule
