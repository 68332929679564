import React, { useEffect } from 'react'
import { MagnifyingGlass, CircleNotch } from '@phosphor-icons/react'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'

import { AddButton } from 'components/components/AddButton'
import useDebounce from 'components/hooks/use-debounce'
import Input from 'components/ui/generic/Input'
import { useCarriersContext } from 'components/contexts/carriers.context'

export const CarriersInputSearch = () => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const [value, setValue] = useDebounce()
  const {
    carriers: { isModalActive, loading },
    actions: { search, setModalType, setCarrier }
  } = useCarriersContext()
  const handleAddButton = () => {
    setCarrier(null)
    setModalType('Create')
  }

  useEffect(() => {
    search(value)
  }, [value])

  useEffect(() => {
    !isModalActive ? search(value) : null
  }, [isModalActive])

  return (
    <div
      className={css({ display: 'flex', gap: theme.sizing.scale800 })}
      data-testid="carriers-search-bar">
      <Input
        startEnhancer={
          loading ? (
            <CircleNotch
              className={css({
                animationDuration: '800ms',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'linear',
                animationName: {
                  from: {
                    transform: 'rotate(0deg)'
                  },
                  to: {
                    transform: 'rotate(360deg)'
                  }
                }
              })}
              size={theme.sizing.scale600}
            />
          ) : (
            <MagnifyingGlass />
          )
        }
        placeholder={t('Settings.Carriers.Search.Placeholder.Text')}
        autoComplete="off"
        name="search[name]"
        onChange={evt => {
          setValue(evt.currentTarget.value)
        }}
      />
      <AddButton
        title={t('Settings.Carriers.Search.CreateCarrier.Text')}
        onClick={handleAddButton}
      />
    </div>
  )
}
