import React from 'react'
import { useStyletron, withStyle } from 'baseui'
import { StyledBodyCell } from 'baseui/table-grid'

export const CiberStyledRow = ({ data, index, hoverIndex, setHoverIndex }) => {
  const [css, theme] = useStyletron()
  const CenteredBodyCell = withStyle(StyledBodyCell, {
    backgroundColor: hoverIndex === index ? theme.colors.mono200 : theme.colors.tableBackground,
    fontSize: theme.typography.LabelXSmall.fontSize,
    paddingTop: theme.sizing.scale400,
    paddingBottom: theme.sizing.scale400,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale600,
    verticalAlign: 'middle'
  } as any)

  return (
    <div
      role="row"
      onMouseEnter={evt => {
        evt.preventDefault()
        setHoverIndex(index)
      }}
      onMouseLeave={evt => {
        evt.preventDefault()
        setHoverIndex(null)
      }}
      className={css({
        display: 'contents'
      })}
      tabIndex={0}>
      {data.map((cell, i) => (
        <CenteredBodyCell key={i}>{!!cell ? cell : '-'}</CenteredBodyCell>
      ))}
    </div>
  )
}
