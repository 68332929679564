import React from 'react'
import { Block } from 'baseui/block'

const VCenterBlock = ({ children, ...props }) => {
  return (
    <Block
      width="100%"
      display="flex"
      height="100%"
      justifyContent="center"
      alignItems="center"
      {...props}>
      {children}
    </Block>
  )
}
export default VCenterBlock
