import React from 'react'
import { AppointmentProvider } from 'components/contexts/appointment-context'
import { CarriersProvider } from 'components/contexts/carriers.context'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import FacilityProvider from 'components/contexts/facility-context'
import UpdateAppointmentExternalForm from './UpdateAppointmentExternalForm'

const index = ({ match }) => (
  <CustomQuestionsProvider>
    <CarriersProvider>
      <AppointmentProvider>
        <FacilityProvider>
          <UpdateAppointmentExternalForm match={match} />
        </FacilityProvider>
      </AppointmentProvider>
    </CarriersProvider>
  </CustomQuestionsProvider>
)
export default index
