import { Block } from 'baseui/block'

import { ParagraphSmall } from 'baseui/typography'

import { Modal } from 'baseui/modal'
import StyledSpinner from 'components/shared/styled-spinner'
import { Plus } from '@phosphor-icons/react'
import React, { useCallback } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/specific/PrimaryButton'
import Header from 'components/ui/generic/Header'
import { Column } from './DroppableColumn'
import {
  CreateCustomCancelReasonButtonContainer,
  DraggableColumnGrid
} from './CustomCancelReasonsContainer.styled'
import { CustomCancelReasonForm } from './CustomCancelReasonForm'
import { useCustomCancelReasonsContext } from 'components/contexts/custom-cancel-reason.context'

export const CustomCancelReasonsContainer = () => {
  const {
    customCancelReason: { isFormOpen, selectedCustomCancelReason },
    columns: { available, selected },
    actions: { setFormOpen, onDragEnd, setSelectedCustomCancelReason }
  } = useCustomCancelReasonsContext()

  const { t } = useTranslation()

  const closeModal = useCallback(() => {
    setFormOpen(false)
    setSelectedCustomCancelReason(undefined)
  }, [])

  return (
    <>
      <Header
        title={t('Settings.CustomCancelReasons.Header.Text')}
        rightItems={[
          <CreateCustomCancelReasonButtonContainer>
            <Button
              data-testid={'cancel-reason-create-button'}
              onClick={() => {
                setFormOpen(true)
              }}
              startEnhancer={() => <Plus />}
              size="compact">
              {t('Settings.CustomCancelReasons.Buttons.NewCustomCancelReason.Text')}
            </Button>
          </CreateCustomCancelReasonButtonContainer>
        ]}
      />
      <ParagraphSmall margin="0 0 16px">
        {t('Settings.CustomCancelReasons.SubTitle.Text')}{' '}
      </ParagraphSmall>
      {false ? (
        <Block display="flex" justifyContent="center" alignItems="center">
          <StyledSpinner />
        </Block>
      ) : (
        <DragDropContext onDragEnd={onDragEnd} margin="0">
          <DraggableColumnGrid>
            <Column col={available} key={available.id} />
            <Column col={selected} key={selected.id} />
          </DraggableColumnGrid>
        </DragDropContext>
      )}
      <Modal isOpen={isFormOpen} onClose={closeModal} closeable animate autoFocus>
        <CustomCancelReasonForm id={selectedCustomCancelReason?.id} onClose={closeModal} />
      </Modal>
    </>
  )
}
