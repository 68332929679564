import { useStyletron } from 'baseui'
import { EquipmentTypesProvider } from 'components/contexts/equipment-types.context'
import React, { createContext } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import AppointmentTypeToggle from './appointment-type-toggle'
import PrintButton from './print-button'

export const SearchStatusContext = createContext(null)

const NonConnectedQuickActions = ({ isSearchStalled, selectedDate }) => {
  const [css, theme] = useStyletron()

  return (
    <EquipmentTypesProvider>
      <SearchStatusContext.Provider value={{ isSearchStalled }}>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            gap: theme.sizing.scale400,
            [theme.mediaQuery.medium]: {
              gap: theme.sizing.scale600
            }
          })}>
          <AppointmentTypeToggle />
          <PrintButton selectedDate={selectedDate} />
        </div>
      </SearchStatusContext.Provider>
    </EquipmentTypesProvider>
  )
}

// If we don't have a searchbox, then typesense gets confused
const QuickActions = connectSearchBox(NonConnectedQuickActions) as any

export default QuickActions
