import { Paragraph1 } from 'baseui/typography'
import React, { useContext } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Calendar } from 'baseui/datepicker'
import { HeadingLevel, Heading } from 'baseui/heading'
import { FormControl } from 'baseui/form-control'
import SchedulerPicker from '../toggles/scheduler-picker'
import MiniCalendar from '../toggles/mini-calendar'
import EventTypes from '../toggles/event-type'
import SelectedView from '../toggles/selected-view'
import TimeRange from '../toggles/time-range'
import FacilityPicker from '../toggles/facility-picker'
import ExternalEvents from '../toggles/external-events'
import { useTranslation } from 'react-i18next'

const SideCalendarNav = ({ open }) => {
  const { t } = useTranslation()

  return (
    <FlexGrid
      flexGridRowGap="scale100"
      top="-31.25px"
      position="sticky"
      width="250px"
      display={open ? 'flex' : 'none'}
      overrides={{
        Block: {
          style: ({ $theme }) => {
            return { background: $theme.colors.mono100 }
          }
        }
      }}>
      <FlexGridItem height="320px">
        <MiniCalendar />
      </FlexGridItem>
      {/* <FlexGridItem>
        <FormControl label="Event Types">
          <EventTypes />
        </FormControl>
      </FlexGridItem> */}
      <FlexGridItem>
        <FormControl label="View">
          <SelectedView />
        </FormControl>
      </FlexGridItem>
      <FlexGridItem>
        <FormControl label={t('Common.FormControl.Titles.TimeRange.Text')}>
          <TimeRange />
        </FormControl>
      </FlexGridItem>
      {/* <FlexGridItem>
        <ExternalEvents />
      </FlexGridItem>*/}
      <FlexGridItem>
        <SchedulerPicker />
      </FlexGridItem>
      {/*  <FlexGridItem paddingBottom="scale200">
        <FacilityPicker />
      </FlexGridItem> */}
    </FlexGrid>
  )
}

export default SideCalendarNav
