import React from 'react'
import { Card, StyledBody, StyledAction } from 'baseui/card'

const VCard = ({ children, actions = null }) => {
  return (
    <Card
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.white,
            borderTopColor: $theme.colors.gray,
            borderBottomColor: $theme.colors.gray,
            borderRightColor: $theme.colors.gray,
            borderLeftColor: $theme.colors.gray,
            borderTopWidth: $theme.borders.borderWidth,
            borderBottomWidth: $theme.borders.borderWidth,
            borderRightWidth: $theme.borders.borderWidth,
            borderLeftWidth: $theme.borders.borderWidth,
            marginBottom: 0
          })
        }
      }}>
      <StyledBody>{children}</StyledBody>
      <StyledAction>{actions}</StyledAction>
    </Card>
  )
}
export default VCard
