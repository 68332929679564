import React, { useEffect, useState } from 'react'
import {
  AppTypeKeys,
  FIXED_DOCK_DOORS_COLUMN,
  useYardScheduleContext
} from '../../contexts/yard-schedule.context'
import { useTranslation } from 'react-i18next'
import { Block } from 'baseui/block'
import Select from 'components/ui/generic/Select'
import { facilityService } from '../../services/facility.service'
import { Option } from '../../shared/types/Option'

const YardScheduleDropModalSlotDockSelect = () => {
  const {
    selectedFacility,
    selectedAppType,
    dropModalSelectedArea,
    dropModalSelectedSlot,
    dropModalToZoneName,
    dropModalAreaOptions,
    actions: { setDropModalAreaOptions, setDropModalSelectedArea, setDropModalSelectedSlot }
  } = useYardScheduleContext()

  const { t } = useTranslation()

  const [areaOptions, setAreaOptions] = useState<Option<string>[]>([{ value: '', label: '' }])
  const [slotOptions, setSlotOptions] = useState<Option<string>[]>([{ value: '', label: '' }])

  useEffect(() => {
    if (selectedFacility) {
      facilityService
        .getAvailableSlotsAndDocks(
          selectedFacility.id,
          selectedAppType.value === AppTypeKeys.ALL ? null : selectedAppType.value
        )
        .then(([{ emptySlots, emptyDocks }, status]) => {
          if ([304, 200].includes(status)) {
            const areaOptionsTemp = Object.keys(emptySlots).map((area: string) => ({
              value: area,
              label: area
            }))

            areaOptionsTemp.push({
              value: FIXED_DOCK_DOORS_COLUMN.title,
              label: FIXED_DOCK_DOORS_COLUMN.title
            })

            setAreaOptions(areaOptionsTemp)

            setDropModalSelectedArea(
              areaOptionsTemp.filter(aot => aot.value === dropModalToZoneName)[0]
            )

            const slotOptionsTemp =
              dropModalToZoneName === FIXED_DOCK_DOORS_COLUMN.title
                ? emptyDocks.map(d => ({
                    value: d.id,
                    label: d.name
                  }))
                : emptySlots[dropModalToZoneName].map(s => ({
                    value: s.id,
                    label: s.name
                  }))

            setSlotOptions(slotOptionsTemp)

            setDropModalSelectedSlot(slotOptionsTemp[0])

            setDropModalAreaOptions({
              ...emptySlots,
              [FIXED_DOCK_DOORS_COLUMN.title]: [...emptyDocks]
            })
          }
        })
    }
  }, [])

  const onChangeArea = params => {
    const slotOptionsTemp = dropModalAreaOptions[params?.value[0].value].map(a => ({
      value: a.id,
      label: a.name
    }))

    setSlotOptions(slotOptionsTemp)
    setDropModalSelectedSlot(slotOptionsTemp[0])
    setDropModalSelectedArea(params?.value[0])
  }

  return (
    <Block
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginTop="24px"
      width="100%">
      <Select
        clearable={false}
        searchable={false}
        valueKey="value"
        options={areaOptions}
        value={dropModalSelectedArea}
        onChange={params => onChangeArea(params)}
        label={t('Common.Fields.To.Text')}
        placeholder=""
        marginRight="24px"
      />
      <Select
        clearable={false}
        searchable={false}
        valueKey="value"
        options={slotOptions}
        value={dropModalSelectedSlot}
        placeholder=""
        onChange={params => setDropModalSelectedSlot(params?.value[0])}
        label={
          dropModalToZoneName === FIXED_DOCK_DOORS_COLUMN.title
            ? t('Common.Fields.Door.Text')
            : t('Common.Fields.Spot.Text')
        }
      />
    </Block>
  )
}

export default YardScheduleDropModalSlotDockSelect
