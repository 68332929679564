import React, { useState } from 'react'
import { ButtonGroup } from 'baseui/button-group'
import Button from 'components/ui/generic/Button'
import { Circle } from '@phosphor-icons/react'

const COLORS = ['#D44333', '#ED6E33', '#0C8564', '#276EF1', '#7356BF', '#FFC043']

const ColorPicker = ({ appointmentType, setAppointmentType }) => {
  if (!appointmentType) {
    return <></>
  }

  const [selected, setSelected] = useState<number>(COLORS.indexOf(appointmentType.color))

  return (
    <ButtonGroup
      kind="minimal"
      mode="checkbox"
      size="compact"
      onClick={(_event, index) => {
        setSelected(index)
        setAppointmentType({
          ...appointmentType,
          color: COLORS[index]
        })
      }}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            gap: $theme.sizing.scale100
          })
        }
      }}
      shape="circle"
      selected={selected}>
      {COLORS.map(color => {
        return (
          <Button
            overrides={{
              BaseButton: {
                style: { color, fontSize: '32px' }
              }
            }}
            key={color}>
            <Circle weight="fill" />
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

export default ColorPicker
