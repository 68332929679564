import { DEFAULT_SCHEDULER_PERMISSION } from 'components/constants/default-permissions'
import { DOCUMENT_TYPE } from 'components/models/Question'

const SET_ANSWERS = 'SET_ANSWERS'
const UPDATE_ANSWERS = 'UPDATE_ANSWERS'
const SET_QUESTIONS = 'SET_QUESTIONS'
const UPDATE_QUESTION = 'UPDATE_QUESTION'
const SET_TEMPORAL_FILES = 'SET_TEMPORAL_FILES'
const REMOVE_TEMPORAL_FILES = 'REMOVE_TEMPORAL_FILES'
const RESET_TEMPORAL_FILES = 'RESET_TEMPORAL_FILES'
const FILE_UPLOAD_STATE = 'FILE_UPLOAD_STATE'
const UPDATE_TEMPORAL_FILES_STATE = 'UPDATE_TEMPORAL_FILES_STATE'
const ADD_FILE_SELECTION = 'ADD_FILE_SELECTION'

export const answersReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_ANSWERS: {
      const answers = payload
      return answers
    }
    case UPDATE_ANSWERS: {
      let answers = [...state]

      answers = answers.map(answer => {
        if (answer.questionId === payload.questionId) {
          return { ...answer, ...payload }
        }
        return { ...answer }
      })

      return answers
    }
    default:
      return state
  }
}

export const questionsReducer = (state, action) => {
  switch (action.type) {
    case SET_QUESTIONS: {
      const questions = action.payload
      return questions
    }
    case UPDATE_QUESTION: {
      const questions = [...state]
      const { questionId, response, appointmentId } = action.payload
      const newQuestions = questions.map(question => {
        if (question.id === questionId) {
          return {
            ...question,
            response,
            questionId,
            appointmentId,
            documents: question.documents,
            answerId: question.answerId
          }
        }
        return {
          ...question
        }
      })

      return newQuestions
    }
    default:
      return state
  }
}

export const temporalFilesReducer = (state, action) => {
  switch (action.type) {
    case SET_TEMPORAL_FILES: {
      let temporalFiles = [...state]
      const { questionId, files } = action.payload

      const activeId = temporalFiles.find(tf => {
        if (tf?.questionId === action.payload.questionId) {
          return true
        }
        return false
      })
      const arrayAddIds = array => {
        array.forEach(item => {
          item.id = ('' + Math.random()).substring(2, 7)
          item.isHidden = action?.payload?.isHidden
        })
        return array
      }
      if (action?.payload?.questionId && !activeId) {
        temporalFiles.push({ questionId, files: arrayAddIds(files), uploadState: 'pending' })
      }

      if (activeId) {
        temporalFiles = temporalFiles.map(tf => {
          if (tf?.questionId === questionId) {
            return { ...tf, files: arrayAddIds([...files, ...tf?.files]) }
          }
          return { ...tf, files: arrayAddIds(tf?.files) }
        })
      }

      return temporalFiles
    }
    case REMOVE_TEMPORAL_FILES: {
      let temporalFiles = [...state]
      const { questionId, fileId } = action.payload

      if (questionId) {
        temporalFiles = temporalFiles.map(tf => {
          if (tf?.questionId === questionId) {
            return {
              ...tf,
              files: tf.files.filter(f => f.id !== fileId)
            }
          }
          return { ...tf }
        })
      }
      return temporalFiles
    }
    case RESET_TEMPORAL_FILES: {
      return []
    }
    case UPDATE_TEMPORAL_FILES_STATE: {
      let temporalFiles = [...state]
      const { questionId } = action.payload

      if (questionId) {
        temporalFiles = temporalFiles.map(tf => {
          if (tf?.questionId === questionId) {
            return {
              ...tf,
              uploadState: 'saved'
            }
          }
          return { ...tf }
        })
      }

      return temporalFiles
    }
    default:
      return state
  }
}

export const settingsReducer = (state, action) => {
  switch (action.type) {
    case FILE_UPLOAD_STATE: {
      let settings = { startFileUpload: undefined }
      settings.startFileUpload = action?.payload
      return settings
    }
    default:
      return state
  }
}

export const documentSelectionsReducer = (state, action) => {
  switch (action.type) {
    case ADD_FILE_SELECTION: {
      const docSelections = [...state]
      const { documentId, questionId } = action.payload
      const activeId = docSelections.find(tf => {
        if (tf?.documentId === documentId && tf?.questionId === questionId) {
          return true
        }
        return false
      })

      if (action.payload && !activeId) {
        docSelections.push(action.payload)
      }
      return docSelections
    }

    default:
      return state
  }
}

const calculateIsDisabled = (questions, answers, temporalFiles, documentSelections) => {
  const promptedQuestionsIndexes = questions.reduce((acc, question, index) => {
    const permission = question?.questionPermissionsAttributes[0] || DEFAULT_SCHEDULER_PERMISSION
    if (!question.disabled && permission.canEdit && permission.showOnCreate) {
      acc.push(index)
    }
    return acc
  }, [])

  const hasErrors = answers.some(
    (answer, index) => promptedQuestionsIndexes.includes(index) && answer.error
  )

  const allRequiredSatisfied = questions.every((question, index) => {
    const permission = question.questionPermissionsAttributes[0] || DEFAULT_SCHEDULER_PERMISSION
    if (permission?.required && question.answerType === DOCUMENT_TYPE) {
      const answerTemporalFiles = temporalFiles.filter(tf => tf?.questionId === question.id)
      return answerTemporalFiles.some(tf => tf.files && tf.files.length > 0)
    }
    return true
  })

  return hasErrors || !allRequiredSatisfied
}

const finalCustomQuestionsReducer = (state, action) => {
  // Run all individual reducers
  const updatedState = {
    answers: answersReducer(state.answers, action),
    questions: questionsReducer(state.questions, action),
    temporalFiles: temporalFilesReducer(state.temporalFiles, action),
    settings: settingsReducer(state.settings, action),
    documentSelections: documentSelectionsReducer(state.documentSelections, action)
  }

  // Compute the isDisabled state
  const isDisabled = calculateIsDisabled(
    updatedState.questions,
    updatedState.answers,
    updatedState.temporalFiles,
    updatedState.documentSelections
  )

  // Return the new state with the updated isDisabled flag
  return {
    ...updatedState,
    isDisabled
  }
}

export default finalCustomQuestionsReducer
