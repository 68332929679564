import { styled } from 'baseui'

export const DragZoneContainer = styled('div', ({ $theme }) => ({
  width: '100%',
  minHeight: '300px',
  margin: 0,
  padding: '24px',
  borderRadius: '24px',
  backgroundColor: '#fff',
  boxSizing: 'border-box'
}))
