import { BROKER, CARRIER, SCHEDULER, SHIPPER } from 'components/models/User'
import { useTranslation } from 'react-i18next'

import type * as React from 'react'
import { YARD_JOCKEY_AUDIENCE } from '../../models/Role'
import { NavbarLinksURLs, NavBarRolesPermissions } from 'components/constants/navbar-Links'

interface NavbarLinkProps {
  label: string
  info: object
  active?: boolean
  icon?: React.ComponentType<any>
  disable?: boolean
  tooltipMessage?: string
  roles?: string[]
}

const isOptionActive = (path: string, exact?: boolean) =>
  exact
    ? window.location.pathname === path || window.location.pathname === `/${path}`
    : window.location.pathname.indexOf(path) > 0 || window.location.pathname === path

const NavbarLinks = (currentUser): NavbarLinkProps[] => {
  const { t } = useTranslation()

  const navBarValidations = {
    openScheduling: !currentUser,
    shipper: currentUser?.name && currentUser?.userType === SHIPPER && !currentUser?.viewOnly,
    hasExternalAppointments: currentUser?.hasExternalAppointments,
    scheduler: [BROKER, CARRIER, SCHEDULER].includes(currentUser?.userType),
    viewOnly: currentUser?.viewOnly,
    yardJockey: [YARD_JOCKEY_AUDIENCE].includes(currentUser?.userType)
  }

  const navBarItems: NavbarLinkProps[] = [
    {
      info: { itemId: `/${NavbarLinksURLs.Openscheduling}` },
      label: t('HeaderNavigation.NavBarLinks.CreateAppointment.Text'),
      active: isOptionActive(NavbarLinksURLs.Openscheduling, true),
      roles: [NavBarRolesPermissions.openScheduling]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.OpenschedulingUpdate}` },
      label: t('HeaderNavigation.NavBarLinks.UpdateAppointment.Text'),
      active: isOptionActive(NavbarLinksURLs.OpenschedulingUpdate),
      roles: [NavBarRolesPermissions.openScheduling]
    },
    {
      info: { itemId: NavbarLinksURLs.Home },
      label: t('HeaderNavigation.NavBarLinks.Exit.Text'),
      active: isOptionActive(NavbarLinksURLs.Home),
      roles: [NavBarRolesPermissions.openScheduling]
    },
    {
      info: { itemId: NavbarLinksURLs.Home },
      label: t('HeaderNavigation.NavBarLinks.Home.Text'),
      active: isOptionActive(NavbarLinksURLs.Home),
      roles: [NavBarRolesPermissions.shipper, NavBarRolesPermissions.viewOnly]
    },
    {
      info: { itemId: NavbarLinksURLs.Home },
      label: t('HeaderNavigation.NavBarLinks.CreateAppointment.Text'),
      active: isOptionActive(NavbarLinksURLs.Home),
      roles: [NavBarRolesPermissions.scheduler]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.ExternalAppointments}` },
      label: t('HeaderNavigation.NavBarLinks.ExternalAppointments.Text'),
      active: isOptionActive(NavbarLinksURLs.ExternalAppointments),
      roles: [NavBarRolesPermissions.hasExternalAppointments]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.Appointments}` },
      label: t('HeaderNavigation.NavBarLinks.UpdateAppointment.Text'),
      active: isOptionActive(NavbarLinksURLs.Appointments),
      roles: [NavBarRolesPermissions.scheduler]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.Appointments}` },
      label: t('HeaderNavigation.NavBarLinks.Appointments.Text'),
      active: isOptionActive(NavbarLinksURLs.Appointments),
      roles: [NavBarRolesPermissions.shipper, NavBarRolesPermissions.viewOnly]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.DockAssignment}` },
      label: t('HeaderNavigation.NavBarLinks.DockAssignment.Text'),
      active: isOptionActive(NavbarLinksURLs.DockAssignment),
      disable: !currentUser?.dockAssignmentActive,
      tooltipMessage: t('HeaderNavigation.NavBarLinks.DockAssignment.Tooltip'),
      roles: [NavBarRolesPermissions.shipper, NavBarRolesPermissions.viewOnly]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.Yard}` },
      label: t('HeaderNavigation.NavBarLinks.Yard.Text'),
      active: isOptionActive(NavbarLinksURLs.Yard),
      disable: !currentUser?.yardManagementActive,
      tooltipMessage: t('HeaderNavigation.NavBarLinks.Yard.Tooltip'),
      roles: [NavBarRolesPermissions.shipper, NavBarRolesPermissions.viewOnly]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.Analytics}` },
      label: t('HeaderNavigation.NavBarLinks.Analytics.Text'),
      active: isOptionActive(NavbarLinksURLs.Analytics),
      roles: [NavBarRolesPermissions.shipper, NavBarRolesPermissions.viewOnly]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.Facilities}` },
      label: t('HeaderNavigation.NavBarLinks.Facilities.Text'),
      active: isOptionActive(NavbarLinksURLs.Facilities),
      roles: [NavBarRolesPermissions.shipper]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.Orders}` },
      label: t('HeaderNavigation.NavBarLinks.Orders.Text'),
      active: isOptionActive(NavbarLinksURLs.Orders),
      roles: [NavBarRolesPermissions.shipper, NavBarRolesPermissions.viewOnly]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.SettingsShipper}` },
      label: t('HeaderNavigation.NavBarLinks.Settings.Text'),
      active: isOptionActive(NavbarLinksURLs.Settings),
      roles: [NavBarRolesPermissions.shipper]
    },
    {
      info: { itemId: `/${NavbarLinksURLs.User}/${currentUser?.id}` },
      label: t('HeaderNavigation.NavBarLinks.UserProfile.Text'),
      active: isOptionActive(NavbarLinksURLs.User),
      roles: [NavBarRolesPermissions.viewOnly, NavBarRolesPermissions.scheduler]
    }
  ]

  const links: NavbarLinkProps[] = navBarItems.filter(item =>
    item.roles.some(role => navBarValidations[role])
  )

  return links
}

export default NavbarLinks
