import { COUNTRIES } from 'baseui/phone-input'
import React, { useState } from 'react'
import PhoneInput from 'components/ui/generic/PhoneInput'

interface TwilioPhoneNumberProps {
  record: any
  setRecord: Function
  recordName?: string
  disabled?: boolean
}

const TwilioPhoneNumber = (props: TwilioPhoneNumberProps) => {
  const { record, setRecord, recordName, disabled } = props
  const [country, setCountry] = useState(COUNTRIES.US)

  return (
    <PhoneInput
      disabled={disabled}
      country={country}
      onCountryChange={(event: any) => {
        setCountry(event.option)
        setRecord({
          ...record,
          phone_number: ''
        })
      }}
      autoComplete="off"
      name={`${recordName || 'user'}[phone_number]`}
      text={record?.phone_number?.replace(country.dialCode, '') ?? ''}
      onTextChange={e => {
        setRecord({
          ...record,
          phone_number: `${country.dialCode}${e.currentTarget.value.replace(/\D/g, '')}`
        })
      }}
    />
  )
}

export default TwilioPhoneNumber
