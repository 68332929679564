import React, { Dispatch, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import useSearchClient from 'components/shared/hooks/use-search-client'
import Checkbox from 'components/ui/generic/Checkbox'

import { SelectSearch } from 'components/components/SelectSearch'

interface OrderValdationSectionProps {
  appointmentPreference: any
  setAppointmentPreference: Dispatch<any>
  disabled: boolean
}

const OrderValidationSection = ({
  appointmentPreference,
  setAppointmentPreference,
  disabled
}: OrderValdationSectionProps) => {
  const { t } = useTranslation()

  return (
    <div style={{ marginBottom: '10px', width: '100%' }}>
      <HeadingXSmall marginTop="0" marginBottom="scale800">
        {t('AppointmentPreferences.Form.Fields.OrderValidation.Header.Text')}
      </HeadingXSmall>
      <>
        <ParagraphSmall marginTop="0" marginBottom="scale800">
          {t('AppointmentPreferences.Form.Fields.OrderValidation.Subtitle.Validation')}
        </ParagraphSmall>
        <FlexGridItem>
          <FormControl>
            <Checkbox
              disabled={disabled}
              checked={appointmentPreference.validateOrderNumbersForExtUsers}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  validateOrderNumbersForExtUsers: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.OrderValidation.Fields.ValidateOrderNumbers.Label.Text'
              )}
            />
          </FormControl>
          <div
            style={{
              display: appointmentPreference.validateOrderNumbersForExtUsers ? 'block' : 'none'
            }}>
            <SelectSearch
              multi
              record={appointmentPreference}
              entity="schedulers"
              onValuesChange={ids =>
                setAppointmentPreference({
                  ...appointmentPreference,
                  schedulers: ids
                })
              }
            />
            <h6 style={{ marginBottom: '20px', marginTop: 0 }}>
              {t(
                'AppointmentPreferences.Form.Fields.OrderValidation.Fields.ValidateOrderNumbers.Caption.Text'
              )}
            </h6>
          </div>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl>
            <Checkbox
              disabled={disabled}
              checked={appointmentPreference.proceedWithoutMatchingOrder}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  proceedWithoutMatchingOrder: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.OrderValidation.Fields.AllowToProceedWithoutMatchingOrder.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl>
            <Checkbox
              disabled={disabled}
              checked={appointmentPreference.allowOpenOrdersOnly}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  allowOpenOrdersOnly: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.OrderValidation.Fields.OnlyAllowOpenOrders.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
      </>
      <>
        <ParagraphSmall marginTop="0" marginBottom="scale800">
          {t('AppointmentPreferences.Form.Fields.OrderValidation.Subtitle.Display')}
        </ParagraphSmall>
        <FormControl>
          <Checkbox
            disabled={disabled}
            checked={appointmentPreference.showQuantityOnAppointmentCreation}
            onChange={e => {
              setAppointmentPreference({
                ...appointmentPreference,
                showQuantityOnAppointmentCreation: e.currentTarget.checked
              })
            }}
            label={t(
              'AppointmentPreferences.Form.Fields.OrderValidation.Fields.DisplayQuantity.Label.Text'
            )}
          />
        </FormControl>
        <FormControl>
          <Checkbox
            disabled={disabled}
            checked={appointmentPreference.showWeightOnAppointmentCreation}
            onChange={e => {
              setAppointmentPreference({
                ...appointmentPreference,
                showWeightOnAppointmentCreation: e.currentTarget.checked
              })
            }}
            label={t(
              'AppointmentPreferences.Form.Fields.OrderValidation.Fields.DisplayWeight.Label.Text'
            )}
          />
        </FormControl>
        <FormControl>
          <Checkbox
            disabled={disabled}
            checked={appointmentPreference.showDeliveryInstructionsOnAppointmentCreation}
            onChange={e => {
              setAppointmentPreference({
                ...appointmentPreference,
                showDeliveryInstructionsOnAppointmentCreation: e.currentTarget.checked
              })
            }}
            label={t(
              'AppointmentPreferences.Form.Fields.OrderValidation.Fields.DisplayDeliveryInstructions.Label.Text'
            )}
          />
        </FormControl>
      </>
    </div>
  )
}

export default OrderValidationSection
