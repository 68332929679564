import React from 'react'
import i18n from 'translations/i18n'

export interface CustomLabels {
  purchaseOrderIdentifiers: string
  scheduler: string
  appTypes: string
}

export type CustomLabelKey = 'purchaseOrderIdentifiers' | 'scheduler' | 'appTypes'

export const INITIAL_CUSTOM_FIELD_NAMES: CustomLabels = {
  purchaseOrderIdentifiers: i18n.t('Common.Fields.PurchaseOrderIdentifiers.Text'),
  scheduler: i18n.t('Common.Fields.Scheduler.Text'),
  appTypes: i18n.t('Common.Fields.AppTypes.Text')
}

interface CustomLabelsContextProps {
  customLabels: CustomLabels
  setCustomLabels: (CustomLabels) => void
  customLabel: (customLabelKey: CustomLabelKey) => string
}

export const CustomLabelsContext = React.createContext<CustomLabelsContextProps>({
  customLabels: null,
  setCustomLabels: () => {},
  customLabel: () => ''
})
