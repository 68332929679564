import {
  CustomLabelKey,
  CustomLabelsContext,
  INITIAL_CUSTOM_FIELD_NAMES
} from 'components/contexts/custom-labels-context'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { upperFirst, camelCase } from 'lodash'
import FormControl from 'components/ui/generic/FormControl'

interface CustomLabelFormControlProps {
  customLabelKey: CustomLabelKey
  children: any
  recurring?: boolean
}

// In the future we can use the default custom fields values to hydrate the component. I'm assuming that the context
// exists for the sake of this exercise.
const CustomLabelFormControl = (props: CustomLabelFormControlProps) => {
  const { customLabelKey, children } = props
  const { customLabels } = useContext(CustomLabelsContext)
  const { t } = useTranslation()
  const label =
    customLabels?.[customLabelKey] ||
    t(`Common.Fields.${upperFirst(camelCase(customLabelKey))}.Text`)

  const caption = customLabels && customLabels[`${customLabelKey}Caption`]

  return (
    <FormControl label={`${props.recurring ? '' : '*'} ${label}`} caption={caption}>
      {children}
    </FormControl>
  )
}

export default CustomLabelFormControl
