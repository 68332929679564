import React from 'react'
import { styled } from 'baseui'
import { Plus } from '@phosphor-icons/react'

import { useTranslation } from 'react-i18next'

import Button from 'components/ui/specific/PrimaryButton'

export const Container = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '36px',
  width: '36px',
  borderRadius: '20px',
  backgroundColor: $theme.colors.primary,
  cursor: 'pointer',
  transition: $theme.animation.timing200,
  ':hover': {
    backgroundColor: $theme.colors.accent
  }
}))

type Props = {
  onClick?: (e: any) => void
  title?: string
}

export const AddButton = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Button data-testid="invite-shipper" IconStart={() => <Plus />} {...props}>
      {props.title ? props.title : t('Common.Button.Create.Text')}
    </Button>
  )
}
