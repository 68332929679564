import Input from 'components/ui/generic/Input'
import React, { FormEvent, ReactElement } from 'react'

interface TextTypeProps {
  value: string
  onChange: (e: FormEvent<HTMLInputElement>) => void
  disabled?: boolean
  error?: boolean
  prompt?: string
  label?: string
}

const TextType = ({
  value,
  onChange,
  disabled,
  error,
  prompt,
  label
}: TextTypeProps): ReactElement => (
  <Input
    onChange={onChange}
    value={value || ''}
    disabled={disabled}
    error={error ? error : undefined}
    name={prompt}
    label={label}
  />
)

export default TextType
