import { useStyletron, withStyle } from "baseui";
import { StyledBodyCell } from "baseui/table-grid";
import Pretag from "components/shared/pretag";
import TimeFormatter from "components/utils/time-formatter";
import moment from "moment";
import React, { useState } from "react";

const StyledRows = ({ appointments, index, hoverIndexes, setHoverIndexes }) => {
  const [css, theme] = useStyletron();

  return (
    <>
      {(appointments.sort((a, b) => {
        return moment(a.arrivalTime).diff(moment(b.arrivalTime));
      }) as Array<any>).map(
        (
          { arrivalTime, purchaseOrderIdentifiers, dockName },
          jindex: number
        ) => {
          const CenteredBodyCell = withStyle(StyledBodyCell, {
            display: "flex",
            alignItems: "center",
            backgroundColor:
              // some oddity of javascript prevents hoverIndexes == [index, jindex] from working
              index === hoverIndexes[0] && jindex === hoverIndexes[1]
                ? theme.colors.mono200
                : theme.colors.tableBackground,
          } as any);
          return (
            <div
              onMouseEnter={(e) => {
                e.preventDefault();
                setHoverIndexes([index, jindex]);
              }}
              onMouseLeave={(e) => {
                e.preventDefault();
                setHoverIndexes([null, null]);
              }}
              role="row"
              className={css({
                display: "contents",
              })}
            >
              <CenteredBodyCell>
                {new TimeFormatter("shortTime").format(arrivalTime)}
              </CenteredBodyCell>
              <CenteredBodyCell>{purchaseOrderIdentifiers}</CenteredBodyCell>
              <CenteredBodyCell>{dockName}</CenteredBodyCell>
            </div>
          );
        }
      )}
    </>
  );
};

export default StyledRows;
