import React, { useEffect, useMemo, useState } from 'react'
import { SHAPE } from 'baseui/button-group'
import { facilityService } from '../../services/facility.service'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'
import FormControl from 'components/ui/generic/FormControl'
import ButtonGroup from 'components/ui/generic/ButtonGroup'
import { DockCapacitiesAttributes } from 'components/models/AppointmentPreference'
import RadioFiller from '../../ui/generic/Radio'
import * as Icons from 'react-icons/fa'

import { Drop, Van, Truck, Snowflake, LadderSimple, BatteryFull } from '@phosphor-icons/react'
import { LabelXSmall } from 'baseui/typography'

interface EquipmentTypeWithCapacityProps {
  record: any
  setRecord: any
  dockCapacitiesAttributes: DockCapacitiesAttributes[]
  label: string
  recordAttributeName: string
  equipmentCapacityShared?: boolean
  openScheduling?: boolean
  disabled?: boolean
  onChange?: Function
  isInternal?: boolean
}

const DOCK_TYPE_ICON = {
  ['van']: Van,
  ['tanker']: Drop,
  ['flatbed']: Truck,
  ['reefer']: Snowflake,
  ['container']: BatteryFull,
  ['rail']: LadderSimple,
  ['Van (Floor Loaded)']: Van,
  ['Van (Pallet Loaded)']: Van,
  ['van_floor']: Van,
  ['van_pallet']: Van
}

const DEFAULT_ICON_NAME = 'FaPallet'

const EquipmentTypeWithCapacity = ({
  record,
  setRecord,
  dockCapacitiesAttributes,
  label,
  equipmentCapacityShared,
  openScheduling,
  isInternal,
  disabled = false
}: EquipmentTypeWithCapacityProps) => {
  const [selected, setSelected] = useState<number>(undefined)
  const [docks, setDocks] = useState([])
  const { t } = useTranslation()

  const handleChange = (_event, index) => {
    // the dockId might possible used in a future feature - dockId: dockId || record.dockId
    // it was used to set the record.dockId to the selected dockId when the equipment type is changed
    let dockId
    if (record.id) {
      dockId = (docks as any).filter(
        dock =>
          dock.equipmentTypes.filter(et => et.id === availableEqTypes[index]?.equipmentTypeId)[0]
      )[0]?.id
    }
    setSelected(index)
    setRecord({ ...availableEqTypes[index] })
  }

  const availableEqTypes = useMemo(
    () =>
      dockCapacitiesAttributes.filter(
        capacity =>
          capacity.appointmentTypeId ===
            (equipmentCapacityShared ? null : record.appointmentTypeId) &&
          (isInternal || capacity.limit > 0)
      ),
    [dockCapacitiesAttributes, equipmentCapacityShared, record.appointmentTypeId]
  )

  useEffect(() => {
    if (record.id) {
      facilityService.dockTypes(record.id, openScheduling).then(([json]) => {
        setDocks(json)
      })
    }
  }, [record.id, openScheduling])

  useEffect(() => {
    if (record.id) {
      setSelected(
        availableEqTypes.findIndex(element => element.equipmentTypeId === record.equipmentTypeId)
      )
    }
  }, [availableEqTypes, record.equipmentTypeId])

  return (
    <FormControl>
      <>
        <LabelXSmall
          overrides={{
            Block: {
              style: ({ $theme }) => ({
                fontSize: '11px',
                color: '#04112B',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '12px',
                opacity: 0.6
              })
            }
          }}>
          {label}
        </LabelXSmall>
        <ButtonGroup disabled={disabled} onClick={handleChange} selected={selected}>
          {availableEqTypes?.map((capacities, index: number) => {
            const IconComponent =
              DOCK_TYPE_ICON[capacities.equipmentType.name] ||
              Icons[capacities.equipmentType.icon] ||
              Icons[DEFAULT_ICON_NAME]
            return (
              <Button
                startEnhancer={<IconComponent size="16" />}
                key={index}
                endEnhancer={<RadioFiller checked={selected === index} />}>
                {t(capacities.equipmentType.name)}
              </Button>
            )
          })}
        </ButtonGroup>
      </>
    </FormControl>
  )
}

export default EquipmentTypeWithCapacity
