import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { COMPLETE_MODAL_BUTTON, useYardJockeyContext } from '../../contexts/yard-jockey.context'
import Button from 'components/ui/specific/PrimaryButton'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { taskService } from '../../services/task.service'
import { Block } from 'baseui/block'
import StyledSpinner from '../../shared/styled-spinner'
import {
  YardJockeyCompleteModalButtonStyles,
  YardJockeyCompleteModalHeader,
  YardJockeyCompleteModalLabel,
  YardJockeyCompleteModalProgressBarStyles,
  YardJockeyCompleteModalSlot,
  YardJockeyCompleteModalStyle,
  YardJockeyCompleteModalTitle,
  YardJockeyCompleteModalTrailerNumber
} from './YardJockey.styled'
import { primitives } from '../../homepage/custom-theme'
import { AppointmentCardSmall } from '../../components/AppointmentCard'
import { ArrowRight } from '@phosphor-icons/react'

const YardJockeyCompleteModal = () => {
  const { t } = useTranslation()
  const {
    taskInProgressId,
    completeModalOpen,
    actionModalButtonLoading,
    actions: { setCompleteModalOpen, handleButtonClick }
  } = useYardJockeyContext()

  const [selectedTask, setSelectedTask] = useState(null)
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (completeModalOpen) {
      taskService
        .getTaskAndAppointmentById(taskInProgressId)
        .then(([{ task, appointment }]) => {
          setSelectedTask(task)
          setSelectedAppointment(appointment)
        })
        .catch(console.error)
        .finally(() => setLoading(false))
    }
  }, [completeModalOpen])

  return (
    <Modal
      isOpen={completeModalOpen}
      unstable_ModalBackdropScroll
      onClose={() => setCompleteModalOpen(false)}
      overrides={YardJockeyCompleteModalStyle}>
      {loading ? (
        <Block display="flex" justifyContent="center" alignItems="center">
          <StyledSpinner />
        </Block>
      ) : (
        <Block>
          <ModalHeader>
            <Block display="flex" alignItems="start" justifyContent="center" flexDirection="column">
              <YardJockeyCompleteModalTrailerNumber>
                {selectedTask?.trailer?.number}
              </YardJockeyCompleteModalTrailerNumber>
              <YardJockeyCompleteModalTitle>
                {t('YardJockey.Modal.Complete.Title.Text', {
                  place: selectedTask?.toSlotId
                    ? t('YardJockey.Modal.Complete.Title.Slot')
                    : t('YardJockey.Modal.Complete.Title.DockDoor')
                })}
              </YardJockeyCompleteModalTitle>
              <Block
                display="flex"
                width="100%"
                height="3px"
                overflow="hidden"
                overrides={YardJockeyCompleteModalProgressBarStyles}>
                <Block width="80%" backgroundColor={primitives.accent} height="100%" />
                <Block width="20%" backgroundColor={primitives.gray} height="100%" />
              </Block>
            </Block>
          </ModalHeader>
          <ModalBody>
            <YardJockeyCompleteModalHeader>
              {t('YardJockey.Modal.Complete.Body.Header.Text')}
            </YardJockeyCompleteModalHeader>
            <Block display="flex" justifyContent="center" alignItems="center" marginTop="20px">
              <Block width="70%">
                <AppointmentCardSmall appointment={selectedAppointment} showAnswers={false} />
              </Block>
              <Block
                width="30%"
                flexDirection="column"
                display="flex"
                justifyContent="start"
                alignItems="start"
                height="150px"
                padding="0px 24px"
                gridGap="15px">
                <Block
                  display="flex"
                  flexDirection="column"
                  justifyContent="start"
                  alignItems="start">
                  <YardJockeyCompleteModalLabel>
                    {t('YardJockey.Modal.Complete.Body.From.Text')}
                  </YardJockeyCompleteModalLabel>
                  <YardJockeyCompleteModalSlot>
                    {selectedTask?.fromSlot?.name || selectedTask?.fromDock?.name}
                  </YardJockeyCompleteModalSlot>
                </Block>
                <Block>
                  <ArrowRight size="16px" />
                </Block>
                <Block
                  display="flex"
                  flexDirection="column"
                  justifyContent="start"
                  alignItems="start"
                  height="100%">
                  <YardJockeyCompleteModalLabel>
                    {t('YardJockey.Modal.Complete.Body.To.Text')}
                  </YardJockeyCompleteModalLabel>
                  <YardJockeyCompleteModalSlot>
                    {selectedTask?.toSlot?.name || selectedTask?.toDock?.name}
                  </YardJockeyCompleteModalSlot>
                </Block>
              </Block>
            </Block>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={actionModalButtonLoading}
              onClick={() => {
                handleButtonClick(
                  { ...selectedTask, completedAt: new Date() },
                  COMPLETE_MODAL_BUTTON
                )
                setSelectedTask({ ...selectedTask, completedAt: new Date() })
              }}
              overrides={YardJockeyCompleteModalButtonStyles}>
              {t('YardJockey.Modal.Complete.Body.CompleteButton.Text')}
            </Button>
          </ModalFooter>
        </Block>
      )}
    </Modal>
  )
}

export default YardJockeyCompleteModal
