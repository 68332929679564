import { FormControl } from "baseui/form-control";
import React from "react";
import { Select } from "baseui/select";

const AppointmentTagSelector = ({ tagList, setTagList }) => {
  return (
    <FormControl
      label="Tags"
      caption="Extra data you want associated to this appointment"
    >
      <Select
        id="appointment-tag-selector"
        overrides={{
          SelectArrow: {
            style: {
              display: "none",
            },
          },
          Input: {
            props: {
              name: "appointment[purchase_order_number]",
            },
          },
        }}
        placeholder=""
        noResultsMsg="Enter a tag and hit 'Enter'"
        backspaceRemoves
        onBlurResetsInput
        onCloseResetsInput
        onSelectResetsInput
        openOnClick
        creatable
        multi
        onChange={({ value, option, type }) => {
          if (type === "select") {
            setTagList([...tagList, { id: null, label: option.label }]);
          } else {
            setTagList(
              tagList.map((element) => {
                if (element === option) {
                  return { ...option, _destroy: true };
                }
                return element;
              })
            );
          }
        }}
        value={
          tagList &&
          tagList.filter(({ _destroy }) => {
            return !_destroy;
          })
        }
      />
    </FormControl>
  );
};

export default AppointmentTagSelector;
