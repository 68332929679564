export enum NavbarLinksURLs {
  Home = '/',
  DockAssignment = 'dock_assignments',
  ExternalAppointments = 'external_appointments',
  Appointments = 'appointments',
  Analytics = 'analytics',
  Facilities = 'facilities',
  Orders = 'orders',
  Settings = 'settings',
  Yard = 'yard',
  User = 'user',
  Openscheduling = 'openscheduling',
  OpenschedulingUpdate = 'openscheduling/update',
  SettingsShipper = 'settings/shipper'
}

export enum NavBarRolesPermissions {
  openScheduling = 'openScheduling',
  shipper = 'shipper',
  hasExternalAppointments = 'hasExternalAppointments',
  scheduler = 'scheduler',
  viewOnly = 'viewOnly',
  yardJockey = 'yardJockey'
}
