import React from 'react'

const formatQuestionLabel = (permission, prompt) => {
  if (permission?.required || permission === undefined) {
    return (
      <span>
        {`${prompt} `}
        <span style={{ color: 'red' }}>*</span>
      </span>
    )
  }
  return <span>{`${prompt}`}</span>
}

export default formatQuestionLabel
