import React from 'react'
import { PhoneInput } from 'baseui/phone-input'
import { SIZE } from 'baseui/input'

const VPhoneInput = ({ size = '', overrides, ...props }) => {
  return (
    <PhoneInput
      size={size || SIZE.compact}
      overrides={{
        Input: {
          props: {
            overrides: {
              Root: {
                style: ({ $theme }) => ({
                  borderTopWidth: $theme.borders.borderWidth,
                  borderBottomWidth: $theme.borders.borderWidth,
                  borderLeftWidth: $theme.borders.borderWidth,
                  borderRightWidth: $theme.borders.borderWidth
                })
              }
            }
          }
        },
        CountrySelect: {
          props: {
            overrides: {
              ControlContainer: {
                style: ({ $theme }) => ({
                  borderTopWidth: $theme.borders.borderWidth,
                  borderBottomWidth: $theme.borders.borderWidth,
                  borderLeftWidth: $theme.borders.borderWidth,
                  borderRightWidth: $theme.borders.borderWidth,
                  height: $theme.sizing.scale1000
                })
              }
            }
          }
        },
        ...overrides
      }}
      {...props}
    />
  )
}
export default VPhoneInput
