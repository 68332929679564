import UploadButton from "./upload-button";
import React, { useContext } from "react";
import { StatefulTooltip } from "baseui/tooltip";
import { useStyletron } from "baseui";
import { AppointmentContext } from "../../../appointment-form";
import { findIndex } from "lodash";

const UploadButtonWrapper = ({ question, answer }) => {
  const [css, theme] = useStyletron();

  const { attachedDocuments, documents } = answer;
  const { id } = question;
  const { appointment, setAppointment } = useContext(AppointmentContext);

  return (
    <StatefulTooltip
      overrides={{
        Arrow: {
          style: {
            backgroundColor: theme.colors.background,
          },
        },
        Inner: {
          style: {
            backgroundColor: theme.colors.background,
            color: theme.colors.primary,
            width: theme.sizing.scale4800,
          },
        },
      }}
      content={
        attachedDocuments?.length > 0 && !documents ? (
          <div>
            {attachedDocuments.map((attachedDocument, index) => {
              const { url, name } = attachedDocument;
              return (
                <a className={css({ display: "block" })} key={index} href={url}>
                  - {name}
                </a>
              );
            })}
          </div>
        ) : null
      }
      showArrow
      placement="bottomLeft"
    >
      <div>
        <UploadButton
          onError={console.log}
          onSuccess={(signedIds) => {
            const { answersAttributes } = appointment;
            const newAnswersAttributes = answersAttributes
              ? [...answersAttributes]
              : [];
            const existingAnswerIndex = findIndex(newAnswersAttributes, {
              questionId: id,
            });
            const newAnswer =
              existingAnswerIndex === -1
                ? { ...answer }
                : newAnswersAttributes[existingAnswerIndex];
            newAnswer.questionId = id;
            newAnswer.documents = signedIds;
            newAnswersAttributes.splice(existingAnswerIndex, 1, newAnswer);
            setAppointment({
              ...appointment,
              answersAttributes: newAnswersAttributes,
            });
          }}
        />
      </div>
    </StatefulTooltip>
  );
};

export default UploadButtonWrapper;
