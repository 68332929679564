import { TrailerContextProps } from 'components/contexts/yard-trailer.context'

export const SET_FACILITY = 'SET_FACILITY'
export const SET_LOADING = 'SET_LOADING'
export const SET_TRAILERS = 'SET_TRAILERS'
export const CLOSE_TASK_MODAL = 'CLOSE_TASK_MODAL'
export const SET_TASK_MODAL = 'SET_TASK_MODAL'
export const SET_TRAILER_DETAILS = 'SET_TRAILER_DETAILS'
export const SET_TASK = 'SET_TASK'

export const trailersReducer = (state: TrailerContextProps, action) => {
  switch (action.type) {
    case SET_FACILITY: {
      return {
        ...state,
        selectedFacility: action.payload
      }
    }

    case SET_LOADING: {
      return {
        ...state,
        trailers: {
          ...state.trailers,
          loading: action.payload
        }
      }
    }

    case CLOSE_TASK_MODAL: {
      return {
        ...state,
        taskModal: {
          ...state.trailers,
          ...action.payload
        }
      }
    }

    case SET_TASK_MODAL: {
      return {
        ...state,
        taskModal: {
          ...state.trailers,
          ...action.payload
        }
      }
    }

    case SET_TRAILERS: {
      return {
        ...state,
        trailers: {
          ...state.trailers,
          ...action.payload
        }
      }
    }

    case SET_TRAILER_DETAILS: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          trailer: {
            ...state.taskModal.trailer,
            ...action.payload
          }
        }
      }
    }

    case SET_TASK: {
      return {
        ...state,
        taskModal: {
          ...state.taskModal,
          task: action.payload
        }
      }
    }

    default:
      return state
  }
}

export default trailersReducer
