import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { DraggableItem, DraggableItemActive, TypeNameLabel } from './Item.styled'
import { capitalize } from 'lodash'

import { List, PencilSimple } from '@phosphor-icons/react'
import { CustomCancelReason } from 'components/models/CustomCancelReason'
import { useCustomCancelReasonsContext } from 'components/contexts/custom-cancel-reason.context'
import { useTranslation } from 'react-i18next'
import { DEFAULT_LOCALE_STRING_LANGUAGE } from 'components/constants/default-locale-string-language'

interface ItemProps {
  customCancelReason: CustomCancelReason
  index: number
  isActive: boolean
}

export const Item: React.FC<ItemProps> = ({ customCancelReason, index, isActive }) => {
  const {
    actions: { setSelectedCustomCancelReason, setFormOpen }
  } = useCustomCancelReasonsContext()
  const { i18n } = useTranslation()
  const reasonLabel =
    customCancelReason.reason[i18n.language] ||
    customCancelReason.reason[DEFAULT_LOCALE_STRING_LANGUAGE]
  const onClick = (customCancelReason: CustomCancelReason) => {
    setSelectedCustomCancelReason(customCancelReason)
    setFormOpen(true)
  }

  const ItemToUse = isActive ? DraggableItemActive : DraggableItem

  return (
    <Draggable draggableId={customCancelReason.id} index={index}>
      {provided => (
        <ItemToUse
          data-testid={'cancel-reason-draggable-item'}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <List style={{ marginTop: '-2px' }} />
              {'  '}
              <TypeNameLabel style={{ maxWidth: '94%' }}>{capitalize(reasonLabel)}</TypeNameLabel>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {customCancelReason.shipperId && (
                <PencilSimple
                  data-testid={'cancel-reason-draggable-item-edit'}
                  onClick={() => onClick(customCancelReason)}
                />
              )}
            </div>
          </div>
        </ItemToUse>
      )}
    </Draggable>
  )
}
