import React from 'react'
import { Pagination } from 'baseui/pagination'
import { useTranslation } from 'react-i18next'

const VPagination = ({ children, overrides, ...props }) => {
  const { t } = useTranslation()

  return (
    <Pagination
      labels={{
        prevButton: t('Common.Table.Pagination.Prev.Text'),
        nextButton: t('Common.Table.Pagination.Next.Text'),
        preposition: t('Common.Table.Pagination.Preposition.Text')
      }}
      size="mini"
      overrides={{
        MaxLabel: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize
          })
        },
        Select: {
          props: {
            overrides: {
              SingleValue: {
                style: ({ $theme }) => ({
                  fontSize: $theme.typography.LabelXSmall.fontSize
                })
              },
              ControlContainer: {
                style: ({ $theme }) => ({
                  borderTopWidth: $theme.borders.borderWidth,
                  borderBottomWidth: $theme.borders.borderWidth,
                  borderLeftWidth: $theme.borders.borderWidth,
                  borderRightWidth: $theme.borders.borderWidth,
                  outline: 'none'
                })
              }
            }
          }
        },
        DropdownContainer: {
          style: ({ $theme, $isActive }) => ({
            outline: $isActive ? `1px solid ${$theme.colors.accent}` : 'none',
            borderRadius: $theme.borders.popoverBorderRadius
          })
        },
        ...overrides
      }}
      {...props}
    />
  )
}
export default VPagination
