import { PAGE_SIZE } from 'components/constants/page-size'
import { MetabaseReport, MetabaseReportForm } from 'components/models/MetabaseReport'
import { PaginationResponse } from 'components/types/pagination-response'
import http from 'components/utils/authenticated-fetch'

const DEFAULT_PARAMS = {
  search: '',
  page: 1,
  pageSize: PAGE_SIZE
}

class ReportsService {
  findReports = (params = DEFAULT_PARAMS): Promise<PaginationResponse<MetabaseReport>> => {
    const query = new URLSearchParams()
    if (params?.page) query.set('page', String(params.page))
    if (params?.pageSize) query.set('page_size', String(params.pageSize))
    if (params?.search) query.set('search', params.search)
    return http({
      path: `/metabase_reports.json?${query.toString()}`,
      method: 'GET'
    }).then(([data]) => data)
  }

  createReport = (body: MetabaseReportForm): Promise<[MetabaseReport, number]> =>
    http({
      path: '/metabase_reports.json',
      method: 'POST',
      body
    }).then(data => data)

  updateReport = (body: MetabaseReportForm): Promise<[MetabaseReport, number]> =>
    http({
      path: `/metabase_reports/${body.id}.json`,
      method: 'PUT',
      body
    }).then(data => data)

  deleteReport = (id: string): Promise<[MetabaseReport, number]> =>
    http({
      path: `/metabase_reports/${id}.json`,
      method: 'DELETE'
    }).then(data => data)
}

export const reportsService = new ReportsService()
