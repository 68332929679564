import React, { useEffect, useState } from 'react'
import { Block } from 'baseui/block'
import { reportsService } from 'components/services/reports.service'
import { MetabaseReport } from 'components/models/MetabaseReport'
import { Navigation } from 'baseui/side-navigation'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { useHistory, useParams } from 'react-router-dom'
import { useHttpDidMount } from 'components/hooks/use-did-mount-http'
import StyledSpinner from 'components/shared/styled-spinner'
import { PaginationResponse } from 'components/types/pagination-response'

import Header from 'components/ui/generic/Header'
import CenterBlock from 'components/ui/generic/CenterBlock'

const findAll = () => reportsService.findReports(null)

export const MetabaseAnalytics = () => {
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const [loadingFrame, setLoadingFrame] = useState(true)
  const [sidebarItems, setSidebarItems] = useState([])
  const [currentUri, setCurrentUri] = useState(null)
  const [_, { data, loading }] = useHttpDidMount<PaginationResponse<MetabaseReport>>(findAll)
  const analyticsId = params.id
  const results = data?.results || []

  useEffect(() => {
    if (results)
      setSidebarItems(
        results.map(item => ({
          title: item.name,
          itemId: `/analytics/${item.id}`,
          uri: item.uri
        }))
      )
  }, [results])

  useEffect(() => {
    if (results && results.length > 0) {
      if (!analyticsId) {
        const item = results[0]
        history.push(`/analytics/${item.id}`)
        setCurrentUri(item.uri.replace('http:', 'https:'))
      } else {
        setCurrentUri(results.find(item => item.id === analyticsId)?.uri.replace('http:', 'https:'))
      }
    }
  }, [analyticsId, results])

  if (loading) return <StyledSpinner />

  const pathArray = location.pathname.split('/')

  const secondLevelLocation = pathArray[1]
  const thirdLevelLocation = pathArray[2]

  return (
    <>
      <Header title={t('HeaderNavigation.NavBarLinks.Analytics.Text')} />

      <Block
        display="flex"
        minHeight="70vh"
        gridGap="scale800"
        flexDirection={['column', 'column', 'row']}>
        <Block width={['100%', '100%', '230px']}>
          <Navigation
            items={sidebarItems}
            activeItemId={`/${secondLevelLocation}/${thirdLevelLocation}`}
            onChange={({ event, item }) => {
              event.preventDefault()
              history.push(item.itemId)
              setCurrentUri(item.uri.replace('http:', 'https:'))
            }}
            overrides={{
              NavItem: {
                style: ({ $active, $theme }) => ({
                  fontSize: $theme.typography.LabelXSmall.fontSize,
                  backgroundImage: 'none',
                  backgroundColor: $active
                    ? $theme.colors.backgroundSecondary
                    : $theme.colors.backgroundPrimary,
                  borderLeftColor: $active ? $theme.colors.accent : $theme.colors.transparent,
                  color: $active ? $theme.colors.accent : '',
                  borderRadius: $theme.borders.radius100
                })
              }
            }}
          />
        </Block>
        <Block width="100%" height="auto">
          {loadingFrame && (
            <CenterBlock>
              <StyledSpinner />
            </CenterBlock>
          )}
          {currentUri && (
            <iframe
              src={currentUri}
              frameBorder="0"
              width="100%"
              height="100%"
              sandbox="allow-same-origin allow-scripts allow-downloads allow-downloads-without-user-activation allow-forms"
              allowTransparency
              onLoad={() => setLoadingFrame(false)}
            />
          )}
        </Block>
      </Block>
    </>
  )
}
