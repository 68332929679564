import React, { useContext } from 'react'
import Header from 'components/ui/generic/Header'
import { useTranslation } from 'react-i18next'
import YardTrailerFacilitySelect from './YardTrailerFacilitySelect'
import { AddButton } from 'components/components/AddButton'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { YardSearchButtonContainer } from './Trailers.styled'
import { Block } from 'baseui/block'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import TrailersList from './TrailersList'
import { ParagraphLarge } from 'baseui/typography'
import { useTrailerContext } from 'components/contexts/yard-trailer.context'
import TaskForm from './TaskModal/TaskForm'

const TrailersContainer = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const {
    state: {
      trailers: { trailers },
      pagination: { page }
    }
  } = useTrailerContext()
  const { t } = useTranslation()
  return (
    <>
      <Header
        title={t('YardTrailer.Header.Text')}
        subtitle={t('YardTrailer.Header.Subtitle')}
        leftItems={[<YardTrailerFacilitySelect />]}
      />
      <Block
        width="100%"
        marginBottom="15px"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <FlexGrid
          flexGridColumnCount={2}
          flexGridColumnGap="scale800"
          alignItems="center"
          width="100%"
          backgroundColor="backgroundPrimary">
          <FlexGridItem display="flex" justifyContent="flex-start">
            <ParagraphLarge>
              <strong>
                {trailers.length}{' '}
                {trailers.length
                  ? t('YardTrailer.Summary.Count.Trailer')
                  : t('YardTrailer.Summary.Count.Trailers')}
              </strong>{' '}
              {t('YardTrailer.Summary.SelectedFacilities.Text')}
            </ParagraphLarge>
          </FlexGridItem>
          <FlexGridItem gridGap="scale200" display="flex" justifyContent="flex-end">
            <span></span>
            <span></span>
          </FlexGridItem>
        </FlexGrid>
      </Block>
      <Block width="100%">
        <TrailersList />
      </Block>
      <TaskForm />
    </>
  )
}

export default TrailersContainer
