import { MODAL_TYPE_EDIT } from 'components/constants/modal-types'

import React from 'react'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import { useCarriersContext } from 'components/contexts/carriers.context'
import { useTranslation } from 'react-i18next'
import regexValidations from 'components/utils/regex-validations'
import { ModalButton, ModalFooter } from 'baseui/modal'
import { KIND, SIZE } from 'baseui/button'

const CarrierModalFooter = ({ closeModal }) => {
  const {
    carriers: { loading, modalType, carrier },
    actions: { handleCreateCarrier }
  } = useCarriersContext()

  const { t } = useTranslation()

  const getActionLabel = () =>
    modalType === MODAL_TYPE_EDIT
      ? t('Settings.Carriers.CreateCarrierModal.Button.Update.Text')
      : t('Settings.Carriers.CreateCarrierModal.Button.Create.Text')

  const formErrors = (): { label: string; status: boolean }[] => {
    return [
      {
        label: t('Settings.Carriers.CreateCarrierModal.Validations.SCACcode.Required'),
        status: !!carrier?.scacCode
      },
      {
        label: t('Settings.Carriers.CreateCarrierModal.Validations.Name.Required'),
        status: !!carrier?.name
      },
      {
        label: t('Settings.Carriers.CreateCarrierModal.Validations.Email.Valid'),
        status: !carrier?.email || regexValidations.email.test(carrier?.email)
      }
    ]
  }

  return (
    <ModalFooter>
      <ModalButton kind={KIND.secondary} onClick={closeModal} size={SIZE.compact}>
        {t('Orders.CreateEditOrderModal.Buttons.Cancel.Text')}
      </ModalButton>
      <ErrorMessageButton
        label={getActionLabel()}
        errors={formErrors()}
        onClick={handleCreateCarrier}
        isLoading={loading}
      />
    </ModalFooter>
  )
}

export default CarrierModalFooter
