import React, { useEffect, useState } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { CyberTable } from 'cyber/table'
import { endsWith, camelCase } from 'lodash'
import { useStyletron } from 'baseui'
import { ParagraphLarge } from 'baseui/typography'
import { FileSearch, PencilSimple } from '@phosphor-icons/react'

import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'
import StyledSpinner from '../../shared/styled-spinner'
import { MILISECONDS_FOR_SPINNER_IN_SETTINGS } from '../../constants/miliseconds-for-spinner-in-settings'

const Hits = props => {
  const [css, theme] = useStyletron()
  const { hits } = props
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const COLUMNS = [
    t('Settings.VendorCompanies.Table.Columns.Name.Label.Text'),
    t('Settings.VendorCompanies.Table.Columns.Name.Label.Email'),
    t('Settings.VendorCompanies.Table.Columns.Actions.Label.Text')
  ]

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, MILISECONDS_FOR_SPINNER_IN_SETTINGS)

    return () => clearTimeout(timeoutId)
  }, [hits])

  const formattedHits = hits.map(hit => {
    const keys = Object.keys(hit)
    const formattedHit = {}
    keys.map(key => {
      const camelCasedKey = camelCase(key)
      if (
        endsWith(key, 'at') ||
        (endsWith(key, 'time') && // In Ruby on Rails-Landia, date fields usually end in the word 'at' (eg: createdAt, updatedAt, etc.)
          typeof hit[key] === 'number' &&
          hit[key] > 1600000000)
      ) {
        formattedHit[camelCasedKey] = new Date(hit[key] * 1000).toISOString()
      } else if (hit[key] === '') {
        // Typesense gets mad if we try to serialize null values.
        formattedHit[camelCasedKey] = null
      } else {
        formattedHit[camelCasedKey] = hit[key]
      }
    })
    return formattedHit
  })

  if (loading) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: theme.sizing.scale1600
        })}>
        <StyledSpinner />
      </div>
    )
  }

  if (hits.length === 0) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: theme.sizing.scale1600
        })}>
        <FileSearch size={theme.sizing.scale2400} />
        <ParagraphLarge>
          {t('Settings.VendorCompanies.Table.NoVendorCompaniesFound.Text')}
        </ParagraphLarge>
      </div>
    )
  }

  return (
    <div
      className={css({
        paddingTop: theme.sizing.scale200
      })}>
      <CyberTable
        gridTemplateColumns={['auto', 'auto', '100px']}
        columns={COLUMNS}
        data={formattedHits.map(hit => {
          const { id, name, email } = hit
          return [
            name,
            email,
            <Button
              shape="circle"
              onClick={() => {
                props.setSelectedScheduler({ id, name, email })
                props.setIsOpen(true)
              }}>
              <PencilSimple />
            </Button>
          ]
        })}
      />
    </div>
  )
}

const ConnectedHits = connectHits(Hits) as any

export default ConnectedHits
