import { fancyToast } from 'components/utils'
import React from 'react'
import Button from 'components/ui/generic/Button'
import { useCopyToClipboard } from 'react-use'
import { Copy } from '@phosphor-icons/react'

interface CopyButtonProps {
  href: string
  label?: string
}

const CopyButton = (props: CopyButtonProps) => {
  const [state, copyToClipboard] = useCopyToClipboard()

  const { href, label } = props

  return (
    <Button
      onClick={() => {
        copyToClipboard(href)
        fancyToast({ info: 'Copied!' }, 201)
      }}
      title={label || 'Copy Link'}
      shape="circle">
      <Copy />
    </Button>
  )
}

export default CopyButton
