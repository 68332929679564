import React, { ReactElement } from 'react'
import { SidebarNavbarTemplateProps } from './SidebarNavbarTemplate.props'
import Navbar from '../../components/Navbar'
import { Content } from '../../components/Sidebar/Sidebar.styled'

function SidebarNavbarTemplate({ children }: SidebarNavbarTemplateProps): ReactElement {
  return (
    <>
      <Navbar />
      <Content>{children}</Content>
    </>
  )
}

export default SidebarNavbarTemplate
