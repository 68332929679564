import scStyled from 'styled-components'

export const BaseFileContainer = scStyled.div`
  height: 40px;
  border-radius: 20px;
  border: 1px solid #dbe6ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 8px;
  ${props => props?.disabled && 'opacity: .5;'}
  ${props => props?.selectedForRemove && 'border: 1px solid #D92179;'}
  ${props => props?.isHidden && 'display: none;'}
`

export const FileNameLabel = scStyled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  flex: 1
`
