import React, { useEffect, useState } from 'react'
import { Button } from 'baseui/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import { fancyToast } from '../../utils'
import { ALIGN, Radio, RadioGroup } from 'baseui/radio'
import { recurringAppointmentBlueprintService } from 'components/services/recurring-appointment-blueprint.service'
import { DateTime } from 'luxon'
import { Appointment } from '../types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { answerService } from 'components/services'
import { useTranslation } from 'react-i18next'

interface ConfirmModificationRecurringAppointmentModalProps {
  isOpen: boolean
  close: () => void
  closeParent: Function
  appointment: Appointment
  openReasonModal?: () => void
  type: 'UPDATE' | 'DELETE'
  touchedRecurringSection?: boolean
  parentLoading?: boolean
  updateOnlyOneAppointment?: () => void
  onMultipleRecurringAppointmentsUpdate?: () => void
}

const ALL = 'ALL'
const CURRENT = 'CURRENT'
const DELETE = 'DELETE'

const ConfirmModificationRecurringAppointmentModal = ({
  isOpen,
  close,
  openReasonModal,
  appointment,
  type,
  touchedRecurringSection,
  onMultipleRecurringAppointmentsUpdate,
  updateOnlyOneAppointment,
  parentLoading,
  closeParent
}: ConfirmModificationRecurringAppointmentModalProps) => {
  const [, setLoading] = useState<boolean>(false)
  const {
    temporalFiles,
    documentSelections,
    actions: { updateFileUploadState }
  } = useCQStateValue()
  const arrivalDateIsLastDayOfSeries =
    DateTime.fromJSDate(appointment?.arrivalTime).startOf('day').toISO() ===
    DateTime.fromJSDate(appointment?.endDate).startOf('day').toISO()
  const [value, setValue] = React.useState(
    !arrivalDateIsLastDayOfSeries ? (touchedRecurringSection ? ALL : CURRENT) : CURRENT
  )

  const { t } = useTranslation()

  useEffect(() => {
    setValue(!arrivalDateIsLastDayOfSeries ? (touchedRecurringSection ? ALL : CURRENT) : CURRENT)
  }, [touchedRecurringSection, arrivalDateIsLastDayOfSeries])

  const cancelRecurringAppointments = async () => {
    setLoading(true)
    if (value === ALL) {
      const [json, status] = await recurringAppointmentBlueprintService.delete(
        {
          startDate: appointment.arrivalTime
        },
        appointment.recurringAppointmentBlueprintId
      )

      if (status == 200) {
        fancyToast(
          { info: t('Appointments.CancelEditRecurringAppointmentModal.CancelAppointmentSuccess') },
          status
        )
        closeParent()
      } else {
        fancyToast(
          { info: t('Appointments.CancelEditRecurringAppointmentModal.CancelAppointmentFailed') },
          500
        )
      }
    } else if (openReasonModal) {
      openReasonModal()
    }
    setLoading(false)
  }

  const updateRecurringAppointments = async () => {
    setLoading(true)
    if (value === ALL) {
      onMultipleRecurringAppointmentsUpdate()
    } else if (updateOnlyOneAppointment) {
      if (documentSelections.length) {
        documentSelections.forEach(({ documentId, questionId }) =>
          answerService.removeFile(documentId, questionId)
        )
      }
      if (temporalFiles.length) {
        updateFileUploadState(true)
      }

      if (!temporalFiles.length) {
        updateOnlyOneAppointment()
        closeParent()
      }
    }
    setLoading(false)
  }

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <ModalHeader>
        {t('Appointments.CancelEditRecurringAppointmentModal.Title', {
          type: type === DELETE ? t('Common.Fields.Cancel.Text') : t('Common.Fields.Edit.Text')
        })}
      </ModalHeader>
      <ModalBody>
        <RadioGroup
          value={value}
          onChange={e => setValue(e.currentTarget.value)}
          name="number"
          align={ALIGN.vertical}>
          {(!touchedRecurringSection || arrivalDateIsLastDayOfSeries) && (
            <Radio value={CURRENT}>
              {t('Appointments.CancelEditRecurringAppointmentModal.ThisEvent')}
            </Radio>
          )}
          {!arrivalDateIsLastDayOfSeries && (
            <Radio value={ALL}>
              {t('Appointments.CancelEditRecurringAppointmentModal.AllFollowingEvents')}
            </Radio>
          )}
        </RadioGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={type === DELETE ? cancelRecurringAppointments : updateRecurringAppointments}
          isLoading={parentLoading}
          disabled={!value}>
          {t('Appointments.CancelEditRecurringAppointmentModal.Confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmModificationRecurringAppointmentModal
