export const VAN = 'van'
export const FLATBED = 'flatbed'
export const REEFER = 'reefer'
export const TANKER = 'tanker'
export const RAIL = 'rail'
export const CONTAINER = 'container'
export const VAN_FLOOR = 'van_floor'
export const VAN_PALLET = 'van_pallet'

export type DockType =
  | typeof VAN
  | typeof FLATBED
  | typeof REEFER
  | typeof TANKER
  | typeof RAIL
  | typeof CONTAINER
  | typeof VAN_FLOOR
  | typeof VAN_PALLET

export const DOCK_TYPES = [VAN, FLATBED, REEFER, TANKER, RAIL, CONTAINER, VAN_FLOOR, VAN_PALLET]
