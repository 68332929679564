import authenticatedFetch from 'components/utils/authenticated-fetch'

class AnswerService {
  updateAnswer = (id, isFile, updatableResponse): Promise<any> =>
    authenticatedFetch({
      path: `/answers/${id}.json`,
      method: 'PATCH',
      body: {
        answer: {
          id,
          ...(!isFile && {
            response: updatableResponse
          }),
          ...(isFile && {
            documents: updatableResponse
          })
        }
      }
    }).then(([result, status]) => [result, status])

  removeFile = (id: string, answerId: string): Promise<any> =>
    authenticatedFetch({
      path: `/answers/remove_file_attachment.json`,
      method: 'DELETE',
      body: { id, answerId }
    }).then(([json, status]) => [json, status])
}

export const answerService = new AnswerService()
