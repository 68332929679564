import authenticatedFetch from 'components/utils/authenticated-fetch'
import http from 'components/utils/authenticated-fetch'

class SchedulerService {
  createScheduler = (scheduler: Record<string, unknown>): Promise<[any, number]> =>
    http({
      path: `/schedulers.json`,
      method: 'POST',
      body: {
        scheduler
      }
    })

  updateScheduler = (scheduler: Record<string, unknown>): Promise<[any, number]> =>
    http({
      path: `/schedulers/${scheduler.id}.json`,
      method: 'PATCH',
      body: {
        scheduler
      }
    })

  schedulersByShippers = (shipperId: string): Promise<[any, number]> =>
    authenticatedFetch({
      path: `/schedulers/scheduler_list_carrier_user.json`,
      method: 'POST',
      body: {
        scheduler: {
          shipperId: shipperId
        }
      }
    })
}

export const schedulerService = new SchedulerService()
