export const PanelProps: any = {
  overrides: {
    Header: {
      style: ({ $theme }) => ({
        fontSize: $theme.typography.LabelSmall.fontSize,
        paddingTop: $theme.sizing.scale500,
        paddingBottom: $theme.sizing.scale500,
        paddingLeft: '0px',
        paddingRight: $theme.sizing.scale600,
        backgroundColor: 'inherit',
        ':focus': {
          outline: $theme.colors.white
        },
        textTransform: 'uppercase'
      })
    },
    PanelContainer: {
      style: ({ $theme }) => ({
        overflow: 'hidden',
        borderTopWidth: '0px',
        borderBottomWidth: $theme.borders.borderWidth,
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
        borderTopStyle: $theme.borders.borderStyle,
        borderBottomStyle: $theme.borders.borderStyle,
        borderLeftStyle: $theme.borders.borderStyle,
        borderRightStyle: $theme.borders.borderStyle,
        borderTopColor: $theme.borders.border200.borderColor,
        borderBottomColor: $theme.borders.border200.borderColor,
        borderLeftColor: $theme.borders.border200.borderColor,
        borderRightColor: $theme.borders.border200.borderColor
      })
    },
    Content: {
      style: ({ $theme }) => {
        return {
          paddingTop: 0,
          paddingLeft: $theme.sizing.scale600,
          paddingRight: $theme.sizing.scale600,
          paddingBottom: $theme.sizing.scale300,
          backgroundColor: 'inherit'
        }
      }
    },
    ToggleIcon: {
      style: ({ $theme }) => {
        return {
          display: 'none'
        }
      }
    }
  }
}
