import React, { useEffect, useState } from 'react'
import Select from 'components/ui/generic/Select'
import FormControl from 'components/ui/generic/FormControl'

import { Facility } from 'components/models/Facility'
import { useDebounce } from 'react-use'
import { orderService } from 'components/services/order'
import { fancyToast } from 'components/utils'
import { StatusCodes } from 'components/constants/http-status-codes'
import { Order } from 'components/models/Order'

interface PurchaseOrderIdentifiersProps {
  purchaseOrders: any
  setPurchaseOrders: any
  facility?: Facility
  schedulerId?: string
  setOrderValidation?: any
  orderValidation?: any
  openScheduling?: boolean
  disabled?: boolean
}

const PurchaseOrderIdentifiers = ({
  purchaseOrders,
  setPurchaseOrders,
  facility,
  setOrderValidation,
  orderValidation,
  schedulerId,
  openScheduling,
  disabled = false
}: PurchaseOrderIdentifiersProps) => {
  const [input, setInput] = useState<string>(null)

  const purchaseOrderIdentifierLabel =
    facility?.appointmentPreference?.purchaseOrderLabels?.purchaseOrderIdentifiers

  const purchaseOrderIdentifierCaption =
    facility?.appointmentPreference?.purchaseOrderLabels?.purchaseOrderIdentifiersCaption

  useEffect(() => {
    if (facility && schedulerId && setOrderValidation && !openScheduling) {
      validateOrder({
        orders: purchaseOrders
          .filter(({ _destroy }) => !_destroy)
          .map((order: Order) => order.identifier),
        facilityId: facility?.id,
        schedulerId
      })
    }
  }, [purchaseOrders, schedulerId])

  const validateOrder = async (order: any) => {
    const [json, status] = await orderService.validateOrder(order)
    if (json && status !== StatusCodes.OK) {
      fancyToast({ info: json.error }, status)
    } else {
      setOrderValidation(json)
    }
  }

  return (
    <FormControl
      label={purchaseOrderIdentifierLabel ? purchaseOrderIdentifierLabel : 'PO Number(s)'}
      caption={
        purchaseOrderIdentifierCaption
          ? purchaseOrderIdentifierCaption
          : "This/These might be a PO number, pack number, BOL number, etc. You can enter multiple identifiers, by hitting 'Enter', after typing each identifier."
      }>
      <Select
        error={
          orderValidation?.errors?.length > 0 ? orderValidation?.errors?.length > 0 : undefined
        }
        disabled={disabled}
        overrides={{
          SelectArrow: {
            style: {
              display: 'none'
            }
          },
          Input: {
            props: {
              name: 'appointment[purchase_order_number]'
            }
          }
        }}
        placeholder=""
        noResultsMsg="Enter the unique identifier for a load, and hit 'Enter'"
        backspaceRemoves
        onSelectResetsInput
        openOnClick
        creatable
        multi
        labelKey="identifier"
        onClose={() => {
          if (input !== null) {
            setPurchaseOrders([...purchaseOrders, { id: null, identifier: input }])

            setInput(null)
          }
        }}
        onBlur={() => {
          if (input !== null) {
            setPurchaseOrders([...purchaseOrders, { id: null, identifier: input }])

            setInput(null)
          }
        }}
        onInputChange={event => {
          setInput(event.currentTarget.value)
        }}
        onChange={({ value, option, type }) => {
          if (type === 'select') {
            setPurchaseOrders([...purchaseOrders, { id: null, identifier: option.identifier }])
            setInput(null)
          } else {
            setPurchaseOrders(
              purchaseOrders.map(element => {
                if (element === option) {
                  return { ...option, _destroy: true }
                }
                return element
              })
            )
          }
        }}
        value={
          purchaseOrders &&
          purchaseOrders.filter(({ _destroy }) => {
            return !_destroy
          })
        }
      />
    </FormControl>
  )
}

export default PurchaseOrderIdentifiers
