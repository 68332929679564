import React, { useState } from 'react'
import { Select, SIZE } from 'baseui/select'
import { useStyletron } from 'baseui'
import _uniqueId from 'lodash/uniqueId'

const VSelect = React.forwardRef(
  (
    {
      size = '',
      options = [],
      minWidth,
      maxWidth,
      maxDropdownHeight,
      overrides,
      disabled,
      label,
      value,
      tags,
      placeholder,
      multi,
      ...props
    },
    ref
  ) => {
    const [css] = useStyletron()

    const [focused, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    const [id] = useState(_uniqueId('select-'))

    const labelStyles = css({
      position: 'absolute',
      paddingLeft: '16px',
      // paddingTop: focused || (value && value[0] && Object.keys(value[0]).length) ? '8px' : '18px',
      paddingTop: '8px',
      color: '#04112B',
      // fontSize: focused || (value && value[0] && Object.keys(value[0]).length) ? '11px' : '14px',
      fontSize: '11px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '12px',
      opacity: 0.6,
      zIndex: 1,
      transition: '.3s all ease-in-out'
    })

    // Conditional styles for Input and InputContainer if there is a label
    const inputConditionalStyles = {}

    const inputContainerConditionalStyles = label
      ? {
          paddingTop: tags ? '16px' : '0', // Maintain vertical rhythm
          paddingLeft: tags ? '12px' : '0',
          width: '100%',
          flex: '1'
        }
      : {}

    const valueContainerConditionalStyles = (() => {
      if (multi && !label)
        return {
          paddingTop: '2px',
          paddingBottom: '2px',
          paddingLeft: '2px'
        }
      if (label)
        return {
          paddingTop: '18px',
          paddingBottom: '8px'
        }

      return {
        paddingTop: '8px',
        paddingBottom: '8px'
      }
    })()

    return (
      <div className={css({ display: 'flex', position: 'relative', width: '100%' })}>
        {label && (
          <label className={labelStyles} htmlFor={id}>
            {label}
          </label>
        )}
        <Select
          id={id}
          size={size || SIZE.compact}
          value={value}
          options={options}
          disabled={disabled}
          maxDropdownHeight={maxDropdownHeight || '50vh'}
          multi={multi}
          overrides={{
            ControlContainer: {
              style: ({ $theme }) => ({
                borderTopWidth: $theme.borders.borderWidth,
                borderBottomWidth: $theme.borders.borderWidth,
                borderLeftWidth: $theme.borders.borderWidth,
                borderRightWidth: $theme.borders.borderWidth,
                minWidth: minWidth || '',
                maxWidth: maxWidth || '',
                minHeight: $theme.sizing.scale1000
              })
            },
            InputContainer: {
              style: ({ $theme }) => ({
                position: 'relative',
                backgroundColor: $theme.colors.transparent,
                ...inputContainerConditionalStyles,
                ...overrides?.InputContainer?.style
              })
            },
            Input: {
              style: {
                ...inputConditionalStyles,
                ...overrides?.Input?.style
              }
            },
            SingleValue: {
              style: ({ $theme }) => ({
                lineHeight: $theme.sizing.scale700
              })
            },
            ValueContainer: {
              style: () => ({
                flexWrap: 'nowrap',
                overflow: 'auto',
                paddingLeft: '14px',
                ...valueContainerConditionalStyles
              })
            },
            Tag: {
              props: {
                overrides: {
                  Root: {
                    style: ({ $theme }) => ({
                      backgroundColor: $theme.colors.backgroundTertiary,
                      color: $theme.colors.contentSecondary
                    })
                  },
                  Text: {
                    style: ({ $theme }) => ({
                      fontSize: $theme.typography.LabelXSmall.fontSize,
                      maxWidth: '13em'
                    })
                  }
                }
              }
            },
            ...overrides
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={label ? '' : placeholder}
          {...props}
        />
      </div>
    )
  }
)

export default VSelect
