import styled from 'styled-components'

export const SpinnerWrapper = styled.div`
  height: 375px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Pill = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 999px;
  background-color: ${({ color }) => color || '#e6e6e6'};
  text-align: center;
  color: #fff;
`
