import http from 'components/utils/authenticated-fetch'
import { Role } from '../models/Role'

class UserRoleService {
  getUrl = (roleId?: string) => `/user_roles${roleId ? `/${roleId}.json` : '.json'}`

  getUserRoleById = (roleId: string): Promise<[any, number]> =>
    http({
      path: this.getUrl(roleId)
    })

  getUserRoles = (
    search?: string,
    audience?: string[],
    enabled?: boolean
  ): Promise<[any, number]> => {
    let url = this.getUrl()

    const params = new URLSearchParams()

    if (search) {
      params.append('search', search)
    }

    if (audience) {
      audience.forEach(audience => {
        params.append(`audience[]`, audience)
      })
    }

    if (enabled) {
      params.append('enabled', enabled.toString())
    }

    if (params.toString()) {
      url += `?${params.toString()}`
    }

    return http({
      path: url
    })
  }

  createRole = (role: Role): Promise<[any, number]> =>
    http({
      path: this.getUrl(),
      method: 'POST',
      body: {
        user_role: role
      }
    })

  updateRole = (role: Role): any => {
    return http({
      path: this.getUrl(role.id),
      method: 'PATCH',
      body: {
        user_role: role
      }
    })
  }
}

export const userRoleService = new UserRoleService()
