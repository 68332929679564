import React from 'react'
import VTable from 'components/ui/generic/Table'
import { useTranslation } from 'react-i18next'
import EmptyMessageForTable from '../../components/EmptyMessageForTable'

const SlotsTable = ({ data }) => {
  const { t } = useTranslation()
  return (
    <VTable
      columns={[
        t('Yard.Table.Columns.Name.Label.Text'),
        t('Yard.Table.Columns.Zone.Label.Text'),
        t('Yard.Table.Columns.Appointment.Label.Text'),
        t('Yard.Table.Columns.Status.Label.Text'),
        t('Yard.Table.Columns.EquipmentType.Label.Text'),
        ''
      ]}
      data={data}
      emptyMessage={<EmptyMessageForTable />}
    />
  )
}

export default SlotsTable
