import { useStyletron } from 'baseui'
import { useCarriersContext } from 'components/contexts/carriers.context'
import CarrierModal from 'components/pages/Carriers/CarrierModal'
import Button from 'components/ui/generic/Button'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import FormControl from 'components/ui/generic/FormControl'
import { useAppointmentContext } from 'components/contexts/appointment-context'

interface CarrierSelectProps {
  appointment: any
  setAppointment: any
  schedulerId?: string
  createdAsOther?: boolean
  disabled?: boolean
  onChange?: (value: any) => void
}

const CarrierSelect = ({
  appointment,
  setAppointment,
  schedulerId,
  createdAsOther,
  disabled = false
}: CarrierSelectProps) => {
  const {
    carriers: { createdCarrier },
    actions: { setModalState, setFormParams }
  } = useCarriersContext()

  const [key, setKey] = useState(0)
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const isMounted = useRef(true)
  const initialParams = useRef({ schedulerId, createdAsOther })

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (
      schedulerId !== initialParams.current.schedulerId ||
      createdAsOther !== initialParams.current.createdAsOther
    ) {
      setFormParams({
        schedulerId,
        externalUser: true,
        createdAsOther
      })
      initialParams.current = { schedulerId, createdAsOther }
    }
  }, [schedulerId, createdAsOther, setFormParams])

  useEffect(() => {
    if (!isMounted.current || !createdCarrier?.id || createdCarrier.id === appointment.carrierId) {
      return
    }
    setAppointment({
      carrier: createdCarrier,
      carrierId: createdCarrier.id
    })
    setKey(prevKey => prevKey + 1)
  }, [createdCarrier?.id])

  return (
    <>
      <div className={css({ position: 'relative', marginBottom: theme.sizing.scale600 })}>
        <FormControl disabled={disabled}>
          <CyberAutocomplete
            label={t('Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Fields.Carrier.Text')}
            key={key}
            record={appointment}
            setRecord={setAppointment}
            indexName="carrier"
          />
        </FormControl>
        <Button size="mini" onClick={() => setModalState(true)} disabled={disabled}>
          {t(
            'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Fields.Carrier.CreateCarrier.Text'
          )}
        </Button>
      </div>
      <CarrierModal />
    </>
  )
}

export default CarrierSelect
