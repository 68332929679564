/* eslint-disable no-nested-ternary */
type FormatQuestionCaptionInterface = {
  question: any
  permission?: any
}

const formatQuestionCaption = ({ question }: FormatQuestionCaptionInterface): string =>
  question?.answerType !== 'document'
    ? `${question.caption ?? ''}${
        question.minLength ? ` - Min length: ${question.minLength}` : ''
      }${question.maxLength ? ` - Max length: ${question.maxLength}` : ''}${
        question.numericOnly ? ` - numeric` : ''
      }`
    : ''

export default formatQuestionCaption
