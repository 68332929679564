import { styled } from 'baseui'

export const ActionButtonProps = {
  overrides: {
    BaseButton: {
      style: () => ({
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderRightWidth: 0,
        borderLeftWidth: 0
      })
    }
  }
}

export const NotFoundContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: $theme.sizing.scale1600
}))
