import React from 'react'
import Tag from 'components/ui/specific/Tag'
import { TagsDiv } from './Tags.styled'

const Tags = ({ tags, ...props }) => {
  return (
    <TagsDiv>
      {tags?.map((tag, index) => (
        <Tag key={index} type="secondary">
          {tag.identifier}
        </Tag>
      ))}
    </TagsDiv>
  )
}

export default Tags
