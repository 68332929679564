import React, { useContext, useEffect } from "react";
import { Select } from "baseui/select";
import { CalendarToggleContext } from "../../../contexts/calendar-toggle-context";
import { CalendarReferenceContext } from "../../../contexts/calendar-reference-context";

const VIEW_OPTIONS = [
  { label: "Timeline", id: "resourceTimelineDay" },
  { label: "Day", id: "resourceTimeGridDay" },
  // { label: "Week", id: "timeGridWeek" },
  { label: "List", id: "listDay" },
];

const SelectedView = () => {
  const { calendarToggles, setCalendarToggles } = useContext(
    CalendarToggleContext
  );
  const { calendarReference } = useContext(CalendarReferenceContext);
  const { selectedView } = calendarToggles;

  // TODO(adenta) I feel like this is a hack. Why do I have to do this?
  // the main fullcalendar component gets rendered 3 times.
  // there is a race condition, where we cant read from localstorage fast enough.
  useEffect(() => {
    calendarReference &&
      calendarReference.current.getApi().changeView(selectedView[0].id);
  }, [selectedView]);

  return (
    <Select
      clearable={false}
      value={selectedView}
      options={VIEW_OPTIONS}
      onChange={({ value }) => {
        // For some reason™, When you change from 'Day' to 'Timeline view', the rows on the calendar look off.
        // this has something to do with the way I am filtering resources in the `useFormattedResources` hook.
        const initialViewId = selectedView[0].id;
        calendarReference.current.getApi().changeView(value[0].id);
        setCalendarToggles({ ...calendarToggles, selectedView: value });
        if (
          initialViewId === "resourceTimeGridDay" &&
          value[0].id === "resourceTimelineDay"
        ) {
          window.location.reload();
        }
      }}
    />
  );
};

export default SelectedView;
