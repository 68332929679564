import { Question } from 'components/appointments/types'

export const checkShowOnCreateProperty = (questions: Array<Question>) =>
  questions.map(question => {
    return {
      ...question,
      questionPermissionsAttributes: question.questionPermissionsAttributes.map(
        questionPermission => {
          if (!questionPermission.hasOwnProperty('showOnCreate')) {
            return {
              ...questionPermission,
              showOnCreate: true
            }
          }
          return questionPermission
        }
      )
    }
  })
