import React, { useState, useEffect, useContext } from 'react'
import { HeadingLevel, Heading } from 'baseui/heading'
import { Card } from 'baseui/card'
import { Block } from 'baseui/block'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Textarea } from 'baseui/textarea'
import { Paragraph2, Paragraph4 } from 'baseui/typography'
import { Button } from 'baseui/button'
import StyledSpinner from '../shared/styled-spinner'
import authenticatedFetch from '../utils/authenticated-fetch'
import TimeFormatter from '../utils/time-formatter'
import { fancyToast } from '../utils'
import { CurrentUserContext } from '../homepage/current-user-context'

const FormattedMessage = ({ sender, body, createdAt }) => {
  return (
    <Card
      title={
        <Block display="flex" justifyContent="space-between" alignItems="flex-start">
          <Paragraph2>{sender.name}</Paragraph2>
          <Paragraph4 $style={{ color: '#a0a0a0' }}>
            {/* TODO(adenta) we should really use a better timezone here. */}(
            {new TimeFormatter('fullDate').format(createdAt, 'Etc/UTC')})
          </Paragraph4>
        </Block>
      }>
      {body}
    </Card>
  )
}

interface MessageDriverProps {
  checkinId: string
  dockName?: string
}

const MessageDriver = (props: MessageDriverProps) => {
  const { checkinId, dockName } = props
  const { currentUser } = useContext(CurrentUserContext)

  const messageTemplate = `Thank you for checking in with us. We have received your load details. Please back your truck/trailer into ${dockName}. Chalk the wheels, and proceed up the steps by ${dockName} with your paperwork.`
  const [textMessages, setTextMessages] = useState<any[]>([])
  const [message, setMessage] = useState<string>(dockName ? messageTemplate : '')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    authenticatedFetch({
      path: `/checkins/${checkinId}/text_messages.json`
    }).then(([json, _status]) => setTextMessages(json))
  }, [])

  const messageDriver = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/checkins/${checkinId}/message_driver.json`,
      method: 'POST',
      body: {
        checkin: {
          message
        }
      }
    })

    setLoading(false)

    if (status === 200) {
      setTextMessages([
        ...textMessages,
        {
          sender: currentUser,
          body: message,
          createdAt: new Date().toISOString()
        }
      ])
      setMessage('')
    } else {
      fancyToast(json, status)
    }
  }

  if (!textMessages) {
    return <StyledSpinner />
  }
  return (
    <FlexGrid flexGridRowGap="scale400">
      {textMessages.map((textMessage, index) => {
        return (
          <FlexGridItem key={index}>
            <FormattedMessage {...textMessage} />
          </FlexGridItem>
        )
      })}
      <FlexGridItem>
        <Block display="flex" justifyContent="space-between">
          <Block display="flex" flex="1">
            <Textarea
              value={message}
              onChange={e => setMessage(e.currentTarget.value)}
              placeholder="type your message to the driver here..."
            />
          </Block>
          <Block display="flex">
            <Button
              isLoading={loading}
              disabled={!message}
              overrides={{ BaseButton: { style: { width: '100%' } } }}
              onClick={messageDriver}>
              Send
            </Button>
          </Block>
        </Block>
      </FlexGridItem>
    </FlexGrid>
  )
}

export default MessageDriver
