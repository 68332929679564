import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import React, { useContext } from 'react'
import { capitalize } from 'lodash'
import { LabelXSmall } from 'baseui/typography'
import { Block } from 'baseui/block'
import { CurrentUserContext } from '../../../../homepage/current-user-context'
import NumberButton from '../../../../shared/fields/number-button'

const BlockCountsNotShared = ({ blockCountsNotSharedHelper, setDockBlock }) => {
  const { assignedEquipmentTypes } = useContext(CurrentUserContext)

  const shouldShowElement = (eqTypeId: string) => {
    return Object.keys(blockCountsNotSharedHelper).reduce((sum, appTypeId) => {
      const { limit, count } = blockCountsNotSharedHelper[appTypeId][eqTypeId]
      return sum + limit + count
    }, 0)
  }

  return (
    <Block display="flex" gridColumnGap="scale800" overflow="scrollX" marginBottom="16px">
      <FlexGrid flex={0.3} gridRowGap="scale400">
        <FlexGridItem key="white-space" height="36px" />
        {assignedEquipmentTypes.map(
          eqType =>
            shouldShowElement(eqType.id) && (
              <FlexGridItem
                key={`header-${eqType.id}`}
                display="flex"
                alignItems="center"
                height="36px">
                <LabelXSmall>{capitalize(eqType.name)}</LabelXSmall>
              </FlexGridItem>
            )
        )}
      </FlexGrid>
      {blockCountsNotSharedHelper &&
        Object.keys(blockCountsNotSharedHelper)?.map(appTypeId => (
          <FlexGrid flex={0} gridRowGap="scale400">
            <FlexGridItem key={appTypeId} display="flex" alignItems="center" height="36px">
              <LabelXSmall>{blockCountsNotSharedHelper[appTypeId]?.name}</LabelXSmall>
            </FlexGridItem>
            {assignedEquipmentTypes.map(eqType => {
              const setCount = (count: number) => {
                const newBlockCountsNotSharedHelper = blockCountsNotSharedHelper
                newBlockCountsNotSharedHelper[appTypeId][eqType.id] = {
                  ...newBlockCountsNotSharedHelper[appTypeId][eqType.id],
                  count
                }
                setDockBlock(prevDockBlock => ({
                  ...prevDockBlock,
                  blockCountsNotSharedHelper: newBlockCountsNotSharedHelper
                }))
              }
              return (
                shouldShowElement(eqType.id) && (
                  <FlexGridItem key={`${appTypeId}-${eqType.id}`}>
                    <NumberButton
                      count={blockCountsNotSharedHelper[appTypeId][eqType.id].count}
                      setCount={(count: number) => setCount(count)}
                      limit={blockCountsNotSharedHelper[appTypeId][eqType.id].limit}
                    />
                  </FlexGridItem>
                )
              )
            })}
          </FlexGrid>
        ))}
    </Block>
  )
}

export default BlockCountsNotShared
