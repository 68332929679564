import { HeadingXSmall, ParagraphSmall } from 'baseui/typography'
import useSizing from 'components/shared/hooks/use-sizing'
import { LoginForm } from 'components/shared/login-helpers'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarBlank } from '@phosphor-icons/react'
import {
  BackgroundContainer,
  DynamicLogo,
  LogoContainer,
  MainContainer,
  ResponsiveSignInCard,
  ResponsiveSignInContent,
  SignInContainer,
  SignInCustomParagraph,
  SignInTitle,
  SignIntroSection
} from './SignUp.styled'
import Logo from '../../../components/logo'
import Button from 'components/ui/specific/PrimaryButton'
import { facilityService } from '../../services/facility.service'
import { useHistory } from 'react-router-dom'

const hasWindow = typeof window !== 'undefined'
const getWindowDimensions = () => {
  const width = hasWindow ? window.innerWidth : null
  const height = hasWindow ? window.innerHeight : null
  return {
    width,
    height
  }
}
// eslint-disable-next-line import/prefer-default-export
const MainPanel = ({ shipper }) => {
  const { customBreakPoints } = useSizing()
  const [schedulingLock, setSchedulingLock] = useState(true)
  const [logoType, setLogoType] = useState<any>({})
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    const targetBody = document?.getElementsByTagName('body')
    const targetDom = document?.getElementById('wrapper-container')
    const targetFooter = document?.getElementById('footer-component')

    if (targetDom && targetFooter && targetBody) {
      targetBody[0].style.margin = '0'
      targetDom.classList.remove('container')
      targetFooter.remove()
    }
  }, [])

  const onImgLoad = ({ target: img }) => {
    if (img?.offsetWidth < 150) {
      setLogoType({ mini: true })
    }

    if (img?.offsetWidth / img?.offsetHeight === 1) {
      setLogoType({ square: true })
    }

    if (img?.offsetWidth > 300) {
      setLogoType({ large: true })
    }
  }

  useEffect(() => {
    if (shipper.id) {
      facilityService.checkOpenSchedulingOnFacilities(shipper.id).then(facilities => {
        setSchedulingLock(facilities.length < 1)
      })
    }
  }, [shipper])

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => setWindowDimensions(getWindowDimensions())

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <MainContainer>
      <BackgroundContainer
        backgroundimage={shipper?.activeBackgroundUrl}
        custombreakpoints={customBreakPoints}
        heightsize={windowDimensions.height}
      />

      <ResponsiveSignInCard custombreakpoints={customBreakPoints}>
        <ResponsiveSignInContent heightsize={windowDimensions.height}>
          <SignInContainer>
            <Logo ids={[shipper?.id]} />
            <LogoContainer>
              {shipper?.activeLogoUrl?.length > 0 ? (
                <DynamicLogo
                  onLoad={onImgLoad}
                  mini={logoType?.mini}
                  square={logoType?.square}
                  large={logoType?.large}
                  src={shipper?.activeLogoUrl}
                />
              ) : (
                <SignInTitle>
                  <HeadingXSmall>{shipper.name}</HeadingXSmall>
                </SignInTitle>
              )}
            </LogoContainer>
            <div style={{ alignSelf: 'center' }}>
              <LoginForm
                title={t('Login.LoginForm.Text')}
                signupMethod="password"
                allowSignUp={false}
              />

              <SignIntroSection>
                {shipper.loginText ? (
                  <ParagraphSmall>{shipper.loginText}</ParagraphSmall>
                ) : (
                  <ParagraphSmall>
                    {t('Login.SignInIntroSection.SignInCustomParagraph.DefaultText')}
                  </ParagraphSmall>
                )}
              </SignIntroSection>

              {schedulingLock ? (
                <div />
              ) : (
                <div>
                  <Button
                    $as="a"
                    href="/openscheduling"
                    startEnhancer={<CalendarBlank />}
                    overrides={{
                      BaseButton: {
                        style: ({ $theme }) => ({
                          width: '100%',
                          backgroundColor: $theme.colors.primary,
                          marginBottom: '16px'
                        })
                      }
                    }}>
                    {shipper?.schedulingBtnLabel
                      ? shipper?.schedulingBtnLabel?.toUpperCase()
                      : t('Login.RiCalendarLine.OpenSchedule.Text')}
                  </Button>
                  <ParagraphSmall>
                    {shipper?.schedulingText
                      ? shipper?.schedulingText
                      : `${shipper?.accountName || shipper?.name} ${t('Login.Paragraph3.Text')}`}
                  </ParagraphSmall>
                </div>
              )}
            </div>
          </SignInContainer>
        </ResponsiveSignInContent>
      </ResponsiveSignInCard>
    </MainContainer>
  )
}

export default MainPanel
