import React, { useState, Suspense, useEffect } from "react";
import { Tabs, Tab } from "baseui/tabs";
import { HeadingLevel, Heading } from "baseui/heading";
import MessageDriver from "./message-driver";
import TransportationDiscrepencyReport from "../appointments/tabs/transportation-discrepency-report";
import { AppointmentContext } from "../appointments/appointment-form";
import StyledSpinner from "../shared/styled-spinner";
import authenticatedFetch from "../utils/authenticated-fetch";
import DriverInfo from "../appointments/tabs/driver-info";
import Pretag from "../shared/pretag";
import { truncate } from "../shared/css-mixins";
import { useStyletron } from "baseui";

const CheckinForm = ({ match }) => {
  const checkinId = match.params.handle;
  const [css, theme] = useStyletron();
  const [activeKey, setActiveKey] = React.useState<string>("0");
  const [checkin, setCheckin] = useState<any>(null);
  const [appointment, setAppointment] = useState<any>(null);

  useEffect(() => {
    checkin &&
      checkin.appointmentId &&
      authenticatedFetch({
        path: `/appointments/${checkin.appointmentId}.json`,
      }).then(([json, status]) => setAppointment(json));
  }, [checkin]);

  useEffect(() => {
    authenticatedFetch({
      path: `/checkins/${checkinId}.json`,
    }).then(([json, status]) => setCheckin(json));
  }, []);

  if (!appointment) {
    return <StyledSpinner />;
  }

  return (
    <AppointmentContext.Provider value={{ appointment, setAppointment }}>
      <HeadingLevel>
        <Heading display={["none", "none", "block"]}>
          <div className={css(truncate as any)}>
            {appointment.purchaseOrderIdentifiers}
          </div>
        </Heading>
        <Tabs
          onChange={(params) => {
            setActiveKey(params.activeKey.toString());
          }}
          activeKey={activeKey}
        >
          <Tab title="Driver Information">
            <DriverInfo {...{ appointment, setAppointment }} />
          </Tab>

          <Tab title="Message Driver">
            <MessageDriver checkinId={checkinId} />
          </Tab>
        </Tabs>
      </HeadingLevel>
    </AppointmentContext.Provider>
  );
};

export default CheckinForm;
