import { styled } from 'baseui'

export const RecurringDays = styled('div', () => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  columnGap: '8px',
  width: '100%',
  flexWrap: 'wrap'
}))

export const FlexContainerProps = {
  overrides: {
    Block: {
      style: () => ({
        width: '40%',
        flexGrow: 0
      })
    }
  }
}
