import React, { useState, useEffect, useContext } from 'react'
import { HeadingLevel, Heading } from 'baseui/heading'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Table } from 'baseui/table'
import { Button } from 'baseui/button'
import { useStyletron } from 'baseui'
import authenticatedFetch from '../utils/authenticated-fetch'
import { CurrentUserContext } from '../homepage/current-user-context'
import SchedulerModal from './scheduler-modal'

const InsightsIndex = () => {
  const [css, theme] = useStyletron()
  const [loading, setLoading] = useState<boolean>(true)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { currentUser } = useContext(CurrentUserContext)
  const [appointments, setAppointments] = useState([])

  const [scoreCardLineItems, setScoreCardLineItem] = useState([])

  useEffect(() => {
    currentUser &&
      currentUser.shipperId &&
      authenticatedFetch({
        path: `/shippers/${currentUser.shipperId}/score_cards.json`
      })
        .then(([shipper, status]) => {
          if (status === 200) {
            setScoreCardLineItem(shipper)
            setLoading(false)
          }
        })
        .catch(console.log)
  }, [currentUser])

  return (
    <HeadingLevel>
      <Heading>Insights Scorecard</Heading>
      <Table
        isLoading={loading}
        columns={['Scheduler Name', 'On Time Performance', 'Average Dwell Time']}
        data={scoreCardLineItems.map((scoreCard, index) => {
          const { schedulerName, onTimePerformance, averageDwellTime, appointments } = scoreCard
          return [
            <span
              key={index}
              onClick={() => {
                setAppointments(appointments)
                setIsOpen(true)
              }}
              className={css({ cursor: 'pointer' })}>
              {schedulerName}
            </span>,
            `${(parseFloat(onTimePerformance) * 100).toPrecision(3)}%`,
            averageDwellTime
          ]
        })}
      />
      <SchedulerModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        appointments={appointments}
      />
    </HeadingLevel>
  )
}

export default InsightsIndex
