import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useYardJockeyContext } from '../../contexts/yard-jockey.context'
import Button from 'components/ui/specific/PrimaryButton'
import { Modal, ModalBody, ModalFooter } from 'baseui/modal'
import Input from 'components/ui/generic/Input'
import { Block } from 'baseui/block'

const YardJockeyNotesModal = () => {
  const { t } = useTranslation()
  const {
    notesModalAction,
    notesModalTask,
    actionModalButtonLoading,
    notesModalOpen,
    actions: { setNotesModalOpen, handleButtonClick, setDrawerOpen }
  } = useYardJockeyContext()

  const [notes, setNotes] = useState(undefined)

  const onClick = () => {
    handleButtonClick({ ...notesModalTask, notes }, notesModalAction, () => {
      setNotes(undefined)
      setNotesModalOpen(false, null, null)
      setDrawerOpen(false, null)
    })
  }
  return (
    <Modal
      isOpen={notesModalOpen}
      unstable_ModalBackdropScroll
      onClose={() => setNotesModalOpen(false, null)}>
      <ModalBody>
        <Block
          height="100px"
          justifyContent="center"
          display="flex"
          alignItems="center"
          padding="10px">
          <Input
            value={notes}
            label={t('YardJockey.Modal.Notes.Title.Text')}
            onChange={evt => setNotes(evt.currentTarget.value)}
            maxLength={50}
          />
        </Block>
      </ModalBody>
      <ModalFooter>
        <Button minWidth="150px" isLoading={actionModalButtonLoading} onClick={onClick}>
          {t(`YardJockey.Modal.Notes.Buttons.${notesModalAction}.Text`)}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default YardJockeyNotesModal
