import { useStyletron } from 'baseui'
import styled from 'styled-components'

export const IconContainer = styled('span', ({ $theme }) => ({
  marginRight: '2px',
  fontSize: $theme.sizing.scale600
}))

export const TAGS_TYPES = {
  warning: 'warning',
  primary: 'primary',
  secondary: 'secondary'
}

export const TagStyled = type => {
  return {
    overrides: {
      Text: {
        style: ({ $theme }) => ({
          fontSize: $theme.sizing.scale400,
          color: type == TAGS_TYPES.primary ? $theme.colors.white : $theme.colors.primary,
          display: 'flex'
        })
      },
      Root: {
        style: ({ $theme }) => ({
          backgroundColor:
            type == TAGS_TYPES.warning
              ? $theme.colors.tagWarning
              : type == TAGS_TYPES.primary
              ? $theme.colors.borderSelected
              : $theme.colors.backgroundTertiary,
          marginBottom: 0,
          marginTop: '0',
          marginRight: '0',
          height: '16px'
        })
      }
    }
  }
}

export const TagIconStyled = type => {
  const [css, theme] = useStyletron()
  return css({
    color:
      type == TAGS_TYPES.warning
        ? theme.colors.warning
        : type == TAGS_TYPES.primary
        ? theme.colors.white
        : theme.colors.primary
  })
}
