export const appointmentNotFoundProps = {
  overrides: {
    Block: {
      style: () => ({
        color: '#6f7480'
      })
    }
  }
}

export const searchInputProps = {
  overrides: {
    EndEnhancer: {
      style: ({ $theme }) => ({
        opacity: '0.6',
        backgroundColor: 'transparent'
      })
    }
  }
}
