import React, { useContext } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import NonDriverOnboarding from '../users/onboarding/non-driver-onboarding'
import { CurrentUserContext } from '../homepage/current-user-context'
import Pretag from 'components/shared/pretag'

const UserOnboardingModal = () => {
  const { currentUser } = useContext(CurrentUserContext)

  return (
    <Modal
      isOpen={currentUser && (!currentUser.userType || !currentUser.emailAddress)}
      closeable={false}
      unstable_ModalBackdropScroll={true}>
      <ModalBody>
        <NonDriverOnboarding />
      </ModalBody>
    </Modal>
  )
}

export default UserOnboardingModal
