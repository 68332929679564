import React, { useContext } from 'react'
import StyledSpinner from '../shared/styled-spinner'
import NonDriverOnboarding from './onboarding/non-driver-onboarding'
import DriverOnboarding from './onboarding/driver-onboarding'
import { CurrentUserContext } from '../homepage/current-user-context'

const UserOnboarding = () => {
  const { currentUser } = useContext(CurrentUserContext)

  const OnboardingComponent =
    currentUser && currentUser.userType == 'driver' ? <DriverOnboarding /> : <NonDriverOnboarding />

  if (!currentUser) {
    return <StyledSpinner />
  }

  return OnboardingComponent
}

export default UserOnboarding
