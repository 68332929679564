import React, { useContext } from 'react'
import { useStyletron } from 'baseui'
import LocaleSwitcher from './locale-switcher'
import { FooterContainer } from './Footer.styled'
import { Block } from 'baseui/block'
import { ParagraphXSmall } from 'baseui/typography'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from './current-user-context'
import { isMyQInstance } from '../utils/is-myQ'

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const logoFile = require('../../../assets/images/velostics-logo.svg') as string

const Footer = () => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const { currentUser } = useContext(CurrentUserContext)

  return (
    <FooterContainer
      marginTop={theme.sizing.scale1000}
      background={theme.colors.backgroundSecondary}
      borderTop={theme.borders.border400}
      id="footer-component">
      <div
        className={css({
          padding: theme.sizing.scale1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '1200px',
          margin: '0 auto'
        })}>
        <Block display="flex" gridGap="10px">
          {isMyQInstance([currentUser?.shipperId]) && (
            <ParagraphXSmall
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    color: '#6f7480'
                  })
                }
              }}>
              {t('Footer.PoweredBy')}
            </ParagraphXSmall>
          )}
          <img className={css({ width: '80px' })} src={logoFile} alt="Velostics Logo" />
        </Block>
        <div className={css({ paddingTop: theme.sizing.scale200 })}>
          <LocaleSwitcher />
        </div>
      </div>
    </FooterContainer>
  )
}

export default Footer
