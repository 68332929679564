import React, { useState } from 'react'
import { ButtonProps } from 'baseui/button'
import Button from 'components/ui/generic/Button'
import { useTranslation } from 'react-i18next'

interface DeleteButtonProps {
  label?: string
  onDelete: Function
  buttonProps?: ButtonProps
}

const DeleteButton = ({ label, onDelete, buttonProps }: DeleteButtonProps) => {
  const { t } = useTranslation()
  const [deleteRequested, setDeleteRequested] = useState<boolean>(false)

  const attemptDelete = () => {
    if (!deleteRequested) {
      setDeleteRequested(true)
    } else {
      onDelete()
    }
  }

  return (
    <Button onClick={attemptDelete} {...buttonProps} kind="tertiary">
      {!deleteRequested ? label || t('Common.Fields.Delete.Text') : t('Common.Fields.Confirm.Text')}
    </Button>
  )
}

export default DeleteButton
