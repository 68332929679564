import React from 'react'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'

import EventIcon from 'components/ui/specific/EventIcon'
import { useTranslation } from 'react-i18next'
import { TaskEvent } from 'components/models/Task'
import {
  ContentWrapper,
  Header,
  InformationContainer,
  InformationItem,
  InformationItemCapitalized
} from './TaskEventTooltip.styled'

export const UNASSIGN_VALUE = 'N/A'

const TaskEventTooltip = ({ event }: { event: TaskEvent }) => {
  const {
    trailer: { appointments, number },
    fromDock,
    fromSlot,
    toDock,
    toSlot,
    userName
  } = event

  const appointment = appointments[0]
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  return (
    <Block minWidth="250px" maxWidth="320px" position="relative">
      <ContentWrapper>
        <Header>
          {appointment?.equipmentTypeId && (
            <EventIcon
              event={{
                extendedProps: {
                  equipmentTypeId: appointment?.equipmentTypeId
                }
              }}
            />
          )}
          {appointment?.schedulerName}
        </Header>
      </ContentWrapper>
      <InformationContainer>
        <InformationItemCapitalized>
          <small>{t('YardTask.Kanban.Tooltip.TrailerNumber.Text')}</small>
          <span>{number}</span>
        </InformationItemCapitalized>
        {fromDock?.name ? (
          <InformationItem>
            <small>{t('YardTask.Kanban.Tooltip.From.Dock')}</small>
            <span>{fromDock?.name}</span>
          </InformationItem>
        ) : null}
        {fromSlot?.name ? (
          <InformationItemCapitalized>
            <small>{t('YardTask.Kanban.Tooltip.From.Slot')}</small>
            <span>{fromSlot?.name}</span>
          </InformationItemCapitalized>
        ) : null}
        {toDock?.name ? (
          <InformationItemCapitalized>
            <small>{t('YardTask.Kanban.Tooltip.To.Dock')}</small>
            <span>{toDock?.name}</span>
          </InformationItemCapitalized>
        ) : null}
        {toSlot?.name ? (
          <InformationItemCapitalized>
            <small>{t('YardTask.Kanban.Tooltip.To.Slot')}</small>
            <span>{toSlot?.name}</span>
          </InformationItemCapitalized>
        ) : null}
        <InformationItemCapitalized>
          <small>{t('YardTask.Kanban.Tooltip.AssignTo.Text')}</small>
          <span>{userName || UNASSIGN_VALUE}</span>
        </InformationItemCapitalized>
      </InformationContainer>
    </Block>
  )
}

export default TaskEventTooltip
