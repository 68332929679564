import { Upload } from "baseui/icon";
import React from "react";
import { useStyletron } from "baseui";
import { DirectUploadProvider } from "react-activestorage-provider";
import { Spinner } from "baseui/spinner";
import { StatefulTooltip } from "baseui/tooltip";

const UploadButton = ({ onSuccess, onError }) => {
  const [css, theme] = useStyletron();
  const hiddenFileInput = React.useRef(null);

  return (
    <DirectUploadProvider
      onError={onError}
      onSuccess={(signedIds) => {
        onSuccess(signedIds);
      }}
      render={({ handleUpload, uploads, ready }) => {
        return (
          <div
            className={css({ marginLeft: "2.25px", cursor: "pointer" })}
            onClick={() => {}}
          >
            <input
              multiple
              ref={hiddenFileInput}
              type="file"
              disabled={!ready}
              onChange={(e) => handleUpload(e.currentTarget.files)}
              className={css({ display: "none" })}
            />
            {uploads?.length ? (
              <Spinner size={theme.sizing.scale900} />
            ) : (
              <span
                onClick={() => {
                  hiddenFileInput?.current?.click();
                }}
              >
                <Upload size={theme.sizing.scale900} />
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default UploadButton;
