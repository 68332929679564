import React from 'react'
import { Block } from 'baseui/block'
import { YardJockeyProvider } from '../../contexts/yard-jockey.context'
import YardJockeyHeader from './YardJockeyHeader'
import YardJockeyTable from './YardJockeyTable'
import YardJockeyCompleteModal from './YardJockeyCompleteModal'
import YardJockeyDrawer from './YardJockeyDrawer'
import YardJockeyNotesModal from './YardJockeyNotesModal'

const YardJockey = () => (
  <Block>
    <YardJockeyProvider>
      <YardJockeyHeader />
      <YardJockeyTable />
      <YardJockeyCompleteModal />
      <YardJockeyDrawer />
      <YardJockeyNotesModal />
    </YardJockeyProvider>
  </Block>
)

export default YardJockey
