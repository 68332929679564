import { TrailerProvider } from 'components/contexts/yard-trailer.context'
import React from 'react'
import TrailersContainer from './Trailers'

const Trailers = () => {
  return (
    <TrailerProvider>
      <TrailersContainer />
    </TrailerProvider>
  )
}

export default Trailers
