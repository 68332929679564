import Button from 'components/ui/generic/Button'
import { EN_US, ES, LocaleContext, LocaleString } from 'components/contexts/locale-context'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'translations/i18n'

const LocaleSwitcher = () => {
  const { locale, setLocale } = useContext(LocaleContext)
  const { t } = useTranslation()
  const setLanguage = (language: LocaleString) => {
    setLocale(language)
    i18n.changeLanguage(language)
    localStorage.setItem('i18nextLng', language)
  }

  return (
    <Button
      kind="tertiary"
      IconStart={locale === EN_US ? '🇺🇸' : '🇪🇸'}
      onClick={() => {
        switch (locale) {
          case EN_US:
            setLanguage(ES)
            break
          case ES:
            setLanguage(EN_US)
            break
        }
      }}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            borderColor: 'transparent',
            fontSize: $theme.typography.LabelXSmall.fontSize
          })
        }
      }}>
      {locale === EN_US
        ? t('Common.Button.Language.English.Text')
        : t('Common.Button.Language.Spanish.Text')}
    </Button>
  )
}

export default LocaleSwitcher
