export const SET_SAVING = 'SET_SAVING'
export const SET_LOADING = 'SET_LOADING'
export const SET_CARRIER_USERS = 'SET_CARRIER_USERS'
export const SET_CARRIER_USER_TO_UPDATE = 'SET_CARRIER_USER_TO_UPDATE'
export const CREATE_CARRIER_USER = 'CREATE_CARRIER_USER'
export const UPDATE_CARRIER_USER = 'UPDATE_CARRIER_USER'
export const SEARCH = 'SEARCH'
export const SEARCH_CARRIER_USER = 'SEARCH_CARRIER_USER'
export const SET_INVITE_MODAL_STATE = 'SET_INVITE_MODAL_STATE'
export const SET_EDIT_MODAL_STATE = 'SET_EDIT_MODAL_STATE'
export const SELECT_CARRIER_USER = 'SELECT_CARRIER_USER'
export const UPDATE_STATUS_CARRIER_USER = 'UPDATE_STATUS_CARRIER_USER'
export const DELETE_CARRIER_USER = 'DELETE_CARRIER_USER'
export const UPDATE_SELECTED_USER_AND_DISABLE_FIELDS = 'UPDATE_SELECTED_USER_AND_DISABLE_FIELDS'
export const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER'
export const SET_EDIT_MODAL_STATE_AND_CLEAR_SELECTED_USER =
  'SET_EDIT_MODAL_STATE_AND_CLEAR_SELECTED_USER'

export const carrierUserReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.payload
      }
    }
    case UPDATE_SELECTED_USER_AND_DISABLE_FIELDS: {
      return {
        ...state,
        selectedUser: action.payload.user,
        disableCarrierUserModalFormFields: action.payload.disableFields
      }
    }
    case SET_SAVING: {
      return {
        ...state,
        saving: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case SET_CARRIER_USERS: {
      const carrierUsers = action.payload
      return {
        ...state,
        items: carrierUsers
      }
    }
    case CREATE_CARRIER_USER: {
      return {
        ...state,
        items: [...state.items],
        isEditModalActive: false,
        isInviteModalActive: false
      }
    }
    case SEARCH: {
      return {
        ...state,
        items: action.payload
      }
    }
    case SEARCH_CARRIER_USER: {
      return {
        ...state,
        searchUser: action.payload
      }
    }
    case UPDATE_CARRIER_USER: {
      return {
        ...state,
        isEditModalActive: false,
        isInviteModalActive: false
      }
    }
    case SET_EDIT_MODAL_STATE: {
      return {
        ...state,
        isEditModalActive: action.payload
      }
    }
    case SET_EDIT_MODAL_STATE_AND_CLEAR_SELECTED_USER: {
      return {
        ...state,
        isEditModalActive: false,
        userToUpdate: null
      }
    }
    case SET_INVITE_MODAL_STATE: {
      return {
        ...state,
        isInviteModalActive: action.payload
      }
    }
    case SELECT_CARRIER_USER: {
      return {
        ...state,
        selectedUser: action.payload
      }
    }
    case SET_CARRIER_USER_TO_UPDATE: {
      return {
        ...state,
        userToUpdate: action.payload.user,
        isEditModalActive: action.payload.isEditModalActive
      }
    }
    case UPDATE_STATUS_CARRIER_USER: {
      return {
        ...state,
        userToUpdate: null,
        isEditModalActive: false
      }
    }
    case DELETE_CARRIER_USER: {
      return {
        ...state,
        userToUpdate: null,
        isEditModalActive: false
      }
    }
    default:
      return state
  }
}

const finalCarrierUserReducer = ({ users }, action) => {
  return {
    users: carrierUserReducer(users, action)
  }
}

export default finalCarrierUserReducer
