import React, { useState } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import { HeadingXSmall } from 'baseui/typography'
import { Block } from 'baseui/block'
import { useTranslation } from 'react-i18next'
import StyledSpinner from 'components/shared/styled-spinner'
import useSearchClient from 'components/shared/hooks/use-search-client'
import { SCHEDULER } from 'components/models/User'
import Header from 'components/ui/generic/Header'
import ConnectedPagination from './connected-pagination'
import ConnectedRefinementList from './connected-refinement-list'
import ConnectedHits from './connected-hits'
import { SchedulerUserInstantSearchInput } from './SchedulerUserInstantSearchInput'
import { UserRolesProvider } from '../../contexts/user-roles.context'
import {
  INDEX_NAME,
  QUERY_BY,
  generateFacetFilters
} from '../../constants/typesense-connected-hits'
import FiltersPopover from 'components/ui/specific/FiltersPopover'
import FilterSummary from 'components/appointments/appointment-instantsearch/FilterSummary'

export const SchedulerUserInstantSearch = () => {
  const client = useSearchClient({ queryBy: QUERY_BY })
  const { t } = useTranslation()
  const [numberOfHits, setNumberOfHits] = useState<number>(0)
  const [appliedFilters, setAppliedFilters] = useState({})

  if (!client) {
    return <StyledSpinner />
  }

  return (
    <UserRolesProvider>
      <InstantSearch indexName={INDEX_NAME} searchClient={client}>
        <Header
          title={t('Settings.SideMenu.Titles.VendorCompanyUsers.Text')}
          rightItems={[
            <FiltersPopover tag={Object.entries(appliedFilters).length}>
              <Block
                backgroundColor="#fff"
                overrides={{
                  Block: {
                    style: () => ({
                      maxWidth: '284px'
                    })
                  }
                }}>
                <HeadingXSmall margin="0 0 8px">Filters</HeadingXSmall>
                <Block display="flex" flexDirection="column" gridRowGap="scale400">
                  <ConnectedRefinementList
                    attribute="scheduler_name"
                    setAppliedFilters={setAppliedFilters}
                  />
                  <ConnectedRefinementList
                    attribute="user_role_name"
                    setAppliedFilters={setAppliedFilters}
                  />
                </Block>
              </Block>
            </FiltersPopover>,
            <SchedulerUserInstantSearchInput />
          ]}
        />

        <Configure facetFilters={generateFacetFilters(SCHEDULER)} />

        <Block marginLeft="scale200">
          <Block paddingBottom="scale200" minHeight="60vh">
            {Object.keys(appliedFilters).length > 0 && (
              <FilterSummary
                numberOfHits={numberOfHits}
                appliedFilters={appliedFilters}
                label="user"
              />
            )}
            <ConnectedHits setNumberOfHits={setNumberOfHits} />
          </Block>
          <ConnectedPagination />
        </Block>
      </InstantSearch>
    </UserRolesProvider>
  )
}
