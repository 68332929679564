import React from 'react'
import { StatefulPanel } from 'baseui/accordion'

const VAccordion = ({ children, overrides, ...props }) => {
  return (
    <ul style={{ paddingLeft: '0px' }}>
      <StatefulPanel
        overrides={{
          Header: {
            style: ({ $theme }) => ({
              fontSize: $theme.typography.LabelSmall.fontSize,
              paddingTop: $theme.sizing.scale500,
              paddingBottom: $theme.sizing.scale500,
              paddingLeft: $theme.sizing.scale600,
              paddingRight: $theme.sizing.scale600
            })
          },
          PanelContainer: {
            style: ({ $theme }) => ({
              borderRadius: $theme.borders.inputBorderRadius,
              overflow: 'hidden',
              borderTopWidth: $theme.borders.borderWidth,
              borderBottomWidth: $theme.borders.borderWidth,
              borderLeftWidth: $theme.borders.borderWidth,
              borderRightWidth: $theme.borders.borderWidth,
              borderTopStyle: $theme.borders.borderStyle,
              borderBottomStyle: $theme.borders.borderStyle,
              borderLeftStyle: $theme.borders.borderStyle,
              borderRightStyle: $theme.borders.borderStyle,
              borderTopColor: $theme.borders.border200.borderColor,
              borderBottomColor: $theme.borders.border200.borderColor,
              borderLeftColor: $theme.borders.border200.borderColor,
              borderRightColor: $theme.borders.border200.borderColor
            })
          },
          Content: {
            style: ({ $theme }) => {
              return {
                paddingTop: 0,
                paddingLeft: $theme.sizing.scale600,
                paddingRight: $theme.sizing.scale600,
                paddingBottom: $theme.sizing.scale300
              }
            }
          },
          ...overrides
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}>
        {children}
      </StatefulPanel>
    </ul>
  )
}
export default VAccordion
