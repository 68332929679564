import { OrderPreferencesProvider } from 'components/contexts/order-preferences.context'
import React from 'react'
import OrderPreferences from './OrderPreferences'

const index = () => {
  return (
    <OrderPreferencesProvider>
      <OrderPreferences />
    </OrderPreferencesProvider>
  )
}

export default index
