import React, { useContext } from 'react'
import { useAppointmentContext } from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import { CalendarCheck } from '@phosphor-icons/react'
import { Block } from 'baseui/block'
import VButton from 'components/ui/generic/Button'
import {
  BlockConfirmationButtons,
  BlockConfirmationContainer,
  ConfirmationBody,
  ConfirmationTitle
} from './AppointmentConfirmation.styled'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CurrentUserContext } from '../../homepage/current-user-context'

const AppointmentConfirmation = ({ confirmationIds }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const {
    actions: { setAppointmentState }
  } = useAppointmentContext()

  const { currentUser } = useContext(CurrentUserContext)

  const {
    state: { facility }
  } = useFacilityContext()

  const handleScheduleAnotherAppointment = () => {
    if (!currentUser) {
      history.push('/openscheduling')
    } else {
      history.push('/')
    }
    setAppointmentState({
      confirmationIds: []
    })
  }

  const mapConfirmations = () => {
    const mappedConfirmations = confirmationIds.map(confirmation => {
      const appointmentTypeName = facility.appointmentTypes.find(
        appointment => appointment.id === confirmation.appointmentTypeId
      )?.name
      return { ...confirmation, appointmentTypeName }
    })
    return mappedConfirmations.map(confirmation => {
      return `${confirmation.appointmentTypeName} ${confirmation.confirmationId}`
    })
  }

  return (
    <div>
      <Block height="100px" />
      <BlockConfirmationContainer>
        <CalendarCheck size={120} />
        <Block display="flex" flexDirection="column" justifyContent="flex-start">
          {/** TODO: check if there is an existing component to remove this styles */}
          <ConfirmationTitle>
            {t('Scheduler.Appointments.Finish.SubTitles.YouAreAllSet.Text')}
          </ConfirmationTitle>
          <ConfirmationBody>
            {t('Scheduler.Appointments.Finish.SubTitles.YourAppointment.Text')}
            <span style={{ fontWeight: 700 }}>{` ${mapConfirmations()} `}</span>
            {t('Scheduler.Appointments.Finish.SubTitles.HasBeenScheduled.Text')}
          </ConfirmationBody>
          <BlockConfirmationButtons>
            <VButton kind="primary" onClick={handleScheduleAnotherAppointment}>
              {t('Scheduler.Appointments.Finish.Buttons.ScheduleAnotherAppointment.Text')}
            </VButton>
          </BlockConfirmationButtons>
        </Block>
      </BlockConfirmationContainer>
    </div>
  )
}

export default AppointmentConfirmation
