import React, { useState, useEffect } from 'react'
import { Button } from 'baseui/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import { ParagraphSmall } from 'baseui/typography'
import { Datepicker } from 'baseui/datepicker'
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { fancyToast } from '../../utils'
import authenticatedFetch from '../../utils/authenticated-fetch'
import DocksSelect from '../../shared/fields/docks-select'

interface CreateBlockProps {
  isOpen: boolean
  close: () => void
  facility: any
}

const CreateBlock = (props: CreateBlockProps) => {
  const { isOpen, close, facility } = props

  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [startTime, setStartTime] = React.useState([new Date()])
  const [endTime, setEndTime] = React.useState([new Date()])
  const [docks, setDocks] = useState<any[]>([])

  // TODO(adenta) this should actually use mapped promises and stuff
  const createBlock = async () => {
    setLoading(true)
    docks
      .filter(dock => dock.checked)
      .map(async dock => {
        const [json, status] = await authenticatedFetch({
          path: `/dock_blocks.json`,
          method: 'POST',
          body: {
            dockBlock: {
              name,
              startTime: startTime[0],
              endTime: endTime[0],
              dockId: dock.id
            }
          }
        })
        if (status === 201) {
          fancyToast({ info: 'Block was successfully created.' }, status)
        } else {
          fancyToast(json, status)
        }
        close()
      })
  }

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <ModalHeader>
        <span tabIndex={1}>Create Block</span>
      </ModalHeader>
      <ModalBody>
        <ParagraphSmall>
          Carriers and Brokers will be unable to schedule anything at {facility.name} during this
          window. All times are listed in the <code>{facility.timeZone}</code> time zone.
        </ParagraphSmall>
        <FormControl
          label="Reason"
          caption="Why are you blocking this time off? This will appear on your calendar.">
          <Input
            value={name}
            name="dock_block[reason]"
            onChange={e => setName(e.currentTarget.value)}
          />
        </FormControl>
        <DocksSelect {...{ docks, setDocks, facility }} />
        <FlexGrid flexGridColumnGap="scale200" flexGridColumnCount={2}>
          <FlexGridItem>
            <FormControl label="Start Time">
              <Datepicker
                formatString="MM/dd/yyyy HH:mm"
                timeSelectStart
                value={startTime}
                onChange={({ date }) => setStartTime(Array.isArray(date) ? date : [date])}
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <FormControl label="End Time">
              <Datepicker
                formatString="MM/dd/yyyy HH:mm"
                timeSelectStart
                value={endTime}
                onChange={({ date }) => setEndTime(Array.isArray(date) ? date : [date])}
              />
            </FormControl>
          </FlexGridItem>
        </FlexGrid>
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={loading}
          disabled={+endTime[0] - +startTime[0] <= 0 || !name || !docks.some(dock => dock.checked)}
          onClick={createBlock}>
          Create
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateBlock
