import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useYardScheduleContext } from '../../contexts/yard-schedule.context'
import Select from 'components/ui/generic/Select'
import { userService } from '../../services/user.service'
import { YARD_JOCKEY_AUDIENCE } from '../../models/Role'
import { StatusCodes } from '../../constants/http-status-codes'
import { useDebounce } from 'react-use'
import { Block } from 'baseui/block'

const YardScheduleDropModalYardJockey = () => {
  const { t } = useTranslation()

  const {
    selectedYardJockey,
    actions: { setSelectedYardJockey }
  } = useYardScheduleContext()

  const [options, setOptions] = useState([])
  const [search, setSearch] = useState(null)

  const searchUsers = (name: string) =>
    userService
      .getUsersByRoleAudience([YARD_JOCKEY_AUDIENCE], name)
      .then(([data, result]) => {
        if (result === StatusCodes.OK) {
          setOptions(data)
        }
      })
      .catch(e => console.error(e))

  useEffect(() => {
    searchUsers(null)
  }, [])

  useDebounce(
    () => {
      setSearch(search)
      searchUsers(search)
    },
    200,
    [search]
  )

  return (
    <Block marginTop="20px">
      <Select
        clearable={false}
        searchable={true}
        valueKey="value"
        label={t('YardSchedule.DropModal.AssignTo.Text')}
        options={options}
        value={selectedYardJockey}
        placeholder=""
        onChange={params => setSelectedYardJockey(params?.value[0])}
        onInputChange={evt => {
          setSearch(evt.currentTarget.value)
        }}
      />
    </Block>
  )
}

export default YardScheduleDropModalYardJockey
