import React, { useState, useEffect, useContext } from 'react'
import { Heading, HeadingLevel } from 'baseui/heading'
import { Button } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Input } from 'baseui/input'
import { FormControl } from 'baseui/form-control'
import { ProgressSteps, Step } from 'baseui/progress-steps'
import { Paragraph1 } from 'baseui/typography'
import authenticatedFetch from '../utils/authenticated-fetch'
import { fancyToast } from '../utils'
import AdditionalInformation from '../facilities/book/additional-information'
import { CurrentUserContext } from '../homepage/current-user-context'
import PurchaseOrderSearch from './fields/purchase-order-search'
import { authService } from 'components/services/auth.service'
import { useCurrentFacilityContext } from 'components/contexts/current-facility.context'

const CreateCheckin = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const [checkin, setCheckin] = useState<any>({})
  const [appointment, setAppointment] = useState<any>({})
  const [appointmentType, setAppointmentType] = useState<any>({})
  const { currentFacility } = useCurrentFacilityContext()
  const [coordinates, setCoordinates] = useState<{
    latitude: number
    longitude: number
  }>({ latitude: 0, longitude: 0 })
  const [loading, setLoading] = useState<boolean>(false)
  const [progress, setProgress] = React.useState(0)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      setCoordinates({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      })
    })
  }, [])

  const checkDriverIn = async () => {
    setLoading(true)
    // TODO(adenta) this logic should probably check the facility id first
    const { latitude, longitude } = coordinates
    const body = {
      checkin: {
        purchaseOrderNumber: checkin.purchaseOrderNumber.trim(),
        latitude,
        longitude,
        appointmentId: appointment.id,
        facilityId: (currentFacility && currentFacility.id) || appointment.facilityId
      }
    }
    const [checkinJson, checkinStatus] = await authenticatedFetch({
      path: `/checkins.json`,
      method: 'POST',
      body
    })

    // TODO(adenta) we shoudl be able to run these in paralell
    // This is also happening, to set the driver questions.
    if (checkinStatus === 201 && appointment.answersAttributes) {
      await authenticatedFetch({
        path: `/appointments/${appointment.id}.json`,
        method: 'PATCH',
        body: {
          appointment
        }
      })
    }

    fancyToast(
      {
        info: `you have been successfully checked in, and will be contacted at ${checkinJson.phoneNumber}.`
      },
      checkinStatus
    )
    if (checkinStatus === 201) {
      setProgress(2)
      setTimeout(authService.logout, 15000)
    }

    setLoading(false)
  }

  const preCheckin = async () => {
    setLoading(true)

    const param = checkin.purchaseOrderId
      ? `purchase_order_id=${checkin.purchaseOrderId}`
      : `purchase_order_number=${encodeURIComponent(checkin.purchaseOrderNumber)}`

    const [json, status] = await authenticatedFetch({
      path: `/checkins/pre_checkin.json?${param}`
    })

    if (status === 200) {
      setAppointment(json.appointment)
      setProgress(1)

      if (json.appointmentType) {
        setAppointmentType(json.appointmentType)
      }
    } else {
      fancyToast(json, status)
      setCheckin({ ...checkin, purchaseOrderNumber: '' })
    }

    setLoading(false)
  }

  const notifyFacility = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `checkins/notify_facility.json`,
      method: 'POST'
    })

    if (status == 200) {
      setProgress(2)
      setTimeout(authService.logout, 45000)
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  return (
    <>
      <HeadingLevel>
        <Heading>Driver Check-in</Heading>
        <ProgressSteps current={progress}>
          <Step title="Load Lookup">
            <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale800">
              <FlexGridItem>
                <FormControl label={() => 'Unique Load Identifier'}>
                  <PurchaseOrderSearch
                    {...{
                      checkin,
                      setCheckin
                    }}
                    onClickOther={notifyFacility}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl
                  label={() => 'Phone Number'}
                  caption="phone number registered on account creation">
                  <Input
                    disabled
                    type="tel"
                    name="checkin[phoneNumber]"
                    value={currentUser && currentUser.phoneNumber}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <Button
                  disabled={!checkin.purchaseOrderNumber}
                  isLoading={loading}
                  onClick={preCheckin}>
                  Find Load
                </Button>
              </FlexGridItem>
            </FlexGrid>
          </Step>
          <Step title="Additional Information">
            <AdditionalInformation
              {...{ appointment, setAppointment }}
              afterSubmit={checkDriverIn}
              appointmentType={appointmentType}
              userType={currentUser && currentUser.userType}
              finishButtonLabel="Check-in"
              loading={loading}
            />
          </Step>
          <Step title="Finish">
            {appointment?.id ? (
              <Paragraph1>You have been checked in!</Paragraph1>
            ) : (
              <Paragraph1>
                The facility manager has been notified that you are here, and will be reaching out
                shortly.
              </Paragraph1>
            )}
          </Step>
        </ProgressSteps>
      </HeadingLevel>
    </>
  )
}

export default CreateCheckin
