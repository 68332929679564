import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { LabelXSmall } from 'baseui/typography'
import { ButtonGroup as BaseButtonGroup, SIZE } from 'baseui/button-group'
import RadioFiller from 'components/ui/generic/Radio'

const CHECKBOX = 'checkbox'
const RADIO = 'radio'

const ButtonGroup = ({
  buttons,
  label,
  mode = RADIO,
  overrides,
  value = mode === CHECKBOX ? [] : '',
  onChange,
  visibility = true,
  disabled = false,
  selected,
  onClick,
  children
}) => {
  const [selectedButton, setSelectedButton] = useState(value)

  const handleButtonSelect = selectedValue => {
    if (mode === CHECKBOX) {
      const arrayPrev = Array.isArray(selectedButton) ? selectedButton : [selectedButton]
      const isSelected = arrayPrev.includes(selectedValue)
      const newSelected = isSelected
        ? arrayPrev.filter(item => item !== selectedValue)
        : [...arrayPrev, selectedValue]
      onChange?.(newSelected)
      setSelectedButton(newSelected)
    } else {
      setSelectedButton(selectedValue)
      onChange?.(selectedValue)
    }
  }

  useEffect(() => {
    if (value && ((mode === CHECKBOX && value.length > 0) || mode === RADIO)) {
      setSelectedButton(value)
    }
  }, [value])

  // Memoize the RadioFiller component to prevent unnecessary re-renders
  const MemoizedRadioFiller = React.memo(({ checked }: { checked: boolean }) => (
    <RadioFiller checked={checked} />
  ))

  return (
    <>
      <LabelXSmall
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              fontSize: '11px',
              color: $theme.colors.text,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '12px',
              opacity: 0.6,
              marginBottom: '12px',
              visibility: visibility ? 'visible' : 'hidden'
            })
          }
        }}>
        {label}
      </LabelXSmall>
      <BaseButtonGroup
        disabled={disabled}
        mode={mode}
        selected={selected}
        size={SIZE.mini}
        onClick={onClick}
        overrides={{
          Root: {
            style: {
              marginBottom: '16px',
              visibility: visibility ? 'visible' : 'hidden',
              gap: '8px',
              ...overrides?.Root?.style
            }
          },
          ...overrides
        }}>
        {children}
      </BaseButtonGroup>
    </>
  )
}

export default ButtonGroup
