import React from 'react'
import {
  DockModalAppointmentTypeContainer,
  DockModalAppointmentType
} from './DockModalAppointmentTypeCheckboxes.styled'
import { capitalize } from 'lodash'

import Checkbox from 'components/ui/generic/Checkbox'

type Props = {
  loading: boolean
  disabled?: boolean
  onChange: (ids: string[]) => void
  equipmentTypes: any[]
  dock: any
}

export const DockModalEquipmentTypeCheckboxes = ({
  dock,
  disabled = true,
  equipmentTypes,
  loading,
  onChange
}: Props) => {
  const equipmentTypesFromDock =
    dock?.dockEquipmentTypesAttributes
      ?.filter(det => !det._destroy)
      ?.map(det => det.equipmentTypeId) || []

  return (
    <DockModalAppointmentTypeContainer>
      {equipmentTypes?.map((equipmentType, index) => {
        const { id } = equipmentType
        return (
          <DockModalAppointmentType>
            <Checkbox
              disabled={disabled || loading}
              checked={equipmentTypesFromDock.filter(eqTypesDockId => eqTypesDockId === id)[0]}
              onChange={evt => {
                onChange(
                  evt.currentTarget.checked
                    ? dock?.dockEquipmentTypesAttributes
                      ? [...dock?.dockEquipmentTypesAttributes, { equipmentTypeId: id }]
                      : [{ equipmentTypeId: id }]
                    : [
                        ...dock?.dockEquipmentTypesAttributes?.map(det => {
                          if (det.equipmentTypeId === id) {
                            return {
                              ...det,
                              _destroy: true
                            }
                          }
                          return det
                        })
                      ]
                )
              }}
              label={capitalize(equipmentType?.name)}
              key={index}
            />
          </DockModalAppointmentType>
        )
      })}
    </DockModalAppointmentTypeContainer>
  )
}
