import React, { createContext, useContext, useReducer } from 'react'
import { userRoleService } from '../services/user-roles.service'
import userRolesReducers, {
  SEARCH,
  SELECT_ROLE,
  SET_LOADING,
  SET_MODAL_STATE,
  SET_MODAL_TYPE,
  SET_ROLES,
  SET_ROLE_TO_UPDATE,
  UPDATE_ROLE
} from '../reducers/user-roles.reducer'
import { fancyToast } from 'components/utils'
import { Role } from '../models/Role'
import i18n from 'translations/i18n'
import { StatusCodes } from '../constants/http-status-codes'

interface UserRolesContextProps {
  roles: {
    items?: Array<Role>
    loading: boolean
    saving: boolean
    selectedRole: Role
    roleToUpdate?: Role
    modalType: string
    isModalActive: boolean
  }
}

const initialState: UserRolesContextProps = {
  roles: {
    loading: false,
    saving: false,
    selectedRole: {},
    roleToUpdate: undefined,
    modalType: 'Create',
    isModalActive: false
  }
}

export const UserRolesContext = createContext({} as any)

export const UserRolesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userRolesReducers, initialState)

  const setModalType = value => {
    dispatch({ type: SET_MODAL_TYPE, payload: value })
  }

  const setLoading = value => {
    dispatch({ type: SET_LOADING, payload: value })
  }

  const setRoles = roles => {
    dispatch({ type: SET_ROLES, payload: roles })
  }

  const setRoleToUpdate = (role, isModalActive) => {
    dispatch({ type: SET_ROLE_TO_UPDATE, payload: { role, isModalActive } })
  }

  const createRole = async role => {
    setLoading(true)

    const [data, status] = await userRoleService.createRole(role)

    if (status <= StatusCodes.CREATED) {
      fancyToast(
        {
          info: i18n.t('Common.Info.Interpolated.Text', {
            model: i18n.t('Common.ModelType.UserRole.Text'),
            action: i18n.t('Common.Actions.Created.Text')
          })
        },
        200
      )
    } else {
      fancyToast({ info: data?.message || i18n.t('Common.Errors.Default.Text') }, 412)
    }

    setLoading(false)
  }

  const updateRole = async (role: Role) => {
    setLoading(true)

    const [data, status] = await userRoleService.updateRole(role)

    if (status >= 400) {
      setLoading(false)
      return fancyToast({ info: data?.message || i18n.t('Common.Errors.Default.Text') }, 500)
    } else {
      fancyToast(
        {
          info: i18n.t('Common.Info.Interpolated.Text', {
            model: i18n.t('Common.ModelType.UserRole.Text'),
            action: i18n.t('Common.Actions.Updated.Text')
          })
        },
        200
      )
      dispatch({ type: UPDATE_ROLE, payload: data })
    }
    setRoleToUpdate(undefined, false)
    setLoading(false)
  }

  const search = async (search, audience) => {
    setLoading(true)
    const [data, status] = await userRoleService.getUserRoles(search, audience)
    dispatch({ type: SEARCH, payload: data })

    setLoading(false)
  }

  const selectedRole = (role: Role) => {
    dispatch({ type: SELECT_ROLE, payload: role })
  }

  const setModalState = value => {
    dispatch({ type: SET_MODAL_STATE, payload: value })
  }

  const actions = {
    setModalState,
    setModalType,
    setRoles,
    search,
    createRole,
    updateRole,
    selectedRole,
    setRoleToUpdate
  }

  return (
    <UserRolesContext.Provider value={{ ...state, actions }}>{children}</UserRolesContext.Provider>
  )
}

export const useUserRolesContext = () => useContext(UserRolesContext)
