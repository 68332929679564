import { styled } from 'baseui'

export const ShipperSettingsImageContainer = styled('div', ({ type, isActive, $theme }) => ({
  position: 'relative',
  display: 'flex',
  borderStyle: 'dashed',
  borderWidth: '1px',
  borderColor: isActive ? '#FBAD39' : 'gray',
  width: type === 'logo' ? '138px' : undefined,
  height: type === 'logo' ? '44px' : '240px',
  marginRight: type === 'logo' ? '10px' : '20px',
  marginTop: '16px',
  cursor: 'pointer',
  transition: '0.5s'
}))

export const CustomLabelForm = styled('label', ({ $theme }) => ({
  fontSize: $theme.typography.LabelXSmall.fontSize,
  fontWeight: $theme.typography.LabelXSmall.fontWeight
}))

export const CustomRemoveLabel = styled('p', ({ isLogo }) => ({
  position: 'absolute',
  marginTop: '-24px',
  marginLeft: isLogo ? '118px' : '200px',
  fontFamily: 'Source Sans Pro',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '8px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textDecorationLine: 'underline',
  color: '#326AF0'
}))

export const CustomPreviewImage = styled('div', ({ $theme, type }) => ({
  position: 'relative',
  opacity: '0.5',
  display: 'flex',
  borderStyle: 'dashed',
  borderColor: $theme.colors.gray,
  borderWidth: '1px',
  width: type === 'logo' ? '138px' : '220px',
  height: type === 'logo' ? '44px' : '240px',
  marginRight: type === 'logo' ? '10px' : '20px',
  marginTop: '8px',
  cursor: 'pointer',
  zIndex: 1,
  padding: '4px',
  borderRadius: '4px'
}))

export const CustomTrashIcon = styled('div', ({ $theme, isLogo }) => ({
  position: 'absolute',
  top: '-8px',
  right: '-8px',
  padding: '4px',
  cursor: 'pointer',
  backgroundColor: $theme.colors.primary,
  borderRadius: '50%',
  width: '16px',
  height: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: '0.5s',
  ':hover': {
    backgroundColor: $theme.colors.accent
  }
}))
