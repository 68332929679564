import React, { useEffect, useState } from 'react'
import { CyberTable } from 'cyber/table'
import { useStyletron } from 'baseui'
import { find } from 'lodash'
import { ParagraphLarge } from 'baseui/typography'
import { FileSearch } from '@phosphor-icons/react'
import { truncate } from 'components/shared/css-mixins'
import { StyledLink } from 'baseui/link'
import { appointmentTypeService } from 'components/services/appointment-type.service'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { DockModal } from '../DockModal'

const COLUMNS_SIZES = ['auto', 'auto', '80px', 'auto']

const getEquipmentTypes = dock =>
  dock.equipmentTypes.map(eqType => capitalize(eqType.name)).join(', ')

const DocksSearchHits = ({ hits, includeFacility = true, onSearch }) => {
  const [css, theme] = useStyletron()
  const [modalOpen, setModalOpen] = useState(false)
  const [appointmentTypes, setAppointmentTypes] = useState([])
  const [selectedDock, setSelectedDock] = useState(null)
  const { t } = useTranslation()

  const COLUMNS = [
    t('Docks.Table.Columns.Name.Label.Text'),
    t('Docks.Table.Columns.EquipmentType.Label.Text'),
    t('Docks.Table.Columns.isEnabled.Label.Text'),
    t('Docks.Table.Columns.AppointmentTypes.Label.Text')
  ]

  useEffect(() => {
    appointmentTypeService.getAppointmentTypes().then(types => setAppointmentTypes(types))
  }, [])

  if (hits.length === 0) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: theme.sizing.scale1600
        })}>
        <FileSearch size={theme.sizing.scale2400} />
        <ParagraphLarge>{t('Docks.Table.NoDocksFound.Text')}</ParagraphLarge>
      </div>
    )
  }

  return (
    <>
      <DockModal
        open={modalOpen}
        selectedDock={selectedDock}
        onModalClose={() => {
          onSearch()
          setModalOpen(false)
        }}
        appointmentTypes={appointmentTypes}
      />
      <CyberTable
        columns={
          includeFacility ? [...COLUMNS, t('Docks.Table.Columns.Facility.Label.Text')] : COLUMNS
        }
        gridTemplateColumns={includeFacility ? [...COLUMNS_SIZES, '250px'] : COLUMNS_SIZES}
        data={hits.map(dock => {
          const { name, enabled, appointmentTypeIds, commodityTypes, facilityName } = dock
          const commodityTypeString = commodityTypes.join(', ')
          const rowColumns = [
            <StyledLink
              className={css({ cursor: 'pointer', textDecoration: 'none !important' })}
              onClick={() => {
                setModalOpen(true)
                setSelectedDock(dock)
              }}>
              {name}
            </StyledLink>,
            getEquipmentTypes(dock),
            enabled
              ? t('Docks.Table.Columns.isEnabled.Items.Yes.Text')
              : t('Docks.Table.Columns.isEnabled.Items.No.Text'),
            appointmentTypeIds
              .map(appointmentTypeId => find(appointmentTypes, { id: appointmentTypeId })?.name)
              .join(', ')
          ]
          if (includeFacility) {
            rowColumns.push(facilityName)
          }
          return rowColumns
        })}
      />
    </>
  )
}

export default DocksSearchHits
