import { useStyletron } from 'baseui'

import React from 'react'
import Checkbox from 'components/ui/generic/Checkbox'

const ChildItem = ({ i, isChecked, label, updateItem }) => {
  const [css, theme] = useStyletron()
  return (
    <div
      className={css({
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.sizing.scale0
      })}
      key={i}>
      <Checkbox label={label} checked={isChecked} onChange={updateItem} />
      {/* <span>({item.count})</span> */}
    </div>
  )
}

export default ChildItem
