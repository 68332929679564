import authenticatedFetch from 'components/utils/authenticated-fetch'
import http from 'components/utils/authenticated-fetch'

class AppointmentTypeService {
  getAppointmentTypes = (): Promise<any> =>
    http({
      path: '/appointment_types.json'
    }).then(([appointmentTypes]) => appointmentTypes)

  getCachedAppointmentTypes = (): Promise<any> =>
    authenticatedFetch({
      path: `/search.json`,
      method: 'POST',
      body: {
        search: {
          indexName: 'appointment_type',
          params: {
            query: ''
          }
        }
      }
    })

  getAppointmentType = (id: string): Promise<any> =>
    http({
      path: `/appointment_types/${id}.json`
    }).then(([data]) => data)

  updateAppointmentType = (appointmentType: Record<string, unknown>): Promise<[any, number]> =>
    http({
      path: `/appointment_types/${appointmentType.id}.json`,
      method: 'PATCH',
      body: {
        appointmentType
      }
    })

  deleteAppointmentType = (id: string): Promise<[any, number]> =>
    http({
      path: `/appointment_types/${id}.json`,
      method: 'DELETE'
    })

  getInboundAppointmentTypes = (): Promise<any> => {
    return http({
      path: '/appointment_types/inbound.json'
    }).then(([appointmentTypes]) => appointmentTypes)
  }

  getOutboundAppointmentTypes = (): Promise<any> => {
    return http({
      path: '/appointment_types/outbound.json'
    }).then(([appointmentTypes]) => appointmentTypes)
  }
}

export const appointmentTypeService = new AppointmentTypeService()
