import { useStyletron } from 'baseui'
import { TableProps } from 'baseui/table'
import { StyledTable, StyledHeadCell } from 'baseui/table-grid'
import StyledSpinner from 'components/shared/styled-spinner'
import React, { useState } from 'react'
import { CiberStyledRow } from './CiberStyledRow'

type Props = TableProps & {
  gridTemplateColumns?: string[]
}

export const CyberTable = ({ data, columns, isLoading, gridTemplateColumns }: Props) => {
  const [css, theme] = useStyletron()
  const [hoverIndex, setHoverIndex] = useState(null)

  return (
    // I couldn't figure out how to get maxHeight or height: minmax() to work
    // for this table, so I just did this. if we fix the height, and you have
    // a small number of rows, the header row gets expanded too and it looks weird.
    <div className={css({ height: data.length <= 10 ? 'auto' : '80vh' })}>
      <StyledTable
        role="grid"
        $gridTemplateColumns={(gridTemplateColumns || Array(columns.length).fill('auto')).join(
          ' '
        )}>
        <div role="row" className={css({ display: 'contents', borderBottom: '1px solid black' })}>
          {columns.map((column, i) => (
            <StyledHeadCell
              title={column}
              key={i}
              overrides={{
                HeadCell: {
                  style: () => {
                    return {
                      boxShadow: 'none',
                      fontSize: theme.typography.LabelXSmall.fontSize,
                      paddingHorizontal: theme.sizing.scale200,
                      paddingVertical: theme.sizing.scale200
                    }
                  }
                }
              }}>
              {column}
            </StyledHeadCell>
          ))}
        </div>
        {data.map((row, i) => (
          <CiberStyledRow
            key={i}
            index={i}
            data={row}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
          />
        ))}
      </StyledTable>
      {isLoading && <StyledSpinner />}
    </div>
  )
}
