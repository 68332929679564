import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { ParagraphSmall } from 'baseui/typography'
import React from 'react'
import { DockCapacity } from '../../models/DockCapacity'
import { FormControl } from 'baseui/form-control'
import { DockCapacitiesTable, DockCapacitySelect } from './DockCapacitiesTable'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'

const DockCapacityContainer = ({
  equipmentTypes,
  appointmentTypes,
  appointmentPreference,
  dockCapacitiesAttributes,
  setAppointmentPreference,
  secondCapacityLayer
}) => {
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  return equipmentTypes?.length === 0 || appointmentTypes?.length === 0 ? (
    <div style={{ marginTop: '35px', width: '100%' }}>
      <ParagraphSmall color="negative">
        {t('AppointmentPreferences.DockCapacitiesTable.NoAppointmentOrDocks.Text')}{' '}
        {secondCapacityLayer
          ? t('AppointmentPreferences.DockCapacitiesTable.SecondCapacityLayer.Text')
          : t('AppointmentPreferences.DockCapacitiesTable.EquipmentCapacity.Text')}
        .
      </ParagraphSmall>
    </div>
  ) : (
    <>
      {!secondCapacityLayer && (
        <ParagraphSmall>
          {t('AppointmentPreferences.DockCapacitiesTable.NoSecondCapacityLayer.Text')}{' '}
        </ParagraphSmall>
      )}
      {appointmentPreference?.equipmentCapacityShared ? (
        <>
          <FlexGridItem>
            <ParagraphSmall>
              {t('AppointmentPreferences.DockCapacitiesTable.AllEquipmentTypes.Text')}
            </ParagraphSmall>
          </FlexGridItem>
          <FlexGrid
            flexGridColumnCount={5}
            flexGridRowGap="scale200"
            flexGridColumnGap="scale200"
            width="100%">
            {dockCapacitiesAttributes.map((dockCapacity: DockCapacity, index: number) => {
              if (dockCapacity.appointmentTypeId === null) {
                return (
                  <FlexGridItem key={dockCapacity.id}>
                    <FormControl label={capitalize(dockCapacity?.equipmentType.name)}>
                      <DockCapacitySelect
                        equipmentType={dockCapacity?.equipmentType}
                        appointmentTypeId={null}
                        dockCapacitiesAttributes={dockCapacitiesAttributes}
                        setAppointmentPreference={setAppointmentPreference}
                        appointmentPreference={appointmentPreference}
                        dockCapacityLimits={appointmentPreference?.dockCapacitiesLimitsShared}
                        dockCapacityProp={dockCapacity}
                        dockCapacitiesAttributesIndex={index}
                        secondCapacityLayer={secondCapacityLayer}
                      />
                    </FormControl>
                  </FlexGridItem>
                )
              }
            })}
          </FlexGrid>
        </>
      ) : (
        <DockCapacitiesTable
          appointmentTypes={appointmentTypes}
          equipmentTypes={equipmentTypes}
          dockCapacitiesAttributes={dockCapacitiesAttributes}
          setAppointmentPreference={setAppointmentPreference}
          appointmentPreference={appointmentPreference}
          secondCapacityLayer={secondCapacityLayer}
        />
      )}
    </>
  )
}

export default DockCapacityContainer
