import React from 'react'
import { Block } from 'baseui/block'
import { ButtonProps } from 'baseui/button'
import { Minus, Plus } from '@phosphor-icons/react'
import { LabelSmall, LabelXSmall } from 'baseui/typography'
import Button from 'components/ui/generic/Button'

type Props = ButtonProps & {
  count: number
  label?: string
  setCount: Function
  limit?: number
  inferiorLimit?: number
  reductionFraction?: number
  incrementFraction?: number
}

const NumberButton = ({
  count,
  setCount,
  limit,
  label,
  inferiorLimit = 0,
  reductionFraction = 1,
  incrementFraction = 1,
  ...props
}: Props) => (
  <div style={{ position: 'relative' }}>
    <Block
      width="100px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center">
      <Button
        shape="circle"
        {...props}
        onClick={() =>
          count - reductionFraction >= inferiorLimit && setCount(count - reductionFraction)
        }>
        <Minus />
      </Button>
      <Block display="flex" alignItems="center" justifyContent="center" flex="0 0 30px">
        <LabelSmall>{count || 0}</LabelSmall>
      </Block>
      <Button
        shape="circle"
        {...props}
        onClick={() => {
          if (limit && count + incrementFraction >= limit) {
            return
          }
          setCount(count + incrementFraction)
        }}>
        <Plus />
      </Button>
    </Block>
    {label && (
      <div style={{ position: 'absolute', top: '17px', left: '115px' }}>
        <LabelXSmall>{label}</LabelXSmall>
      </div>
    )}
  </div>
)

export default NumberButton
