import moment from 'moment-timezone'
import { DEFAULT_LOCALE_STRING_LANGUAGE } from '../constants/default-locale-string-language'

const numberOfDigits = '2-digit'

class TimeFormatter {
  formatKey: string

  constructor(
    format:
      | 'shortTime'
      | 'localCalendarTime'
      | 'localCalendarTime12h'
      | 'shortDate'
      | 'fullDate'
      | 'humanDate'
      | 'shortHumanDate'
      | 'humanDateAndTime'
  ) {
    this.formatKey = format
  }

  public format(date: Date | string, timeZone?) {
    if (typeof timeZone === 'undefined') {
      timeZone = moment.tz.guess()
    }
    if (typeof date === 'string') {
      date = new Date(date)
    }

    switch (this.formatKey) {
      case 'shortTime':
        return new Date(date).toLocaleTimeString(DEFAULT_LOCALE_STRING_LANGUAGE, {
          hour: numberOfDigits,
          minute: numberOfDigits,
          timeZoneName: 'short',
          timeZone
        })
      case 'localCalendarTime':
        // `hourCycle` is pretty new, not in the TS defs. Likewise it dosen't work in Safari.
        // If we use `hour12` instead of `hourCycle`, then hour12 takes priority. This means that we would see
        // `00:00` instead of `24:00` at the beginning of the day. As such, we are deciding to not worry about Safari
        // and are ok that it will be formatted with AM/PM times, in favor of not screwing up `00:00`.        dayHeaderContent={DayHeaderContent}
        return new Date(date).toLocaleTimeString(DEFAULT_LOCALE_STRING_LANGUAGE, {
          hour: numberOfDigits,
          minute: numberOfDigits,
          hourCycle: 'h23',
          timeZone
        } as any)

      case 'localCalendarTime12h':
        // `hourCycle` is pretty new, not in the TS defs. Likewise it dosen't work in Safari.
        // If we use `hour12` instead of `hourCycle`, then hour12 takes priority. This means that we would see
        // `00:00` instead of `24:00` at the beginning of the day. As such, we are deciding to not worry about Safari
        // and are ok that it will be formatted with AM/PM times, in favor of not screwing up `00:00`.        dayHeaderContent={DayHeaderContent}
        return new Date(date).toLocaleTimeString(DEFAULT_LOCALE_STRING_LANGUAGE, {
          hour: numberOfDigits,
          minute: numberOfDigits,
          hour12: true,
          timeZone
        } as any)
      case 'shortDate':
        return new Date(date).toLocaleDateString(DEFAULT_LOCALE_STRING_LANGUAGE, {
          hourCycle: 'h23',
          timeZone
        } as any)
      case 'fullDate':
        return `${new Date(date).toLocaleDateString(DEFAULT_LOCALE_STRING_LANGUAGE, {
          timeZone
        })} ${new TimeFormatter('shortTime').format(date, timeZone)}`
      case 'humanDate':
        return `${new Date(date).toLocaleDateString(DEFAULT_LOCALE_STRING_LANGUAGE, {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone
        })}`
      case 'humanDateAndTime':
        return `${new Date(date).toLocaleDateString(DEFAULT_LOCALE_STRING_LANGUAGE, {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          hour: numberOfDigits,
          minute: numberOfDigits,
          hour12: true,
          timeZone
        })}`
      case 'shortHumanDate':
        return `${new Date(date).toLocaleDateString(DEFAULT_LOCALE_STRING_LANGUAGE, {
          month: 'short',
          weekday: 'short',
          day: 'numeric',
          timeZone
        })}`
      default:
        break
    }
  }

  public formatRange(start: Date, end: Date, timeZone: string) {
    return `${new Date(start).toLocaleTimeString(DEFAULT_LOCALE_STRING_LANGUAGE, {
      hour: numberOfDigits,
      minute: numberOfDigits
    })} to ${new Date(end).toLocaleTimeString(DEFAULT_LOCALE_STRING_LANGUAGE, {
      timeZoneName: 'short',
      hour: numberOfDigits,
      minute: numberOfDigits
    })}`
  }

  public getDaysElapsed(date: Date) {
    if (date) {
      const currentDate = new Date()
      const givenDate = new Date(date)
      const diffInMilliseconds = currentDate.getTime() - givenDate.getTime()
      const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24))
      return diffInDays
    }
    return null
  }
}

export default TimeFormatter
