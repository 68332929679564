import React, { useState, useEffect, useCallback } from 'react'
import Select from 'components/ui/generic/Select'
import useIsMounted from 'components/hooks/use-is-mounted'
import { useTranslation } from 'react-i18next'
import { useTasksContext } from 'components/contexts/tasks.context'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { facilityService, slotService } from 'components/services'

const TO_OPTIONS_DOCK = 'dock'

const DestinationOption = [{ label: 'Dock Doors', id: TO_OPTIONS_DOCK }]

const SelectToField = () => {
  const { state, actions } = useTasksContext()
  const {
    taskModal: { task }
  } = state
  const { setTask } = actions

  const [loading, setLoading] = useState(false)
  const [hits, setHits] = useState({})
  const [hitsDock, setHitsDock] = useState([])
  const [options, setOptions] = useState([])
  const [toOption, setToOption] = useState<string>(null)
  const { t } = useTranslation()

  const convertToSelect = array => {
    return Array.isArray(array) ? array.map(item => ({ label: item.name, id: item.id })) : []
  }

  const groupedByZone = array => {
    const grouped = {}
    array.forEach(current => {
      const zone = current.zone
      if (!grouped[zone]) {
        grouped[zone] = []
      }
      grouped[zone].push({ label: current.name, id: current.id })
    })
    return grouped
  }

  const formatZones = () => {
    return Object.keys(hits).map(zone => ({
      label: zone,
      id: zone
    }))
  }

  const fetchDocks = useCallback(async () => {
    if (task?.facilityId) {
      setLoading(true)
      const json = await facilityService.getFacilityDocks(task?.facilityId)
      setHitsDock(convertToSelect(json))
      setLoading(false)
    }
  }, [task?.facilityId, toOption])

  const fetchSlots = useCallback(async () => {
    if (task?.facilityId) {
      setLoading(true)
      const [json, _status] = await slotService.getAllSlots(task?.facilityId)
      if (json.results) {
        setHits({
          ...groupedByZone(json.results)
        })
      }
      setLoading(false)
    }
  }, [task?.facilityId, toOption])

  const clearSelect = () => {
    setTask({
      ...task,
      toDockId: null,
      toSlotId: null
    })
  }

  useEffect(() => {
    setHits({})
    setHitsDock([])
    fetchSlots()
    fetchDocks()
  }, [task?.facilityId])

  useEffect(() => {
    setOptions([...DestinationOption, ...formatZones()])
    if (!toOption) {
      setDefaultToOption()
    }
  }, [hits])

  const setDefaultToOption = () => {
    if (task?.toSlot) {
      setToOption(task?.toSlot?.zone)
    } else {
      setToOption(TO_OPTIONS_DOCK)
    }
  }

  const onChange = option => {
    if (toOption === TO_OPTIONS_DOCK) {
      setTask({
        ...task,
        toDockId: option.id
      })
    } else {
      setTask({
        ...task,
        toSlotId: option.id
      })
    }
  }

  const getValue = () => {
    if (toOption === TO_OPTIONS_DOCK) {
      return hitsDock.find(f => f.id === task?.toDockId) || []
    } else {
      return hits[toOption]?.find(f => f.id === task?.toSlotId) || []
    }
  }

  const getLabel = () => {
    if (toOption === TO_OPTIONS_DOCK) {
      return t('Common.Fields.Dock.Text')
    }
    return options.find(option => option.id === toOption)?.label
  }

  return (
    <FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap={'scale600'}>
      <FlexGridItem>
        <FormControl>
          <Select
            maxDropdownHeight="250px"
            label={t('YardTask.Kanban.TaskModal.Fields.To.Text')}
            value={options.find(f => f.id === toOption)}
            searchable={false}
            clearable={false}
            placeholder=""
            onChange={({ option }) => {
              setToOption(option.id)
              clearSelect()
            }}
            options={options}
          />
        </FormControl>
      </FlexGridItem>
      <FlexGridItem>
        <FormControl>
          <Select
            isLoading={loading}
            maxDropdownHeight="250px"
            label={getLabel()}
            value={getValue()}
            searchable={false}
            clearable={false}
            placeholder=""
            onChange={({ option }) => {
              onChange(option)
            }}
            options={toOption === TO_OPTIONS_DOCK ? hitsDock : hits[toOption]}
          />
        </FormControl>
      </FlexGridItem>
    </FlexGrid>
  )
}

export default SelectToField
