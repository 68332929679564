import { styled } from 'baseui'

export const OpenSchedulingCheckBox = styled('div', {
  position: 'absolute',
  top: '16px',
  right: 0
})

export const FooterRow = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  fontSize: $theme.typography.LabelSmall.fontSize
}))

export const QrCodeContainer = styled('div', {
  transform: 'translate(15px, 33px)'
})

export const QrCodeModalBody = styled('div', {
  padding: '20px'
})

export const QrCodeModalBodyContainer = styled('div', {
  border: '1px solid grey',
  marginTop: '20px'
})

export const QrCodeHeader = styled('div', ({ $theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '15px',
  alignItems: 'center',
  height: '60px',
  backgroundColor: `${$theme.colors.primary}`
}))

export const QrCodeHeaderImage = styled('img', {
  border: '.5px solid rgba(128, 128, 128, 0.7)',
  borderRadius: '50%',
  height: '60%'
})

export const QrCodeTextAlignCenter = styled('div', {
  textAlign: 'center'
})

export const QrCodeBody = styled('div', {
  display: 'inline-block',
  padding: '20px',
  marginTop: '25px'
})

export const SubtitleStyle = {
  fontWeight: '600',
  marginBottom: '20px',
  marginTop: '0px',
  fontSize: '20px',
  textTransform: 'capitalize'
}
