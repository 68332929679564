import React from 'react'

import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/ui/generic/Pagination'

type Props = {
  page: number
  onPageChange: (page: number) => void
  total: number
}

export const MetabaseAnalyticsSettingsPagination = ({ page, total, onPageChange }: Props) => {
  const [css] = useStyletron()
  const { t } = useTranslation()
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      })}>
      <Pagination
        labels={{
          prevButton: t('Common.Table.Pagination.Prev.Text'),
          nextButton: t('Common.Table.Pagination.Next.Text'),
          preposition: t('Common.Table.Pagination.Preposition.Text')
        }}
        numPages={total}
        currentPage={page}
        onPageChange={({ nextPage }) => {
          onPageChange(nextPage)
        }}
      />
    </div>
  )
}
