import { FormControl } from "baseui/form-control";
import { Select } from "baseui/select";
import { COMMODITY_TYPE_OPTIONS } from "components/appointments/types";
import React from "react";
import { find } from "lodash";

const CommodityType = ({ appointment, setAppointment }) => {
  let commodityValue = appointment?.commodityTypeOption
    ? [appointment?.commodityTypeOption]
    : [];

  if (!appointment?.commodityTypeOption && appointment?.commodityType) {
    commodityValue = [
      {
        id: appointment?.commodityType,
        label: find(COMMODITY_TYPE_OPTIONS, { id: appointment?.commodityType }),
      },
    ];
  }
  return (
    <FormControl label="Commodity">
      <Select
        id="commodity-type"
        clearable={false}
        value={commodityValue}
        options={COMMODITY_TYPE_OPTIONS}
        onChange={({ option }) => {
          const { id } = option;
          setAppointment({
            ...appointment,
            commodityType: id,
            commodityTypeOption: option,
          });
        }}
      />
    </FormControl>
  );
};

export default CommodityType;
