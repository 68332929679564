import React, { useState, useEffect, useContext, Dispatch } from 'react'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { Facility } from 'components/models/Facility'
import { Option } from 'components/shared/types/Option'
import { Order } from 'components/models/Order'
import { shipperService } from 'components/services/shipper.service'
import { useTranslation } from 'react-i18next'

import FormControl from 'components/ui/generic/FormControl'
import Select from 'components/ui/generic/Select'

interface OrderSelectFacilityFieldProps {
  order: Order
  setOrder: Dispatch<Order>
  facility: Facility[]
  setFacility: Dispatch<Facility[]>
  disabled?: boolean
}

const OrderSelectFacilityField = ({
  order,
  setOrder,
  facility,
  setFacility,
  disabled
}: OrderSelectFacilityFieldProps) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [facilityOptions, setFacilityOptions] = useState<Option<Facility>[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    if (currentUser?.shipperId) {
      shipperService
        .getFacilityList(currentUser.shipperId)
        .then(facilities => {
          setFacilityOptions(facilities)
        })
        .catch(console.log)
    }
  }, [currentUser])

  return (
    <FormControl
      htmlFor="facilitySelect"
      label={t('Orders.CreateEditOrderModal.Fields.Facility.Label.Text')}>
      <Select
        id="facilitySelect"
        options={facilityOptions}
        value={facility}
        searchable={false}
        clearable={false}
        placeholder=""
        disabled={disabled}
        onChange={async params => {
          const [json, status] = await authenticatedFetch({
            path: `/facilities.json/?id[]=${params.option.id}`
          })

          if ([200, 304].includes(status)) {
            setFacility([{ ...facility, ...json[0] }])
            setOrder({ ...order, facilitiesId: json[0].id })
          }
        }}
      />
    </FormControl>
  )
}

export default OrderSelectFacilityField
