import styled from 'styled-components'
import { breakpoints } from '../constants/breakpoints'

export const FooterContainer = styled.div<{
  background: string
  marginTop: string
}>`
  background: ${({ background }) => background};
  margin-top: ${({ marginTop }) => marginTop};
`
