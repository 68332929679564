import http from 'components/utils/authenticated-fetch'

class AppointmentPreferencesService {
  get = (id: string): Promise<[any, number]> =>
    http({
      path: `/appointment_preferences/${id}.json`,
      method: 'GET'
    }).then(([result, status]) => [result, status])

  update = (id, body): Promise<any> =>
    http({
      path: `/appointment_preferences/${id}.json`,
      method: 'PATCH',
      body
    }).then(([result, status]) => [result, status])
}

export const appointmentPreferencesService = new AppointmentPreferencesService()
