import React from 'react'
import { TimePicker } from 'baseui/datepicker'
import { SIZE } from 'baseui/input'

const VTimePicker = ({ value, size, overrides, ...props }) => {
  return (
    <TimePicker
      size={size || SIZE.compact}
      value={value}
      overrides={{
        Select: {
          props: {
            overrides: {
              ControlContainer: {
                style: ({ $theme }) => ({
                  borderTopWidth: $theme.borders.borderWidth,
                  borderBottomWidth: $theme.borders.borderWidth,
                  borderLeftWidth: $theme.borders.borderWidth,
                  borderRightWidth: $theme.borders.borderWidth,
                  height: $theme.sizing.scale1000
                })
              },
              SingleValue: {
                style: ({ $theme }) => ({
                  lineHeight: $theme.sizing.scale800
                })
              }
            }
          }
        },
        ...overrides
      }}
      {...props}
    />
  )
}
export default VTimePicker
