export const SET_SAVING = 'SET_SAVING'
export const SET_LOADING = 'SET_LOADING'
export const SET_ZONES = 'SET_ZONES'
export const SET_ZONE_TO_UPDATE = 'SET_ZONE_TO_UPDATE'
export const SET_ZONE_TO_DELETE = 'SET_ZONE_TO_DELETE'
export const CREATE_ZONE = 'CREATE_ZONE'
export const UPDATE_ZONE = 'UPDATE_ZONE'
export const DELETE_ZONE = 'DELETE_ZONE'
export const SEARCH = 'SEARCH'
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE'
export const SELECT_ZONE = 'SELECT_ZONE'
export const CREATED_ZONE = 'CREATED_ZONE'
export const SET_MODAL_STATE = 'SET_MODAL_STATE'
export const SET_ZONE = 'SET_ZONE'
export const SET_SCAC_CODE = 'SET_SCAC_CODE'
export const SET_SEARCH_ZONE = 'SET_SEARCH_ZONE'
export const SET_CLOSE_MODAL_STATE = 'SET_CLOSE_MODAL_STATE'
export const SET_FORM_PARAMS = 'SET_MODAL_PARAMS'
export const SEARCH_PAGINATE = 'SEARCH_PAGINATE'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_SEARCH = 'SET_SEARCH'

export const zonesReducer = (state, action) => {
  switch (action.type) {
    case SET_SAVING: {
      return {
        ...state,
        saving: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case SET_SEARCH_ZONE: {
      return {
        ...state,
        zone: action.payload.zone,
        disableFields: action.payload.disableFields
      }
    }
    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
        currentPage: 1
      }
    }
    case SET_ZONES: {
      const zones = action.payload
      return {
        ...state,
        items: zones
      }
    }
    case CREATE_ZONE: {
      return {
        ...state,
        items: [...state.items],
        isModalActive: false
      }
    }
    case SEARCH: {
      return {
        ...state,
        selectItems: action.payload
      }
    }
    case SEARCH_PAGINATE: {
      return {
        ...state,
        items: action.payload.results,
        total: action.payload.total
      }
    }
    case UPDATE_ZONE: {
      const updatedZone = action.payload

      const currentZone = [...state.items]
      const indexOfUpdatedZone = currentZone.findIndex(zone => zone.id === updatedZone.id)
      currentZone[indexOfUpdatedZone] = updatedZone

      return {
        ...state,
        items: currentZone,
        isModalActive: false
      }
    }
    case DELETE_ZONE: {
      const deleteZone = action.payload

      const currentZone = [...state.items]
      const indexOfDeletedZone = currentZone.findIndex(zone => zone.id === deleteZone.id)
      currentZone.splice(indexOfDeletedZone, 1)

      return {
        ...state,
        items: currentZone
      }
    }
    case SET_MODAL_TYPE: {
      return {
        ...state,
        modalType: action.payload,
        isModalActive: true
      }
    }
    case SELECT_ZONE: {
      return {
        ...state,
        selectedZone: action.payload
      }
    }
    case CREATED_ZONE: {
      return {
        ...state,
        createdZone: action.payload
      }
    }
    case SET_MODAL_STATE: {
      return {
        ...state,
        isModalActive: action.payload
      }
    }
    case SET_CLOSE_MODAL_STATE: {
      return {
        ...state,
        isModalActive: false,
        disableFields: false,
        zone: action.payload
      }
    }
    case SET_ZONE_TO_UPDATE: {
      return {
        ...state,
        zoneToUpdate: action.payload.zone,
        isModalActive: action.payload.isModalActive
      }
    }
    case SET_ZONE_TO_DELETE: {
      return {
        ...state,
        zoneToDelete: action.payload.zone,
        isDeleteModalActive: action.payload.isDeleteModalActive
      }
    }
    case SET_ZONE: {
      return {
        ...state,
        zone: action.payload
      }
    }
    case SET_FORM_PARAMS: {
      return {
        ...state,
        externalUser: action.payload.externalUser,
        schedulerId: action.payload.schedulerId,
        createdAsOther: action.payload.createdAsOther
      }
    }
    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      }
    }
    default:
      return state
  }
}

const finalZonesReducer = ({ zones }, action) => {
  return {
    zones: zonesReducer(zones, action)
  }
}

export default finalZonesReducer
