import React, { useState, useContext } from 'react'

import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Redirect } from 'react-router-dom'

import { useStyletron } from 'baseui'

import { CustomLabelsContext } from 'components/contexts/custom-labels-context'
import { useTranslation } from 'react-i18next'

import Header from 'components/ui/generic/Header'
import FormControl from 'components/ui/generic/FormControl'
import Button from 'components/ui/specific/PrimaryButton'
import Checkbox from 'components/ui/generic/Checkbox'
import Input from 'components/ui/generic/Input'
import StyledSpinner from '../shared/styled-spinner'
import { fancyToast } from '../utils'
import authenticatedFetch from '../utils/authenticated-fetch'
import { CurrentUserContext } from '../homepage/current-user-context'
import NotificationPreferenceForm from '../notification-preference/form'

const UserProfile = () => {
  const [css, theme] = useStyletron()
  const { customLabel } = useContext(CustomLabelsContext)
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

  const [redirect, setRedirect] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const refreshCurrentUser = () => {
    if (!currentUser) return
    authenticatedFetch({
      path: `/users/${currentUser.id}.json`
    }).then(([json, status]) => {
      if (status !== 200) {
        setCurrentUser(json)
      }
    })
  }

  const submitRecordForm = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/users/${currentUser.id}.json`,
      method: 'PATCH',
      body: {
        user: currentUser
      }
    })
    setLoading(false)
    if (status === 201) {
      setTimeout(() => {
        setRedirect(true)
      }, 2000)
      fancyToast(
        {
          info: t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.UserProfile.Text'),
            action: t('Common.Actions.Created.Text')
          })
        },
        status
      )
    } else if (status === 200) {
      fancyToast(
        {
          info: t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.UserProfile.Text'),
            action: t('Common.Actions.Updated.Text')
          })
        },
        status
      )
    } else {
      fancyToast(json, status)
    }
    refreshCurrentUser()
  }

  const onChangeName = ({ target: { value } }: any) => {
    setCurrentUser(user => ({
      ...user,
      name: value
    }))
  }

  if (!currentUser || !currentUser) {
    return <StyledSpinner />
  }

  if (redirect) {
    return <Redirect to="/" />
  }

  return (
    <>
      <Header title={t('Settings.UserProfile.Header.Text')} />

      <FlexGrid flexGridColumnCount={1} maxWidth="640px">
        <FlexGridItem>
          <FormControl label={t('Settings.UserProfile.Form.Fields.Name.Label.Text')}>
            <Input
              autoComplete="off"
              name="user[name]"
              required
              error={!currentUser.name}
              value={currentUser.name}
              onChange={onChangeName}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          {currentUser.userType !== 'driver' && (
            <FormControl label={t('Settings.UserProfile.Form.Fields.EmailAddress.Label.Text')}>
              <Input
                type="email"
                name="user[email_address]"
                required
                error={!currentUser.emailAddress}
                value={currentUser.emailAddress}
                disabled
              />
            </FormControl>
          )}
        </FlexGridItem>
        <FlexGridItem>
          <FormControl label={t('Settings.UserProfile.Form.Fields.MobilePhoneNumber.Label.Text')}>
            <Input type="tel" name="user[phoneNumber]" value={currentUser.phoneNumber} disabled />
          </FormControl>
        </FlexGridItem>
        {currentUser.userType === 'driver' && (
          <FlexGridItem>
            <FormControl label={t('Settings.UserProfile.Form.Fields.Carrier.Label.Text')}>
              <Input
                name="user[carrier]"
                value={currentUser.carrierName || 'Owner operator'}
                disabled
              />
            </FormControl>
          </FlexGridItem>
        )}
        {currentUser.userType === 'shipper' && currentUser.shipperName && (
          <FlexGridItem>
            <FormControl label={t('Settings.UserProfile.Form.Fields.Shipper.Label.Text')}>
              <Input name="user[shipper]" value={currentUser.shipperName} disabled />
            </FormControl>
          </FlexGridItem>
        )}
        {!process.env.production && (
          <FlexGridItem>
            <FormControl
              label={t('Settings.UserProfile.Form.Fields.SchedulerMode.Label.Text')}
              caption={t('Settings.UserProfile.Form.Fields.SchedulerMode.SubTitle.Text')}>
              <Checkbox
                checked={currentUser.schedulerMode}
                onChange={({ currentTarget: { checked } }) =>
                  setCurrentUser(user => ({
                    ...user,
                    schedulerMode: checked
                  }))
                }
                labelPlacement="right"
                checkmarkType="toggle_round"
              />
            </FormControl>
          </FlexGridItem>
        )}
        <NotificationPreferenceForm />
      </FlexGrid>
      <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale800">
        <FlexGridItem>
          <Button isLoading={loading} disabled={!currentUser.name} onClick={submitRecordForm}>
            {t('Settings.UserProfile.Form.SaveButton.Text')}
          </Button>
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}

export default UserProfile
