import React, { useState, useEffect } from 'react'
import MainTable from './main-table'
import Header from 'components/ui/generic/Header'
import SearchBar from './search-bar'
import { useTranslation } from 'react-i18next'
import { PurchaseOrderLabelsContext } from 'components/pages/CreateAppointmentForExternalUsers'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { DEFAULT_SHIPPER_SUBDOMAIN } from '../../constants/default-shipper-subdomain'

const SchedulerDashboard = () => {
  const subdomain = window.location.hostname.split('.')[0]
  const [appointments, setAppointments] = useState<any>([])
  const [mainAppointment, setMainAppointment] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  const { t } = useTranslation()

  useEffect(() => {
    authenticatedFetch({
      path: `/facilities/open_scheduling_list.json?subdomain=${
        subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain
      }`
    })
      .then(([facilityLabels, _status]) => {
        if (facilityLabels.length >= 1) {
          setMainAppointment(facilityLabels[0])
        }
      })
      .catch(console.log)
  }, [])

  return (
    <>
      <PurchaseOrderLabelsContext.Provider
        value={{
          purchaseOrderLabels:
            mainAppointment &&
            mainAppointment.appointmentPreference &&
            mainAppointment.appointmentPreference.purchase_order_field_names
        }}>
        <Header
          title={t('Appointments.Header.Text')}
          rightItems={[
            <SearchBar
              setAppointments={setAppointments}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          ]}
        />

        <MainTable
          appointments={appointments}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </PurchaseOrderLabelsContext.Provider>
    </>
  )
}

export default SchedulerDashboard
