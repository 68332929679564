import { KIND } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { HeadingLevel } from 'baseui/heading'
import { ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import React, { useState } from 'react'
import 'react-color-palette/lib/css/styles.css'
import { useTranslation } from 'react-i18next'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import { useCustomCancelReasonsContext } from '../../contexts/custom-cancel-reason.context'
import Checkbox from 'components/ui/generic/Checkbox'
import { DEFAULT_LOCALE_STRING_LANGUAGE } from 'components/constants/default-locale-string-language'
import { ES } from 'components/contexts/locale-context'

const OTHER_LANGUAGES = [ES]

const initialValue = {
  reason: {
    'en-US': ''
  }
}

export const CustomCancelReasonForm = ({ id, onClose }) => {
  const {
    customCancelReason: { selectedCustomCancelReason, saving },
    actions: { createCancelReason, updateCancelReason }
  } = useCustomCancelReasonsContext()

  const { t, i18n } = useTranslation()
  const [cancelReason, setCancelReason] = useState(id ? selectedCustomCancelReason : initialValue)

  const onSubmit = () => {
    if (cancelReason.id) {
      updateCancelReason(cancelReason)
    } else {
      createCancelReason(cancelReason)
    }
  }

  const requiredFieldsPresent = [
    {
      label: t('Settings.CustomCancelReasons.Modal.Validations.ReasonRequired.Text'),
      status: !Object.values(cancelReason?.reason).some(value => value === '' || value === null)
    }
  ]

  const onFieldChange = (language: string, reason: string): void => {
    setCancelReason({
      ...cancelReason,
      reason: {
        ...cancelReason.reason,
        [language]: reason
      }
    })
  }

  const onLanguageCheckboxChange = (language: string, value: boolean) => {
    if (value) {
      setCancelReason({
        ...cancelReason,
        reason: {
          ...cancelReason.reason,
          [language]: ''
        }
      })
    } else {
      const cancelReasonUpdated = cancelReason
      cancelReasonUpdated?.reason.hasOwnProperty(language)
        ? delete cancelReasonUpdated.reason[language]
        : null

      setCancelReason({
        ...cancelReasonUpdated
      })
    }
  }

  return (
    <HeadingLevel>
      <ModalHeader>
        {id
          ? t('Settings.CustomCancelReasons.Modal.Header.Alternatives.Update.Text')
          : t('Settings.CustomCancelReasons.Modal.Header.Alternatives.Create.Text')}{' '}
        {t('Settings.CustomCancelReasons.Modal.Header.Text')}
      </ModalHeader>
      <ModalBody>
        <FlexGrid flexGridColumnCount={2} flexGridRowGap="scale200" flexGridColumnGap="scale200">
          <FormControl label={t('Settings.CustomCancelReasons.Modal.Fields.Reason.Label.Text')}>
            <Input
              name="reason"
              id="reason"
              autoComplete="off"
              value={cancelReason?.reason[DEFAULT_LOCALE_STRING_LANGUAGE]}
              onChange={e => {
                onFieldChange(DEFAULT_LOCALE_STRING_LANGUAGE, e.target.value)
              }}
              maxLength={255}
            />
          </FormControl>
          {OTHER_LANGUAGES.map(lang => (
            <>
              <Checkbox
                key={lang}
                checked={cancelReason?.reason.hasOwnProperty(lang)}
                onChange={e => {
                  onLanguageCheckboxChange(lang, e.currentTarget.checked)
                }}
                label={t('Settings.CustomCancelReasons.Modal.Fields.IncludeLanguage.Text', {
                  lang: lang.toUpperCase()
                })}
              />
              {cancelReason?.reason.hasOwnProperty(lang) && (
                <FormControl
                  label={t('Settings.CustomCancelReasons.Modal.Fields.ReasonLanguage.Label.Text', {
                    lang: lang.toUpperCase()
                  })}>
                  <Input
                    name={lang}
                    id={lang}
                    autoComplete="off"
                    value={cancelReason?.reason[lang]}
                    onChange={e => {
                      onFieldChange(lang, e.target.value)
                    }}
                    maxLength={255}
                  />
                </FormControl>
              )}
            </>
          ))}
        </FlexGrid>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          overrides={{ BaseButton: { style: { marginRight: '10px' } } }}
          kind={KIND.secondary}
          size="compact"
          onClick={onClose}>
          {t('Settings.CustomCancelReasons.Modal.CancelButton.Text')}
        </ModalButton>
        <ErrorMessageButton
          errors={requiredFieldsPresent}
          onClick={onSubmit}
          isLoading={saving}
          statefulTooltipProps={{ placement: 'top' }}
          label={
            id
              ? t('Settings.CustomCancelReasons.Modal.ConfirmButton.Update.Text')
              : t('Settings.CustomCancelReasons.Modal.ConfirmButton.Create.Text')
          }
        />
      </ModalFooter>
    </HeadingLevel>
  )
}
