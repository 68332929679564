import React, { useContext } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { useTranslation } from 'react-i18next'
import FormControl from 'components/ui/generic/FormControl'
import DatePicker from 'components/ui/generic/DatePicker'
import TimePicker from 'components/ui/generic/TimePicker'
import { FacilitiesContext } from '../../context/facilities-context'

const ArrivalTime = ({ record, setRecord }) => {
  const { facilityId } = record
  const { facilities } = useContext(FacilitiesContext)
  const { t } = useTranslation()

  return (
    <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200">
      <FlexGridItem>
        <FormControl label={t('Common.Fields.ArrivalDate.Text')}>
          <DatePicker
            value={record.arrivalTime ? record.arrivalTime : []}
            formatString="MM/dd/yyyy"
            onChange={({ date }) =>
              setRecord({
                ...record,
                arrivalTime: date as Date
              })
            }
          />
        </FormControl>
      </FlexGridItem>
      <FlexGridItem>
        <FormControl label={t('Common.Fields.ArrivalTime.Text')}>
          <TimePicker
            step={facilities[facilityId]?.appointmentDuration || 1800}
            nullable
            value={record.arrivalTime}
            onChange={date =>
              setRecord({
                ...record,
                arrivalTime: date as Date
              })
            }
          />
        </FormControl>
      </FlexGridItem>
    </FlexGrid>
  )
}

export default ArrivalTime
