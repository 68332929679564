import http from 'components/utils/authenticated-fetch'
import { Slot } from 'components/models/Slot'
import { fancyToast } from 'components/utils'
import { StatusCodes } from 'components/constants/http-status-codes'

class SlotService {
  getSlotById = (slotId: string): Promise<[any, number]> =>
    http({
      path: `/slots/${slotId}.json`
    })

  getSlots = (
    facilityId: string,
    search?: string,
    page?: number,
    perPage?: number
  ): Promise<[any, number]> => {
    const params = new URLSearchParams()

    if (search) {
      params.append('term', search)
    }
    if (page) {
      params.append('page', page.toString())
    }
    if (perPage) {
      params.append('per_page', perPage.toString())
    }
    return http({
      path: `/facilities/${facilityId}/slots.json?${params.toString()}`
    })
  }

  getAllSlots = (facilityId: string): Promise<[any, number]> =>
    http({ path: `/facilities/${facilityId}/slots.json?per_page=${Number.MAX_SAFE_INTEGER}` })

  getSlotsTotals = (facilityId: string, appType?: string): Promise<[any, number]> => {
    let path = `/facilities/${facilityId}/slots_per_zone.json`

    if (appType) {
      path += `?appointment_type=${appType}`
    }

    return http({ path })
  }

  createSlot = async (facilityId: string, slot: Slot): Promise<any> => {
    const [data, status] = await http({
      path: `/facilities/${facilityId}/slots.json`,
      method: 'POST',
      body: {
        slot
      }
    })

    if (status < StatusCodes.BAD_REQUEST && data) {
      fancyToast({ info: 'Slot(s) created successfully' }, StatusCodes.OK)
      return data
    }
    fancyToast({ info: data?.message || 'Something went wrong' }, StatusCodes.BAD_REQUEST)
    return null
  }

  updateSlot = async (slot: Slot): Promise<any> => {
    const [data, status] = await http({
      path: `/slots/${slot.id}.json`,
      method: 'PATCH',
      body: {
        slot
      }
    })

    if (status < StatusCodes.BAD_REQUEST && data) {
      fancyToast({ info: 'Slot updated successfully' }, StatusCodes.OK)
      return data
    }
    fancyToast({ info: data?.message || 'Something went wrong' }, StatusCodes.BAD_REQUEST)
    return null
  }

  assignTrailerToSlotOrDock = async (
    trailerId: string,
    dockId: string,
    slotId: string,
    facilityId: string,
    userId: string
  ): Promise<any> => {
    const params = new URLSearchParams()

    if (trailerId) {
      params.append('trailer_id', trailerId)
    }

    if (facilityId) {
      params.append('facility_id', facilityId)
    }

    if (dockId) {
      params.append('dock_id', dockId.toString())
    }

    if (slotId) {
      params.append('slot_id', slotId.toString())
    }

    if (userId) {
      params.append('user_id', userId)
    }

    return http({
      path: `/slots/assign_slot.json?${params.toString()}`,
      method: 'PUT'
    })
  }
}

export const slotService = new SlotService()
