import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { ButtonProps } from 'baseui/button'
import CancelAppointment from 'components/appointments/modals/cancel-appointment'
import ConfirmModificationRecurringAppointmentModal from 'components/appointments/modals/confirm-modification-recurring-modal'
import Button from 'components/ui/specific/ErrorButton'
import CancelDropAppointment from 'components/appointments/modals/cancel-drop-appointment'

const CancelButton = ({
  appointment,
  appointments,
  close,
  appointmentIds,
  label,
  facility,
  buttonProps
}: {
  appointment: any
  appointments?: any
  close: Function
  facility?: any
  appointmentIds?: string[]
  label?: string
  buttonProps?: ButtonProps
}) => {
  const [openReasonModal, setOpenReasonModal] = useState<boolean>(false)
  const [openCancelOption, setOpenCancelOption] = useState<boolean>(false)
  const [openRecurringAppointment, setOpenRecurringAppointment] = useState<boolean>(false)
  const [idsToCancel, setIdsToCancel] = useState<string[]>(appointmentIds)
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  return (
    <div className={css({ marginRight: theme.sizing.scale400 })}>
      <Button
        onClick={() => {
          if (appointment?.recurringAppointmentBlueprintId) {
            setOpenRecurringAppointment(true)
          } else {
            appointmentIds.length > 1 ? setOpenCancelOption(true) : setOpenReasonModal(true)
          }
        }}
        kind="secondary"
        {...buttonProps}>
        {label ? label : t('Common.Button.CancelAppointment.Text')}
      </Button>

      {appointment?.recurringAppointmentBlueprintId && !openReasonModal ? (
        <ConfirmModificationRecurringAppointmentModal
          isOpen={openRecurringAppointment}
          close={() => {
            setOpenRecurringAppointment(false)
          }}
          closeParent={close}
          appointment={appointment}
          openReasonModal={() => setOpenReasonModal(true)}
          type="DELETE"
        />
      ) : (
        <>
          <CancelDropAppointment
            isOpen={openCancelOption}
            close={ids => {
              if (ids?.length > 0) {
                setIdsToCancel(ids)
                setOpenReasonModal(true)
              }
              setOpenCancelOption(false)
            }}
            appointments={appointments}
            facility={facility}
          />
          <CancelAppointment
            isOpen={openReasonModal}
            close={deleted => {
              setOpenReasonModal(false)
              close(deleted)
            }}
            appointment={appointment}
            appointmentIds={idsToCancel}
            openScheduling
          />
        </>
      )}
    </div>
  )
}

export default CancelButton
