import { DROPDOWN_LIST, TEXT_TYPE } from '../models/Question'
import moment from 'moment'

const checkTextError = (question, answer) =>
  (question?.minLength ? question?.minLength > answer.response?.length : false) ||
  (question?.maxLength ? question?.maxLength < answer.response?.length : false) ||
  (question?.numericOnly ? !/^[0-9]+$/.test(answer?.response) : false)

export const checkQuestionError = (question, permission, answer) => {
  if (!permission || permission?.required) {
    if (question.answerType === TEXT_TYPE && checkTextError(question, answer)) {
      return true
    }

    return (
      (answer.response !== null ? answer.response.length === 0 : true) ||
      answer.response === undefined
    )
  }

  if (question.answerType === DROPDOWN_LIST && !permission?.required) {
    return false
  }

  if (
    moment(answer?.response, 'MM/DD/YYYY', true).isValid() ||
    (!permission?.required && answer.response === null)
  ) {
    return false
  }
  return answer.response?.length !== 0 && checkTextError(question, answer)
}

export const checkFileQuestionError = (
  question,
  permission,
  temporalFiles,
  attachedDocuments,
  documentSelections,
  answerId
) => {
  if (!permission.required) {
    return false
  }

  if (
    answerId &&
    attachedDocuments.length > 0 &&
    documentSelections.filter(ds => ds.questionId === answerId).length < attachedDocuments.length
  ) {
    return false
  }

  const answerTemporalFiles = temporalFiles.filter(tf => tf?.questionId === question?.id)

  if (answerTemporalFiles.length > 0) {
    return !answerTemporalFiles.every(tf => tf.files && tf.files.length > 0)
  }

  return true
}
