import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { useCarriersContext } from 'components/contexts/carriers.context'
import React from 'react'
import CarrierModalForm from './CarrierModalForm'
import CarrierModalFooter from './CarrierModalFooter'
import { useTranslation } from 'react-i18next'
import { MODAL_TYPE_EDIT } from 'components/constants/modal-types'

const CarrierModal = () => {
  const {
    carriers: { modalType, isModalActive },
    actions: { closeModal }
  } = useCarriersContext()

  const { t } = useTranslation()

  return (
    <Modal unstable_ModalBackdropScroll={true} onClose={closeModal} isOpen={isModalActive}>
      <ModalHeader>
        {modalType === MODAL_TYPE_EDIT
          ? t('Settings.Carriers.CreateCarrierModal.Header.Alternatives.Update.Text')
          : t('Settings.Carriers.CreateCarrierModal.Header.Alternatives.Create.Text')}{' '}
        {t('Settings.Carriers.CreateCarrierModal.Header.Text')}
      </ModalHeader>
      <ModalBody>
        <CarrierModalForm />
      </ModalBody>

      <CarrierModalFooter closeModal={closeModal} />
    </Modal>
  )
}

export default CarrierModal
