import authenticatedFetch from 'components/utils/authenticated-fetch'

class QuestionService {
  listQuestions = (appointmentTypeId): Promise<any> =>
    authenticatedFetch({
      path: `/appointment_types/${appointmentTypeId}/questions.json`
    }).then(([result, status]) => [result, status])
}

export const questionService = new QuestionService()
