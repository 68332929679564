import { SIZE, Tag, VARIANT } from 'baseui/tag'
import {
  KIND_METRIC,
  metricAccent,
  metricPrimary,
  metricSuccess
} from 'components/constants/tasks_metrics_types'
import { primitives } from 'components/homepage/custom-theme'
import React from 'react'
import { TitleMetricCard, YardMetricCardProps } from './YardMetricCard.style'

export interface MetricCardProps {
  title: string
  value: number
  icon?: any
  kind?: KIND_METRIC
}

const YardMetricCard = ({ icon, title, value, kind }: MetricCardProps) => {
  const color = () => {
    switch (kind) {
      case metricPrimary:
        return primitives.primary
      case metricAccent:
        return primitives.accent
      case metricSuccess:
        return primitives.positive
      default:
        return primitives.primary
    }
  }

  return (
    <Tag
      closeable={false}
      variant={VARIANT.solid}
      startEnhancer={() => (icon ? icon : null)}
      size={SIZE.medium}
      {...YardMetricCardProps({ color: color() })}>
      <span data-testid={'yard-metric-card-value'}>{value}</span>
      {'  '}
      <TitleMetricCard data-testid={'yard-metric-card-title'}>{title}</TitleMetricCard>
    </Tag>
  )
}

export default YardMetricCard
