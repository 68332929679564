import { string } from 'prop-types'
import { Dock } from './Dock'
import { Facility } from './Facility'
import { Model } from './Model'
import { Slot } from './Slot'
import { Trailer, TrailerDetails } from './Trailer'

export interface Task extends Model {
  id?: string
  facilityId?: string
  facility?: Facility
  notes?: string
  acceptedAt?: Date
  completedAt?: Date
  startedAt?: Date
  createdAt?: Date
  fromDockId?: string
  fromDock?: Dock
  toDockId?: string
  toDock?: Dock
  fromSlotId?: string
  toSlotId?: string
  trailerId?: string
  userId?: string
  fromSlot?: Slot
  toSlot?: Slot
  trailer?: Trailer
  rank?: string
  flag?: boolean
  user?: {
    id?: string
    name?: string
  }
}

export interface TaskEvent {
  id?: string
  facilityId?: string
  facility?: Facility
  notes?: string
  acceptedAt?: Date
  completedAt?: Date
  startedAt?: Date
  createdAt?: Date
  fromDockId?: string
  fromDock?: Dock
  toDockId?: string
  toDock?: Dock
  fromSlotId?: string
  toSlotId?: string
  fromSlot?: Slot
  toSlot?: Slot
  userName?: string
  userId?: string
  status?: string
  trailerId?: string
  trailerStatus?: string
  trailerState?: string
  trailerNumber?: string
  assigned?: string
  assignedPrev?: string
  trailer?: TrailerDetails
  rank?: string
}

export const TASK_ACCEPTED = 'accepted'
export const TASK_STARTED = 'started'
export const TASK_COMPLETED = 'completed'
export const TASK_CREATED = 'created'
export const TASK_UNASSIGNED = 'unassigned'
