export interface Role {
  name?: string
  enabled?: boolean
  id?: string
  audience?: string
  shipperId?: string
}

export const DEFAULT = 'Default'
export const ENABLED = 'Enabled'
export const DISABLED = 'Disabled'
export const INTERNAL = 'internal'
export const YARD_JOCKEY_AUDIENCE = 'yard_jockey'
export const YARD_JOCKEY = 'yard jockey'
export const EXTERNAL = 'external'
export const DRIVER = 'driver'
export const SHIPPER = 'shipper'
export const SCHEDULER = 'scheduler'
export const VIEW_ONLY = 'view only'
export const DEFAULT_ROLE_NAMES = [SHIPPER, SCHEDULER, DRIVER, YARD_JOCKEY, VIEW_ONLY]
